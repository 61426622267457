import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import api from 'shared/utils/api';

const initialState = {
  appThemeColor: '#8160FF',
  supervisorData: null,
  supervisorList: [],

  changeSvPositionModal: false,
  selectedSvUserPosition: null,

  changePaymentModal: false,

  svJoinRequestList: [],

  unreadSvNotificationsCount: 0,

  changeSvStoreModal: false,

  svStoreSelectModal: false,

  brandList: [],
  franchiseStoreList: [],

  svUserInfo: null,
};

// franchise login thunk
export const loginFranchiseUserAsync = createAsyncThunk(
  'franchise/user/loginFranchiseUser',
  async (data) => {
    const response = await api.supervisorLogin(data);

    const {results} = response.data;

    return results;
  },
);

// get owner signature color thunk
export const getUserColor = createAsyncThunk(
  'franchise/user/getFranchiseColor',
  async () => {
    const response = await api.getUserColor();
    const {franchiseColor} = response.data;

    return franchiseColor;
  },
);

export const getSVUserInfo = createAsyncThunk(
  'franchise/user/getSVUserInfo',
  async () => {
    const response = await api.getSVUserInfo();

    const {results} = response.data;

    return results;
  },
);

// get owner signature color thunk
export const getSupervisorListAsync = createAsyncThunk(
  'franchise/user/getSupervisorList',
  async (sv_user_uid) => {
    const response = await api.getSupervisorList(sv_user_uid);

    const {results} = response.data;

    return results;
  },
);

export const patchSupervisorPermissionAsync = createAsyncThunk(
  'franchise/user/patchSupervisorPermissionAsync',
  async (sv_user_uid) => {
    const response = await api.patchSupervisorPermission(sv_user_uid);

    const {results} = response.data;

    return results;
  },
);

export const changeSvPositionsAsync = createAsyncThunk(
  'franchise/user/changeSvPositionsAsync',
  async (sv_user_uid) => {
    const response = await api.changeSvPositions(sv_user_uid);

    const {results} = response.data;

    return results;
  },
);

// Get Supervisor join request list
export const getSvJoinRequestListAsync = createAsyncThunk(
  'franchise/user/getSvJoinRequestList',
  async (sv_user_uid) => {
    const response = await api.getSvJoinRequestList(sv_user_uid);
    const {results} = response.data;
    return results;
  },
);

// Post Accept supervisor join request
export const postAcceptSvJoinRequestAsync = createAsyncThunk(
  'franchise/user/postAcceptSvJoinRequest',
  async (data) => {
    const response = await api.postAcceptSvJoinRequest(data);
    const {results} = response.data;
    return results;
  },
);

// Post Accept supervisor join request
export const rejectSvJoinRequestAsync = createAsyncThunk(
  'franchise/user/rejectSvJoinRequest',
  async (data) => {
    const response = await api.rejectSvJoinRequest(data);
    const {results} = response.data;
    return results;
  },
);

// GET :: Unread notification count
export const getSvUnreadNotificationsCountAsync = createAsyncThunk(
  'franchise/user/getSvUnreadNotificationsCount',
  async (param) => {
    const response = await api.getSvUnreadNotificationsCount(param);
    const {results} = response.data;

    return results;
  },
);

export const getFranchiseSvJoinAsync = createAsyncThunk(
  'franchise/user/getFranchiseSvJoinAsync',
  async (param) => {
    const response = await api.getFranchiseSvJoin(param);
    const {results} = response.data;

    return results;
  },
);

export const postFranchiseSvJoinAsync = createAsyncThunk(
  'franchise/user/postFranchiseSvJoinAsync',
  async (param) => {
    const response = await api.postFranchiseSvJoin(param);
    const {results} = response.data;

    return results;
  },
);

export const getSvBrandListAsync = createAsyncThunk(
  'franchise/user/getSvBrandListAsync',
  async (param) => {
    const response = await api.getSvBrandList(param);
    const {results} = response.data;

    return results;
  },
);

export const getSvfranchiseStoreListAsync = createAsyncThunk(
  'franchise/user/getSvfranchiseStoreListAsync',
  async (param) => {
    const response = await api.getSvfranchiseStoreList(param);
    const {results} = response.data;

    return results;
  },
);

export const patchSvfranchiseStoreAsync = createAsyncThunk(
  'franchise/user/patchSvfranchiseStoreAsync',
  async (param) => {
    const response = await api.patchSvfranchiseStore(param);
    const {results} = response.data;

    return results;
  },
);

export const getSvSignAsync = createAsyncThunk(
  'franchise/user/getSvSignAsync',
  async (param) => {
    const response = await api.getSvSign(param);
    const {results} = response.data;

    return results;
  },
);

export const postSvSignAsync = createAsyncThunk(
  'franchise/user/postSvSignAsync',
  async (param) => {
    const response = await api.postSvSign(param);
    const {results} = response.data;

    return results;
  },
);

export const franchiseUserSlice = createSlice({
  name: 'franchiseUser',
  initialState,
  reducers: {
    setAppThemeColor: (state, action) => {
      state.appThemeColor = action.payload;
    },
    setSelectedSvUserPosition: (state, action) => {
      state.selectedSvUserPosition = action.payload;
    },

    setChangeSvPositionModal: (state, action) => {
      state.changeSvPositionModal = action.payload;
    },
    setChangePaymentModal: (state, action) => {
      state.changePaymentModal = action.payload;
    },
    setChangeSvStoreModal: (state, action) => {
      state.changeSvStoreModal = action.payload;
    },
    setSvStoreSelectModal: (state, action) => {
      state.svStoreSelectModal = action.payload;
    },
    setSVUserInfo: (state, action) => {
      state.supervisorData = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(loginFranchiseUserAsync.pending, (state) => {
        state.supervisorData = null;
      })
      .addCase(getSVUserInfo.fulfilled, (state, action) => {
        state.svUserInfo = action.payload;
      })
      .addCase(loginFranchiseUserAsync.fulfilled, (state, action) => {
        state.supervisorData = action.payload;
      })
      .addCase(loginFranchiseUserAsync.rejected, (state) => {
        state.supervisorData = null;
      })

      .addCase(getUserColor.fulfilled, (state, action) => {
        state.appThemeColor = action.payload;
      })

      .addCase(getSupervisorListAsync.fulfilled, (state, action) => {
        state.supervisorList = action.payload;
      })

      .addCase(getSvJoinRequestListAsync.fulfilled, (state, action) => {
        state.svJoinRequestList = action.payload;
      })

      .addCase(getSvBrandListAsync.fulfilled, (state, action) => {
        state.brandList = action.payload;
      })

      .addCase(getSvfranchiseStoreListAsync.fulfilled, (state, action) => {
        state.franchiseStoreList = action.payload;
      })

      .addCase(
        getSvUnreadNotificationsCountAsync.fulfilled,
        (state, action) => {
          state.unreadSvNotificationsCount = action.payload;
        },
      );
  },
});

export const {
  setAppThemeColor,
  setSelectedSvUserPosition,
  setChangeSvPositionModal,
  setSVUserInfo,
  setChangeSvStoreModal,
  setSvStoreSelectModal,
  setChangePaymentModal,
} = franchiseUserSlice.actions;

export const selectFranchiseUser = (state) => state.franchise.user;

export default franchiseUserSlice.reducer;
