import {useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import toast from 'react-hot-toast';
import {useTranslation} from 'react-i18next';

import {
  setUserInfo,
  webPhoneLoginAsync,
  sendPhoneValidationCodeAsync,
  setCurrentAuthUser,
} from 'owler/utils/slices/userSlice';
import {selectApp} from 'shared/utils/redux/appSlice';
import {getUserStoresAsync} from 'owler/utils/slices/storeSlice';

import PhoneLoginTemplate from './template';
import {setSVUserInfo} from 'franchise/utils/slices/frUserSlice';

const PhoneLogin = () => {
  const {t} = useTranslation();
  const {appType} = useSelector(selectApp);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [userPhoneNumInput, setUserPhoneNumInput] = useState('');
  const [formattedPhoneNum, setFormattedPhoneNum] = useState('');

  // Handle 'enter' button press
  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      inputRef.current.blur();
      onSignup();
    }
  };

  const onSignup = () => {
    setLoading(true);

    const formatPh = '+82' + userPhoneNumInput.slice(1);

    // Define the regular expression pattern for phone numbers in the format +821012345678
    const phoneNumPattern = /\+82\d{10}$/;

    if (formatPh === '+') {
      toast.error(t('alerts.enter_your_number'));
      setLoading(false);
      return;
    }

    // Test the formatPh variable against the phoneNumPattern regular expression
    if (!phoneNumPattern.test(formatPh)) {
      // Show a toast error message if the phone number format is invalid
      toast.error(t('alerts.invalid_phone_num'));
      setLoading(false);
      return;
    }

    setFormattedPhoneNum(formatPh);
    /// new login
    dispatch(sendPhoneValidationCodeAsync({phone: formatPh}))
      .unwrap()
      .then(({en, kr}) => {
        setLoading(false);
        setShowOTP(true);
        toast.success(kr);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error);
      });
  };

  const onOTPVerify = () => {
    setLoading(true);

    if (appType === 'owler') {
      dispatch(
        webPhoneLoginAsync({
          code: otp,
          phone: formattedPhoneNum,
          token: null,
        }),
      )
        .unwrap()
        .then((payload) => {
          dispatch(setUserInfo(payload?.userInfo));
          dispatch(getUserStoresAsync(payload?.userInfo));
          dispatch(setCurrentAuthUser(true));
          setLoading(false);
          navigate('/owler/storeList');
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
          dispatch(setCurrentAuthUser(false));
          toast.error(error);
          navigate('/phoneLogin');
        });
    } else if (appType === 'franchise') {
      // first we need to log 'owler' user and then we can log the franchise user
      dispatch(
        webPhoneLoginAsync({
          code: otp,
          phone: formattedPhoneNum,
          token: null,
        }),
      )
        .unwrap()
        .then((payload) => {
          if (payload?.commuProfile === null) {
            alert(
              '슈퍼바이저 계정이 존재하지 않습니다. 아울러로 문의주시기 바랍니다.',
            );
            navigate(-1);
            return;
          }
          dispatch(setSVUserInfo(payload?.commuProfile));
          dispatch(setCurrentAuthUser(true));
          navigate('/franchise/dashboard');
        })
        .catch((error) => {
          setLoading(false);
          dispatch(setCurrentAuthUser(false));
          toast.error(error);
          navigate('/phoneLogin');
        });
    }
  };

  const handleOpenEmailLogin = () => {
    navigate('/emailLogin');
  };

  const handleLogoClick = () => {
    navigate('/');
  };
  return (
    <>
      <PhoneLoginTemplate
        handleLogoClick={handleLogoClick}
        showOTP={showOTP}
        otp={otp}
        setOtp={setOtp}
        onOTPVerify={onOTPVerify}
        loading={loading}
        inputRef={inputRef}
        handleKeyDown={handleKeyDown}
        userPhoneNumInput={userPhoneNumInput}
        setUserPhoneNumInput={setUserPhoneNumInput}
        onSignup={onSignup}
        handleOpenEmailLogin={handleOpenEmailLogin}
      />
    </>
  );
};

export default PhoneLogin;
