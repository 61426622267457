import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import i18n from 'i18next';

import api from 'shared/utils/api';

const initialState = {
  storeMemberList: [],
  commuteLog: [],
};

export const getStoreMemberListAsync = createAsyncThunk(
  'franchise/getStoreMemberListAsync',
  async (param, {rejectWithValue}) => {
    try {
      const response = await api.getStoreMemberList(param);
      const {results} = response.data;

      return results;
    } catch (error) {
      const errorResponse = error.response || {};
      const data = errorResponse.data || {};
      const message =
        i18n.language === 'ko'
          ? data.kr || error.message || '잘못된 접근입니다.'
          : data.en || error.message || 'Something went wrong :(';
      return rejectWithValue(message);
    }
  },
);

export const getMemberCommuteLogAsync = createAsyncThunk(
  'franchise/getMemberCommuteLogAsync',
  async (param) => {
    const response = await api.getMemberCommuteLog(param);
    const {results} = response.data;

    return results;
  },
);

export const commuteSlice = createSlice({
  name: 'franchiseSupplemention',
  initialState,
  reducers: {
    setStoreMemberList: (state, action) => {
      state.storeMemberList = action.payload;
    },
    setCommuteLog: (state, action) => {
      state.commuteLog = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getStoreMemberListAsync.fulfilled, (state, action) => {
      state.storeMemberList = action.payload;
    });
    builder.addCase(getMemberCommuteLogAsync.fulfilled, (state, action) => {
      state.commuteLog = action.payload;
    });
  },
});

export const {setStoreMemberList, setCommuteLog} = commuteSlice.actions;

export const selectCommute = (state) => state.owler.commute;

export default commuteSlice.reducer;
