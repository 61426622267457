import React from 'react';
import styled, {css} from 'styled-components';

const DataGroup = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  margin: ${(props) => props.margin || '0px'};
  margin-left: ${(props) => props.marginLeft || '0px'};
  ${({justifyContent}) => {
    switch (justifyContent) {
      case 'center':
        return css`
          justify-content: center;
        `;
      case 'space-around':
        return css`
          justify-content: space-around;
        `;
      case 'space-between':
        return css`
          justify-content: space-between;
        `;
      case 'flex-start':
        return css`
          justify-content: flex-start;
        `;
      case 'flex-end':
        return css`
          justify-content: flex-end;
        `;
      case 'space-evenly':
        return css`
          justify-content: space-evenly;
        `;
      default:
        return css``;
    }
  }}
  ${({width, onClick}) => {
    if (width) {
      return css`
        width: ${width};
        /* & > *:last-child {
          flex-basis: 20%;
          display: flex;
          justify-content: center;
          text-align: right;
        } */
      `;
    }
    return css`
      flex: 1;
      text-align: center;
      cursor: ${onClick ? ' pointer' : 'default'};
    `;
  }};
`;

const TableDataGroup = (props) => {
  const {
    padding,
    fontSize,
    width,
    onClick,
    children,
    style,
    justifyContent,
    margin,
    marginLeft,
  } = props;
  return (
    <DataGroup
      justifyContent={justifyContent}
      style={style}
      padding={padding}
      fontSize={fontSize}
      width={width}
      margin={margin}
      onClick={onClick}
      marginLeft={marginLeft}
    >
      {children}
    </DataGroup>
  );
};

export default TableDataGroup;
