import React from 'react';

import NoticeAddForm from './NoticeAddForm';
import {Wrapper} from 'shared/components/index';

const NoticeAddTemplate = ({
  store,
  onSubmit,
  onValidate,
  handleBackBtnClick,
  handleContentChange,
}) => {
  return (
    <Wrapper titleText={store.store_name}>
      <NoticeAddForm
        onSubmit={onSubmit}
        onValidate={onValidate}
        handleBackBtnClick={handleBackBtnClick}
        handleContentChange={handleContentChange}
      />
    </Wrapper>
  );
};

export default NoticeAddTemplate;
