import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import i18n from 'i18next';
import api from 'shared/utils/api';

const initialState = {
  qsc_uid: null,
  qscProcess: null,
  masterQscProcessList: [],
  qscSignatures: null,
  svUserPositionsList: [],
  qscStoreList: [],
  masterQSCNotes: [],
  currentPage: {page: 1},
  countPage: null,
  qscExportInfo: null,
  currentQSCPage: 1,
  QSCPageCount: null,

  loadSignatureModal: false,
  loadSignatureQSC: null,

  qscCheckDetail: null,
  qscCheckCatName: null,
  qscCheckDetailModal: false,

  qscImageModal: false,
  qscImage: null,
  openModal: false,
  storeQsc: [],
};

export const getQSCProcessListByPageThunk = createAsyncThunk(
  'qsc/getQSCProcessListByPage',
  async (data) => {
    const response = await api.getQscProcessListByPage(data);

    const {results} = response.data;

    return results;
  },
);

export const getSignaturesFromServerThunk = createAsyncThunk(
  'qsc/getSignaturesFromServer',
  async (data, {rejectWithValue}) => {
    try {
      const response = await api.getSignaturesFromServer(data);

      return response?.data;
    } catch (error) {
      const errorResponse = error.response || {};
      const data = errorResponse.data || {};
      const message =
        i18n.language === 'ko'
          ? data.kr || error.message || '잘못된 접근입니다.'
          : data.en || error.message || 'Something went wrong :(';
      return rejectWithValue(message);
    }
  },
);

// GET :: SV QSC Status
export const getSvQSCStatusAsync = createAsyncThunk(
  'qsc/getSvQSCStatus',
  async (param) => {
    const response = await api.getSvQSCStatus(param);

    const {results} = response.data;

    return results;
  },
);

export const getFranchiseStoresQscAsync = createAsyncThunk(
  'qsc/getFranchiseStoresQsc',
  async () => {
    const response = await api.getFranchiseStoresQsc();

    const {results} = response.data;

    return results;
  },
);

// deleteNewQSCProcessComment thunk
export const deleteNewQSCProcessCommentAsync = createAsyncThunk(
  'qsc/deleteNewQSCProcessComment',
  async (data, {rejectWithValue}) => {
    try {
      const response = await api.deleteNewQSCProcessComment(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const franchiseQscSlice = createSlice({
  name: 'franchiseQsc',
  initialState,
  reducers: {
    setQSCUID: (state, action) => {
      state.qsc_uid = action.payload;
    },
    setMasterQSCProcessList: (state, action) => {
      state.masterQscProcessList = action.payload;
    },

    setCurrentQSCPage: (state, action) => {
      state.currentQSCPage = action.payload;
    },
    setQSCPageCount: (state, action) => {
      state.QSCPageCount = action.payload;
    },

    setQSCProcess: (state, action) => {
      state.qscProcess = action.payload;
    },

    setLoadSignatureModal: (state, action) => {
      state.loadSignatureModal = action.payload;
    },

    setLoadSignatureQSC: (state, action) => {
      state.loadSignatureModal = true;
      state.loadSignatureQSC = action.payload;
    },

    setQscSignatures: (state, action) => {
      state.qscSignatures = action.payload;
    },

    setQscExportInfo: (state, action) => {
      state.qscExportInfo = action.payload;
    },

    setQscCheckDetail: (state, action) => {
      state.qscCheckDetail = action.payload;
    },

    setQscCheckCatName: (state, action) => {
      state.qscCheckCatName = action.payload;
    },

    setQscCheckDetailModal: (state, action) => {
      state.qscCheckDetailModal = action.payload;
    },

    setQscImageModal: (state, action) => {
      state.qscImageModal = action.payload;
    },
    setQscImage: (state, action) => {
      state.qscImage = action.payload;
    },
    setOpenModal: (state, action) => {
      state.openModal = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      // Handling franchise stores
      .addCase(getFranchiseStoresQscAsync.fulfilled, (state, action) => {
        state.storeQsc = action.payload;
      });
  },
});

export const {
  setQSCUID,
  setQSCProcess,
  setMasterQSCProcessList,
  setCurrentQSCPage,
  setQSCPageCount,
  setLoadSignatureModal,
  setLoadSignatureQSC,
  setQscSignatures,
  setQscExportInfo,
  setOpenModal,
  setQscCheckDetail,
  setQscCheckCatName,
  setQscCheckDetailModal,
  setQscImageModal,
  setQscImage,
} = franchiseQscSlice.actions;

export const selectFranchiseQsc = (state) => state.franchise.qsc;

export default franchiseQscSlice.reducer;
