/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
import React, {useCallback, useState} from 'react';
import styled from 'styled-components';
import {useNavigate, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Logo, MuiButton, SimpleButton, Text} from 'shared/components/index';
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import theme from 'shared/styles/theme';
import {useDispatch, useSelector} from 'react-redux';
import {selectStore, setCurrentStore} from 'owler/utils/slices/storeSlice';
import {Menu, MenuItem} from '@mui/material';
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded';
import {selectUser} from 'owler/utils/slices/userSlice';
import {useEffect} from 'react';
import {getMemberUidAsync, setMember_uid} from 'owler/utils/slices/noticeSlice';
import {getUnreadNotificationsCountAsync} from 'owler/utils/slices/userSlice';
import Chatting from 'owler/pages/Chat/index';
const Wrapper = styled.div`
  height: 100%;
  min-width: 1300px;
`;

const Content = styled.div`
  flex: 1;
  margin-top: 0px;
`;

const Drawer = styled.div`
  height: 75px;
  box-shadow: 0px 1px 2px #00000016;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  z-index: 1300;
  min-width: 1320px;
  margin: 0 auto;
`;

const ContentWrapper = styled.div`
  width: 1200px;
  min-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 1000px;
`;

const StoreNameWrapper = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;
  &:hover {
    transition: all;
    transform: scale(1.03);
  }

  margin-left: 64px;
  font-weight: 600;
  margin-top: -8px;
`;

const Container = styled.div`
  display: flex;
  align-items: flex-start;
`;

const IconContainer = styled.div`
  position: relative;
  margin-right: 10px;
`;

const IconButton = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: ${theme.colors.notBlack};

  &:focus {
    outline: none;
  }
`;

const Indicator = styled.div`
  position: absolute;
  top: -4px;
  right: -4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: red;
  color: white;
  font-size: 12px;
`;
const ListButton = styled.button`
  position: relative;
  // top: 22px;
  left: 10px;
  width: 20px;
  height: 20px;
  border: none;
  background-color: ${theme.colors.kalsPurple};
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-itmes: center;
  justify-content: center;
  &:hover {
    background-color: #8b77d9;
  }
`;
const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)`
  color: white;
  position: relative;
  top: -3px;
`;
const StyledMenuItem = styled(MenuItem)`
  overflow: wrap;
  z-index: 3000;
  &:hover {
    //width: 400px;
    transform: scale(1.03);
    z-index: 999;
  }
  color: ${(props) => (props.selected ? '#BCA9F5' : '')} !important;
  font-weight: ${(props) => (props.selected ? '600' : '')} !important;
  font-size: ${(props) => (props.selected ? '18px' : '14px')} !important;
`;
const StyledMenu = styled(Menu)`
  margin: 6px 0 0 -142px;
  max-height: 600px;
  z-index: 3000;
`;
const Header = ({children, src, infoText, buttonText, handleStoreClick}) => {
  const {currentStore, userStores} = useSelector(selectStore);
  const [anchorEl, setAnchorEl] = useState(null);
  const {unreadMessagesCount, unreadNotificationsCount} =
    useSelector(selectUser);
  const navigate = useNavigate();
  const {t} = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getUnreadNotificationsCountAsync({store_uid: currentStore?.store_uid}),
    );
  }, [currentStore]);

  const handleLogoClick = useCallback(() => {
    navigate(`/owler/store/${currentStore.store_uid}`);
  }, [dispatch, navigate]);

  const handleStoreNameClick = useCallback(() => {
    navigate(`/owler/store/${currentStore.store_uid}`);
  }, [navigate]);

  const handleNotificationsBtn = useCallback(() => {
    navigate(`/owler/notifications/${currentStore.store_uid}`);
  }, [navigate]);

  const handleChattingBtn = useCallback(() => {
    navigate(`/owler/chat/${currentStore.store_uid}`);
  }, [navigate]);

  const handleOpenMenu = (event) => {
    event.stopPropagation();
    if (anchorEl === null) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const handleCloseMenu = (event, store) => {
    event.stopPropagation();
    setAnchorEl(null);
    if (store === 'backdropClick') {
      return;
    }
    handleStoreClick(store);
  };

  return (
    <Wrapper>
      <Drawer>
        <ContentWrapper>
          <LogoWrapper>
            <Logo size="lg" src={src} onClick={handleLogoClick} />

            <StoreNameWrapper onClick={handleStoreNameClick}>
              <Text
                style={{
                  fontSize: '20px',
                  maxWidth: '700px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {currentStore?.store_name}
              </Text>
              {handleStoreClick && currentStore.is_hq ? (
                <ListButton onClick={(e) => handleOpenMenu(e)}>
                  <StyledArrowDropDownIcon sx={{fontSize: 25}} />
                </ListButton>
              ) : null}
              <StyledMenu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
              >
                <div style={{zIndex: '3000'}}>
                  {userStores.map((store, index) => {
                    if (store.is_hq) {
                      return (
                        <div key={index} style={{width: '302px'}}>
                          <StyledMenuItem
                            value={store.store_uid}
                            selected={
                              currentStore.store_uid === store.store_uid
                                ? true
                                : false
                            }
                            onClick={(e) => handleCloseMenu(e, store)}
                          >
                            {store.store_name}
                          </StyledMenuItem>
                        </div>
                      );
                    }
                  })}
                </div>
              </StyledMenu>
              {/* <ExpandMoreIcon /> */}
            </StoreNameWrapper>
          </LogoWrapper>

          <div></div>

          <Container>
            {/* <IconContainer>
              <IconButton onClick={handleChattingBtn}>
                <ChatRoundedIcon sx={{fontSize: 24}} />
              </IconButton>
              {unreadMessagesCount > 0 && (
                <Indicator>{unreadMessagesCount}</Indicator>
              )}
            </IconContainer> */}

            <IconContainer>
              <IconButton onClick={handleNotificationsBtn}>
                <NotificationsActiveRoundedIcon sx={{fontSize: 24}} />
              </IconButton>
              {unreadNotificationsCount > 0 && (
                <Indicator>{unreadNotificationsCount}</Indicator>
              )}
            </IconContainer>
          </Container>
        </ContentWrapper>
      </Drawer>

      <Content>{children}</Content>
    </Wrapper>
  );
};

export default Header;
