import React, {useCallback, useMemo} from 'react';

import CheckCircleSolidIcon from 'shared/assets/icons/CheckCircleSolidIcon';
import ImageRegularIcon from 'shared/assets/icons/ImageRegularIcon';
import ImageSolidIcon from 'shared/assets/icons/ImageSolidIcon';
import theme from 'shared/styles/theme';
import {
  ImageTaskModal,
  MuiButton,
  Wrapper,
  Drawer,
  Text,
} from 'shared/components/index';
import {getFormattedTime2} from 'shared/utils/time';
import styled from 'styled-components';
import TaskImageModal from './TaskImageModal';
import TaskDeleteModal from './TaskDeleteModal';

const Container = styled.div`
  width: 1100px;
  min-height: 500px;
  margin: -69px 0 0 38px;
  border-top: 1px solid #d5d5d5;
  //border-bottom: 1px solid #d5d5d5;
  display: flex;
  flex-direction: column;
`;

const TopContainer = styled.div`
  flex-basis: 30%;
  display: flex;
  flex-direction: column;
  & > * {
    flex-basis: 33%;
  }
`;

const StyledImg = styled.img`
  width: 145px;
  height: 110px;

  object-fit: contain;
  background-color: #bdbdbd;
`;

const ImageWrapper = styled.div`
  opacity: 0.4;
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
  width: 145px;
  height: 110px;
  margin: 0px 0 0 20px;
`;

// const BottomContainer = styled.div`
//   flex-basis: 70%;
//   padding: 30px;
// `;

const Row = styled.div`
  height: 100%;
  display: flex;
`;

const Box = styled.div`
  height: 61px;
  min-height: 61px;
  border-bottom: 1px solid #d5d5d5;
  width: ${({width}) => width};
  background-color: ${({backgroundColor}) => backgroundColor};
  color: ${({color}) => color};
  align-items: center;
  display: flex;
  cursor: ${({onClick}) => onClick && 'pointer'};
  text-align: center;
`;

const Divider = styled.div`
  width: 50%;
  display: flex;
`;

const BtnContainer = styled.div`
  width: 1100px;
  margin: 20px 0 0 40px;
  display: flex;
  justify-content: space-between;
`;

const StyledMuiButton = styled(MuiButton)`
  &:hover {
    opacity: 0.9;
  }
  &:click {
  }
`;

const TaskDetailTemplate = ({
  imgOpened,
  task,
  forTaskDetail,
  store,
  handleImgIconClick,
  handleImgIconClose,
  handleEditBtnClick,
  handleDeleteBtnClick,
  images,
  handleListBtnClick,
  cat,
  setTaskImageModal,
  taskImageModal,
  setDeleteTaskModal,
  dispatch,
}) => {
  const {start, end, is_completed, repetition, task_type, name} = task;
  let week = [];

  forTaskDetail?.weeks?.forEach((days) => {
    if (days === 'MON') {
      week.push('월');
    }
    if (days === 'TUE') {
      week.push('화');
    }
    if (days === 'WED') {
      week.push('수');
    }
    if (days === 'THU') {
      week.push('목');
    }
    if (days === 'FRI') {
      week.push('금');
    }
    if (days === 'SAT') {
      week.push('토');
    }
    if (days === 'SUN') {
      week.push('일');
    }
  });

  const formattedRepetition = useMemo(
    () => {
      if (repetition === 1) return '매일 반복';
      if (repetition === 2) return `매주 ${week}요일`;
      if (repetition === 3) return '월 1회';
      if (repetition === 4) return `${forTaskDetail?.repeat_amount}일 반복`;
      if (repetition === 5)
        return `${forTaskDetail?.repeat_amount}주 간격 ${week}요일`;
      if (repetition === 6) return '주 1회';
      if (repetition === 0) return '반복 없음';
      return '';
    },
    [repetition],
    forTaskDetail?.repeat_amount,
  );

  const formattedTime = useMemo(
    () => `${getFormattedTime2(start)}~${getFormattedTime2(end)}`,
    [end, start],
  );

  const renderIcon = useCallback(() => {
    if (task_type === 'image')
      return is_completed ? (
        <div
          style={{margin: '0px 0 0 0', display: 'flex', alignItems: 'center'}}
        >
          <ImageSolidIcon fill={theme.colors.kalsPurple} />
          <Text margin="-1px 0 0 7px">보고 완료</Text>
        </div>
      ) : (
        <Text>보고 미완료</Text>
      );
    return is_completed ? (
      <div style={{margin: '0px 0 0 0', display: 'flex', alignItems: 'center'}}>
        <CheckCircleSolidIcon fill={theme.colors.kalsPurple} />
        <Text margin="-1px 0 0 7px">보고 완료</Text>
      </div>
    ) : (
      <Text>보고 미완료</Text>
    );
  }, [is_completed, task_type]);

  return (
    <Wrapper
      titleText={store?.store_name}
      infoText={`${store?.store_name}의 상세정보입니다.`}
    >
      <Drawer>
        <Container>
          <TopContainer>
            <Row>
              <Box width="180px" backgroundColor="#eff0fc" color="#252525">
                <Text
                  style={{
                    width: '100%',
                    textAlign: 'center',
                  }}
                >
                  업무명
                </Text>
              </Box>
              <Box width="920px">
                <Text
                  style={{
                    width: '100%',
                    textAlign: 'left',
                    margin: '0 0 0 20px',
                  }}
                >
                  {name}
                </Text>
              </Box>
            </Row>
            <Row>
              <Divider>
                <Box width="180px" backgroundColor="#eff0fc" color="#252525">
                  <Text
                    style={{
                      width: '100%',
                      textAlign: 'center',
                    }}
                  >
                    카테고리
                  </Text>
                </Box>
                <Box width="370px">
                  <Text
                    style={{
                      width: '100%',
                      textAlign: 'left',
                      margin: '0 0 0 20px',
                    }}
                  >
                    {cat}
                  </Text>
                </Box>
              </Divider>
              <Divider>
                <Box width="180px" backgroundColor="#eff0fc" color="#252525">
                  <Text
                    style={{
                      width: '100%',
                      textAlign: 'center',
                    }}
                  >
                    업무 수행 시간
                  </Text>
                </Box>
                <Box width="370px">
                  <Text
                    style={{
                      width: '100%',
                      textAlign: 'left',
                      margin: '0 0 0 20px',
                    }}
                  >
                    {formattedTime}
                  </Text>
                </Box>
              </Divider>
            </Row>
            <Row>
              <Divider>
                <Box width="180px" backgroundColor="#eff0fc" color="#252525">
                  <Text
                    style={{
                      width: '100%',
                      textAlign: 'center',
                    }}
                  >
                    업무 반복 주기
                  </Text>
                </Box>
                <Box width="370px">
                  <Text
                    style={{
                      width: '100%',
                      textAlign: 'left',
                      margin: '0 0 0 20px',
                    }}
                  >
                    {formattedRepetition}
                  </Text>
                </Box>
              </Divider>
              <Divider>
                <Box width="180px" backgroundColor="#eff0fc" color="#252525">
                  <Text
                    style={{
                      width: '100%',
                      textAlign: 'center',
                    }}
                  >
                    완료 현황
                  </Text>
                </Box>
                <Box
                  width="370px"
                  onClick={() => handleImgIconClick(task_type, is_completed)}
                >
                  <Text
                    style={{
                      width: '100%',
                      textAlign: 'left',
                      margin: '0 0 0 20px',
                    }}
                  >
                    {renderIcon()}
                  </Text>
                </Box>
              </Divider>
            </Row>
            <Row>
              <Box
                width="180px"
                backgroundColor="#eff0fc"
                color="#252525"
                style={{height: '122px'}}
              >
                <Text
                  style={{
                    width: '100%',
                    textAlign: 'center',
                  }}
                >
                  설명 이미지
                </Text>
              </Box>
              <Box width="920px" style={{height: '122px'}}>
                {forTaskDetail?.description_images.length > 0 ? (
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <ImageWrapper
                      style={{position: 'relative'}}
                      onClick={() => setTaskImageModal(true)}
                    >
                      <StyledImg src={forTaskDetail?.description_images[0]} />
                      <div
                        style={{
                          position: 'relative',
                          top: '-75px',
                          left: '47px',
                          width: '50px',
                          height: '50px',
                        }}
                      >
                        <ImageRegularIcon fill="black" length={40} />
                      </div>
                    </ImageWrapper>
                  </div>
                ) : (
                  <Text
                    style={{
                      fontSize: '13px',
                      color: '#bdbdbd',
                      width: '100%',
                      textAlign: 'left',
                      margin: '0 0 0 20px',
                    }}
                  >
                    등록된 이미지가 없습니다
                  </Text>
                )}
              </Box>
            </Row>
            <Row>
              <Box
                width="180px"
                backgroundColor="#eff0fc"
                color="#252525"
                style={{height: '425px'}}
              >
                <Text
                  style={{
                    width: '100%',
                    textAlign: 'center',
                  }}
                >
                  설명
                </Text>
              </Box>
              <Box width="920px" style={{height: '425px'}}>
                <Text
                  style={{
                    width: '100%',
                    textAlign: 'left',
                    margin: '0 0 0 20px',
                    overflowY: 'auto',
                    height: '95%',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {forTaskDetail?.description ? (
                    forTaskDetail?.description
                  ) : (
                    <div
                      style={{
                        alignItems: 'center',
                        display: 'flex',
                        height: '100%',
                      }}
                    >
                      <p
                        style={{
                          fontSize: '13px',
                          color: '#bdbdbd',
                        }}
                      >
                        작성된 내용이 없습니다
                      </p>
                    </div>
                  )}
                </Text>
              </Box>
            </Row>
          </TopContainer>

          {/* <BottomContainer>{forTaskDetail?.description}</BottomContainer> */}
        </Container>
        <TaskImageModal
          taskImageModal={taskImageModal}
          setTaskImageModal={setTaskImageModal}
          images={forTaskDetail?.description_images}
        />
        <BtnContainer>
          <div></div>
          <div style={{display: 'flex'}}>
            <div style={{marginBottom: '10px', marginRight: '10px'}}>
              <StyledMuiButton
                variant="contained"
                fontSize="18px"
                textColor="white"
                backgroundColor="#A4A4A4"
                onClick={() => handleListBtnClick()}
                text="취소"
                style={{fontWeight: '500', padding: '2px 12px 0 12px'}}
              />
            </div>
            <div style={{marginBottom: '10px', marginRight: '10px'}}>
              <StyledMuiButton
                variant="contained"
                fontSize="18px"
                textColor="white"
                backgroundColor="#FA5858"
                onClick={() => dispatch(setDeleteTaskModal(true))}
                text="삭제"
                style={{fontWeight: '500', padding: '2px 12px 0 12px'}}
              />
            </div>
            <div style={{marginBottom: '10px'}}>
              <StyledMuiButton
                variant="contained"
                fontSize="18px"
                textColor="white"
                backgroundColor={theme.colors.kalsPurple}
                onClick={() => handleEditBtnClick(task)}
                text="편집"
                style={{fontWeight: '500', padding: '2px 12px 0 12px'}}
              />
            </div>
          </div>
        </BtnContainer>
        {imgOpened && (
          <ImageTaskModal
            imageURL={forTaskDetail?.description_images}
            handleClose={handleImgIconClose}
            task={task}
            open={imgOpened}
            performer="someone"
            registeredDate="someday"
            images={images}
          />
        )}
        <TaskDeleteModal handleDeleteBtnClick={handleDeleteBtnClick} />
      </Drawer>
    </Wrapper>
  );
};

export default TaskDetailTemplate;
