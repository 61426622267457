import theme from 'shared/styles/theme';
import styled from 'styled-components';
import DailyPerformance from './DailyPerformance';
import WeeklyPerformance from './WeeklyPerformance';
import MonthlyPerformance from './MonthlyPerformance';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-left: 30px;
  z-index: 3000;
`;

const Button = styled.button`
  margin-right: 10px;
  border: none;
  border-bottom: ${({isActive}) =>
    isActive ? `2px solid ${theme.colors.primary}` : 'none'};
  background-color: transparent;
  font-size: 18px;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: ${theme.colors.secondaryLight};
    transform: scale(1.03);
  }
`;

const Box2 = styled.div`
  margin-left: 3px;
  padding: 15px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
`;

const JobPerformanceTemplate = ({
  dailyTaskStatus,
  activeTaskStat,
  handleTaskStatClick,
  lastWeekGraphData,
  thisWeekGraphData,
  thisMonthLineGraphData,
  isDateLoading,
}) => {
  return (
    <div>
      <ButtonContainer>
        <Button
          isActive={activeTaskStat === 1}
          onClick={() => handleTaskStatClick(1)}
          style={{fontSize: '15px'}}
        >
          일간
        </Button>
        <Button
          isActive={activeTaskStat === 2}
          onClick={() => handleTaskStatClick(2)}
          style={{fontSize: '15px'}}
        >
          주간
        </Button>
        <Button
          isActive={activeTaskStat === 3}
          onClick={() => handleTaskStatClick(3)}
          style={{fontSize: '15px'}}
        >
          월간
        </Button>
      </ButtonContainer>

      {isDateLoading !== true ? (
        <Box2>
          {activeTaskStat === 1 && (
            <DailyPerformance dailyTaskStatus={dailyTaskStatus} />
          )}

          {activeTaskStat === 2 && (
            <WeeklyPerformance
              lastWeekGraphData={lastWeekGraphData}
              thisWeekGraphData={thisWeekGraphData}
            />
          )}

          {activeTaskStat === 3 && (
            <MonthlyPerformance
              thisMonthLineGraphData={thisMonthLineGraphData}
            />
          )}
        </Box2>
      ) : (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '100px 0 0 0',
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default JobPerformanceTemplate;
