import styled from 'styled-components';
import theme from 'shared/styles/theme';

const StyledButton = styled.button`
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: ${props => (props.outlined ? `2px solid ${props.bgColor}` : 'none')};
  font-size: ${props => (props.fontSize ? props.fontSize : '14px')};
  position: relative;
  background-color: ${props => (props.outlined ? 'none' : props.bgColor)};
  color: ${props => (props.outlined ? props.bgColor : 'white')};
  cursor: pointer;
  user-select: none;
  text-align: center;
  text-decoration: none;
  transition-duration: 0.4s;
  margin: ${props => (props.margin ? props.margin : '0')};
  /* Safari */
  -webkit-transition-duration: 0.4s;

  &:hover {
    transition-duration: 0.1s;
    background-color: ${props =>
      props.hvColor ? props.hvColor : theme.colors.primaryDark};
    color: ${theme.colors.white};
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.5s;
    box-shadow: 0 0 5px 10px #8160ff;
    border-radius: 5px;
  }
  &:active:after {
    box-shadow: 0 0 0 0 white;
    position: absolute;
    border-radius: 5px;
    left: 0;
    top: 0;
    opacity: 1;
    transition: 0s;
  }
  &:active {
    top: 1px;
  }
`;

export default function SimpleButton({
  bgColor = theme.colors.primary,
  color = theme.colors.black,
  text = 'button',
  fontSize,
  onClick,
  margin,
  hvColor = '',
  ...otherProps
}) {
  return (
    <StyledButton
      margin={margin}
      color={color}
      bgColor={bgColor}
      onClick={onClick}
      fontSize={fontSize}
      hvColor={hvColor}
      {...otherProps}>
      {text}
    </StyledButton>
  );
}
