import React, {useEffect, useState, useCallback} from 'react';
import {Text} from 'shared/components/index';
import theme from 'shared/styles/theme';
import styled from 'styled-components';
import {VictoryPie, VictoryLabel} from 'victory';
import {useNavigate} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {selectStore} from 'owler/utils/slices/storeSlice';
import {
  setCompeleteByStoreModal,
  setCompeleteByStoreModalforImage,
} from 'owler/utils/slices/storeSlice';
import CompleteByStoreModalforImage from './CompleteByStoreModalforImage';
import CompleteByStoreModal from './CompleteByStoreModal';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 6px 0 2px 0;
  border-radius: 15px;
  padding: 10px;
`;

const ProgressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  margin: -20px 0 0 0;
`;

const ProgressBarContainer = styled.div`
  width: 270px;
  height: 40px;
  border: 1px solid #ccc;
  margin-top: 5px;
  border-radius: 5px;
  &:hover {
    cursor: pointer;
    background-color: #f2f2f2;
    transform: scale(1.05);
  }
`;

const ProgressBar = styled.div`
  height: 100%;
  width: ${({progress}) => progress}%;
  background-color: ${theme.colors.kalsPurple};
  transition: width 2s ease-in-out, border-radius 1s ease-in-out;
  border-radius: ${({progress}) => (progress < 5 ? '5px' : '3px')};
  position: relative;

  z-index: -1;
`;

const SubtextContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  top: -47px;

  justify-content: space-between;
  width: 240px;
  margin-top: 7px;
  margin-left: 7px;
`;

const Percentage = styled.span`
  color: #1c1c1c;
  font-size: 15px;
  font-weight: 500;
`;

const Fraction = styled.span`
  color: #2e2e2e;
  font-size: 15px;
  font-weight: 500;
`;

const Wrapper = styled.div`
  width: 100%;

  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const StyledProgressBarWrap = styled.div`
  width: 210px;
  height: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const StyledProgressBarWrap2 = styled.div`
  width: 150px;
  height: 215px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 3px 3px 3px 0px #6e6e6e;
  margin: 0 20px 0 0;
  flex-direction: column;
  border-radius: 15px;
  &:hover {
    cursor: pointer;
    transform: scale(1.01);
  }
`;
const ShadowFilter = () => (
  <defs>
    <filter id="shadow" x="-50%" y="-50%" width="200%" height="200%">
      <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
      <feOffset dx="0" dy="5" result="offsetblur" />
      <feComponentTransfer>
        <feFuncA type="linear" slope="0.3" />
      </feComponentTransfer>
      <feMerge>
        <feMergeNode />
        <feMergeNode in="SourceGraphic" />
      </feMerge>
    </filter>
  </defs>
);
const AnimatedNumProgressBar = React.memo(({progress}) => (
  <ProgressBar style={{width: `${progress}%`}} />
));

const SProgressBar = ({text, progress, currentStep, totalSteps, nonClick}) => {
  const navigate = useNavigate();
  const {currentStore} = useSelector(selectStore);
  const dispatch = useDispatch();

  // useCallback으로 함수를 메모이제이션
  const handleClick = useCallback(() => {
    if (nonClick) {
      if (text === '체크 보고') {
        dispatch(setCompeleteByStoreModal(true));
      }
      if (text === '사진 보고' || text === '메모 보고') {
        dispatch(setCompeleteByStoreModalforImage(true));
      }

      return;
    }
    navigate(`/owler/tasks/${currentStore.store_uid}`);
  }, [navigate, currentStore]);

  // 애니메이션 state
  const [animatedNumProgress, setAnimatedNumProgress] = useState(0);
  const [realAnimatedProgress, setRealAnimatedProgress] = useState(0);

  useEffect(() => {
    let animationFrameId = null;
    const start = performance.now();

    const animate = (time) => {
      const elapsedTime = time - start;
      const progressPercent = Math.min((elapsedTime / 2000) * 100, progress);
      setAnimatedNumProgress(progressPercent);
      setRealAnimatedProgress(progress);
      if (progressPercent < progress) {
        animationFrameId = requestAnimationFrame(animate);
      }
    };

    animationFrameId = requestAnimationFrame(animate);

    // Cleanup
    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [progress]);

  return (
    <Container onClick={handleClick}>
      <ProgressBarContainer>
        <AnimatedNumProgressBar progress={realAnimatedProgress} />
        <SubtextContainer>
          <div>
            <Text fontSize="medium" bold>
              {text}
            </Text>
            &nbsp;&nbsp;
            <Percentage>{Math.round(animatedNumProgress)}%</Percentage>
          </div>
          <div>
            <Fraction>
              {currentStep}/{totalSteps}&nbsp;&gt;
            </Fraction>
          </div>
        </SubtextContainer>
      </ProgressBarContainer>
    </Container>
  );
};

const StyledVictoryPie = ({total_task_rate, getData, currentStore, size}) => {
  const [percentageData, setPercentageData] = useState(getData(0));
  const [animatedPercent, setAnimatedPercent] = useState(0);

  useEffect(() => {
    setAnimatedPercent(0);
    setPercentageData(getData(0));
    let currentProgress = 0;
    const targetProgress = total_task_rate || 0;

    const animationTime = 1300; // 2 seconds in milliseconds
    const intervalTime = 10; // 10 milliseconds for each interval

    const totalIntervals = animationTime / intervalTime;
    const incrementValue = (targetProgress - currentProgress) / totalIntervals;

    if (targetProgress !== 0) {
      const interval = setInterval(() => {
        currentProgress += incrementValue;
        setAnimatedPercent(currentProgress);
        setPercentageData(getData(currentProgress));
        if (currentProgress >= targetProgress) {
          clearInterval(interval);
        }
      }, intervalTime);

      return () => clearInterval(interval);
    } else {
      setAnimatedPercent(0);
    }
  }, [currentStore, total_task_rate]);

  return (
    <>
      <VictoryPie
        standalone={false}
        width={size?.width}
        height={size?.height}
        data={percentageData}
        innerRadius={78}
        cornerRadius={10}
        labels={() => null}
        style={{
          data: {
            fill: ({datum}) => (datum.x === 1 ? '#9D86FD' : '#ccc'),
            filter: 'url(#shadow)',
          },
        }}
      />
      {total_task_rate !== undefined && (
        <>
          <VictoryLabel
            textAnchor="middle"
            verticalAnchor="middle"
            x={size?.x}
            y={size?.y}
            text={`${
              isNaN(Math.round(animatedPercent))
                ? 0
                : Math.round(animatedPercent)
            }%`}
            style={{fontSize: 50, fill: '#9D86FD'}}
          />
        </>
      )}
    </>
  );
};

const DailyPerformance = ({dailyTaskStatus}) => {
  const {currentStore, checkTasks, imageTasks} = useSelector(selectStore);

  function getData(percent) {
    return [
      {x: 1, y: percent},
      {x: 2, y: 100 - percent},
    ];
  }

  return (
    <Wrapper>
      {currentStore.is_hq === false ? (
        <>
          <StyledProgressBarWrap>
            <svg viewBox="40 40 250 250">
              <ShadowFilter />
              <StyledVictoryPie
                currentStore={currentStore}
                getData={getData}
                total_task_rate={dailyTaskStatus?.total_task_rate}
                size={{width: 320, height: 320, x: 160, y: 160}}
              />
            </svg>
          </StyledProgressBarWrap>

          <ProgressWrapper>
            <SProgressBar
              text="체크 보고"
              progress={dailyTaskStatus?.check_task_progress?.rate}
              currentStep={
                dailyTaskStatus?.check_task_progress?.completed_count
              }
              totalSteps={dailyTaskStatus?.check_task_progress?.total_count}
            />
            <SProgressBar
              text="사진 보고"
              progress={dailyTaskStatus?.image_task_progress?.rate}
              currentStep={
                dailyTaskStatus?.image_task_progress?.completed_count
              }
              totalSteps={dailyTaskStatus?.image_task_progress?.total_count}
            />
            <SProgressBar
              text="메모 보고"
              progress={dailyTaskStatus?.memo_task_progress?.rate}
              currentStep={dailyTaskStatus?.memo_task_progress?.completed_count}
              totalSteps={dailyTaskStatus?.memo_task_progress?.total_count}
            />
          </ProgressWrapper>
        </>
      ) : (
        <>
          <StyledProgressBarWrap>
            <svg viewBox="40 40 250 250">
              <ShadowFilter />
              <StyledVictoryPie
                currentStore={currentStore}
                getData={getData}
                total_task_rate={dailyTaskStatus?.total_task_rate}
                size={{width: 320, height: 320, x: 160, y: 160}}
              />
            </svg>
          </StyledProgressBarWrap>

          <ProgressWrapper>
            <SProgressBar
              text="체크 보고"
              progress={dailyTaskStatus?.check_task_progress?.rate}
              currentStep={
                dailyTaskStatus?.check_task_progress?.completed_count
              }
              totalSteps={dailyTaskStatus?.check_task_progress?.total_count}
              nonClick
            />
            <SProgressBar
              //onClick={() => dispatch(setCompeleteByStoreModalforImage(true))}
              text="사진 보고"
              progress={dailyTaskStatus?.image_task_progress?.rate}
              currentStep={
                dailyTaskStatus?.image_task_progress?.completed_count
              }
              totalSteps={dailyTaskStatus?.image_task_progress?.total_count}
              nonClick
            />
            <SProgressBar
              //onClick={() => dispatch(setCompeleteByStoreModalforImage(true))}
              text="메모 보고"
              progress={dailyTaskStatus?.image_task_progress?.rate}
              currentStep={
                dailyTaskStatus?.image_task_progress?.completed_count
              }
              nonClick
              totalSteps={dailyTaskStatus?.image_task_progress?.total_count}
            />
          </ProgressWrapper>

          {/* <StyledProgressBarWrap2
            onClick={() => dispatch(setCompeleteByStoreModal(true))}
          >
            <svg viewBox="40 40 200 220">
              <ShadowFilter />
              <StyledVictoryPie
                currentStore={currentStore}
                getData={getData}
                total_task_rate={dailyTaskStatus?.check_task_progress?.rate}
                size={{width: 280, height: 280, x: 140, y: 140}}
              />
            </svg>
            <Text bold style={{fontSize: '15px'}}>
              체크 보고
            </Text>
          </StyledProgressBarWrap2>

          <StyledProgressBarWrap2
            onClick={() => dispatch(setCompeleteByStoreModalforImage(true))}
          >
            <svg viewBox="40 40 200 220">
              <ShadowFilter />
              <StyledVictoryPie
                currentStore={currentStore}
                getData={getData}
                total_task_rate={dailyTaskStatus?.image_task_progress?.rate}
                size={{width: 280, height: 280, x: 140, y: 140}}
              />
            </svg>
            <Text bold style={{fontSize: '15px'}}>
              사진 보고
            </Text>
          </StyledProgressBarWrap2> */}
          <CompleteByStoreModal checkTasks={checkTasks} />
          <CompleteByStoreModalforImage imageTasks={imageTasks} />
        </>
      )}
    </Wrapper>
  );
};

export default DailyPerformance;
