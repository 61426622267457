import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {selectStore} from 'owler/utils/slices/storeSlice';
import {
  getNotificationsThunk,
  selectNotice,
  setNotificationModal,
  getNotificationsDetailThunk,
  setNotificationDetail,
} from 'owler/utils/slices/noticeSlice';
import {setSelectedDateT} from 'owler/utils/slices/taskSlice';
import NotificationsTemplate from './template/index';
import {getUnreadNotificationsCountAsync} from 'owler/utils/slices/userSlice';
const Notifications = () => {
  const dispatch = useDispatch();
  const {currentStore} = useSelector(selectStore);
  const {member_uid} = useSelector(selectNotice);
  const [currentPage, setCurrentPage] = useState(1);
  const [notiPageCount, setNotiPageCount] = useState(null);

  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    dispatch(setSelectedDateT(new Date()));
    if (member_uid !== null) {
      setLoading(true);
      dispatch(
        getNotificationsThunk({
          member_uid: member_uid.member_uid,
          page: currentPage,
        }),
      )
        .unwrap()
        .then((payload) => {
          setNotiPageCount(payload?.count);
          setNotifications(payload?.results);
          setLoading(false);
        })
        .catch((err) => alert(err.message));
    }
  }, [dispatch, member_uid, currentPage]);

  const count = () => {
    return notiPageCount !== null ? Math.ceil(notiPageCount / 10) : 1;
  };

  const handlePaginationChange = (e, p) => {
    setCurrentPage(p);
  };

  const handleNoticeClick = useCallback(
    (notification) => {
      dispatch(setNotificationModal(true));

      dispatch(
        getNotificationsDetailThunk({
          notification_uid: notification.notification_uid,
        }),
      )
        .unwrap()
        .then((payload) => {
          dispatch(setNotificationDetail(payload));
          if (notification.is_view === false) {
            dispatch(
              getUnreadNotificationsCountAsync({
                store_uid: currentStore?.store_uid,
              }),
            );

            dispatch(
              getNotificationsThunk({
                member_uid: member_uid.member_uid,
                page: currentPage,
              }),
            )
              .unwrap()
              .then((payload) => {
                setNotiPageCount(payload?.count);
                setNotifications(payload?.results);
                setLoading(false);
              })
              .catch((err) => alert(err.message));
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    [dispatch, currentPage, currentStore, member_uid],
  );

  return (
    <NotificationsTemplate
      loading={loading}
      currentStore={currentStore}
      notifications={notifications}
      handleNoticeClick={handleNoticeClick}
      count={count}
      handlePaginationChange={handlePaginationChange}
    />
  );
};

export default Notifications;
