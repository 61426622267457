import styled from 'styled-components';
import {Toaster} from 'react-hot-toast';

import {
  Text,
  FranchiseDrawer,
  FranchiseWrapper,
  TableRow,
  TableDataGroup,
  TableData,
  Button,
} from 'shared/components/index';

const QscBtnContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  margin-right: 5px;
  padding: 2px 5px;
`;
const Container = styled.div`
  margin-left: 60px;
`;

const StoreJoinRequestTemplate = ({
  svJoinRequestList,
  handleAcceptJoinRequest,
  handleRejectJoinRequest,
}) => {
  return (
    <FranchiseWrapper titleText="Settings">
      <FranchiseDrawer>
        <Container>
          <Text bold fontSize="large">
            신청 리스트
          </Text>

          {svJoinRequestList?.map((request) => (
            <TableRow width="800PX;" key={request?.in_charge_store_uid} border>
              <TableDataGroup justifyContent="center">
                <TableData>
                  <Text anchor>{request.supervisor}</Text>
                </TableData>
              </TableDataGroup>

              <TableDataGroup justifyContent="center">
                <TableData>
                  <Text anchor>{request.position_name}</Text>
                </TableData>
              </TableDataGroup>

              <TableDataGroup justifyContent="center">
                <TableData>
                  <Text anchor>{request.store_name}</Text>
                </TableData>
              </TableDataGroup>

              <TableDataGroup justifyContent="flex-end">
                <TableData>
                  <Button
                    size="sm"
                    color="#6650c0"
                    onClick={() => {
                      handleAcceptJoinRequest(request);
                    }}
                  >
                    <QscBtnContent>승인</QscBtnContent>
                  </Button>
                </TableData>

                <TableData>
                  <Button
                    size="sm"
                    color="#6650c0"
                    onClick={() => {
                      handleRejectJoinRequest(request);
                    }}
                  >
                    <QscBtnContent>거절</QscBtnContent>
                  </Button>
                </TableData>
              </TableDataGroup>
            </TableRow>
          ))}
        </Container>
      </FranchiseDrawer>
    </FranchiseWrapper>
  );
};

export default StoreJoinRequestTemplate;
