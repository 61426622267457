import React from 'react';
import styled from 'styled-components';

// Define your styled components outside of the ChatRoomImage component
const ImageContainer = styled.div`
  height: 46px;
  width: 46px;
  border-radius: 23px; /* Half of the width/height to make it round */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`;

const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%; /* A percentage makes it always round */
`;

const InitialsText = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #ffffff; /* Replace with your colors.white from your config */
`;

function ChatRoomImage({chatData, name = 'title', url_type = 'image'}) {
  const chatRoomName = chatData?.[name] || '';

  const firstCharacterCode = chatRoomName.codePointAt(0);
  const firstCharacter = firstCharacterCode
    ? String.fromCodePoint(firstCharacterCode)
    : '';
  const initials = firstCharacter || ''; // If firstCharacter is falsy, set initials to an empty string

  const colorsArray = [
    '#C70039', // red
    '#FF5733', // orange
    // ... the rest of your colors
  ];

  const selectedColor =
    colorsArray[chatRoomName.charCodeAt(0) % colorsArray.length];

  return (
    <ImageContainer
      style={{
        backgroundColor: chatData?.[url_type] ? 'transparent' : selectedColor,
      }}
    >
      {chatData?.[url_type] ? (
        <ProfileImage src={chatData?.[url_type]} alt={chatRoomName} />
      ) : (
        <InitialsText>{initials}</InitialsText>
      )}
    </ImageContainer>
  );
}

export default ChatRoomImage;
