import styled from 'styled-components';
import theme from 'shared/styles/theme';
import {forwardRef} from 'react';

const StyledSelect = styled.select`
  width: ${({width}) => (width ? `${width}` : '200px')};
  border: 1px solid ${theme.colors.grey9};
  border-radius: 4px;
  height: ${({height}) => (height ? `${height}` : '30px')};
  padding: 0 3px;
`;

const Select2 = forwardRef(({width, style, height, children, ...rest}, ref) => {
  return (
    <StyledSelect
      ref={ref}
      width={width}
      style={style}
      height={height}
      {...rest}
    >
      {children}
    </StyledSelect>
  );
});

Select2.displayName = 'Select';

export default Select2;
