import {useEffect, useState} from 'react';
import styled from 'styled-components';
import theme from 'shared/styles/theme';
import {Text} from 'shared/components/index';
import Button from '@mui/material/Button';
import {useSelector, useDispatch} from 'react-redux';
import {
  selectPosition,
  setPositionDetailModalOpen,
  setQuestionModalOpen,
} from 'owler/utils/slices/positionSlice';
import PositionDetailModal from './PositionDetailModal';
import MemberPositionChangeModal from './MemberPositionChangeModal';
const Container = styled.div`
  width: 95%;
  margin-left: 2.5%;
  margin-top: 10px;
`;
const PositionName = styled.p`
  padding-left: 5px;
  border-bottom: 1px solid ${theme.colors.grey9};
`;
const PositionBox = styled.div`
  background-color: #f2f2f2;
  padding: 10px 20px;
  border-radius: 10px;
  margin: 10px 0;
`;
const MemberBox = styled.div`
  display: flex;
  overflow: auto;
  margin-left: -9px;
`;
const Members = styled.div`
  min-width: 80px;
  width: 80px;
  display: flex;
  flex-direction: column;
  margin: 5px 10px;
  padding: 10px 10px;
  background-color: #d8d8d8;
  border-radius: 25px;
  word-break: keep-all;
  &:hover {
    cursor: pointer;
  }
  text-align: center;
`;
const Img = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 40px;
  object-fit: cover;
  background-color: white;
  margin-bottom: 5px;
`;
const PositionManagement = ({modifyMember, fireMember}) => {
  const {allMembers} = useSelector(selectPosition);
  const [selected, setSelected] = useState(null);
  const dispatch = useDispatch();

  return (
    <Container>
      {allMembers.map((position, index) => {
        return (
          <PositionBox key={index}>
            <PositionName>{position.position_name}</PositionName>
            <MemberBox>
              {position?.members_list?.map((member, index) => {
                return (
                  <Members
                    key={index}
                    onClick={() => {
                      setSelected({
                        position: position.position_name,
                        member: member,
                      });
                      dispatch(setPositionDetailModalOpen(true));
                    }}
                  >
                    <Img
                      src={
                        member.image !== null ? member.image : '/empty_user.png'
                      }
                    />
                    <Text bold style={{fontSize: '12px'}}>
                      {member.name}
                    </Text>
                  </Members>
                );
              })}
            </MemberBox>
          </PositionBox>
        );
      })}
      <MemberPositionChangeModal
        modifyMember={modifyMember}
        selected={selected}
      />
      <PositionDetailModal selected={selected} fireMember={fireMember} />
    </Container>
  );
};

export default PositionManagement;
