/* eslint-disable no-console */
const catchError = async (promise, ...params) => {
  try {
    const res = await promise(...params);

    return res;
  } catch (error) {
    let errorMessage;

    if (error.response) {
      console.error(
        '%c error response',
        'background-color:red;font-weight:bold;color:white;font-size:15px;',
      );

      const {detail, message, KR} = error.response?.data;
      errorMessage = KR || detail || message;
      if (error.response.status === 500)
        errorMessage = 'Internal server error 500';
    } else if (error.request) {
      // 요청이 이루어 졌으나 응답을 받지 못했습니다.
      // `error.request`는 브라우저의 XMLHttpRequest 인스턴스 또는
      // Node.js의 http.ClientRequest 인스턴스입니다.
      console.error(error.request);
    } else {
      errorMessage = error.message;
    }
    if (!errorMessage) errorMessage = '뭔지모를 에러';
    throw new Error(errorMessage);
  }
};

export {catchError};
