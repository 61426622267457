import React from 'react';
import styled, {css} from 'styled-components';

const imgSize = {
  SMALL: 'sm',
  LARGE: 'lg',
};

const logoShape = {
  ROUND: 'round',
};

const ImageWrapper = styled.div`
  border: 1px solid #707070;
  background-color: white;
  ${({size}) => {
    switch (size) {
      case imgSize.SMALL:
        return css`
          width: 34px;
          height: 34px;
        `;
      case imgSize.LARGE:
        return css`
          width: 260px;
          height: 300px;
        `;
      default:
        return css`
          width: 52px;
          height: 52px;
        `;
    }
  }}

  ${({shape}) => {
    switch (shape) {
      case logoShape.ROUND:
        return css`
          overflow: hidden;
          border-radius: 50%;
        `;
      default:
        return css`
          border-radius: 0px;
        `;
    }
  }}
  & .img {
    width: 100%;
    height: 100%;
  }
`;

const Image = props => {
  const {size, src, shape} = props;
  return (
    <ImageWrapper size={size} shape={shape}>
      <img className="img" src={src} alt="img" />
    </ImageWrapper>
  );
};

export default Image;
