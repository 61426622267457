import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import {
  MuiModal,
  Text,
  TableRow,
  TableDataGroup,
  TableData,
} from 'shared/components/index';

import {
  selectFranchiseUser,
  setChangeSvPositionModal,
} from 'franchise/utils/slices/frUserSlice';

import theme from 'shared/styles/theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 40px;
  align-items: center;
  overflow: hidden;
  border-radius: 20px;
`;

const TitleWrapper = styled.div`
  width: 100%;
`;

const ChangePositionModal = ({haddleChangeSvPermission}) => {
  const {changeSvPositionModal} = useSelector(selectFranchiseUser);
  const dispatch = useDispatch();

  const handleModalClose = () => {
    dispatch(setChangeSvPositionModal(false));
  };

  const handleSvPositionClick = (is_admin) => {
    haddleChangeSvPermission(is_admin);
    dispatch(setChangeSvPositionModal(false));
  };

  return (
    <MuiModal open={changeSvPositionModal} onClose={handleModalClose}>
      <Container>
        <TitleWrapper>
          <Text bold fontSize="xl">
            관리 권한 변경
          </Text>
        </TitleWrapper>

        <TableRow
          width="250px"
          bgColor={theme.colors.kalsPurple}
          onClick={() => handleSvPositionClick(true)}
          border
        >
          <TableDataGroup justifyContent="center">
            <TableData>
              <Text color="white" anchor>
                관리자
              </Text>
            </TableData>
          </TableDataGroup>
        </TableRow>

        <TableRow
          width="250px"
          bgColor={theme.colors.grey3}
          onClick={() => handleSvPositionClick(false)}
          border
        >
          <TableDataGroup justifyContent="center">
            <TableData>
              <Text color="white" anchor>
                일반
              </Text>
            </TableData>
          </TableDataGroup>
        </TableRow>
      </Container>
    </MuiModal>
  );
};
export default ChangePositionModal;
