import React, {useCallback, useEffect, useState} from 'react';

import DashboardTemplate from './template/index';
import {getWeeksInMonth} from 'shared/utils/time';
import {useDispatch, useSelector} from 'react-redux';
import {
  getSvUnreadNotificationsCountAsync,
  selectFranchiseUser,
  getSVUserInfo,
} from 'franchise/utils/slices/frUserSlice';
import {
  getMonthlyManagementStoreScoreAsync,
  getSvTodaysScheduleAsync,
  getWeeklyManagementStoreScoreAsync,
  // getWeeklyVisitStoreStatusAsync,
  getFranchiseNameAsync,
} from 'franchise/utils/slices/frStoreSlice';
import {useNavigate} from 'react-router-dom';
import {getSvQSCStatusAsync} from 'franchise/utils/slices/frQscSlice';
import {setQSCUID} from 'franchise/utils/slices/frQscSlice';
import {getSupplementationDashboardAsync} from 'franchise/utils/slices/frSupplementionSlice';
const koreanWeekNames = {
  SUN: '일',
  MON: '월',
  TUE: '화',
  WED: '수',
  THU: '목',
  FRI: '금',
  SAT: '토',
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const {supervisorData} = useSelector(selectFranchiseUser);

  const [svQSCStatus, setSvQSCStatus] = useState(null);

  const [svTodaysSchedules, setSvTodaysSchedules] = useState([]);

  const [activeQSCStat, setQSCActiveTaskStat] = useState(1);

  const [lastWeekScoreGraphData, setLastWeekScoreGraphData] = useState([]);
  const [thisWeekScoreGraphData, setThisWeekScoreGraphData] = useState([]);

  const [supplementationList, setSupplementationList] = useState([]);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [imageModal, setImageModal] = useState(false);

  // const [lastWeekSvVisitData, setLastWeekSvVisitData] = useState([]);
  // const [thisWeekSvVisitData, setThisWeekSvVisitData] = useState([]);

  const [thisMonthScoreGraphData, setThisMonthScoreGraphData] = useState(null);

  const [minMaxScores, setMinMaxScores] = useState(null);

  const [displayDateWeekly, setDisplayDateWeekly] = useState(
    `${new Date().getFullYear()}-${new Date().getMonth() + 1}`,
  );

  const [weekStateDate, setWeekStateDate] = useState(new Date());
  const [clickCount, setClickCount] = useState(1);

  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const navigate = useNavigate();

  useEffect(() => {
    const getWeekOfMonth = (date) => {
      const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
      const firstDayOfWeek = firstDayOfMonth.getDay();
      const offsetDate = date.getDate() + firstDayOfWeek;
      return Math.floor((offsetDate - 1) / 7) + 1;
    };

    const today = new Date();
    const currentWeek = getWeekOfMonth(today);

    setClickCount(currentWeek);
  }, []);

  useEffect(() => {
    dispatch(getSVUserInfo());
    setIsImageLoading(true);
    dispatch(getFranchiseNameAsync());
    dispatch(getSvUnreadNotificationsCountAsync({sv_user_uid: supervisorData}));

    dispatch(getSvQSCStatusAsync({sv_user_uid: supervisorData}))
      .unwrap()
      .then((payload) => {
        setSvQSCStatus(payload);
      });

    dispatch(getSvTodaysScheduleAsync({sv_user_uid: supervisorData}))
      .unwrap()
      .then((payload) => {
        setSvTodaysSchedules(payload);
      });

    dispatch(getSupplementationDashboardAsync())
      .unwrap()
      .then((payload) => {
        setSupplementationList(payload);
      })
      .catch((err) => {
        alert(err.message);
      })
      .finally(() => {
        setIsImageLoading(false);
      });
  }, [dispatch, supervisorData]);

  // call weekly ai tasks
  useEffect(() => {
    dispatch(
      getWeeklyManagementStoreScoreAsync({
        sv_user_uid: supervisorData,
        year: weekStateDate.getFullYear(),
        month: weekStateDate.getMonth() + 1,
        week: clickCount,
      }),
    )
      .unwrap()
      .then((payload) => {
        const convertData = (weekData) => {
          return Object.entries(weekData).map(([day, value]) => ({
            x: koreanWeekNames[day],
            y: value,
          }));
        };
        setMinMaxScores({
          max_score_store_name: payload.max_score_store_name,
          min_score_store_name: payload.min_score_store_name,
        });
        if (payload !== null) {
          if (Object.keys(payload?.last_week).length > 0) {
            setLastWeekScoreGraphData(convertData(payload?.last_week));
          } else {
            setLastWeekScoreGraphData([]);
          }
          if (Object.keys(payload?.current_week).length > 0) {
            setThisWeekScoreGraphData(convertData(payload?.current_week));
          } else {
            setThisWeekScoreGraphData([]);
          }
        }
      });

    // dispatch(
    //   getWeeklyVisitStoreStatusAsync({
    //     sv_user_uid: supervisorData,
    //     year: weekStateDate.getFullYear(),
    //     month: weekStateDate.getMonth() + 1,
    //     week: clickCount,
    //   }),
    // )
    //   .unwrap()
    //   .then((payload) => {
    //     const convertData = (weekData) => {
    //       return Object.entries(weekData).map(([day, value]) => ({
    //         x: koreanWeekNames[day],
    //         y: value,
    //       }));
    //     };
    //     if (payload !== null) {
    //       setLastWeekSvVisitData(convertData(payload?.last_week));
    //       setThisWeekSvVisitData(convertData(payload?.current_week));
    //     }
    //   });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weekStateDate, clickCount]);

  useEffect(() => {
    dispatch(
      getMonthlyManagementStoreScoreAsync({
        sv_user_uid: supervisorData,
        year: selectedYear,
      }),
    )
      .unwrap()
      .then((payload) => {
        setThisMonthScoreGraphData(payload?.monthly_score);
        setMinMaxScores({
          max_score_store_name: payload.max_score_store_name,
          min_score_store_name: payload.min_score_store_name,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear]);

  const handleQSCStatClick = (statNumber) => {
    setQSCActiveTaskStat(statNumber);

    if (statNumber === 1) {
      dispatch(
        getWeeklyManagementStoreScoreAsync({
          sv_user_uid: supervisorData,
          year: weekStateDate.getFullYear(),
          month: weekStateDate.getMonth() + 1,
          week: clickCount,
        }),
      )
        .unwrap()
        .then((payload) => {
          const convertData = (weekData) => {
            return Object.entries(weekData).map(([day, value]) => ({
              x: koreanWeekNames[day],
              y: value,
            }));
          };
          setMinMaxScores({
            max_score_store_name: payload.max_score_store_name,
            min_score_store_name: payload.min_score_store_name,
          });
          if (payload !== null) {
            if (Object.keys(payload?.last_week).length > 0) {
              setLastWeekScoreGraphData(convertData(payload?.last_week));
            } else {
              setLastWeekScoreGraphData([]);
            }
            if (Object.keys(payload?.current_week).length > 0) {
              setThisWeekScoreGraphData(convertData(payload?.current_week));
            } else {
              setThisWeekScoreGraphData([]);
            }
          }
        });
    } else if (statNumber === 2) {
      dispatch(
        getMonthlyManagementStoreScoreAsync({
          sv_user_uid: supervisorData,
          year: selectedYear,
        }),
      )
        .unwrap()
        .then((payload) => {
          setThisMonthScoreGraphData(payload?.monthly_score);
          setMinMaxScores({
            max_score_store_name: payload.max_score_store_name,
            min_score_store_name: payload.min_score_store_name,
          });
        });
    }
  };

  const handleYearChange = (increment) => {
    const newYear = selectedYear + increment;
    setSelectedYear(newYear);
  };

  // updating week & month
  const handleChangeWeekBack = () => {
    setClickCount((old) => old - 1);

    if (clickCount === 1) {
      setWeekStateDate((oldDate) => {
        const newDate = new Date(oldDate); // Create a new Date object based on oldDate
        newDate.setMonth(newDate.getMonth() - 1);

        setDisplayDateWeekly(
          `${newDate.getFullYear()}-${newDate.getMonth() + 1}`,
        );

        setClickCount(
          getWeeksInMonth(newDate.getFullYear(), newDate.getMonth()),
        );

        return newDate;
      });
    }
  };

  const handleChangeWeekForward = () => {
    setClickCount((old) => {
      const newClickCount = old + 1;
      if (
        newClickCount >
        getWeeksInMonth(weekStateDate.getFullYear(), weekStateDate.getMonth())
      ) {
        setWeekStateDate((oldDate) => {
          const newDate = new Date(oldDate); // Create a new Date object based on oldDate
          newDate.setMonth(newDate.getMonth() + 1);

          setDisplayDateWeekly(
            `${newDate.getFullYear()}-${newDate.getMonth() + 1}`,
          );

          return newDate;
        });
        return 1; // Reset clickCount to 1
      }
      return newClickCount; // Otherwise, increment clickCount
    });
  };

  const handleSvTodaysScheduleBtn = useCallback((_schedule) => {}, []);

  const handleNoteCreateBtn = useCallback(
    async (_qsc) => {
      dispatch(setQSCUID(_qsc.qsc_uid));
      navigate(`/franchise/qscCreateNote/${_qsc.qsc_uid}`);
    },
    [dispatch, navigate],
  );

  return (
    <DashboardTemplate
      svQSCStatus={svQSCStatus}
      handleNoteCreateBtn={handleNoteCreateBtn}
      //
      svTodaysSchedules={svTodaysSchedules}
      //
      handleSvTodaysScheduleBtn={handleSvTodaysScheduleBtn}
      //
      activeQSCStat={activeQSCStat}
      handleQSCStatClick={handleQSCStatClick}
      //
      lastWeekScoreGraphData={lastWeekScoreGraphData}
      thisWeekScoreGraphData={thisWeekScoreGraphData}
      //
      thisMonthScoreGraphData={thisMonthScoreGraphData}
      //
      minMaxScores={minMaxScores}
      //
      // lastWeekSvVisitData={lastWeekSvVisitData}
      // thisWeekSvVisitData={thisWeekSvVisitData}
      //
      handleChangeWeekBack={handleChangeWeekBack}
      handleChangeWeekForward={handleChangeWeekForward}
      displayDateWeekly={displayDateWeekly}
      clickCount={clickCount}
      //
      selectedYear={selectedYear}
      handleYearChange={handleYearChange}
      supplementationList={supplementationList}
      isImageLoading={isImageLoading}
      //
      imageModal={imageModal}
      setImageModal={setImageModal}
    />
  );
};

export default Dashboard;
