/* eslint-disable camelcase */
import React, {useState, useCallback, useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import QSCTemplate from './template';
import {
  getQSCProcessListByPageThunk,
  selectFranchiseQsc,
  setMasterQSCProcessList,
  setQSCPageCount,
  setQSCUID,
  setQSCProcess,
  setCurrentQSCPage,
} from 'franchise/utils/slices/frQscSlice';

const QSC = () => {
  const {
    QSCPageCount,
    currentQSCPage,
    masterQscProcessList,
    svUserPositionsList,
  } = useSelector(selectFranchiseQsc);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const defaultSelectedValue = params.get('selected') || '1';

  const [selectedValue, setSelectedValue] = useState(defaultSelectedValue);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleQSCProcessClick = useCallback(
    (_qscProcess) => {
      const {qsc_uid} = _qscProcess;
      navigate(`/franchise/qscProcess/${qsc_uid}`);
      dispatch(setQSCUID(qsc_uid));
      dispatch(setQSCProcess(_qscProcess));
    },
    [dispatch, navigate],
  );

  // on first load:
  useEffect(() => {
    let startDate = new Date();
    const year = startDate.getFullYear();
    const month = String(startDate.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더함
    const day = String(startDate.getDate()).padStart(2, '0');

    startDate = `${year}-${month}-${day}`;
    dispatch(
      getQSCProcessListByPageThunk({
        status: 1,
        page: currentQSCPage,
        sort: selectedValue,
        date: startDate,
      }),
    )
      .unwrap()
      .then((payload) => {
        dispatch(setMasterQSCProcessList(payload?.results));
        dispatch(setQSCPageCount(payload?.count));
      })
      .catch((err) => {
        alert(err.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQSCPage, selectedValue]);

  const handleNoteCreateBtn = useCallback(
    async (_qsc) => {
      dispatch(setQSCUID(_qsc.qsc_uid));
      navigate(`/franchise/qscCreateNote/${_qsc.qsc_uid}`);
    },
    [dispatch, navigate],
  );

  const handleViewQscDetail = useCallback(
    (_qscDetail) => {
      const {qsc_uid} = _qscDetail;
      dispatch(setQSCUID(qsc_uid));
      navigate(`/franchise/qscDetail/${qsc_uid}`);
    },
    [dispatch, navigate],
  );

  const count = () => {
    return QSCPageCount !== null ? Math.ceil(QSCPageCount / 10) : 1;
  };

  const handlePaginationChange = (e, p) => {
    dispatch(setCurrentQSCPage(p));
  };
  const handleDateChange = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더함
    const day = String(date.getDate()).padStart(2, '0');
    const selectDate = `${year}-${month}-${day}`;

    dispatch(
      getQSCProcessListByPageThunk({
        status: 1,
        page: currentQSCPage,
        sort: selectedValue,
        date: selectDate,
      }),
    )
      .unwrap()
      .then((payload) => {
        setQSCPageCount(payload?.count);

        dispatch(setMasterQSCProcessList(payload?.results));
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  return (
    <>
      <QSCTemplate
        handleQSCProcessClick={handleQSCProcessClick}
        qscProcessList={masterQscProcessList}
        svUserPositionsList={svUserPositionsList}
        handleNoteCreateBtn={handleNoteCreateBtn}
        handlePaginationChange={handlePaginationChange}
        handleViewQscDetail={handleViewQscDetail}
        count={count}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        handleDateChange={handleDateChange}
      />
    </>
  );
};

export default QSC;
