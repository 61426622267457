import React from 'react';
import styled from 'styled-components';
import {Text} from '../index';

const TextDiv = styled.div`
  display: flex;
  padding: 0px 15px 3px 15px;
  color: #707070;
  border-radius: 5px;
  margin: 5px 10px;
  text-align: center;
  max-width: 85px;
  width: 85px;

  background-color: ${({backgroundColor}) => backgroundColor};
  cursor: pointer;
  &:hover {
    background-color: ${({hover}) => hover};
  }
`;

const TextBox = (props) => {
  const {text, fontSize, boxSize, backgroundColor, color, ...rest} = props;
  return (
    <TextDiv backgroundColor={backgroundColor} boxSize={boxSize} {...rest}>
      <Text bold color={color} fontSize={fontSize}>
        {text}
      </Text>
    </TextDiv>
  );
};

export default TextBox;
