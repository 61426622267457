const getFormattedTime = (date) => {
  const startDate = new Date(date);
  let startHour = startDate.getHours();
  let startMinute = startDate.getMinutes();

  startHour = startHour > 9 ? startHour : `0${startHour}`;
  startMinute = startMinute > 9 ? startMinute : `0${startMinute}`;

  return `${startHour}:${startMinute}`;
};

function getFormattedTime2(timeStr) {
  const timeParts = timeStr.split(':'); // 시간 문자열을 ':'으로 분리

  if (timeParts.length !== 3) {
    throw new Error('Invalid time format. Expected HH:MM:SS');
  }

  const [hour, minute, second] = timeParts;
  return `${hour}:${minute}`; // 초를 제외하고 시간과 분만 반환
}
const toISOString = (date) => {
  const pad = (num) => {
    const norm = Math.floor(Math.abs(num));
    return (norm < 10 ? '0' : '') + norm;
  };

  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
    date.getDate(),
  )}T${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(
    date.getSeconds(),
  )}`;
};

const formatDateRangeForApi = (date) => {
  const timeZoneOffset = date.getTimezoneOffset() * 60000;
  const start = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
  ).toISOString();
  const end = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    23,
    59,
    59,
    999,
  ).toISOString();
  return {
    start: new Date(new Date(start).getTime() - timeZoneOffset)
      .toISOString()
      .slice(0, -1),
    end: new Date(new Date(end).getTime() - timeZoneOffset)
      .toISOString()
      .slice(0, -1),
  };
};

function getWeekNumberInMonth(date) {
  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const firstSundayOfMonth =
    firstDayOfMonth.getDate() - firstDayOfMonth.getDay();
  const currentSunday = date.getDate() - date.getDay();

  return Math.floor((currentSunday - firstSundayOfMonth) / 7) + 1;
}

// Get the week's count in a month (starting from Sunday)
function getWeeksInMonth(year, month) {
  // Create a new Date object for the specified year and month
  let date = new Date(year, month);

  // Set the date to the first day of the month
  date.setDate(1);

  // Get the day of the week (0-based index, 0 = Sunday) of the first day of the month
  let firstDay = date.getDay();

  // Set the date to the last day of the month
  date.setMonth(month + 1, 0);

  // Get the day of the week (0-based index, 0 = Sunday) of the last day of the month
  let lastDay = date.getDay();

  // Calculate the number of days in the month
  let days = date.getDate();

  // Calculate the number of weeks in the month
  // Add one week for each day in the last week that is in the month
  // Add one additional week if the first and last weeks don't overlap and there are at least 7 days in the month
  // If the first day of the month is not Sunday, subtract one week
  let weeks = Math.ceil((days + (6 - lastDay)) / 7);

  if (firstDay !== 0) {
    return (weeks -= 1);
  }

  return weeks;
}

const getWeek = () => {
  var currentDate = new Date();

  var currentDay = currentDate.getDay();
  var totalDays = currentDate.getDate();
  if (currentDay !== 0) {
    var weekNumber = Math.ceil((totalDays - currentDay) / 7);
  } else {
    weekNumber = Math.ceil(totalDays / 7);
  }
  return weekNumber <= 0 ? 1 : weekNumber;
};

export {
  getFormattedTime,
  getFormattedTime2,
  toISOString,
  formatDateRangeForApi,
  getWeekNumberInMonth,
  getWeeksInMonth,
  getWeek,
};
