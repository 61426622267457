import React, {useState, useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled, {css} from 'styled-components';
import {MuiModal, Text} from 'shared/components/index';
import CloseIcon from '@mui/icons-material/Close';
import {
  selectCalendar,
  setShowFranchiseListModal,
  setEventDetailModal,
  getCalendarScheduleAsync,
  setSelectedEventDetail,
  patchCalendarScheduleAsync,
} from 'owler/utils/slices/calendarSlice';
import theme from 'shared/styles/theme';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import ShowFranchiseListModal from './ShowFranchiseListModal';
import toast from 'react-hot-toast';
import {
  getNoticeDetailAsync,
  setCurrentNotice,
  setCurrentNotice_uid,
} from 'owler/utils/slices/noticeSlice';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';

const Container = styled.div`
  background-color: white;
  margin-top: 20%;
  width: 700px;
  height: 610px;
  max-height: 610px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0px 3px 3px 3px #6e6e6e;
`;
const BtnWrapper = styled.button`
  border: none;
  background-color: white;
  cursor: pointer;
  position: relative;
  top: 0px;
  left: 2px;
`;
const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
`;
const FormWrapper = styled.form`
  width: 100%;
  padding: 15px 10px;
  //background-color: #f2f2f2;
  height: 540px;
  border-radius: 20px;

  > * {
    margin-bottom: 20px;
  }
`;
const FieldWrapper = styled.div`
  display: flex;
  flex-direction: ${({container}) => (container ? 'row' : 'column')};
  &:not(:last-child) {
    margin-right: 30px;
  }
  width: ${({width}) => width || '100%'};
  ${({center}) =>
    center &&
    css`
      margin: 0 auto;
    `}
`;

const ContentArea = styled.div`
  border: 1px solid ${theme.colors.grey9};
  border-radius: 5px;
  font-size: 14px;
  padding: 8px 13px;
  min-height: 180px;
  height: 180px;
  overflow: auto;
`;
const ExtraNoticeWrapper = styled.div`
  height: 55px;
  min-height: 55px;
  max-height: 55px;
  overflow: auto;
  border: 1px solid ${theme.colors.grey6};
  border-radius: 5px;
`;
const Notice = styled.div`
  width: 100%;
  margin: 6px 0;
  display: flex;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
    opacity: 0.5;
    color: #00bfff;
  }
`;
const NoticeTitle = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const StyledImg = styled.img`
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 50%;
  border: 2px solid black;
`;
const StyledInput = styled.input`
  border-radius: 5px;
  border: none;
  opacity: 0.8;
  font-weight: 600;
  padding: 0 5px 0px 5px;
  font-size: 19px;
`;
const EventDetailModal = ({deleteEvent, member_uid, reload, currentStore}) => {
  const {eventDetailModal, selectedEvent, selectedEventDetail} =
    useSelector(selectCalendar);
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [newTitle, setNewTitle] = useState('');
  const [newContents, setNewContents] = useState('');

  const handleModalClose = () => {
    dispatch(setEventDetailModal(false));
  };

  useEffect(() => {
    setLoad(true);
    if (selectedEvent) {
      dispatch(
        getCalendarScheduleAsync({
          schedule_uid: selectedEvent.schedule_uid,
        }),
      )
        .unwrap()
        .then((payload) => {
          dispatch(setSelectedEventDetail(payload));
          setLoad(false);
        });
    }
  }, [selectedEvent]);

  useEffect(() => {}, [selectedEventDetail]);

  const clickNotice = useCallback(async (notice_uid) => {
    try {
      const payload = await dispatch(
        getNoticeDetailAsync({notice_uid}),
      ).unwrap();

      await dispatch(setCurrentNotice(payload));
      await dispatch(setCurrentNotice_uid(notice_uid));

      // navigate(`/owler/notices/noticeDetail/${notice_uid}`)
      setTimeout(() => {
        const url = `/owler/notices/noticeDetail/${notice_uid}`;
        window.open(url, '_blank');
      }, 10); // 1초 지연
    } catch (error) {
      console.error('Error fetching notice detail:', error);
    }
  }, []);

  const EventDelete = useCallback(() => {
    if (window.confirm('해당 일정을 삭제하시겠습니까?')) {
      deleteEvent(selectedEvent);
    }
  }, []);

  const formatDate = useCallback(
    (dateInput1, dateInput2) => {
      let dateObj1 = new Date(dateInput1);
      let dateObj2 = new Date(dateInput2);

      const days = ['일', '월', '화', '수', '목', '금', '토'];

      const mm = String(dateObj1?.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so +1 and pad with 0s
      const dd = String(dateObj1?.getDate()).padStart(2, '0');
      const day = days[dateObj1?.getDay()];
      const hh = String(dateObj1?.getHours()).padStart(2, '0');
      const min = String(dateObj1?.getMinutes()).padStart(2, '0');

      const mm2 = String(dateObj2?.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so +1 and pad with 0s
      const dd2 = String(dateObj2?.getDate()).padStart(2, '0');
      const day2 = days[dateObj2?.getDay()];
      const hh2 = String(dateObj2?.getHours()).padStart(2, '0');
      const min2 = String(dateObj2?.getMinutes()).padStart(2, '0');

      if (hh === '00' && min === '00' && hh2 === '23' && min2 === '59') {
        if (mm === mm2 && dd === dd2 && day === day2) {
          return `${mm}월 ${dd}일 ${day}`;
        }
        return `${mm}월 ${dd}일 ${day} - ${mm2}월 ${dd2}일 ${day2}`;
      }

      return `${mm}월 ${dd}일 ${day} ${hh}시 ${min}분 - ${mm2}월 ${dd2}일 ${day2} ${hh2}시 ${min2}분`;
    },
    [selectedEvent],
  );

  const handleEdit = () => {
    if (newTitle === '' && newContents === '') {
      return;
    }
    dispatch(
      patchCalendarScheduleAsync({
        schedule_uid: selectedEvent.schedule_uid,
        member_uid: member_uid,
        title: newTitle.length > 0 ? newTitle : selectedEventDetail?.title,
        start: selectedEventDetail.start,
        end: selectedEventDetail.end,
        contents:
          newContents.length > 0 ? newContents : selectedEventDetail?.contents,
        color: selectedEventDetail.color,
      }),
    )
      .unwrap()
      .then(() => {
        dispatch(
          getCalendarScheduleAsync({
            schedule_uid: selectedEvent.schedule_uid,
          }),
        )
          .unwrap()
          .then((payload) => {
            dispatch(setSelectedEventDetail(payload));
            toast.success('수정되었습니다.');
            setNewTitle('');
            setNewContents('');
            reload();
          });
      });
  };

  return (
    <MuiModal open={eventDetailModal} onClose={handleModalClose}>
      <Container>
        {load == false ? (
          <>
            <TitleWrapper>
              <Text bold></Text>
              <BtnWrapper onClick={EventDelete} style={{marginLeft: '210px'}}>
                <DeleteIcon style={{color: `#424242`}} />
              </BtnWrapper>
              {editMode === false ? (
                <BtnWrapper
                  onClick={() => {
                    setEditMode(true);
                  }}
                >
                  <EditIcon style={{color: `#424242`}} />
                </BtnWrapper>
              ) : (
                <BtnWrapper
                  onClick={() => {
                    setEditMode(false);
                    handleEdit();
                  }}
                >
                  <SaveIcon style={{color: `#424242`}} />
                </BtnWrapper>
              )}
              <BtnWrapper onClick={handleModalClose}>
                <CloseIcon />
              </BtnWrapper>
            </TitleWrapper>

            <FormWrapper>
              <FieldWrapper>
                {editMode === false ? (
                  <div
                    style={{
                      fontSize: '22px',
                      width: '100%',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    bold
                    margin="-10px 0 5px 5px"
                  >
                    일정명:{selectedEventDetail?.title}
                  </div>
                ) : (
                  <StyledInput
                    placeholder={selectedEventDetail?.title}
                    onChange={(e) => setNewTitle(e.target.value)}
                  />
                )}
              </FieldWrapper>

              <FieldWrapper
                container={true}
                style={{justifyContent: 'space-between'}}
              >
                <Text margin="-5px 0 -10px 0px" style={{fontSize: '14px'}}>
                  {formatDate(
                    selectedEventDetail?.start,
                    selectedEventDetail?.end,
                  )}
                </Text>
                {currentStore.is_hq && (
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: `${theme.colors.kalsPurple}`,
                      color: 'white',
                      margin: '-10px 0px -10px 0',
                      borderRadius: '15px',
                      height: '25px',
                    }}
                    onClick={() => dispatch(setShowFranchiseListModal(true))}
                  >
                    공유 매장
                  </Button>
                )}
              </FieldWrapper>

              <FieldWrapper
                style={{
                  borderTop: `1px solid ${theme.colors.grey9}`,
                  paddingTop: '25px',
                }}
                container="row"
              >
                <StyledImg
                  src={
                    selectedEventDetail?.creator?.image !== null
                      ? selectedEventDetail?.creator?.image
                      : '/empty_user.png'
                  }
                />
                <Text margin="5px 0 0 20px" bold>
                  {selectedEventDetail?.creator?.name}
                </Text>
              </FieldWrapper>

              <FieldWrapper
                style={{
                  borderTop: `1px solid ${theme.colors.grey9}`,
                  paddingTop: '25px',
                }}
              >
                <Text bold margin="0 0 5px 10px">
                  내용
                </Text>
                {editMode === false ? (
                  <ContentArea>{selectedEventDetail?.contents}</ContentArea>
                ) : (
                  <TextField
                    multiline
                    fullWidth
                    rows={7}
                    size="small"
                    placeholder={selectedEventDetail?.contents}
                    onChange={(e) => setNewContents(e.target.value)}
                  />
                )}
              </FieldWrapper>

              <FieldWrapper>
                <Text bold margin="0 0 0px 10px">
                  공지 링크
                </Text>
                <ExtraNoticeWrapper>
                  {selectedEventDetail?.linked_notices?.length > 0 ? (
                    selectedEventDetail?.linked_notices?.map(
                      (selectedNotice) => {
                        return (
                          <Notice
                            style={{margin: '0'}}
                            key={selectedNotice.notice_uid}
                            onClick={() =>
                              clickNotice(selectedNotice.notice_uid)
                            }
                          >
                            <NoticeTitle
                              style={{width: '500px', margin: '0 0 0 10px'}}
                            >
                              {selectedNotice.title}
                            </NoticeTitle>
                          </Notice>
                        );
                      },
                    )
                  ) : (
                    <NoticeTitle style={{width: '400px', margin: '0 0 0 8px'}}>
                      등록된 공지 링크가 없습니다.
                    </NoticeTitle>
                  )}
                </ExtraNoticeWrapper>
              </FieldWrapper>
              <ShowFranchiseListModal
                selectedEventDetail={selectedEventDetail}
              />
            </FormWrapper>
          </>
        ) : null}
      </Container>
    </MuiModal>
  );
};
export default EventDetailModal;
