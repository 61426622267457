import React, {useEffect, useState} from 'react';
import AppText from 'shared/components/AppText';
import theme from 'shared/styles/theme';
import styled from 'styled-components';
import ScheduleTimeModal from './ScheduleTimeModal';
import {useDispatch, useSelector} from 'react-redux';
import {
  createTimesetAsync,
  createWorkScheduleAsync,
  deleteTimesetAsync,
  deleteWorkScheduleAsync,
  fetchTimeset,
  fetchWorkScheduleAsync,
} from 'owler/utils/slices/calendarSlice';
import {selectStore} from 'owler/utils/slices/storeSlice';
import {formatISO, startOfToday} from 'date-fns';

import SearchIcon from '@mui/icons-material/Search';
import {useRef} from 'react';

const Container = styled.div`
  width: 100%;
  padding: 0px 10px;
`;

const SearchBarContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 10px;
`;

const SearchBar = styled.input`
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
`;

const SearchIconWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`;

const MemberNameWrapper = styled.p`
  width: 35%;
  margin-top: 12px;
  font-size: 15px;
  color: ${(props) => (props.fontColor >= 1 ? 'black' : 'gray')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const MemberRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1px;
  background-color: ${(props) => (props.disabled ? '#ccc' : 'white')};
  width: 100%;
`;

const ScheduleListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
`;

const MemberRowTwo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  background-color: ${(props) => (props.disabled ? '#ccc' : 'white')};
  border-bottom: ${(props) =>
    props.disabled
      ? `1px solid ${theme.colors.kalsPurple};`
      : `1px solid ${theme.colors.kalsPurple};`};
`;

const TimeSlot = styled.span`
  font-size: 15px;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
  margin-left: 5px;
`;

const ScheduleCreateContainer = styled.div`
  position: relative;
  height: 500px;
  overflow-y: scroll;
`;

const Popup = styled.div`
  position: absolute;
  background-color: white;
  border: 1px solid ${theme.colors.grey4};
  border-radius: 10px;
  padding: 10px;
  left: 50px;
  top: 120px;
  z-index: 10;
  width: 200px;
  height: 300px;
`;

const PlusButton = styled.button`
  border: none;
  padding: 0;
  color: ${theme.colors.primary};
  background: none;
  cursor: pointer;
  margin-right: 15px;
  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
`;

const PlusButtonTwo = styled.button`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: ${theme.colors.white};
  background: ${theme.colors.kalsPurple};
  border-radius: 50%;
  cursor: pointer;
  margin-right: 4px;

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
`;

const CloseButton = styled.button`
  border: none;
  padding: 0;
  font-weight: bold;
  color: ${(props) => (props.xColor ? props.xColor : 'red')};
  background: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
  }
`;

const PopupContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
`;

const TimeSetWrap = styled.div`
  cursor: pointer;
`;

const TimeText = styled.div`
  font-weight: ${(props) => (props.current ? 'bold' : 'normal')};
  color: ${(props) => (props.current ? theme.colors.kalsPurple : 'black')};
`;

// Main component
const MemberScheduleComponent = ({
  selectedSchedule,
  handleRefresh,
  memberSchedules,
}) => {
  const dispatch = useDispatch();
  const {currentStore} = useSelector(selectStore);
  const currentDate = formatISO(startOfToday(), {representation: 'date'});

  const [searchTerm, setSearchTerm] = useState('');
  const [popupSchedule, setPopupSchedule] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [scheduleTimesets, setScheduleTimesets] = useState([]);
  const [memberWorkSchedules, setMemberWorkSchedules] = useState([]);
  const [popupScheduleType, setPopupScheduleType] = useState('view');
  const [filteredMembers, setFilteredMembers] = useState(memberSchedules);
  const [isModalOpen, setModalOpen] = useState(false);

  const popupRef = useRef();

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setPopupSchedule(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const result = searchTerm
      ? memberSchedules.filter((member) =>
          member.name.toLowerCase().includes(searchTerm.toLowerCase()),
        )
      : memberSchedules;

    setFilteredMembers(result);
  }, [searchTerm, memberSchedules]);

  // Function to show the popup
  const handleFetchWorkSchedule = (schedule, member) => {
    setPopupScheduleType('view');
    setPopupSchedule(true);

    dispatch(
      fetchWorkScheduleAsync({
        work_schedule_uid: schedule.work_schedule_uid,
        date: selectedSchedule ? selectedSchedule : currentDate,
      }),
    )
      .unwrap()
      .then(({results}) => {
        setMemberWorkSchedules(results);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // Function to hide the popup
  const hidePopup = () => {
    setPopupSchedule(null);
  };

  // Function to open the modal
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // Function to fetch schedule timeset
  const handleFetchScheduleTimeset = (_member) => {
    setSelectedMember(_member);

    dispatch(fetchTimeset({store_uid: currentStore?.store_uid}))
      .unwrap()
      .then(({results}) => {
        setScheduleTimesets(results);
      })
      .catch((err) => {
        console.error(err);
      });

    setPopupScheduleType('create');
    setPopupSchedule(true);
  };

  const handleSubmitTimeset = (values) => {
    const start_time = `${values.startHour}:${values.startMinute}`;
    const end_time = `${values.endHour}:${values.endMinute}`;

    dispatch(
      createTimesetAsync({
        start_time,
        end_time,
        store_uid: currentStore?.store_uid,
      }),
    )
      .unwrap()
      .then((res) => {
        dispatch(fetchTimeset({store_uid: currentStore?.store_uid}))
          .unwrap()
          .then(({results}) => {
            setScheduleTimesets(results);
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDeleteTimeSet = (timeset) => {
    dispatch(
      deleteTimesetAsync({
        schedule_time_set_uid: timeset.schedule_time_set_uid,
      }),
    )
      .unwrap()
      .then((res) => {
        dispatch(fetchTimeset({store_uid: currentStore?.store_uid}))
          .unwrap()
          .then(({results}) => {
            setScheduleTimesets(results);
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleCreateWorkSchedule = (timeset) => {
    dispatch(
      createWorkScheduleAsync({
        store_uid: currentStore?.store_uid,
        member_uid: selectedMember.member_uid,
        time_set_uid: timeset.schedule_time_set_uid,
        date: selectedSchedule ? selectedSchedule : currentDate,
      }),
    )
      .unwrap()
      .then((res) => {
        handleRefresh();
        hidePopup();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDeleteWorkSchedule = (schedule) => {
    dispatch(
      deleteWorkScheduleAsync({
        work_schedule_uid: schedule.work_schedule_uid,
      }),
    )
      .unwrap()
      .then((res) => {
        handleRefresh();
        hidePopup();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Container>
      <SearchBarContainer>
        <SearchBar
          placeholder=""
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
      </SearchBarContainer>

      <ScheduleCreateContainer>
        {filteredMembers?.map((member, index) => (
          <MemberRow key={index.toString()}>
            <MemberNameWrapper>{member.name}</MemberNameWrapper>

            <ScheduleListWrapper>
              {member?.work_schedule_list?.length > 0 ? (
                member?.work_schedule_list.map((schedule, index) => (
                  <MemberRowTwo key={index.toString()}>
                    <TimeSlot
                      onClick={() =>
                        schedule?.start &&
                        handleFetchWorkSchedule(schedule, member)
                      }
                    >
                      {schedule?.start
                        ? `${schedule?.start.slice(
                            11,
                            16,
                          )} ~ ${schedule.end.slice(11, 16)}`
                        : '-'}
                    </TimeSlot>

                    <div
                      style={{
                        width: '30px',
                        marginLeft: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <PlusButton
                        onClick={() => handleFetchScheduleTimeset(member)}
                      >
                        +
                      </PlusButton>

                      <CloseButton
                        onClick={() => handleDeleteWorkSchedule(schedule)}
                      >
                        ×
                      </CloseButton>
                    </div>
                  </MemberRowTwo>
                ))
              ) : (
                <MemberRowTwo>
                  <TimeSlot
                    onClick={() =>
                      member?.start && handleFetchWorkSchedule(member, member)
                    }
                  >
                    {member?.start
                      ? `${member?.start.slice(11, 16)} ~ ${member.end.slice(
                          11,
                          16,
                        )}`
                      : '-'}
                  </TimeSlot>

                  <div
                    style={{
                      width: '30px',
                      marginLeft: '10px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <PlusButton
                      onClick={() => handleFetchScheduleTimeset(member)}
                    >
                      +
                    </PlusButton>

                    <CloseButton
                      disabled
                      onClick={() => handleDeleteWorkSchedule(member)}
                    >
                      ×
                    </CloseButton>
                  </div>
                </MemberRowTwo>
              )}
            </ScheduleListWrapper>
          </MemberRow>
        ))}

        {popupSchedule && (
          <Popup ref={popupRef}>
            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{display: 'flex', cursor: 'pointer'}}
                onClick={handleOpenModal}
              >
                <PlusButtonTwo>+</PlusButtonTwo>
                <AppText text="생성하기">생성하기</AppText>
              </div>

              <CloseButton xColor="black" onClick={hidePopup}>
                ×
              </CloseButton>
            </div>

            {popupScheduleType === 'view' && (
              <div style={{marginTop: '14px'}}>
                {memberWorkSchedules.map((schedule, index) => (
                  <PopupContent key={index.toString()}>
                    <TimeText current={schedule.current}>
                      {`${schedule.start.slice(11, 16)} ~ ${schedule.end.slice(
                        11,
                        16,
                      )}`}
                    </TimeText>

                    <CloseButton
                      style={{marginRight: '5px'}}
                      onClick={() => handleDeleteWorkSchedule(schedule)}
                      color="black"
                    >
                      x
                    </CloseButton>
                  </PopupContent>
                ))}
              </div>
            )}

            {popupScheduleType === 'create' && (
              <div style={{marginTop: '10px'}}>
                {scheduleTimesets.map((timeset, index) => (
                  <PopupContent key={index.toString()}>
                    <TimeSetWrap
                      onClick={() => handleCreateWorkSchedule(timeset)}
                    >
                      {`${timeset.start_time.substring(
                        0,
                        5,
                      )} ~ ${timeset.end_time.substring(0, 5)}`}
                    </TimeSetWrap>

                    <CloseButton
                      style={{marginRight: '4px'}}
                      onClick={() => handleDeleteTimeSet(timeset)}
                    >
                      x
                    </CloseButton>
                  </PopupContent>
                ))}
              </div>
            )}
          </Popup>
        )}
      </ScheduleCreateContainer>

      {/* TimeModal Component */}
      <ScheduleTimeModal
        open={isModalOpen}
        handleClose={handleCloseModal}
        handleSubmitTimeset={handleSubmitTimeset}
      />
    </Container>
  );
};

export default MemberScheduleComponent;
