import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import api from 'shared/utils/api';

const initialState = {
  masterNoticeList: [],
  currentNotice: null,
  currentNoticePage: 1,
  noticePageCount: null,
  storeData: [],
  directGroup: [],
  noDirectGroup: [],
  directStoreData: {
    all: false,
    stores: [],
    names: [],
  },
  noDirectStoreData: {
    all: false,
    stores: [],
    names: [],
  },
  selectedNotice: null,

  notifications: [],
  member_uid: null,
  notificationModal: false,
  notificationDetail: null,
};

export const getMemberUidAsync = createAsyncThunk(
  'notice/getMemberUidAsync',
  async (uid) => {
    const response = await api.getMember(uid);
    const {results} = response.data;
    return results;
  },
);
// get store notices by page thunk
export const getNoticesByPageAsync = createAsyncThunk(
  'notice/getNoticesByPage',
  async (param) => {
    const response = await api.getNoticesByPage(param);

    const {results} = response.data;

    return results;
  },
);

// register a notice thunk
export const createNewNoticeAsync = createAsyncThunk(
  'notice/createNewNotice',
  async (data, {rejectWithValue}) => {
    try {
      const response = await api.createNewNotice(data);

      const {results} = response.data;

      return results;
    } catch (error) {
      const errorResponse = error.response || {};
      const data = errorResponse.data || {};

      return rejectWithValue(data.kr);
    }
  },
);

// get notice detail
export const getNoticeDetailAsync = createAsyncThunk(
  'notice/getSvNoticeDetail',
  async (param) => {
    const response = await api.getNoticeDetail(param);

    const {results} = response.data;

    return results;
  },
);

export const getNoticeResponseAsync = createAsyncThunk(
  'notice/getSvNoticeDetail',
  async (param) => {
    const response = await api.getNoticeResponse(param);

    const {results} = response.data;

    return results;
  },
);

// Edit a notice thunk
export const editNoticeAsync = createAsyncThunk(
  'notice/editNotices',
  async (data) => {
    const response = await api.editANotice(data);
    const {result} = response.data;

    return result;
  },
);

// delete a notice thunk
export const deleteNoticeAsync = createAsyncThunk(
  'notice/deleteNotice',
  async (data) => {
    const response = await api.deleteANotice(data);
    const {result} = response.data;

    return result;
  },
);

// get user's post notifications thunk
export const getNotificationsThunk = createAsyncThunk(
  'comUser/getSvNotifications',
  async (param) => {
    const response = await api.getNotifications(param);

    const {results} = response.data;

    return results;
  },
);
export const getNotificationsDetailThunk = createAsyncThunk(
  'comUser/getNotificationsDetail',
  async (param) => {
    const response = await api.getNotificationsDetail(param);

    const {results} = response.data;

    return results;
  },
);
// patch user's view notification thunk
export const patchViewNotificationThunk = createAsyncThunk(
  'comUser/patchViewNotification',
  async (param) => {
    const response = await api.getPatchViewNotification(param);

    const {results} = response.data;

    return results;
  },
);

export const pinNoticeThunk = createAsyncThunk(
  'comUser/pinNotice',
  async (param, {rejectWithValue}) => {
    try {
      const response = await api.pinNotice(param);
      const {results} = response.data;

      return results;
    } catch (error) {
      // return {error: error.response.data.kr };
      return rejectWithValue(error.response.data.kr);
    }
  },
);

export const deleteNoticePinThunk = createAsyncThunk(
  'comUser/deleteNoticePin',
  async (param, {rejectWithValue}) => {
    try {
      const response = await api.deleteNoticePin(param);
      const {results} = response.data;

      return results;
    } catch (error) {
      if (error.response.data.kr) {
        return rejectWithValue(error.response.data.kr);
      }

      return rejectWithValue(error);
    }
  },
);

// postRichImage thunk
export const postRichImageThunk = createAsyncThunk(
  'notice/postRichImage',
  async (param) => {
    const response = await api.postRichImage(param);

    const {results} = response.data;

    return results;
  },
);

// postRichImageMultiple thunk
export const postRichImageMultipleThunk = createAsyncThunk(
  'notice/postRichImageMultiple',
  async (param) => {
    const response = await api.postRichImageMultiple(param);

    const {results} = response.data;

    return results;
  },
);

export const noticeSlice = createSlice({
  name: 'notice',
  initialState,
  reducers: {
    setMasterNoticeList: (state, action) => {
      state.masterNoticeList = action.payload;
    },
    setCurrentNoticePage: (state, action) => {
      state.currentNoticePage = action.payload;
    },
    setNoticePageCount: (state, action) => {
      state.noticePageCount = action.payload;
    },

    setCurrentNotice: (state, action) => {
      state.currentNotice = action.payload;
    },

    setSelectedNotice: (state, action) => {
      state.selectedNotice = action.payload;
    },
    setStoreData: (state, action) => {
      state.storeData = action.payload;
    },
    setDirectGroup: (state, action) => {
      state.directGroup = action.payload;
    },
    setNoDirectGroup: (state, action) => {
      state.noDirectGroup = action.payload;
    },
    setDirectStoreData: (state, action) => {
      state.directStoreData = action.payload;
      if (
        state.directStoreData.stores.length === state.directGroup.length &&
        state.directGroup.length !== 0
      ) {
        state.directStoreData.all = true;
      } else {
        state.directStoreData.all = false;
      }
    },
    setNoDirectStoreData: (state, action) => {
      state.noDirectStoreData = action.payload;
      if (
        state.noDirectStoreData.stores.length === state.noDirectGroup.length &&
        state.noDirectGroup.length !== 0
      ) {
        state.noDirectStoreData.all = true;
      } else {
        state.noDirectStoreData.all = false;
      }
    },

    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    setCurrentNotice_uid: (state, action) => {
      if (!state.currentNotice) {
        state.currentNotice = {};
      }
      state.currentNotice.notice_uid = action.payload;
    },
    setMember_uid: (state, action) => {
      state.member_uid = action.payload;
    },
    setNotificationModal: (state, action) => {
      state.notificationModal = action.payload;
    },
    setNotificationDetail: (state, action) => {
      state.notificationDetail = action.payload;
    },
  },
});

export const {
  setMasterNoticeList,
  setCurrentNoticePage,
  setNoticePageCount,
  setDirectStoreData,
  setNoDirectStoreData,
  setCurrentNotice,
  setSelectedNotice,
  setNotifications,
  setCurrentNotice_uid,
  setMember_uid,
  setNotificationModal,
  setNotificationDetail,
  setStoreData,
  setDirectGroup,
  setNoDirectGroup,
} = noticeSlice.actions;

export const selectNotice = (state) => state.owler.notice;

export default noticeSlice.reducer;
