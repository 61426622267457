/* eslint-disable react/prop-types */
import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import {styled} from '@mui/system';

const CheckboxGroup = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const CheckboxLabel = styled(FormControlLabel)({
  marginRight: '16px',
});

const WeekSelector = ({label, name, options, value, onChange}) => {
  const handleChange = event => {
    const {value: selectedValue, checked} = event.target;
    const updatedValue = checked
      ? [...value, selectedValue]
      : value.filter(val => val !== selectedValue);
    onChange(name, updatedValue);
  };

  return (
    <>
      <FormLabel>{label}</FormLabel>
      <CheckboxGroup>
        {options.map(option => (
          <CheckboxLabel
            key={option.value}
            control={
              <Checkbox
                name={name}
                value={option.value}
                checked={value.includes(option.value)}
                onChange={handleChange}
              />
            }
            label={option.label}
            labelPlacement="end"
          />
        ))}
      </CheckboxGroup>
    </>
  );
};

export default WeekSelector;
