import React from 'react';
import styled from 'styled-components';

import Header from './Header';
import NewLogoCircle from '../../assets/icons/NewLogoCircle';
import Text from '../atoms/Text';

const StyledWrapper = styled.div`
  overflow: ${(props) => props.overflow};
  mim-width: 1280px;
  width: 1280px;
  margin: 0 auto;
  padding: 0 20px;
  min-height: 100%;
  & > *:last-child {
    margin-bottom: 0;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 30%;
  margin: 0 auto;
`;

const TitleWrapper = styled.div`
  min-width: 1280px;
  margin: 60px auto 30px;
`;

// eslint-disable-next-line no-unused-vars, react/prop-types
const Wrapper = ({
  overflow,
  children,
  titleText,
  infoText,
  buttonText,
  handleStoreClick,
}) => (
  <StyledWrapper overflow={overflow}>
    <Header
      src={<NewLogoCircle length={58} />}
      buttonText={buttonText}
      handleStoreClick={handleStoreClick}
    >
      {titleText && (
        <TitleWrapper>
          <Text fontSize="strongTitle" text={titleText} bold />
        </TitleWrapper>
      )}
      {children}
    </Header>
  </StyledWrapper>
);

export default Wrapper;
