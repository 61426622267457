import React from 'react';
// import {Text} from 'shared/components/index';
import theme from 'shared/styles/theme';
import styled from 'styled-components';
import {VictoryChart, VictoryBar, VictoryGroup, VictoryAxis} from 'victory';

const hexColor = theme.colors.bgSkelet;

const red = parseInt(hexColor.slice(1, 3), 16);
const green = parseInt(hexColor.slice(3, 5), 16);
const blue = parseInt(hexColor.slice(5, 7), 16);

const StyledWrapper = styled.div`
  //background-color: rgba(${red}, ${green}, ${blue}, 0.65);
  padding: 10px;
  border-radius: 10px;
`;

const CardTitleWrap = styled.div`
  width: 100%;
  height: 20px;
  margin: 0 0 0px 0;
`;

const StyledGraphWrapper = styled.div`
  margin-top: 5px;
  position: relative;
`;

const Description = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 5px;
  right: 5px;
  margin-top: ${(props) => props.marginTop}px;
  span {
    color: black;
    font-size: 10px;
    margin-left: 6px;
  }
`;

const ColorBox = styled.div`
  width: 17px;
  height: 10px;
  border-radius: 10px;
  background-color: ${(props) => props.color};
`;

const WeeklyQSCPerformance = ({
  lastWeekScoreGraphData,
  thisWeekScoreGraphData,
}) => {
  const koreanWeekLabels = ['일', '월', '화', '수', '목', '금', '토'];

  return (
    <StyledWrapper>
      <CardTitleWrap>
        {/* <Text color={theme.colors.white} fontSize="xl2">
          주간 수행 현황
        </Text> */}
      </CardTitleWrap>

      <StyledGraphWrapper>
        <VictoryChart height={240} domainPadding={{x: 15, y: 0}}>
          <VictoryAxis
            style={{
              tickLabels: {fill: 'black'},
              // axis: {stroke: 'transparent'},
            }}
            tickFormat={koreanWeekLabels}
          />
          <VictoryAxis
            dependentAxis
            domain={[1, 100]}
            style={{
              tickLabels: {fill: 'black'},
              axis: {stroke: 'transparent'},
            }}
          />
          <VictoryGroup
            offset={8}
            style={{data: {width: 6}}}
            colorScale={['#8b77d9', 'rgba(139, 119, 217, 0.5)']}
          >
            <VictoryBar data={thisWeekScoreGraphData} cornerRadius={3} />
            <VictoryBar data={lastWeekScoreGraphData} cornerRadius={3} />
          </VictoryGroup>
        </VictoryChart>

        {/* Description  */}
        <Description marginTop={25}>
          <ColorBox color="rgba(139, 119, 217, 0.5)" />
          <span>저번주</span>
        </Description>
        <Description marginTop={47}>
          <ColorBox color="#8b77d9" />
          <span>이번주</span>
        </Description>
      </StyledGraphWrapper>
    </StyledWrapper>
  );
};

export default WeeklyQSCPerformance;
