import styled from "styled-components";

const TitleArea = styled.div`
    width: 92%;
    margin-left: 3%;
    margin-top: 1.5vh;
    font-size: ${props => props.fontSize || "18px"};
    font-weight: 500;
    display:flex;
    justify-content: space-between;  
    `

export default TitleArea;