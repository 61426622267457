import React, {useCallback, useEffect, useState} from 'react';

import WorkerManagementTemplate from './template/index';
import {useDispatch, useSelector} from 'react-redux';

import {
  getSupervisorListAsync,
  selectFranchiseUser,
  setChangeSvStoreModal,
  setSelectedSvUserPosition,
  getSvBrandListAsync,
  setSvStoreSelectModal,
  getSvfranchiseStoreListAsync,
  patchSvfranchiseStoreAsync,
} from 'franchise/utils/slices/frUserSlice';
import {getFranchisePositionsAsync} from 'franchise/utils/slices/frPositionSlice';

const StoreManage = () => {
  const dispatch = useDispatch();
  const {supervisorList, supervisorData} = useSelector(selectFranchiseUser);
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedPositionName, setSelectedPositionName] = useState('');
  const [brandLoading, setBrandLoading] = useState(false);
  const [storeLoading, setStoreLoading] = useState(false);
  const [headUid, setHeadUid] = useState(null);

  useEffect(() => {
    dispatch(getSupervisorListAsync(supervisorData));
    dispatch(getFranchisePositionsAsync(supervisorData));

    return () => {
      dispatch(setChangeSvStoreModal(false));
    };
  }, [dispatch, supervisorData]);

  const handleSvSelect = useCallback(
    async (_member, _name) => {
      setBrandLoading(true);
      setSelectedMember(_member);
      setSelectedPositionName(_name);
      dispatch(
        getSvBrandListAsync({
          target_sv_user_uid: _member.sv_user_uid,
        }),
      )
        .unwrap()
        .then(() => {
          setBrandLoading(false);
        })
        .catch(() => {
          alert('매장을 불러오지 못했습니다.');
          setBrandLoading(false);
        });
      dispatch(setSelectedSvUserPosition(_member));
      dispatch(setChangeSvStoreModal(true));
    },
    [dispatch],
  );

  const selectBrand = useCallback(
    (head_quarters_uid) => {
      setHeadUid(head_quarters_uid);
      setStoreLoading(true);
      dispatch(setSvStoreSelectModal(true));
      dispatch(
        getSvfranchiseStoreListAsync({
          head_quarters_uid: head_quarters_uid,
          target_sv_user_uid: selectedMember.sv_user_uid,
        }),
      )
        .unwrap()
        .then(() => {
          setStoreLoading(false);
        })
        .catch(() => {
          alert('매장을 불러오지 못했습니다.');
          setStoreLoading(false);
        });
    },
    [dispatch, selectedMember],
  );

  const handleSaveStoreList = useCallback(
    (direct, nodirect) => {
      dispatch(
        patchSvfranchiseStoreAsync({
          target_sv_user_uid: selectedMember.sv_user_uid,
          franchise_store_uid_list: [...direct, ...nodirect],
          head_quarters_uid: headUid,
        }),
      )
        .unwrap()
        .then(() => {
          alert('변경되었습니다.');
          dispatch(
            getSvBrandListAsync({
              target_sv_user_uid: selectedMember.sv_user_uid,
            }),
          );
          dispatch(setSvStoreSelectModal(false));
        });
    },
    [dispatch, selectedMember, headUid],
  );

  return (
    <WorkerManagementTemplate
      supervisorList={supervisorList}
      handleSvSelect={handleSvSelect}
      selectedMember={selectedMember}
      selectedPositionName={selectedPositionName}
      selectBrand={selectBrand}
      handleSaveStoreList={handleSaveStoreList}
      brandLoading={brandLoading}
      storeLoading={storeLoading}
    />
  );
};

export default StoreManage;
