import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import {MuiModal, Text, MuiButton} from 'shared/components/index';
import CloseIcon from '@mui/icons-material/Close';

import {
  selectFranchiseUser,
  setChangeSvStoreModal,
} from 'franchise/utils/slices/frUserSlice';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import StoreSelectModal from './StoreSelectModal';

const Container = styled.div`
  background-color: white;
  width: 570px;
  height: 570px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
`;
const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const BtnWrapper = styled.button`
  border: none;
  background-color: white;
  cursor: pointer;
`;

const StoreWrapper = styled.div`
  width: 160px;
  height: 160px;
  border-radius: 15px;
  box-shadow: 0px 2px 2px 2px #bdbdbd;
  margin: 7px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
  overflow: hidden;
  &:hover {
    cursor: pointer;
  }
`;
const StoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
  width: 100%;
  justify-content: start;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow-x: auto;
  border-radius: 20px;
  background-color: #fafafa;
  border: 1px solid #f2f2f2;
  margin-bottom: 20px;
`;

const BrandImg = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50px;
  object-fit: cover;
  background-color: white;
  border: 1px solid #bdbdbd;
`;
const ImgWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
`;
const BrandSelectModal = ({
  selectedMember,
  selectedPositionName,
  selectBrand,
  handleSaveStoreList,
  brandLoading,
  storeLoading,
}) => {
  const {changeSvStoreModal, brandList} = useSelector(selectFranchiseUser);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');

  const handleModalClose = () => {
    dispatch(setChangeSvStoreModal(false));
  };

  return (
    <MuiModal open={changeSvStoreModal} onClose={handleModalClose}>
      <Container>
        <TitleWrapper>
          <Text bold>브랜드 선택</Text>

          <BtnWrapper onClick={handleModalClose}>
            <CloseIcon />
          </BtnWrapper>
        </TitleWrapper>
        <div style={{width: '100%', padding: '0 20px'}}>
          <input
            style={{
              height: '35px',
              border: '1px solid #BDBDBD',
              width: '300px',
              marginBottom: '15px',
              padding: '10px',
            }}
            placeholder="브랜드명을 검색해주세요."
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div style={{display: 'flex', width: '90%'}}>
          <Text
            margin="0px 0 3px 0"
            style={{
              fontSize: '16px',
              textAlign: 'left',
            }}
            bold
          >
            {selectedMember?.supervisor_name} {selectedPositionName}
          </Text>
          <Text
            color="#A4A4A4"
            margin="0px 0 0px 0"
            style={{
              fontSize: '16px',
            }}
          >
            &nbsp;님의 관리 현황
          </Text>
        </div>
        <StoreContainer>
          {brandLoading === true ? (
            <Box
              sx={{
                display: 'flex',
                height: '100%',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            brandList
              ?.filter((eachStore) =>
                eachStore.name.toLowerCase().includes(searchTerm.toLowerCase()),
              )
              .map((store) => {
                return (
                  <StoreWrapper
                    key={store.head_quarters_uid}
                    onClick={() => selectBrand(store.head_quarters_uid)}
                  >
                    <ImgWrapper>
                      <BrandImg src={store.logo} />
                    </ImgWrapper>

                    <Text
                      margin="0px 0 0 0"
                      style={{
                        fontSize: '15px',
                        width: '90%',
                        textAlign: 'center',
                      }}
                      bold
                    >
                      {store.name}
                    </Text>
                    <Text
                      margin="0px 0 0 0"
                      style={{
                        fontSize: '15px',
                        width: '90%',
                        textAlign: 'center',
                      }}
                      bold
                    >
                      {store.in_charge_store_count} /{' '}
                      {store.franchise_store_count}
                    </Text>
                  </StoreWrapper>
                );
              })
          )}
        </StoreContainer>
        <StoreSelectModal
          storeLoading={storeLoading}
          handleSaveStoreList={handleSaveStoreList}
        />
        <MuiButton
          textColor="white"
          backgroundColor="#FA5858"
          text="닫기"
          onClick={handleModalClose}
        />
      </Container>
    </MuiModal>
  );
};
export default BrandSelectModal;
