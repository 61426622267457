import React, {useCallback, useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {selectStore} from 'owler/utils/slices/storeSlice';
import {selectNotice} from 'owler/utils/slices/noticeSlice';
import SettingsTemplate from './template';
import {
  getJoinMemberAsync,
  setJoinMembers,
  postJoinMemberAsync,
  deleteJoinMemberAsync,
  getAllMembersAsync,
  setAllMembers,
  patchAllMembersAsync,
  deleteAllMembersAsync,
  setPositionDetailModalOpen,
  setMemberPositionChangeModalOpen,
  setAllPositions,
  getAllPositionsAsync,
  postAllPositionsAsync,
  patchAllPositionsAsync,
  deleteAllPositionsAsync,
  setQuestionModalOpen,
  setPositionChangeModalOpen,
  setPositionAddModalOpen,
  getBrandAssignMentListAsync,
  postBrandAssignMentAsync,
  setBrandList,
  setOfferList,
  getPhoneJoinMemberAsync,
  postPhoneJoinMemberAsync,
} from 'owler/utils/slices/positionSlice';
import {setCurrentStore} from 'owler/utils/slices/storeSlice';
import {selectPosition} from 'owler/utils/slices/positionSlice';

const Settings = () => {
  const [setting, setSetting] = useState(0);
  const {currentStore} = useSelector(selectStore);
  const {member_uid} = useSelector(selectNotice);
  const dispatch = useDispatch();
  const {brandAssinment} = useSelector(selectPosition);

  useEffect(() => {
    if (currentStore) {
      dispatch(getAllPositionsAsync({store_uid: currentStore.store_uid}))
        .unwrap()
        .then((payload) => {
          dispatch(setAllPositions(payload));
        });
      dispatch(getAllMembersAsync({store_uid: currentStore.store_uid}))
        .unwrap()
        .then((payload) => {
          dispatch(setAllMembers(payload));
        });
      dispatch(
        getJoinMemberAsync({
          store_uid: currentStore.store_uid,
          memberUID: member_uid.member_uid,
        }),
      )
        .unwrap()
        .then((payload) => {
          dispatch(setJoinMembers(payload));
        });
      setSetting(0);
    }

    return () => {
      dispatch(setQuestionModalOpen(false));
      dispatch(setPositionDetailModalOpen(false));
      dispatch(setPositionChangeModalOpen(false));
      dispatch(setPositionAddModalOpen(false));
      dispatch(setMemberPositionChangeModalOpen(false));
    };
  }, [dispatch, currentStore, member_uid]);

  const handleJoinReqManagement = useCallback(async () => {
    setSetting(1);
    dispatch(
      getJoinMemberAsync({
        store_uid: currentStore.store_uid,
        memberUID: member_uid.member_uid,
      }),
    )
      .unwrap()
      .then((payload) => {
        dispatch(setJoinMembers(payload));
      });
  }, [dispatch, currentStore, member_uid]);

  const handlePositionManagement = useCallback(async () => {
    setSetting(2);
    dispatch(getAllMembersAsync({store_uid: currentStore.store_uid}))
      .unwrap()
      .then((payload) => {
        dispatch(setAllMembers(payload));
      });
  }, [dispatch, currentStore]);

  const handleBrandManagement = useCallback(async () => {
    setSetting(4);
    dispatch(getAllMembersAsync({store_uid: currentStore.store_uid}))
      .unwrap()
      .then((payload) => {
        dispatch(setAllMembers(payload));
      });
  }, [dispatch, currentStore]);

  const handleWorkerManagement = useCallback(async () => {
    setSetting(3);
    dispatch(getAllPositionsAsync({store_uid: currentStore.store_uid}))
      .unwrap()
      .then((payload) => {
        dispatch(setAllPositions(payload));
      });
  }, [dispatch, currentStore]);
  //////////////////////////////////////////////////////////
  const accessJoin = useCallback(
    (member_uid) => {
      dispatch(postJoinMemberAsync({member_uid: member_uid}))
        .unwrap()
        .then((payload) => {
          dispatch(
            getJoinMemberAsync({
              store_uid: currentStore.store_uid,
              memberUID: member_uid.member_uid,
            }),
          )
            .unwrap()
            .then((payload) => {
              dispatch(setJoinMembers(payload));
            });
        });
    },
    [dispatch, currentStore],
  );

  const rejectJoin = useCallback(
    (member_uid) => {
      dispatch(deleteJoinMemberAsync({member_uid: member_uid}))
        .unwrap()
        .then((payload) => {
          dispatch(
            getJoinMemberAsync({
              store_uid: currentStore.store_uid,
              memberUID: member_uid.member_uid,
            }),
          )
            .unwrap()
            .then((payload) => {
              dispatch(setJoinMembers(payload));
            });
        });
    },
    [dispatch, currentStore],
  );
  //////////////////////////////////////////////////////////

  const fireMember = useCallback(
    (member_uid) => {
      if (window.confirm('이 근무자를 해고하시겠습니까?')) {
        dispatch(deleteAllMembersAsync({member_uid: member_uid}))
          .unwrap()
          .then((payload) => {
            alert('해고되었습니다.');
            dispatch(setPositionDetailModalOpen(false));
            dispatch(getAllMembersAsync({store_uid: currentStore.store_uid}))
              .unwrap()
              .then((payload) => {
                dispatch(setAllMembers(payload));
              });
          })
          .catch((err) => {
            alert(err);
          });
      }
    },
    [dispatch, currentStore],
  );

  const modifyMember = useCallback(
    (member, position) => {
      if (
        window.confirm(
          `${member.name}의 직급을 ${position.name}(으)로 변경하시겠습니까?`,
        )
      ) {
        dispatch(
          patchAllMembersAsync({
            member_uid: member.member_uid,
            position_uid: position.position_uid,
          }),
        )
          .unwrap()
          .then((payload) => {
            alert('변경되었습니다.');
            dispatch(setMemberPositionChangeModalOpen(false));
            dispatch(getAllMembersAsync({store_uid: currentStore.store_uid}))
              .unwrap()
              .then((payload) => {
                dispatch(setAllMembers(payload));
              });
          })
          .catch((err) => {
            alert(err);
          });
      }
    },
    [currentStore, dispatch],
  );
  //////////////////////////////////////////////////////////

  const handleAddBtn = useCallback(
    (newName, newLevel) => {
      dispatch(
        postAllPositionsAsync({
          store_uid: currentStore.store_uid,
          name: newName,
          authority_level: newLevel,
        }),
      )
        .unwrap()
        .then((payload) => {
          alert('추가되었습니다');
          dispatch(setPositionAddModalOpen(false));
          dispatch(getAllPositionsAsync({store_uid: currentStore.store_uid}))
            .unwrap()
            .then((payload) => {
              dispatch(setAllPositions(payload));
            });
        })
        .catch((err) => {
          alert(err);
        });
    },
    [dispatch, currentStore],
  );

  const handleChangeBtn = useCallback(
    (position, newName, newLevel) => {
      dispatch(
        patchAllPositionsAsync({
          position_uid: position.position_uid,
          name: newName,
          authority_level: newLevel,
        }),
      )
        .unwrap()
        .then((payload) => {
          alert('수정되었습니다.');
          dispatch(setPositionChangeModalOpen(false));
          dispatch(getAllPositionsAsync({store_uid: currentStore.store_uid}))
            .unwrap()
            .then((payload) => {
              dispatch(setAllPositions(payload));
            });
        })
        .catch((err) => {
          alert(err);
        });
    },
    [dispatch, currentStore],
  );

  const handleDeleteBtn = useCallback(
    (position) => {
      if (window.confirm('직급을 삭제하시겠습니까?')) {
        dispatch(deleteAllPositionsAsync({position_uid: position.position_uid}))
          .unwrap()
          .then((payload) => {
            alert('삭제 되었습니다.');
            dispatch(setQuestionModalOpen(false));
            dispatch(getAllPositionsAsync({store_uid: currentStore.store_uid}))
              .unwrap()
              .then((payload) => {
                dispatch(setAllPositions(payload));
              });
          })
          .catch((err) => {
            alert(err);
          });
      }
    },
    [dispatch, currentStore],
  );

  const handleMemberClick = useCallback(
    (member) => {
      dispatch(
        getBrandAssignMentListAsync({
          store_uid: currentStore.store_uid,
          user_uid: member.user_uid,
        }),
      )
        .unwrap()
        .then((payload) => {
          dispatch(setBrandList(payload));
        });
    },
    [dispatch, currentStore],
  );

  const handleSubmit = (user) => {
    let list = [];
    brandAssinment.stores.forEach((store_uid) => {
      list.push(store_uid);
    });

    dispatch(
      postBrandAssignMentAsync({
        member_uid: member_uid.member_uid,
        user_uid: user.member.user_uid,
        store_list: list,
      }),
    );
  };

  const handleStoreClick = useCallback(
    (_store) => {
      dispatch(setCurrentStore(_store));
    },
    [dispatch],
  );

  const searchMemberByPhone = useCallback(
    (number) => {
      const sanitizedInput = '+82' + number.replace(/\s+/g, '').substr(1);

      dispatch(
        getPhoneJoinMemberAsync({
          phone_number: sanitizedInput,
        }),
      )
        .unwrap()
        .then(({kr, results}) => {
          alert(kr);
          dispatch(setOfferList(results));
        })
        .catch((error) => {
          dispatch(setOfferList(null));
          alert(error);
        });
    },
    [dispatch],
  );

  const inviteJoin = useCallback(
    (user_uid) => {
      dispatch(
        postPhoneJoinMemberAsync({
          user_uid: user_uid,
          store_uid: currentStore.store_uid,
        }),
      )
        .unwrap()
        .then(({kr}) => {
          alert(kr);
        })
        .catch((err) => alert(err));
    },
    [currentStore, dispatch],
  );
  return (
    <>
      <SettingsTemplate
        currentStore={currentStore}
        handleStoreClick={handleStoreClick}
        handlePositionManagement={handlePositionManagement}
        handleWorkerManagement={handleWorkerManagement}
        handleJoinReqManagement={handleJoinReqManagement}
        handleBrandManagement={handleBrandManagement}
        setSetting={setSetting}
        setting={setting}
        accessJoin={accessJoin}
        rejectJoin={rejectJoin}
        modifyMember={modifyMember}
        fireMember={fireMember}
        handleAddBtn={handleAddBtn}
        handleDeleteBtn={handleDeleteBtn}
        handleChangeBtn={handleChangeBtn}
        handleMemberClick={handleMemberClick}
        handleSubmit={handleSubmit}
        searchMemberByPhone={searchMemberByPhone}
        inviteJoin={inviteJoin}
      />
    </>
  );
};

export default Settings;
