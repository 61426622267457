import styled from 'styled-components';
import {Notification, Wrapper, Drawer} from 'shared/components/index';
import theme from 'shared/styles/theme';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {useMemo} from 'react';
import NotificationModal from './NotificationModal';

const Container = styled.div`
  width: 1080px;
  height: 45vh;
  margin-left: 60px;
  //margin-bottom: 10px;
`;

const PaginationContainer = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`;
const CustomPagination = styled(Pagination)`
  & .MuiPaginationItem-page.Mui-selected {
    background-color: ${theme.colors.kalsPurple};
  }
  & .MuiPaginationItem-page.Mui-active {
    background-color: ${theme.colors.kalsPurple};
  }
  & .MuiPaginationItem-page:hover {
    background-color: ${theme.colors.kalsPurple}; // 원하는 색상으로 수정하세요.
  }
  & .MuiPaginationItem-page.Mui-selected:hover {
    background-color: #8f77d9; // 원하는 색상으로 수정하세요.
  }
`;

const NotificationsTemplate = ({
  currentStore,
  notifications,
  handleNoticeClick,
  handlePaginationChange,
  count,
}) => {
  const renderNotifications = useMemo(() => {
    if (notifications?.length === 0) {
      return <p style={{fontSize: '20px'}}>읽지 않은 알림이 없습니다.</p>;
    }
    return notifications.map((notification, indx) => (
      <Notification
        key={indx.toString()}
        notification={notification}
        onPress={() => handleNoticeClick(notification)}
      />
    ));
  }, [handleNoticeClick, notifications]);

  return (
    <Wrapper titleText={currentStore.store_name}>
      <Drawer>
        <Container>
          {renderNotifications}
          <NotificationModal />
          {notifications.length > 0 && (
            <PaginationContainer>
              <Stack spacing={2}>
                <CustomPagination
                  onChange={handlePaginationChange}
                  count={count()}
                  //color="primary"
                />
              </Stack>
            </PaginationContainer>
          )}
        </Container>
      </Drawer>
    </Wrapper>
  );
};

export default NotificationsTemplate;
