import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {MuiModal, Text} from 'shared/components/index';
import CloseIcon from '@mui/icons-material/Close';
import {
  selectPosition,
  setMemberPositionChangeModalOpen,
} from 'owler/utils/slices/positionSlice';

const Container = styled.div`
  width: 300px;
  margin-left: 3%;
`;
const PositionWrap = styled.div`
  width: 100%;
  margin-bottom: 20px;
  background-color: #d8d8d8;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  &:hover {
    cursor: pointer;
  }
`;
const AllPositionWrap = styled.div`
  background-color: #f2f2f2;
  border-radius: 15px;
  padding: 20px 20px 5px 20px;
  margin: 20px 0 50px 0;
`;
const TitleWrapper = styled.div`
  display: flex;
  padding: 0 10px;
  margin: 15px 0 5px 0;
  width: 100%;
`;
const StyledText = styled(Text)`
  width: 50%;
  text-align: center;
`;
const BtnWrapper = styled.button`
  border: none;
  cursor: pointer;
  position: relative;
  top: 60px;
  left: -5px;
`;
const TitleWrapper2 = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0px;
`;
const MemberPositionChangeModal = ({modifyMember, selected}) => {
  const {memberPositionChangeModalOpen, allPositions} =
    useSelector(selectPosition);
  const dispatch = useDispatch();
  const renderLevel = (position) => {
    if (position.authority_level === 0) return '사장';
    if (position.authority_level === 1) return '매니저';
    if (position.authority_level === 2) return '정직원';
    if (position.authority_level === 3) return '아르바이트';
  };
  const handleModalClose = () => {
    dispatch(setMemberPositionChangeModalOpen(false));
  };

  return (
    <MuiModal open={memberPositionChangeModalOpen} onClose={handleModalClose}>
      <Container>
        <TitleWrapper2>
          <BtnWrapper onClick={handleModalClose}>
            <CloseIcon />
          </BtnWrapper>
        </TitleWrapper2>
        <AllPositionWrap>
          <TitleWrapper>
            <StyledText bold>직급명</StyledText>
            <StyledText bold>권한</StyledText>
          </TitleWrapper>
          {allPositions?.map((position, index) => {
            return (
              <div style={{display: 'flex'}}>
                <PositionWrap
                  key={index}
                  onClick={() => {
                    modifyMember(selected.member, position);
                  }}
                >
                  <StyledText bold>{position.name}</StyledText>
                  <StyledText bold>{renderLevel(position)}</StyledText>
                </PositionWrap>
              </div>
            );
          })}
        </AllPositionWrap>
      </Container>
    </MuiModal>
  );
};
export default MemberPositionChangeModal;
