import React from 'react';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import {MuiModal} from 'shared/components/index';
import {useDispatch, useSelector} from 'react-redux';
import {
  selectTask,
  setRealTimePhotoImageModal,
} from 'owler/utils/slices/taskSlice';

const Container = styled.div`
  background-color: white;
  width: 900px;
  height: 700px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 5px;
`;

const BtnWrapper = styled.button`
  border: none;
  background-color: white;
  margin-left: 20px;
  cursor: pointer;
`;

const Image = styled.img`
  height: 620px;
  width: auto;
`;

const RealTimePhotoImageModal = () => {
  const dispatch = useDispatch();
  const {realTimePhotoImageModal, realTimePhotoImage} = useSelector(selectTask);
  const handleModalClose = () => {
    dispatch(setRealTimePhotoImageModal(false));
  };
  return (
    <MuiModal open={realTimePhotoImageModal} onClose={handleModalClose}>
      <Container>
        <TitleWrapper>
          <BtnWrapper onClick={handleModalClose}>
            <CloseIcon />
          </BtnWrapper>
        </TitleWrapper>

        <Image src={realTimePhotoImage} alt="Image" />
      </Container>
    </MuiModal>
  );
};
export default RealTimePhotoImageModal;
