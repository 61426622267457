import React from 'react';
import {useCallback, useState, useEffect} from 'react';
import SupplementationTemplate from './template/index';
import {
  getSvSupplementationAsync,
  getSvSupplementationForPdfAsync,
  selectFranchiseSupplemention,
  setStartDate,
  setEndDate,
} from 'franchise/utils/slices/frSupplementionSlice';
import {useDispatch, useSelector} from 'react-redux';

const formatDate = (date) => {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  year = year.toString();

  return [year, month, day].join('-');
};

const Supplementation = () => {
  const dispatch = useDispatch();
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 6);
  const [start, setStart] = useState(new Date(currentDate));
  const [end, setEnd] = useState(new Date());
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const {supplementionList} = useSelector(selectFranchiseSupplemention);
  const [extraImageShowModalOpen, setExtraImageShowModalOpen] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      getSvSupplementationAsync({
        page: page,
        start_date: formatDate(start),
        end_date: formatDate(end),
      }),
    )
      .unwrap()
      .then(() => setIsLoading(false));
  }, [dispatch, page, start, end]);

  const search = useCallback((data) => {
    let differenceInTime = data.endDate?.getTime() - data.startDate?.getTime();
    let differenceInDays = differenceInTime / (1000 * 3600 * 24);

    if (differenceInDays > 6) {
      alert('최대 설정가능한 조회기간은 7일 입니다.');
      return;
    }

    setPage(1);
    setStart(data.startDate);
    setEnd(data.endDate);
  }, []);

  const count = () => {
    return supplementionList?.count?.request_count !== null
      ? Math.ceil(supplementionList?.count?.request_count / 10)
      : 1;
  };

  const handlePaginationChange = useCallback((e, p) => {
    setPage(p);
  }, []);

  const handlePdfButtonClick = useCallback(() => {
    dispatch(setStartDate(formatDate(start)));
    dispatch(setEndDate(formatDate(end)));

    dispatch(
      getSvSupplementationForPdfAsync({
        start_date: formatDate(start),
        end_date: formatDate(end),
      }),
    )
      .unwrap()
      .then(() => {
        const url = `/franchise/supplementation/veiwPdf`;
        window.open(url, '_blank');
        //navigate(`/franchise/supplementation/veiwPdf`);
      })
      .catch((error) => alert(error));
  }, [dispatch, start, end]);

  return (
    <SupplementationTemplate
      supplementionList={supplementionList}
      start={start}
      end={end}
      formatDate={formatDate}
      search={search}
      count={count}
      page={page}
      handlePaginationChange={handlePaginationChange}
      handlePdfButtonClick={handlePdfButtonClick}
      isLoading={isLoading}
      extraImageShowModalOpen={extraImageShowModalOpen}
      setExtraImageShowModalOpen={setExtraImageShowModalOpen}
    />
  );
};

export default Supplementation;
