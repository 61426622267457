/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
import React, {useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import BullhornIcon from 'shared/assets/icons/BullhornIcon';
import PinIcon from 'shared/assets/icons/PinIcon';
import {TableData, TableDataGroup, TableRow} from 'shared/components/index';
import theme from 'shared/styles/theme';

import styled from 'styled-components';
import {
  getRequestDetailAsync,
  setCurrentRequest,
  setCurrentRequestUid,
} from 'owler/utils/slices/requestSlice';
import {useDispatch, useSelector} from 'react-redux';

const Wrapper = styled.div`
  margin-bottom: 10px;
`;

const TaskCategoryWrapper = styled.div`
  width: 100%;
  border: 1px solid black;
  padding: 5px;
  margin-bottom: 5px;
  margin-left: 45px;
  border: ${({theme}) => `1px solid ${theme.colors.grey9}`};
  border-radius: 10px;
  padding: 5px 20px;
  display: flex;
  font-size: 13px;
  &:hover {
    & > * {
      cursor: pointer;
    }
    & a {
      font-weight: bold;
      text-decoration: underline;
    }
    border: ${({theme}) => `1px solid ${theme.colors.medium}`};
    background-color: ${({theme}) => theme.colors.lightYellow1};
  }
  background-color: ${(props) =>
    props?.notice?.is_pinned === true ? '#F8ECE0;' : ''};
  border: 1px solid
    ${(props) =>
      props?.notice?.is_pinned === true ? '#F7F8E0;' : `${theme.colors.grey9}`};
`;

const State = styled.div`
  background-color: ${({color}) =>
    color ? color : `${theme.colors.kalsPurple}`};
  width: 62px;
  height: 25px;
  border-radius: 15px;
  text-align: center;
  color: white;
  font-size: 12px;
  line-height: 25px;
  margin-left: -2px;
`;
const StyledDiv = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const renderCategory = (category) => {
  if (category === 1) return <State>A</State>;
  if (category === 2) return <State>B</State>;
  if (category === 3) return <State>C</State>;
  if (category === 4) return <State>D</State>;
  else
    return (
      <p style={{fontSize: '13px', textAlign: 'center', width: '62px'}}>
        {category}
      </p>
    );
};

const rendeGroup = (group) => {
  if (group === '가맹') return <State color="#A4A4A4">{group}</State>;
  else return <State>{group}</State>;
};

const renderState = (status) => {
  if (status === '처리중') return <State color="#0070C0">{status}</State>;
  if (status === '보류') return <State color="#FF4343">{status}</State>;
  if (status === '처리완료') return <State color="#00B050">{status}</State>;
  if (status === '미확인') return <State color="#A4A4A4">{status}</State>;
  else return <State>{status}</State>;
};

const MasterRequest = ({request}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleRequestClick = useCallback(
    (_request) => {
      dispatch(
        getRequestDetailAsync({
          request_uid: request.request_uid,
        }),
      )
        .unwrap()
        .then((payload) => {
          dispatch(setCurrentRequest(payload));

          dispatch(setCurrentRequestUid(_request.request_uid));

          navigate(`/owler/request/requestDetail/${_request.request_uid}`);
        })
        .catch((error) => {
          console.error('Error getting request detail:', error);
        });
    },
    [dispatch, navigate, request],
  );

  const fromattedTime = (time) => {
    const noticeDate = new Date(time);
    const year = noticeDate.getFullYear();
    const month = noticeDate.getMonth() + 1;
    const day = noticeDate.getDate();

    if (month < 10 && day < 10) return `${year}.0${month}.0${day}`;
    if (month < 10) return `${year}.0${month}.${day}`;
    if (day < 10) return `${year}.${month}.0${day}`;
    return `${year}.${month}.${day}`;
  };

  const formattedTitle = (body) => {
    if (body !== null) {
      if (body?.length >= 30) {
        return `${body.substr(0, 30)} ···`;
      }
      return body;
    }
    return '';
  };

  return (
    <Wrapper>
      <TaskCategoryWrapper onClick={() => handleRequestClick(request)} border>
        <TableDataGroup>
          <TableData width="85px">
            {renderCategory(request?.request_category)}
          </TableData>
          <TableData width="74px">{rendeGroup(request?.group)}</TableData>

          <div
            style={{width: '365px', textAlign: 'left', padding: '0 0 0 10px'}}
          >
            {formattedTitle(request?.title)}
          </div>
        </TableDataGroup>

        <TableDataGroup
          width={'510px'}
          justifyContent="space-between"
          margin="0 -10px 0 0"
        >
          <TableData width="100px">
            <StyledDiv style={{border: '', width: '100%', textAlign: 'center'}}>
              {fromattedTime(request?.created_on)}
            </StyledDiv>
          </TableData>
          <TableData width="200px">
            <StyledDiv style={{border: '', width: '100%', textAlign: 'center'}}>
              {request?.store_name}
            </StyledDiv>
          </TableData>
          <TableData width="100px">
            <div style={{border: '', width: '100%', textAlign: 'center'}}>
              {request?.supervisor}
            </div>
          </TableData>
          <TableData width="80px">
            <div
              style={{
                border: '',
                marginLeft: '25%',
                width: '75%',
                textAlign: 'right',
              }}
            >
              {renderState(request?.status)}
            </div>
          </TableData>
        </TableDataGroup>
      </TaskCategoryWrapper>
    </Wrapper>
  );
};

export default MasterRequest;
