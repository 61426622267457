/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import styled from 'styled-components';

import theme from 'shared/styles/theme';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';

import {selectFranchiseUser} from 'franchise/utils/slices/frUserSlice';

const drawerWidth = 170;

const StyledDrawer = styled(Drawer)`
  &.MuiDrawer-root .MuiDrawer-paper {
    position: absolute;
    height: 960px;
    top: ${(props) => (props.why ? '-40px' : '-152.9px')};
    left: -28px; // 부모 요소를 기준으로 왼쪽에 배치하려면 이 속성을 사용합니다.
  }
`;
const MainBox = styled(Box)`
  position: relative;
  left: 80px;
`;
const StyledBox = styled(Box)`
  width: 1280px;
`;

const Content = styled.div`
  flex: 1;
  margin: 0 auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const StyledBox2 = styled(Box)`
  position: relative;
  min-width: 1300px;
  @media (max-width: 1300px) {
    margin-left: 20px;
  }
  @media (min-width: 1300px) and (max-width: 1304px) {
    margin-left: 18px;
  }
  @media (min-width: 1305px) and (max-width: 1308px) {
    margin-left: 16px;
  }
  @media (min-width: 1309px) and (max-width: 1312px) {
    margin-left: 14px;
  }
  @media (min-width: 1313px) and (max-width: 1316px) {
    margin-left: 12px;
  }
  @media (min-width: 1317px) and (max-width: 1320px) {
    margin-left: 10px;
  }
  @media (min-width: 1321px) and (max-width: 1324px) {
    margin-left: 8px;
  }
  @media (min-width: 1325px) and (max-width: 1328px) {
    margin-left: 6px;
  }
  @media (min-width: 1329px) and (max-width: 1332px) {
    margin-left: 4px;
  }
  @media (min-width: 1333px) and (max-width: 1336px) {
    margin-left: 2px;
  }
  @media (min-width: 1337px) and (max-width: 1340px) {
    margin-left: 1px;
  }

  diplay: flex;
  justify-content: center;
`;
function ResponsiveDrawer({children, why}) {
  const {t} = useTranslation();
  const {supervisorData} = useSelector(selectFranchiseUser);
  const location = useLocation();
  const navigate = useNavigate();

  const checkLoc = useCallback(
    (cur) => {
      if (location.pathname === cur) {
        return theme.colors.kalsBlue;
      } else if (location.pathname.startsWith(cur)) {
        return theme.colors.black;
      }
      return theme.colors.black;
    },
    [location.pathname],
  );

  const mainRoutes = [
    {
      name: t('buttons.dashboard'),
      link: `/franchise/dashboard`,
    },
    {
      name: '매장',
      link: `/franchise/qscStoreList`,
    },
    // {
    //   name: '캘린더',
    //   link: `/franchise/calendar`,
    // },
    {
      name: '보완 요청',
      link: `/franchise/supplementation`,
    },
    {
      name: 'QSC',
      link: `/franchise/qsc`,
    },
    // {
    //   name: '공지',
    //   link: `/franchise/notice`,
    // },
    // {
    //   name: '일정 공유',
    //   link: `/franchise/scheduleAdd`,
    // },
    {
      name: '설정',
      link: `/franchise/settings`,
    },
  ];

  const handleNavigation = useCallback(
    (_route) => {
      navigate(_route.link);
    },
    [navigate],
  );

  const drawer = (
    <div>
      <Toolbar />
      <Toolbar />
      <ListItem>
        <ListItemButton>
          <ListItemText primary={supervisorData?.userName} />

          <ListItemText
            disableTypography
            primary={
              <Typography
                variant="body2"
                style={{color: theme.colors.medium, marginLeft: '-80px'}}
              ></Typography>
            }
          />
        </ListItemButton>
      </ListItem>

      <Divider />

      <List>
        {mainRoutes.map((route, index) => (
          <ListItem key={route.name}>
            <ListItemButton onClick={() => handleNavigation(route)}>
              <ListItemText
                primary={route.name}
                style={{color: checkLoc(route.link)}}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      {/* 
      <Divider /> */}
    </div>
  );

  return (
    <StyledBox2 sx={{display: 'flex'}}>
      <StyledBox
        component="nav"
        sx={{width: {sm: 0}, flexShrink: {sm: 0}}}
        aria-label="mailbox folders"
      >
        <StyledDrawer
          why={why}
          variant="permanent"
          sx={{
            display: {xs: 'block', sm: 'block'},
            '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
          }}
          open
        >
          {drawer}
        </StyledDrawer>
      </StyledBox>

      <MainBox component="main" sx={{flexGrow: 1, p: 3}}>
        <Content>{children}</Content>
      </MainBox>
    </StyledBox2>
  );
}

export default ResponsiveDrawer;
