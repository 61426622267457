/* eslint-disable no-plusplus */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable no-console */
import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';

import QSCDetailTemplate from './template';
import {useDispatch, useSelector} from 'react-redux';
import {
  selectFranchiseQsc,
  setQscCheckCatName,
  setQscCheckDetail,
  setQscCheckDetailModal,
  setQscExportInfo,
} from 'franchise/utils/slices/frQscSlice';
import api from 'shared/utils/api';

const QSCDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {qsc_uid} = useSelector(selectFranchiseQsc);
  const [error, setError] = useState(null);
  const [qscCheckLists, setQscCheckLists] = useState([]);
  const [scoreTitleList, setscoreTitleList] = useState([]);

  const getQSCCheckListAsync = useMemo(
    () => async () => {
      try {
        const response = await api.getQSCChecklist({qsc_uid});
        const {results} = response.data;
        setQscCheckLists(results?.category_list);
        setscoreTitleList(results?.score_title_list);
        dispatch(setQscExportInfo(results));
      } catch (e) {
        setError({opened: true, error: e});
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // backend not ready yet 😶
  // eslint-disable-next-line no-unused-vars
  // const getQSCCInfoForExportAsync = useMemo(
  //   () => async () => {
  //     try {
  //       const response = await api.getQSCCInfoForExport({qsc_uid});

  //       const {results} = response.data;
  //       dispatch(setQscExportInfo(results));
  //     } catch (e) {
  //       setError({opened: true, error: e});
  //     }
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [],
  // );

  useEffect(() => {
    getQSCCheckListAsync();
    // setQscExportInfo(sampleQscExportInfo1);
    //getQSCCInfoForExportAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Go back to chat room list
  const handleListBtnClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleCheckListClick = useCallback(
    (_qscDetail, _catName) => {
      dispatch(setQscCheckDetail(_qscDetail));
      dispatch(setQscCheckCatName(_catName));
      dispatch(setQscCheckDetailModal(true));
    },
    [dispatch],
  );

  const handleExportBtn = useCallback(() => {
    window.open('/qsc/pdf-viewer', '_blank');
  }, []);

  const handleViewPdf = useCallback(() => {
    window.open(`/franchise/qscDetail/viewPDF/${qsc_uid}`, '_blank');
  }, [qsc_uid]);

  return (
    <>
      <QSCDetailTemplate
        handleListBtnClick={handleListBtnClick}
        handleCheckListClick={handleCheckListClick}
        qscCheckLists={qscCheckLists}
        scoreTitleList={scoreTitleList}
        handleExportBtn={handleExportBtn}
        handleViewPdf={handleViewPdf}
      />
    </>
  );
};

export default QSCDetail;
