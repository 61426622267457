import React from 'react';
import Grid from '@mui/material/Grid';
import styled from 'styled-components';
import theme from 'shared/styles/theme';
import AppText from 'shared/components/AppText';
import DownloadIcon from '@mui/icons-material/Download';

import ImageRegularIcon from 'shared/assets/icons/ImageRegularIcon';

const MessageBubble = styled.div`
  background-color: ${theme.colors.kalsPurple};
  padding: 8px 12px;
  border-radius: 10px;
  display: inline-block;
  max-width: 70%;
  color: white;
`;

// const UnreadCount = styled.div`
//   color: purple;
//   font-weight: bold;
// `;

const DateTime = styled.div`
  color: gray;
  font-size: 0.75rem;
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const MessageMeta = styled.div`
  margin-right: 8px;
`;

const StyledImg = styled.img`
  width: 145px;
  height: 110px;

  object-fit: contain;
  background-color: #bdbdbd;
`;

const OwnMessageBox = ({message, setSelectedMessage, setTaskImageModal}) => {
  const {message: msg, message_type, files, images, created_on} = message;

  const date = new Date(created_on).toLocaleDateString();
  const time = new Date(created_on).toLocaleTimeString();

  return (
    <Grid item xs={12} style={{display: 'flex', justifyContent: 'flex-end'}}>
      <AppText />

      {message_type === 'message' && (
        <MessageContainer>
          <MessageMeta>
            {/* {chatData?.unread_count > 0 && (
            <UnreadCount>{chatData.unread_count}</UnreadCount>
          )} */}
            <DateTime>
              {date} {time}
            </DateTime>
          </MessageMeta>

          <MessageBubble>{msg}</MessageBubble>
        </MessageContainer>
      )}

      {message_type === 'file' && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          {files.map((file, index) => (
            <div
              style={{
                width: '80%',
                padding: '10px',
                borderRadius: '10px',
                border: '1px solid #e0e0e0',
                backgroundColor: '#f5f5f5',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: '10px',
              }}
              key={index}
            >
              <a
                href={file.file ? file.file : file.file_uri}
                download
                style={{
                  display: 'block',
                  marginBottom: '10px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  marginRight: '10px',
                }}
              >
                {file.file_name.length > 60
                  ? file.file_name.substring(0, 60) + '...'
                  : file.file_name}
              </a>

              <a href={file.file ? file.file : file.file_uri} download>
                <DownloadIcon style={{color: 'grey'}} />
              </a>
            </div>
          ))}
        </div>
      )}

      {message_type === 'image' && (
        <div
          style={{
            borderRadius: '10px',
            overflow: 'hidden',
            boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.1)',
            cursor: 'pointer',
            border: '1px solid #e0e0e0',
            position: 'relative',
          }}
          onClick={() => {
            setSelectedMessage(message);
            setTaskImageModal(true);
          }}
        >
          <StyledImg
            src={typeof images[0] === 'string' ? images[0] : images[0]?.image}
          />
          <div
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              color: 'white',
              width: '100%',
              height: '100%',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <ImageRegularIcon fill="white" length={40} />
          </div>
        </div>
      )}
    </Grid>
  );
};

export default OwnMessageBox;
