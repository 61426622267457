import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import {Text} from 'shared/components/index';
import theme from 'shared/styles/theme';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  z-index: 3000;
`;

const AppIconBtn = styled.button`
  width: 30px;
  height: 30px;
  background: none;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50%;
  padding: 0;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${theme.colors.secondaryLight};
  }
`;

const AppText = styled(Text)`
  font-size: 16px;
  font-weight: 500;
  margin: 0 5px;
`;

const WeekNumberSelector = ({
  handleChangeWeekBack,
  handleChangeWeekForward,
  displayDateWeekly,
  clickCount,
}) => {
  const {t} = useTranslation();
  return (
    <Container>
      <AppIconBtn onClick={handleChangeWeekBack}>
        <ChevronLeftIcon />
      </AppIconBtn>

      <AppText>
        {displayDateWeekly.slice(0, 4)}
        {t('screens.ai.year')} {displayDateWeekly.slice(5)}
        {t('screens.ai.month')} {clickCount}
        {t('screens.ai.ai_month_week_count')}
      </AppText>

      <AppIconBtn onClick={handleChangeWeekForward}>
        <ChevronRightIcon />
      </AppIconBtn>
    </Container>
  );
};

export default WeekNumberSelector;
