import React, {useEffect} from 'react';
import EventListTemplate from './template/index';

import {useNavigate} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {
  selectMap,
  getEventListAsync,
  setEventList,
} from 'shared/utils/redux/mapSlice';

const EventList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {selectedMarker, eventList} = useSelector(selectMap);

  useEffect(() => {
    dispatch(getEventListAsync({store_uid: selectedMarker?.store_uid})).then(
      (payload) => {
        dispatch(setEventList(payload.payload.results));
      },
    );
  }, [dispatch, selectedMarker]);

  const goback = () => {
    navigate(-1);
  };
  const clickEvent = (item) => {
    navigate(`/eventDetail?event_uid=${item.event_uid}`, {state: {item}});
  };

  return (
    <EventListTemplate
      goback={goback}
      eventList={eventList}
      clickEvent={clickEvent}
    />
  );
};
export default EventList;
