import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {toast} from 'react-hot-toast';

import {
  addTaskCategoryAsync,
  getStoreTasksByDateAsync,
  selectTask,
  setAddCategoryModal,
  setAddCategoryModalInput,
} from 'owler/utils/slices/taskSlice';
import {MuiButton, MuiModal, Text, TextArea} from 'shared/components/index';
import {selectStore} from 'owler/utils/slices/storeSlice';
import {formatDateRangeForApi} from 'shared/utils/time';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 40px;
  align-items: center;
  overflow: hidden;
  & > * {
    margin-bottom: 30px;
  }

  & > :last-child {
    margin-bottom: 0px;
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const CategoryAddModal = () => {
  const {currentStore} = useSelector(selectStore);
  const {addCategoryModal, addCategoryModalInput} = useSelector(selectTask);
  const dispatch = useDispatch();

  const handleModalClose = () => {
    dispatch(setAddCategoryModal(false));
  };

  const handleInputChange = (event) => {
    dispatch(setAddCategoryModalInput(event.target.value));
  };

  const handleDoneBtn = () => {
    if (addCategoryModalInput === '') {
      alert('카테고리 명을 입력해주세요!');
      return;
    }
    if (addCategoryModalInput.length >= 31) {
      alert('30자 이내로 작성해주세요!');
      return;
    }
    if (addCategoryModalInput !== '') {
      const newCategoryData = {
        store_uid: currentStore.store_uid,
        name: addCategoryModalInput,
      };

      dispatch(addTaskCategoryAsync(newCategoryData))
        .unwrap()
        .then((payload) => {
          toast.success('Created successfully!');
          dispatch(setAddCategoryModal(false));
          dispatch(setAddCategoryModalInput(''));
          const selectedDate = new Date();
          const year = selectedDate.getFullYear();
          const month = (selectedDate.getMonth() + 1)
            .toString()
            .padStart(2, '0'); // 월을 0으로 패딩
          const day = selectedDate.getDate().toString().padStart(2, '0'); // 일을 0으로 패딩
          const date = `${year}-${month}-${day}`;
          dispatch(
            getStoreTasksByDateAsync({
              store_uid: currentStore?.store_uid,
              date,
            }),
          );
        })
        .catch((err) => alert(err.message));
    } else {
      dispatch(setAddCategoryModal(false));
      dispatch(setAddCategoryModalInput(''));
    }
  };

  return (
    <MuiModal open={addCategoryModal} onClose={handleModalClose}>
      <Container>
        <TitleWrapper>
          <Text bold fontSize="xl">
            카테고리 추가
          </Text>
        </TitleWrapper>
        <TextArea
          value={addCategoryModalInput}
          width="300px"
          placeholder="ex) 메뉴 및 식자재 관리 (30자 제한)"
          onChange={handleInputChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') e.preventDefault();
          }}
          fontSize="16px" // optional, specify the desired font size
          padding="10px" // optional, specify the desired padding
          rows={4} // optional, specify the desired number of rows
          cols={30} // optional, specify the desired number of columns
          height="200px" // optional, specify the desired height
        />
        {addCategoryModalInput?.length >= 31 && (
          <p style={{color: '#FF0000'}}>30자 이상입니다.</p>
        )}

        <ButtonContainer>
          <div></div>
          <div>
            <MuiButton
              onClick={handleModalClose}
              variant="contained"
              backgroundColor="#bdbdbd"
              textcolor="#f8f8f8"
              disabled={addCategoryModalInput?.length >= 31 ? true : false}
              text="취소"
              style={{margin: '0 10px 0 0'}}
            />
            <MuiButton
              onClick={handleDoneBtn}
              variant="contained"
              backgroundColor={
                addCategoryModalInput?.length >= 31 ? '#f2f2f2' : '#8773D6'
              }
              textcolor={
                addCategoryModalInput?.length >= 31 ? '#000000' : '#f8f8f8'
              }
              disabled={addCategoryModalInput?.length >= 31 ? true : false}
              text="등록"
            />
          </div>
        </ButtonContainer>
      </Container>
    </MuiModal>
  );
};
export default CategoryAddModal;
