import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const TextInput = styled.input.attrs(() => ({
  type: 'text',
}))`
  ${({ padding, fontSize, theme }) => css`
    padding: ${theme.paddings[padding]};
    font-size: ${theme.fontSizes[fontSize]};
  `};
  width: 100%;
  background-color: #f8f8f8;
  border: none;
  border-radius: 20px;
`;

const TextField = (props) => {
  const { value, name, placeholder, padding, fontSize, onChange } = props;

  return (
    <TextInput
      value={value}
      name={name}
      placeholder={placeholder}
      padding={padding}
      fontSize={fontSize}
      onChange={onChange}
    />
  );
};

TextField.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  padding: PropTypes.string,
  fontSize: PropTypes.string,
};

TextField.defaultProps = {
  padding: 'base',
  fontSize: 'base',
};

export default TextField;
