import React from 'react';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import {MuiModal} from 'shared/components/index';
import {useDispatch, useSelector} from 'react-redux';
import {selectTask, setExtraEmployeeModal} from 'owler/utils/slices/taskSlice';
import {Text} from 'shared/components/index';
import theme from 'shared/styles/theme';

const EachScheduleWrapper = styled.div`
  height: 400px;
  //background-color: #ff0000;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 13px;
  }
  ::-webkit-scrollbar-track {
    background: #ffffff;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  width: 600px;
  border-bottom: 3px solid ${theme.colors.grey9};
  border-top: 3px solid ${theme.colors.grey9};
  margin-top: 10px;
`;
const EachSchedule = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-top: 2px solid ${theme.colors.grey9};
  border-bottom: ${({lastCell}) =>
    lastCell && `2px solid ${theme.colors.grey9}`};
  margin: 5px 0;
`;
const SchedulesHeader = styled.div`
  width: 100%;
  display: flex;
`;
const WorkerTimes = styled.div`
  width: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5px;
  padding: 2px;
`;

const TimeWrap = styled.div`
  display: flex;
`;

const Container = styled.div`
  background-color: white;
  width: 700px;
  height: 560px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 5px;
`;

const BtnWrapper = styled.button`
  border: none;
  background-color: white;
  margin-left: 20px;
  cursor: pointer;
`;

const ExtraEmployeeModal = ({workSchedules}) => {
  const dispatch = useDispatch();
  const {extraEmployeeModal, realTimePhotoImage} = useSelector(selectTask);
  const handleModalClose = () => {
    dispatch(setExtraEmployeeModal(false));
  };
  return (
    <MuiModal open={extraEmployeeModal} onClose={handleModalClose}>
      <Container>
        <TitleWrapper>
          <Text bold fontSize="xlarge" margin="0 250px 0 0">
            근무표
          </Text>

          <BtnWrapper onClick={handleModalClose}>
            <CloseIcon />
          </BtnWrapper>
        </TitleWrapper>
        <SchedulesHeader>
          <Text fontSize="meduim" bold margin="0px 0 0px 110px">
            근무자명
          </Text>

          <Text fontSize="meduim" bold margin="0px 0 0px 120px">
            근무 일정
          </Text>

          <Text fontSize="meduim" bold margin="0px 0 0px 130px">
            출/퇴근
          </Text>
        </SchedulesHeader>
        <EachScheduleWrapper workSchedules={workSchedules}>
          {workSchedules.work_schedule?.map((schedules, i) => (
            <EachSchedule
              key={i.toString()}
              lastCell={i === workSchedules.work_schedule.length - 1}
            >
              <Text>{schedules.name}</Text>

              <WorkerTimes>
                {schedules?.schedule?.map((time, i) => (
                  <TimeWrap key={i.toString()}>
                    <Text margin="4px">
                      {new Date(time?.start).getHours()}:
                      {new Date(time?.start)
                        .getMinutes()
                        .toString()
                        .padStart(2, '0')}
                    </Text>
                    ~
                    <Text margin="4px">
                      {new Date(time?.end).getHours()}:
                      {new Date(time?.end)
                        .getMinutes()
                        .toString()
                        .padStart(2, '0')}
                    </Text>
                  </TimeWrap>
                ))}
              </WorkerTimes>

              <Text>{schedules.name}</Text>
            </EachSchedule>
          ))}
        </EachScheduleWrapper>
      </Container>
    </MuiModal>
  );
};
export default ExtraEmployeeModal;
