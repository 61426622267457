/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable spaced-comment */
import React, {useState, useCallback, useRef} from 'react';
import styled, {css} from 'styled-components';
import {useLocation} from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import * as Yup from 'yup';
import {useDispatch} from 'react-redux';
import Select from '@mui/material/Select';
import Rating from '@mui/material/Rating';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import AppText from 'shared/components/AppText';
import AppText2 from 'shared/components/AppText2';
import AppWrapper from 'shared/components/AppWrapper';
import AppSimpleModal from 'shared/components/AppSimpleModal';
import {AppForm, AppFormField, SubmitBtn} from 'shared/components/forms/index';
import NewLogoCircle from 'shared/assets/icons/NewLogoCircle';
import {selectMap, postReviewAsync} from 'shared/utils/redux/mapSlice';
import {useSelector} from 'react-redux';
//import api from '../../utils/api';
import {catchError} from 'shared/utils/catchError';

import theme from 'shared/styles/theme';

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: ${({container}) => (container ? 'row' : 'column')};
  &:not(:last-child) {
    margin-right: 0px;
  }
  width: ${({width}) => width || '100%'};
  ${({center}) =>
    center &&
    css`
      margin: 0 auto;
    `}
`;
const Image = styled.img`
  width: 50px;
  height: 50px;
  margin: 0 5px;
  &:hover {
    cursor: pointer;
    opacity: 0.85;
  }
`;
const StyledContainer = styled.div`
  width: 95vw;
  min-width: 350px;
  max-width: 600px;
  height: 100vh;
  min-height: 650px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5px 10px;
  //overflow: scroll;
`;

const StyledLogoWrapper = styled.div`
  width: 90%;
  height: 70px;
  margin: 30px 0 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-start;
`;

const StyledStoreNameWrapper = styled.div`
  //min-width: 80%;
  height: 50px;
  margin-left: 1vw;
`;

const StyledSearchInputWrapper = styled.div`
  width: 90%;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-start;
  align-items: top;
`;

const StyledSimpleSelect = styled(Select)`
  && {
    border-radius: 15px;
  }
`;

const feedbackValidationSchema = Yup.object().shape({
  feedback: Yup.string()
    .min(20, '20자 이상의 내용으로 작성해주시길 바랍니다.')
    .required('내용을 작성해주세요')
    .label('내용'),
  phoneNum: Yup.string()
    .min(11, '연락처는 최소 11자리여야 합니다.')
    .max(11, '연락처는 최대 11자리여야 합니다.')
    .label('연락처'),
});

const StyledRatingsWrap = styled.div`
  width: 100%;
  margin: 10px 0 5px 0;
`;
const StyledImageUploadWrap = styled.div`
  width: 100%;
  position: relative;
`;
const Img = styled.img`
  width: 45px;
  height: 45px;
`;

const OwlerReviewTemplate = () => {
  const location = useLocation();
  const [alertModal, setAlertModal] = useState(false);
  const [ratingValue, setRatingValue] = useState(5);
  const [selectedImages, setSelectedImages] = useState([]);
  const {selectedMarker} = useSelector(selectMap);
  const inputRef = useRef();
  const dispatch = useDispatch();
  // category
  const [title, setTitle] = useState(0);

  // handle category change
  const handleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleFileChange = useCallback((e) => {
    const {files} = e.target;

    const newFiles = Object.keys(files).map((key) => {
      return files[key];
    });

    setSelectedImages((prevSelectedImages) => [
      ...prevSelectedImages,
      ...newFiles,
    ]);
  }, []);

  const handleImageClick = (index) => {
    if (window.confirm('이미지를 삭제하시겠습니까?')) {
      const newImages = [...selectedImages];
      newImages.splice(index, 1);
      setSelectedImages(newImages);
      document.getElementById('contained-button-file').value = '';
    }
  };

  const onSubmit = useCallback(
    async (values, actions) => {
      if (title === 0) {
        alert('카테고리를 선택하세요.');
      } else if (
        values.phoneNum !== '' &&
        values.phoneNum.slice(0, 3) !== '010'
      ) {
        alert('핸드폰 번호가 올바르지 않습니다.');
      } else {
        const feedbackObj = {
          store_uid: selectedMarker?.store_uid,
          category: title,
          contents: values.feedback,
          score: ratingValue,
        };

        if (values?.phoneNum?.length !== 0) {
          feedbackObj.contactNumber = values.phoneNum;
        }
        if (selectedImages?.length !== 0) {
          feedbackObj.images = selectedImages;
        }

        const form = new FormData();
        form.append('store_uid', selectedMarker?.store_uid);
        form.append('category', title);
        form.append('contents', values.feedback);
        if (values?.phoneNum?.length !== 0)
          form.append('contactNumber', values.phoneNum);
        if (selectedImages?.length !== 0) {
          selectedImages?.map((eachImg) =>
            form.append('images', eachImg, eachImg.name),
          );
        }

        form.append('score', ratingValue);

        try {
          dispatch(postReviewAsync(form))
            .unwrap()
            .then(() => {
              setAlertModal(true);

              setTimeout(() => {
                actions.resetForm();
                setTitle(0);
                setSelectedImages([]);
              }, 1000);
            })
            .catch((e) => {
              alert('요청이 실패했습니다. 다시 시도해주세요.', e.message);
            });
        } catch (e) {
          alert('요청이 실패했습니다. 다시 시도해주세요.', e.message);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedMarker, ratingValue, title],
  );

  return (
    <AppWrapper>
      <AppForm
        initialValues={{feedback: '', phoneNum: ''}}
        onSubmit={onSubmit}
        validationSchema={feedbackValidationSchema}
      >
        <StyledContainer>
          <StyledLogoWrapper>
            {selectedMarker?.store_logo === null ? (
              <NewLogoCircle length={45} />
            ) : (
              <Img src={selectedMarker?.store_logo} />
            )}

            <StyledStoreNameWrapper>
              <AppText
                fontSize="16px"
                weight="bold"
                text="고객의 소리에 귀 기울이는"
                margin="0 5px"
              />

              <AppText2
                weight="bold"
                fontSize="16px"
                margin="0 3px"
                color={theme.colors.kalsPurple}
                text={`${
                  selectedMarker !== null ? selectedMarker.store_name : ''
                } `}
                xFontSize="16px"
                extra="입니다"
              />
            </StyledStoreNameWrapper>
          </StyledLogoWrapper>

          <StyledSearchInputWrapper>
            {/* User Select Survey Category*/}
            <>
              <AppText
                text="분류*"
                weight="500"
                fontSize="16px"
                margin="0 0 16px 0"
              />

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  카테고리를 선택해주세요.
                </InputLabel>
                <StyledSimpleSelect
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={title}
                  label="카테고리를 선택해주세요."
                  onChange={handleChange}
                >
                  <MenuItem value={1}>건의사항</MenuItem>
                  <MenuItem value={2}>불편사항</MenuItem>
                  <MenuItem value={3}>기타</MenuItem>
                </StyledSimpleSelect>
              </FormControl>
            </>

            {/* User Feedback Input */}
            <>
              <AppText
                text="내용*"
                weight="500"
                fontSize="16px"
                margin="15px 0 13px 0"
              />

              <AppFormField
                backIcon={''}
                inputRef={inputRef}
                multiline
                rows={2}
                name="feedback"
                label="내용을 작성해주세요."
              />
            </>

            {/* Enter photo image: */}
            <StyledImageUploadWrap>
              <AppText
                text="사진"
                weight="500"
                fontSize="16px"
                margin="10px 5px 0px 0"
              />
              <FieldWrapper style={{marginTop: '1px'}} container={true}>
                <label htmlFor="contained-button-file">
                  <>
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                    >
                      <PhotoCamera
                        style={{
                          fontSize: '50px',
                          marginTop: '0px',
                          marginLeft: '-12px',
                        }}
                      />
                      <input
                        hidden
                        accept="image/*"
                        type="file"
                        id="contained-button-file"
                        multiple="multiple"
                        onChange={(e) => handleFileChange(e)}
                      />
                    </IconButton>
                  </>
                </label>
                {selectedImages && selectedImages.length > 0 && (
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    {selectedImages.map((img, indx) => (
                      <Image
                        src={URL.createObjectURL(img)}
                        alt={`Uploaded ${indx}`}
                        onClick={() => handleImageClick(indx)}
                      />
                    ))}
                  </div>
                )}
              </FieldWrapper>
            </StyledImageUploadWrap>

            {/* User Feedback Input */}
            <StyledRatingsWrap>
              <AppText
                text="평점"
                weight="500"
                fontSize="16px"
                margin="0px 5px 0px 0"
              />

              <Rating
                name="simple-controlled"
                size="large"
                value={ratingValue}
                style={{marginLeft: '-6px'}}
                onChange={(event, newValue) => {
                  setRatingValue(newValue);
                }}
              />
            </StyledRatingsWrap>

            {/* User Phone Number */}
            <>
              <AppText
                text="연락처 "
                weight="500"
                fontSize="16px"
                margin="5px 5px 10px 0"
                xFontSize="10px"
                xColor={theme.colors.medium}
                extra=" 추첨을 통해 기프트콘을 드립니다."
              />

              <AppFormField
                backIcon={''}
                multiline
                name="phoneNum"
                label="ex. 01012345678"
                inputProps={{inputMode: 'numeric'}}
              />
            </>
          </StyledSearchInputWrapper>

          <SubmitBtn type="submit" text="제출" />
        </StyledContainer>

        <AppSimpleModal
          isOpen={alertModal}
          bodyText="성공적으로 전송되었습니다."
          btnTwo="확인"
          handleClose={() => {
            setAlertModal(false);
            inputRef.current.value = '';
          }}
        />
      </AppForm>
    </AppWrapper>
  );
};

export default OwlerReviewTemplate;
