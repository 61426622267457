import React from 'react';
import {useTranslation} from 'react-i18next';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {useSelector} from 'react-redux';
import styled from 'styled-components';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Fab from '@mui/material/Fab';
import SendIcon from '@mui/icons-material/Send';
import {makeStyles} from '@mui/styles';
import {TextField} from '@mui/material';

import AppText from 'shared/components/AppText';
import {Button} from 'shared/components/index';
import AppSearchBox from 'shared/components/organisms/AppSearchBox';
import {selectStore} from 'owler/utils/slices/storeSlice';
import TaskImageModal from 'owler/pages/TaskDetail/template/TaskImageModal';
import OwnMessageBox from './OwnMessageBox';
import SenderMessageBox from './SenderMessageBox';
import ChatCardList from './ChatCardList';

const ChatSection = styled(Paper)(({theme}) => ({
  width: '100%',
  height: '88vh',
  border: '1px solid #e0e0e0',
  borderRadius: '1px',
  boxShadow: 'none',
}));

const Form = styled.form`
  border-radius: 15px;
`;

const FloatingButton = styled(Button)`
  position: absolute;
  bottom: 80px;
  right: 10px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
`;

const RelativeList = styled(List)`
  position: relative;
  padding-bottom: 70px;
  height: 92%;
  overflow-y: scroll;
`;

const useStyles = makeStyles({
  chatSection: {
    width: '100%',
    height: '80vh',
    boxShadow: 'none',
    border: 'none',
  },
  headBG: {
    backgroundColor: '#e0e0e0',
  },
  borderLeft500: {
    height: '88vh',
    boxShadow: 'none',
    borderLeft: '1px solid #e0e0e0',
  },
  messageArea: {
    height: '72vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '10px',
  },
});

const validationSchema = Yup.object({
  message: Yup.string().required('Required'),
});

const InlineTextField = ({formikProps, label, name, ...restProps}) => {
  return (
    <>
      <TextField
        {...restProps}
        name={name}
        value={formikProps.values[name]}
        onChange={formikProps.handleChange}
        error={formikProps.touched[name] && Boolean(formikProps.errors[name])}
        helperText={
          (formikProps.touched[name] && formikProps.errors[name]) || ' '
        }
        variant="filled"
        fullWidth
        placeholder={label}
        sx={{
          width: '100%',
          '& .MuiFilledInput-root': {
            backgroundColor: 'white',
          },
        }}
      />
    </>
  );
};

const Chat = ({
  chatData,
  onSubmit,
  chatDataList,
  filteredChatRoomList,
  handleAddChat,
  handleChatRoomPress,
  handleChatActions,
  topMessageRef,
  messagesEndRef,
  handleScroll,
  setUploadMediaModal,
  setTaskImageModal,
  taskImageModal,
  selectedMessage,
  setSelectedMessage,
  search,
  searchFilter,
}) => {
  const {t} = useTranslation();
  const classes = useStyles();
  const {memberInfo} = useSelector(selectStore);

  return (
    <ChatSection elevation={0}>
      <Grid
        sx={{border: 'none', boxShadow: 'none'}}
        container
        component={Paper}
        className={classes.chatSection}
      >
        <Grid item xs={8}>
          {chatData !== null ? (
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <AppText />

                <AppText
                  fontSize="18px"
                  weight="600"
                  margin="5px 0 5px 0"
                  text={chatData?.title}
                />

                <button
                  style={{border: 'none', backgroundColor: 'transparent'}}
                  onClick={handleChatActions}
                >
                  <MoreVertIcon />
                </button>
              </div>

              <List
                ref={topMessageRef}
                onScroll={handleScroll}
                className={classes.messageArea}
              >
                {chatDataList?.map((_chatData, _index) => (
                  <ListItem key={_index.toString()}>
                    {memberInfo?.member_uid === _chatData?.member_uid ? (
                      <OwnMessageBox
                        message={_chatData}
                        setSelectedMessage={setSelectedMessage}
                        setTaskImageModal={setTaskImageModal}
                      />
                    ) : (
                      <SenderMessageBox
                        message={_chatData}
                        setSelectedMessage={setSelectedMessage}
                        setTaskImageModal={setTaskImageModal}
                      />
                    )}
                  </ListItem>
                ))}

                <div ref={messagesEndRef} />
              </List>

              <Divider />

              <Formik
                initialValues={{
                  message: '',
                }}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
              >
                {(formikProps) => {
                  return (
                    <Form
                      onBlur={formikProps.handleBlur}
                      onSubmit={formikProps.handleSubmit}
                    >
                      <Grid
                        container
                        style={{
                          height: '100px',
                          padding: '0 10px 0 10px',
                        }}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item xs={2} align="left">
                          <Fab
                            sx={{
                              backgroundColor: '#9D86FD',
                              height: '50px',
                              width: '50px',
                            }}
                            id="upload"
                            name="upload"
                            onClick={() => setUploadMediaModal(true)}
                          >
                            <AttachFileIcon style={{color: 'white'}} />
                          </Fab>
                        </Grid>

                        <Grid item xs={8}>
                          <InlineTextField
                            name="message"
                            label="메시지를 입력해 주세요."
                            formikProps={formikProps}
                          />
                        </Grid>

                        <Grid item xs={2} align="right">
                          <Fab
                            id="submit"
                            type="submit"
                            aria-label="add"
                            sx={{
                              backgroundColor: '#9D86FD',
                              height: '50px',
                              width: '50px',
                            }}
                          >
                            <SendIcon style={{color: 'white'}} />
                          </Fab>
                        </Grid>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <AppText
                fontSize="20"
                text="메시지를 시작하려면 채팅을 선택하세요"
              />
            </div>
          )}
        </Grid>

        <Grid item xs={4} className={classes.borderLeft500}>
          <Divider />
          <Grid item xs={12} style={{padding: '10px'}}>
            <AppSearchBox
              searchValue={search}
              searchValueFilter={searchFilter}
              placeholder={t('screens.chatting.search_members')}
              rightIcon={true}
              rightIconName="search" // Replace with your icon component if needed
              rightIconColor="#ccc" // Use the actual color from your theme
              onRightIconPress={() => {}}
            />
          </Grid>

          <Divider />

          <RelativeList>
            {filteredChatRoomList?.length === 0 ? (
              <AppText>{t('screens.chatting.no_chat_users')}</AppText>
            ) : (
              filteredChatRoomList?.map((chatData, i) => (
                <ChatCardList
                  key={i.toString()}
                  chatData={chatData}
                  handleChatRoomPress={() => handleChatRoomPress(chatData)}
                />
              ))
            )}

            <FloatingButton onClick={handleAddChat}>+</FloatingButton>
          </RelativeList>
        </Grid>
      </Grid>

      <TaskImageModal
        taskImageModal={taskImageModal}
        setTaskImageModal={setTaskImageModal}
        images={selectedMessage?.images}
      />
    </ChatSection>
  );
};

export default Chat;
