import React from 'react';
import theme from 'shared/styles/theme';
import styled from 'styled-components';

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const Square = styled.div`
  width: 12.5px;
  height: 15px;
  background-color: ${(props) => props.backgroundColor};
`;

const Square2 = styled.div`
  width: 26px;
  height: 14px;
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const NameContainer = styled.div`
  flex: 0.15;
  max-width: 15%; // Add this line
  padding: 5px;
`;

const MemberRow = styled.div`
  flex: 0.85;
  display: flex;
  flex-direction: column;
`;

const MemberContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const AppText = styled.span`
  color: white;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;

const RenderTimeHeader = () => {
  return (
    <Row>
      {Array.from({length: 24}, (_, index) => (
        <Square2 key={index}>
          <p style={{fontSize: 13}}>{index + 1}</p>
        </Square2>
      ))}
    </Row>
  );
};

const SquareRow = ({count, squareColor, indexes}) => {
  return (
    <Row>
      {Array.from({length: count}, (_, index) => {
        const isActive = indexes.includes(index);
        const backgroundColor = isActive ? squareColor : 'transparent';

        return <Square key={index} backgroundColor={backgroundColor} />;
      })}
    </Row>
  );
};

const SquareGrid = ({scheduleTimeline}) => {
  return (
    <div
      style={{
        marginTop: 5,
        width: '100%',
        height: '250px',
        overflowY: 'auto',
      }}
    >
      <HeaderContainer>
        <NameContainer>
          <AppText title={'Time Header'} />
        </NameContainer>

        <MemberRow>
          <RenderTimeHeader />
        </MemberRow>
      </HeaderContainer>

      {scheduleTimeline?.map((member, index) => {
        const scheduleIndexes = member.work_schedule_index_list.map(Number);

        return (
          <MemberContainer key={member.member_uid}>
            <NameContainer>
              <AppText title={member.name}>{member.name}</AppText>
            </NameContainer>

            <MemberRow>
              {/* Render SquareRow for each member */}
              <SquareRow
                count={48} // 48 squares for 24 hours
                squareColor={theme.colors.primary} // Assuming you have a theme with colors
                indexes={scheduleIndexes}
              />
            </MemberRow>
          </MemberContainer>
        );
      })}
    </div>
  );
};

export default SquareGrid;
