import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const DateDiv = styled.div`
  display: flex;
  width: 100%;
  font-size: 30px;
  font-weight: 600;
  color: #707070;
`;

const StyledDate = ({ date }) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return (
    <>
      <DateDiv>
        {year}.{`0${month}`}.{date < 10 ? `0${date}` : day}
      </DateDiv>
    </>
  );
};
StyledDate.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
};
export default StyledDate;
