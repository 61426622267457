import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CheckInput = styled.input.attrs(() => ({
  type: 'checkbox',
}))``;

const CheckField = (props) => {
  const {name, onChange, style, ...otherProps} = props;

  return (
    <CheckInput
      type="checkbox"
      name={name}
      id={name}
      style={style}
      onChange={onChange}
      {...otherProps}
    />
  );
};
CheckField.defaultProps = {
  style: {},
};

CheckField.propTypes = {
  style: PropTypes.object,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CheckField;
