import styled from 'styled-components';

import {ReactComponent as SvgMain} from 'shared/assets/svgs/main_drawing.svg';
import {Text, SimpleButton} from 'shared/components/index';

const HomeBackground = styled.div`
  background: -webkit-linear-gradient(#eff0fc, #d6d9fd);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const Container = styled.div`
  margin: auto;
  width: 1300px;
  height: 500px;
  display: flex;
  align-items: center;
`;

const InfoContainer = styled.div`
  width: 40%;
`;

const SvgContainer = styled.div`
  width: 60%;
`;

const BtnContainer = styled.div`
  margin-top: 35px;
  width: 320px;
  display: flex;
`;

const HomeTemplates = ({handleStartBtn}) => {
  return (
    <HomeBackground>
      <Container>
        <InfoContainer>
          <Text fontSize="gigantic2" bold>
            Owler.
          </Text>

          <Text fontSize="huge">
            효율적인 매장관리의 시작, <Text fontSize="massive">아울러</Text>
          </Text>

          <BtnContainer>
            <SimpleButton
              onClick={() => handleStartBtn('owler')}
              text="매장 관리자"
            />

            <SimpleButton
              outlined
              margin="0 15px"
              onClick={() => handleStartBtn('franchise')}
              text="슈퍼바이저"
            />
          </BtnContainer>
        </InfoContainer>

        <SvgContainer>
          <SvgMain width="100%" style={{height: 'auto'}} />
        </SvgContainer>
      </Container>
    </HomeBackground>
  );
};
export default HomeTemplates;
