import React from 'react';
import styled from 'styled-components';
import {useDispatch} from 'react-redux';
import {setSelectedMarker} from 'shared/utils/redux/mapSlice';

// import {Button} from 'shared/components/index';
// import SearchIcon from '@mui/icons-material/Search';

import {useSelector} from 'react-redux';
import {selectMap} from 'shared/utils/redux/mapSlice';
// import StoreSelect from './StoreSelect';
import {OwlerRNHeader} from 'shared/components/index';
import PlaceIcon from '@mui/icons-material/Place';
import theme from 'shared/styles/theme';

// add a shadow to StyledDiv
const StyledDiv = styled.div`
  padding: 5px 10px;
  width: 95%;
  margin: 10px 0;
  font-weight: 500;
  margin-left: 2.5%;
  border-radius: 10px;
  background-color: ${theme.colors.newLight};
  &:hover {
    cursor: pointer;
  }
  transition: height 1s ease, opacity 1s ease;
  display: flex;
  align-items: center;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.23);
`;

const Container = styled.div`
  background-color: #fafafa;
  padding-bottom: 10px 1px 10px 1px;
  width: 100%;
  overflow: auto;
  margin-top: 25px;
  transition: height 0.3s ease;
  min-height: ${(props) =>
    props.listopen || props.selectedmarker ? '200px' : '200px'};
  height: ${(props) =>
    props.listopen || props.selectedmarker ? '30vh' : '30vh'};
`;
const ResultStoreList = ({alls, listOpen, buttonClick}) => {
  const dispatch = useDispatch();
  const {selectedMarker} = useSelector(selectMap);

  const clickChange = (list) => {
    dispatch(setSelectedMarker(list));
    buttonClick(list.store_uid);
  };

  return (
    <Container listopen={listOpen} selectedmarker={selectedMarker}>
      <div style={{}}>
        {alls?.length > 0 ? (
          alls.map((list, index) => (
            <StyledDiv key={index} onClick={() => clickChange(list)}>
              <PlaceIcon color="error" />

              <p style={{marginLeft: '5px', fontSize: '14px'}}>
                {list.store_name}
              </p>
            </StyledDiv>
          ))
        ) : (
          <StyledDiv no={true}>근처 매장이 존재하지 않습니다.</StyledDiv>
        )}
      </div>
    </Container>
  );
};

export default ResultStoreList;
