/* eslint-disable react-hooks/exhaustive-deps */
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {Toaster} from 'react-hot-toast';
import {CgSpinner} from 'react-icons/cg';
import 'react-phone-input-2/lib/style.css';

import {Logo, SimpleButton, Text} from 'shared/components/index';
// import theme from 'shared/styles/theme';
import NewLogoCircle from 'shared/assets/icons/NewLogoCircle';

const HomeBackground = styled.div`
  background-color: #eff0fc;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
  width: 310px;
`;

const TotalTimeInput = styled.input`
  height: 40px;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

const BtnContainer = styled.div`
  width: 100%;
  margin: 10px 0;
`;

const EmailLoginTemplate = ({
  emailAddress,
  setEmailAddress,
  emailLoginPassword,
  setEmailLoginPassword,
  loading,
  handleEmailLoginBtn,
  handlePhoneLogin,
  handleFindEmail,
  handleLogoClick,
}) => {
  const {t} = useTranslation();
  return (
    <HomeBackground>
      <Toaster toastOptions={{duration: 4000}} />

      <Wrapper>
        <Logo src={<NewLogoCircle length={80} />} onClick={handleLogoClick} />
        <Text fontSize="large" bold>
          아울러
        </Text>

        <Wrapper>
          <TotalTimeInput
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
            placeholder="이메일을 입력하세요"
            type="email"
          />

          <TotalTimeInput
            value={emailLoginPassword}
            onChange={(e) => setEmailLoginPassword(e.target.value)}
            placeholder="비밀번호를 입력하세요"
            type="password"
          />

          <SimpleButton
            onClick={handleEmailLoginBtn}
            text={loading ? <CgSpinner size={20} /> : t('buttons.login')}
          />

          <BtnContainer>
            {/* <SimpleButton
            bgColor={theme.colors.oldYellow}
            hvColor={theme.colors.olderYellow}
            onClick={handleFindEmail}
            text={t('screens.login.find_email_pw')}
          /> */}
            <SimpleButton
              outlined
              text={t('screens.login.login_phone_number')}
              onClick={handlePhoneLogin}
            />
          </BtnContainer>
        </Wrapper>
      </Wrapper>
    </HomeBackground>
  );
};

export default EmailLoginTemplate;
