/* eslint-disable no-unused-vars */

import React, {useCallback, useState} from 'react';
import styled, {css} from 'styled-components';
import {Formik} from 'formik';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import theme from 'shared/styles/theme';
import {MuiButton, RichTextEditor} from 'shared/components/index';
import {useSelector} from 'react-redux';
import {selectNotice} from 'owler/utils/slices/noticeSlice';
import {useDropzone} from 'react-dropzone';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {ImageSelector} from 'shared/components/index';
import {Alert} from '@mui/material';
import Button from '@mui/material/Button';

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: ${({container}) => (container ? 'row' : 'column')};
  &:not(:last-child) {
    margin-right: 30px;
  }
  width: ${({width}) => width || '100%'};
  ${({center}) =>
    center &&
    css`
      margin: 0 auto;
    `}
`;

const Form = styled.form`
  width: 920px;
  min-height: 670px;
  padding: 60px 40px;
  margin: 0 auto 50px auto;
  border: 1px solid ${theme.colors.grey6};
  border-radius: 15px;
  & > * {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    &:first-child {
      margin: 10px 0 70px;
    }
    &:last-child {
      margin-top: 50px;
    }
  }
`;

const TitleText = styled.div`
  font-size: 18px;
  color: #252525;
  margin-bottom: 10px;
`;

const FlexBox = styled.div`
  display: flex;
`;
const DropArea = styled.div`
  width: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  font-size: 15px;
  padding: 10px;

  &:hover {
    cursor: pointer;
    background-color: #e6e6e6;
  }

  border: 1px solid grey;
  border-radius: 10px;
`;

const StyledP = styled.p`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 20px;
`;

const StyledImg = styled.img`
  width: 100px;
  height: auto;
  margin: 0 10px 10px 0;
`;
const ForDelete = styled.input`
  width: 100px;

  margin-bottom: 20px;
`;

const Field = ({
  formikProps,
  label,
  name,
  placeholder,
  classes,
  ...restProps
}) => {
  return (
    <>
      <Typography gutterBottom>{label}</Typography>
      <TextField
        {...restProps}
        name={name}
        value={formikProps.values[name]}
        onChange={formikProps.handleChange}
        error={formikProps.touched[name] && Boolean(formikProps.errors[name])}
        helperText={
          (formikProps.touched[name] && formikProps.errors[name]) || ' '
        }
        variant="filled"
        fullWidth
        placeholder={placeholder}
        className={classes && classes.text}
        InputProps={
          classes && {
            classes: {
              root: classes.text,
              input: classes.textInput,
              multiline: classes.multi,
            },
          }
        }
      />
    </>
  );
};

const MyDropzone = ({formikProps}) => {
  const {getRootProps, getInputProps} = useDropzone({
    onDrop: (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        formikProps.setFieldValue('files', [
          ...formikProps.values.files,
          {file: file, file_name: file.name},
        ]);
      });
    },
  });

  return (
    <DropArea {...getRootProps()}>
      <input {...getInputProps()} />
      <p>클릭하거나 파일을 드래그 해주세요!</p>
    </DropArea>
  );
};

const NoticeEditForm = ({
  onSubmit,
  onValidate,
  handleBackBtnClick,
  handleContentChange,
}) => {
  const {currentNotice} = useSelector(selectNotice);
  const [showAlert, setShowAlert] = useState(false);
  const [deleteImaged, setDeleteImage] = useState('');
  const [checkout, setCheckOut] = useState(false);
  let {title, contents, urls, files, rich_contents} = currentNotice;
  const [images, setImages] = useState(currentNotice.images);

  const handleFileChange = useCallback((e, formikProps) => {
    const file = e.target.files[0];
    formikProps.setFieldValue('files.file', file);
    formikProps.setFieldValue('files.file_name', file.name);
  }, []);

  const deleteImage = (img) => {
    setDeleteImage(img);
    setShowAlert(true);
  };
  const imageDelete = () => {
    //Owen's
    const new2Images = images?.filter((img) => img !== deleteImaged);
    setImages(new2Images);
  };
  return (
    <Formik
      onSubmit={onSubmit}
      validate={onValidate}
      initialValues={{
        title: title,
        files: files,
        urls: urls,
        description_images: images,
      }}
    >
      {(formikProps) => {
        const realDelete = () => {
          setShowAlert(false);
          setCheckOut(true);
          imageDelete();
          const newImages = formikProps.values.description_images.filter(
            (img) => img !== deleteImaged,
          );
          formikProps.setFieldValue('description_images', newImages);
        };
        return (
          <Form
            onBlur={formikProps.handleBlur}
            onSubmit={formikProps.handleSubmit}
          >
            <FieldWrapper>
              <TitleText>제목</TitleText>
              <Field
                formikProps={formikProps}
                fullWidth
                name="title"
                placeholder="제목을 입력하세요"
              />
            </FieldWrapper>

            <FieldWrapper>
              <TitleText>내용</TitleText>
              <RichTextEditor
                initialContent={rich_contents}
                onContentChange={handleContentChange}
              />
            </FieldWrapper>

            <FieldWrapper>
              <ImageSelector
                name="description_images"
                value={formikProps.values.description_images}
                formikProps={formikProps}
                label="공지 설명 사진"
                onChange={formikProps.handleChange}
              />

              {images && images.length > 0 ? (
                <>
                  <StyledP>이미 등록된 사진</StyledP>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    {images.map((img, indx) => (
                      <div key={indx.toString()}>
                        <StyledImg src={img} />

                        <ForDelete
                          type="button"
                          onClick={() => deleteImage(img)}
                          value="삭제"
                        ></ForDelete>
                      </div>
                    ))}
                  </div>
                </>
              ) : null}
              {/* <StyledP>이미 등록된 사진</StyledP>
            <div style={{display: 'flex', alignItems: 'center'}}>
              {images.map((img, indx) => (
              <div key={indx.toString()}>
                <StyledImg src={img}/>
                <ForDelete type='button' onClick={() => deleteImage(img)} value="삭제"></ForDelete>
              </div>
              ))}
            </div> */}
              {showAlert && (
                <div>
                  <Alert
                    severity="warning"
                    action={
                      <Button
                        color="inherit"
                        size="small"
                        onClick={() => realDelete()}
                      >
                        {' '}
                        삭제하기{' '}
                      </Button>
                    }
                  >
                    정말 삭제하시겠습니까
                  </Alert>
                </div>
              )}
              {checkout && (
                <div>
                  <Alert
                    onClose={() => {
                      setCheckOut(false);
                    }}
                  >
                    삭제되었습니다.
                  </Alert>
                </div>
              )}
            </FieldWrapper>

            <FlexBox>
              <FieldWrapper width="40%" center style={{marginTop: '37px'}}>
                <MyDropzone formikProps={formikProps} />
              </FieldWrapper>

              <FieldWrapper width="60%">
                <TitleText>첨부파일</TitleText>
                {formikProps?.values?.files?.map(
                  (
                    file,
                    index, // Loop over files
                  ) => (
                    <TextField
                      key={index}
                      fullWidth
                      name={`files[${index}].file_name`}
                      value={file.file_name}
                      onChange={formikProps.handleChange}
                      placeholder="첨부할 파일을 선택하세요"
                      variant="filled"
                      disabled
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton>
                              <CloseIcon
                                onClick={() => {
                                  const newFiles = [
                                    ...formikProps.values.files,
                                  ];
                                  newFiles.splice(index, 1);
                                  formikProps.setFieldValue('files', newFiles);
                                }}
                              />{' '}
                              {/* This can be your "x" button */}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  ),
                )}
              </FieldWrapper>
            </FlexBox>

            <FieldWrapper>
              <TitleText>링크 첨부</TitleText>
              <Field
                name="urls"
                placeholder="첨부할 링크를 넣어주세요"
                formikProps={formikProps}
              />
            </FieldWrapper>
            <FlexBox>
              <FieldWrapper width="20%" center>
                <MuiButton
                  id="noticeEdit-submit"
                  text="수정하기"
                  type="submit"
                />
              </FieldWrapper>

              <FieldWrapper width="20%" center>
                <MuiButton
                  id="noticeEdit-goBack"
                  text="뒤로가기"
                  onClick={handleBackBtnClick}
                />
              </FieldWrapper>
            </FlexBox>
          </Form>
        );
      }}
    </Formik>
  );
};

export default NoticeEditForm;
