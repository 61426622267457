import React from 'react';
import styled from 'styled-components';

import theme from 'shared/styles/theme';
import {Button, Text} from 'shared/components/index';

const LeftDashWrapper = styled.div`
  width: 100%;
  height: 412.3px;
  //border: 1px solid ${(props) => props.theme.colors.primary};
  border-radius: 15px;
  padding: 0 15px;
  margin: 15px 0 0 0;
  overflow: auto;
`;

const ButtonWrap = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  border: 1px solid ${theme.colors.primary};

  padding: 0 10px;
  border-radius: 10px;
`;

const NoSchedule = styled.div`
  text-align: center;
  font-size: 13px;
  margin-top: 20px;
  color: #bdbdbd;
`;

const TodayQSCSchedule = ({svTodaysSchedules, handleSvTodaysScheduleBtn}) => {
  return (
    <LeftDashWrapper>
      <Text fontSize="large" margin="0 0 5px 10px" bold>
        금일 일정
      </Text>

      {svTodaysSchedules?.length > 0 ? (
        svTodaysSchedules?.map((schedule, index) => (
          <ButtonWrap
            key={index.toString()}
            onClick={() => handleSvTodaysScheduleBtn(schedule)}
          >
            <Text style={{fontSize: '13px', width: '200px'}} margin="0 0 0 0">
              {schedule?.store_name}
            </Text>

            <Text />

            <Text style={{fontSize: '13px'}}>
              {new Date(schedule?.visit_date).getHours()}:
              {new Date(schedule?.visit_date)
                .getMinutes()
                .toString()
                .padStart(2, '0')}
            </Text>

            <Button
              style={{fontSize: '13px', height: '28px', borderRadius: '6px'}}
            >
              QSC
            </Button>
          </ButtonWrap>
        ))
      ) : (
        <NoSchedule>금일 등록된 일정이 없습니다.</NoSchedule>
      )}
    </LeftDashWrapper>
  );
};

export default TodayQSCSchedule;
