import {Route, Routes} from 'react-router-dom';

import withAuth from 'owler/auth/auth';

import StoreList from 'owler/pages/StoreList/index';
import TaskList from 'owler/pages/TaskList/index';
import Dashboard from 'owler/pages/Dashboard/index';
import TaskAdd from 'owler/pages/TaskAdd/index';
import TaskDetail from 'owler/pages/TaskDetail/index';
import NoticeList from 'owler/pages/NoticeList/index';
import NoticeDetail from 'owler/pages/NoticeDetail/index';
import NoticeEdit from 'owler/pages/NoticeEdit/index';
import NoticeAdd from 'owler/pages/NoticeAdd/index';
import TaskEdit from 'owler/pages/TaskEdit/index';
import Notifications from 'owler/pages/Notifications/index';
import Calendar from 'owler/pages/Calendar/index';
import WorkSchedule from 'owler/pages/WorkSchedule/index';
import Settings from 'owler/pages/Settings/index';
import RequestList from 'owler/pages/RequestList/index';
import RequestDetail from 'owler/pages/RequestDetail/index';
import Corporation from 'owler/pages/Corporation/index';
import WorkRegister from 'owler/pages/WorkRegister/index';
import WorkRegisterViewPdf from 'owler/pages/WorkRegisterViewPdf/index';
import Chatting from 'owler/pages/Chat/index';

// Owler Protected Routes:
const AuthStoreList = withAuth(StoreList);
const AuthDashboard = withAuth(Dashboard);
const AuthCorporation = withAuth(Corporation);

const AuthTaskList = withAuth(TaskList);
const AuthTaskAdd = withAuth(TaskAdd);
const AuthTaskEdit = withAuth(TaskEdit);
const AuthTaskDetail = withAuth(TaskDetail);

const AuthWorkRegister = withAuth(WorkRegister);
const AuthWorkRegisterViewPdf = withAuth(WorkRegisterViewPdf);

const AuthNoticeList = withAuth(NoticeList);
const AuthNoticeAdd = withAuth(NoticeAdd);
const AuthNoticeEdit = withAuth(NoticeEdit);
const AuthNoticeDetail = withAuth(NoticeDetail);

const AuthNotifications = withAuth(Notifications);
const AuthChatting = withAuth(Chatting);
const AuthCalendar = withAuth(Calendar);
const AuthSettings = withAuth(Settings);

const AuthRequestList = withAuth(RequestList);
const AuthRequestDetail = withAuth(RequestDetail);

const OwlerRoutes = () => {
  return (
    <Routes>
      <Route path="/storeList" element={<AuthStoreList />} />
      <Route path="/store/:id" element={<AuthDashboard />} />
      <Route path="/corporation/:id" element={<AuthCorporation />} />
      <Route path="/calendar/:id" element={<AuthCalendar />} />
      <Route path="/workSchedule/:id" element={<WorkSchedule />} />
      <Route path="/settings/:id" element={<AuthSettings />} />

      <Route path="/notifications/:id" element={<AuthNotifications />} />
      <Route path="/chat/:id" element={<AuthChatting />} />

      <Route path="/tasks/:id" element={<AuthTaskList />} />
      <Route path="/tasks/taskAdd/:id" element={<AuthTaskAdd />} />
      <Route path="/tasks/taskEdit/:id" element={<AuthTaskEdit />} />
      <Route path="/tasks/taskDetail/:id" element={<AuthTaskDetail />} />

      <Route path="/workRegister/:id" element={<AuthWorkRegister />} />
      <Route
        path="/workRegisterViewPdf/:id"
        element={<AuthWorkRegisterViewPdf />}
      />

      <Route path="/notices/:id" element={<AuthNoticeList />} />
      <Route path="/notices/noticeAdd/:id" element={<AuthNoticeAdd />} />
      <Route path="/notices/noticeEdit/:id" element={<AuthNoticeEdit />} />
      <Route path="/notices/noticeDetail/:id" element={<AuthNoticeDetail />} />

      <Route path="/request/:id" element={<AuthRequestList />} />
      <Route
        path="/request/requestDetail/:id"
        element={<AuthRequestDetail />}
      />
    </Routes>
  );
};

export default OwlerRoutes;
