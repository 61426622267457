import React from 'react';
import {ThemeProvider} from 'styled-components';
import GlobalStyle from 'shared/styles/globalStyles'; // Import the global styles
import theme from 'shared/styles/theme';
import AppRoutes from 'shared/routes/index';

// initialize i18n for translations:
import './shared/utils/i18n';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <AppRoutes />
    </ThemeProvider>
  );
}

export default App;
