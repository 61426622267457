import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import i18n from 'i18next';

import api from 'shared/utils/api';

const initialState = {
  masterTaskList: [],
  selectedTask: null,
  selectedDateT: new Date(),
  addCategoryModal: false,
  addCategoryModalInput: '',

  categorySettingsModal: false,

  selectedCategory: null,
  categoryDeleteModal: false,

  editCategoryModel: false,

  realTimePhotoDetail: null,
  realTimePhotoDetailModal: false,

  realTimePhotoImageModal: false,
  realTimePhotoImage: null,
  forTaskDetail: [],
  imagePostModal: false,
  imageDetailModal: false,
  imageDetail: [],
  error: null,
  deleteTaskModal: false,
  extraEmployeeModal: false,
};

// get store tasks by date thunk
export const getStoreTasksByDateAsync = createAsyncThunk(
  'task/getStoreTasksByDate',
  async (data) => {
    const response = await api.getStoreTasksByDate(data);
    const {results} = response.data;

    return results;
  },
);

// add new task category thunk
export const addTaskCategoryAsync = createAsyncThunk(
  'task/addTaskCategoryAsync',
  async (data) => {
    const response = await api.addTaskCategory(data);
    const {result} = response.data;

    return result;
  },
);

// add new task category thunk
export const editTaskCategoryAsync = createAsyncThunk(
  'task/editTaskCategory',
  async (data) => {
    const response = await api.editTaskCategory(data);
    const {result} = response.data;

    return result;
  },
);

// delete category thunk
export const deleteCategoryAsync = createAsyncThunk(
  'task/deleteCategory',
  async (data) => {
    const response = await api.deleteStoreTaskCategory(data);
    const {message} = response.data;

    return message;
  },
);

export const getTaskAsync = createAsyncThunk('task/getTask', async (data) => {
  const response = await api.getTask(data);
  return response.data.results;
});

// register task thunk
export const registerTaskAsync = createAsyncThunk(
  'task/registerTasks',
  async (data, {rejectWithValue}) => {
    try {
      const response = await api.registerNewTask(data);
      return response.data;
    } catch (error) {
      const errorResponse = error.response || {};
      const errorData = errorResponse.data || {};
      const message =
        i18n.language === 'ko'
          ? errorData.kr || error.message || '잘못된 접근입니다.'
          : errorData.en || error.message || 'Something went wrong :(';
      const status = errorResponse.status || 500;
      return rejectWithValue({message, status});
    }
  },
);

// edit task thunk
export const editTaskAsync = createAsyncThunk(
  'task/editTask',
  async (data, {rejectWithValue}) => {
    try {
      const response = await api.editTask(data);
      const {message} = response.data;

      return message;
    } catch (error) {
      if (error.response.status === 400) {
        if (i18n.language === 'ko') {
          return rejectWithValue(error.response.data.KR);
        } else {
          return rejectWithValue(error.response.data.EN);
        }
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

// delete task thunk
export const deleteTaskAsync = createAsyncThunk(
  'task/deleteTask',
  async (data) => {
    const response = await api.deleteTask(data);
    const {message} = response.data;

    return message;
  },
);

// Dashboard related api calls

export const postCompleteionTaskAsync = createAsyncThunk(
  'task/postCompleteionTaskAsync',
  async (data, {rejectWithValue}) => {
    try {
      const response = await api.postCompleteionTask(data);
      return response.data;
    } catch (error) {
      const errorResponse = error.response || {};
      const errorData = errorResponse.data || {};
      const message =
        i18n.language === 'ko'
          ? errorData.kr || error.message || '잘못된 접근입니다.'
          : errorData.en || error.message || 'Something went wrong :(';
      const status = errorResponse.status || 500;
      return rejectWithValue({message, status});
    }
  },
);
export const deleteCompleteionTaskAsync = createAsyncThunk(
  'task/deleteCompleteionTaskAsync',
  async (data, {rejectWithValue}) => {
    try {
      const response = await api.deleteCompleteionTask(data);
      return response.data;
    } catch (error) {
      const errorResponse = error.response || {};
      const errorData = errorResponse.data || {};
      const message =
        i18n.language === 'ko'
          ? errorData.kr || error.message || '잘못된 접근입니다.'
          : errorData.en || error.message || 'Something went wrong :(';
      const status = errorResponse.status || 500;
      return rejectWithValue({message, status});
    }
  },
);

export const getCompleteionTaskAsync = createAsyncThunk(
  'task/getCompleteionTaskAsync',
  async (data) => {
    const response = await api.getCompleteionTask(data);
    return response.data.results;
  },
);
// GET :: Task status daily
export const getDailyTaskStatusAsync = createAsyncThunk(
  'task/getDailyTaskStatus',
  async (param) => {
    const response = await api.getDailyTaskStatus(param);
    const {results} = response.data;

    return results;
  },
);

export const getBrandDailyTaskStatusAsync = createAsyncThunk(
  'task/getBrandDailyTaskStatusAsync',
  async (param) => {
    const response = await api.getBrandDailyTaskStatus(param);
    const {results} = response.data;

    return results;
  },
);

export const getBrandTaskStatusListAsync = createAsyncThunk(
  'task/getBrandTaskStatusListAsync',
  async (param) => {
    const response = await api.getBrandTaskStatusList(param);
    const {results} = response.data;

    return results;
  },
);

export const getBrandRealTimeImageTaskAsync = createAsyncThunk(
  'task/getBrandRealTimeImageTask',
  async (param) => {
    const response = await api.getBrandRealTimeImageTask(param);
    const {results} = response.data;

    return results;
  },
);

// GET :: Task status weekly
export const getWeeklyTaskStatusAsync = createAsyncThunk(
  'task/getWeeklyTaskStatus',
  async (param) => {
    const response = await api.getWeeklyTaskStatus(param);
    const {results} = response.data;

    return results;
  },
);

export const getBrandWeeklyTaskStatusAsync = createAsyncThunk(
  'task/getBrandWeeklyTaskStatusAsync',
  async (param) => {
    const response = await api.getBrandWeeklyTaskStatus(param);
    const {results} = response.data;

    return results;
  },
);

// GET :: Task status weekly
export const getMonthlyTaskStatusAsync = createAsyncThunk(
  'task/getMonthlyTaskStatusAsync',
  async (param) => {
    const response = await api.getMonthlyTaskStatus(param);
    const {results} = response.data;

    return results;
  },
);

export const getBrandMonthlyTaskStatusAsync = createAsyncThunk(
  'task/getBrandMonthlyTaskStatusAsync',
  async (param) => {
    const response = await api.getBrandMonthlyTaskStatus(param);
    const {results} = response.data;

    return results;
  },
);

// GET :: Real-time picture task status
export const getRealTimePictureTaskStatusAsync = createAsyncThunk(
  'task/getRealTimePictureTaskStatus',
  async (param) => {
    const response = await api.getRealTimePictureTaskStatus(param);
    const {results} = response.data;

    return results;
  },
);

// GET :: Today work schedule
export const getTodaysWorkScheduleAsync = createAsyncThunk(
  'task/getTodaysWorkSchedule',
  async (param) => {
    const response = await api.getTodaysWorkSchedule(param);
    const {results} = response.data;

    return results;
  },
);

// GET :: Today work schedule
export const getCurrentWorkersAsync = createAsyncThunk(
  'task/getCurrentWorkers',
  async (param) => {
    const response = await api.getCurrentWorkers(param);
    const {results} = response.data;

    return results;
  },
);

// GET :: Review summary
export const getTaskReviewSummaryAsync = createAsyncThunk(
  'task/getTaskReviewSummary',
  async (param) => {
    const response = await api.getTaskReviewSummary(param);
    const {results} = response.data;

    return results;
  },
);
export const getBrandTodayScheduleAsync = createAsyncThunk(
  'task/getBrandTodayScheduleAsync',
  async (param) => {
    const response = await api.getBrandTodaySchedule(param);
    const {results} = response.data;

    return results;
  },
);
export const getBrandNewRequestAsync = createAsyncThunk(
  'task/getBrandNewRequestAsync',
  async (param) => {
    const response = await api.getBrandNewRequest(param);
    const {results} = response.data;

    return results;
  },
);
export const getBrandUncheckNoticeAsync = createAsyncThunk(
  'task/getBrandUncheckNoticeAsync',
  async (param) => {
    const response = await api.getBrandUncheckNotice(param);
    const {results} = response.data;

    return results;
  },
);

export const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    setMasterTaskList: (state, action) => {
      state.masterTaskList = action.payload;
    },

    setSelectedTask: (state, action) => {
      state.selectedTask = action.payload;
    },

    setSelectedDateT: (state, action) => {
      // Convert Date to timestamp or ISO string when updating state
      state.selectedDateT = action.payload.toISOString();
    },

    setAddCategoryModal: (state, action) => {
      state.addCategoryModal = action.payload;
    },

    setAddCategoryModalInput: (state, action) => {
      state.addCategoryModalInput = action.payload;
    },

    setCategorySettingsModal: (state, action) => {
      state.categorySettingsModal = action.payload;
    },

    setCategoryDeleteModal: (state, action) => {
      state.categoryDeleteModal = action.payload;
    },

    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },

    setEditCategoryModel: (state, action) => {
      state.editCategoryModel = action.payload;
    },

    setRealTimePhotoDetail: (state, action) => {
      state.realTimePhotoDetail = action.payload;
    },

    setRealTimePhotoDetailModal: (state, action) => {
      state.realTimePhotoDetailModal = action.payload;
    },

    setRealTimePhotoImageModal: (state, action) => {
      state.realTimePhotoImageModal = action.payload;
    },

    setRealTimePhotoImage: (state, action) => {
      state.realTimePhotoImage = action.payload;
    },
    setForTaskDetail: (state, action) => {
      state.forTaskDetail.description_images = action.payload;
    },
    setImagePostModal: (state, action) => {
      state.imagePostModal = action.payload;
    },
    setImageDetailModal: (state, action) => {
      state.imageDetailModal = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setDeleteTaskModal: (state, action) => {
      state.deleteTaskModal = action.payload;
    },
    setExtraEmployeeModal: (state, action) => {
      state.extraEmployeeModal = action.payload;
    },
    resetTaskSlice: () => initialState,
  },

  extraReducers: (builder) => {
    builder
      .addCase(getStoreTasksByDateAsync.fulfilled, (state, action) => {
        state.masterTaskList = action.payload;
      })
      .addCase(getStoreTasksByDateAsync.rejected, (state) => {
        state.masterTaskList = [];
      })
      .addCase(getTaskAsync.fulfilled, (state, action) => {
        state.forTaskDetail = action.payload;
      })
      .addCase(getCompleteionTaskAsync.fulfilled, (state, action) => {
        state.imageDetail = action.payload;
      })
      .addCase(postCompleteionTaskAsync.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload; // here, you get the error message passed with rejectWithValue
        } else {
          state.error = action.error.message; // A generic error happened and didn't get to the rejectWithValue part
        }
      });
  },
});

export const {
  setMasterTaskList,
  setSelectedDateT,
  resetTaskSlice,
  setAddCategoryModal,
  setAddCategoryModalInput,
  setCategorySettingsModal,
  setCategoryDeleteModal,
  setSelectedCategory,
  setEditCategoryModel,
  setSelectedTask,
  setRealTimePhotoDetail,
  setRealTimePhotoDetailModal,
  setRealTimePhotoImageModal,
  setRealTimePhotoImage,
  setForTaskDetail,
  setImagePostModal,
  setImageDetailModal,
  setError,
  setDeleteTaskModal,
  setExtraEmployeeModal,
} = taskSlice.actions;

export const selectTask = (state) => state.owler.task;

export default taskSlice.reducer;
