/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
import React, {useState} from 'react';
import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';

import Search from './modal_components/Search';
import {MuiButton} from 'shared/components/index';
import theme from 'shared/styles/theme';
import {
  selectNotice,
  setNoDirectStoreData,
  setDirectStoreData,
} from 'owler/utils/slices/noticeSlice';
import {useDispatch, useSelector} from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';

const Wrapper = styled.div`
  display: block;
  width: 100vw;
  height: 100vh;
  border-radius: 5px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999 !important;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(18px);
`;

const ModalContainer = styled.div`
  width: 620px;
  min-width: 620px;
  height: 620px;
  min-width: 620px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 25px;
  border-radius: 5px;
`;

const StyledText = styled.p`
  font-size: ${(props) => (props.font ? props.font : '14px')};
  font-weight: ${(props) => (props.weight ? props.weight : 400)};
  white-space: ${(props) => (props.white_space ? props.white_space : 'normal')};
  color: ${(props) => (props.color ? props.color : '#252525')};
  line-height: 20px;
  margin: 12px 0 10px 0;
`;

const FlexBox = styled.div`
  width: 100%;
  height: ${(props) => (props.height ? props.height : '50px')};
  display: flex;
  justify-content: ${(props) =>
    props.justify ? props.justify : 'space-between'};
  align-items: center;
  margin: 12px 0;
`;

const ShopListWrapper = styled.div`
  width: 100%;
  height: ${(props) => (props.height ? props.height : '410px')};
  overflow-y: scroll;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  flex-wrap: wrap;
  justify-content: start;
  padding: 5px;
  margin-bottom: 10px;
`;

const BtnWrapper = styled.div`
  width: 100px;
  height: auto;
`;

const StoreName = styled.p`
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 3px;
  height: 40px;
  padding: 3px 8px;
  font-size: 13px;
  text-align: center;
  margin: 3px;
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: left;
  user-select: none;
  cursor: pointer;
`;
const BranchName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default function AddStoresModal({toggleModal}) {
  const dispatch = useDispatch();
  const {directStoreData, noDirectStoreData, directGroup, noDirectGroup} =
    useSelector(selectNotice);
  const [searchTerm, setSearchTerm] = useState('');

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // useEffect(() => {
  //   const direct = [];
  //   const noDirect = [];
  //   stores.forEach((item) => {
  //     if (item.is_direct) {
  //       direct.push(item);
  //     } else {
  //       noDirect.push(item);
  //     }
  //   });
  //   dispatch(setDirectGroup(direct));
  //   dispatch(setNoDirectGroup(noDirect));
  // }, [stores]);

  const onCheckBoxChange = (e) => {
    if (e.target.checked) {
      const direct_store_list = directGroup?.map(
        (eachstore) => eachstore.target_store_uid,
      );
      const direct_branch_name = directGroup?.map(
        (eachStore) => eachStore.branch_name,
      );
      const nodirect_store_list = noDirectGroup?.map(
        (eachstore) => eachstore.target_store_uid,
      );
      const nodirect_branch_name = noDirectGroup?.map(
        (eachStore) => eachStore.branch_name,
      );

      dispatch(
        setDirectStoreData({
          all: e.target.checked,
          stores: direct_store_list,
          names: direct_branch_name,
        }),
      );
      dispatch(
        setNoDirectStoreData({
          all: e.target.checked,
          stores: nodirect_store_list,
          names: nodirect_branch_name,
        }),
      );
    } else {
      dispatch(
        setDirectStoreData({
          all: e.target.checked,
          stores: [],
          names: [],
        }),
      );
      dispatch(
        setNoDirectStoreData({
          all: e.target.checked,
          stores: [],
          names: [],
        }),
      );
    }
  };

  const onCheckBoxChange2 = (e) => {
    if (e.target.checked) {
      const direct_store_list = directGroup?.map(
        (eachstore) => eachstore.target_store_uid,
      );
      const direct_branch_name = directGroup?.map(
        (eachStore) => eachStore.branch_name,
      );

      dispatch(
        setDirectStoreData({
          all: e.target.checked,
          stores: direct_store_list,
          names: direct_branch_name,
        }),
      );
    } else {
      dispatch(
        setDirectStoreData({
          all: e.target.checked,
          stores: [],
          names: [],
        }),
      );
    }
  };

  const onCheckBoxChange3 = (e) => {
    if (e.target.checked) {
      const nodirect_store_list = noDirectGroup?.map(
        (eachstore) => eachstore.target_store_uid,
      );
      const nodirect_branch_name = noDirectGroup?.map(
        (eachStore) => eachStore.branch_name,
      );

      dispatch(
        setNoDirectStoreData({
          all: e.target.checked,
          stores: nodirect_store_list,
          names: nodirect_branch_name,
        }),
      );
    } else {
      dispatch(
        setNoDirectStoreData({
          all: e.target.checked,
          stores: [],
          names: [],
        }),
      );
    }
  };

  const handleCheckedStores = (e) => {
    const checked = e.target.value;
    const id = e.target.id;

    if (e.target.checked) {
      if (
        !directStoreData.stores.includes(checked) &&
        !directStoreData.names.includes(id)
      ) {
        dispatch(
          setDirectStoreData({
            ...directStoreData,
            stores: [...directStoreData.stores, checked],
            names: [...directStoreData.names, id],
          }),
        );
      }
    } else {
      dispatch(
        setDirectStoreData({
          ...directStoreData,
          stores: directStoreData.stores.filter(
            (store) => store.indexOf(checked) === -1,
          ),
          names: directStoreData.names.filter(
            (name) => name.indexOf(id) === -1,
          ),
        }),
      );
    }
  };

  const handleCheckedStores2 = (e) => {
    const checked = e.target.value;
    const id = e.target.id;

    if (e.target.checked) {
      if (
        !noDirectStoreData.stores.includes(checked) &&
        !noDirectStoreData.names.includes(id)
      ) {
        dispatch(
          setNoDirectStoreData({
            ...noDirectStoreData,
            stores: [...noDirectStoreData.stores, checked],
            names: [...noDirectStoreData.names, id],
          }),
        );
      }
    } else {
      dispatch(
        setNoDirectStoreData({
          ...noDirectStoreData,
          stores: noDirectStoreData.stores.filter(
            (store) => store.indexOf(checked) === -1,
          ),
          names: noDirectStoreData.names.filter(
            (name) => name.indexOf(id) === -1,
          ),
        }),
      );
    }
  };

  return (
    <Wrapper>
      <ModalContainer>
        <StyledText font="22px" weight="bold">
          매장 검색하기
        </StyledText>
        {noDirectGroup.length !== 0 ? (
          <>
            <FlexBox>
              <Search value={searchTerm} onChange={handleChange} />
              <FormControlLabel
                value="전체 매장 선택"
                control={
                  <Checkbox
                    checked={directStoreData.all && noDirectStoreData.all}
                    onChange={onCheckBoxChange}
                    sx={{'&.Mui-checked': {color: theme.colors.kalsPurple}}}
                  />
                }
                label="전체 매장 선택"
                labelPlacement="start"
              />
            </FlexBox>
            <FlexBox height="12px">
              <FormControlLabel
                value="직영점 선택"
                control={
                  <Checkbox
                    checked={directStoreData.all}
                    onChange={onCheckBoxChange2}
                    sx={{'&.Mui-checked': {color: theme.colors.kalsPurple}}}
                  />
                }
                label="직영점 선택"
                labelPlacement="start"
              />
            </FlexBox>
            <ShopListWrapper height="175px">
              {directGroup
                ?.filter((eachStore) =>
                  eachStore.branch_name
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()),
                )
                .map((eachStore) => {
                  return (
                    <StoreName key={eachStore.target_store_uid}>
                      <Checkbox
                        id={eachStore.branch_name}
                        value={eachStore.target_store_uid}
                        onChange={handleCheckedStores}
                        sx={{
                          '&.Mui-checked': {
                            color: `${theme.colors.kalsPurple}`,
                          },
                        }}
                        checked={directStoreData.stores.includes(
                          eachStore.target_store_uid,
                        )}
                      />
                      <BranchName>{eachStore.branch_name} </BranchName>
                    </StoreName>
                  );
                })}
            </ShopListWrapper>
            <FlexBox height="12px">
              <FormControlLabel
                value="가맹점 선택"
                control={
                  <Checkbox
                    checked={noDirectStoreData.all}
                    onChange={onCheckBoxChange3}
                    sx={{'&.Mui-checked': {color: theme.colors.kalsPurple}}}
                  />
                }
                label="가맹점 선택"
                labelPlacement="start"
              />
            </FlexBox>
            <ShopListWrapper height="175px">
              {noDirectGroup
                ?.filter((eachStore) =>
                  eachStore.branch_name
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()),
                )
                .map((eachStore) => {
                  return (
                    <StoreName key={eachStore.target_store_uid}>
                      <Checkbox
                        id={eachStore.branch_name}
                        value={eachStore.target_store_uid}
                        onChange={handleCheckedStores2}
                        sx={{
                          '&.Mui-checked': {
                            color: `${theme.colors.kalsPurple}`,
                          },
                        }}
                        checked={noDirectStoreData.stores.includes(
                          eachStore.target_store_uid,
                        )}
                      />
                      <BranchName>{eachStore.branch_name} </BranchName>
                    </StoreName>
                  );
                })}
            </ShopListWrapper>
          </>
        ) : (
          <>
            <FlexBox>
              <Search value={searchTerm} onChange={handleChange} />
            </FlexBox>
            <FlexBox height="12px">
              <FormControlLabel
                value="직영점 선택"
                control={
                  <Checkbox
                    checked={directStoreData.all}
                    onChange={onCheckBoxChange2}
                    sx={{'&.Mui-checked': {color: theme.colors.kalsPurple}}}
                  />
                }
                label="직영점 선택"
                labelPlacement="start"
              />
            </FlexBox>
            <ShopListWrapper height="400px">
              {directGroup
                ?.filter((eachStore) =>
                  eachStore.branch_name
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()),
                )
                .map((eachStore) => {
                  return (
                    <StoreName key={eachStore.target_store_uid}>
                      <Checkbox
                        id={eachStore.branch_name}
                        value={eachStore.target_store_uid}
                        onChange={handleCheckedStores}
                        sx={{
                          '&.Mui-checked': {
                            color: `${theme.colors.kalsPurple}`,
                          },
                        }}
                        checked={directStoreData.stores.includes(
                          eachStore.target_store_uid,
                        )}
                      />
                      <BranchName>{eachStore.branch_name} </BranchName>
                    </StoreName>
                  );
                })}
            </ShopListWrapper>
          </>
        )}

        <FlexBox justify="center">
          <BtnWrapper>
            <MuiButton
              id="noticeAdd-goBack"
              text="완료"
              backgroundColor={theme.colors.kalsPurple}
              textColor="white"
              onClick={toggleModal}
              type="submit"
            />
          </BtnWrapper>
        </FlexBox>
      </ModalContainer>
    </Wrapper>
  );
}
