import {Text, FranchiseDrawer, FranchiseWrapper} from 'shared/components/index';
import BrandSelectModal from './BrandSelectModal';
import styled from 'styled-components';
import theme from 'shared/styles/theme';
import Button from '@mui/material/Button';

const MasterText = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  color: grey1;
  margin-bottom: 5px;
`;

const MasterCardRow = styled.div`
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 15px;
  border-bottom: 1px solid ${theme.colors.grey5};
  margin: 5px;
`;

const CardWrapper = styled.div`
  width: 100%;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

const CardWrap = styled.div`
  align-items: center;
  min-height: 32px;
  min-width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8px 8px;
  padding: 3px 8px;
  box-shadow: 0px 2px 3px 2px #bdbdbd;
  border-radius: 10px;
`;

const Container = styled.div`
  margin-left: 60px;
`;

const StyledButton = styled(Button)`
  && {
    background-color: ${({backgroundcolor}) => backgroundcolor} !important;
  }
`;
const StoreManageTemplate = ({
  supervisorList,
  handleSvSelect,
  selectedMember,
  selectedPositionName,
  selectBrand,
  handleSaveStoreList,
  brandLoading,
  storeLoading,
}) => {
  return (
    <FranchiseWrapper titleText="WorkerManagement">
      <FranchiseDrawer>
        <Container>
          <Text bold fontSize="large">
            매장 배정
          </Text>

          <>
            {supervisorList?.map((position, i) => (
              <MasterCardRow key={i.toString()}>
                <MasterText>
                  {`${position?.position_name}: ${position?.supervisor_list?.length}`}{' '}
                  명
                </MasterText>

                <CardWrapper>
                  {position.supervisor_list.map((member, index) => (
                    <CardWrap key={member?.sv_user_uid + index}>
                      <Text margin="0 0 3px 0">{member.supervisor_name}</Text>
                      <StyledButton
                        backgroundcolor={theme.colors.kalsPurple}
                        sx={{
                          color: 'white',
                          fontWeight: '500',
                          fontSize: '14px',
                          height: '20px',
                          borderRadius: '4px',
                          width: '80px',
                          marginBottom: '6px',
                        }}
                        onClick={() =>
                          handleSvSelect(member, position?.position_name)
                        }
                      >
                        매장 배정
                      </StyledButton>
                    </CardWrap>
                  ))}
                </CardWrapper>
              </MasterCardRow>
            ))}
          </>
          <BrandSelectModal
            brandLoading={brandLoading}
            selectedMember={selectedMember}
            selectedPositionName={selectedPositionName}
            selectBrand={selectBrand}
            handleSaveStoreList={handleSaveStoreList}
            storeLoading={storeLoading}
          />
        </Container>
      </FranchiseDrawer>
    </FranchiseWrapper>
  );
};

export default StoreManageTemplate;
