import React from 'react';
import styled from 'styled-components';
import {VictoryChart, VictoryLine, VictoryAxis} from 'victory';

import {Text} from 'shared/components/index';
import theme from 'shared/styles/theme';

const StyledWrapper = styled.div`
  //background-color: #8b77d9;
  padding: 10px;
  border-radius: 10px;
  height: 100%;
  width: 100%;
`;

const CardTitleWrap = styled.div`
  width: 100%;
  height: 20px;
`;

const VictoryLineCharWrapper = styled.div`
  margin-top: -40px;
  position: relative;
`;

const MonthlyPerformance = ({thisMonthLineGraphData}) => {
  const koreanMonthLabels = [
    '1월',
    '2월',
    '3월',
    '4월',
    '5월',
    '6월',
    '7월',
    '8월',
    '9월',
    '10월',
    '11월',
    '12월',
  ];
  return (
    <StyledWrapper>
      <CardTitleWrap></CardTitleWrap>

      <VictoryLineCharWrapper>
        {thisMonthLineGraphData !== null && (
          <VictoryChart
            height={240}
            padding={{top: 20, bottom: 30, left: 50, right: 50}}
          >
            <VictoryLine
              data={Object.entries(thisMonthLineGraphData).map(
                ([month, value]) => ({
                  x: month,
                  y: value !== null ? value : 0,
                }),
              )}
              style={{
                data: {stroke: '#8b77d9'},
              }}
            />
            <VictoryAxis
              tickValues={Object.keys(thisMonthLineGraphData)}
              tickFormat={(tick) => koreanMonthLabels[parseInt(tick) - 1]}
              style={{
                tickLabels: {fontSize: 12, fill: 'black'},
              }}
            />
            <VictoryAxis
              dependentAxis
              domain={[0, 100]}
              style={{
                tickLabels: {fontSize: 12, fill: 'black'},
                axis: {stroke: 'transparent'},
              }}
            />
          </VictoryChart>
        )}
      </VictoryLineCharWrapper>
    </StyledWrapper>
  );
};

export default MonthlyPerformance;
