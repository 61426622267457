import React from 'react';
import styled from 'styled-components';

import PushPinIcon from '@mui/icons-material/PushPin';
import ChatRoomImage from './ChatRoomImage';

// Define your styled components outside of the ChatCard component
const CardContainer = styled.div`
  width: 100%;
  height: 90px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0; /* Replace with colors.grey5 */
  background-color: #fff; /* Replace with colors.white */
  cursor: pointer;
`;

const CardContent = styled.div`
  width: 95%;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
`;

const ChatDetails = styled.div`
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ChatInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChatTitle = styled.div`
  max-width: 245px;
  font-size: 15px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ChatLastMessage = styled.div`
  max-width: 245px;
  font-size: 14px;
  margin-top: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const UnreadCountBadge = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: #6f42c1; /* Replace with colors.kalsPurple */
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UnreadCount = styled.div`
  font-size: 11px;
  color: #fff; /* Replace with colors.white */
`;

// Your PinIcon and ChatRoomImage components need to be imported or defined as well

const ChatCardList = ({chatData, handleChatRoomPress}) => {
  return (
    <CardContainer onClick={() => handleChatRoomPress(chatData)}>
      <CardContent>
        <ChatRoomImage chatData={chatData} />

        <ChatDetails>
          <ChatInfo>
            <ChatTitle>{chatData?.title}</ChatTitle>
            <ChatLastMessage>{chatData?.last_message}</ChatLastMessage>
          </ChatInfo>
          {chatData?.is_pinned && <PushPinIcon />}
          {chatData?.unread_message_count !== 0 && (
            <UnreadCountBadge>
              <UnreadCount>{chatData?.unread_message_count}</UnreadCount>
            </UnreadCountBadge>
          )}
        </ChatDetails>
      </CardContent>
    </CardContainer>
  );
};

export default ChatCardList;
