import React from 'react';
import styled from 'styled-components';

import CheckField from '../atoms/CheckField';
import Label from '../atoms/Label';

const CheckBoxWrapper = styled.div`
  ${({theme}) => theme.putCenter}
  & > *:last-child {
    margin-left: 10px;
  }
`;

const CheckBox = (props) => {
  const {name, label, onChange, style, ...otherProps} = props;
  return (
    <CheckBoxWrapper>
      <CheckField
        name={name}
        id={name}
        style={style}
        onChange={onChange}
        {...otherProps}
      />
      <Label htmlFor={name}>{label}</Label>
    </CheckBoxWrapper>
  );
};

export default CheckBox;
