/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/prop-types */
import React from 'react';
import {Page, Document, StyleSheet, Text} from '@react-pdf/renderer';
import theme from 'shared/styles/theme';
import PdfHeader from './PdfHeader';
import PdfBody from './PdfBody';

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    padding: 15,
    paddingTop: 15,
  },
  text: {
    textAlign: 'center',
    verticalAlign: 'middle',
    fontSize: 15,
    fontFamily: 'Nanum Gothic',
    color: theme.colors.notBlack,
    fontWeight: 'thin',
    marginBottom: 10,
  },
});

// Create Document Component
const WorkRegisterViewPdfTemplate = ({year, month, commuteLog}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.text}>
          {year} 년도 {month} 월 출퇴근 기록부
        </Text>
        <PdfHeader commuteLog={commuteLog} />
        <PdfBody commuteLog={commuteLog} />
      </Page>
    </Document>
  );
};

export default WorkRegisterViewPdfTemplate;
