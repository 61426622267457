import {
  Text,
  FranchiseDrawer,
  FranchiseWrapper,
  MuiButton,
} from 'shared/components/index';
import styled from 'styled-components';
import theme from 'shared/styles/theme';
import {useDropzone} from 'react-dropzone';

const Container = styled.div`
  margin-left: 60px;
  width: 1050px;
`;

const SignWrapper = styled.div`
  width: 420px;
  height: 420px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const SignArea = styled.div`
  border: 2px solid ${theme.colors.notBlack};
  width: 300px;
  height: 300px;
  margin: 0 0 20px 0;
`;
const SignImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const SignManagementTemplate = ({defaultSign, submitSign}) => {
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
    multiple: false,
  });

  const updatedImages = acceptedFiles.map((image) => {
    const preview = URL.createObjectURL(image);
    const imageName = image.name;
    return {preview, file: image, name: imageName};
  });

  const handleSubmit = () => {
    if (updatedImages.length <= 0) {
      alert('사진을 추가해주세요!');
      return;
    }
    submitSign(acceptedFiles[0]);
  };

  return (
    <FranchiseWrapper titleText="SignManagement">
      <FranchiseDrawer>
        <Container>
          <Text bold fontSize="large">
            사인 관리
          </Text>
          <div>
            <Text margin="20px 0 0 0">
              새로운 서명을 서명을 업로드하려면 클릭 후 파일을 선택해주세요.
            </Text>
          </div>
          <SignWrapper>
            <div {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} />
              <SignArea>
                {updatedImages.length > 0 ? (
                  <SignImg src={updatedImages[0].preview} alt="" />
                ) : (
                  <SignImg src={defaultSign} />
                )}
              </SignArea>
            </div>

            <MuiButton
              text="저장"
              textColor="white"
              backgroundColor={theme.colors.kalsPurple}
              style={{fontSize: '13px', borderRadius: '6px'}}
              onClick={() => handleSubmit()}
            />
          </SignWrapper>
        </Container>
      </FranchiseDrawer>
    </FranchiseWrapper>
  );
};

export default SignManagementTemplate;
