import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({width}) => width};
  margin-top: ${({margin}) => margin || '0px'};
  ${({theme}) => theme.putCenter}
`;
const TableWrapper = ({children, margin, width}) => (
  <StyledTableWrapper margin={margin} width={width}>
    {children}
  </StyledTableWrapper>
);
TableWrapper.propTypes = {
  children: PropTypes.any.isRequired,
};

export default TableWrapper;
