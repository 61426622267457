import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Icon from './Icon';
import {ReactComponent as SvgClose} from 'shared/assets/svgs/close.svg';

const SelfCenterAlignWrapper = styled.div`
  width: ${width => width || '630px'};
  height: ${height => height || '460px'};
  padding: ${padding => padding || '30px'};
  background-color: #f6f6f6;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 5px;
  outline: none;
`;

const IconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

// Modal 전체를 감싸는 wrapper, 알림창 영역
const ModalWrapper = ({width, height, padding, children, onClick}) => (
  <SelfCenterAlignWrapper width={width} height={height} padding={padding}>
    <IconWrapper>
      <Icon
        size="sm"
        icon={
          <SvgClose width="100%" style={{height: 'auto'}} onClick={onClick} />
        }
      />
    </IconWrapper>
    {children}
  </SelfCenterAlignWrapper>
);

ModalWrapper.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  padding: PropTypes.string,
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

ModalWrapper.defaultProps = {
  width: 'base',
  height: 'base',
  padding: 'base',
};

export default ModalWrapper;
