import React from 'react';
import {Modal} from '@mui/material';
import {styled} from '@mui/system';

const ContentWrapper = styled('div')`
  height: fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MuiModal = ({open, onClose, children, ...restProps}) => {
  return (
    <Modal
      {...restProps}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        outline: 'none',
        border: 'none',
        backgroundColor: 'none',
        '& > *': {
          borderRadius: '1px',
          outline: 'none',
        },
        zIndex: '3200',
      }}
      open={open}
      onClose={onClose}
    >
      <ContentWrapper>{children}</ContentWrapper>
    </Modal>
  );
};

export default MuiModal;
