import React, {useCallback, useState} from 'react';
import TaskAddTemplate from './template';
import {useLocation, useNavigate} from 'react-router-dom';
import {toISOString} from 'shared/utils/time';
import {useDispatch, useSelector} from 'react-redux';
import {selectStore} from 'owler/utils/slices/storeSlice';
import {toast, Toaster} from 'react-hot-toast';
import i18n from 'i18next';

import {
  getStoreTasksByDateAsync,
  registerTaskAsync,
} from 'owler/utils/slices/taskSlice';

const TaskAdd = () => {
  const {currentStore} = useSelector(selectStore);
  const {
    state: {id, name: categoryName},
  } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(async (value) => {
    let {
      repetition,
      task_type,
      start,
      end,
      description,
      notify,
      description_images,
      weeks,
      repeat_amount,
    } = value;
    const {name} = value;

    // Adjusting repetition values based on provided conditions
    if (repetition === '없음') {
      repetition = 0;
    } else if (repetition === '매일') {
      repetition = 1;
    } else if (repetition === '주 1회') {
      repetition = 6;
    } else if (repetition === '월 1회') {
      repetition = 3;
    } else if (repetition === 'n일 마다') {
      repetition = 4;
    } else if (repetition === 'n주 마다') {
      repetition = 5;
    }

    // format task_type
    if (task_type === '사진') {
      task_type = 'image';
    } else if (task_type === '메모') {
      task_type = 'memo';
    } else {
      task_type = 'check';
    }

    // formatTime
    const startDate = new Date();
    startDate.setHours(...start.split(':'));
    const endDate = new Date();
    endDate.setHours(...end.split(':'));
    start = toISOString(startDate);
    end = toISOString(endDate);

    // format description
    if (description === '' || description === ' ' || description === '\n')
      description = null;

    try {
      const formData = new FormData();

      // Append fields to formData
      formData.append('task_category_uid', id);
      formData.append('task_type', task_type);
      formData.append('name', name);
      formData.append('start', start);
      formData.append('end', end);

      formData.append('repetition', repetition);

      // Ensuring the logic for setting repeat_amount matches the app's behavior
      if (repetition === 4 || repetition === 5) {
        // Use 2 as a default value for repeat_amount if it's 0; otherwise, use the actual repeatAmount
        const amountToAppend = repeat_amount === 0 ? 2 : repeat_amount;
        formData.append('repeat_amount', amountToAppend);
      }

      if (description) {
        formData.append('description', description);
      }

      formData.append('is_push', notify);

      if (weeks.length) {
        formData.append('weeks', JSON.stringify(weeks));
      }

      // Append image files to formData
      if (description_images.length) {
        description_images.forEach((image, index) => {
          formData.append('images', image);
        });
      } else {
        formData.append('images', JSON.stringify([]));
      }

      setLoading(true);
      dispatch(registerTaskAsync(formData))
        .unwrap()
        .then(({en, kr}) => {
          if (i18n.language === 'ko') {
            toast.success(kr);
          } else {
            toast.success(en);
          }

          setLoading(false);

          const selectedDate = new Date();
          const year = selectedDate.getFullYear();
          const month = (selectedDate.getMonth() + 1)
            .toString()
            .padStart(2, '0'); // 월을 0으로 패딩
          const day = selectedDate.getDate().toString().padStart(2, '0'); // 일을 0으로 패딩
          const date = `${year}-${month}-${day}`;
          dispatch(
            getStoreTasksByDateAsync({
              store_uid: currentStore.store_uid,
              date,
            }),
          );

          navigate(-1);
        })
        .catch((err) => {
          alert(err);
          setLoading(false);
        });
    } catch (e) {
      alert(e.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onValidate = useCallback((value) => {
    const errors = {};
    const reg = new RegExp(/^([01][0-9]|2[0-3]):([0-5][0-9])$/, 'i');
    if (!value.name) errors.name = '업무명을 입력해주세요';
    else if (value.name.length > 100)
      errors.name = '문자 길이가 100을 초과할 수 없습니다';
    // start
    if (!value.start) errors.start = '시작하는 날짜를 입력해주세요';
    else if (!reg.test(value.start))
      errors.start = '날짜형식은 HH:MM 이어야합니다.';
    // end
    let timeS = value.start.substring(0, 2);
    let timeE = value.end.substring(0, 2);

    if (timeS >= 24) errors.start = '시간은 23:59까지 기입 가능합니다';

    if (!value.end) errors.end = '끝나는 날짜를 입력해주세요';
    else if (!reg.test(value.end))
      errors.end = '날짜형식은 HH:MM 이어야합니다.';
    if (timeE >= 24) errors.end = '시간은 23:59까지 기입 가능합니다';
    if (value.start > value.end && value.start !== '' && value.end !== '') {
      errors.start = '시작시간보다 끝 시간이 더 빠를수 없습니다.';
      errors.end = '시작시간보다 끝 시간이 더 빠를수 없습니다.';
    }
    if (value.start === value.end)
      errors.end = '시작 시간과 끝 시간이 같습니다';
    if (!value.repetition) errors.repetition = 'ㅠㅠ';
    if (!value.task_type) errors.task_type = 'ㅠㅠ';
    return errors;
  }, []);

  const handleGoback = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <>
      <Toaster toastOptions={{duration: 4000}} />
      <TaskAddTemplate
        categoryName={categoryName}
        storeName={currentStore.store_name}
        onSubmit={onSubmit}
        onValidate={onValidate}
        loading={loading}
        handleGoback={handleGoback}
      />
      ;
    </>
  );
};

export default TaskAdd;
