import React from 'react';
import styled from 'styled-components';

const StylesWrapper = styled.div`
  width: 100%;
  height: 100vh;
  min-width: 350px;
  min-height: 650px;
  background-color: ${({theme}) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;

// eslint-disable-next-line react/prop-types
export default function AppWrapper({children}) {
  return <StylesWrapper>{children}</StylesWrapper>;
}
