import {Route, Routes} from 'react-router-dom';

import withAuth from 'franchise/auth/auth';

import Dashboard from 'franchise/pages/Dashboard/index';
import QscStoreList from 'franchise/pages/QscStoreList/index';
import Calendar from 'franchise/pages/Calendar/index';
import QSC from 'franchise/pages/QSC/index';
import Notice from 'franchise/pages/Notice/index';
import ScheduleAdd from 'franchise/pages/ScheduleAdd/index';
import Settings from 'franchise/pages/Settings/index';
import QSCStore from 'franchise/pages/QSCStore';
import QSCProcess from 'franchise/pages/QSCProcess/index';
import QSCNote from 'franchise/pages/QSCNote/index';
import QSCDetail from 'franchise/pages/QSCDetailPage/index';
import DetailViewPdf from 'franchise/pages/QSCDetailViewPdf/index';
import Notifications from 'franchise/pages/Notifications/index';
import QSCHistory from 'franchise/pages/OldQSCHistory/index';
import PositionManagement from 'franchise/pages/PositionManagement/index';
import WorkerManagement from 'franchise/pages/WorkerManagement/index';
import StoreJoinRequests from 'franchise/pages/StoreJoinRequests/index';
import StoreManage from 'franchise/pages/StoreManage/index';
import PaymentManagement from 'franchise/pages/PaymentManagement/index';
import SignManagement from 'franchise/pages/SignManagement/index';
import Supplementation from 'franchise/pages/Supplementation/index';
import SupplementationViewPdf from 'franchise/pages/SupplementationViewPdf/index';

// Franchise Protected Routes:
const AuthCalendar = withAuth(Calendar);
const AuthDashboard = withAuth(Dashboard);
const AuthNotice = withAuth(Notice);
const AuthQSC = withAuth(QSC);
const AuthQscStoreList = withAuth(QscStoreList);
const AuthScheduleAdd = withAuth(ScheduleAdd);
const AuthSettings = withAuth(Settings);
const AuthQscStore = withAuth(QSCStore);
const AuthQscProcess = withAuth(QSCProcess);
const AuthQscNote = withAuth(QSCNote);
const AuthQscDetail = withAuth(QSCDetail);
const AuthQscDetailViewPdf = withAuth(DetailViewPdf);
const AuthQSCHistory = withAuth(QSCHistory);
const AuthNotifications = withAuth(Notifications);
const AuthPositionManagement = withAuth(PositionManagement);
const AuthWorkerManagement = withAuth(WorkerManagement);
const AuthStoreJoinRequests = withAuth(StoreJoinRequests);
const AuthStoreManage = withAuth(StoreManage);
const AuthPaymentManagement = withAuth(PaymentManagement);
const AuthSignManagement = withAuth(SignManagement);

const AuthSupplementation = withAuth(Supplementation);
const AuthSupplementationViewPdf = withAuth(SupplementationViewPdf);

const FranchiseRoutes = () => {
  return (
    <Routes>
      <Route path="/dashboard" element={<AuthDashboard />} />
      <Route path="/qscStoreList" element={<AuthQscStoreList />} />
      <Route path="/supplementation" element={<AuthSupplementation />} />
      <Route
        path="/supplementation/veiwPdf"
        element={<AuthSupplementationViewPdf />}
      />
      <Route path="/qscStore/:id" element={<AuthQscStore />} />
      <Route path="/qscProcess/:id" element={<AuthQscProcess />} />
      <Route path="/qscCreateNote/:id" element={<AuthQscNote />} />
      <Route path="/qscDetail/:id" element={<AuthQscDetail />} />
      <Route path="/qscDetail/viewPDF/:id" element={<AuthQscDetailViewPdf />} />
      <Route path="/qscHistory/:id" element={<AuthQSCHistory />} />
      <Route path="/notifications" element={<AuthNotifications />} />
      <Route path="/calendar" element={<AuthCalendar />} />
      <Route path="/qsc" element={<AuthQSC />} />
      <Route path="/notice" element={<AuthNotice />} />
      <Route path="/scheduleAdd" element={<AuthScheduleAdd />} />
      <Route path="/settings" element={<AuthSettings />} />
      <Route
        path="/settings/positionManagement"
        element={<AuthPositionManagement />}
      />
      <Route
        path="/settings/workerManagement"
        element={<AuthWorkerManagement />}
      />
      <Route
        path="/settings/paymentManagement"
        element={<AuthPaymentManagement />}
      />
      <Route path="/settings/signManagement" element={<AuthSignManagement />} />

      <Route
        path="/settings/storeJoinRequests"
        element={<AuthStoreJoinRequests />}
      />
      <Route path="/settings/storeManage" element={<AuthStoreManage />} />
    </Routes>
  );
};

export default FranchiseRoutes;
