import React from 'react';
import {MuiModal} from 'shared/components/index';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import {useSelector, useDispatch} from 'react-redux';
import {
  selectFranchiseQsc,
  setOpenModal,
  getFranchiseStoresQscAsync,
} from 'franchise/utils/slices/frQscSlice';
import {useEffect} from 'react';
import {
  TableData,
  TableDataGroup,
  TableRow,
  Text,
  Button,
} from 'shared/components/index';

const Container = styled.div`
  width: 600px;
  height: 480px;
  background-color: white;
  padding: 20px 20px;
  align-items: center;
  overflow: scroll;
`;
const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
`;

const BtnWrapper = styled.button`
  border: none;
  background-color: white;
  margin-left: 20px;
  cursor: pointer;
`;
const QscBtnContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;

  padding: 2px 5px;
`;
const QscContainer = styled.div`
  margin-left: 5%;
`;
const StoreQscNoteModal = ({handleNoteCreateBtn}) => {
  const {openModal, storeQsc} = useSelector(selectFranchiseQsc);
  const dispatch = useDispatch();
  const handleModalClose = () => {
    dispatch(setOpenModal(false));
  };

  useEffect(() => {
    if (openModal === true) {
      dispatch(getFranchiseStoresQscAsync());
    }
  }, [dispatch, openModal]);
  return (
    <MuiModal open={openModal} onClose={handleModalClose}>
      <Container>
        <TitleWrapper>
          <Text bold fontSize="large">
            QSC 작성중인 매장
          </Text>

          <BtnWrapper onClick={handleModalClose}>
            <CloseIcon />
          </BtnWrapper>
        </TitleWrapper>
        <QscContainer>
          {storeQsc.map((qsc, index) => {
            return (
              <TableRow key={index} width="500px" border margin="10px 0 0 0">
                <TableDataGroup marginLeft="2%">
                  <TableData>
                    <Text style={{fontSize: '13px'}} anchor>
                      {qsc.store_name}
                    </Text>
                  </TableData>
                </TableDataGroup>
                <TableDataGroup ustifyContent="flex-end">
                  <TableData>
                    <Button
                      color="#6650c0"
                      onClick={() => {
                        handleNoteCreateBtn(qsc);
                      }}
                      style={{
                        margin: '0 0 0 150px',
                        height: '23px',
                        lineHeight: '0px',
                      }}
                    >
                      <QscBtnContent>QSC</QscBtnContent>
                    </Button>
                  </TableData>
                </TableDataGroup>
              </TableRow>
            );
          })}
        </QscContainer>
      </Container>
    </MuiModal>
  );
};

export default StoreQscNoteModal;
