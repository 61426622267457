import React from 'react';
import { Link as Li } from 'react-router-dom';
import PropTypes from 'prop-types';

const Link = (props) => {
  const { to, label } = props;
  return <Li to={to}>{label}</Li>;
};

Link.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
export default Link;
