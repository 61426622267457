/* eslint-disable no-use-before-define */
/* eslint-disable no-console */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {Text} from 'shared/components/index';
import Table from './MatrixTable';
import theme from 'shared/styles/theme';

export default function QscMatrix({
  svStoreDetails,
  svFirstStoreDetail,
  handleQSCMatrixGraphTable,
  matrixTableData,
  matrixLoading,
}) {
  const [expandedIndices, setExpandedIndices] = useState([]);

  useEffect(() => {
    if (svFirstStoreDetail !== null) {
      toggleExpand(0);
      handleQSCMatrixGraphTable(svFirstStoreDetail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [svFirstStoreDetail]);

  const toggleExpand = (index) => {
    if (expandedIndices.includes(index)) {
      setExpandedIndices(expandedIndices.filter((i) => i !== index));
    } else {
      setExpandedIndices([index]);
    }
  };

  return (
    <div style={{maxHeight: '500px', overflowY: 'auto'}}>
      {svStoreDetails?.qsc_history?.map((history, index) => (
        <React.Fragment key={index.toString()}>
          <div
            style={{
              width: '100%',
              height: '50px',
              borderBottom: '1px solid #d5d5d5',
              // borderRadius: '5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '10px 0 ',
            }}
          >
            <button
              onClick={() => {
                toggleExpand(index);
                handleQSCMatrixGraphTable(history);
              }}
              style={{
                width: '100%',
                textAlign: 'left',
                border: 'none',
                background: 'none',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  justifyContent: 'space-between',
                  padding: '0 20px 0 10px',
                }}
              >
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <Text style={{fontSize: '13px'}}>{`${new Date(
                    history?.created_on,
                  )
                    .toLocaleString('ko-KR', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false,
                    })
                    .replace(',', '')}`}</Text>
                </div>

                <Text
                  style={{
                    fontSize: '13px',
                    color: theme.colors.kalsPurple,
                    width: '80px',
                    textAlign: 'left',
                  }}
                  bold
                >{`총계: ${history?.score}`}</Text>
                {expandedIndices.includes(index) ? (
                  <ArrowDropUpIcon />
                ) : (
                  <ArrowDropDownIcon />
                )}
              </div>
            </button>
          </div>

          {expandedIndices.includes(index) && (
            <Table
              matrixTableData={matrixTableData}
              matrixLoading={matrixLoading}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
}
