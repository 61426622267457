import {useEffect} from 'react';
import styled from 'styled-components';

import NaverMapAPI from './NaverMapAPI';
// import SearchStoreList from './SearchStore';
import NewLogoCircle from 'shared/assets/icons/NewLogoCircle';
// import {useSelector} from 'react-redux';
// import {selectMap} from 'shared/utils/redux/mapSlice';
import ResultStoreList from './ResultStoreList';
import AppText from 'shared/components/AppText';
import {OwlerRNHeader} from 'shared/components/index';

const Container = styled.div`
  display: flex;
  overflow: scroll;
  flex-direction: column;
  align-items: center;
  /* background-color: #fafafa; */

  > * {
    margin: 0 0 0 0;
  }
`;

const LogoWrapper = styled.div`
  //margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.7vh 3vw;
  background: ${(props) =>
    props.isOpen !== true
      ? 'linear-gradient(90deg, rgba(242,242,242,1) 0%, rgba(106,120,240,0) 0%, rgba(157,134,253,1) 100%)'
      : 'linear-gradient(90deg, rgba(157,134,253,1) 0%, rgba(106,120,240,0) 100%)'};
  transition: background 1.2s ease-in-out;
`;

const MarketingTemplate = ({
  zoom,
  Latitude,
  Longtitude,
  handleSubmit,
  roadAddress,
  alls,
  setLat,
  setLng,
  setZoom,
  buttonClick,
  mylat,
  mylng,
  listOpen,
  setListOpen,
  loading,
}) => {
  // const {selectedMarker} = useSelector(selectMap);
  useEffect(() => {
    const preventZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };

    document.addEventListener('touchmove', preventZoom, {passive: false});

    return () => {
      document.removeEventListener('touchmove', preventZoom);
    };
  }, []);

  return (
    <Container>
      <LogoWrapper isOpen={listOpen}>
        <NewLogoCircle length={45} />

        <AppText text="매장 선택" fontSize="18px" />

        <AppText text="" />
      </LogoWrapper>

      <div>
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
              width: '100vw',
            }}
          >
            Loading...
          </div>
        ) : (
          <NaverMapAPI
            zoom={zoom}
            Latitude={Latitude}
            Longtitude={Longtitude}
            mylat={mylat}
            mylng={mylng}
            handleSubmit={handleSubmit}
            roadAddress={roadAddress}
            alls={alls}
            buttonClick={buttonClick}
            listOpen={listOpen}
            setListOpen={setListOpen}
            setZoom={setZoom}
          />
        )}
      </div>

      <div
        style={{
          paddingHorizontal: 10,
          paddingVertical: 20,
          backgroundColor: '#fafafa',
          width: '100%',
          zIndex: 99,
        }}
      >
        <OwlerRNHeader title="근처 매장" />
      </div>

      <ResultStoreList
        alls={alls}
        setLat={setLat}
        setLng={setLng}
        setZoom={setZoom}
        listOpen={listOpen}
        buttonClick={buttonClick}
      />
    </Container>
  );
};

export default MarketingTemplate;
