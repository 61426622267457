import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const StyledSvg = styled.svg`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : null)};
`;
const RightIcon = ({ length, fill, onClick }) => {
  return (
    <StyledSvg
      onClick={onClick}
      width={length}
      height={length}
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="caret-right"
      className="svg-inline--fa fa-caret-right fa-w-6"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 192 512">
      <path
        fill={fill}
        d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z"
      />
    </StyledSvg>
  );
};

RightIcon.propTypes = {
  length: PropTypes.number,
  fill: PropTypes.string,
  onClick: PropTypes.func,
};
RightIcon.defaultProps = {
  length: 20,
  fill: 'black',
  onClick: null,
};
export default RightIcon;
