import {Wrapper, Drawer} from 'shared/components/index';
import {Toaster} from 'react-hot-toast';
import styled from 'styled-components';
import ReactCalendar from './ReactCalendar';
import DayScheduleModal from './DayScheduleModal';

const Container = styled.div`
  margin-top: 68px;
  width: 1060px;
  display: flex;
  margin-left: 45px;
`;

const CalendarTemplate = ({
  setSelectedDate,
  handleStoreClick,
  workerAvailability,
  onRefresh,
}) => {
  return (
    <Wrapper handleStoreClick={handleStoreClick}>
      <Toaster toastOptions={{duration: 5000}} />

      <Drawer why="true">
        <Container>
          <ReactCalendar
            setSelectedDate={setSelectedDate}
            workerAvailability={workerAvailability}
          />

          <DayScheduleModal onRefresh={onRefresh} />
        </Container>
      </Drawer>
    </Wrapper>
  );
};

export default CalendarTemplate;
