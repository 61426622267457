import {Text, FranchiseDrawer, FranchiseWrapper} from 'shared/components/index';
import ChangePaymentModal from './ChangePaymentModal';
import styled from 'styled-components';
import theme from 'shared/styles/theme';
import {Toaster} from 'react-hot-toast';
import Button from '@mui/material/Button';
import EastIcon from '@mui/icons-material/East';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {useState, useEffect} from 'react';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AddIcon from '@mui/icons-material/Add';
import PaymentPosition from './PaymentPosition';

const Container = styled.div`
  margin-left: 60px;
  width: 1050px;
`;

const ManagerWrapper = styled.div`
  width: 718px;
  height: 270px;
  background-color: ${theme.colors.grey9};
  padding: 8px;
  margin: 10px 0;
  border-radius: 15px;
`;

const PaymentLine = styled.div`
  display: flex;
  > * {
    margin: 10px;
  }
`;
const PaymentMemberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 120px;
  height: 165px;
  min-width: 120px;
  min-height: 150px;
`;

const Blank = styled.div`
  margin: 0 0 12px 0;
  min-height: 27px;
`;

const From = styled.div`
  margin: 0 0 12px 0;
  color: white;
  background-color: ${theme.colors.kalsPurple};
  width: 60px;
  font-size: 14px;
  border-radius: 12px;
  min-height: 27px;
  line-height: 27px;
  text-align: center;
`;

const Sign = styled.div`
  min-height: 90px;
  min-width: 90px;
  border-radius: 15px;
  background-color: white;
  margin-bottom: 10px;
  position: relative;
`;

const ButtonWrapper = styled.div`
  width: 240px;
  float: right;
  margin-right: 10px;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
`;
const StyledButton = styled(Button)`
  && {
    background-color: ${({backgroundcolor}) => backgroundcolor} !important;
  }
`;

const PaymentManagementTemplate = ({
  supervisorList,
  handleSvSelect,
  positionLists,
  patchArray,
  postArray,
  deleteArray,
  onlyPositionLists,
  handleChangeSvPositions,
}) => {
  const [array, setArray] = useState(positionLists);
  const [state, setState] = useState('normal');
  const [deletedArray, setDeletedArray] = useState([]);

  const nameOrigin = positionLists.map((sign) => {
    return sign.name;
  });
  const [nameArray, setNameArray] = useState(nameOrigin);
  const [signName, setSignName] = useState('');

  useEffect(() => {
    setArray(positionLists);
    setNameArray(
      positionLists.map((sign) => {
        return sign.name;
      }),
    );
  }, [positionLists]);

  const postSign = () => {
    if (signName === '' || signName === undefined) {
      alert('이름을 입력해주세요');
      return;
    }
    let newArray = [...array];
    let newNameArray = [...nameArray];

    newArray.push({
      name: signName,
      franchise_position_uid: ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(
        /[018]/g,
        (c) =>
          (
            c ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
          ).toString(16),
      ),
    });
    newNameArray.push(signName);
    setSignName('');

    setNameArray(newNameArray);
    setArray(newArray);
  };

  const deleteSign = (_id, _name) => {
    let newArray = [...deletedArray];
    if (array.length <= 2) {
      alert('직급은 최소 1개 이상 존재해야 합니다.');
      return;
    }

    newArray.push(array.find((each) => each.franchise_position_uid === _id));
    setDeletedArray(newArray);
    setArray(array.filter((each) => each.franchise_position_uid !== _id));
    setNameArray(nameArray.filter((each) => each !== _name));
  };

  const changeSign = (index) => {
    let newArray = [...array];
    let newNameArray = [...nameArray];

    [newArray[index], newArray[index - 1]] = [
      newArray[index - 1],
      newArray[index],
    ];

    [newNameArray[index], newNameArray[index - 1]] = [
      newNameArray[index - 1],
      newNameArray[index],
    ];

    setArray(newArray);
    setNameArray(newNameArray);
  };

  const updateNameArray = (index, newName) => {
    let newArray = [...nameArray];
    newArray[index] = newName;
    setNameArray(newArray);
  };

  const updateArrayNames = () => {
    const newArray = array.map((item, index) => ({
      ...item,
      name: nameArray[index] || item.name, // nameArray의 값이 없으면 기존 이름을 유지
    }));

    setArray(newArray);
  };

  const saveUpdate = () => {
    if (state === 'patch') {
      patchArray(array, nameArray);
    }
    if (state === 'post') {
      postArray(nameArray);
    }
    if (state === 'delete') {
      deleteArray(deletedArray);
      setDeletedArray([]);
    }
  };

  return (
    <FranchiseWrapper titleText="PaymentManagement">
      <FranchiseDrawer>
        <Container>
          <Text margin="0 0 0 12px" bold fontSize="large">
            결재 관리
          </Text>

          <ManagerWrapper>
            <PaymentLine>
              {array.map((paymentMember, index) => {
                return (
                  <PaymentMemberWrapper
                    key={paymentMember.franchise_position_uid}
                  >
                    {index <= 1 ? (
                      <From>{index === 0 ? '매장' : '본사'}</From>
                    ) : (
                      <Blank />
                    )}
                    <Sign>
                      {index !== 0 && (
                        <>
                          {state !== 'patch' ? (
                            <EastIcon
                              sx={{
                                position: 'relative',
                                top: '33px',
                                left: '-37px',
                                color: `${theme.colors.kalsPurple}`,
                              }}
                            />
                          ) : (
                            <>
                              {index >= 2 && (
                                <AutorenewIcon
                                  sx={{
                                    position: 'relative',
                                    top: '33px',
                                    left: '-37px',
                                    transform: 'rotate(45deg)',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => changeSign(index)}
                                />
                              )}
                            </>
                          )}
                        </>
                      )}
                      {index !== 0 && state === 'delete' && (
                        <RemoveCircleIcon
                          sx={{
                            position: 'relative',
                            top: '-5px',
                            right: '-48px',
                            color: `#FA5858`,
                            cursor: 'pointer',
                          }}
                          onClick={() =>
                            deleteSign(
                              paymentMember.franchise_position_uid,
                              paymentMember.name,
                            )
                          }
                        />
                      )}
                    </Sign>
                    {state !== 'patch' ? (
                      <Text
                        style={{
                          width: '100%',
                          textAlign: 'center',
                          fontSize: '14px',
                        }}
                      >
                        {paymentMember.name}
                      </Text>
                    ) : (
                      <input
                        defaultValue={paymentMember.name}
                        style={{
                          width: '100px',
                          fontSize: '14px',
                          textAlign: 'center',
                        }}
                        onChange={(e) => updateNameArray(index, e.target.value)}
                      />
                    )}
                  </PaymentMemberWrapper>
                );
              })}
              {array.length < 5 && state === 'post' && (
                <PaymentMemberWrapper>
                  <Blank />
                  <Sign style={{cursor: 'pointer'}} onClick={() => postSign()}>
                    <AddIcon
                      sx={{
                        position: 'relative',
                        top: '33px',
                        left: '33px',
                      }}
                    />
                  </Sign>
                  <input
                    placeholder="직급명 입력"
                    value={signName}
                    style={{
                      width: '100px',
                      fontSize: '14px',
                      textAlign: 'center',
                    }}
                    onChange={(e) => setSignName(e.target.value)}
                  />
                </PaymentMemberWrapper>
              )}
            </PaymentLine>
            {state === 'normal' ? (
              <ButtonWrapper>
                <StyledButton
                  backgroundcolor="#FA5858"
                  sx={{
                    color: 'white',
                    fontWeight: '500',
                    fontSize: '14px',
                    height: '25px',
                    borderRadius: '10px',
                    width: '70px',
                  }}
                  onClick={() => setState('delete')}
                >
                  삭제
                </StyledButton>
                <StyledButton
                  backgroundcolor="#5858FA"
                  sx={{
                    color: 'white',
                    fontWeight: '500',
                    fontSize: '14px',
                    height: '25px',
                    borderRadius: '10px',
                    width: '70px',
                  }}
                  onClick={() => setState('post')}
                >
                  추가
                </StyledButton>
                <StyledButton
                  backgroundcolor="#8773d6"
                  sx={{
                    color: 'white',
                    fontWeight: '500',
                    fontSize: '14px',
                    height: '25px',
                    borderRadius: '10px',
                    width: '70px',
                  }}
                  onClick={() => setState('patch')}
                >
                  수정
                </StyledButton>
              </ButtonWrapper>
            ) : (
              <ButtonWrapper style={{width: '160px'}}>
                <StyledButton
                  backgroundcolor={theme.colors.grey3}
                  sx={{
                    color: 'white',
                    fontWeight: '500',
                    fontSize: '14px',
                    height: '25px',
                    borderRadius: '10px',
                    width: '70px',
                  }}
                  onClick={() => {
                    setState('normal');
                    setArray(positionLists);
                    setNameArray(nameOrigin);
                  }}
                >
                  취소
                </StyledButton>
                <StyledButton
                  backgroundcolor="#8773d6"
                  sx={{
                    color: 'white',
                    fontWeight: '500',
                    fontSize: '14px',
                    height: '25px',
                    borderRadius: '10px',
                    width: '70px',
                  }}
                  onClick={() => {
                    try {
                      nameArray.forEach((name) => {
                        if (name === '' || name === undefined) {
                          throw new Error('이름을 작성해주세요');
                        }
                      });
                      if (window.confirm('정말 저장하시겠습니까?')) {
                        updateArrayNames();
                        saveUpdate();
                        setState('normal');
                      }
                    } catch (error) {
                      alert(error.message);
                    }
                  }}
                >
                  저장
                </StyledButton>
              </ButtonWrapper>
            )}
          </ManagerWrapper>

          <ChangePaymentModal
            positionLists={onlyPositionLists}
            handleChangeSvPositions={handleChangeSvPositions}
          />
        </Container>
        <PaymentPosition
          supervisorList={supervisorList}
          handleSvSelect={handleSvSelect}
        />
      </FranchiseDrawer>
    </FranchiseWrapper>
  );
};

export default PaymentManagementTemplate;
