import React, {useCallback, useEffect, useState} from 'react';
import {toast} from 'react-hot-toast';

import StoreJoinRequestTemplate from './template/index';
import {useDispatch, useSelector} from 'react-redux';
import {
  getSvJoinRequestListAsync,
  postAcceptSvJoinRequestAsync,
  rejectSvJoinRequestAsync,
  selectFranchiseUser,
} from 'franchise/utils/slices/frUserSlice';

const StoreJoinRequests = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {svJoinRequestList, supervisorData} = useSelector(selectFranchiseUser);

  useEffect(() => {
    setLoading(true);
    dispatch(getSvJoinRequestListAsync(supervisorData))
      .unwrap()
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        alert(err.message);
      });
  }, [dispatch]);

  const handleAcceptJoinRequest = useCallback(
    async (_sv) => {
      dispatch(
        postAcceptSvJoinRequestAsync({
          sv_user_uid: supervisorData,
          in_charge_store_uid: _sv.in_charge_store_uid,
        }),
      )
        .unwrap()
        .then(() => {
          toast.success('Accepter successfully!');
          setLoading(false);
          dispatch(getSvJoinRequestListAsync(supervisorData));
        })
        .catch((err) => {
          alert(err.message);
        });
    },
    [dispatch, supervisorData],
  );

  const handleRejectJoinRequest = useCallback(
    async (_sv) => {
      dispatch(
        rejectSvJoinRequestAsync({
          sv_user_uid: supervisorData,
          in_charge_store_uid: _sv.in_charge_store_uid,
        }),
      )
        .unwrap()
        .then(() => {
          toast.success('Rejected successfully!');
          setLoading(false);
          dispatch(getSvJoinRequestListAsync(supervisorData));
        })
        .catch((err) => {
          alert(err.message);
        });
    },
    [dispatch],
  );

  return (
    <StoreJoinRequestTemplate
      loading={loading}
      svJoinRequestList={svJoinRequestList}
      handleAcceptJoinRequest={handleAcceptJoinRequest}
      handleRejectJoinRequest={handleRejectJoinRequest}
    />
  );
};

export default StoreJoinRequests;
