import React from 'react';
import styled from 'styled-components';
import {VictoryChart, VictoryLine, VictoryAxis} from 'victory';

import {Text} from 'shared/components/index';
import theme from 'shared/styles/theme';

const hexColor = theme.colors.bgSkelet;
const red = parseInt(hexColor.slice(1, 3), 16);
const green = parseInt(hexColor.slice(3, 5), 16);
const blue = parseInt(hexColor.slice(5, 7), 16);

const StyledWrapper = styled.div`
  //background-color: rgba(${red}, ${green}, ${blue}, 0.65);
  padding: 5px 10px;
  border-radius: 10px;
  margin-top: 0px;
`;

const CardTitleWrap = styled.div`
  width: 100%;
  height: 0px;
  margin: 0 0 20px 0;
`;

const VictoryLineCharWrapper = styled.div`
  margin-top: 15px;
  position: relative;
`;

const MonthlyQSCPerformance = ({thisMonthScoreGraphData}) => {
  const koreanMonthLabels = [
    '1월',
    '2월',
    '3월',
    '4월',
    '5월',
    '6월',
    '7월',
    '8월',
    '9월',
    '10월',
    '11월',
    '12월',
  ];

  return (
    <StyledWrapper>
      <CardTitleWrap>
        {/* <Text color={theme.colors.white} fontSize="xl2">
          월간 수행 현황
        </Text> */}
      </CardTitleWrap>

      <VictoryLineCharWrapper>
        {thisMonthScoreGraphData !== null && (
          <VictoryChart height={240}>
            <VictoryLine
              data={Object.entries(thisMonthScoreGraphData).map(
                ([month, value]) => ({
                  x: month,
                  y: value !== null ? value : 0,
                }),
              )}
              style={{
                data: {stroke: '#8b77d9'},
              }}
            />
            <VictoryAxis
              tickValues={Object.keys(thisMonthScoreGraphData)}
              tickFormat={(tick) => koreanMonthLabels[parseInt(tick) - 1]}
              style={{
                tickLabels: {fontSize: 12, fill: 'black'},
              }}
            />
            <VictoryAxis
              dependentAxis
              domain={[1, 100]}
              style={{
                tickLabels: {fontSize: 12, fill: 'black'},
                axis: {stroke: 'transparent'},
              }}
            />
          </VictoryChart>
        )}
      </VictoryLineCharWrapper>
    </StyledWrapper>
  );
};

export default MonthlyQSCPerformance;
