import RequestListTemplate from './template/index';
import {useEffect, useState, useCallback} from 'react';
import {
  getRequestListAsync,
  getRequestBrandListAsync,
  setRequestList,
  setCurrentRequest,
  selectRequest,
  setRequestPageCount,
} from 'owler/utils/slices/requestSlice';
import {useDispatch, useSelector} from 'react-redux';
import {selectStore, setCurrentStore} from 'owler/utils/slices/storeSlice';
import {setSelectedDateT} from 'owler/utils/slices/taskSlice';

const RequestList = () => {
  const dispatch = useDispatch();
  const {currentStore} = useSelector(selectStore);
  const {currentRequestPage, requestList} = useSelector(selectRequest);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(setSelectedDateT(new Date()));
    if (currentStore !== null) {
      if (!currentStore?.is_hq) {
        setIsLoading(true);
        dispatch(
          getRequestListAsync({
            store_uid: currentStore.store_uid,
            page: currentRequestPage,
          }),
        )
          .unwrap()
          .then((payload) => {
            dispatch(setRequestPageCount(payload?.count));
            dispatch(setRequestList(payload?.results));
          })
          .catch((err) => {
            alert(err.message);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else if (currentStore?.is_hq) {
        setIsLoading(true);
        dispatch(
          getRequestBrandListAsync({
            store_uid: currentStore.store_uid,
            page: currentRequestPage,
          }),
        )
          .unwrap()
          .then((payload) => {
            dispatch(setRequestPageCount(payload?.count));
            dispatch(setRequestList(payload?.results));
          })
          .catch((err) => {
            alert(err.message);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStore, currentRequestPage]);

  const handleStoreClick = useCallback(
    (_store) => {
      dispatch(setCurrentStore(_store));
    },
    [dispatch],
  );

  return (
    <RequestListTemplate
      currentStore={currentStore}
      requestList={requestList}
      isLoading={isLoading}
      currentRequestPage={currentRequestPage}
      handleStoreClick={handleStoreClick}
    />
  );
};

export default RequestList;
