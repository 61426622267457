import React, {useState, useEffect} from 'react';
import {Editor} from 'react-draft-wysiwyg';
import {EditorState, convertToRaw, ContentState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {useDispatch} from 'react-redux';
import {postRichImageThunk} from 'owler/utils/slices/noticeSlice';

function RichTextEditor({initialContent, onContentChange}) {
  const dispatch = useDispatch();

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (initialContent) {
      const contentBlock = htmlToDraft(initialContent);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks,
        );
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
      }
    }
  }, [initialContent]);

  const onEditorStateChange = (newState) => {
    setEditorState(newState);
    const rawContentState = convertToRaw(newState.getCurrentContent());
    const html = draftToHtml(rawContentState);
    onContentChange(html);
  };

  const uploadImageCallBack = (file) => {
    const formData = new FormData();

    formData.append('image', file);

    return dispatch(postRichImageThunk(formData))
      .unwrap()
      .then((payload) => {
        const listOfIMGUrls = payload;
        if (listOfIMGUrls && listOfIMGUrls.length > 0) {
          return {data: {link: payload}};
        } else {
          throw new Error('Upload failed');
        }
      });
  };

  const toolbarOptions = {
    options: [
      'inline',
      'blockType',
      'fontSize',
      'fontFamily',
      'list',
      'textAlign',
      'colorPicker',
      'link',
      'embedded',
      'emoji',
      'image',
      'remove',
      'history',
    ],
    inline: {
      options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
    },
    fontSize: {
      options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    },
    fontFamily: {
      options: [
        'Arial',
        'Georgia',
        'Impact',
        'Tahoma',
        'Times New Roman',
        '맑은고딕',
        '궁서',
        '궁서체',
        '굴림',
        '굴림체',
      ],
    },
    list: {
      options: ['unordered', 'ordered'],
    },
    textAlign: {
      options: ['left', 'center', 'right', 'justify'],
    },
    colorPicker: {
      // default color options, can be customized
    },
    link: {
      // link options, can be customized
    },
    image: {
      uploadCallback: uploadImageCallBack,
      alt: {present: true, mandatory: false},
      previewImage: true,
      inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
    },
  };

  return (
    <Editor
      editorState={editorState}
      toolbar={toolbarOptions}
      onEditorStateChange={onEditorStateChange}
      wrapperClassName="rich-text-editor-wrapper"
      editorClassName="rich-text-editor-editor"
      editorStyle={{
        minHeight: '250px',
        maxHeight: '300px', // set the maximum height
        overflow: 'auto', // show scrollbar when the content exceeds the maximum height
        backgroundColor: '#f0f0f0',
        borderBottom: '1px solid #e0e0e0',
      }}
    />
  );
}

export default RichTextEditor;
