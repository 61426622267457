import styled from 'styled-components';
import theme from 'shared/styles/theme';
import CheckIcon from '@mui/icons-material/Check';

const TableWrapper = styled.div`
  width: 1050px;
  margin: 20px 0;
`;

const TheTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed; /* 고정 테이블 레이아웃 사용 */
  border: 2px solid ${theme.colors.grey3};
  font-size: 15px;
  text-align: center;

  th,
  td {
    border: 2px solid ${theme.colors.grey3};
  }

  th {
    height: 32px;
    background-color: #dfd7f3;
    font-weight: 600;
  }
`;

const PdfBodyArea = ({commuteLog}) => {
  const timeformmater = (time) => {
    const date = new Date(time);
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // 시간과 분을 항상 두 자리 숫자로 표시 (예: '07:05')
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}`;
  };

  return (
    <TableWrapper>
      <TheTable border="1">
        <thead>
          <tr>
            <th>일자</th>
            <th colSpan="2">근무 예정</th>
            <th>출근</th>
            <th>퇴근</th>
            <th>지각</th>
            <th>조퇴</th>
            <th>비고</th>
          </tr>
        </thead>
        <tbody>
          {commuteLog.length !== 0 &&
            Object.values(commuteLog?.commute_log_list).map(
              (dayCommute, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td colSpan="2">
                      {dayCommute.schedule.length !== 0 ? (
                        dayCommute.schedule.map((eachSchedule) => {
                          return (
                            <div>
                              {timeformmater(eachSchedule.start)}~
                              {timeformmater(eachSchedule.end)}
                            </div>
                          );
                        })
                      ) : (
                        <div style={{color: '#BDBDBD'}}>
                          등록된 근무표가 없습니다.
                        </div>
                      )}
                    </td>
                    <td>
                      {dayCommute.arrive.map((eachArrive) => {
                        return <div>{timeformmater(eachArrive)}</div>;
                      })}
                    </td>
                    <td>
                      {dayCommute.leave.map((eachLeave) => {
                        return <div>{timeformmater(eachLeave)}</div>;
                      })}
                    </td>
                    <td>{dayCommute.late === true && <CheckIcon />}</td>
                    <td>{dayCommute.early === true && <CheckIcon />}</td>
                    <td></td>
                  </tr>
                );
              },
            )}
        </tbody>
      </TheTable>
    </TableWrapper>
  );
};
export default PdfBodyArea;
