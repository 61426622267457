import {useEffect, useState} from 'react';
import styled from 'styled-components';
import theme from 'shared/styles/theme';
import {Text} from 'shared/components/index';
import Button from '@mui/material/Button';
import {useSelector} from 'react-redux';
import {selectPosition} from 'owler/utils/slices/positionSlice';

const Container = styled.div`
  width: 95%;
  margin-left: 2.5%;
  margin-top: 10px;
`;
const State = styled.div`
  //border: 1px solid black;
  background-color: ${theme.colors.kalsPurple};
  width: 100%;
  height: 45px;
  border-radius: 12px;
`;
const Request = styled.div`
  //border: 1px solid black;
  background-color: #f2f2f2;
  width: 100%;
  min-height: 45px;
  border-radius: 12px;
  margin: 10px 0;
  display: flex;
  align-items: center;
`;
const RedText = styled.span`
  color: #2e2e2e;
  font-weight: 600;
`;
const ButtonWrap = styled.div`
  width: 30%;
  height: 35px;
`;
const StyledButton = styled(Button)`
  && {
    background-color: ${({backgroundcolor}) => backgroundcolor} !important;
  }
`;
const StyledInput = styled.input`
  width: 85%;
  height: 45px;
  border-radius: 12px;
  margin-bottom: 20px;
  border: 1px solid ${theme.colors.grey3};
  padding: 0 15px;
`;
const OfferUser = styled.div`
  width: 100%;
  height: 55px;
  border-radius: 16px;
  border: 1px solid ${theme.colors.grey6};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
`;

const NameWrapper = styled.div`
  padding: 5px 0;
`;
const JoinManagement = ({
  accessJoin,
  rejectJoin,
  inviteJoin,
  searchMemberByPhone,
}) => {
  const {joinMembers, offerList} = useSelector(selectPosition);
  const [input, setIput] = useState('');
  const [searchMode, setSearchMode] = useState(false);

  useEffect(() => {}, [joinMembers, offerList]);

  const handleSearch = () => {
    setSearchMode(true);
    searchMemberByPhone(input);
  };

  return (
    <Container>
      <StyledInput
        placeholder="번호로 검색하세요. 예)010 1234 5678"
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            handleSearch();
          }
        }}
        value={input}
        maxLength="13"
        onChange={(e) => {
          const rawValue = e.target.value.replace(/\D/g, ''); // 숫자만 추출
          let formattedValue;

          if (rawValue.length <= 3) {
            formattedValue = rawValue;
          } else if (rawValue.length <= 7) {
            formattedValue = rawValue.slice(0, 3) + ' ' + rawValue.slice(3);
          } else {
            formattedValue =
              rawValue.slice(0, 3) +
              ' ' +
              rawValue.slice(3, 7) +
              ' ' +
              rawValue.slice(7);
          }
          setIput(formattedValue);
        }}
      />
      <StyledButton
        backgroundcolor={theme.colors.kalsPurple}
        sx={{
          color: 'white',
          fontWeight: '600',
          fontSize: '20px',
          height: '46px',
          margin: '-6px 0 0 2%',
          borderRadius: '12px',
          width: '13%',
        }}
        onClick={() => {
          if (searchMode === false) {
            handleSearch();
          }
          if (searchMode === true) {
            setSearchMode(false);
          }
        }}
      >
        {searchMode === true ? 'X' : '조회'}
      </StyledButton>

      {searchMode === false ? (
        <>
          <State>
            {joinMembers.length === 0 ? (
              <Text color="#FAFAFA" fontSize="large" margin="8px 0 0 20px">
                현재 합류 신청한 인원이 없습니다.
              </Text>
            ) : (
              <Text color="#FAFAFA" fontSize="large" margin="8px 0 0 20px">
                현재 <RedText>{joinMembers.length}</RedText> 명이 합류 신청을
                했습니다.
              </Text>
            )}
          </State>

          {joinMembers?.map((member, index) => {
            return (
              <Request key={index}>
                <NameWrapper style={{width: '70px'}}>
                  <Text margin="0px 0 0 20px" bold>
                    {member.name}
                  </Text>
                </NameWrapper>
                <div style={{width: '370px', overflow: 'hidden'}}>
                  <Text margin="0px 0 0 20px">{member.phone_number}</Text>
                </div>
                <ButtonWrap>
                  <StyledButton
                    backgroundcolor="#FA5858"
                    sx={{
                      color: 'white',
                      fontWeight: '500',
                      fontSize: '15px',
                      height: '35px',
                      marginLeft: '40px',
                      borderRadius: '8px',
                    }}
                    onClick={() => rejectJoin(member.member_uid)}
                  >
                    거절
                  </StyledButton>
                  <StyledButton
                    backgroundcolor="#5858FA"
                    sx={{
                      color: 'white',
                      fontWeight: '500',
                      fontSize: '15px',
                      height: '35px',
                      marginLeft: '20px',
                      borderRadius: '8px',
                    }}
                    onClick={() => accessJoin(member.member_uid)}
                  >
                    수락
                  </StyledButton>
                </ButtonWrap>
              </Request>
            );
          })}
        </>
      ) : (
        <>
          {offerList !== null ? (
            <OfferUser key={offerList.user_uid}>
              <div
                style={{
                  width: '100px',
                  textAlign: 'left',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Text margin="0 0 0 3px" style={{width: '100%'}} bold>
                  {offerList?.name}
                </Text>
                <Text>{offerList?.phone_number}</Text>
              </div>
              <StyledButton
                backgroundcolor={theme.colors.kalsPurple}
                sx={{
                  color: 'white',
                  fontWeight: '500',
                  fontSize: '18px',
                  height: '35px',
                  borderRadius: '8px',
                }}
                onClick={() => inviteJoin(offerList.user_uid)}
              >
                초대
              </StyledButton>
            </OfferUser>
          ) : (
            <div style={{fontSize: '18px'}}>
              해당 연락처로 가입한 유저 정보가 존재하지 않거나 이미 매장에
              합류되어 있습니다.
            </div>
          )}
        </>
      )}
    </Container>
  );
};

export default JoinManagement;
