import React from 'react';
import {MuiModal} from 'shared/components/index';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import {useSelector} from 'react-redux';
import {selectFranchiseSupplemention} from 'franchise/utils/slices/frSupplementionSlice';
import {Text} from 'shared/components/index';

const Container = styled.div`
  width: 600px;
  height: 350px;
  background-color: white;
  padding: 15px 10px;
  align-items: center;
  overflow: scroll;
`;
const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const BtnWrapper = styled.button`
  border: none;
  background-color: white;
  margin-left: 20px;
  cursor: pointer;
`;

const ImagesWrapper = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 255px;
  overflow-x: auto;
  display: flex;

  img {
    width: 300px;
    height: 225px;
    min-width: 300px;
    min-height: 225px;
    margin: 0 10px;
    object-fit: contain;
    background-color: #424242;
  }
`;

const ExtraImageShowModal = ({
  extraImageShowModalOpen,
  setExtraImageShowModalOpen,
}) => {
  const {selectedImages} = useSelector(selectFranchiseSupplemention);
  const handleModalClose = () => {
    setExtraImageShowModalOpen(false);
  };

  return (
    <MuiModal open={extraImageShowModalOpen} onClose={handleModalClose}>
      <Container>
        <TitleWrapper>
          <Text bold fontSize="lg"></Text>

          <BtnWrapper onClick={handleModalClose}>
            <CloseIcon />
          </BtnWrapper>
        </TitleWrapper>
        <ImagesWrapper>
          {selectedImages?.map((image) => {
            return <img key={image} src={image} alt="몰라" />;
          })}
        </ImagesWrapper>
      </Container>
    </MuiModal>
  );
};

export default ExtraImageShowModal;
