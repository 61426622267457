import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import {
  MuiModal,
  Text,
  TableRow,
  TableDataGroup,
  TableData,
} from 'shared/components/index';

import {
  selectFranchiseUser,
  setChangePaymentModal,
} from 'franchise/utils/slices/frUserSlice';

import theme from 'shared/styles/theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 40px 30px;
  align-items: center;
  overflow: hidden;
  border-radius: 20px;
`;

const TitleWrapper = styled.div`
  width: 100%;
`;

const ChangePaymentModal = ({positionLists, handleChangeSvPositions}) => {
  const {changePaymentModal} = useSelector(selectFranchiseUser);
  const dispatch = useDispatch();

  const handleModalClose = () => {
    dispatch(setChangePaymentModal(false));
  };

  const handleSvPositionClick = (_position) => {
    handleChangeSvPositions(_position);
    dispatch(setChangePaymentModal(false));
  };

  return (
    <MuiModal open={changePaymentModal} onClose={handleModalClose}>
      <Container>
        <TitleWrapper>
          <Text bold fontSize="xl">
            결제 포지션 변경
          </Text>
        </TitleWrapper>

        {positionLists &&
          positionLists.map((position) => {
            return (
              <TableRow
                width="250px"
                bgColor={theme.colors.kalsPurple}
                onClick={() => handleSvPositionClick(position.position_uid)}
                border
                key={position.position_uid}
              >
                <TableDataGroup justifyContent="center">
                  <TableData>
                    <Text color="white" anchor>
                      {position.name}
                    </Text>
                  </TableData>
                </TableDataGroup>
              </TableRow>
            );
          })}
      </Container>
    </MuiModal>
  );
};
export default ChangePaymentModal;
