/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React from 'react';
import {useSelector} from 'react-redux';
import styled, {css} from 'styled-components';
import {Formik} from 'formik';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import AddStores from './AddStores';
import {selectStore} from 'owler/utils/slices/storeSlice';
import {
  FileSelector,
  MuiButton,
  MuiRadio,
  RichTextEditor,
  Text,
} from 'shared/components/index';
import {useDropzone} from 'react-dropzone';
import theme from 'shared/styles/theme';
import {ImageSelector} from 'shared/components/index';

const DropArea = styled.div`
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  font-size: 15px;
  padding: 10px;

  &:hover {
    cursor: pointer;
    background-color: #e6e6e6;
  }

  border: 1px solid grey;
  border-radius: 10px;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: ${({container}) => (container ? 'row' : 'column')};
  &:not(:last-child) {
    margin-right: 30px;
  }
  width: ${({width}) => width || '100%'};
  ${({center}) =>
    center &&
    css`
      margin: 0 auto;
    `}
`;

const Form = styled.form`
  width: 920px;
  padding: 30px;
  margin: 0 auto 50px auto;
  border: 1px solid ${theme.colors.grey6};
  border-radius: 15px;
  & > * {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    &:last-child {
      margin-top: 20px;
    }
  }
`;

const TitleText = styled.div`
  font-size: 18px;
  color: #252525;
  margin-bottom: 10px;
`;

const FlexBox = styled.div`
  display: flex;
  margin: 0 0 15px 0;
  justify-content: space-between;
`;

const Error = styled.div`
  color: #df0101;
  font-size: 12px;
  margin: -18px 0 10px 12px;
  opacity: 0.9;
`;

const FileWrapper = styled.div``;

const EptyFileWrapper = styled.div`
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  height: 60px;
  background-color: #f2f2f2;
`;

const Field = ({
  formikProps,
  label,
  name,
  placeholder,
  classes,
  value,
  ...restProps
}) => {
  return (
    <>
      <Typography gutterBottom>{label}</Typography>
      <TextField
        {...restProps}
        name={name}
        value={value || formikProps.values[name]}
        onChange={formikProps.handleChange}
        error={formikProps.touched[name] && Boolean(formikProps.errors[name])}
        helperText={
          (formikProps.touched[name] && formikProps.errors[name]) || ' '
        }
        variant="filled"
        placeholder={placeholder}
        className={classes && classes.text}
        InputProps={
          classes && {
            classes: {
              root: classes.text,
              input: classes.textInput,
              multiline: classes.multi,
            },
          }
        }
      />
    </>
  );
};

const MyDropzone = ({formikProps}) => {
  const {getRootProps, getInputProps} = useDropzone({
    onDrop: (acceptedFiles) => {
      formikProps.setFieldValue('files', acceptedFiles);
    },
    multiple: true,
  });

  return (
    <DropArea {...getRootProps()}>
      <input {...getInputProps()} />
      <p>파일 업로드</p>
    </DropArea>
  );
};

const NoticeAddForm = ({
  onSubmit,
  onValidate,
  handleBackBtnClick,
  handleContentChange,
}) => {
  const {currentStore} = useSelector(selectStore);

  return (
    <Formik
      onSubmit={onSubmit}
      validate={onValidate}
      initialValues={{
        notice_type: '',
        title: '',
        files: [],
        urls: [],
        storeList: [],
        description_images: [],
      }}
    >
      {(formikProps) => {
        return (
          <Form
            franchise={currentStore?.is_head_quarters}
            encType="multipart/form-data"
            onBlur={formikProps.handleBlur}
            onSubmit={formikProps.handleSubmit}
          >
            <FieldWrapper>
              <Text
                style={{fontSize: '25px', width: '100%', textAlign: 'center'}}
                margin="0 0 20px 0"
                bold
              >
                신규 공지 생성
              </Text>
              <TitleText>공지 확인</TitleText>
              <MuiRadio
                name="notice_type"
                value={formikProps.values.noticeType}
                labels={[
                  '단순 확인: 공지 확인자/미확인자 파악이 필요한 경우',
                  '사진 취합: 피드백을 사진으로 취합 받아야 하는 경우',
                ]}
                handleChange={formikProps.handleChange}
              />
            </FieldWrapper>
            <Error>{formikProps?.errors?.notice_type}</Error>

            <FieldWrapper>
              <TitleText style={{margin: '10px 0 0 0'}}>제목</TitleText>
              <Field
                formikProps={formikProps}
                fullWidth
                name="title"
                placeholder="제목을 입력하세요"
                style={{margin: '10px 0 0 0'}}
              />
            </FieldWrapper>

            <FieldWrapper>
              <TitleText>내용</TitleText>

              <div>
                <RichTextEditor onContentChange={handleContentChange} />
              </div>
            </FieldWrapper>

            <FieldWrapper container="column">
              <ImageSelector
                name="description_images"
                value={formikProps.values.description_images}
                formikProps={formikProps}
                label="공지 설명 사진"
                onChange={formikProps.handleChange}
              />
            </FieldWrapper>

            {/* <FlexBox>
              <FieldWrapper width="50%">
                <TitleText>파일 첨부</TitleText>

                {formikProps.values.files.length === 0 && <EptyFileWrapper />}

                {formikProps.values.files.map((file, index) => (
                  <FileWrapper key={index}>
                    <Field
                      formikProps={formikProps}
                      name={`files[${index}].name`}
                      placeholder="첨부할 파일을 선택하세요"
                      sx={{width: '350px'}}
                      value={file.name}
                      disabled
                    />
                  </FileWrapper>
                ))}
              </FieldWrapper>
            </FlexBox> */}

            <FieldWrapper container="column">
              <FileSelector
                name="files"
                value={formikProps}
                formikProps={formikProps}
                label=""
                onChange={formikProps.handleChange}
              />
            </FieldWrapper>

            <FieldWrapper>
              <TitleText>링크 첨부</TitleText>
              <Field
                name="urls"
                placeholder="URL을 적어주세요 ex)https://www.owlerz.kr"
                formikProps={formikProps}
              />
            </FieldWrapper>

            {currentStore?.is_hq && <AddStores formprops={formikProps} />}

            <FlexBox>
              <div></div>
              <div>
                <MuiButton
                  id="noticeAdd-goBack"
                  text="취소"
                  onClick={handleBackBtnClick}
                  backgroundColor="#bdbdbd"
                  textColor="white"
                  style={{margin: '0 10px 0 0'}}
                />

                <MuiButton
                  id="noticeAdd-submit"
                  backgroundColor={theme.colors.kalsPurple}
                  textColor="white"
                  text="완료"
                  type="submit"
                />
              </div>
            </FlexBox>
          </Form>
        );
      }}
    </Formik>
  );
};

export default NoticeAddForm;
