import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import api from 'shared/utils/api';

const initialState = {
  franchiseStores: [],
};

// get franchise stores
export const getFranchiseStoresAsync = createAsyncThunk(
  'franchise/getFranchiseStores',
  async uid => {
    const response = await api.getFranchiseStores(uid);
    const {result} = response.data.results;

    return response.data.results;
  },
);

export const franchiseSlice = createSlice({
  name: 'franchise',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder
      .addCase(getFranchiseStoresAsync.fulfilled, (state, action) => {
        state.franchiseStores = action.payload;
      })
      .addCase(getFranchiseStoresAsync.rejected, state => {
        state.franchiseStores = [];
      });
  },
});

export const selectFranchise = state => state.owler.franchise;

export default franchiseSlice.reducer;
