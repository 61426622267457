import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import api from 'shared/utils/api';

const initialState = {
  svNoticeList: [],
  selectedNotice: null,
  svNoticeCount: null,
  notifications: [],
};

// get sv notices by page thunk
export const getSvNoticesAsync = createAsyncThunk(
  'notice/getSvNotices',
  async (param) => {
    const response = await api.getSvNotices(param);

    const {results} = response.data;

    return results;
  },
);

// get notice detail
export const getSvNoticeDetailAsync = createAsyncThunk(
  'notice/getSvNoticeDetail',
  async (param) => {
    const response = await api.getSvNoticeDetail(param);

    const {results} = response.data;

    return results[0];
  },
);

// register a notice thunk
export const postSvNoticeAsync = createAsyncThunk(
  'notice/postSvNotice',
  async (data) => {
    const response = await api.postSvNotice(data);

    const {results} = response.data;

    return results;
  },
);

// Edit a notice thunk
export const patchSvNoticeAsync = createAsyncThunk(
  'notice/patchSvNotice',
  async (data) => {
    const response = await api.patchSvNotice(data);

    const {results} = response.data;

    return results;
  },
);

// delete a notice thunk
export const deleteSvNoticeAsync = createAsyncThunk(
  'notice/deleteSvNotice',
  async (data) => {
    const response = await api.deleteSvNotice(data);

    const {results} = response.data;

    return results;
  },
);

// get user's post notifications thunk
export const getSvNotificationsThunk = createAsyncThunk(
  'comUser/getSvNotifications',
  async (param) => {
    const response = await api.getSvNotifications(param);

    const {results} = response.data;

    return results;
  },
);
// patch user's view notification thunk
export const getViewNotificationDetailThunk = createAsyncThunk(
  'comUser/getPatchViewNotification',
  async (param) => {
    const response = await api.getViewNotificationDetail(param);

    const {results} = response.data;

    return results;
  },
);

export const franchiseNoticeSlice = createSlice({
  name: 'frNotice',
  initialState,
  reducers: {
    setSelectNotice: (state, action) => {
      state.selectedNotice = action.payload;
    },

    setSvNoticeCount: (state, action) => {
      state.svNoticeCount = action.payload;
    },

    setNoticePosts: (state, action) => {
      state.svNoticeList = action.payload;
    },

    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
  },
});

export const {
  setSelectNotice,
  setSvNoticeCount,
  setNoticePosts,
  setNotifications,
} = franchiseNoticeSlice.actions;

export const selectFranchiseNotice = (state) => state.franchise.notice;

export default franchiseNoticeSlice.reducer;
