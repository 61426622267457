import React from 'react';
import styled from 'styled-components';

const TextAreaWrapper = styled.textarea`
  width: ${({width}) => width};
  height: ${({height}) => height};
  margin: auto 0;
  background-color: #f8f8f8;
  border: none;
  border-radius: 10px;
  resize: none;
  padding: 10px;
`;

const TextArea = ({
  value,
  width,
  placeholder,
  onChange,
  fontSize,
  padding,
  rows,
  cols,
  height,
  ...otherProps
}) => {
  return (
    <TextAreaWrapper
      value={value}
      width={width}
      placeholder={placeholder}
      onChange={onChange}
      fontSize={fontSize}
      padding={padding}
      rows={rows}
      cols={cols}
      height={height}
      {...otherProps}
    />
  );
};

export default TextArea;
