import React, {useEffect, useCallback, useRef, useState} from 'react';
import {
  Container as MapDiv,
  NavermapsProvider,
  NaverMap,
  Marker,
} from 'react-naver-maps';
import styled from 'styled-components';
import _ from 'lodash';

import {useSelector, useDispatch} from 'react-redux';
import CustomMarker from './CustomMarker';
import {
  setMapBounds,
  setSelectedMarker,
  selectMap,
  getStoresInMapAsync,
  setAlls,
} from 'shared/utils/redux/mapSlice';
import MyLocationIcon from '@mui/icons-material/MyLocation';

const MapContainer = styled(MapDiv)`
  width: 100vw;
  height: ${(props) =>
    props.listopen || props.selectedmarker ? '50vh' : '50vh'};
  transition: height 0.3s ease;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px 15px;
`;
const MapButton = styled.button`
  width: 40px;
  height: 40px;
  line-height: 0px;
  border-radius: 50%;
  border: 0px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  &:hover {
    cursor: pointer;
  }
`;
const NaverMapAPI = ({
  zoom,
  Latitude,
  Longtitude,
  roadAddress,
  alls,
  mylat,
  mylng,
  buttonClick,
  listOpen,
  setListOpen,
  setZoom,
}) => {
  const [myZoom, setMyZoom] = useState(zoom);
  const [center, setCenter] = useState({lat: Latitude, lng: Longtitude});
  const dispatch = useDispatch();
  const {selectedMarker, mapBounds} = useSelector(selectMap);
  const zoomRef = useRef(zoom);

  const debouncedBoundsChanged = useCallback(
    _.debounce((bounds, zoom) => {
      const data = {
        max_latitude: bounds._max._lat,
        max_longitude: bounds._max._lng,
        min_latitude: bounds._min._lat - 0.0015,
        min_longitude: bounds._min._lng - 0.0001,
      };

      if (zoom > 5 && listOpen === false) {
        dispatch(getStoresInMapAsync(data))
          .unwrap()
          .then((payload) => {
            dispatch(setAlls(payload.results));
          });
      }
    }, 200),
    [listOpen, dispatch],
  );

  useEffect(() => {
    setMyZoom(zoom);
    setCenter({x: Longtitude, y: Latitude});
    return () => {
      debouncedBoundsChanged.cancel(); // cleanup function on unmount
    };
  }, [Latitude, Longtitude, zoom, debouncedBoundsChanged]);

  useEffect(() => {
    zoomRef.current = myZoom;
    setZoom(myZoom);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myZoom]);

  useEffect(() => {
    if (listOpen === false && mapBounds) {
      debouncedBoundsChanged(mapBounds, zoomRef.current);
    }
  }, [listOpen, mapBounds, debouncedBoundsChanged]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCenter({lat: Latitude + 0.000001, lng: Longtitude + 0.00000001});
    }, 400); // 500ms 는 0.5초입니다.

    // Cleanup function을 사용하여 타이머를 취소하면 컴포넌트가 언마운트되기 전에 타이머가 실행되지 않도록 할 수 있습니다.
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <NavermapsProvider
        ncpClientId={import.meta.env.VITE_REACT_APP_API_Client_ID}
        submodules={['geocoder']}
      >
        <MapContainer
          selectedmarker={selectedMarker}
          listopen={listOpen}
          style={{width: '100vw'}}
        >
          <ButtonWrapper>
            <MapButton
              onClick={() => {
                setCenter({x: mylng, y: mylat});
              }}
            >
              <MyLocationIcon />
            </MapButton>
          </ButtonWrapper>
          <NaverMap
            draggable={false}
            disableKineticPan={false}
            keyboardShortcuts={false}
            pinchZoom={false}
            scrollWheel={false}
            scaleControl={false}
            center={center}
            zoom={16}
            onZoomChanged={(newZoom) => {
              setMyZoom(newZoom);
              zoomRef.current = newZoom; // zoom 값을 ref로 업데이트
              debouncedBoundsChanged(null, newZoom);
            }}
            onBoundsChanged={(bounds) => {
              dispatch(setMapBounds(bounds));
              debouncedBoundsChanged(bounds, zoomRef.current);
            }}
            onCenterChanged={setCenter}
          >
            <Marker position={{lat: mylat, lng: mylng}} title={roadAddress} />
            {alls?.length > 0 &&
              alls.map((item, index) => {
                return (
                  <CustomMarker
                    listOpen={listOpen}
                    key={index}
                    item={item}
                    index={index}
                    myZoom={myZoom}
                    selectedMarker={selectedMarker}
                    setSelectedMarker={setSelectedMarker}
                    buttonClick={buttonClick}
                  />
                );
              })}
          </NaverMap>
        </MapContainer>
      </NavermapsProvider>
    </>
  );
};

export default NaverMapAPI;
