import React from 'react';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import {ImageCarousel, MuiModal, Text} from 'shared/components/index';
import {useSelector} from 'react-redux';
import {
  selectFranchiseSupplemention,
  setSelectedImages,
} from 'franchise/utils/slices/frSupplementionSlice';

const Container = styled.div`
  background-color: white;
  width: 800px;
  height: 700px;
  padding: 30px 20px;
  align-items: center;
  overflow: scroll;
  & > * {
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const BtnWrapper = styled.button`
  border: none;
  background-color: white;
  margin-left: 20px;
  cursor: pointer;
`;

const RealTimeSupplementationImageModal = ({imageModal, setImageModal}) => {
  const {selectedImagesForDashBoard} = useSelector(
    selectFranchiseSupplemention,
  );

  const handleModalClose = () => {
    setImageModal(false);
  };

  return (
    <MuiModal open={imageModal} onClose={handleModalClose}>
      <Container>
        <TitleWrapper>
          <Text bold fontSize="lg">
            {selectedImagesForDashBoard?.sup_title}
          </Text>

          <BtnWrapper onClick={handleModalClose}>
            <CloseIcon />
          </BtnWrapper>
        </TitleWrapper>

        {selectedImagesForDashBoard?.image_list?.length > 0 && (
          <ImageCarousel
            urlType="image_url"
            images={selectedImagesForDashBoard?.image_list}
            disable
          />
        )}

        <div style={{display: 'flex', flexDirection: 'column'}}>
          {selectedImagesForDashBoard?.completed_on !== null && (
            <Text fontSize="sm">
              {new Date(
                selectedImagesForDashBoard?.completed_on,
              ).toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </Text>
          )}
        </div>
      </Container>
    </MuiModal>
  );
};
export default RealTimeSupplementationImageModal;
