/* eslint-disable camelcase */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {Text} from 'shared/components/index';
import theme from 'shared/styles/theme';

const Table = ({matrixTableData, matrixLoading}) => {
  if (matrixLoading) {
    return (
      <div
        style={{
          width: '100%',
          height: '60px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (!matrixTableData) {
    return null;
  }

  const {category_namelist, score_name_list, table_matrix} = matrixTableData;

  // SUN 2
  const rows = score_name_list.map((scoreName, index) => {
    const rowValues = table_matrix.map((value) => value[index]);
    return [scoreName, ...rowValues];
  });

  return (
    <table style={{marginLeft: '15px'}}>
      <thead>
        <tr style={{margin: '5px'}}>
          <th></th>
          {/* <th style={{ fontSize: '18px', paddingBottom: '7px', paddingTop: '7px' }}>총계: {totalScore}</th> */}
          {category_namelist.map((categoryName, index) => (
            <th
              style={{
                paddingBottom: '7px',
                paddingTop: '7px',
                marginLeft: '50px',
                fontSize: '13px',
              }}
              key={index.toString()}
            >
              <p style={{marginLeft: '0px'}}>{categoryName}</p>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <tr key={index.toString()}>
            {row.map((value, indx) => {
              return (
                <td
                  style={{
                    width: '100px',
                    padding: '4px',
                    borderBottom: indx === 0 ? '' : '1px solid #d5d5d5',

                    // borderLeft: '1px solid #d5d5d5',
                    fontSize: '13px',
                  }}
                  key={indx.toString()}
                >
                  <Text
                    style={{
                      width: '100%',

                      textAlign: 'center',
                      color: indx === 0 && theme.colors.kalsPurple,
                    }}
                  >
                    {value === '소계' ? '합계' : value}
                  </Text>
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
