import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const StyledSvg = styled.svg`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : null)};
`;

const PinIcon = ({ length, stroke, onClick }) => {
  return (
    <StyledSvg
      onClick={onClick}
      width={length}
      height={length}
      aria-hidden="true"
      focusable="false"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      strokeWidth="2"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <line x1="4" y1="20" x2="9.5" y2="14.5" />
      <path d="M6 11l7 7l1 -1l1 -4l4 -4m-4 -4l-4 4l-4 1l-1 1" />
      <line x1="14" y1="4" x2="20" y2="10" />
    </StyledSvg>
  );
};

PinIcon.propTypes = {
  length: PropTypes.number,
  stroke: PropTypes.string,
  onClick: PropTypes.func,
};

PinIcon.defaultProps = {
  length: 24,
  stroke: 'currentColor',
  onClick: null,
};

export default PinIcon;
