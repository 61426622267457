/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/button-has-type */
/* eslint-disable dot-notation */
/* eslint-disable no-constant-condition */
/* eslint-disable react/prop-types */
/* eslint-disable no-lone-blocks */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/require-default-props */
import React, { useEffect } from 'react';
import styled, {css} from 'styled-components';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import {
  TableWrapper,
  TableContent,
  TableDataGroup,
  TableData,
  Button,
  FranchiseWrapper,
} from 'shared/components/index';

import ViewQscDetailModal from './ViewQSCDetailModal';
import ViewQscImageModal from './ViewQSCImageModal';
import theme from 'shared/styles/theme';

const Container = styled.div`
  width: 1300px;
  margin: 15px auto;
  margin-top: 130px;
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin-right: 5px;
  white-space: nowrap;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-content: start;
  grid-gap: 15px;
  width: 1280px;
`;

const CheckListHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${() => theme.colors.moodyBlue};
  margin: 0 0 10px 0;
  color: ${() => theme.colors.white};
`;

const CategoryName = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  min-height: 50px;
  font-weight: ${({header}) => (header ? 'bold' : '500')};
`;

const CheckListContent = styled.div`
  border-right: ${({header}) => `1px solid ${header ? 'white' : '#d5d5d4'}`};
  border-left: ${({header}) => `1px solid ${header ? 'white' : '#d5d5d4'}`};
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({header}) => (header ? '45%' : '82%')};
  min-height: 50px;
  font-weight: ${({header}) => (header ? 'bold' : '500')};
`;

const Score = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({header}) => (header ? '10%' : '18%')};
  min-height: 50px;
  font-weight: ${({header}) => (header ? 'bold' : '500')};
`;

const CheckList = styled.div`
  width: 100%;
  border: 1px solid #d5d5d5;
  display: flex;
  align-items: center;
  align-self: start;
`;

const CheckListItemWrap = styled.div`
  width: 55%;
`;

const CheckListItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #d5d5d5;

  &:hover {
    ${({onClick}) =>
      onClick &&
      css`
        & > * {
          cursor: pointer;
        }
        & a {
          font-weight: bold;
          text-decoration: underline;
        }
        border: ${({border}) => (border ? '1px solid #d5d5d5' : 'none')};
        background-color: ${theme.colors.kalsPurple};
      `}
  }
`;

const QSCCheckLists = ({qscCheckLists, handleCheckListClick, scoreTitleList}) => {
  const setScore = (num) => {
    const score = scoreTitleList.find((score, index) => index === num);
    return score;
  };
  return (
    <>
      <Grid>
        <CheckListHeader>
          <CategoryName header>카테고리명</CategoryName>
          <CheckListContent header>항목명</CheckListContent>
          <Score header>점수</Score>
        </CheckListHeader>

        <CheckListHeader>
          <CategoryName header>카테고리명</CategoryName>
          <CheckListContent header>항목명</CheckListContent>
          <Score header>점수</Score>
        </CheckListHeader>
      </Grid>

      <Grid>
        {qscCheckLists.map((_checkList, index) => (
          <CheckList key={index.toString()}>
            <CategoryName>{_checkList.name}</CategoryName>

            <CheckListItemWrap>
              {_checkList?.checklist?.map((_detail, indx) => (
                <CheckListItem
                  key={indx.toString()}
                  border
                  onClick={() =>
                    handleCheckListClick(_detail, _checkList.name)
                  }>
                  <CheckListContent>{_detail.name}</CheckListContent>
                  <Score>{setScore(_detail.index)}</Score>
                </CheckListItem>
              ))}
            </CheckListItemWrap>
          </CheckList>
        ))}
      </Grid>
    </>
  );
};

const QSCDetailTemplate = ({
  handleListBtnClick,
  handleCheckListClick,
  qscCheckLists,
  scoreTitleList,
  handleViewPdf,
}) => {
  return (
    <FranchiseWrapper>
      <Container>
        {/* Header actions  */}
        <TableDataGroup justifyContent="space-between">
          <TableData>
            <Button size="sm" color="#6650c0" onClick={handleListBtnClick}>
              <ButtonContent>
                <ChevronLeftIcon />
                이전 화면
              </ButtonContent>
            </Button>
          </TableData>

          {/* <TableData>
            <Button size="sm" color="green" onClick={handleViewPdf}>
              View
            </Button>
          </TableData> */}

          <TableData>
            <ButtonContent>
              <Button size="sm" color="green" onClick={handleViewPdf}>
                PDF 다운로드
              </Button>
            </ButtonContent>
          </TableData>
        </TableDataGroup>

        <TableWrapper>
          <TableContent>
            <QSCCheckLists
              handleCheckListClick={handleCheckListClick}
              qscCheckLists={qscCheckLists}
              scoreTitleList = {scoreTitleList}
            />
          </TableContent>
        </TableWrapper>

        <ViewQscDetailModal 
          scoreTitleList = {scoreTitleList} 
        />
        <ViewQscImageModal />
      </Container>
    </FranchiseWrapper>
  );
};

export default QSCDetailTemplate;
