import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {Button} from 'shared/components/index';
import TextField from '@mui/material/TextField';
import {useTranslation} from 'react-i18next';
import {MuiModal} from 'shared/components/index';

// Style for the modal content
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

function AddChatRoomNameModal({
  addChatRoomNameModal,
  setAddChatRoomNameModal,
  handleCreateChatRoom,
  placeHolderNames,
}) {
  const {t} = useTranslation();

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    handleCreateChatRoom({name: formData.get('name')});
  };

  return (
    <MuiModal
      open={addChatRoomNameModal}
      onClose={() => setAddChatRoomNameModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{mb: 2}}
        >
          {t('screens.chatting.add_chatroom_name')}
        </Typography>

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            name="name"
            placeholder={placeHolderNames.join(', ')}
            autoFocus
            sx={{border: '1px solid #ccc'}}
            inputProps={{maxLength: 50}}
          />

          <Box sx={{mt: 2, display: 'flex', justifyContent: 'flex-end'}}>
            <Button
              style={{
                backgroundColor: 'grey',
                border: 'none',
              }}
              onClick={() => setAddChatRoomNameModal(false)}
              sx={{mr: 1}}
            >
              {t('buttons.cancel')}
            </Button>

            <Button type="submit" variant="contained" color="primary">
              {t('buttons.confirm')}
            </Button>
          </Box>
        </Box>
      </Box>
    </MuiModal>
  );
}

export default AddChatRoomNameModal;
