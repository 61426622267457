import React, {useState} from 'react';
import styled, {css} from 'styled-components';
import {Formik} from 'formik';
import {getFormattedTime2} from 'shared/utils/time';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import {Alert} from '@mui/material';
import theme from 'shared/styles/theme';
import {
  ImageSelector,
  MuiButton,
  MuiRadio,
  Text,
  CheckBox,
} from 'shared/components/index';
import {useSelector} from 'react-redux';
import {selectTask} from 'owler/utils/slices/taskSlice';
import Button from '@mui/material/Button';
import {setForTaskDetail} from 'owler/utils/slices/taskSlice';
import {useDispatch} from 'react-redux';

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: ${({container}) => (container ? 'row' : 'column')};
  &:not(:last-child) {
    margin-right: 30px;
  }
  width: ${({width}) => width || '100%'};
  ${({center}) =>
    center &&
    css`
      margin: 0 auto;
    `}
`;
const StyledP = styled.p`
  font-size: 18px;
  font-weight: 500;
`;

const StyledImg = styled.img`
  width: 150px;
  height: 120px;
  margin: 10px;
`;
const Form = styled.form`
  width: 920px;
  min-height: 670px;
  padding: 20px 40px;
  margin: 0 auto 50px auto;
  border: 1px solid ${theme.colors.grey6};
  border-radius: 15px;
  & > * {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    &:first-child {
      margin: 10px 0 70px;
    }
    &:last-child {
      margin-top: 50px;
    }
  }
`;
const ForDelete = styled.input`
  width: 100px;
  margin-left: 35px;
`;

const Field = ({
  formikProps,
  label,
  name,
  placeholder,
  classes,
  ...restProps
}) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        {label}
      </Typography>
      <TextField
        {...restProps}
        name={name}
        value={formikProps.values[name]}
        onChange={formikProps.handleChange}
        error={formikProps.touched[name] && Boolean(formikProps.errors[name])}
        helperText={
          (formikProps.touched[name] && formikProps.errors[name]) || ' '
        }
        variant="filled"
        fullWidth
        placeholder={placeholder}
        className={classes && classes.text}
        InputProps={
          classes && {
            classes: {
              root: classes.text,
              input: classes.textInput,
              multiline: classes.multi,
            },
          }
        }
      />
    </>
  );
};

const SlaveTaskEditForm = ({
  categoryName,
  onSubmit,
  onValidate,
  forTaskDetail,
  handleGoback,
}) => {
  const {selectedTask} = useSelector(selectTask);
  const [showAlert, setShowAlert] = useState(false);
  const [checkout, setCheckOut] = useState(false);
  const [deleteImaged, setDeleteImage] = useState('');

  const dispatch = useDispatch();
  let {
    task_uid,
    name,
    task_type,
    repetition,
    description,
    description_images,
    start,
    end,
  } = selectedTask;

  const deleteImage = (img) => {
    setDeleteImage(img);
    setShowAlert(true);
  };
  const imageDelete = () => {
    //Owen's
    const new2Images = forTaskDetail?.description_images?.filter(
      (img) => img !== deleteImaged,
    );
    dispatch(setForTaskDetail(new2Images));
  };

  // Reverse formatting repetition
  if (repetition === '없음') repetition = 0;
  else if (repetition === '주 1회') repetition = 6;
  else if (repetition === '월 1회') repetition = 3;
  else if (repetition === '일') repetition = 4;
  else if (repetition === '주') {
    repetition = 5; //2
  } else if (repetition === '매일') repetition = 1;
  else repetition = 0;

  // Reverse format time
  const startDate = start;
  const endDate = end;

  const startFormatted = getFormattedTime2(startDate);
  const endFormatted = getFormattedTime2(endDate);

  // Reverse format description
  //if (forTaskDetail?.description === undefined) forTaskDetail.description = '';
  const taskTypeMapping = {
    image: '사진',
    check: '체크',
    memo: '메모',
  };

  const value = {
    task_uid,
    name,
    repetition,
    task_type: taskTypeMapping[task_type],
    start: startFormatted,
    end: endFormatted,
    description,
    notify: forTaskDetail?.is_push,
    description_images,
  };

  return (
    <Formik
      onSubmit={onSubmit}
      validate={onValidate}
      initialValues={{
        name: value?.name,
        start: value?.start,
        end: value?.end,
        repetition: value?.repetition,
        task_type: value?.task_type || '체크',
        description:
          forTaskDetail?.description !== undefined
            ? forTaskDetail?.description
            : '',
        notify: forTaskDetail?.is_push,
        description_images: forTaskDetail?.description_images,
      }}
    >
      {(formikProps) => {
        const realDelete = () => {
          setShowAlert(false);
          setCheckOut(true);
          imageDelete();
          const newImages = formikProps.values.description_images.filter(
            (img) => img !== deleteImaged,
          );
          formikProps.setFieldValue('description_images', newImages);
        };

        return (
          <Form
            onBlur={formikProps.handleBlur}
            onSubmit={formikProps.handleSubmit}
          >
            <Text
              style={{
                width: '100%',
                textAlign: 'center',
                fontSize: '25px',
                fontWeight: '600',
              }}
            >
              업무 편집
            </Text>
            <Typography
              align="left"
              style={{
                fontSize: '20px',
                fontWeight: 'bold',
                margin: '-30px 0 20px 0',
              }}
            >{`카테고리: ${categoryName}`}</Typography>
            <FieldWrapper>
              <Field
                name="name"
                label="업무명"
                placeholder="ex)유통기한 검사하기"
                formikProps={formikProps}
              />
            </FieldWrapper>
            <FieldWrapper container>
              <FieldWrapper width="40%">
                <Field
                  name="start"
                  label="업무 수행 시간"
                  placeholder="ex)13:00"
                  formikProps={formikProps}
                />
                <Text
                  style={{
                    fontSize: '16px',
                    margin: '-10px 0 0 5px',
                  }}
                >
                  부터
                </Text>
                <Field
                  name="end"
                  placeholder="ex)21:00"
                  formikProps={formikProps}
                />
                <Text
                  style={{
                    fontSize: '16px',
                    margin: '-10px 0 0 5px',
                  }}
                >
                  까지
                </Text>
              </FieldWrapper>
              <FieldWrapper width="30%"></FieldWrapper>

              <FieldWrapper width="20%">
                <MuiRadio
                  formLabel="업무 보고 방식"
                  name="task_type"
                  value={formikProps.values.task_type}
                  labels={['체크', '사진', '메모']}
                  handleChange={formikProps.handleChange}
                />
              </FieldWrapper>
            </FieldWrapper>

            <FieldWrapper>
              <Field
                name="description"
                label="업무 설명"
                multiline
                rows={2}
                placeholder="ex) 유통기한 확인 후, 기한이 짧은 순대로 앞부터 진열"
                formikProps={formikProps}
              />
            </FieldWrapper>

            <FieldWrapper>
              <ImageSelector
                name="description_images"
                value={formikProps.values.description_images}
                formikProps={formikProps}
                label="업무 설명 사진"
                onChange={formikProps.handleChange}
              />
            </FieldWrapper>
            <FieldWrapper>
              {forTaskDetail?.description_images &&
              forTaskDetail?.description_images.length > 0 ? (
                <>
                  <StyledP>기존 등록 사진</StyledP>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    {forTaskDetail?.description_images.map((img, indx) => (
                      <div key={indx.toString()}>
                        <StyledImg src={img} />
                        <ForDelete
                          type="button"
                          onClick={() => deleteImage(img)}
                          value="삭제"
                        ></ForDelete>
                      </div>
                    ))}
                  </div>
                </>
              ) : null}
            </FieldWrapper>

            {showAlert && (
              <div>
                <Alert
                  severity="warning"
                  action={
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() => realDelete()}
                    >
                      {' '}
                      삭제하기{' '}
                    </Button>
                  }
                >
                  정말 삭제하시겠습니까
                </Alert>
              </div>
            )}
            {checkout && (
              <div>
                <Alert
                  onClose={() => {
                    setCheckOut(false);
                  }}
                >
                  삭제되었습니다.
                </Alert>
              </div>
            )}

            <FieldWrapper>
              <CheckBox
                label="업무 완료시 알림 받기"
                name="notify"
                checked={formikProps.values.notify}
                style={{transform: 'scale(1.5)'}}
                onChange={formikProps.handleChange}
              />
            </FieldWrapper>

            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <div></div>
              <div>
                <MuiButton
                  id="taskAdd-submit"
                  disabled={
                    formikProps.values.name === '' || !formikProps.isValid
                  }
                  text="취소"
                  variant="contained"
                  backgroundColor="#bdbdbd"
                  textcolor={theme.colors.white}
                  style={{margin: '0 10px 0 0px'}}
                  onClick={() => handleGoback()}
                />
                <MuiButton
                  id="taskAdd-submit"
                  disabled={
                    formikProps.values.name === '' || !formikProps.isValid
                  }
                  text="수정"
                  type="submit"
                  variant="contained"
                  backgroundColor={theme.colors.kalsPurple}
                  textcolor={theme.colors.white}
                />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SlaveTaskEditForm;
