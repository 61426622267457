import React from 'react';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import {MuiModal} from 'shared/components/index';
import {useDispatch, useSelector} from 'react-redux';
import {selectTask, setImageDetailModal} from 'owler/utils/slices/taskSlice';

const Container = styled.div`
  background-color: white;
  width: 600px;
  height: 600px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scrollbar-width: thin;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #ffffff;
  }
  ::-webkit-scrollbar-thumb {
    background: #000;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const Label = styled.label`
  font-size: 20px;
  font-weight: 600;
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: px;
`;

const BtnWrapper = styled.button`
  border: none;
  background-color: white;
  margin-left: 20px;
  cursor: pointer;
`;

const Image = styled.img`
  width: auto;
  height: 250px;
  margin: 10px 0;
`;

const ImageDetailModal = ({imageDetail}) => {
  const dispatch = useDispatch();
  const {imageDetailModal} = useSelector(selectTask);

  const handleModalClose = () => {
    dispatch(setImageDetailModal(false));
  };
  return (
    <MuiModal open={imageDetailModal} onClose={handleModalClose}>
      <Container>
        <TitleWrapper>
          <BtnWrapper onClick={handleModalClose}>
            <CloseIcon />
          </BtnWrapper>
        </TitleWrapper>

        <Label htmlFor="images">업무 보고 사진</Label>
        {imageDetail?.images?.map((image) => (
          <Image src={image} alt="image" />
        ))}
      </Container>
    </MuiModal>
  );
};
export default ImageDetailModal;
