/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';
import {Text, View, StyleSheet, Font} from '@react-pdf/renderer';
import theme from 'shared/styles/theme';

Font.register({
  family: 'Nanum Gothic',
  src: 'https://fonts.gstatic.com/ea/nanumgothic/v5/NanumGothic-ExtraBold.ttf',
});

const styles = StyleSheet.create({
  wrap: {
    marginTop: 5,
  },
  table: {
    display: 'table',
    width: '100%',
    borderCollapse: 'collapse',
  },
  headerTableRow: {
    flexDirection: 'row',
    borderBottom: 0,
  },
  tableRow: {
    flexDirection: 'row',
    border: 0.5,
    borderBottom: 0,
    borderColor: theme.colors.notBlack,
  },
  tableCell: {
    width: '10%',
    padding: 5,
    border: 0.5,
    borderBottom: 0,
    borderLeft: 0,
    borderColor: theme.colors.notBlack,
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  lastTableCell: {
    width: '40%',
    padding: 5,
    textAlign: 'left',
    verticalAlign: 'middle',
  },
  text: {
    verticalAlign: 'middle',
    fontSize: 9,
    fontFamily: 'Nanum Gothic',
    color: theme.colors.notBlack,
    fontWeight: 'thin',
  },
  descriptionCell: {
    width: '15%',
    padding: 5,
    borderRightWidth: 0.5,
    borderColor: theme.colors.notBlack,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerDynamicCell: {
    width: '85%',
  },
  dynamicCell: {
    width: '85%',
    minHeight: 30,

    borderColor: theme.colors.notBlack,
  },
  itemCell: {
    borderColor: theme.colors.notBlack,
    padding: 5,
    marginBottom: 2,
  },
});

const QscCategoryListTable = ({categoryList, scoreTitleList}) => {
  const splitTextByLength = (str, length) => {
    const chunks = [];
    let startIndex = 0;

    while (startIndex < str?.length) {
      chunks.push(str?.substr(startIndex, length));
      startIndex += length;
    }

    return chunks.join('\n');
  };
  return (
    <View style={styles.wrap}>
      <View style={styles.table}>
        <View
          style={[
            styles.tableRow,
            {backgroundColor: theme.colors.secondaryLight},
          ]}
        >
          <View style={styles.descriptionCell}>
            <Text style={styles.text}>대분류</Text>
          </View>

          <View style={styles.headerDynamicCell}>
            <View
              style={[styles.headerTableRow, {borderTop: 0}, {borderBottom: 0}]}
            >
              <View style={[styles.tableCell, {width: '30%'}]}>
                <Text style={styles.text}>점검</Text>
              </View>

              {scoreTitleList?.map((scoreTitle, _indx) => (
                <View key={_indx.toString()} style={[styles.tableCell]}>
                  <Text style={styles.text}>{scoreTitle}</Text>
                </View>
              ))}

              <View
                style={[
                  styles.tableCell,
                  styles.lastTableCell,
                  {textAlign: 'center'},
                ]}
              >
                <Text style={styles.text}>비고</Text>
              </View>
            </View>
          </View>
        </View>
      </View>

      <View>
        {categoryList?.map((category, index) => (
          <View key={index.toString()} style={[styles.table]}>
            <View
              style={[
                styles.tableRow,
                index === categoryList.length - 1 && {borderBottom: 0.5},
              ]}
            >
              <View style={styles.descriptionCell}>
                <Text style={styles.text}>{category?.name}</Text>
              </View>

              <View style={[styles.dynamicCell, {border: 0}]}>
                {category.checklist?.map((item, indx) => (
                  <View
                    key={indx.toString()}
                    style={[styles.tableRow, {borderTop: 0}, {borderLeft: 0}]}
                  >
                    <View style={[styles.tableCell, {width: '30%'}]}>
                      <Text style={styles.text}>{item?.name}</Text>
                    </View>

                    {scoreTitleList?.map((_score, indx1) => (
                      <View key={indx1.toString()} style={styles.tableCell}>
                        <Text style={styles.text}>
                          {scoreTitleList[item?.index] === _score && 'ㅇ'}
                        </Text>
                      </View>
                    ))}

                    <View
                      style={[
                        styles.tableCell,
                        styles.lastTableCell,
                        {borderRight: 0},
                        {width: '40%'},
                      ]}
                    >
                      <Text style={styles.text}>{item?.note}</Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
};

export default QscCategoryListTable;
