import React from 'react';
import styled from 'styled-components';

import {ReactComponent as Img404} from 'shared/assets/svgs/404_drawing.svg';
import {SimpleButton, Text} from 'shared/components/index';

const Container = styled.div`
  margin: 0 auto;
  display: flex;
  width: 1100px;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

const TextContainer = styled.div`
  flex-basis: 40%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 40px;
`;

const ImgContainer = styled.div`
  flex-basis: 50%;
`;

const ButtonContainer = styled.div`
  margin-top: 70px;
  display: flex;
  width: 100%;
`;

const Template = ({handleMainBtnClick, handleBackBtnClick}) => {
  return (
    <Container>
      <TextContainer>
        <Text fontSize="massive" bold>
          404 Not Found
        </Text>
        <Text fontSize="huge" bold>
          페이지를 찾을 수 없습니다.
        </Text>

        <ButtonContainer>
          <SimpleButton text="메인으로" onClick={handleMainBtnClick} />

          <div style={{width: '20px'}} />

          <SimpleButton text="이전으로" outlined onClick={handleBackBtnClick} />
        </ButtonContainer>
      </TextContainer>
      <ImgContainer>
        <Img404 />
      </ImgContainer>
    </Container>
  );
};

export default Template;
