import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import NotificationsTemplate from './template/index';
import {selectFranchiseUser} from 'franchise/utils/slices/frUserSlice';
import {
  getSvNoticeDetailAsync,
  getSvNotificationsThunk,
  getViewNotificationDetailThunk,
  setSelectNotice,
} from 'franchise/utils/slices/frNoticeSlice';
import {setQSCUID} from 'franchise/utils/slices/frQscSlice';

const Notifications = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {supervisorData} = useSelector(selectFranchiseUser);

  const [currentPage, setCurrentPage] = useState(1);
  const [notiPageCount, setNotiPageCount] = useState(null);

  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    if (supervisorData !== null) {
      setLoading(true);

      dispatch(
        getSvNotificationsThunk({
          sv_user_uid: supervisorData,
          page: currentPage,
        }),
      )
        .unwrap()
        .then((payload) => {
          setNotiPageCount(payload?.count);
          setNotifications(payload.results);

          setLoading(false);
        })
        .catch((err) => alert(err.message));
    }
  }, [dispatch, supervisorData, currentPage]);

  const count = () => {
    return notiPageCount !== null ? Math.ceil(notiPageCount / 10) : 1;
  };

  const handlePaginationChange = (e, p) => {
    setCurrentPage(p);
  };

  const handleNoticeClick = useCallback(
    async (_notif) => {
      if (_notif.type === 'notice') {
        dispatch(getSvNoticeDetailAsync({noticeUID: _notif?.noticeUID}))
          .unwrap()
          .then((payload) => {
            dispatch(setSelectNotice(payload));
            navigate('/franchise/dashboard');
          });
      } else if (_notif.type === 'newQSC' || _notif.type === 'finishQSC') {
        // navigate(routes.CL_MAIN_STORE_QSC_SCREEN);

        dispatch(setQSCUID(_notif?.qscUID));

        // dispatch(getQSCChecklistThunk({qscUID: _notif?.qscUID}));

        // dispatch(getQSCNotesThunk({qscUID: _notif?.qscUID}));

        // dispatch(getQSCHistoryThunk({qscUID: _notif?.qscUID}));
      } else if (_notif.type === 'newMeeting') {
        //
        // dispatch(getScheduleRequestThunk({userUID: _notif?.userUID}))
        //   .unwrap()
        //   .then(() => {
        //     navigation.navigate(routes.SV_MEETING_SCHEDULES);
        //   });
      }

      //
      dispatch(
        getViewNotificationDetailThunk({
          sv_notification_uid: _notif.sv_notification_uid,
        }),
      )
        .unwrap()
        .then(() => {
          dispatch(
            getSvNotificationsThunk({
              sv_user_uid: supervisorData,
              page: 1,
            }),
          )
            .unwrap()
            .then((payload) => {
              setNotiPageCount(payload?.count);
              setNotifications(payload?.results);
            })
            .catch((err) => alert(err.message));
        });
    },
    [dispatch, navigate, supervisorData?.userUID],
  );

  return (
    <NotificationsTemplate
      loading={loading}
      notifications={notifications}
      handleNoticeClick={handleNoticeClick}
      count={count}
      handlePaginationChange={handlePaginationChange}
    />
  );
};

export default Notifications;
