import React, {useCallback, useEffect, useState} from 'react';

import PaymentManagementTemplate from './template/index';
import {useDispatch, useSelector} from 'react-redux';

import {
  getSupervisorListAsync,
  selectFranchiseUser,
  setChangePaymentModal,
  setSelectedSvUserPosition,
  changeSvPositionsAsync,
} from 'franchise/utils/slices/frUserSlice';
import {
  getFranchisePositionsAsync,
  postFranchisePositionAsync,
  patchFranchisePositionAsync,
  deleteFranchisePositionAsync,
  selectFranchisePosition,
  getSupervisorPositionListAsync,
} from 'franchise/utils/slices/frPositionSlice';

const PaymentManagement = () => {
  const dispatch = useDispatch();
  const {supervisorList, supervisorData} = useSelector(selectFranchiseUser);
  const {positionLists, onlyPositionLists} = useSelector(
    selectFranchisePosition,
  );
  const [selectedSvMemeber, setSelectedSvMember] = useState(null);

  useEffect(() => {
    dispatch(getSupervisorListAsync());
    dispatch(getFranchisePositionsAsync(supervisorData));
    dispatch(getSupervisorPositionListAsync());

    return () => {
      dispatch(setChangePaymentModal(false));
    };
  }, [dispatch, supervisorData]);

  const handleSvSelect = useCallback(
    (_user) => {
      dispatch(setSelectedSvUserPosition(_user));
      dispatch(setChangePaymentModal(true));
      setSelectedSvMember(_user);
    },
    [dispatch],
  );

  const patchArray = (_array, _names) => {
    dispatch(
      patchFranchisePositionAsync(
        _array.map((each, index) => {
          return {
            franchise_position_uid: each.franchise_position_uid,
            position_name: _names[index],
          };
        }),
      ),
    )
      .unwrap()
      .then(() => {
        dispatch(getFranchisePositionsAsync(supervisorData));
        dispatch(getSupervisorListAsync());
        dispatch(getSupervisorPositionListAsync());
      });
  };

  const postArray = (_array) => {
    const changeArray = _array.slice(supervisorList.length);

    dispatch(
      postFranchisePositionAsync(
        changeArray.map((each) => {
          return {position_name: each};
        }),
      ),
    )
      .unwrap()
      .then(() => {
        dispatch(getFranchisePositionsAsync(supervisorData));
        dispatch(getSupervisorListAsync());
        dispatch(getSupervisorPositionListAsync());
      });
  };
  const deleteArray = (_array) => {
    dispatch(
      deleteFranchisePositionAsync(
        _array.map((each) => {
          return {franchise_position_uid: each.franchise_position_uid};
        }),
      ),
    )
      .unwrap()
      .then(() => {
        dispatch(getFranchisePositionsAsync(supervisorData));
        dispatch(getSupervisorListAsync());
        dispatch(getSupervisorPositionListAsync());
      })
      .catch(() => {
        alert('해당 직급에 인원이 존재하여 삭제할 수 없습니다.');
        dispatch(getFranchisePositionsAsync(supervisorData));
      });
  };

  const handleChangeSvPositions = useCallback(
    (position_uid) => {
      dispatch(
        changeSvPositionsAsync({
          position_uid: position_uid,
          sv_user_uid: selectedSvMemeber?.sv_user_uid,
        }),
      )
        .unwrap()
        .then(() => {
          dispatch(getSupervisorListAsync());
        });
    },
    [dispatch, selectedSvMemeber],
  );
  return (
    <PaymentManagementTemplate
      supervisorList={supervisorList}
      handleSvSelect={handleSvSelect}
      positionLists={positionLists}
      patchArray={patchArray}
      postArray={postArray}
      deleteArray={deleteArray}
      onlyPositionLists={onlyPositionLists}
      handleChangeSvPositions={handleChangeSvPositions}
    />
  );
};

export default PaymentManagement;
