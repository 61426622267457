/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';

const buttonSize = {
  XSMALL: 'xs',
  SMALL: 'sm',
  MEDIUM: 'md',
  LARGE: 'lg',
  XLARGE: 'xl',
};

const buttonColor = {
  YELLOW: 'yellow',
  GRAY: 'gray',
  RED: 'red',
};

const StyledButton = styled.button`
  align-items: center;
  justify-content: center;
  margin: 5px;
  margin-left: ${(props) => props.margin || ''};
  ${({fullWidth, size}) => {
    if (!fullWidth) {
      switch (size) {
        case buttonSize.XSMALL:
          return css`
            border-radius: 7px;
            border: none;
            height: 30px;
            line-height: 0px;
            width: 90px;
          `;
        case buttonSize.SMALL:
          return css`
            border-radius: 10px;
            border: none;
            width: 110px;
          `;
        case buttonSize.MEDIUM:
          return css`
            border-radius: 22px;
            border: none;
            width: 150px;
          `;
        case buttonSize.LARGE:
          return css`
            border-radius: 24px;

            border: none;
            width: 200px;
          `;
        case buttonSize.XLARGE:
          return css`
            border-radius: 40px;
            border: none;
            width: 330px;
          `;
        default:
          return css`
            border-radius: 10px;
            width: 80px;
          `;
      }
    }
    return css`
      width: 100%;
    `;
  }}
  ${({color}) => {
    switch (color) {
      case buttonColor.YELLOW:
        return css`
          background-color: #f1c868;
          color: #ffffff;
          border: none;
          &:hover {
            background-color: #e0bf74;
          }
        `;
      case buttonColor.GRAY:
        return css`
          background-color: #d5d5d5;
          color: #ffffff;
          border: none;
          &:hover {
            background-color: #bababa;
          }
        `;
      case buttonColor.RED:
        return css`
          background-color: #fa5858;
          color: #ffffff;
          border: none;
          &:hover {
            background-color: #fe2e2e;
          }
        `;
      default:
        return css`
          background-color: #8773d6;
          color: #ffffff;
          border: none;
          &:hover {
            background-color: #6650c0;
          }
        `;
    }
  }}
  padding: 5px 10px;
  cursor: pointer;
`;

const Button = (props) => {
  const {children, onClick, size, fullWidth, color, margin, ...rest} = props;

  return (
    <StyledButton
      fullWidth={fullWidth}
      size={size}
      color={color}
      onClick={onClick}
      margin={margin}
      {...rest}
    >
      {children}
    </StyledButton>
  );
};

Button.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  size: PropTypes.string,
  color: PropTypes.string,
  fullWidth: PropTypes.bool,
};

Button.defaultProps = {
  fullWidth: false,
  size: 'base',
  color: 'default',
  onClick: null,
};

export default Button;
