import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled, {createGlobalStyle} from 'styled-components';
import {MuiModal, Text} from 'shared/components/index';
import CloseIcon from '@mui/icons-material/Close';
import {
  selectCalendar,
  setCategoryAddModal,
  addCategorys,
} from 'owler/utils/slices/calendarSlice';
import {CirclePicker} from 'react-color';
import {useForm, Controller} from 'react-hook-form';
import toast from 'react-hot-toast';
import theme from 'shared/styles/theme';

const Container = styled.div`
  background-color: white;
  width: 220px;
  max-height: 550px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0px 3px 3px 3px #6e6e6e;
`;
const BtnWrapper = styled.button`
  border: none;
  background-color: white;
  cursor: pointer;
  position: relative;
  top: 0px;
  left: 5px;
`;
const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
`;
const GlobalStyle = createGlobalStyle`
  .circle-picker * {
    border-radius: 25%!important;
  }
`;

const Color = styled(CirclePicker)`
  background-color: white;
  padding: 15px 0 0 17px;
  border-radius: 20px;
`;

const ColorSelectModal = ({setColor, color}) => {
  const {categoryAddModal} = useSelector(selectCalendar);
  const dispatch = useDispatch();

  const handleModalClose = () => {
    dispatch(setCategoryAddModal(false));
  };

  return (
    <MuiModal open={categoryAddModal} onClose={handleModalClose}>
      <Container>
        <GlobalStyle />
        <TitleWrapper>
          <Text bold margin="0 0 0 10px">
            컬러
          </Text>
          <BtnWrapper onClick={handleModalClose}>
            <CloseIcon />
          </BtnWrapper>
        </TitleWrapper>

        <Color
          width="200px"
          color={color}
          onChangeComplete={color => {
            setColor(color.hex);
          }}
          colors={[
            '#f44336',
            '#e91e63',
            '#9c27b0',
            '#673ab7',
            '#3f51b5',
            '#2196f3',
            '#03a9f4',
            '#00bcd4',
            '#009688',
            '#4caf50',
            '#8bc34a',
            '#cddc39',
            '#ffeb3b',
            '#ffc107',
            '#ff9800',
            '#ff5722',
            '#795548',
            '#607d8b',
            `${theme.colors.kalsBlue}`,
            `${theme.colors.kalsPurple}`,
          ]}
        />
      </Container>
    </MuiModal>
  );
};
export default ColorSelectModal;
