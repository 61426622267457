import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const rowSize = {
  SMALL: 'sm',
};

const RowDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  font-size: 18px;
  cursor: default;
  ${({ size }) => {
    switch (size) {
      case rowSize.SMALL:
        return css`
          // style
        `;
      default:
        return css`
          //style
        `;
    }
  }}
`;
// Modal의 한 행에 나오는 element를 children으로 넘김
const Row = (props) => {
  const { justifyContent, children, size } = props;
  return (
    <>
      <RowDiv justifyContent={justifyContent} size={size}>
        {children}
      </RowDiv>
    </>
  );
};
Row.propTypes = {
  children: PropTypes.string.isRequired,
  justifyContent: PropTypes.string,
  size: PropTypes.string,
};
Row.defaultProps = {
  justifyContent: 'flex-start',
  size: 'base',
};
export default Row;
