import React from 'react';
import {Grid} from '@mui/material';
import styled from 'styled-components';
import ChatRoomImage from './ChatRoomImage';
import theme from 'shared/styles/theme';
import DownloadIcon from '@mui/icons-material/Download';

import ImageRegularIcon from 'shared/assets/icons/ImageRegularIcon';
import AppText from 'shared/components/AppText';

const MessageContainer = styled.div`
  display: flex;
`;

const MessageDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  justify-content: flex-start;
`;

const MemberInfo = styled.div`
  font-size: 0.9rem;
  color: gray;
`;

const MessageBubble = styled.div`
  background-color: ${theme.colors.secondaryLight};
  padding: 8px 12px;
  border-radius: 10px;
  display: inline-block;
  max-width: 80%;
  margin-top: 4px;
`;

const StyledImg = styled.img`
  width: 145px;
  height: 110px;

  object-fit: contain;
  background-color: #bdbdbd;
`;

const MessageMeta = styled.div``;

// const UnreadCount = styled.div`
//   color: ${theme.colors.primary};
//   margin-left: 8px;
//   font-size: 0.9rem;
// `;

const DateTime = styled.div`
  color: gray;
  font-size: 0.75rem;
  margin-left: 8px;
`;

function SenderMessageBox({message, setSelectedMessage, setTaskImageModal}) {
  const {
    message: msg,
    message_type,
    files,
    images,
    created_on,
    member_name,
    position,
  } = message;

  const date = new Date(created_on).toLocaleDateString();
  const time = new Date(created_on).toLocaleTimeString();

  return (
    <Grid item xs={12} style={{display: 'flex', justifyContent: 'flex-start'}}>
      <AppText />

      {message_type === 'message' && (
        <MessageContainer>
          <ChatRoomImage
            chatData={message}
            name="member_name"
            url_type="member_image"
          />

          <MessageDetails>
            <MemberInfo>
              {member_name} / {position}
            </MemberInfo>

            <div
              style={{
                display: 'flex',
              }}
            >
              <MessageBubble>{msg}</MessageBubble>

              <MessageMeta>
                <DateTime>
                  {date} {time}
                </DateTime>

                {/* {chatData.unread_count >= 0 && (
                <UnreadCount>{chatData.unread_count}</UnreadCount>
              )} */}
              </MessageMeta>
            </div>
          </MessageDetails>
        </MessageContainer>
      )}

      {message_type === 'file' && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {files.map((file, index) => (
            <div
              style={{
                width: '50%',
                padding: '10px',
                borderRadius: '10px',
                border: '1px solid #e0e0e0',
                backgroundColor: '#f5f5f5',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: '10px',
              }}
              key={index}
            >
              <a
                href={file.file ? file.file : file.file_uri}
                download
                style={{
                  display: 'block',
                  marginBottom: '10px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  marginRight: '10px',
                }}
              >
                {file.file_name}
              </a>

              <a href={file.file ? file.file : file.file_uri} download>
                <DownloadIcon style={{color: 'grey'}} />
              </a>
            </div>
          ))}
        </div>
      )}

      {message_type === 'image' && (
        <div
          style={{
            borderRadius: '10px',
            overflow: 'hidden',
            boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.1)',
            cursor: 'pointer',
            border: '1px solid #e0e0e0',
            position: 'relative',
          }}
          onClick={() => {
            setSelectedMessage(message);
            setTaskImageModal(true);
          }}
        >
          <StyledImg
            src={typeof images[0] === 'string' ? images[0] : images[0]?.image}
          />
          <div
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              color: 'white',
              width: '100%',
              height: '100%',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <ImageRegularIcon fill="white" length={40} />
          </div>
        </div>
      )}
    </Grid>
  );
}

export default SenderMessageBox;
