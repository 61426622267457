import React from 'react';
import {useEffect} from 'react';
import {Marker} from 'react-naver-maps';
import {useDispatch, useSelector} from 'react-redux';
import {selectMap} from 'shared/utils/redux/mapSlice';
//import styled from 'styled-components';

const CustomMarker = ({
  index,
  item,
  myZoom,
  selectedMarker,
  setSelectedMarker,
  listOpen,
  buttonClick,
}) => {
  const dispatch = useDispatch();
  const {alls} = useSelector(selectMap);

  useEffect(() => {}, [alls]);

  return (
    <Marker
      key={index}
      position={{lat: item.latitude, lng: item.longitude}}
      title={item.store_name}
      clickable={true}
      onClick={() => {
        dispatch(setSelectedMarker(item));
        buttonClick(item.store_uid);
      }}
      icon={{
        content: `<div className="${
          item?.store_uid === selectedMarker?.store_uid ? 'animated-marker' : ''
        }" style="position: relative; width: 48px; height: 48px; z-index: ${
          item?.store_uid === selectedMarker?.store_uid ? '1' : 'auto'
        }; opacity: ${
          listOpen === true || item?.store_uid === selectedMarker?.store_uid
            ? '1'
            : '0.75'
        }" >
        <div style="position: absolute; top: -34px; left: -16px; text-align:center;  transform: scale(${
          item?.store_uid === selectedMarker?.store_uid ? '1.25' : '1'
        }">
          <?xml version="1.0" ?><svg height="38" version="1.1" width="36" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"><g transform="translate(0 -1028.4)"><path d="m12 0c-4.4183 2.3685e-15 -8 3.5817-8 8 0 1.421 0.3816 2.75 1.0312 3.906 0.1079 0.192 0.221 0.381 0.3438 0.563l6.625 11.531 6.625-11.531c0.102-0.151 0.19-0.311 0.281-0.469l0.063-0.094c0.649-1.156 1.031-2.485 1.031-3.906 0-4.4183-3.582-8-8-8zm0 4c2.209 0 4 1.7909 4 4 0 2.209-1.791 4-4 4-2.2091 0-4-1.791-4-4 0-2.2091 1.7909-4 4-4z" fill="${
            item?.store_uid === selectedMarker?.store_uid
              ? '#FE2E2E'
              : '#FF8000'
          }"  stroke="${
          item?.store_uid === selectedMarker?.store_uid ? '#DF0101' : '#DF7401'
        }" stroke-width="1" transform="translate(0 1028.4)"/><path d="m12 3c-2.7614 0-5 2.2386-5 5 0 2.761 2.2386 5 5 5 2.761 0 5-2.239 5-5 0-2.7614-2.239-5-5-5zm0 2c1.657 0 3 1.3431 3 3s-1.343 3-3 3-3-1.3431-3-3 1.343-3 3-3z" fill="${
          item?.store_uid === selectedMarker?.store_uid ? '#FE2E2E' : '#FF8000'
        }" transform="translate(0 1028.4)"/></g></svg>
        </div>
        ${
          myZoom > 12 ||
          item?.store_uid === selectedMarker?.store_uid ||
          listOpen
            ? `<p style="font-size:10px; position: absolute; top: 5px; left: -120px; text-align: center; width: 500%; font-weight: ${
                item?.store_uid === selectedMarker?.store_uid ? '500' : '500'
              }; transform: scale(${
                item?.store_uid === selectedMarker?.store_uid ? '1.3' : '1'
              }">${item.store_name}<p>`
            : ''
        }
      </div>`,
      }}
    />
  );
};

export default CustomMarker;
