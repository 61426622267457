import {Text, FranchiseDrawer, FranchiseWrapper} from 'shared/components/index';

const NoticeTemplate = () => {
  return (
    <FranchiseWrapper titleText="Notice">
      <FranchiseDrawer>
        <Text>Hello Notice!</Text>
      </FranchiseDrawer>
    </FranchiseWrapper>
  );
};

export default NoticeTemplate;
