import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const StyledSvg = styled.svg`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : null)};
`;
const CheckCircleRegular = ({ length, background, fill, point, onClick }) => {
  return (
    <StyledSvg
      onClick={onClick}
      width={length}
      height={length}
      xmlns="http://www.w3.org/2000/svg"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 70 70">
      <defs>
        <linearGradient id="무제_그라디언트_43" x1="35" x2="35" y2="70" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#8773d6" />
          <stop offset="0.36" stopColor="#806ed6" />
          <stop offset="0.87" stopColor="#6d60d6" />
          <stop offset="1" stopColor="#675bd6" />
        </linearGradient>
      </defs>
      <g id="레이어_2" data-name="레이어 2">
        <g id="레이어_1-2" data-name="레이어 1">
          <path
            fill={background}
            className="cls-1"
            d="M28.7,59.15c-5.76-.13-9.57-2.37-9.8-3.64-.08-.49.37-.83,1.44-.85a9.17,9.17,0,0,0,5.87-2s1.89-8.32,7-13.27l3.24-3.24c5-5.13,13.27-7,13.27-7a9.17,9.17,0,0,0,2-5.87c0-1.15.41-1.58.95-1.41,1.27.38,3.38,4.09,3.54,9.65,0,0,0,.1,0,.15a75.15,75.15,0,0,1,13.5,7.77A36.28,36.28,0,0,0,70,35h0A35,35,0,0,0,35,0h0A35,35,0,0,0,0,35H0A35,35,0,0,0,35,70h.19a96.29,96.29,0,0,1-6.46-10.85Z"
          />
          <path
            fill={fill}
            className="cls-2"
            d="M69.71,39.45a75.15,75.15,0,0,0-13.5-7.77c0-.05,0-.1,0-.15C56,26,53.93,22.26,52.66,21.88c-.54-.17-.92.26-.95,1.41a9.17,9.17,0,0,1-2,5.87s-8.32,1.89-13.27,7l-3.24,3.24c-5.13,4.95-7,13.27-7,13.27a9.17,9.17,0,0,1-5.87,2c-1.07,0-1.52.36-1.44.85.23,1.27,4,3.51,9.8,3.64h0A96.29,96.29,0,0,0,35.19,70,35,35,0,0,0,69.71,39.45Z"
          />
          <path
            fill={point}
            className="cls-3"
            d="M51.24,55.3s-1.4,1.85,2.33,3,5.29.63,4.15-2.13-2.11-5-3.68-3.67A32,32,0,0,0,51.24,55.3Z"
          />
        </g>
      </g>
    </StyledSvg>
  );
};

CheckCircleRegular.propTypes = {
  length: PropTypes.number,
  background: PropTypes.string,
  fill: PropTypes.string,
  point: PropTypes.string,
  onClick: PropTypes.func,
};
CheckCircleRegular.defaultProps = {
  length: 20,
  background: '#8773D6',
  fill: '#fcfcfa',
  point: '#8773D6',
  onClick: null,
};
export default CheckCircleRegular;
