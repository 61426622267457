/* eslint-disable react/prop-types */
import React from 'react';
import { useFormikContext } from 'formik';

import AppTextInput from '../AppTextInput';
import ErrorMessage from './ErrorMessage';

function AppFormField({ name, validation = true, ...otherProps }) {
  const { handleChange, errors, setFieldTouched, touched, values } = useFormikContext();
 
  return (
    <>
      <AppTextInput
        value={values[name]}
        onFocus={() => setFieldTouched(name)}
        onChange={handleChange(name)}
        {...otherProps}
      />
      {validation && <ErrorMessage error={errors[name]} visible={touched[name]} />}
    </>
  );
}

export default AppFormField;
