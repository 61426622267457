import {
  FranchiseDrawer,
  FranchiseWrapper,
  TableWrapper,
  TableContent,
  Text,
  Button,
} from 'shared/components/index';
import styled, {css} from 'styled-components';
import theme from 'shared/styles/theme';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 7px;
  width: 1035px;
  margin-left: 80px;
`;

const StoreItem = styled.div`
  border: ${({border}) => (border ? '1px solid #d5d5d5' : 'none')};
  width: 100%;
  padding: 5px 15px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  &:hover {
    ${({onClick}) =>
      onClick &&
      css`
        & > * {
          cursor: pointer;
        }
        & a {
          font-weight: bold;
          text-decoration: underline;
        }
        border: ${({border}) => (border ? '1px solid #d5d5d5' : 'none')};
        background-color: ${theme.colors.purple_light};
      `}
  }
`;

const Header = styled.div`
  width: 1010px;
  display: flex;
  align-items: center;
  margin-left: 80px;
  text-align: center;
  font-size: 15px;
  margin-bottom: 20px;
`;

const QscBtnContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  margin-right: 5px;
  padding: 2px 5px;
`;

const TruncatedText = styled(Text)`
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const QSCHeader = () => {
  return (
    <Header>
      <div style={{width: '520px', textAlign: 'left', padding: '0 0 0 17px'}}>
        매장명 / 주소
      </div>
      <div style={{width: '210px'}}>직가맹 / 점주명</div>
      <div style={{width: '180px'}}>마지막 방문일</div>
    </Header>
  );
};

const QSCStoreList = ({qscStoreList, handleQSCStoreClick, franchiseName}) => {
  const renderOwner = (store) => {
    if (!store.is_direct) {
      return (
        <div
          style={{
            width: '220px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Text style={{color: `${theme.colors.kalsPurple}`}}>가맹점</Text>
          <TruncatedText>{store.owner_name}</TruncatedText>
        </div>
      );
    } else {
      return (
        <div style={{width: '220px', display: 'flex', flexDirection: 'column'}}>
          <Text>직영점</Text>
          <TruncatedText>{store.owner_name}</TruncatedText>
        </div>
      );
    }
  };

  return (
    <Grid>
      {qscStoreList.map((_store, index) => (
        <StoreItem
          border
          key={index.toString()}
          onClick={() => handleQSCStoreClick(_store)}
        >
          <div
            style={{
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '500px',
            }}
          >
            <p
              style={{
                fontSize: '15px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {_store.store_name}
            </p>
            <Text style={{fontSize: '13px'}} color="#bdbdbd">
              {_store.store_address}
            </Text>
          </div>

          <div style={{fontSize: '13px', width: '210px'}}>
            {renderOwner(_store)}
          </div>
          <div
            style={{
              width: '180px',
              textAlign: 'center',
            }}
          >
            <Text style={{fontSize: '13px'}}>
              {_store.last_visit !== null
                ? new Date(_store.last_visit)
                    .toLocaleString('ko-KR', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false,
                    })
                    .replace(',', '')
                : '--'}
            </Text>
          </div>
          <div style={{margin: '0 0 0 25px'}}>
            <Button
              style={{
                fontSize: '13px',
                height: '28px',
                borderRadius: '6px',
                lineHeight: '0px',
              }}
              color="#6650c0"
            >
              <QscBtnContent>QSC</QscBtnContent>
            </Button>
          </div>
        </StoreItem>
      ))}
    </Grid>
  );
};

const QSCStoreListTemplate = ({
  franchiseName,
  handleQSCStoreClick,
  qscStoreList,
}) => {
  return (
    <FranchiseWrapper titleText="매장 목록">
      <FranchiseDrawer>
        <TableWrapper margin="0px">
          <TableContent>
            <div style={{margin: '-40px 0 0 0'}}>
              <QSCHeader />
              <QSCStoreList
                handleQSCStoreClick={handleQSCStoreClick}
                qscStoreList={qscStoreList}
                franchiseName={franchiseName}
              />
            </div>
          </TableContent>
        </TableWrapper>
      </FranchiseDrawer>
    </FranchiseWrapper>
  );
};

export default QSCStoreListTemplate;
