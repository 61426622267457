import React from 'react';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import {MuiModal} from 'shared/components/index';
import {useDispatch, useSelector} from 'react-redux';
import {selectRequest, setImageModal} from 'owler/utils/slices/requestSlice';
const Container = styled.div`
  background-color: white;
  width: 700px;
  height: 560px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 5px;
`;

const BtnWrapper = styled.button`
  border: none;
  background-color: white;
  margin-left: 20px;
  cursor: pointer;
`;

const Image = styled.img`
  height: 500px;
  width: auto;
`;

const ImageDetailModal = ({selectImage}) => {
  const dispatch = useDispatch();
  const {imageModal} = useSelector(selectRequest);
  const handleModalClose = () => {
    dispatch(setImageModal(false));
  };
  return (
    <MuiModal open={imageModal} onClose={handleModalClose}>
      <Container>
        <TitleWrapper>
          <BtnWrapper onClick={handleModalClose}>
            <CloseIcon />
          </BtnWrapper>
        </TitleWrapper>

        <Image src={selectImage} alt="Image" />
      </Container>
    </MuiModal>
  );
};
export default ImageDetailModal;
