/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/require-default-props */
import React from 'react';
import {MuiModal} from 'shared/components/index';
import styled from 'styled-components';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  selectStore,
  setCompeleteByStoreModal,
} from 'owler/utils/slices/storeSlice';
import {useSelector, useDispatch} from 'react-redux';
const Container = styled.div`
  width: 600px;
  max-height: 650px;
  min-height: 500px;
  border-radius: 5px;
  background-color: #fff;
  padding: 5px 25px 5px 25px;
  overflow-y: scroll;
`;

const StyledText = styled.p`
  font-size: ${(props) => (props.size ? props.size : '14px')};
  font-weight: ${(props) => (props.weight ? props.weight : '400')};
  color: ${(props) => (props.color ? props.color : 'black')};
  margin: 12px 0 5px 0;
`;

const StoreListContainer = styled.div`
  width: 98%;
  max-height: 230px;
  min-height: 160px;
  overflow-y: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    scrollbar-width: none;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  max-height: 530px;
  min-height: 379px;
`;

const UserContainer = styled.div`
  width: 100%;
  border-bottom: 0.3px solid #cecece;
  padding: 10px 15px 10px 0;
  user-select: none;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding-left: 10px;
  &:hover {
    background-color: #e5e5e5;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  height: auto;
  text-align: center;
`;

const StyledButton = styled.button`
  font-size: 12px;
  padding: 6px 30px;
  color: #fff;
  background-color: #8773d6;
  border: none;
  border-radius: 20px;
  margin: 5px auto;
  cursor: pointer;
`;

const StyledSpan2 = styled.span``;

const CompleteByStoreModal = React.memo(({checkTasks}) => {
  const {compeleteByStoreModal} = useSelector(selectStore);
  const dispatch = useDispatch();

  const handleModalClose = () => {
    dispatch(setCompeleteByStoreModal(false));
  };

  return (
    <MuiModal open={compeleteByStoreModal} onClose={handleModalClose}>
      <Container>
        <Wrapper>
          <StyledText color="#0ead69" weight="bold" size="16px">
            체크 보고 완수 매장
          </StyledText>
          {checkTasks ? (
            <StoreListContainer>
              {checkTasks?.completed_store_list?.map((store, i) => {
                return (
                  <UserContainer key={i}>
                    <StyledSpan2>{store}</StyledSpan2>
                  </UserContainer>
                );
              })}
            </StoreListContainer>
          ) : (
            <MoreHorizIcon />
          )}

          <StyledText color="#e63946" weight="bold" size="16px">
            체크 보고 미완수 매장
          </StyledText>
          {checkTasks ? (
            <StoreListContainer>
              {checkTasks?.incompleted_store_list?.map((store, i) => {
                return (
                  <UserContainer key={i}>
                    <StyledSpan2>{store}</StyledSpan2>
                  </UserContainer>
                );
              })}
            </StoreListContainer>
          ) : (
            <MoreHorizIcon />
          )}
        </Wrapper>
        <ButtonContainer>
          <StyledButton onClick={handleModalClose}>확인</StyledButton>
        </ButtonContainer>
      </Container>
    </MuiModal>
  );
});

export default CompleteByStoreModal;
