import React from 'react';
import {useCallback, useState, useEffect} from 'react';
import WorkRegisterTemplate from './template/index';
import {
  selectCommute,
  getMemberCommuteLogAsync,
  getStoreMemberListAsync,
  setCommuteLog,
} from 'owler/utils/slices/commuteSlice';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {selectStore} from 'owler/utils/slices/storeSlice';

const WorkRegister = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const {currentStore} = useSelector(selectStore);
  const {commuteLog} = useSelector(selectCommute);
  const [storeMembers, setStoreMembers] = useState([]);

  useEffect(() => {
    dispatch(setCommuteLog([]));
    dispatch(
      getStoreMemberListAsync({
        store_uid: currentStore.store_uid,
      }),
    )
      .then((response) => {
        setStoreMembers(response.payload);
      })
      .catch((error) => {
        console.error('error', error);
      });
  }, [dispatch, currentStore]);

  const search = useCallback(
    (data) => {
      setYear(data.year);
      setMonth(data.month);

      dispatch(
        getMemberCommuteLogAsync({
          year: data.year,
          month: data.month,
          member_uid: data.member_uid,
        }),
      );
    },
    [dispatch],
  );

  const handlePdfButtonClick = useCallback(() => {
    navigate(`/owler/workRegisterViewPdf/${currentStore.store_uid}`, {
      state: {year: year, month: month},
    });
  }, [navigate, year, month, currentStore]);

  return (
    <WorkRegisterTemplate
      storeMemberList={storeMembers}
      commuteLog={commuteLog}
      year={year}
      month={month}
      search={search}
      handlePdfButtonClick={handlePdfButtonClick}
    />
  );
};

export default WorkRegister;
