/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/prop-types */
import React from 'react';
import {Text, View, StyleSheet, Image, Font} from '@react-pdf/renderer';
import theme from 'shared/styles/theme';

Font.register({
  family: 'Nanum Gothic',
  src: 'https://fonts.gstatic.com/ea/nanumgothic/v5/NanumGothic-Regular.ttf',
});

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    padding: 15,
    paddingTop: 15,
  },
  body: {
    display: 'flex',
    width: '100%',
    color: 'black',
    border: 1,
    borderBottom: 0,
    minHeight: '24px',
    textAlign: 'center',
    fontSize: 10,
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#dfd7f3',
    borderBottom: 1,
    height: '24px',
  },
  image: {
    marginTop: 3,
    marginBottom: 3,
    height: 52.5,
    width: 70,
    objectFit: 'contain',
  },
  text: {
    textAlign: 'center',
    verticalAlign: 'middle',
    fontSize: 9,
    fontFamily: 'Nanum Gothic',
    color: theme.colors.notBlack,
    fontWeight: 'thin',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: 1,
    minHeight: '24px',
  },
});

const PdfBody = ({supplementionDetailPdf}) => {
  function getUniqueUrl(url) {
    const timestamp = new Date().getTime(); // 현재 시간의 타임스탬프
    // 이미 URL에 쿼리가 있는지 확인하고, 적절히 타임스탬프를 추가합니다.
    const separator = url?.includes('?') ? '&' : '?';
    return `${url}${separator}timestamp=${timestamp}`;
  }
  return (
    <View style={styles.body}>
      <View style={styles.headerWrapper}>
        <View style={[styles.header, {width: '3%', borderRight: 1}]}>
          <Text style={styles.text}></Text>
        </View>
        <View style={[styles.header, {width: '15%', borderRight: 1}]}>
          <Text style={styles.text}>매장</Text>
        </View>
        <View style={[styles.header, {width: '48%', borderRight: 1}]}>
          <Text style={styles.text}>요청 내용</Text>
        </View>
        <View style={[styles.header, {width: '17%', borderRight: 1}]}>
          <Text style={styles.text}>보완 이전</Text>
        </View>
        <View style={[styles.header, {width: '17%'}]}>
          <Text style={styles.text}>보완 이후</Text>
        </View>
      </View>
      {supplementionDetailPdf?.supplementation_objs?.map((item, index) => {
        return (
          <View key={item.description + index} style={styles.contentWrapper}>
            <View style={[styles.content, {width: '3%', borderRight: 1}]}>
              <Text style={styles.text}>{index + 1}</Text>
            </View>
            <View style={[styles.content, {width: '15%', borderRight: 1}]}>
              <Text style={[styles.text, {margin: '3px 0 0 0'}]}>
                {item.brand_name}
              </Text>
              <Text style={[styles.text, {margin: '3px 0'}]}>
                {item.branch_name}
              </Text>
            </View>
            <View style={[styles.content, {width: '48%', borderRight: 1}]}>
              <Text
                style={[
                  styles.text,
                  {margin: '3px 0 0 0', width: '95%', textAlign: 'left'},
                ]}
              >
                {item.name}
              </Text>
              <Text
                style={[
                  styles.text,
                  {margin: '8px 0 3px 0', width: '95%', textAlign: 'left'},
                ]}
              >
                {item.description}
              </Text>
            </View>
            <View style={[styles.content, {width: '17%', borderRight: 1}]}>
              <Image
                style={styles.image}
                source={{
                  uri: getUniqueUrl(item?.before_supplementation_image),
                }}
              />
              <Text style={styles.text}>
                {item?.before_supplementation_date}
              </Text>
            </View>
            <View style={[styles.content, {width: '17%'}]}>
              <Image
                style={styles.image}
                source={{
                  uri: getUniqueUrl(item?.after_supplementation_image),
                }}
              />
              <Text style={[styles.text, {marginBottom: 3}]}>
                {item?.after_supplementation_date}
              </Text>
            </View>
          </View>
        );
      })}
    </View>
  );
};

export default PdfBody;
