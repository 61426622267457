import React, {useState} from 'react';
import styled from 'styled-components';

import LeftIcon from 'shared/assets/icons/LeftIcon';
import RightIcon from 'shared/assets/icons/RightIcon';
import {MuiButton, MuiModal} from '../index';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 480px;
  height: 600px;
  align-items: center;
  padding: 20px;
  & > * {
    &:not(:last-child) {
      margin-top: 40px;
    }
    &:first-child {
      margin-top: 60px;
    }
    &:last-child {
      margin-top: 60px;
    }
  }
`;
const StyledImg = styled.img`
  height: 300px;
  width: auto;
`;

const Box = styled.div`
  height: 60px;
  width: ${({width}) => width};
  background-color: ${({backgroundColor}) => backgroundColor};
  color: ${({color}) => color};
  padding: 20px;
  cursor: ${({onClick}) => onClick && 'pointer'};
  text-align: center;
`;

const ImageTaskModal = ({open, handleClose, imageURL,images}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevImage = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNextImage = () => {
    if (currentIndex < images.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  return (
    <MuiModal open={open} onClose={handleClose}>
      <Container>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}>
          <Box>
            <LeftIcon length={30} onClick={handlePrevImage} />
          </Box>

          <div
            style={{
              height: '290px',
              width: '300px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <StyledImg src={images[currentIndex]} />
          </div>

          <Box>
            <RightIcon length={30} onClick={handleNextImage} />
          </Box>
        </div>
        <MuiButton text="닫기" onClick={handleClose} />
      </Container>
    </MuiModal>
  );
};

export default ImageTaskModal;
