import React from 'react';
import styled from 'styled-components';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const IndicatorWrapper = styled.div`
  margin: ${({margin}) => margin || 0};
`;

const ArrowIndicator = ({open, margin}) => {
  return (
    <IndicatorWrapper margin={margin}>
      {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
    </IndicatorWrapper>
  );
};

export default ArrowIndicator;
