/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
import React, {useCallback, useEffect} from 'react';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {
  Image,
  Logo,
  MuiButton,
  TableData,
  TableDataGroup,
  Text,
} from 'shared/components/index';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {selectFranchiseStore} from 'franchise/utils/slices/frStoreSlice';
import theme from 'shared/styles/theme';

import {useSelector} from 'react-redux';
import useLogout from 'shared/utils/hooks/useLogout';
import {selectFranchiseUser} from 'franchise/utils/slices/frUserSlice';

const Wrapper = styled.div`
  height: 100%;
  min-width: 1300px;
`;

const Content = styled.div`
  flex: 1;
  margin-top: 0px;
`;

const Drawer = styled.div`
  height: 75px;
  box-shadow: 0px 1px 2px #00000016;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  z-index: 3100;
  min-width: 1320px;
  margin: 0 auto;
`;

const ContentWrapper = styled.div`
  width: 1200px;
  min-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StoreNameWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    transition: all;
    transform: scale(1.03);
  }
  width: 780px;
  font-weight: 500;
  margin-top: -8px;
`;

const Indicator = styled.div`
  position: absolute;
  top: -4px;
  right: -4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: red;
  color: white;
  font-size: 12px;
`;
const Header = ({children, src}) => {
  const navigate = useNavigate();
  const {logUserOut} = useLogout();
  const {t} = useTranslation();
  const {supervisorData, unreadSvNotificationsCount} =
    useSelector(selectFranchiseUser);
  const {franchiseName} = useSelector(selectFranchiseStore);

  useEffect(() => {}, [franchiseName]);
  const handleLogoClick = useCallback(() => {
    navigate('/franchise/dashboard');
  }, [navigate]);

  const handleNotificationsBtn = useCallback(() => {
    navigate('/franchise/notifications');
  }, [navigate]);

  const handleUserClickLogout = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('정말 로그아웃하시겠습니까?')) {
      logUserOut();
    }
  };

  return (
    <Wrapper>
      <Drawer>
        <ContentWrapper>
          <LogoWrapper onClick={handleLogoClick}>
            {supervisorData?.logo !== null &&
            supervisorData?.logo !== '' &&
            supervisorData?.logo !== undefined ? (
              <Image src={supervisorData?.logo} shape="round" />
            ) : (
              <Logo size="lg" src={src} />
            )}
          </LogoWrapper>

          <StoreNameWrapper onClick={() => {}}>
            <Text
              style={{
                fontSize: '20px',
                width: '100%',
              }}
            >
              {franchiseName}
            </Text>
          </StoreNameWrapper>

          <TableDataGroup width="200px" justifyContent="space-between">
            <TableData onClick={handleNotificationsBtn}>
              <NotificationsIcon style={{fontSize: '25px'}} />
              {unreadSvNotificationsCount > 0 && (
                <Indicator>{unreadSvNotificationsCount}</Indicator>
              )}
            </TableData>

            <MuiButton
              fontSize={theme.fontSizes.body}
              onClick={handleUserClickLogout}
              backgroundColor="#A4A4A4"
              textColor="#f8f8f8"
              variant="contained"
              text={t('buttons.logout')}
              endIcon={<LogoutIcon />}
              style={{height: '28px'}}
            />
          </TableDataGroup>
        </ContentWrapper>
      </Drawer>

      <Content>{children}</Content>
    </Wrapper>
  );
};

export default Header;
