import React from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import {Wrapper} from 'shared/components/index';
import SlaveTaskAddForm from './SlaveTaskAddForm';

const TitleWrapper = styled.div`
  padding: 120px 0px 0px 0px;
  width: 100%;
  margin: -40px 0 0 0;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const TaskAddTemplate = ({
  storeName,
  categoryName,
  onSubmit,
  onValidate,
  loading,
  handleGoback,
}) => {
  return (
    <Wrapper infoText="업무등록 페이지를 보고계십니다.">
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '100px 0px 0px 0px',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TitleWrapper>
            <Typography variant="h2" gutterBottom></Typography>
          </TitleWrapper>
          <SlaveTaskAddForm
            categoryName={categoryName}
            onSubmit={onSubmit}
            onValidate={onValidate}
            handleGoback={handleGoback}
          />
        </>
      )}
    </Wrapper>
  );
};

export default TaskAddTemplate;
