/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import CircularProgress from '@mui/material/CircularProgress';
import theme from 'shared/styles/theme';

const colors = [
  '#a18ddd',
  '#8488b5',
  '#78acc1',
  '#98bfd0',
  theme.colors.kalsPurple,
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#FDE842',
  '#FF8092',
  '#FF3400',
];

const sample = [
  {
    name: '',
    0: 0,
  },
  {
    name: '',
    0: 0,
  },
  {
    name: '',
    0: 0,
  },

  {
    name: '',
    total: 0,
  },
];

const QscBarChart = ({matrixGraphData, matrixLoading}) => {
  if (matrixLoading) {
    return (
      <div
        style={{
          width: '650px',
          height: '300px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (matrixGraphData) {
    const barData = matrixGraphData
      ? matrixGraphData?.category_namelist?.map((categoryName, index) => {
          const values = matrixGraphData?.graph_matrix[index];
          const shortenedCategoryName =
            categoryName.length > 10
              ? `${categoryName.slice(0, 10)}...`
              : categoryName;
          const dataObject = {name: shortenedCategoryName};
          values.forEach((value, valueIndex) => {
            dataObject[valueIndex + 1] = value;
          });
          return dataObject;
        })
      : [];

    if (matrixGraphData?.score) {
      barData?.push({name: '총계', total: matrixGraphData?.score});
    }

    const barComponents = matrixGraphData
      ? matrixGraphData?.score_name_list.map((scoreName, index) => (
          <Bar key={index} dataKey={index + 1} fill={colors[index]} />
        ))
      : [];

    return (
      <BarChart
        width={650}
        height={300}
        data={barData}
        margin={{top: 5, right: 30, left: 0, bottom: 5}}
        style={{margin: '0 0 0 -40px', fontSize: '13px'}}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis domain={[0, 100]} />
        <Tooltip />
        <Legend
          payload={
            matrixGraphData
              ? matrixGraphData?.score_name_list
                  .reverse()
                  .map((name, index) => ({value: name, color: colors[index]}))
              : []
          }
        />

        {barComponents}
        <Bar dataKey="total" fill="#8182da" />
      </BarChart>
    );
  }

  return (
    <BarChart
      width={650}
      height={300}
      data={sample}
      margin={{
        top: 5,
        right: 30,
        left: 0,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis domain={[0, 100]} />
      <Tooltip />
      <Legend />
      <Bar dataKey="1" fill={colors[0]} />
      <Bar dataKey="2" fill={colors[2]} />
    </BarChart>
  );
};

export default QscBarChart;
