import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {MuiModal, Text} from 'shared/components/index';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';
import {Button} from 'shared/components/index';
import {
  getInviteMembersToChatRoomAsync,
  postMembertoChatRoomAsync,
  selectChatting,
  setAddChatRoomModal,
} from 'owler/utils/slices/chattingSlice';
import {selectStore} from 'owler/utils/slices/storeSlice';
import AppSearchBox from 'shared/components/organisms/AppSearchBox';

import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import AddChatRoomNameModal from './AddChatRoomNameModal';
import theme from 'shared/styles/theme';

const NoChatsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MemberListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc; /* Adjust based on your design */
`;

const Container = styled.div`
  position: relative;
  background-color: white;
  width: 600px;
  height: 700px;
  align-items: center;
  & > * {
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const UserText = styled(Typography)`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default function AddChatRoomModal({loadChatRooms}) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {addChatRoomModal, addChatRoomData} = useSelector(selectChatting);

  const {currentStore, memberInfo} = useSelector(selectStore);

  const [search, setSearch] = useState('');
  const [chatMemberList, setChatMemberList] = useState([]);

  const [searchFilteredUsers, setSearchFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [addChatRoomNameModal, setAddChatRoomNameModal] = useState(false);
  const [placeHolderNames, setPlaceHolderNames] = useState([]);

  const handleModalClose = () => {
    dispatch(setAddChatRoomModal(false));
  };

  useEffect(() => {
    if (memberInfo !== null) {
      setLoading(true);
      dispatch(
        getInviteMembersToChatRoomAsync({
          member_uid: memberInfo?.member_uid,
          store_uid: currentStore?.store_uid,
        }),
      )
        .unwrap()
        .then((payload) => {
          setChatMemberList(payload);
          setLoading(false);
        })
        .catch((err) => {
          alert(err);
          setLoading(false);
        });
    }
  }, [dispatch, memberInfo, currentStore]);

  useEffect(() => {
    if (chatMemberList?.length) {
      setSearchFilteredUsers(chatMemberList);
    } else {
      setSearchFilteredUsers([]);
    }
  }, [chatMemberList]);

  const searchFilter = (text) => {
    if (text) {
      const newData = chatMemberList?.filter((_member) => {
        const filterData = _member.name
          ? _member.name.toUpperCase()
          : ''.toUpperCase();

        const textData = text.toUpperCase();
        return filterData.indexOf(textData) > -1;
      });
      setSearchFilteredUsers(newData);
      setSearch(text);
    } else {
      setSearchFilteredUsers(chatMemberList);
      setSearch(text);
    }
  };

  const handleUserSelect = (user) => {
    const newSelectedUsers = [...selectedUsers];
    const index = newSelectedUsers.findIndex(
      (u) => u.member_uid === user.member_uid,
    );
    if (index === -1) {
      newSelectedUsers.push(user);
    } else {
      newSelectedUsers.splice(index, 1);
    }
    setSelectedUsers(newSelectedUsers);
    setPlaceHolderNames(newSelectedUsers.map((_u) => _u.name));
  };

  const handleAddUsers = () => {
    if (selectedUsers.length > 1) {
      setAddChatRoomNameModal(true);
    } else {
      setAddChatRoomNameModal(false);

      const selectedRoomMembers = selectedUsers.map((user) => user.member_uid);

      const myOwnUID = memberInfo.member_uid;

      selectedRoomMembers.push(myOwnUID);

      const data = {
        store_uid: currentStore?.store_uid,
        title: selectedUsers.length === 1 ? selectedUsers[0].name : null,
        member_uid_list: selectedRoomMembers,
      };

      if (selectedUsers?.length) {
        dispatch(postMembertoChatRoomAsync(data))
          .unwrap()
          .then((payload) => {
            if (i18n.language === 'ko') {
              const {kr} = payload;
              alert(kr);
            } else {
              const {en} = payload;
              alert(en);
            }

            dispatch(setAddChatRoomModal(false));
            loadChatRooms();
          })
          .catch((error) => {
            alert(error);
          });
      }
    }
  };

  const handleCreateChatRoom = (values) => {
    const selectedRoomMembers = selectedUsers.map((user) => user.member_uid);

    const myOwnUID = memberInfo.member_uid;

    selectedRoomMembers.push(myOwnUID);

    const data = {
      store_uid: currentStore?.store_uid,
      title: values.name ? values.name : null,
      member_uid_list: selectedRoomMembers,
    };

    dispatch(postMembertoChatRoomAsync(data))
      .unwrap()
      .then((payload) => {
        const {kr} = payload;
        alert(kr);

        dispatch(setAddChatRoomModal(false));
        loadChatRooms();
      })
      .catch((error) => {
        alert(error);
      });

    setAddChatRoomNameModal(false);
  };

  return (
    <MuiModal open={addChatRoomModal} onClose={handleModalClose}>
      <Container>
        <TitleWrapper>
          <Text bold fontSize="lg">
            {addChatRoomData}
          </Text>
        </TitleWrapper>

        <AppSearchBox
          searchValue={search}
          searchValueFilter={searchFilter}
          placeholder={t('screens.chatting.search_members')}
          rightIcon={true}
          rightIconName="search" // Replace with your icon component if needed
          rightIconColor="#ccc" // Use the actual color from your theme
          onRightIconPress={() => {}}
        />

        <div
          style={{
            overflow: 'scroll',
            width: '100%',
            height: '550px',
            padding: '30px 20px',
          }}
        >
          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div>
              {searchFilteredUsers.length === 0 ? (
                <NoChatsContainer>
                  <Typography>
                    {t('screens.chatting.no_store_members')}
                  </Typography>
                </NoChatsContainer>
              ) : (
                searchFilteredUsers.map((member, index) => (
                  <MemberListContainer key={index}>
                    <UserText variant="body2">
                      {member.name}
                      {member.member_position
                        ? ` (${member.member_position})`
                        : ''}
                    </UserText>
                    <Checkbox
                      checked={selectedUsers.some(
                        (u) => u.member_uid === member.member_uid,
                      )}
                      onChange={() => handleUserSelect(member)}
                      color="primary"
                    />
                  </MemberListContainer>
                ))
              )}
            </div>
          )}
        </div>

        <div
          style={{
            width: '100%',
            height: '70px',
            position: 'absolute',
            bottom: 0,
            zIndex: 100,
            display: 'flex',
            padding: '2px 30px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            variant="contained"
            onClick={handleModalClose}
            style={{
              marginRight: '30px',
              backgroundColor: 'grey',
              border: 'none',
            }}
          >
            취소
          </Button>

          <Button
            variant="contained"
            onClick={handleAddUsers}
            style={{
              backgroundColor: theme.colors.kalsPurple,
              border: 'none',
            }}
          >
            확인
          </Button>
        </div>
      </Container>

      <AddChatRoomNameModal
        addChatRoomNameModal={addChatRoomNameModal}
        setAddChatRoomNameModal={setAddChatRoomNameModal}
        handleCreateChatRoom={handleCreateChatRoom}
        placeHolderNames={placeHolderNames}
      />
    </MuiModal>
  );
}
