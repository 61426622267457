import styled from 'styled-components';
import theme from 'shared/styles/theme';
//import { TableDataGroup,TableData } from 'shared/components/index';
import {OwlerRNHeader} from 'shared/components/index';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import giftImage from 'shared/assets/images/gift_box_icon.png';

const State = styled.div`
  height: 22px;
  width: 64px;
  background-color: ${theme.colors.kalsPurple};
  color: #ffffff;
  font-size: 13px;
  border-radius: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DeadLine = styled.div`
  right: 0;
  background-color: ${theme.colors.primaryDark};
  color: #ffffff;
  height: 4vh;
  font-size: 15px;
  text-align: center;
  border-radius: 10px;
  opacity: '1' !important;
  line-height: 4vh;
  margin-top: 10px;
`;

const Container = styled.div`
  border: 1px solid ${theme.colors.kalsPurple};
  width: 90vw;
  padding: 10px 10px 14px 10px;
  border-radius: 12px;
  background-color: ${(props) =>
    props.list.is_close === false ? '' : '#E6E6E6'};
  opacity: ${(props) => (props.list.is_close === false ? '1' : '0.68')};

  &::-webkit-scrollbar {
    display: none;
  }
  &:hover {
    cursor: ${(props) =>
      props.list.is_close === false ? 'pointer' : 'default'};
  }

  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
`;

const TitleArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const Image = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 10px;
  object-fit: cover;
`;

const ReviewEvent = ({item, clickEvent}) => {
  return (
    <Container list={item} onClick={() => clickEvent(item)}>
      <TitleArea>
        <OwlerRNHeader title={item.category} marginLeft="2px" />

        {item.status === '한정수량' ? (
          <State>{item.status}</State>
        ) : (
          <div style={{width: '60px', height: '20px'}} />
        )}
      </TitleArea>

      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: '92%',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '10px',
          }}
        >
          <div
            style={{
              borderRadius: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {item.image ? (
              <Image src={item.image} />
            ) : (
              <Image src={giftImage} />
            )}
          </div>

          <div
            style={{
              marginLeft: '12px',
            }}
          >
            <p
              style={{
                fontSize: 15,
                color: theme.colors.secondary,
                marginBottom: '5px',
              }}
            >
              이벤트 참여 시
            </p>
            <p
              style={{
                fontSize: 15,
                color: theme.colors.notBlack,
                fontWeight: 'bold',
                maxWidth: '220px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {item.title}
            </p>
          </div>
        </div>

        <ArrowForwardIosIcon />
      </div>

      {item.is_close === true ? (
        <DeadLine>오늘의 이벤트가 종료되었습니다.</DeadLine>
      ) : null}
    </Container>
  );
};

export default ReviewEvent;
