import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DropBox from 'react-select';

const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Select = (props) => {
  const { isMulti, name, options, placeholder, defaultValue, closeMenuOnSelect } = props;
  return (
    <SelectWrapper>
      <DropBox
        isMulti={isMulti}
        name={name}
        options={options}
        placeholder={placeholder}
        defaultValue={defaultValue}
        closeMenuOnSelect={closeMenuOnSelect}
        className="basic-multi-select"
        classNamePrefix="select"
      />
    </SelectWrapper>
  );
};

Select.propTypes = {
  isMulti: PropTypes.bool,
  name: PropTypes.string, // 임시로 null로함 나중에  isrequired로 바꾸기
  options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })).isRequired,
  placeholder: PropTypes.string, // 임시로 null로함 나중에  isrequired로 바꾸기
  defaultValue: PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }).isRequired,
  closeMenuOnSelect: PropTypes.bool,
};

Select.defaultProps = {
  closeMenuOnSelect: false,
  isMulti: false,
  name: null,
  placeholder: null,
};

export default Select;
