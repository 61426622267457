import styled from 'styled-components';

const ProductText = styled.div`
    width: 60%;
    font-size: ${props => props.fontSize || "18px"};
    word-wrap: break-word;
    margin-top: ${props => props.marginTop || "2.5vh"};
    text-align:left;
    border-left: 3px dashed black;
    padding: 2vh 0 2vh 5vw;
`

export default ProductText;