/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable react/require-default-props */
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import MoreFeedbackModal from './MoreFeedbackModal';
import SearchInputWithIcon from './modal_components/Search';
import {MuiModal} from 'shared/components/index';
import api from 'shared/utils/api';

const Container = styled.div`
  width: 480px;
  height: 450px;
  border-radius: 5px;
  background-color: #fff;
  padding: 25px;
`;

const StyledTextWrapper = styled.div`
  width: 100px;
  display: flex;
  justify-content: center;
`;

const StyledText = styled.p`
  font-size: ${(props) => (props.size ? props.size : '14px')};
  font-weight: ${(props) => (props.weight ? props.weight : '400')};
  color: ${(props) => (props.color ? props.color : 'black')};
  margin: 0 0 -3px 0;
`;

const StoreListContainer = styled.div`
  width: 98%;
  height: 260px;

  overflow-y: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    scrollbar-width: none;
  }
`;

const StoreContainer = styled.div`
  width: 100%;
  height: 50px;
  border-bottom: 0.3px solid #cecece;
  padding: 0 5px 0 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: #e5e5e5;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  height: auto;
  text-align: center;
`;

const StyledButton = styled.button`
  font-size: 12px;
  padding: 6px 30px;
  color: #fff;
  background-color: #8773d6;
  border: none;
  border-radius: 20px;
  margin: 15px auto;
  cursor: pointer;
`;

const NoticeFeedback = ({open, handleClose, notice_uid, notice_type}) => {
  const [feedback, setFeedback] = useState([]);

  const [filteredFeedback, setFilteredFeedback] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [toggleModal, setToggleModal] = useState(false);

  const [moreFeedback, setMoreFeedback] = useState({
    type: '',
    feedback: [],
    incompleteUsers: [],
  });

  useEffect(() => {
    const fetchFeedback = async () => {
      setLoading(true);
      const res = await api.getResponderInfo(notice_uid);

      setFeedback(res.data.results.results);

      setLoading(false);
    };

    fetchFeedback();
  }, [notice_uid]);

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const searchResult = feedback?.filter((eachStore) =>
      eachStore.store_name?.toLowerCase().includes(searchTerm.toLowerCase()),
    );
    setFilteredFeedback(searchResult);
  }, [searchTerm, feedback]);

  const handleStoreClick = (feedbacks, incomplete) => {
    if (notice_type === 'image') {
      if (feedbacks.length > 0) {
        setToggleModal(true);
      } else {
        setToggleModal(false);
      }
    } else {
      setToggleModal(true);
    }
    setMoreFeedback({
      type: notice_type,
      feedback: feedbacks,
      incompleteUsers: incomplete,
    });
  };

  const handleCloseModal = () => {
    setToggleModal(false);
  };

  return (
    <MuiModal open={open} onClose={handleClose}>
      <Container>
        <StyledText weight="bold" size="22px">
          매장 확인
        </StyledText>
        <SearchInputWithIcon value={searchTerm} onChange={handleChange} />
        {loading ? (
          'Loading...'
        ) : (
          <>
            <StoreListContainer>
              {filteredFeedback?.length === 0 && (
                <div
                  style={{
                    margin: '10px auto',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  공지를 전달한 매장이 없습니다!
                </div>
              )}

              {filteredFeedback.map((eachStore) => (
                <StoreContainer
                  onClick={() =>
                    handleStoreClick(eachStore.responses, eachStore.unresponse)
                  }
                  key={eachStore.storeUID}
                >
                  {notice_type === 'check' ? (
                    <>
                      <StyledText>{eachStore.store_name}</StyledText>

                      {eachStore.res_member_count === 0 ? (
                        <StyledTextWrapper>
                          <StyledText color="red">
                            {eachStore.res_member_count}
                          </StyledText>

                          <StyledText color="grey"> / </StyledText>

                          <StyledText color="black">
                            {eachStore.member_count}
                          </StyledText>
                        </StyledTextWrapper>
                      ) : (
                        <StyledTextWrapper>
                          <StyledText color="#8160FF">
                            {eachStore.res_member_count}
                          </StyledText>

                          <StyledText color="gray"> / </StyledText>

                          <StyledText color="black">
                            {eachStore.member_count}
                          </StyledText>
                        </StyledTextWrapper>
                      )}
                    </>
                  ) : (
                    <>
                      <StyledText>{eachStore.store_name}</StyledText>
                      {eachStore.res_member_count === 0 ? (
                        <StyledTextWrapper>
                          <StyledText color="red">
                            {eachStore.res_member_count}
                          </StyledText>

                          <StyledText color="grey"> / </StyledText>

                          <StyledText color="black">
                            {eachStore.member_count}
                          </StyledText>
                        </StyledTextWrapper>
                      ) : (
                        <StyledTextWrapper>
                          <StyledText color="#8160FF">
                            {eachStore.res_member_count}
                          </StyledText>

                          <StyledText color="gray"> / </StyledText>

                          <StyledText color="black">
                            {eachStore.member_count}
                          </StyledText>
                        </StyledTextWrapper>
                      )}
                      {/* {eachStore.res_member_count > 0 ? (
                        <StyledCameraIcon coolor="#8773D6" />
                      ) : (
                        <StyledCameraIcon />
                      )} */}
                    </>
                  )}
                </StoreContainer>
              ))}
            </StoreListContainer>

            {toggleModal && (
              <MoreFeedbackModal
                feedback={moreFeedback}
                open={toggleModal}
                handleClose={handleCloseModal}
              />
            )}
          </>
        )}
        <ButtonContainer>
          <StyledButton onClick={handleClose}>확인</StyledButton>
        </ButtonContainer>
      </Container>
    </MuiModal>
  );
};

export default NoticeFeedback;
