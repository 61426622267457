import styled from 'styled-components';
import {Text} from 'shared/components/index';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {Button} from '@mui/material';
import {useSelector, useDispatch} from 'react-redux';
import {
  selectPosition,
  setQuestionModalOpen,
  setPositionAddModalOpen,
} from 'owler/utils/slices/positionSlice';
import QuestionModal from './QuestionModal';
import PositionChangeModal from './PositionChangeModal';
import PositionAddModal from './PositionAddModal';
import {useState} from 'react';

const Container = styled.div`
  width: 94%;
  margin-left: 3%;
  margin-top: -10px;
`;
const PositionWrap = styled.div`
  width: 100%;
  margin-bottom: 20px;
  background-color: #d8d8d8;
  padding: 10px;
  border-radius: 10px;
  display: flex;
`;
const AllPositionWrap = styled.div`
  background-color: #f2f2f2;
  border-radius: 15px;
  padding: 20px 20px 5px 20px;
  margin: 20px 0 50px 0;
`;
const TitleWrapper = styled.div`
  display: flex;
  padding: 0 10px;
  margin-bottom: 5px;
  width: 93.5%;
`;
const StyledText = styled(Text)`
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
`;
const WorkerManagement = ({handleAddBtn, handleDeleteBtn, handleChangeBtn}) => {
  const {allPositions} = useSelector(selectPosition);
  const [selectedPosition, setSelectPosition] = useState(null);
  const dispatch = useDispatch();
  const renderLevel = (position) => {
    if (position.authority_level === 0) return '사장';
    if (position.authority_level === 1) return '매니저';
    if (position.authority_level === 2) return '정직원';
    if (position.authority_level === 3) return '아르바이트';
  };
  return (
    <Container>
      <AllPositionWrap>
        <TitleWrapper>
          <StyledText bold>직급명</StyledText>
          <StyledText bold>권한</StyledText>
        </TitleWrapper>
        {allPositions?.map((position, index) => {
          return (
            <div style={{display: 'flex'}}>
              <PositionWrap key={index}>
                <StyledText bold>{position.name} </StyledText>
                <StyledText bold>{renderLevel(position)}</StyledText>
              </PositionWrap>
              <Button
                onClick={() => {
                  dispatch(setQuestionModalOpen(true));
                  setSelectPosition(position);
                }}
                sx={{
                  color: '#000000',
                  marginTop: '4px',
                  height: '36px',
                  width: '24px',
                  minWidth: '0px',
                }}
              >
                <MoreVertIcon
                  fontSize="large"
                  sx={{
                    marginTop: '0px',
                  }}
                />
              </Button>
            </div>
          );
        })}
      </AllPositionWrap>
      <Button
        onClick={() => {
          dispatch(setPositionAddModalOpen(true));
        }}
        sx={{
          color: '#000000',
          marginTop: '-70px',
          marginLeft: '340px',
          marginBottom: '0px',
          height: '36px',
          width: '36px',
          minWidth: '0px',
        }}
      >
        <AddCircleOutlineIcon fontSize="large" />
      </Button>
      <QuestionModal
        position={selectedPosition}
        handleDeleteBtn={handleDeleteBtn}
      />
      <PositionChangeModal
        position={selectedPosition}
        handleChangeBtn={handleChangeBtn}
      />
      <PositionAddModal handleAddBtn={handleAddBtn} />
    </Container>
  );
};
export default WorkerManagement;
