/* eslint-disable no-plusplus */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable no-console */
import React, {useState, useEffect, useCallback, useMemo} from 'react';

import {useNavigate} from 'react-router-dom';
import QSCNoteTemplate from './template';
import {catchError} from 'shared/utils/catchError';
import api from 'shared/utils/api';
import {useSelector} from 'react-redux';
import {selectFranchiseQsc} from 'franchise/utils/slices/frQscSlice';

const QSCNote = () => {
  const navigate = useNavigate();
  const {qsc_uid} = useSelector(selectFranchiseQsc);
  const [error, setError] = useState(null);
  const [qscNotes, setQscNotes] = useState([]);

  const getQSCNotes = useMemo(
    () => async () => {
      try {
        const response = await api.getQSCNotes({qsc_uid});

        const {results} = response.data;
        setQscNotes(results);
      } catch (e) {
        setError({opened: true, error: e});
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    getQSCNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (value, {resetForm}) => {
    const {message} = value;

    if (message.trim() !== '') {
      try {
        const data = {
          qsc_uid: qsc_uid,
          contents: message,
        };

        await catchError(api.createQSCNote, data).then(() => {
          getQSCNotes();
          resetForm();
        });
      } catch (e) {
        setError({opened: true, error: e});
      }
    }
  };

  const handleDeleteQscNote = async _note => {
    // Prompt user for confirmation
    const userConfirmation = window.confirm('메모를 삭제하시겠습니까?');

    if (userConfirmation) {
      try {
        await catchError(api.deleteQSCNote, {
          qsc_note_uid: _note.qsc_note_uid,
        }).then(() => {
          getQSCNotes();
        });
      } catch (e) {
        setError({opened: true, error: e});
      }
    }
  };

  // Go back to chat room list
  const handleListBtnClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <>
      <QSCNoteTemplate
        handleListBtnClick={handleListBtnClick}
        qscNotes={qscNotes}
        onSubmit={onSubmit}
        handleDeleteQscNote={handleDeleteQscNote}
      />
    </>
  );
};

export default QSCNote;
