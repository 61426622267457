/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';

const CarouselContainer = styled.div`
  margin: 14px -15px;
  display: flex;
  //padding-left: 20px;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scrollbar-width: thin;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-track {
    background: #ffffff;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const ImageItem = styled.img`
  height: 500px;
  width: auto;
  margin: 0 5px 10px 5px;
  cursor: ${({disable}) => (disable ? '' : 'pointer')};
`;

const ImageCarousel = ({
  urlType = 'image',
  images,
  handleViewImageBtn,
  disable,
}) => {
  return (
    <CarouselContainer>
      {images.map((image, index) => (
        <ImageItem
          key={index}
          src={typeof image === 'string' ? image : image.image}
          alt={`Image ${index}`}
          onClick={() =>
            handleViewImageBtn(typeof image === 'string' ? image : image.image)
          }
          disable={disable}
        />
      ))}
    </CarouselContainer>
  );
};

export default ImageCarousel;
