import React, {useState} from 'react';
import styled from 'styled-components';

import SearchIcon from '@mui/icons-material/Search';

// Define the styled components

const InputContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  padding: 10px;
`;

const SearchInput = styled.input`
  border-color: ${(props) => (props.clearIcon ? '#007bff' : '#ccc')};
  border-width: ${(props) => (props.clearIcon ? '1.2px' : '1px')};
  padding: 10px;
  width: 89%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const RightIcon = styled.button`
  position: absolute;
  right: 0px;
  background: transparent;
  border: none;
  cursor: pointer;
  color: ${(props) => props.iconColor || 'black'};
  width: 12%;
`;

function AppSearchBox({
  searchValue,
  searchValueFilter,
  placeholder,
  rightIcon,
  rightIconName,
  rightIconColor,
  onRightIconPress,
  ...otherProps
}) {
  const [clearIcon, setClearIcon] = useState(false);

  const onFocus = () => setClearIcon(true);
  const onBlur = () => setClearIcon(false);

  return (
    <InputContainer>
      <SearchInput
        clearIcon={clearIcon}
        onFocus={onFocus}
        onBlur={onBlur}
        value={searchValue}
        onChange={(e) => searchValueFilter(e.target.value)}
        placeholder={placeholder}
        {...otherProps}
      />

      {rightIcon && (
        <RightIcon iconColor={rightIconColor} onClick={onRightIconPress}>
          <SearchIcon />
        </RightIcon>
      )}
    </InputContainer>
  );
}

export default AppSearchBox;
