import {FranchiseDrawer, FranchiseWrapper, Text} from 'shared/components/index';
import theme from 'shared/styles/theme';
import styled from 'styled-components';
import TopFranchiseActions from './TopFranchiseActions';
import TodayQSCSchedule from './TodayQSCSchedule';
import QSCAverageScore from './QSCAverageScore';
//import SvStoreVisitGraph from './SvStoreVisitGraph';
import SupplementationStatus from './SupplementationStatus';
import RealTimeSupplementationImageModal from './RealTimeSupplementationImageModal';

const DashContainer = styled.div`
  width: 1160px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -65px 0 0 -2px;
`;

const LeftDashWrapper = styled.div`
  width: 540px;
`;

const RightDashWrapper = styled.div`
  width: 540px;
  border-left: 2px solid ${theme.colors.grey9};
  position: relative;
  // padding: 15px;
`;

const ButtonWrap = styled.div`
  width: 88%;
  min-height: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 0px 0;
  margin-left: 48px;
  border: 2px solid ${theme.colors.grey9};
  padding: 2px 10px;
  border-radius: 6px;
`;

const MinMaxScores = ({minMaxScores}) => {
  return (
    <>
      <ButtonWrap style={{marginTop: '15px'}}>
        <Text style={{width: '120px', fontSize: '13px'}} margin="0 0 0 15px">
          최고점 매장
        </Text>

        <Text
          style={{
            fontSize: '13px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '300px',
            textAlign: 'center',
          }}
        >
          {minMaxScores?.max_score_store_name}
        </Text>
      </ButtonWrap>

      <ButtonWrap>
        <Text style={{width: '120px', fontSize: '13px'}} margin="0 0 0 15px">
          최저점 매장
        </Text>

        <Text
          style={{
            width: '300px',
            fontSize: '13px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textAlign: 'center',
          }}
        >
          {minMaxScores?.min_score_store_name}
        </Text>
      </ButtonWrap>
    </>
  );
};

const DashboardTemplate = ({
  svQSCStatus,
  handleNoteCreateBtn,

  svTodaysSchedules,

  handleSvTodaysScheduleBtn,

  activeQSCStat,
  handleQSCStatClick,

  lastWeekScoreGraphData,
  thisWeekScoreGraphData,

  thisMonthScoreGraphData,

  minMaxScores,

  handleChangeWeekBack,
  handleChangeWeekForward,
  displayDateWeekly,
  clickCount,

  selectedYear,
  handleYearChange,

  supplementationList,
  isImageLoading,
  imageModal,
  setImageModal,
}) => {
  return (
    <FranchiseWrapper titleText="주식회사 아울러">
      <FranchiseDrawer>
        <DashContainer>
          <LeftDashWrapper>
            <TopFranchiseActions
              svQSCStatus={svQSCStatus}
              handleNoteCreateBtn={handleNoteCreateBtn}
            />
            <TodayQSCSchedule
              svTodaysSchedules={svTodaysSchedules}
              handleSvTodaysScheduleBtn={handleSvTodaysScheduleBtn}
            />
          </LeftDashWrapper>

          <RightDashWrapper>
            <QSCAverageScore
              activeQSCStat={activeQSCStat}
              handleQSCStatClick={handleQSCStatClick}
              lastWeekScoreGraphData={lastWeekScoreGraphData}
              thisWeekScoreGraphData={thisWeekScoreGraphData}
              thisMonthScoreGraphData={thisMonthScoreGraphData}
              handleChangeWeekBack={handleChangeWeekBack}
              handleChangeWeekForward={handleChangeWeekForward}
              displayDateWeekly={displayDateWeekly}
              clickCount={clickCount}
              selectedYear={selectedYear}
              handleYearChange={handleYearChange}
            />

            <MinMaxScores minMaxScores={minMaxScores} />

            <SupplementationStatus
              supplementationList={supplementationList}
              isImageLoading={isImageLoading}
              setImageModal={setImageModal}
            />

            <RealTimeSupplementationImageModal
              imageModal={imageModal}
              setImageModal={setImageModal}
            />
          </RightDashWrapper>
        </DashContainer>
      </FranchiseDrawer>
    </FranchiseWrapper>
  );
};

export default DashboardTemplate;
