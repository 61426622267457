import React from 'react';
import {useSelector} from 'react-redux';
import {PDFViewer} from '@react-pdf/renderer';
import WorkRegisterViewPdfTemplate from './template/index';
import {useLocation} from 'react-router-dom';
import {selectCommute} from 'owler/utils/slices/commuteSlice';

const WorkRegisterViewPdf = () => {
  const location = useLocation();
  const {year, month} = location.state;
  const {commuteLog} = useSelector(selectCommute);

  const pdfContent = (
    <PDFViewer style={{width: '100%', height: '100vh'}}>
      <WorkRegisterViewPdfTemplate
        year={year}
        month={month}
        commuteLog={commuteLog}
      />
    </PDFViewer>
  );

  return pdfContent;
};

export default WorkRegisterViewPdf;
