/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable react/require-default-props */
import React, {useState} from 'react';
import {MuiModal, Text} from 'shared/components/index';
import styled from 'styled-components';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import MoreImageModal from './MoreImageModal';

const Container = styled.div`
  width: 600px;
  max-height: 700px;
  min-height: 500px;
  border-radius: 5px;
  background-color: #fff;
  padding: 5px 25px 5px 25px;
  overflow-y: scroll;
  ::webkit-scrollbar-thumb {
    background-color: #d6d6d6; /* 스크롤 핸들의 배경색 */
    border-radius: 4px; /* 스크롤 핸들의 모서리 둥글게 */
    background-clip: padding-box; /* 경계 내부로 배경이 그려지도록 */
    border: 2px solid transparent; /* 스크롤바의 경계 설정 (안쪽에 간격 줌) */
  }
  ::webkit-scrollbar {
    width: 100px; /* 스크롤바의 넓이 */
    background-color: #f5f5f5; /* 스크롤 트랙의 배경색 */
  }
  ::webkit-scrollbar-thumb:hover {
    background-color: #a8a8a8; /* 마우스를 올렸을 때의 스크롤 핸들 배경색 */
  }
`;
const StyledCameraIcon = styled(PhotoCameraOutlinedIcon)`
  && {
    color: ${(props) => (props.coolor ? props.coolor : '#A8A8A8')};
    font-size: 35px;
    margin-top: 3px;
  }

  margin-left: 15px;
`;

const StyledText = styled.p`
  font-size: ${(props) => (props.size ? props.size : '14px')};
  font-weight: ${(props) => (props.weight ? props.weight : '400')};
  color: ${(props) => (props.color ? props.color : 'black')};
  margin: 12px 0 5px 0;
`;

const StoreListContainer = styled.div`
  width: 98%;
  max-height: 190px;
  min-height: 160px;
  overflow-y: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    scrollbar-width: none;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  max-height: 530px;
  min-height: 379px;
`;

const UserContainer = styled.div`
  width: 100%;
  border-bottom: 0.3px solid #cecece;
  padding: 10px 15px 10px 0;
  user-select: none;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-size: 18px;

  &:hover {
    background-color: #e5e5e5;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  margin: 10px 0 0 0;
`;

const StyledButton = styled.button`
  font-size: 12px;
  padding: 6px 30px;
  color: #fff;
  background-color: #8773d6;
  border: none;
  border-radius: 4px;
  margin: 5px auto;
  cursor: pointer;
`;

const UserImage = styled.img`
  width: 30px;
  height: 30px;
  display: inline;
  margin: 0 10px;
  vertical-align: middle;
  border: 1px solid black;
  border-radius: 15px;
`;

const StyledSpan2 = styled.div`
  display: flex;
  height: 32px;
  align-items: center;
`;
const StoreNoticeFeedback = ({
  open,
  handleClose,
  notice_type,
  storeFeedback,
}) => {
  const [toggleModal, setToggleModal] = useState(false);
  const handleCloseModal = () => {
    setToggleModal(false);
  };

  if (notice_type === 'image') {
    return (
      <MuiModal open={open} onClose={handleClose}>
        <Container>
          <Wrapper>
            <StyledText color="#0ead69" weight="bold" size="22px">
              확인
            </StyledText>
            {storeFeedback.responses?.length > 0 ? (
              <StoreListContainer>
                {storeFeedback.responses?.map((user, i) => (
                  <UserContainer onClick={() => setToggleModal(true)} key={i}>
                    <StyledSpan2>
                      <UserImage
                        src={
                          user.image !== null ? user.image : '/empty_user.png'
                        }
                      />
                      <Text
                        style={{
                          width: '300px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {user.name}
                      </Text>
                    </StyledSpan2>
                    <StyledSpan2>
                      <Text
                        style={{
                          width: '120px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          textAlign: 'right',
                        }}
                      >
                        {user.position}
                      </Text>
                    </StyledSpan2>
                  </UserContainer>
                ))}
              </StoreListContainer>
            ) : (
              <MoreHorizIcon />
            )}

            <StyledText color="#e63946" weight="bold" size="22px">
              미확인
            </StyledText>
            {storeFeedback.unresponse?.length > 0 ? (
              <StoreListContainer>
                {storeFeedback.unresponse?.map((user, i) => (
                  <UserContainer key={i}>
                    <StyledSpan2>
                      <UserImage
                        src={
                          user.image !== null ? user.image : '/empty_user.png'
                        }
                      />
                      <Text
                        style={{
                          width: '300px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {user.name}
                      </Text>
                    </StyledSpan2>
                    <StyledSpan2>
                      <Text
                        style={{
                          width: '120px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          textAlign: 'right',
                        }}
                      >
                        {user.position}
                      </Text>
                    </StyledSpan2>
                  </UserContainer>
                ))}
              </StoreListContainer>
            ) : (
              <MoreHorizIcon />
            )}
          </Wrapper>
          <ButtonContainer>
            <StyledButton onClick={handleClose}>확인</StyledButton>
          </ButtonContainer>
        </Container>
        {toggleModal && (
          <MoreImageModal
            storeFeedback={storeFeedback}
            open={toggleModal}
            handleClose={handleCloseModal}
          />
        )}
      </MuiModal>
    );
  }
  if (notice_type === 'check') {
    return (
      <MuiModal open={open} onClose={handleClose}>
        <Container>
          <Wrapper>
            <StyledText color="#0ead69" weight="bold" size="22px">
              확인
            </StyledText>
            {storeFeedback.responses?.length > 0 ? (
              <StoreListContainer>
                {storeFeedback.responses?.map((user, i) => (
                  <UserContainer key={i}>
                    <StyledSpan2>
                      <UserImage
                        src={
                          user.image !== null ? user.image : '/empty_user.png'
                        }
                      />
                      <Text
                        style={{
                          width: '300px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {user.name}
                      </Text>
                    </StyledSpan2>
                    <StyledSpan2>
                      <Text
                        style={{
                          width: '120px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          textAlign: 'right',
                        }}
                      >
                        {user.position}
                      </Text>
                    </StyledSpan2>
                  </UserContainer>
                ))}
              </StoreListContainer>
            ) : (
              <MoreHorizIcon />
            )}

            <StyledText color="#e63946" weight="bold" size="22px">
              미확인
            </StyledText>
            {storeFeedback.unresponse?.length > 0 ? (
              <StoreListContainer>
                {storeFeedback.unresponse?.map((user, i) => (
                  <UserContainer key={i}>
                    <StyledSpan2>
                      <UserImage
                        src={
                          user.image !== null ? user.image : '/empty_user.png'
                        }
                      />
                      <Text
                        style={{
                          width: '300px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {user.name}
                      </Text>
                    </StyledSpan2>
                    <StyledSpan2>
                      <Text
                        style={{
                          width: '120px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          textAlign: 'right',
                        }}
                      >
                        {user.position}
                      </Text>
                    </StyledSpan2>
                  </UserContainer>
                ))}
              </StoreListContainer>
            ) : (
              <MoreHorizIcon />
            )}
          </Wrapper>
          <ButtonContainer>
            <StyledButton onClick={handleClose}>확인</StyledButton>
          </ButtonContainer>
        </Container>
      </MuiModal>
    );
  }

  return (
    <MuiModal open={open} onClose={handleClose}>
      <Container>
        <Wrapper>
          <StyledText weight="bold" size="24px">
            아무도 확인하지 않았습니다.
          </StyledText>
        </Wrapper>
        <ButtonContainer>
          <div></div>
          <div>
            <StyledButton onClick={handleClose}>확인</StyledButton>
          </div>
        </ButtonContainer>
      </Container>
    </MuiModal>
  );
};

export default StoreNoticeFeedback;
