import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledTableContent = styled.div`
  border-radius: 5px;
  /* border-color: ${({theme}) => theme.colors.gray_3}; */
  flex: 1;
  display: flex;
  flex-direction: column;
  // align-items: center;
  // padding: 0 45px;
  min-height: 500px;
  margin-bottom: 150px;
`;
const TableContent = ({ children, className }) => (
  <StyledTableContent className={className}>{children}</StyledTableContent>
);
TableContent.propTypes = {
  children: PropTypes.any.isRequired,
};

export default TableContent;
