/* eslint-disable no-unused-vars */
import React, {useEffect, useState, useCallback} from 'react';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import theme from 'shared/styles/theme';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import {Text} from 'shared/components/index';
import MasterRequest from './MasterRequest';
import {
  selectRequest,
  setCurrentRequestPage,
} from 'owler/utils/slices/requestSlice';

const NoNotice = styled.div`
  width: 1000px;
  margin-left: 85px;
  height: 350px;
  display: flex;
  align-items: center;
`;
const YesNotice = styled.div`
  min-height: 460px;
  margin-top: -15px;
`;
const PaginationContainer = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-left: 50px;
`;

const MasterTasksWrapper = styled.div`
  width: 100%;
  margin-bottom: 30px;
  margin-top: 10px;
`;
const CustomPagination = styled(Pagination)`
  & .MuiPaginationItem-page.Mui-selected {
    background-color: ${theme.colors.kalsPurple};
  }
  & .MuiPaginationItem-page.Mui-active {
    background-color: ${theme.colors.kalsPurple};
  }
  & .MuiPaginationItem-page:hover {
    background-color: ${theme.colors.kalsPurple}; // 원하는 색상으로 수정하세요.
  }
  & .MuiPaginationItem-page.Mui-selected:hover {
    background-color: #8f77d9; // 원하는 색상으로 수정하세요.
  }
`;
const RequestList = ({currentRequestPage, requestList}) => {
  const dispatch = useDispatch();
  const {requestPageCount} = useSelector(selectRequest);

  const count = () => {
    return requestPageCount !== null ? Math.ceil(requestPageCount / 10) : 1;
  };

  const handlePaginationChange = (e, p) => {
    dispatch(setCurrentRequestPage(p));
  };

  const Requests = ({page}) => {
    return (
      <>
        {requestList?.length === 0 ? (
          <NoNotice>
            <Text style={{fontSize: '18px', color: '#bdbdbd'}}>
              접수된 건의/요청 사항이 존재하지 않았습니다.
            </Text>
          </NoNotice>
        ) : (
          <YesNotice>
            {requestList?.map((request) => {
              return (
                <MasterRequest
                  key={request.request_uid}
                  request={request}
                  page={page}
                />
              );
            })}
          </YesNotice>
        )}
      </>
    );
  };

  return (
    <>
      <MasterTasksWrapper>
        <Requests page={currentRequestPage} />

        {requestList?.length > 0 ? (
          <PaginationContainer>
            <Stack spacing={2}>
              <CustomPagination
                page={currentRequestPage}
                onChange={handlePaginationChange}
                count={count()}
                color="primary"
              />
            </Stack>
          </PaginationContainer>
        ) : (
          <></>
        )}
      </MasterTasksWrapper>
    </>
  );
};

export default RequestList;
