import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledPopup = styled.div`
  display: ${({ open }) => (open ? 'flex' : 'none')};
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 125px;
  height: 80px;
  flex-direction: column;
  border: 1px solid #b1b1b1;
  color: ${({ theme }) => theme.colors.black};
  border-radius: 10px;
  text-align: left;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  & > * {
    padding: 8px 5px;
    &:hover {
      cursor: pointer;
      opacity: 0.6;
    }
  }
  & > :not(:last-child) {
    border-bottom: 1px solid #c3c4cc;
  }
`;
const Popup = ({ children, position, open }) => {
  return (
    <StyledPopup open={open} style={position}>
      {children}
    </StyledPopup>
  );
};
Popup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]).isRequired,
  position: PropTypes.shape({
    top: PropTypes.string,
    bottom: PropTypes.string,
    left: PropTypes.string,
    right: PropTypes.string,
  }).isRequired,
  open: PropTypes.bool.isRequired,
};
export default Popup;
