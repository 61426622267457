import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';

const avatarSize = {
  SMALL: 'sm',
  MEDIUM: 'md',
  LARGE: 'lg',
};

const AvatarWrapper = styled.div`
  border-radius: 50%;
  /* background-color: #bfbfbf; */
  margin: auto 0;
  ${({size}) => {
    switch (size) {
      case avatarSize.SMALL:
        return css`
          width: 20px;
          height: 20px;
        `;
      case avatarSize.MEDIUM:
        return css`
          width: 30px;
          height: 30px;
        `;
      case avatarSize.LARGE:
        return css`
          width: 50px;
          height: 50px;
        `;
      default:
        return css`
          width: 42px;
          height: 42px;
        `;
    }
  }}
  & .avatar {
    width: 100%;
    height: 100%;
  }
`;

const Avatar = props => {
  const {size, src} = props;
  return <AvatarWrapper size={size}>{src}</AvatarWrapper>;
};

Avatar.propTypes = {
  size: PropTypes.string,
  src: PropTypes.element,
};

Avatar.defaultProps = {
  size: 'base',
  src: null,
};
export default Avatar;
