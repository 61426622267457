import {Text, FranchiseDrawer, FranchiseWrapper} from 'shared/components/index';
import theme from 'shared/styles/theme';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import {useEffect, useState} from 'react';

const Container = styled.div`
  margin-left: 60px;
  width: 650px;
`;

const StyledButton = styled(Button)`
  && {
    background-color: ${({backgroundcolor}) => backgroundcolor} !important;
  }
`;
const StyledInput = styled.input`
  width: 85%;
  height: 45px;
  border-radius: 12px;
  margin-bottom: 20px;
  border: 1px solid ${theme.colors.grey3};
  padding: 0 15px;
`;
const OfferUser = styled.div`
  width: 100%;
  height: 60px;
  border-radius: 10px;
  border: 1px solid black;
  display: flex;
  align-items: center;
  padding: 0 15px;
  justify-content: space-between;
`;
const PositionManagementTemplate = ({
  offerList,
  input,
  setIput,
  searchNumber,
  joinSvMemeber,
}) => {
  const [isFirst, setIsFirst] = useState(true);
  const [changeNum, setChangeNum] = useState(
    offerList?.phone && '0' + (offerList?.phone).substr(3),
  );

  useEffect(() => {
    setChangeNum(offerList?.phone && '0' + (offerList?.phone).substr(3));
  }, [offerList]);

  const handleSearch = () => {
    searchNumber();
    setIsFirst(false);
  };

  return (
    <FranchiseWrapper titleText="Settings">
      <FranchiseDrawer>
        <Container>
          <div style={{margin: '0 0 20px 15px'}}>
            <Text bold fontSize="large">
              직원 합류
            </Text>
          </div>
          <StyledInput
            placeholder="번호로 검색하세요. 예)010 1234 5678"
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleSearch();
              }
            }}
            value={input}
            maxLength="13"
            onChange={(e) => {
              const rawValue = e.target.value.replace(/\D/g, ''); // 숫자만 추출
              let formattedValue;

              if (rawValue.length <= 3) {
                formattedValue = rawValue;
              } else if (rawValue.length <= 7) {
                formattedValue = rawValue.slice(0, 3) + ' ' + rawValue.slice(3);
              } else {
                formattedValue =
                  rawValue.slice(0, 3) +
                  ' ' +
                  rawValue.slice(3, 7) +
                  ' ' +
                  rawValue.slice(7);
              }
              setIput(formattedValue);
            }}
          />
          <StyledButton
            backgroundcolor={theme.colors.kalsPurple}
            sx={{
              color: 'white',
              fontWeight: '600',
              fontSize: '20px',
              height: '46px',
              margin: '-6px 0 0 2%',
              borderRadius: '12px',
              width: '13%',
            }}
            onClick={() => handleSearch()}
          >
            조회
          </StyledButton>

          {offerList ? (
            <OfferUser>
              <div>
                <Text margin="0 0 0 5px" fontSize="large" bold>
                  {offerList?.name}
                </Text>
                <div>
                  <Text>
                    {changeNum}/{offerList?.email}
                  </Text>
                </div>
              </div>
              <StyledButton
                backgroundcolor={theme.colors.kalsPurple}
                sx={{
                  color: 'white',
                  fontWeight: '500',
                  fontSize: '18px',
                  height: '40px',
                  borderRadius: '10px',
                }}
                onClick={() => joinSvMemeber(offerList.user_uid)}
              >
                합류
              </StyledButton>
            </OfferUser>
          ) : (
            <div>
              {isFirst === true
                ? ''
                : `해당 연락처로 가입한 유저 정보가 존재하지 않거나 이미 합류되어
              있습니다.`}
            </div>
          )}
        </Container>
      </FranchiseDrawer>
    </FranchiseWrapper>
  );
};

export default PositionManagementTemplate;
