import React, {useCallback, useState} from 'react';
import TaskAddTemplate from './template';
import {useLocation, useNavigate} from 'react-router-dom';
import {toISOString} from 'shared/utils/time';
import {useDispatch, useSelector} from 'react-redux';
import {selectStore} from 'owler/utils/slices/storeSlice';
import {useTranslation} from 'react-i18next';
import {
  editTaskAsync,
  getStoreTasksByDateAsync,
  selectTask,
  deleteTaskAsync,
  setDeleteTaskModal,
} from 'owler/utils/slices/taskSlice';

const TaskEdit = () => {
  const {currentStore} = useSelector(selectStore);
  const {
    state: {id, name: categoryName, task_uid: task_uid},
  } = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {selectedTask} = useSelector(selectTask);
  const {forTaskDetail} = useSelector(selectTask);
  const [loading, setLoading] = useState(false);
  const {t} = useTranslation();

  const onSubmit = useCallback(async (value) => {
    let {
      name,
      repetition,
      task_type,
      start,
      end,
      description,
      description_images,
      notify,
    } = value;

    // Formatting repetition
    if (repetition === '없음') repetition = 0;
    else if (repetition === '주 1회') repetition = 6;
    else if (repetition === '월 1회') repetition = 3;
    else if (repetition === '일') repetition = 4;
    else if (repetition === '주') {
      repetition = 5; //2
    } else if (repetition === '매일') repetition = 1;
    else repetition = 0;

    // format task_type
    if (task_type === '사진') {
      task_type = 'image';
    } else if (task_type === '메모') {
      task_type = 'memo';
    } else {
      task_type = 'check';
    }
    // formatTime
    const startDate = new Date();
    startDate.setHours(...start.split(':'));
    const endDate = new Date();
    endDate.setHours(...end.split(':'));
    start = toISOString(startDate);
    end = toISOString(endDate);

    // format description
    if (description === '' || description === ' ' || description === '\n')
      description = null;

    try {
      const formData = new FormData();

      // Append fields to formData
      formData.append('task_category_uid', id);
      formData.append('task_uid', selectedTask.task_uid);
      formData.append('name', name);
      formData.append('repetition', repetition);
      formData.append('task_type', task_type);
      formData.append('start', start);
      formData.append('end', end);

      if (description) {
        formData.append('description', description);
      }
      // Append image files to formData
      if (description_images?.length) {
        await Promise.all(
          description_images.map(async (image, index) => {
            let fileName;
            let fileExtension;
            let imageUrl;
            let data;
            if (image instanceof File) {
              imageUrl = URL.createObjectURL(image);
              fileName = image.name;
              fileExtension = image.type;
              data = image;
            } else {
              imageUrl = image;
              const originalFileExtension = imageUrl.substring(
                imageUrl.lastIndexOf('.') + 1,
              );
              fileExtension =
                originalFileExtension === 'jpg'
                  ? 'image/jpeg'
                  : `image/${originalFileExtension}`;
              fileName =
                imageUrl.substring(
                  imageUrl.lastIndexOf('/') + 1,
                  imageUrl.lastIndexOf('.'),
                ) +
                '.' +
                originalFileExtension;

              const response = await fetch(imageUrl, {
                method: 'GET',
                headers: {
                  'Cache-Control': 'no-cache',

                  // 여기에 필요한 다른 헤더들도 추가할 수 있습니다.
                },
              });
              data = await response.blob();
            }
            formData.append(
              'images',
              new File([data], fileName, {type: fileExtension}),
            );

            if (image instanceof File) {
              URL.revokeObjectURL(imageUrl);
            }
          }),
        );
      } else {
        formData.append('images', JSON.stringify([]));
      }

      formData.append('is_push', notify);

      // really post the task to the list:
      setLoading(true);

      dispatch(editTaskAsync(formData))
        .unwrap()
        .then(() => {
          setLoading(false);

          alert(t('alerts.done_editing'));

          const selectedDate = new Date();
          const year = selectedDate.getFullYear();
          const month = (selectedDate.getMonth() + 1)
            .toString()
            .padStart(2, '0'); // 월을 0으로 패딩
          const day = selectedDate.getDate().toString().padStart(2, '0'); // 일을 0으로 패딩
          const date = `${year}-${month}-${day}`;
          dispatch(
            getStoreTasksByDateAsync({
              store_uid: currentStore.store_uid,
              date,
            }),
          );

          navigate(-2);
        })
        .catch((err) => {
          alert(err);
          setLoading(false);
        });
    } catch (e) {
      alert(e.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onValidate = useCallback((value) => {
    const errors = {};
    const reg = new RegExp(/^([01][0-9]|2[0-3]):([0-5][0-9])$/, 'i');
    if (!value.name) errors.name = '업무명을 입력해주세요';
    else if (value.name.length > 100)
      errors.name = '문자 길이가 100을 초과할 수 없습니다';
    // start
    if (!value.start) errors.start = '시작하는 날짜를 입력해주세요';
    else if (!reg.test(value.start))
      errors.start = '날짜형식은 HH:MM 이어야합니다.';
    let timeS = value.start.substring(0, 2);
    let timeE = value.end.substring(0, 2);

    if (timeS >= 24) errors.start = '시간은 23:59까지 기입 가능합니다';
    // end
    if (!value.end) errors.end = '끝나는 날짜를 입력해주세요';
    else if (!reg.test(value.end))
      errors.end = '날짜형식은 HH:MM 이어야합니다.';
    if (timeE >= 24) errors.end = '시간은 23:59까지 기입 가능합니다';
    if (value.start > value.end && value.start !== '' && value.end !== '') {
      errors.start = '시작시간보다 끝 시간이 더 빠를수 없습니다.';
      errors.end = '시작시간보다 끝 시간이 더 빠를수 없습니다.';
    }
    if (value.start === value.end)
      errors.end = '시작 시간과 끝 시간이 같습니다';
    // if (!value.repetition) errors.repetition = 'ㅠㅠ';
    if (!value.task_type) errors.task_type = 'ㅠㅠ';
    return errors;
  }, []);

  const handleDeleteBtnClick = useCallback(
    async (_selectedTask, _option) => {
      if (!window.confirm('정말로 삭제하시겠습니까?')) return;
      dispatch(
        deleteTaskAsync({
          task_uid: task_uid,
          option: _option,
        }),
      )
        .unwrap()
        .then(() => {
          alert(t('alerts.task_deleted_success'));
          navigate(-2);
          dispatch(setDeleteTaskModal(false));
        })
        .catch((err) => alert(err.message));
    },
    [dispatch, navigate, t],
  );
  const handleGoback = () => {
    navigate(-1);
  };

  return (
    <>
      <TaskAddTemplate
        categoryName={categoryName}
        storeName={currentStore.storeName}
        onSubmit={onSubmit}
        onValidate={onValidate}
        loading={loading}
        forTaskDetail={forTaskDetail}
        handleDeleteBtnClick={handleDeleteBtnClick}
        handleGoback={handleGoback}
      />
      ;
    </>
  );
};

export default TaskEdit;
