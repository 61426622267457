import styled from 'styled-components';
import theme from 'shared/styles/theme';
import {Text} from 'shared/components/index';

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 1050px;
  margin: 20px 0 0 0;
  align-items: center;
`;

const TheTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed; /* 고정 테이블 레이아웃 사용 */
  border: 2px solid ${theme.colors.grey3};
  font-size: 15px;
  text-align: center;

  th,
  td {
    height: 32px;
    border: 2px solid ${theme.colors.grey3};
  }

  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  th {
    background-color: #dfd7f3;
    font-weight: 600;
  }
`;

const PdfTitleArea = ({month, year, commuteLog}) => {
  return (
    <TableWrapper>
      <Text fontSize="huge" margin="0 0 12px 0">
        {year} 년도 {month} 월 출퇴근 기록부
      </Text>
      <TheTable border="1">
        <thead>
          <tr>
            <th>소속</th>
            <td colSpan="2" className="truncate">
              {commuteLog?.store_name}
            </td>
            <th>직급</th>
            <td colSpan="2" className="truncate">
              {commuteLog?.position}
            </td>
            <th>성명</th>
            <td colSpan="2" className="truncate">
              {commuteLog?.name}
            </td>
          </tr>
        </thead>
      </TheTable>
    </TableWrapper>
  );
};
export default PdfTitleArea;
