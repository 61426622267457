/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {
  selectStore,
  setCurrentStore,
  setStoreBrandList,
  getStoreBrandListAsync,
} from 'owler/utils/slices/storeSlice';
import CorporationTemplate from './template';

const Corporation = () => {
  const dispatch = useDispatch();
  const {corpCurrentStore, userStores} = useSelector(selectStore);
  const navigate = useNavigate();

  useEffect(() => {
    if (corpCurrentStore) {
      dispatch(
        getStoreBrandListAsync({
          store_uid: corpCurrentStore.store_uid,
        }),
      )
        .unwrap()
        .then((payload) => {
          dispatch(setStoreBrandList(payload));
        });
    }
  }, [corpCurrentStore]);

  const handleStoreClick = useCallback(
    (_store) => {
      dispatch(setCurrentStore(_store));
    },
    [dispatch],
  );

  const handleBrandStoreClick = useCallback(
    (_store) => {
      userStores.map((store) => {
        if (store.store_uid === _store.brand_store_uid) {
          dispatch(setCurrentStore(store));
          navigate(`/owler/store/${_store.brand_store_uid}`);
        }
      });
    },
    [dispatch],
  );

  return (
    <CorporationTemplate
      corpCurrentStore={corpCurrentStore}
      handleStoreClick={handleStoreClick}
      handleBrandStoreClick={handleBrandStoreClick}
    />
  );
};

export default Corporation;
