import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled, {css} from 'styled-components';
import {MuiModal, Text} from 'shared/components/index';
import CloseIcon from '@mui/icons-material/Close';
import {
  selectCalendar,
  setEventAddModal,
  setFranchiseListModal,
  setCategoryAddModal,
  getFranchiseListAsync,
  setCurrentFrachiseList,
  setNoticeLinkAddmodal,
  deleteExtraNoticeList,
  setExtraNoticeList,
  setdirectStoreData,
  setnoDirectStoreData,
  setDirectGroup,
  setNoDirectGroup,
} from 'owler/utils/slices/calendarSlice';
import theme from 'shared/styles/theme';
import DatePicker from 'react-datepicker';
import {ko} from 'date-fns/esm/locale';
import InputDateStyle from 'shared/styles/datePickerStyle';
import {useForm, Controller} from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import ColorSelectModal from './ColorSelectModal';
import Button from '@mui/material/Button';
import FranchiseListModal from './FranchiseListModal';
import AddNoticeModal from './AddNoticeModal';

const Container = styled.div`
  background-color: white;
  width: 580px;
  max-height: 610px;
  min-height: 580px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0px 3px 3px 3px #6e6e6e;
  margin-top: 20%;
`;
const BtnWrapper = styled.button`
  border: none;
  background-color: white;
  cursor: pointer;
  position: relative;
  top: 0px;
  left: 5px;
`;
const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
`;
const FormWrapper = styled.form`
  width: 100%;
  padding: 15px 10px;
  //background-color: #f2f2f2;
  height: 540px;
  border-radius: 20px;

  > * {
    margin-bottom: 20px;
  }
`;
const FieldWrapper = styled.div`
  display: flex;
  flex-direction: ${({container}) => (container ? 'row' : 'column')};
  &:not(:last-child) {
    margin-right: 30px;
  }
  width: ${({width}) => width || '100%'};
  ${({center}) =>
    center &&
    css`
      margin: 0 auto;
    `}
`;
const StyledInput = styled.input`
  border-radius: 5px;
  border: 1px solid ${theme.colors.grey6};
  padding: 0 5px 2px 5px;
  width: 92.5%;
`;
const StyledInput2 = styled.input`
  border-radius: 5px;
  border: none;
  opacity: 0.8;
  font-weight: 500;
  padding: 0 5px 2px 5px;
  font-size: 13px;
`;
const CustomTextField = styled(TextField)``;
const DateSelector = styled.div`
  display: flex;
  font-weight: 500;
`;
const StlyedDatePicker = styled(DatePicker)`
  width: 103px;
  border-radius: 5px;
  border: none;
  opacity: 0.8;
`;
const StlyedDatePicker2 = styled(DatePicker)`
  width: 66px;
  border-radius: 5px;
  border: none;
  opacity: 0.8;
  //margin: 0 0 0 10px;
`;
const Danger = styled.div`
  color: #fa5858;
  font-size: 12px;
  font-weight: 600;
  margin-left: 5px;
  opacity: 0.8;
  height: 15px;
  min-height: 15px;
`;
const StyledButton = styled.input`
  border: none;
  width: 70px;
  border-radius: 4px;

  margin-top: 5px;
  font-size: 15px;
  height: 25px;
  &:hover {
    cursor: pointer;
  }
  &:active {
    opacity: 0.8;
  }
  color: white;
  background-color: ${({color}) =>
    color ? color : `${theme.colors.kalsPurple}`};
`;
const ButtonWrapper = styled.div``;
const ButtonWrapper2 = styled.div`
  display: flex;
  > * {
    margin: 0 0 0 10px;
  }
`;
const StyledButton2 = styled(Button)``;
const ExtraNoticeWrapper = styled.div`
  height: 50px;
  min-height: 50px;
  max-height: 50px;
  overflow: auto;
`;
const Notice = styled.div`
  width: 100%;
  margin: 6px 0;
  display: flex;
  justify-content: space-between;
`;
const NoticeTitle = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const EventAddModal = ({
  currentStore,
  masterNoticeList,
  extraNoticeList,
  handleEventSubmit,
}) => {
  const {
    selectedDate,
    directGroup,
    noDirectGroup,
    eventAddModal,
    currentFrachiseList,
  } = useSelector(selectCalendar);

  const dispatch = useDispatch();
  const [color, setColor] = useState(`${theme.colors.kalsPurple}`);
  const now = new Date(selectedDate);
  const ceilHour = new Date(now);
  ceilHour.setMinutes(0); // 분을 0으로 설정
  ceilHour.setHours(ceilHour.getHours() + (now.getMinutes() > 0 ? 1 : 0)); // 만약 분이 0보다 크면 시간을 1 추가
  const endCeilHour = new Date(now);
  endCeilHour.setMinutes(0); // 분을 0으로 설정
  endCeilHour.setHours(endCeilHour.getHours() + (now.getMinutes() > 0 ? 2 : 1)); // 만약 분이 0보다 크면 시간을 1 추가
  const start = ceilHour;
  const end = endCeilHour;
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: {errors},
  } = useForm();
  const watchStart = watch('start', start);
  const [checked, setChecked] = useState(false);

  const start2 = new Date(now);
  start2.setHours(0, 0, 0, 0);
  const end2 = new Date(now);
  end2.setHours(23, 59, 59, 999);

  const onSubmit = (data) => {
    let payload = {
      ...data,
      color: color, // 추가
    };
    if (checked === false) {
      payload = {
        ...payload,
        allDay: true,
      };
    }

    handleEventSubmit(payload);
  };

  const handleModalClose = () => {
    dispatch(setEventAddModal(false));
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    if (checked === false) {
      setValue('start', start2);
      setValue('end', end2);
    }
    if (checked === true) {
      setValue('end', endCeilHour);
    }
  }, [checked]);

  useEffect(() => {
    dispatch(setExtraNoticeList(null));
    dispatch(
      setdirectStoreData({
        all: false,
        stores: [],
        names: [],
      }),
    );
    dispatch(
      setnoDirectStoreData({
        all: false,
        stores: [],
        names: [],
      }),
    );
    dispatch(getFranchiseListAsync({store_uid: currentStore.store_uid}))
      .unwrap()
      .then((payload) => {
        dispatch(setCurrentFrachiseList(payload));
      });
  }, [dispatch]);

  useEffect(() => {
    const direct = [];
    const noDirect = [];
    currentFrachiseList.forEach((item) => {
      if (item.is_direct) {
        direct.push(item);
      } else {
        noDirect.push(item);
      }
    });
    dispatch(setDirectGroup(direct));
    dispatch(setNoDirectGroup(noDirect));
  }, [currentFrachiseList, dispatch]);

  useEffect(() => {
    const direct_store_list = directGroup?.map(
      (eachstore) => eachstore.franchise_store_uid,
    );
    const direct_branch_name = directGroup?.map(
      (eachStore) => eachStore.branch_name,
    );
    const nodirect_store_list = noDirectGroup?.map(
      (eachstore) => eachstore.franchise_store_uid,
    );
    const nodirect_branch_name = noDirectGroup?.map(
      (eachStore) => eachStore.branch_name,
    );

    dispatch(
      setdirectStoreData({
        all: true,
        stores: direct_store_list,
        names: direct_branch_name,
      }),
    );
    dispatch(
      setnoDirectStoreData({
        all: true,
        stores: nodirect_store_list,
        names: nodirect_branch_name,
      }),
    );
  }, [directGroup, noDirectGroup, dispatch]);

  return (
    <MuiModal open={eventAddModal} onClose={handleModalClose}>
      <Container>
        <TitleWrapper>
          <Text bold margin="0 0 0px 20px" fontSize="large">
            일정 생성
          </Text>
          <BtnWrapper onClick={handleModalClose}>
            <CloseIcon />
          </BtnWrapper>
        </TitleWrapper>

        <FormWrapper onSubmit={handleSubmit(onSubmit)}>
          <FieldWrapper>
            <div
              style={{
                display: 'flex',
                width: '92%',
                justifyContent: 'space-between',
              }}
            >
              <Text bold margin="0 0 5px 10px">
                제목
              </Text>
              <ButtonWrapper>
                {currentStore.is_hq && (
                  <StyledButton2
                    sx={{
                      border: `1px solid ${theme.colors.grey3}`,
                      lineHeight: 1.5,
                      color: 'black',
                      borderRadius: '5px',
                      height: '20px!important',
                    }}
                    size="small"
                    onClick={() => {
                      dispatch(setFranchiseListModal(true));
                    }}
                  >
                    매장 선택
                  </StyledButton2>
                )}
              </ButtonWrapper>
            </div>
            <div>
              <Controller
                rules={{
                  required: '이벤트명을 입력해주세요',
                }}
                render={({field}) => (
                  <StyledInput placeholder="제목을 입력해주세요" {...field} />
                )}
                control={control}
                name="title"
              />
              <StyledButton2
                onClick={() => dispatch(setCategoryAddModal(true))}
                sx={{
                  backgroundColor: color,
                  color: 'white',
                  margin: '0px 0px 5px 10px',
                  borderRadius: '20px',
                  height: '25px!important',
                  width: '25px!important',
                  minWidth: '25px!important',
                  '&:hover': {backgroundColor: color, opacity: '0.9'},
                  '&:active': {backgroundColor: color},
                }}
                size="small"
              />
            </div>

            <Danger>{errors.title && errors.title.message}</Danger>
          </FieldWrapper>

          <ColorSelectModal color={color} setColor={setColor} />
          <FranchiseListModal />
          <FieldWrapper>
            <Text bold margin="0 0 5px 10px">
              날짜
            </Text>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              {checked === true ? (
                <DateSelector>
                  <InputDateStyle>
                    <Controller
                      rules={{
                        required: '시작 날짜를 입력해주세요',
                      }}
                      render={({field}) => (
                        <StlyedDatePicker
                          showTimeSelect
                          locale={ko}
                          selected={field.value}
                          onChange={(date) => field.onChange(date)}
                          dateFormat="MM월 dd일 HH:mm"
                          customInput={<StyledInput2 />}
                        />
                      )}
                      control={control}
                      name="start"
                      defaultValue={start}
                    />
                    ~
                    <Controller
                      rules={{
                        required: '끝나는 날짜를 입력해주세요',
                        validate: {
                          afterStart: (value) =>
                            value > watchStart ||
                            '시작 날짜보다 끝 날짜가 빠르거나 같을 수 없습니다.',
                        },
                      }}
                      render={({field}) => (
                        <StlyedDatePicker
                          showTimeSelect
                          locale={ko}
                          selected={field.value}
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          dateFormat="MM월 dd일 HH:mm"
                          customInput={<StyledInput2 />}
                        />
                      )}
                      control={control}
                      name="end"
                      defaultValue={end}
                    />
                  </InputDateStyle>
                </DateSelector>
              ) : (
                <InputDateStyle>
                  <Controller
                    rules={{
                      required: '날짜를 입력해주세요',
                    }}
                    render={({field}) => (
                      <StlyedDatePicker2
                        locale={ko}
                        selected={field.value}
                        onChange={(date) => field.onChange(date)}
                        dateFormat="MM월 dd일"
                        customInput={<StyledInput2 />}
                      />
                    )}
                    control={control}
                    name="start"
                    defaultValue={start2}
                  />
                  ~
                  <Controller
                    rules={{
                      required: '날짜를 입력해주세요',
                      validate: {
                        afterStart: (value) =>
                          value > watch('start') ||
                          '시작 날짜보다 끝 날짜가 빠를 수 없습니다.',
                      },
                    }}
                    render={({field}) => (
                      <StlyedDatePicker2
                        locale={ko}
                        selected={field.value}
                        onChange={(date) => field.onChange(date)}
                        dateFormat="MM월 dd일"
                        customInput={<StyledInput2 />}
                      />
                    )}
                    control={control}
                    name="end"
                    defaultValue={end2}
                  />
                </InputDateStyle>
              )}
              <FormControlLabel
                sx={{width: '110px', margin: '-5px 0 0 0'}}
                control={
                  <Checkbox
                    size="small"
                    checked={checked}
                    onChange={handleChange}
                    sx={{'&.Mui-checked': {color: '#2E9AFE'}}}
                  />
                }
                labelPlacement="start"
                label="시간 설정"
              />
            </div>
            <Danger>{errors.end && errors.end.message}</Danger>
          </FieldWrapper>

          <FieldWrapper>
            <Text bold margin="0 0 5px 10px">
              내용
            </Text>

            <Controller
              // rules={{
              //     required: '내용을 입력해주세요'
              // }}
              render={({field}) => (
                <CustomTextField
                  multiline
                  fullWidth
                  rows={5}
                  size="small"
                  placeholder="내용을 입력해주세요"
                  {...field}
                />
              )}
              control={control}
              name="contents"
            />
          </FieldWrapper>
          <FieldWrapper>
            <div>
              <Text bold margin="0 0 0px 10px">
                공지 링크
              </Text>
              <Button
                onClick={() => dispatch(setNoticeLinkAddmodal(true))}
                variant="text"
                style={{
                  width: '68px',
                  border: `1px solid ${theme.colors.grey3}`,
                  height: '23px',
                  padding: '0px',
                  margin: '0 0 3px 20px',
                  color: 'black',
                }}
              >
                공지 선택
              </Button>
            </div>
            <ExtraNoticeWrapper>
              {extraNoticeList?.length > 0 ? (
                extraNoticeList?.map((selectedNotice) => {
                  return (
                    <Notice
                      style={{margin: '0'}}
                      key={selectedNotice.notice_uid}
                    >
                      <NoticeTitle
                        style={{width: '400px', margin: '0 0 0 10px'}}
                      >
                        {selectedNotice.notice_title}
                      </NoticeTitle>
                      <Button
                        onClick={() =>
                          dispatch(deleteExtraNoticeList(selectedNotice))
                        }
                        sx={{width: '20px!important', height: '20px'}}
                      >
                        <CloseIcon style={{fontSize: '18px', color: 'black'}} />
                      </Button>
                    </Notice>
                  );
                })
              ) : (
                <NoticeTitle
                  style={{
                    width: '400px',
                    margin: '0 0 0 8px',
                    fontSize: '14px',
                    color: `${theme.colors.grey3}`,
                  }}
                >
                  등록된 공지 링크가 없습니다.
                </NoticeTitle>
              )}
            </ExtraNoticeWrapper>
          </FieldWrapper>
          <FieldWrapper
            style={{
              width: '98%',
              marginBottom: '0px!important',
              justifyContent: 'space-between',
            }}
            container
          >
            <div></div>
            <ButtonWrapper2>
              <StyledButton
                type="button"
                value="취소"
                onClick={handleModalClose}
                color="#bdbdbd"
              />
              <StyledButton type="submit" value="생성" />
            </ButtonWrapper2>
          </FieldWrapper>
          <AddNoticeModal masterNoticeList={masterNoticeList} />
        </FormWrapper>
      </Container>
    </MuiModal>
  );
};
export default EventAddModal;
