import React from 'react';
import {Text} from 'shared/components/index';
import theme from 'shared/styles/theme';
import styled from 'styled-components';
import {
  VictoryChart,
  VictoryBar,
  VictoryGroup,
  VictoryAxis,
  VictoryLabel,
} from 'victory';

const StyledWrapper = styled.div`
  height: 100%;
  width: 100%;
  //background-color: #8b77d9;
  padding: 10px;
  border-radius: 10px;
`;

const CardTitleWrap = styled.div`
  width: 100%;
  height: 50px;
`;

const StyledGraphWrapper = styled.div`
  margin-top: -80px;
  position: relative;
`;

const Description = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 40px;
  right: 5px;
  margin-top: ${(props) => props.marginTop}px;
  span {
    color: black;
    font-size: 10px;
    margin-left: 6px;
  }
`;

const ColorBox = styled.div`
  width: 17px;
  height: 12px;
  border-radius: 10px;
  background-color: ${(props) => props.color};
`;

const WeeklyPerformance = ({lastWeekGraphData, thisWeekGraphData}) => {
  const koreanWeekLabels = ['일', '월', '화', '수', '목', '금', '토'];

  return (
    <StyledWrapper>
      <CardTitleWrap></CardTitleWrap>

      <StyledGraphWrapper>
        <VictoryChart
          height={260}
          domainPadding={{x: 15, y: 0}}
          domain={{x: [-0.05, 6.07]}}
        >
          <VictoryAxis
            style={{
              tickLabels: {fill: 'black'},
              //axis: {stroke: 'transparent'},
            }}
            tickValues={thisWeekGraphData.map((_, idx) => idx)}
            tickFormat={koreanWeekLabels}
            tickLabelComponent={<VictoryLabel dx={0} />}
          />
          <VictoryAxis
            dependentAxis
            domain={[1, 100]}
            style={{
              tickLabels: {fill: 'black'},
              axis: {stroke: 'transparent'},
            }}
            tickLabelComponent={<VictoryLabel dx={-20} />}
          />
          <VictoryGroup
            offset={7}
            style={{data: {width: 5}}}
            colorScale={['rgba(139, 119, 217, 0.5)', '#8b77d9']}
          >
            <VictoryBar data={lastWeekGraphData} cornerRadius={3} />
            <VictoryBar data={thisWeekGraphData} cornerRadius={3} />
          </VictoryGroup>
        </VictoryChart>

        {/* Description  */}
        <Description marginTop={0}>
          <ColorBox color="rgba(139, 119, 217, 0.5)" />
          <span>저번주</span>
        </Description>
        <Description marginTop={22}>
          <ColorBox color="#8b77d9" />
          <span>이번주</span>
        </Description>
      </StyledGraphWrapper>
    </StyledWrapper>
  );
};

export default WeeklyPerformance;
