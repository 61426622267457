import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import QSCHistoryTemplate from './template';
import api from 'shared/utils/api';
import {useDispatch} from 'react-redux';
import {setQSCUID} from 'franchise/utils/slices/frQscSlice';

const QSCHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [qscHistoryList, setQscHistoryList] = useState([]);

  const {
    state: {id},
  } = useLocation();

  const getFullQSCHistory = useMemo(
    () => async () => {
      try {
        const response = await api.getFullQSCHistory(id);
        const {results} = response.data;

        setQscHistoryList(results);
      } catch (e) {}
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    getFullQSCHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleViewQscDetail = useCallback(
    (_qscDetail) => {
      const {qsc_uid} = _qscDetail;
      dispatch(setQSCUID(qsc_uid));

      navigate(`/franchise/qscDetail/${qsc_uid}`);
    },
    [dispatch, navigate],
  );

  // Go back to chat room list
  const handleListBtnClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <>
      <QSCHistoryTemplate
        handleListBtnClick={handleListBtnClick}
        qscHistoryList={qscHistoryList}
        handleViewQscDetail={handleViewQscDetail}
      />
    </>
  );
};

export default QSCHistory;
