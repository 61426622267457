import React from 'react';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import {MuiModal,Text} from 'shared/components/index';
import {useDispatch, useSelector} from 'react-redux';
import { 
    selectPosition,
    setPositionDetailModalOpen,
    setMemberPositionChangeModalOpen, 
} from 'owler/utils/slices/positionSlice';
import Button from '@mui/material/Button';
import theme from 'shared/styles/theme';

const Members = styled.div`
    width: 100%;
    display: flex;
    flex-direction:column;
    margin: 0 0 0 0px;
    padding: 10px 10px;
    background-color: #D8D8D8;
    border-radius: 40px;
    &:hover{
        cursor:pointer;
    }
    text-align:center;
`
const ButtonWrap = styled.div`
    display:flex;
    margin-top: 25px;
    margin-left: -40px;
`
const StyledButton = styled(Button)`
  && {
    background-color: ${({backgroundcolor}) => backgroundcolor} !important;
  }
`;
const Img = styled.img`
    width: 120px;
    height: 120px;
    border-radius: 100px;
    object-fit: cover;
    margin: 0 0 0 10px;
    background-color: white;
`
const Container = styled.div`
  background-color: white;
  width: 200px;
  height: 320px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #D8D8D8;
  border-radius: 25px;
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0px;
`;
const BtnWrapper = styled.button`
  border: none;
  background-color: #D8D8D8;
  margin-left: 20px;
  cursor: pointer;
`;
const PositionDetailModal = ({selected,fireMember}) => {
  const dispatch = useDispatch();
  const {positionDetailModalOpen} = useSelector(selectPosition);

  const handleModalClose = () => {
    dispatch(setPositionDetailModalOpen(false));
  };
  return (
    <MuiModal open={positionDetailModalOpen} onClose={handleModalClose}>
        <Container>
        <TitleWrapper>
          <BtnWrapper onClick={handleModalClose}>
            <CloseIcon />
          </BtnWrapper>
        </TitleWrapper>

        <Members>
            <Img src={selected?.member?.image}/>
            <Text bold margin='10px 0 0 0px'>{selected?.member?.name}</Text>
            <Text margin='0px 0 0 0px'>{selected?.position}</Text>
        </Members>
        <ButtonWrap>
            <StyledButton
                backgroundcolor={theme.colors.danger}
                sx={{
                    color: 'white', 
                    fontWeight:'500',
                    fontSize: '12px',
                    height:'30px',
                    marginLeft: '40px',                        
                    borderRadius:'8px'
                    }}
                    onClick={() => fireMember(selected?.member?.member_uid)}
            >퇴사</StyledButton>
            <StyledButton
                backgroundcolor={theme.colors.kalsBlue}
                sx={{
                    color: 'white',
                    fontWeight:'500',
                    fontSize: '12px',
                    height:'30px',marginLeft: '20px',
                    borderRadius:'8px'
                    }}
                    onClick={() => 
                      {dispatch(setMemberPositionChangeModalOpen(true))
                      dispatch(setPositionDetailModalOpen())}
                    }
            >직급변경</StyledButton>
        </ButtonWrap>
      </Container>

    </MuiModal>
  );
};
export default PositionDetailModal;
