import {Text, FranchiseDrawer, FranchiseWrapper} from 'shared/components/index';

const ScheduleAddTemplate = () => {
  return (
    <FranchiseWrapper titleText="ScheduleAdd">
      <FranchiseDrawer>
        <Text>Hello ScheduleAdd!</Text>
      </FranchiseDrawer>
    </FranchiseWrapper>
  );
};

export default ScheduleAddTemplate;
