/* eslint-disable react/prop-types */
import {useFormikContext} from 'formik';
import React from 'react';

import SimpleButton from '../atoms/SimpleButton';

function SubmitBtn({text, event, ...otherProps}) {
  const {handleSubmit} = useFormikContext();
  return (
    <SimpleButton
      text={text}
      type="submit"
      onClick={
        event === undefined
          ? handleSubmit
          : () => {
              handleSubmit();
              event();
            }
      }
      {...otherProps}
    />
  );
}

export default SubmitBtn;
