import React, {useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {Text} from 'shared/components/index';
import theme from 'shared/styles/theme';
import styled, {createGlobalStyle} from 'styled-components';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Slider from 'react-slick';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {setSelectedImagesForDashBoard} from 'franchise/utils/slices/frSupplementionSlice';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CarouselContainer = styled.div`
  width: 100%;

  height: 420px;
  margin-top: 24px;
  border-top: 2px solid ${theme.colors.grey9};
`;

const OneMoreWrap = styled.div`
  // display: flex;
  // flex-direction: column;
  // align-items: flex-start;
  // justify-content: center;
  width: 100%;
  height: 450px;
  overflow: hidden;
  margin-left: 20px;
  margin-top: 0px;
`;

const CarouselItem = styled.div`
  width: 500px;
  height: auto;
  margin: 12px 5px;
  cursor: pointer;
  display: flex;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.01);
  }
  font-weight: 500;
`;

const ImageWrapper = styled.div`
  width: 52px;
  min-width: 52px;
  height: 52px;
  border-radius: 12px;
  border: 1px solid ${theme.colors.grey};
  background-color: ${theme.colors.white};
`;

const CarouselImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;
`;
const TextCenter = styled.div`
  text-align: left;

  margin-left: 15px;
`;
const StyledP = styled.p`
  font-size: 13px;
  margin-top: 16px;
  color: #bdbdbd;
`;
const StyledText = styled(Text)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 290px;
`;
const GlobalStyle = createGlobalStyle`
    .slick-dots {
      display: flex;
      justify-content: center;
      bottom: ${(props) => {
        if (props.supplementationList > 50 && props.supplementationList <= 100)
          return '-60px';
        if (props.supplementationList > 100) return '-80px';
        return '-30px';
      }};
    }
  
    .slick-dots li button:before{
      color: ${theme.colors.kalsPurple}!important;
      font-size: 8px!important;
    }
    .slick-dots li {
      background: lightgray;
      border-radius: 50%;
      margin: 0 1px;
      width:20px;
      height: 20px;
      background: transparent;  /* 배경 투명하게 */
    }
    .slick-dots li.slick-active {  // 활성화된 dot에 대한 스타일
      background: transparent;  // 배경 투명하게
    }
  `;
const StyledText2 = styled(Text)`
  position: relative;
  top: -1px;
  left: 3px;
`;
const SupplementationStatus = ({
  supplementationList,
  isImageLoading,
  setImageModal,
}) => {
  const dispatch = useDispatch();
  const sliderRef = React.useRef(null);

  const timeDifference = (dateString) => {
    const completedDate = new Date(dateString);
    const currentDate = new Date();
    const diffInSeconds = Math.floor((currentDate - completedDate) / 1000);

    if (diffInSeconds < 60) {
      return `${diffInSeconds}초 전`;
    } else if (diffInSeconds < 3600) {
      // 60 sec/min * 60 min/hour
      return `${Math.floor(diffInSeconds / 60)}분 전`;
    } else if (diffInSeconds < 86400) {
      // 60 sec/min * 60 min/hour * 24 hours/day
      return `${Math.floor(diffInSeconds / 3600)}시간 전`;
    } else {
      return `${Math.floor(diffInSeconds / 86400)}일 전`;
    }
  };

  const visibleImages = [];

  if (supplementationList?.length < 3) {
    for (let i = 0; i < supplementationList?.length; i++) {
      visibleImages?.push(supplementationList?.[i]);
    }
  } else {
    let count = 0;
    for (
      let i = 0;
      count < 5 && i < supplementationList?.length;
      i++, count++
    ) {
      visibleImages.push(supplementationList[i]);
    }
  }
  //   const doubledSupplementationList = useMemo(() => {
  //     return [...supplementationList, ...supplementationList];
  //   }, [supplementationList]);

  //   const groupedPictures = useMemo(() => {
  //     const groups = [];
  //     for (let i = 0; i < doubledSupplementationList.length; i += 5) {
  //       groups.push(doubledSupplementationList.slice(i, i + 5));
  //     }
  //     return groups;
  //   }, [doubledSupplementationList]);

  const groupedPictures = useMemo(() => {
    const groups = [];
    for (let i = 0; i < supplementationList?.length; i += 5) {
      groups.push(supplementationList?.slice(i, i + 5));
    }
    return groups;
  }, [supplementationList]);

  if (!supplementationList?.length && isImageLoading === false) {
    return (
      <CarouselContainer>
        <Text fontSize="large" margin="15px 0 10px 22px" bold>
          실시간 보완 요청 현황
        </Text>
        <div
          style={{
            width: '100%',

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <StyledP>보완된 내용이 없습니다.</StyledP>
        </div>
      </CarouselContainer>
    );
  }
  const settings = {
    dots: true,
    infinite: true,
    //speed:500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    // 슬라이드 변경 시간
    speed: 1500,
    lazyLoad: 'ondemand',
    autoplaySpeed: 5000,
  };
  return (
    <CarouselContainer>
      <Text fontSize="large" margin="15px 0 10px 22px" bold>
        실시간 보완 요청 현황
      </Text>
      <GlobalStyle supplementationList={supplementationList?.length} />
      <OneMoreWrap>
        {isImageLoading === true ? (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '120px 0 0 -70px',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Slider
            {...settings}
            key={supplementationList?.length}
            ref={sliderRef}
          >
            {groupedPictures?.map((group, index) => {
              return (
                <>
                  <div key={index.toString()}>
                    {group?.map((item, indx) => (
                      <CarouselItem
                        key={indx.toString()}
                        onClick={() => {
                          dispatch(setSelectedImagesForDashBoard(item));
                          setImageModal(true);
                        }}
                      >
                        <ImageWrapper>
                          <CarouselImage
                            src={item?.image_list[0]}
                            alt={item?.image_list[0]}
                          />
                        </ImageWrapper>
                        <TextCenter>
                          <StyledText
                            style={{fontSize: '13px'}}
                            margin="0px 0 0 0"
                          >
                            {item?.store_name}
                          </StyledText>

                          <br></br>
                          <StyledText
                            style={{fontSize: '13px'}}
                            margin="0px 0 0 0"
                          >
                            {item?.sup_title}
                          </StyledText>
                        </TextCenter>
                        <div
                          style={{
                            width: '80px',
                            display: 'flex',
                            margin: '0 0 0 60px',
                          }}
                        >
                          {item?.completed_on !== null && (
                            <>
                              <AccessTimeIcon fontSize="small" />

                              <StyledText2 fontSize="small">
                                {timeDifference(item?.completed_on)}
                              </StyledText2>
                            </>
                          )}
                        </div>
                      </CarouselItem>
                    ))}
                  </div>
                </>
              );
            })}
          </Slider>
        )}
      </OneMoreWrap>
    </CarouselContainer>
  );
};

export default SupplementationStatus;
