import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const iconSize = {
  SMALL: 'sm',
  LARGE: 'lg',
};
const iconShape = {
  ROUND: 'round',
  SQUARE: 'square',
};

const IconWrapper = styled.div`
  /* margin: auto 0; */
  /* float: right; */
  /* background-color: #bfbfbf; */
  cursor: pointer;
  ${({ size }) => {
    switch (size) {
      case iconSize.SMALL:
        return css`
          width: 20px;
          height: 15px;
        `;
      case iconSize.LARGE:
        return css`
          width: 40px;
          height: 40px;
        `;
      default:
        return css`
          width: 20px;
          height: 20px;
        `;
    }
  }}
  ${({ shape }) => {
    switch (shape) {
      case iconShape.ROUND:
        return css`
          border-radius: 50%;
        `;
      case iconShape.SQUARE:
        return css``;
      default:
        return css`
          background-color: transparent;
        `;
    }
  }}
`;

const Icon = (props) => {
  const { size, shape, icon, onClick } = props;
  return (
    <IconWrapper size={size} shape={shape} onClick={onClick}>
      {icon}
    </IconWrapper>
  );
};

Icon.propTypes = {
  size: PropTypes.string,
  shape: PropTypes.string,
  icon: PropTypes.element,
  onClick: PropTypes.func,
};

Icon.defaultProps = {
  size: 'base',
  shape: 'square',
  onClick: null,
  icon: null,
};
export default Icon;
