import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import i18n from 'i18next';
import api from 'shared/utils/api';

const initialState = {
  inviteMemberList: [],
  addChatRoomModal: false,
  addChatRoomData: null,

  addChatRoomNameModal: false,
  addChatRoomNameData: null,

  chatData: null,
  selectedChatData: null,
};

//get Inite MemberList thunk
export const getInviteMembersToChatRoomAsync = createAsyncThunk(
  'chatting/getMemberListToChatRoom',
  async (param, {rejectWithValue}) => {
    try {
      const response = await api.getMembersToInviteChatRoom(param);
      const {results} = response.data;
      return results;
    } catch (error) {
      if (error.response.status === 400) {
        return rejectWithValue(error?.response?.data?.kr);
      } else if (error.response.status === 403) {
        return rejectWithValue(error?.response?.data?.kr);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

//post Member thunk
export const postMembertoChatRoomAsync = createAsyncThunk(
  'chatting/postMemberToChatRoom',
  async (data, {rejectWithValue}) => {
    try {
      const response = await api.postMemberToChatRoom(data);
      return response.data;
    } catch (error) {
      const errorResponse = error.response || {};
      const errData = errorResponse.data || {};
      const message =
        i18n.language === 'ko'
          ? errData.kr || error.message || '잘못된 접근입니다.'
          : errData.en || error.message || 'Something went wrong.';
      return rejectWithValue(message);
    }
  },
);

//get Inite MemberList thunk
export const getChatRoomAsync = createAsyncThunk(
  'chatting/getChatRoom',
  async (param, {rejectWithValue}) => {
    try {
      const response = await api.getChatRoom(param);
      const {results} = response.data;

      return results;
    } catch (error) {
      if (error.response.status === 400) {
        return rejectWithValue(error?.response?.data?.kr);
      } else if (error.response.status === 403) {
        return rejectWithValue(error?.response?.data?.kr);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

//get Inite MemberList thunk
export const getChatMessagesAsync = createAsyncThunk(
  'chatting/getChatMessages',
  async (param, {rejectWithValue}) => {
    try {
      const response = await api.getChatMessages(param);

      const {result} = response.data;

      return result;
    } catch (error) {
      if (error.response.status === 404) {
        return rejectWithValue('404');
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

//get Inite MemberList thunk
export const deleteUserChatAsync = createAsyncThunk(
  'chatting/deleteUserChat',
  async (data, {rejectWithValue}) => {
    try {
      const response = await api.deleteUserChat(data);
      return response.data;
    } catch (error) {
      const errorResponse = error.response || {};
      const errData = errorResponse.data || {};
      const message =
        i18n.language === 'ko'
          ? errData.kr || error.message || '잘못된 접근입니다.'
          : errData.en || error.message || 'Something went wrong.';
      return rejectWithValue(message);
    }
  },
);

// SV CHAT:
//get Inite MemberList thunk
export const getSvChatRoomAsync = createAsyncThunk(
  'chatting/getSvChatRoom',
  async (param, {rejectWithValue}) => {
    try {
      const response = await api.getSvChatRoom(param);
      return response.data;
    } catch (error) {
      const errorResponse = error.response || {};
      const errData = errorResponse.data || {};
      const message =
        i18n.language === 'ko'
          ? errData.kr || error.message || '잘못된 접근입니다.'
          : errData.en || error.message || 'Something went wrong.';
      return rejectWithValue(message);
    }
  },
);

//get Inite MemberList thunk
export const getSvChatMessagesAsync = createAsyncThunk(
  'chatting/getSvChatMessages',
  async (param, {rejectWithValue}) => {
    try {
      const response = await api.getSvChatMessages(param);
      const {results} = response.data;
      return results;
    } catch (error) {
      const errorResponse = error.response || {};
      const errData = errorResponse.data || {};
      const message =
        i18n.language === 'ko'
          ? errData.kr || error.message || '잘못된 접근입니다.'
          : errData.en || error.message || 'Something went wrong.';
      return rejectWithValue(message);
    }
  },
);

// post Member thunk
export const pinChatRoomAsync = createAsyncThunk(
  'chatting/pinChatRoom',
  async (data, {rejectWithValue}) => {
    try {
      const response = await api.pinChatRoom(data);
      return response.data;
    } catch (error) {
      const errorResponse = error.response || {};
      const errData = errorResponse.data || {};
      const message =
        i18n.language === 'ko'
          ? errData.kr || error.message || '잘못된 접근입니다.'
          : errData.en || error.message || 'Something went wrong.';
      return rejectWithValue(message);
    }
  },
);

// post Media to chat room thunk
export const postImageFileToChatAsync = createAsyncThunk(
  'chatting/postImageFileToChat',
  async (data, {rejectWithValue}) => {
    try {
      const response = await api.postImageFileToChat(data);
      return response.data;
    } catch (error) {
      const errorResponse = error.response || {};
      const errData = errorResponse.data || {};
      const message =
        i18n.language === 'ko'
          ? errData.kr || error.message || '잘못된 접근입니다.'
          : errData.en || error.message || 'Something went wrong.';
      return rejectWithValue(message);
    }
  },
);

export const chattingSlice = createSlice({
  name: 'chatting',
  initialState,
  reducers: {
    setAddChatRoomModal: (state, action) => {
      state.addChatRoomModal = action.payload;
    },
    setAddChatRoomData: (state, action) => {
      state.addChatRoomData = action.payload;
    },

    setAddChatRoomNameModal: (state, action) => {
      state.addChatRoomNameModal = action.payload;
    },
    setAddChatRoomNameData: (state, action) => {
      state.addChatRoomNameData = action.payload;
    },

    setSelectedChatData: (state, action) => {
      state.selectedChatData = action.payload;
    },

    setChatData: (state, action) => {
      state.chatData = action.payload;
    },
    resetChattingSlice: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(
      getInviteMembersToChatRoomAsync.fulfilled,
      (state, action) => {
        state.inviteMemberList = action.payload;
      },
    );
  },
});

export const {
  setAddChatRoomModal,
  setAddChatRoomData,

  setAddChatRoomNameModal,
  setAddChatRoomNameData,

  setSelectedChatData,

  setChatData,
  resetChattingSlice,
} = chattingSlice.actions;

export const selectChatting = (state) => state.owler.chatting;

export default chattingSlice.reducer;
