import styled from 'styled-components';

const Img = styled.img`
    width: 8vh;
    height: 8vh;
    border-radius: 1000px;
    border:none;
    //border: 1px solid black;
    margin-top: 3vh;
    margin-left: 7vw;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
`

export default Img;