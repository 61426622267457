import styled from 'styled-components';
import ReviewEvent from './ReviewEvent';
import theme from 'shared/styles/theme';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AppText from 'shared/components/AppText';

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  >*{
    margin 0; 0;
  }
`;

const ReviewWrapper = styled.div`
  height: 90vh;
  display: flex;
  flex-direction: column;

  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  > * {
    margin-top: 3vh;
  }
  margin-bottom: 3vh;
  margin-top: -1vh;
  padding-bottom: 100px;
  padding: 0 5px;
  width: 100%;
  align-items: center;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.75vh 3.5vw;
  height: ${(props) => props.height || ''};
  background: rgb(242, 242, 242);
  background: linear-gradient(
    90deg,
    rgba(242, 242, 242, 1) 0%,
    rgba(106, 120, 240, 0) 0%,
    rgba(157, 134, 253, 1) 100%
  );
`;

const StyledArrowButton = styled.button`
  background: none;
  border: none;
  font-size: 15px;
  &:hover {
    cursor: pointer;
  }
`;

const StyledArrowIcon = styled((props) => (
  <ArrowBackIosIcon {...props} fontSize="small" />
))`
  margin-top: 5px;
`;

const StyledReplacer = styled.div`
  width: 24px;
  height: 24px;
`;

const EventListTemplate = ({goback, clickEvent, eventList}) => {
  return (
    <Container>
      <LogoWrapper>
        <StyledArrowButton onClick={() => goback()}>
          <StyledArrowIcon />
        </StyledArrowButton>

        <AppText text="진행중인 이벤트" fontSize="18px" />

        <StyledReplacer />
      </LogoWrapper>

      <div style={{width: '100%', padding: '10px 0 0px 20px'}}>
        <AppText
          text="· 참여하실 이벤트를 클릭해 주세요."
          fontSize="15px"
          color={theme.colors.kalsPurple}
        />
      </div>

      <ReviewWrapper>
        {eventList?.length > 0
          ? eventList?.map((item, index) => (
              <ReviewEvent key={index} item={item} clickEvent={clickEvent} />
            ))
          : null}
      </ReviewWrapper>
    </Container>
  );
};

export default EventListTemplate;
