import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-hot-toast';
import {useTranslation} from 'react-i18next';

import {
  postEmailPasswordAsync,
  setCurrentAuthUser,
  setUserInfo,
} from 'owler/utils/slices/userSlice';
import {selectApp} from 'shared/utils/redux/appSlice';
import {getUserStoresAsync} from 'owler/utils/slices/storeSlice';
import EmailLoginTemplate from './template';
import {setSVUserInfo} from 'franchise/utils/slices/frUserSlice';

const EmailLogin = () => {
  const {t} = useTranslation();
  const {appType} = useSelector(selectApp);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [emailLoginPassword, setEmailLoginPassword] = useState('');

  const validateEmail = (email) => {
    return email.match(/^\S+@\S+\.\S+$/);
  };

  const onLogin = (email, password) => {
    if (password.length === 0 && email.length === 0) {
      toast.error(t('alerts.enter_your_email_password'));
    } else if (validateEmail(email) === null) {
      toast.error(t('alerts.invalid_email'));
    } else if (password.length === 0) {
      toast.error(t('alerts.incorrect_password'));
    } else {
      setLoading(true);

      if (appType === 'owler') {
        dispatch(
          postEmailPasswordAsync({
            email: email,
            password: emailLoginPassword,
            token: null,
          }),
        )
          .unwrap()
          .then((payload) => {
            dispatch(setUserInfo(payload?.userInfo));
            dispatch(getUserStoresAsync(payload?.userInfo));
            dispatch(setCurrentAuthUser(true));
            setLoading(false);
            navigate('/owler/storeList');
          })
          .catch((error) => {
            setLoading(false);
            dispatch(setCurrentAuthUser(false));
            toast.error(error);
            navigate('/emailLogin');
          });
      } else if (appType === 'franchise') {
        dispatch(
          postEmailPasswordAsync({
            email: email,
            password: emailLoginPassword,
            token: null,
          }),
        )
          .unwrap()
          .then((payload) => {
            if (payload?.commuProfile === null) {
              alert(
                '슈퍼바이저 계정이 존재하지 않습니다. 아울러로 문의주시기 바랍니다.',
              );
              navigate(-1);
              return;
            }

            dispatch(setSVUserInfo(payload?.commuProfile));

            dispatch(setCurrentAuthUser(true));

            setLoading(false);

            navigate('/franchise/dashboard');
          })
          .catch((error) => {
            setLoading(false);

            dispatch(setCurrentAuthUser(false));

            toast.error(error);

            navigate('/emailLogin');
          });
      }
    }
  };

  const handleEmailLoginBtn = () => {
    onLogin(emailAddress, emailLoginPassword);
  };

  const handlePhoneLogin = () => {
    navigate('/phoneLogin');
  };

  const handleFindEmail = () => {
    navigate('/findEmail');
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <>
      <EmailLoginTemplate
        handleLogoClick={handleLogoClick}
        emailAddress={emailAddress}
        setEmailAddress={setEmailAddress}
        emailLoginPassword={emailLoginPassword}
        setEmailLoginPassword={setEmailLoginPassword}
        onLogin={onLogin}
        loading={loading}
        handleEmailLoginBtn={handleEmailLoginBtn}
        handlePhoneLogin={handlePhoneLogin}
        handleFindEmail={handleFindEmail}
      />
    </>
  );
};

export default EmailLogin;
