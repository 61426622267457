import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import api from 'shared/utils/api';

const initialState = {
  allFranchiseStores: [],
  supervisorStores: [],
  currentStore: null,
  currentStoreDetails: null,
  franchiseName: null,
};

// get all stores from franchise thunk
export const getFranchiseStoreListAsync = createAsyncThunk(
  'franchiseStore/getFranchiseStoreList',
  async (uid) => {
    const response = await api.getFranchiseStoreList(uid);

    const {results} = response.data;

    return results;
  },
);

// get Supervisor Stores thunk
export const getSupervisorStoresAsync = createAsyncThunk(
  'franchiseStore/getSupervisorStores',
  async (uid) => {
    const response = await api.getSupervisorStores(uid);

    const {results} = response.data;

    return results;
  },
);

// add To Supervisor Store List thunk
export const addToSupervisorStoreListAsync = createAsyncThunk(
  'franchiseStore/addToSupervisorStoreList',
  async (data, {rejectWithValue}) => {
    try {
      const response = await api.addToSupervisorStoreList(data);

      const {results} = response.data;

      return results;
    } catch (error) {
      if (error.response.status === 400) {
        return rejectWithValue(error?.response?.data?.KR);
      } else if (error.response.status === 403) {
        return rejectWithValue(error?.response?.data?.KR);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

// delete supervisor store from the list thunk
export const deleteSupervisorStoreAsync = createAsyncThunk(
  'franchiseStore/deleteSupervisorStore',
  async (data) => {
    const response = await api.deleteSupervisorStore(data);

    return response.data;
  },
);

// get Supervisor Stores thunk
export const getSupervisorStoreDetailAsync = createAsyncThunk(
  'franchiseStore/getSupervisorStoreDetail',
  async (uid) => {
    const response = await api.getSupervisorStoreDetail(uid);

    const {results} = response.data;

    return results;
  },
);

// GET :: SV Today Schedule
export const getSvTodaysScheduleAsync = createAsyncThunk(
  'franchiseStore/getSvTodaysSchedule',
  async (param) => {
    const response = await api.getSvTodaysSchedule(param);

    const {results} = response.data;

    return results;
  },
);

// GET :: Management store score status weekly
export const getWeeklyManagementStoreScoreAsync = createAsyncThunk(
  'franchiseStore/getWeeklyManagementStoreScore',
  async (param) => {
    const response = await api.getWeeklyManagementStoreScore(param);

    const {results} = response.data;

    return results;
  },
);

// GET :: Management store score status monthly
export const getMonthlyManagementStoreScoreAsync = createAsyncThunk(
  'franchiseStore/getMonthlyManagementStoreScore',
  async (param) => {
    const response = await api.getMonthlyManagementStoreScore(param);

    const {results} = response.data;

    return results;
  },
);

// GET :: Visit store status weekly
export const getWeeklyVisitStoreStatusAsync = createAsyncThunk(
  'franchiseStore/getWeeklyVisitStoreStatus',
  async (param) => {
    const response = await api.getWeeklyVisitStoreStatus(param);

    const {results} = response.data;

    return results;
  },
);
export const getFranchiseNameAsync = createAsyncThunk(
  'franchiseStore/getFranchiseNameAsync',
  async () => {
    const response = await api.getFranchiseName();

    const {results} = response.data;

    return results;
  },
);

export const franchiseStoreSlice = createSlice({
  name: 'franchiseStore',
  initialState,
  reducers: {
    setCurrentStore: (state, action) => {
      state.currentStore = action.payload;
    },
    setFranchiseStoreList: (state, action) => {
      state.allFranchiseStores = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      // Handling franchise stores
      .addCase(getFranchiseStoreListAsync.fulfilled, (state, action) => {
        state.allFranchiseStores = action.payload;
      })
      .addCase(getFranchiseStoreListAsync.rejected, () => {
        console.error('Error getting franchise stores 😐');
      })

      // Handling supervisor stores
      .addCase(getSupervisorStoresAsync.fulfilled, (state, action) => {
        state.supervisorStores = action.payload;
      })
      .addCase(getSupervisorStoresAsync.rejected, (state) => {
        console.error('Error getting supervisor stores 😐');
        state.supervisorStores = [];
      })

      .addCase(getSupervisorStoreDetailAsync.fulfilled, (state, action) => {
        state.currentStoreDetails = action.payload;
      })
      .addCase(getFranchiseNameAsync.fulfilled, (state, action) => {
        state.franchiseName = action.payload;
      });
  },
});

export const {setCurrentStore, setFranchiseStoreList} =
  franchiseStoreSlice.actions;

export const selectFranchiseStore = (state) => state.franchise.store;

export default franchiseStoreSlice.reducer;
