import React from 'react';
import styled from 'styled-components';

import NewLogoCircle from '../../assets/icons/NewLogoCircle';
import Text from '../atoms/Text';
import {FranchiseHeader} from '../index';

const StyledWrapper = styled.div`
  overflow: ${(props) => props.overflow};
  mim-width: 1280px;
  width: 1280px;
  margin: 0 auto;
  padding: 0 20px;
  min-height: 100%;
  & > *:last-child {
    margin-bottom: 0;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
`;

const TitleWrapper = styled.div`
  min-width: 1280px;
  margin: 60px auto 30px;
`;

const Wrapper = ({overflow, children, titleText, buttonText}) => (
  <StyledWrapper overflow={overflow}>
    <FranchiseHeader
      src={<NewLogoCircle length={58} />}
      buttonText={buttonText}
    >
      {titleText && (
        <TitleWrapper>
          <Text fontSize="strongTitle" text={titleText} bold />
        </TitleWrapper>
      )}
      {children}
    </FranchiseHeader>
  </StyledWrapper>
);

export default Wrapper;
