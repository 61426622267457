import React, {useState, useEffect, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate, useLocation} from 'react-router-dom';
import {selectUser} from 'owler/utils/slices/userSlice';

export default function withAuth(WrappedComponent) {
  function AuthComponent() {
    const appState = useSelector(selectUser);
    const navigate = useNavigate();
    const location = useLocation();
    const [authenticated, setAuthenticated] = useState(false);

    const isLoginPage = useCallback(() => {
      const root = location.pathname;
      return root === '/' || root === '/phoneLogin';
    }, [location]);

    useEffect(() => {
      const isLogin = isLoginPage();
      const isAuthenticated = !!appState.currentAuthUser;

      switch (true) {
        case !isLogin && !isAuthenticated:
          alert('admin만 접근이 가능합니다.');
          navigate('/');
          break;
        case !isLogin && isAuthenticated:
          setAuthenticated(true);
          break;
        case isLogin && !isAuthenticated:
          setAuthenticated(true);
          break;
        case isLogin && isAuthenticated:
          setAuthenticated(true);
          navigate('/franchise/dashboard');
          break;
        default:
          break;
      }
    }, [isLoginPage, navigate, appState.currentAuthUser]);

    return authenticated && <WrappedComponent />;
  }

  return AuthComponent;
}
