import React from 'react';
import styled from 'styled-components';
import StarIcon from '@mui/icons-material/Star';

import theme from 'shared/styles/theme';
import {RatingStars, Text} from 'shared/components/index';

const hexColor = theme.colors.bgSkelet;
const red = parseInt(hexColor.slice(1, 3), 16);
const green = parseInt(hexColor.slice(3, 5), 16);
const blue = parseInt(hexColor.slice(5, 7), 16);

const StyledGraphsWrapper = styled.div`
  border-radius: 12px;
  //background-color: rgba(${red}, ${green}, ${blue}, 0.65);
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  width: 100%;
`;

const CardTitleWrap = styled.div`
  width: 100%;
  margin: -25px 0 12px -10px;
`;

const RatingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;
  margin-top: -38px;
  margin-left: 52%;
  background-color: #8b77d9;
  padding: 2px 2px;
  border-radius: 20px;
`;

const Info = styled.div`
  position: absolute;
  z-index: 1;
  color: #f9ce8b;
  display: flex;
  align-items: center;
  flex-direction: row;
  p,
  h1 {
    white-space: nowrap;
  }
  p {
    font-size: 20px;
    font-weight: 500;
  }
  h1 {
    font-size: 32px;
    margin-top: -5px;
    margin-left: 9px;
    margin-right: 25px;
  }
`;

const ReviewWrapper = styled.div`
  width: 500px;
  height: 50px;
  background: #8b77d9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  margin: 40px auto 0 auto;
  padding: 10px 5px;
`;

const ReviewStarWrap = styled.div`
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -60px;
`;

const ReviewScore = styled.div`
  font-size: ${(props) => props.fontSize};
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0px')};
  &:hover {
    cursor: pointer;
  }
  margin-top: -5px;
`;

const ReviewInfoWrap = styled.div`
  width: 75%;
`;

const ReviewingStoreInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 3px 0 3px 0;
`;

const StyledWrapper = styled.div`
  width: 100%;
  height: 175px;
  //border-left: 3px solid ${theme.colors.kalsPurple};
  padding: 15px;
  display: flex;
  // border-radius: 15px;
  //margin-top: 20px;
`;

const StoreReportStatus = ({storeReviews}) => {
  if (storeReviews === null) {
    return;
  }

  // Access properties once storeReviews is not null
  const {month_avg_score, latest_review, scores} = storeReviews;

  return (
    <StyledWrapper>
      <StyledGraphsWrapper>
        <CardTitleWrap>
          <Text
            color={theme.colors.black}
            fontSize="large"
            margin="0 0 0 -5px"
            bold
          >
            &nbsp;&nbsp;&nbsp;이번달 고객 리뷰
          </Text>
        </CardTitleWrap>

        <RatingContainer>
          <RatingStars totalRate={month_avg_score} />

          <Info>
            <p>평균</p>
            <h1>{month_avg_score}</h1>
          </Info>
        </RatingContainer>

        {latest_review !== null && (
          <ReviewWrapper>
            <ReviewStarWrap>
              <StarIcon style={{color: 'white'}} />

              <ReviewScore fontSize="24px" style={{margin: '0.2px 0 0 0'}}>
                {latest_review?.score !== null ? latest_review?.score : 0}
              </ReviewScore>
            </ReviewStarWrap>

            <ReviewInfoWrap>
              {/* First line  */}
              <ReviewingStoreInfo>
                {latest_review?.score !== null ? (
                  <ReviewScore fontSize="15px">
                    [{latest_review?.store_name}]{' '}
                  </ReviewScore>
                ) : (
                  <ReviewScore />
                )}

                {latest_review?.score !== null ? (
                  <ReviewScore fontSize="15px">
                    {new Date(latest_review?.created_on).toLocaleString(
                      'en-US',
                      {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      },
                    )}
                  </ReviewScore>
                ) : (
                  <ReviewScore fontSize="15px">
                    이번 달 리뷰가 존재하지 않습니다.
                  </ReviewScore>
                )}
              </ReviewingStoreInfo>

              {/* Second line. if context text is too long then cut off and put ... */}
              <ReviewScore fontSize="14px">
                {latest_review?.contents}
              </ReviewScore>
            </ReviewInfoWrap>
          </ReviewWrapper>
        )}
      </StyledGraphsWrapper>
    </StyledWrapper>
  );
};

export default StoreReportStatus;
