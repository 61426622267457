import React, {useState, useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  fetchWorkScheduleCalendar,
  fetchScheduleTimeline,
} from 'owler/utils/slices/calendarSlice';
import {setCurrentStore} from 'owler/utils/slices/storeSlice';
import {selectStore} from 'owler/utils/slices/storeSlice';

import CalendarTemplate from './template/index';

const Calendar = () => {
  const dispatch = useDispatch();
  const {currentStore} = useSelector(selectStore);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [workerAvailability, setWorkerAvailability] = useState([]);

  useEffect(() => {
    if (currentStore !== null) {
      const year = selectedDate.getFullYear();
      const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0'); // 월을 0으로 패딩
      const day = selectedDate.getDate().toString().padStart(2, '0'); // 일을 0으로 패딩
      const date = `${year}-${month}-${day}`;

      dispatch(fetchWorkScheduleCalendar({store_uid: currentStore.store_uid}))
        .unwrap()
        .then(({results}) => {
          setWorkerAvailability(results?.date_list);
        })
        .catch((err) => {
          console.error(err);
        });

      dispatch(
        fetchScheduleTimeline({store_uid: currentStore.store_uid, date: date}),
      )
        .unwrap()
        .then(() => {})
        .catch((err) => {
          console.error(err);
        });
    }
  }, [currentStore, dispatch, selectedDate]);

  const onRefresh = useCallback(() => {
    if (currentStore !== null) {
      const year = selectedDate.getFullYear();
      const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0'); // 월을 0으로 패딩
      const day = selectedDate.getDate().toString().padStart(2, '0'); // 일을 0으로 패딩
      const date = `${year}-${month}-${day}`;

      dispatch(fetchWorkScheduleCalendar({store_uid: currentStore.store_uid}))
        .unwrap()
        .then(({results}) => {
          setWorkerAvailability(results?.date_list);
        })
        .catch((err) => {
          console.error(err);
        });

      dispatch(
        fetchScheduleTimeline({store_uid: currentStore.store_uid, date: date}),
      )
        .unwrap()
        .then(() => {})
        .catch((err) => {
          console.error(err);
        });
    }
  }, [currentStore, dispatch, selectedDate]);

  const handleStoreClick = useCallback(
    (_store) => {
      dispatch(setCurrentStore(_store));
    },
    [dispatch],
  );

  return (
    <CalendarTemplate
      onRefresh={onRefresh}
      setSelectedDate={setSelectedDate}
      handleStoreClick={handleStoreClick}
      workerAvailability={workerAvailability}
    />
  );
};

export default Calendar;
