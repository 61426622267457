import {configureStore} from '@reduxjs/toolkit';
import storageSession from 'redux-persist/lib/storage/session';
import {persistReducer, createTransform} from 'redux-persist';
import {combineReducers} from 'redux';

import appSlice from './appSlice';

import franchiseUserSlice from 'franchise/utils/slices/frUserSlice';
import franchiseStoreSlice from 'franchise/utils/slices/frStoreSlice';
import franchiseQscSlice from 'franchise/utils/slices/frQscSlice';
import franchiseNoticeSlice from 'franchise/utils/slices/frNoticeSlice';
import franchisePositionSlice from 'franchise/utils/slices/frPositionSlice';
import franchiseSupplementionSlice from 'franchise/utils/slices/frSupplementionSlice';

import userSlice from 'owler/utils/slices/userSlice';
import storeSlice from 'owler/utils/slices/storeSlice';
import taskSlice from 'owler/utils/slices/taskSlice';
import noticeSlice from 'owler/utils/slices/noticeSlice';
import franchiseSlice from 'owler/utils/slices/franchiseSlice';
import calendarSlice from 'owler/utils/slices/calendarSlice';
import positionSlice from 'owler/utils/slices/positionSlice';
import requestSlice from 'owler/utils/slices/requestSlice';
import commuteSlice from 'owler/utils/slices/commuteSlice';

import mapSlice from 'shared/utils/redux/mapSlice';
import chattingSlice from 'owler/utils/slices/chattingSlice';

const franchiseReducers = combineReducers({
  user: franchiseUserSlice,
  store: franchiseStoreSlice,
  qsc: franchiseQscSlice,
  notice: franchiseNoticeSlice,
  position: franchisePositionSlice,
  supplemention: franchiseSupplementionSlice,
});

const owlerReducers = combineReducers({
  user: userSlice,
  store: storeSlice,
  task: taskSlice,
  notice: noticeSlice,
  franchise: franchiseSlice,
  calendar: calendarSlice,
  position: positionSlice,
  request: requestSlice,
  commute: commuteSlice,
  chatting: chattingSlice,
});

const reducers = combineReducers({
  app: appSlice,
  map: mapSlice,
  owler: owlerReducers,
  franchise: franchiseReducers,
});

const SetMarkerTransform = createTransform(
  (inboundState, key) => {
    if (key === 'map') {
      return {...inboundState, alls: null};
    }
    return inboundState;
  },
  (outboundState, key) => {
    return outboundState;
  },
);

const persistConfig = {
  key: 'root',
  storage: storageSession,
  transforms: [SetMarkerTransform],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: true,
});
