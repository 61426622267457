import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {setAppType} from '../redux/appSlice';
import {deleteAllCookies} from '../cookie';
import {resetUserSlice} from 'owler/utils/slices/userSlice';
import {resetTaskSlice} from 'owler/utils/slices/taskSlice';
import {resetStoreSlice} from 'owler/utils/slices/storeSlice';

// This hook handles user logout
function useLogout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logUserOut = async () => {
    try {
      // go home!
      navigate('/');

      // remove cookies:
      deleteAllCookies();

      // reset app type
      dispatch(setAppType(null));

      // reset owler user state
      dispatch(resetUserSlice());

      // reset owler store state
      dispatch(resetStoreSlice());

      // reset owler task state
      dispatch(resetTaskSlice());
    } catch (error) {
      console.error('Logout error 🐛: ', error.message);
    }
  };

  return {logUserOut};
}

export default useLogout;
