import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {format, parseISO, formatISO, startOfToday} from 'date-fns';

import {
  fetchScheduleList,
  selectCalendar,
} from 'owler/utils/slices/calendarSlice';

import {selectStore} from 'owler/utils/slices/storeSlice';

import MemberScheduleComponent from './MemberScheduleComponent';

const Container = styled.div`
  background-color: white;
  width: 32%;
  height: 770px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5px;
`;

const Header = styled.div`
  padding: 10px 20px;
  text-align: flex-start;
  width: 100%;
`;

const FormattedDate = styled.span`
  font-weight: bold;
  font-size: 1.4em;
`;

const DayScheduleModal = ({onRefresh}) => {
  const {selectedSchedule} = useSelector(selectCalendar);
  const {currentStore} = useSelector(selectStore);
  const dispatch = useDispatch();
  const currentDate = formatISO(startOfToday(), {representation: 'date'});
  const scheduleDate = selectedSchedule || currentDate;
  const formattedDate = format(parseISO(scheduleDate), 'M월 d일');

  const [memberSchedules, setMemberSchedules] = useState([]);

  useEffect(() => {
    dispatch(
      fetchScheduleList({
        store_uid: currentStore?.store_uid,
        date: scheduleDate,
      }),
    )
      .unwrap()
      .then(({results}) => {
        setMemberSchedules(results);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [dispatch, currentStore, scheduleDate]);

  const handleRefresh = useCallback(() => {
    onRefresh();

    dispatch(
      fetchScheduleList({
        store_uid: currentStore?.store_uid,
        date: scheduleDate,
      }),
    )
      .unwrap()
      .then(({results}) => {
        setMemberSchedules(results);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [dispatch, currentStore, scheduleDate, setMemberSchedules]);

  return (
    <Container>
      <Header>
        <FormattedDate>{formattedDate}</FormattedDate>
      </Header>

      <MemberScheduleComponent
        selectedSchedule={selectedSchedule}
        handleRefresh={handleRefresh}
        memberSchedules={memberSchedules}
      />
    </Container>
  );
};

export default DayScheduleModal;
