/* eslint-disable react-hooks/exhaustive-deps */
import styled from 'styled-components';
import OTPInput from 'otp-input-react';
import {Toaster} from 'react-hot-toast';
import PI from 'react-phone-input-2'; ////////////////
import {useTranslation} from 'react-i18next';
import {CgSpinner} from 'react-icons/cg';
import {BsFillShieldLockFill} from 'react-icons/bs';
//import 'react-phone-input-2/lib/style.css';

import {Logo, SimpleButton, Text} from 'shared/components/index';
import NewLogoCircle from 'shared/assets/icons/NewLogoCircle';
import theme from 'shared/styles/theme';

const HomeBackground = styled.div`
  background-color: #eff0fc;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const HiddenCountryCode = styled.div`
  .react-phone-number-input__input--disabled {
    color: transparent !important;
  }

  .react-phone-number-input__country-select
    .react-phone-number-input__country-select-value {
    visibility: hidden;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
  width: 310px;
`;

const BtnContainer = styled.div`
  width: 100%;
  margin: 10px 0;
`;

const OtpWrapper = styled.div`
  width: '100%',
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0 10px 0;
`;

const PhoneLoginTemplate = ({
  handleLogoClick,
  showOTP,
  otp,
  setOtp,
  onOTPVerify,
  loading,
  inputRef,
  handleKeyDown,
  userPhoneNumInput,
  setUserPhoneNumInput,
  onSignup,
  handleOpenEmailLogin,
}) => {
  const {t} = useTranslation();
  const ReactPhoneInput = PI.default ? PI.default : PI;
  return (
    <HiddenCountryCode>
      <HomeBackground>
        <Toaster toastOptions={{duration: 4000}} />
        <div id="recaptcha-container" />
        <Wrapper>
          <Logo src={<NewLogoCircle length={80} />} onClick={handleLogoClick} />
          <Text fontSize="large" bold>
            아울러
          </Text>

          {showOTP ? (
            <Wrapper>
              <div>
                <BsFillShieldLockFill size={30} />
              </div>
              <Text fontSize="medium" bold>
                {t('screens.login.verification_title_one')}
              </Text>
              <Text fontSize="medium" bold>
                {t('screens.login.verification_title_two')}
              </Text>

              <OtpWrapper>
                <OTPInput
                  value={otp}
                  onChange={setOtp}
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                  autoFocus
                  secure={true}
                  inputStyles={{
                    border: `1px solid ${theme.colors.primary}`,
                    marginLeft: '2.5px',
                    marginRight: '2.5px',
                  }}
                />
              </OtpWrapper>

              <BtnContainer>
                <SimpleButton
                  onClick={onOTPVerify}
                  text={
                    loading ? <CgSpinner size={20} /> : t('buttons.confirm')
                  }
                />
              </BtnContainer>
            </Wrapper>
          ) : (
            <Wrapper>
              <ReactPhoneInput
                inputProps={{ref: inputRef}}
                onKeyDown={handleKeyDown}
                country={'kr'}
                placeholder="휴대폰 번호를 입력하세요"
                value={userPhoneNumInput}
                onChange={setUserPhoneNumInput}
                containerClass={{width: '100%', border: '1px solid red'}}
                inputStyle={{
                  height: '50px',
                  paddingLeft: '55px',
                  width: '100%',
                }}
                buttonStyle={{
                  backgroundColor: 'white',
                  width: '50px',
                }}
                disableCountryCode={true}
              />

              <BtnContainer>
                <SimpleButton
                  onClick={onSignup}
                  text={
                    loading ? (
                      <CgSpinner size={20} />
                    ) : (
                      t('screens.profile.verify')
                    )
                  }
                />
              </BtnContainer>

              <SimpleButton
                outlined
                margin="0 15px"
                onClick={handleOpenEmailLogin}
                text={t('screens.login.email_login')}
              />
            </Wrapper>
          )}
        </Wrapper>
      </HomeBackground>
    </HiddenCountryCode>
  );
};

export default PhoneLoginTemplate;
