import React, {useRef, useEffect} from 'react';
import styled from 'styled-components';
import {Text} from 'shared/components/index';
import {useDispatch, useSelector} from 'react-redux';
import {
  selectCalendar,
  setEventAddModal,
  setSelectedEvent,
  setEventDetailModal,
} from 'owler/utils/slices/calendarSlice';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import theme from 'shared/styles/theme';
import GlobalStyles from 'shared/styles/DayScheduleCalendarStyle';

const Container = styled.div`
  background-color: white;
  width: 280px;
  height: 770px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5px;
  border-left: 1px solid ${theme.colors.grey9};
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: -10px;
`;

const Colorrec = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 4.5px;
  margin: 5px 4px;
`;
const CalendarContainer = styled.div`
  margin-top: 10px;
  width: 95%;
`;
const WrapperForEvent = styled.div`
  max-width: 260px;
  width: 260px;

  display: flex;
  flex-direction: row;
  align-items: start;
  background-color: white !important;
`;
const DayScheduleModal = () => {
  const dispatch = useDispatch();
  const {selectedDate, events} = useSelector(selectCalendar);
  const calendarRef = useRef(null);

  const handleEventClick = (info) => {
    dispatch(setEventDetailModal(true));
    dispatch(
      setSelectedEvent({
        title: info.event.title,
        color: info.event.borderColor,
        start: info.event.start,
        end: info.event.end,
        schedule_uid: info.event.extendedProps.schedule_uid,
      }),
    );
  };

  const handleModalOpen = () => {
    dispatch(setEventAddModal(true));
  };

  useEffect(() => {
    const calendarApi = calendarRef?.current?.getApi();
    if (calendarApi && selectedDate) {
      calendarApi.gotoDate(selectedDate); // FullCalendar API를 사용하여 날짜를 변경
    }
  }, [selectedDate]);

  const renderEventContent = (eventInfo) => {
    return (
      <WrapperForEvent>
        <Colorrec
          style={{backgroundColor: `${eventInfo.event.backgroundColor}`}}
        />
        <div>
          <div className="custom-event-title">
            <Text
              bold
              style={{
                fontSize: '16px',
                width: '215px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              margin="2px 0 0 4px"
            >
              {eventInfo.event.title}
            </Text>
          </div>
        </div>
      </WrapperForEvent>
    );
  };
  return (
    // <MuiModal open={scheduleModal} onClose={handleModalClose}>
    <Container>
      <TitleWrapper>
        {/* <BtnWrapper onClick={handleModalClose}>
            <CloseIcon />
          </BtnWrapper> */}
      </TitleWrapper>
      <CalendarContainer>
        <GlobalStyles>
          <FullCalendar
            ref={calendarRef}
            plugins={[timeGridPlugin, dayGridPlugin]}
            initialView="dayGridDay"
            initialDate={selectedDate}
            locale="ko"
            weekends={true}
            titleFormat={{month: 'long', day: 'numeric'}}
            headerToolbar={{
              left: 'title', // 좌측에 이전, 오늘, 다음 버튼
              center: '', // 중앙에 제목 (월/일로 설정한 것)
              right: 'myCustomButton', // 오른쪽에는 아무것도 없음
            }}
            eventClick={(info) => handleEventClick(info)}
            buttonText={{today: '금일'}}
            height={580}
            events={events}
            eventColor="#D0A9F5"
            allDaySlot={true}
            allDayText="종일 일정"
            customButtons={{
              myCustomButton: {
                text: `+ 생성`,
                click: handleModalOpen,
              },
            }}
            eventContent={renderEventContent}
          />
        </GlobalStyles>
      </CalendarContainer>
    </Container>
    // </MuiModal>
  );
};
export default DayScheduleModal;
