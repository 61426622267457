import React from 'react';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import {ImageCarousel, MuiModal, Text} from 'shared/components/index';

const Container = styled.div`
  background-color: white;
  width: 800px;
  height: 680px;
  padding: 30px 20px;
  align-items: center;
  overflow: scroll;
  & > * {
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const BtnWrapper = styled.button`
  border: none;
  background-color: white;
  margin-left: 20px;
  cursor: pointer;
`;

const NoticeImageModal = ({noticeImageModal, setNoticeImageModal, images}) => {
  const handleModalClose = () => {
    setNoticeImageModal(false);
  };
  return (
    <MuiModal open={noticeImageModal} onClose={handleModalClose}>
      <Container>
        <TitleWrapper>
          <Text bold fontSize="lg"></Text>

          <BtnWrapper onClick={handleModalClose}>
            <CloseIcon />
          </BtnWrapper>
        </TitleWrapper>

        {images.length > 0 && (
          <ImageCarousel urlType="image_url" images={images} disable />
        )}
      </Container>
    </MuiModal>
  );
};
export default NoticeImageModal;
