import React, {useRef} from 'react';
import FullCalendar from '@fullcalendar/react';
import styled from 'styled-components';
import {useState} from 'react';

import {differenceInDays, getDay} from 'date-fns';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';

import PersonIcon from '@mui/icons-material/Person';

import {useDispatch, useSelector} from 'react-redux';
import {
  setScheduleModal,
  setSelectedDate2,
  setSelectedSchedule,
  fetchScheduleTimeline,
  selectCalendar,
} from 'owler/utils/slices/calendarSlice';
import {Text} from 'shared/components/index';
import ViewScheduleDetailModal from './ScheduleViewDetailModal';
import {selectStore} from 'owler/utils/slices/storeSlice';

const CalendarContainer = styled.div`
  width: 68%;
`;

const GlobalStyles = styled.div`
  .fc-daygrid-block-event {
    & .fc-event-title {
      padding: 0 0 0 3px;
    }
    margin-bottom: 1px;
  }
  .fc-daygrid-day-events {
    height: 75px;
  }

  .fc-daygrid-day:hover {
    cursor: pointer;
    opacity: 0.6;
  }
  .fc-daygrid-day.fc-day-disabled {
    cursor: default;
    opacity: 1;
  }
  .fc-popover {
    z-index: 2000;
  }
  .fc-toolbar-chunk {
    font-size: 13px;

    &:first-child {
      margin: 0 0 0 20px;
    }
    &:nth-child(2) {
      margin-left: -400px;
    }
  }
  .fc-day-sun {
    color: red;
  }
`;

const WrapperForEvent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  background-color: ${({color}) => color};
  width: 100%;
  border-radius: 4px;
  height: 21px !important;
`;

const StyledPersonIcon = styled(PersonIcon)`
  margin-right: 2px;
`;

const ReactCalendar = ({setSelectedDate, workerAvailability}) => {
  const dispatch = useDispatch();
  const calendarRef = useRef(null);
  const {currentStore} = useSelector(selectStore);
  const {scheduleTimelineArray} = useSelector(selectCalendar);

  const [calendarHeight, setCalendarHeight] = useState(770);

  const workerEvents = workerAvailability.map((day) => ({
    title: `${day.workers_count}`,
    start: day.date,
  }));

  const eventSources = [
    {
      events: workerEvents,
      color: 'transparent',
      textColor: 'black',
      backgroundColor: 'transparent',
    },
  ];

  const days = (startDate, endDate) => {
    // 한 달이 몇주 인지 높이 조절 함수
    const totalDays =
      differenceInDays(new Date(endDate), new Date(startDate)) + 1;
    const startDay = getDay(new Date(startDate));
    // 첫 주의 일 수를 계산
    const firstWeekDays = 7 - startDay;
    const remainingDays = totalDays - firstWeekDays;

    const fullWeeks = Math.floor(remainingDays / 7);
    const remainingWeekDays = remainingDays % 7;
    let weeks = remainingWeekDays > 0 ? 1 + fullWeeks + 1 : 1 + fullWeeks;

    if (weeks === 4) setCalendarHeight(540);
    if (weeks === 5) setCalendarHeight(645);
    if (weeks === 6) setCalendarHeight(760);
    return;
  };

  const handleDateClick = (info) => {
    dispatch(setSelectedSchedule(info?.dateStr));

    dispatch(
      fetchScheduleTimeline({
        store_uid: currentStore?.store_uid,
        date: info.dateStr,
      }),
    )
      .unwrap()
      .then(() => {})
      .catch((err) => {
        console.error(err);
      });
    dispatch(setScheduleModal(true));
    dispatch(setSelectedDate2(info.date));
  };

  const handleDateSet = (info) => {
    const startOfMonth = new Date(info.start);
    setSelectedDate(startOfMonth); // 현재 달의 첫 날짜를 설정합니다.
    dispatch(setSelectedDate2(startOfMonth));

    const endWeekOfMonth = new Date(info.end - 1);
    days(startOfMonth, endWeekOfMonth);
  };

  const renderEventContent = (eventInfo) => {
    const showPersonIcon =
      eventInfo?.event?.backgroundColor === '' &&
      eventInfo?.event?.textColor === '';

    const existingContent = (
      <WrapperForEvent color={eventInfo.event.borderColor}>
        <div className="custom-event-title">
          <Text
            color={showPersonIcon ? 'black' : 'white'}
            style={{
              padding: '0 0px 0 3px',
              width: '100px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            {showPersonIcon && <StyledPersonIcon />}
            {eventInfo.event.title}
          </Text>
        </div>
      </WrapperForEvent>
    );

    return (
      <div style={{display: 'flex', alignItems: 'center'}}>
        {existingContent}
      </div>
    );
  };

  return (
    <CalendarContainer>
      <GlobalStyles>
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          locale="ko"
          weekends={true}
          headerToolbar={{left: 'title', center: 'prev next today', right: ''}}
          buttonText={{today: '이번 달', month: '월', week: '주', day: '일'}}
          fixedWeekCount={false}
          eventSources={eventSources}
          showNonCurrentDates={false}
          height={calendarHeight}
          datesSet={handleDateSet}
          dateClick={handleDateClick}
          dayMaxEvents={true}
          editable={true}
          nowIndicator={true}
          eventDurationEditable={false}
          dayCellClassNames={(arg) => {
            const classNames = [];
            if (arg.date.getDay() === 0) {
              classNames.push('fc-day-sun');
            }
            return classNames;
          }}
          eventContent={renderEventContent}
        />
      </GlobalStyles>

      <ViewScheduleDetailModal scheduleTimeline={scheduleTimelineArray} />
    </CalendarContainer>
  );
};

export default ReactCalendar;
