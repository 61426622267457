import React, {useState, useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled, {createGlobalStyle} from 'styled-components';
import {MuiModal, Text} from 'shared/components/index';
import CloseIcon from '@mui/icons-material/Close';
import {
  selectCalendar,
  setNoticeLinkAddmodal,
  setExtraNoticeList,
} from 'owler/utils/slices/calendarSlice';
import {
  selectNotice,
  setCurrentNoticePage,
} from 'owler/utils/slices/noticeSlice';
import theme from 'shared/styles/theme';
import {MuiButton} from 'shared/components/index';
import Pagination from '@mui/material/Pagination';
import {
  getNoticeDetailAsync,
  setCurrentNotice,
  setCurrentNotice_uid,
} from 'owler/utils/slices/noticeSlice';
import CheckIcon from '@mui/icons-material/Check';

const Container = styled.div`
  background-color: white;
  width: 450px;
  max-height: 550px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0px 3px 3px 3px #6e6e6e;
`;
const BtnWrapper = styled.button`
  border: none;
  background-color: white;
  cursor: pointer;
  position: relative;
  top: 0px;
  left: 5px;
`;
const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
`;
const SearchStore = styled.input`
  width: 400px;
  font-size: 16px;
  padding: 0 10px;
  border: none;
  border: 2px solid ${theme.colors.grey9};
  border-radius: 10px;
  margin: 5px 10px;
`;
const Notice = styled(Text)`
  witdh: 30px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 6px 0;
  &:hover {
    cursor: pointer;
    opacity: 0.5;
    color: #00bfff;
  }
`;
const NoticeList = styled.div`
  //border-bottom: 1px solid ${theme.colors.grey6};
  margin: 0px 0 10px 5px;
  width: 400px;
  max-height: 380px;
  min-height: 380px;
  overflow: auto;
`;
const StyledMuiButton = styled(MuiButton)`
  &:hover {
    opacity: 0.9;
  }
  &:click {
  }
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.grey9};
`;
const CustomPagination = styled(Pagination)`
  max-width: 400px;
  & .MuiPaginationItem-page.Mui-selected {
    background-color: ${theme.colors.kalsPurple};
  }
  & .MuiPaginationItem-page.Mui-active {
    background-color: ${theme.colors.kalsPurple};
  }
  & .MuiPaginationItem-page:hover {
    background-color: ${theme.colors.kalsPurple}; // 원하는 색상으로 수정하세요.
  }
  & .MuiPaginationItem-page.Mui-selected:hover {
    background-color: #8f77d9; // 원하는 색상으로 수정하세요.
  }
`;
const AddNoticeModal = ({masterNoticeList}) => {
  const {noticeLinkAddmodal, extraNoticeList} = useSelector(selectCalendar);
  const {noticePageCount, currentNoticePage} = useSelector(selectNotice);
  const dispatch = useDispatch();
  const [directname, setdirectname] = useState('');

  const count = () => {
    return noticePageCount !== null ? Math.ceil(noticePageCount / 10) : 1;
  };

  const handlePaginationChange = (e, p) => {
    dispatch(setCurrentNoticePage(p));
  };

  const handleModalClose = () => {
    dispatch(setNoticeLinkAddmodal(false));
  };

  const handledNameChange = useCallback((e) => {
    setdirectname(e.target.value);
  }, []);

  const clickNotice = useCallback(async (notice_uid) => {
    try {
      const payload = await dispatch(
        getNoticeDetailAsync({notice_uid}),
      ).unwrap();

      await dispatch(setCurrentNotice(payload));
      await dispatch(setCurrentNotice_uid(notice_uid));

      setTimeout(() => {
        const url = `/owler/notices/noticeDetail/${notice_uid}`;
        window.open(url, '_blank');
      }, 1); // 1초 지연
    } catch (error) {
      console.error('Error fetching notice detail:', error);
    }
  }, []);

  return (
    <MuiModal open={noticeLinkAddmodal} onClose={handleModalClose}>
      <Container>
        <TitleWrapper>
          <Text bold margin="0 0 0 10px">
            공지 링크
          </Text>
          <BtnWrapper onClick={handleModalClose}>
            <CloseIcon />
          </BtnWrapper>
        </TitleWrapper>
        <SearchStore
          value={directname}
          onChange={(e) => handledNameChange(e)}
          placeholder="공지 검색"
        />
        <NoticeList>
          {masterNoticeList
            ?.filter((notice) =>
              notice.title.toLowerCase().includes(directname.toLowerCase()),
            )
            .map((notice) => {
              const isNoticeSelected = extraNoticeList.some(
                (item) => item.notice_uid === notice.notice_uid,
              );
              return (
                <Wrapper>
                  <Notice
                    key={notice.notice_uid}
                    onClick={() => {
                      clickNotice(notice.notice_uid);
                    }}
                  >
                    {notice.title}
                  </Notice>
                  <StyledMuiButton
                    fontSize="15px"
                    textColor="white"
                    backgroundColor={theme.colors.kalsPurple}
                    style={{
                      lineHeight: '15px',
                      height: '20px',
                      margin: '10px 0 0 0',
                    }}
                    onClick={() => {
                      dispatch(
                        setExtraNoticeList({
                          notice_uid: notice.notice_uid,
                          notice_title: notice.title,
                        }),
                      );
                    }}
                    text={isNoticeSelected ? <CheckIcon /> : '선택'}
                  />
                </Wrapper>
              );
            })}
        </NoticeList>
        <CustomPagination
          page={currentNoticePage}
          onChange={handlePaginationChange}
          count={count()}
          color="primary"
        />
      </Container>
    </MuiModal>
  );
};
export default AddNoticeModal;
