import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import Template from './template';
import {useSelector} from 'react-redux';
import {selectApp} from 'shared/utils/redux/appSlice';

const Page404 = () => {
  const {appType} = useSelector(selectApp);

  const navigate = useNavigate();
  const handleBackBtnClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleMainBtnClick = useCallback(() => {
    if (appType === 'owler') {
      navigate('owler/storeList');
    } else {
      navigate('franchise/dashboard');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  return (
    <Template
      handleBackBtnClick={handleBackBtnClick}
      handleMainBtnClick={handleMainBtnClick}
    />
  );
};

export default Page404;
