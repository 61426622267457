import styled from 'styled-components';
import theme from 'shared/styles/theme';
import PanoramaIcon from '@mui/icons-material/Panorama';
import {setSelectedImages} from 'franchise/utils/slices/frSupplementionSlice';
import {useDispatch} from 'react-redux';
import {useState, useRef, useEffect} from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const TableWrapper = styled.div`
  width: 1050px;
  margin: 20px 0 0 0;
`;

const TheTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed; /* 고정 테이블 레이아웃 사용 */
  border: 2px solid ${theme.colors.grey3};
  font-size: 15px;
  text-align: center;

  th,
  td {
    min-height: 32px;
    border: 2px solid ${theme.colors.grey3};
  }

  td {
    height: 161px;
    max-height: 161px !important;
    padding: 0 8px;
  }

  th {
    background-color: #dfd7f3;
    font-weight: 600;
  }
  .col-index {
    width: 3%;
  }
  .col-description {
    width: 48%;
  }
  .col-image {
    width: 17%;
  }
`;

const DetailImg = styled.img`
  width: 160px;
  height: 120px;
  margin-top: 10px;
  object-fit: contain;
  background-color: white;
  opacity: ${({count}) => (count > 1 ? '0.5' : '1')};
  &:hover {
    cursor: ${({count}) => (count > 1 ? 'pointer' : '')};
    transform: ${({count}) => (count > 1 ? 'scale(1.008)' : '')};
  }
`;

const Indicator = styled.div`
  position: absolute;
  top: 32px;
  left: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: #e6e6e6;
  color: #2e2e2e;
  font-size: 21px;
  pointer-events: none;
`;
const ImgWrapper = styled.div`
  diplay: flex;
  flex-direction: column;
  position: relative;
`;

const TextWithButton = styled.div`
  position: relative;
  min-height: 150px;
  padding: 3px 0;
`;
const AnimationP = styled.p`
  transition: max-height 0.5s ease-out;
  margin-bottom: 0px;
  white-space: pre-wrap;
`;
const ShowMoreButton = styled.button`
  position: absolute;
  border: none;
  top: 5px;
  right: -5px;
  color: white;
  height: 25px;
  border-radius: 5px;
  background-color: ${theme.colors.kalsPurple};
`;

const DescriptionCell = ({name, text}) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [maxWidth, setMaxWidth] = useState('113px');
  const [buttonText, setButtonText] = useState('down');
  const textRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState('1000px');

  useEffect(() => {
    if (textRef.current.scrollHeight > textRef.current.offsetHeight) {
      setIsOverflowing(true);
      setMaxHeight(textRef.current.scrollHeight + 'px'); // 실제 내용 높이를 기반으로 maxHeight 설정
    } else {
      setIsOverflowing(false);
    }
  }, [text]);

  return (
    <td>
      <TextWithButton>
        <p style={{textAlign: 'left'}}>{name}</p>
        <br />
        <AnimationP
          ref={textRef}
          style={{
            maxHeight: maxWidth,
            overflowY: 'hidden',
            textAlign: 'left',
          }}
        >
          {text}
        </AnimationP>
        {isOverflowing && (
          <ShowMoreButton
            onClick={() => {
              if (maxWidth === '113px') {
                setMaxWidth(maxHeight);
                setTimeout(() => setButtonText('up'), 500); // 상태를 'up'으로 변경
              } else {
                setMaxWidth('113px');
                setTimeout(() => setButtonText('down'), 500); // 상태를 'down'으로 변경
              }
            }}
          >
            {buttonText === 'down' ? (
              <ArrowDropDownIcon />
            ) : (
              <ArrowDropUpIcon />
            )}
          </ShowMoreButton>
        )}
      </TextWithButton>
    </td>
  );
};

const PdfBodyArea = ({supplementionList, setExtraImageShowModalOpen}) => {
  const dispatch = useDispatch();

  const formatDate = (date) => {
    if (date === null) {
      return '미완료';
    }
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    year = year.toString().substr(-2);

    return [year, month, day].join('-');
  };

  return (
    <TableWrapper>
      <TheTable border="1">
        <thead>
          <tr>
            <th className="col-index"></th>
            <th>매장</th>

            <th className="col-description">요청 내용</th>
            <th className="col-image">보완 이전</th>
            <th className="col-image">보완 이후</th>
          </tr>
          {supplementionList?.supplementation_objs?.results.map(
            (item, index) => {
              const beforeImageCount =
                item.before_supplementation_image_list.length;

              const afterImageCount =
                item.after_supplementation_image_list.length;

              return (
                <tr key={index.toString()}>
                  <td>{index + 1}</td>
                  <td>
                    <p
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {item.brand_name}
                    </p>
                    <p
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {item.branch_name}
                    </p>
                  </td>
                  <DescriptionCell
                    name={item.name}
                    text={item.description?.repeat(1)}
                  />
                  <td>
                    <ImgWrapper>
                      <DetailImg
                        src={item.before_supplementation_image_list[0]}
                        count={beforeImageCount}
                        onClick={() => {
                          if (beforeImageCount > 1) {
                            setExtraImageShowModalOpen(true);
                            dispatch(
                              setSelectedImages(
                                item.before_supplementation_image_list,
                              ),
                            );
                          }
                        }}
                      />
                      {item.before_supplementation_image_list.length > 1 && (
                        <Indicator>
                          <PanoramaIcon />
                          <p style={{margin: '-2px 0 0 2px'}}>
                            {item.before_supplementation_image_list.length}
                          </p>
                        </Indicator>
                      )}
                      <p>{formatDate(item.before_supplementation_date)}</p>
                    </ImgWrapper>
                  </td>
                  <td>
                    <ImgWrapper>
                      <DetailImg
                        src={item.after_supplementation_image_list[0]}
                        count={item.after_supplementation_image_list.length}
                        onClick={() => {
                          if (afterImageCount > 1) {
                            setExtraImageShowModalOpen(true);
                            dispatch(
                              setSelectedImages(
                                item.after_supplementation_image_list,
                              ),
                            );
                          }
                        }}
                      />
                      {item.after_supplementation_image_list.length > 1 && (
                        <Indicator>
                          <PanoramaIcon />
                          <p style={{margin: '-2px 0 0 2px'}}>
                            {item.after_supplementation_image_list.length}
                          </p>
                        </Indicator>
                      )}
                      <p>{formatDate(item.after_supplementation_date)}</p>
                    </ImgWrapper>
                  </td>
                </tr>
              );
            },
          )}
        </thead>
      </TheTable>
    </TableWrapper>
  );
};
export default PdfBodyArea;
