import React from 'react';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {MuiButton, MuiModal, Text} from 'shared/components/index';
import {selectTask, setDeleteTaskModal} from 'owler/utils/slices/taskSlice';
import theme from 'shared/styles/theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 320px;
  height: 300px;
  padding: 40px;

  & > * {
  }
`;

const TitleWrapper = styled.div`
  margin: 0 0 10px 0;
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`;

const TaskDeleteModal = ({handleDeleteBtnClick}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const {deleteTaskModal, selectedTask} = useSelector(selectTask);

  const handleModalClose = () => {
    dispatch(setDeleteTaskModal(false));
  };

  return (
    <MuiModal open={deleteTaskModal} onClose={handleModalClose}>
      <Container>
        <TitleWrapper>
          <Text bold fontSize="xl">
            삭제하시겠습니까?
          </Text>
        </TitleWrapper>

        <ButtonContainer>
          <MuiButton
            onClick={() => handleDeleteBtnClick(selectedTask, 1)}
            variant="contained"
            backgroundColor={theme.colors.danger}
            text="전체 업무 삭제"
            style={{width: '150px'}}
          />
          <br></br>
          <MuiButton
            onClick={() => handleDeleteBtnClick(selectedTask, 2)}
            variant="contained"
            backgroundColor={theme.colors.danger}
            text="해당 날짜만 삭제"
            style={{width: '150px'}}
          />
          <br></br>
          <MuiButton
            onClick={handleModalClose}
            variant="contained"
            backgroundColor={theme.colors.grey}
            text={t('buttons.cancel')}
            style={{width: '150px'}}
          />
        </ButtonContainer>
      </Container>
    </MuiModal>
  );
};

export default TaskDeleteModal;
