import styled from 'styled-components';
import {
  Text,
  FranchiseDrawer,
  FranchiseWrapper,
  Notification,
} from 'shared/components/index';
import theme from 'shared/styles/theme';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {useMemo} from 'react';

const Container = styled.div`
  width: 1100px;
  height: 45vh;
  margin-left: 60px;
`;

const PaginationContainer = styled.div`
  margin-top: 135px;
  margin-left: 80px;
`;
const CustomPagination = styled(Pagination)`
  & .MuiPaginationItem-page.Mui-selected {
    background-color: ${theme.colors.kalsPurple};
  }
  & .MuiPaginationItem-page.Mui-active {
    background-color: ${theme.colors.kalsPurple};
  }
  & .MuiPaginationItem-page:hover {
    background-color: ${theme.colors.kalsPurple}; // 원하는 색상으로 수정하세요.
  }
  & .MuiPaginationItem-page.Mui-selected:hover {
    background-color: #8f77d9; // 원하는 색상으로 수정하세요.
  }
`;
const NotificationsTemplate = ({
  loading,
  notifications,
  handleNoticeClick,
  handlePaginationChange,
  count,
}) => {
  const renderNotifications = useMemo(() => {
    if(notifications?.length === 0){
      return <p style={{fontSize:'20px'}}>읽지 않은 알림이 없습니다.</p>
    }
    return notifications?.map((notification, indx) => (
      <Notification
        key={indx.toString()}
        notification={notification}
        onPress={() => handleNoticeClick(notification)}
      ></Notification>
    ));
  }, [handleNoticeClick, notifications]);

  return (
    <FranchiseWrapper titleText="Notifications">
      <FranchiseDrawer>
        <Container>{renderNotifications}</Container>

        {notifications?.length > 0 && (
          <PaginationContainer>
            <Stack spacing={2}>
              <CustomPagination
                onChange={handlePaginationChange}
                count={count()}
                // color="primary"
              />
            </Stack>
          </PaginationContainer>
        )}
      </FranchiseDrawer>
    </FranchiseWrapper>
  );
};

export default NotificationsTemplate;
