import axios from 'axios';
import {getCookies} from './cookie';

// axios.defaults.baseURL = 'http://3.36.43.101:8000'; // Test API (v0.0.0)

//axios.defaults.baseURL = 'https://owlerfc-dev2.kr/api';

axios.defaults.baseURL = 'https://owler-user.co.kr/api/'; // this is live right now! v0.1.1

// axios.defaults.baseURL = "https://owlerdev.co.kr"; // Live API 1 (v0.0.0)

// axios.defaults.baseURL = 'https://owler-dev1.kr'; // Live API 2 (v0.1.0)

// axios.defaults.baseURL = 'https://corp-via.com'; // Live API 3 (v1.1.1) current

axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  (config) => {
    const token = getCookies(); // get the token from the cookie

    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // set the Authorization header with the token
    }
    return config;
  },
  (error) => Promise.reject('axios interceptor failed: ', error),
);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  /*
   ****************** USER *************************
   */
  // Login with phone number on web:
  webPhoneLogin: (data) => axios.post('/web/login', data),

  webEmailLogin: (data) => axios.post('/web/login/email', data),

  // Confirm if user exists  or not. Requires id.
  confirmUser: (data) => axios.post('/user-confirm', {id: data}),

  // Sign up
  signUpUser: (data) => axios.post('/signup', data),

  // Delete Account
  deleteAccount: (data) => axios.delete('/user-delete', {data: data}),

  // Log in
  loginUser: (data) => axios.post('/login', data),

  // Send phone validation code
  sendPhoneValidationCode: (data) => axios.post('/user/sending/phone', data), // new

  // Validation code check for password
  validatePhoneSMSCode: (data) =>
    axios.post('/user/validation/code/phone', data), // new

  /*
   ****************** EMAIL LOGIN *************************
   */

  postEmailLogin: (data) => axios.post('/email-login', data),

  // Get user's stores
  getUserStores: (uid) => axios.get(`/web/store/list?user_uid=${uid}`),

  // Get user profile
  getUserProfile: (param) =>
    axios.get(
      `/member-profile?storeuid=${param.storeUID}&useruid=${param.userUID}`,
    ),

  // Edit user profile
  editUserProfile: (data) => axios.patch('/member-profile', data),

  // Change visibility of user profile phone number open status
  changePhoneOpenStatus: (data) => axios.post('/members/open', data),

  // Get the pictures for ios ad
  getIosAdImage: () => axios.get('/Ad-ios'),

  // Get the pictures for android ad
  getAndroidAdImage: () => axios.get('/Ad-android'),

  // Find user email:
  getUserEmailAddress: (param) => axios.get(`/search-password?phone=${param}`),

  /*
   ****************** STORE *************************
   */

  // Get all stores
  getMember: (store_uid) =>
    axios.get(`/web/store/member/info?store_uid=${store_uid}`),
  getAllStores: () => axios.get('/all-stores'),

  // when opened main dashboard we call this api:
  getStoreMainPage: (param) =>
    axios.get(
      `/store-main-page?storeuid=${param.uid}&start=${param.start}&end=${param.end}`,
    ),

  getAppliedWorkplaces: (uid) => axios.get(`/join/wait?useruid=${uid}`),

  getStoreJoinWaitList: (uid) => axios.get(`/join?storeuid=${uid}`),

  // Request to join a store
  requestToJoinStore: (data) => axios.post('/participate', data),

  // Register store
  registerStore: (data) => axios.post('/store', data),

  // Delete Store
  deleteStore: (data) => axios.delete('/store', {data: data}),

  // Get store info
  getStoreInfo: (uid) => axios.get(`/store?storeuid=${uid}`),

  // Get demo store info
  getDemoStoreInfo: (uid) => axios.get(`/demo-store?storeUID=${uid}`),

  // Get store members
  getStoreMembers: (uid) => axios.get(`/members?storeuid=${uid}`),

  // Get a list of store members
  getListOfStoreMembers: (uid) => axios.get(`/members/show?storeuid=${uid}`),

  // Get a list of store members - it is gone !
  getListOfCurrentStoreMembers: (uid) =>
    axios.get(`/members/current?storeuid=${uid}`),

  // Accept memeber's join request
  acceptMemberRequest: (data) => axios.post('/join', data),

  // Reject memeber's join request
  rejectMemberRequest: (param) => axios.delete('/join', {data: param}),

  // Manage store members
  manageStoreMembers: (data) => axios.patch('/members', data),

  // Reject memeber's join request
  deleteStoreMembers: (param) => axios.delete('/members', {data: param}),

  // Verify store name
  verifyStoreName: (name) => axios.get(`/store-name-verify?storename=${name}`),

  //Verify store business registration number
  verifyStoreBusinessNumber: (num) => axios.post('/business-status', num),

  // Calculate salary
  calculateSalary: (data) => axios.post('/salary', data),

  // Get a list of store svs
  getSvWaitList: (uid) => axios.get(`/store-supervisor-join?storeUID=${uid}`),

  // Accept sv to a store
  acceptSvJoinRequest: (data) => axios.post('/store-supervisor-join', data),

  // Delete sv join req
  deleteSvJoinRequest: (data) =>
    axios.delete('/store-supervisor-join', {data: data}),

  // Get store profile
  getStoreProfile: (uid) => axios.get(`/store?storeuid=${uid}`),

  // Get store profile
  patchStoreProfile: (data) => axios.patch('store', data),

  // Get store attendance settings
  getAttendanceSettings: (param) =>
    axios.get(`/store/attendance-setting?storeuid=${param.storeUID}`),

  // Post store attendance settings
  patchAttendanceSettings: (data) =>
    axios.patch('/store/attendance-setting', data),

  // Pin store
  postPinStore: (data) => axios.post('/user/pin', data),

  /*
   ****************** TASKS *************************
   */

  // Get store tasks
  getStoreTasks: (uid) => axios.get(`/tasks?storeuid=${uid}`),

  // Get demo tasks
  getDemoStoreTasks: (uid) => axios.get(`/demo-tasks?storeUID=${uid}`),

  // Get store tasks by date
  getStoreTasksByDate: (param) =>
    axios.get(`/web/task/list?store_uid=${param.store_uid}&date=${param.date}`),

  // complete checker task
  completeCheckerTask: (data) => axios.post('/task-completion', data),

  // delete task image completion
  deleteImageTaskCompletion: (data) =>
    axios.delete('/task-completion', {data: data}),

  deleteOneImageTaskCompletion: (data) =>
    axios.delete('/tasks/images/delete', {data: data}),

  // make checker task incomplete
  notCompleteCheckerTask: (data) =>
    axios.delete('/task-completion', {data: data}),

  // post image task
  postImageTask: (param) =>
    axios.post('/task-completion', param, {
      headers: {
        // 'Content-Type': 'multipart/form-data;boundary=----WebKitFormBoundaryyrV7KO0BoCBuDbTL',
        'Content-Type': 'multipart/form-data',
      },
    }),

  // edit image task
  editImageTask: (param) =>
    axios.patch('/task-completion', param, {
      headers: {
        // 'Content-Type': 'multipart/form-data;boundary=----WebKitFormBoundaryyrV7KO0BoCBuDbTL',
        'Content-Type': 'multipart/form-data',
      },
    }),

  // add task category
  addTaskCategory: (data) => axios.post('/web/task/category', data),

  // edit task category
  editTaskCategory: (data) => axios.patch('/web/task/category', data),

  // delete task category
  deleteStoreTaskCategory: (data) =>
    axios.delete('/web/task/category', {data: data}),

  getTask: (data) => axios.get(`/web/task/?task_uid=${data.task_uid}`),
  // register a new task
  registerNewTask: (data) =>
    axios.post('/web/task/', data, {
      headers: {'Content-Type': 'multipart/form-data'},
    }),

  // edit the task
  editTask: (data) =>
    axios.patch('/web/task/', data, {
      headers: {'Content-Type': 'multipart/form-data'},
    }),

  // delete a task
  deleteTask: (data) => axios.delete('/web/task/', {data: data}),

  // get task list to show or not show basic setting popup:
  checkStoreTaskCategories: (param) =>
    axios.get(`/check-task?storeUID=${param.storeUID}`),

  // post task basic setting:
  postTaskBasicSetting: (data) => axios.post('/task-basic-setting', data),

  /*
   ****************** NOTICE *************************
   */

  // get store notices
  getStoreNotices: (uid) => axios.get(`/notice?storeuid=${uid}`),

  // get notices by page number
  getNoticesByPage: (param) =>
    axios.get(
      `/web/notice/list?page=${param.page}&store_uid=${param.store_uid}`,
    ),

  getResponderInfo: (param) =>
    axios.get(`/web/notice/hq/response?notice_uid=${param}`),

  // get sv notice details:
  getNoticeDetail: (param) =>
    axios.get(`/web/notice/?notice_uid=${param.notice_uid}`),

  getNoticeResponse: (param) =>
    axios.get(`/web/notice/response?notice_uid=${param.notice_uid}`),

  // create a new notice
  createNewNotice: (data) =>
    axios.post('/web/notice/', data, {
      headers: {'Content-Type': 'multipart/form-data'},
    }),

  // pin the notice:
  pinNotice: (data) => axios.post('web/notice/pin', data),

  // delete the notice pin
  deleteNoticePin: (data) => axios.delete('web/notice/pin', {data: data}),

  // edit store's notices
  editANotice: (data) => axios.patch('/web/notice/', data),

  // delete store's notices
  deleteANotice: (data) => axios.delete('/web/notice/', {data: data}),

  // when user checks a check notice
  responseToCheckerNotice: (data) => axios.post('/notice/response', data),

  // register store's notice responses
  responseToImageNotice: (data) =>
    axios.post('/notice/response', data, {
      headers: {'Content-Type': 'multipart/form-data'},
    }),

  // delete response (image) to Image Notice post
  deleteResponseToImgNotice: (data) =>
    axios.delete('/notice/response', {data: data}),

  // add comment
  makeNoticeComment: (data) => axios.post('/notice/comment', data),

  // reply comment
  makeNoticeCommentReply: (data) => axios.patch('/notice/comment', data),

  // delete comment
  deleteNoticeComment: (data) => axios.delete('/notice/comment', {data: data}),

  // Get store notices search
  getNoticesBySearch: (param) =>
    axios.get(`/notice/search?storeuid=${param.storeUID}&word=${param.word}`),

  /*
   ****************** OWLER - DASHBOARD *************************
   */

  // GET :: Unread chat total count
  getUnreadMessagesCount: (param) =>
    axios.get(
      `/chat/web-owler/unread/total-count?member_uid=${param.memberUID}`,
    ),

  // GET :: Unread notification count
  getUnreadNotificationsCount: (param) =>
    axios.get(`/web/dashboard/notification/count?store_uid=${param.store_uid}`),

  // GET :: Task status daily
  getDailyTaskStatus: (param) =>
    axios.get(
      `/web/dashboard/task-status/daily?store_uid=${param.store_uid}&date=${param.date}`,
    ),
  getBrandDailyTaskStatus: (param) =>
    axios.get(
      `/web/dashboard/brand/task-status/daily?store_uid=${param.store_uid}&date=${param.date}`,
    ),
  getBrandTaskStatusList: (param) =>
    axios.get(
      `/web/dashboard/brand/task-status/list?store_uid=${param.store_uid}`,
    ),

  getBrandRealTimeImageTask: (param) =>
    axios.get(
      `/web/dashboard/brand/real-time/image-task?store_uid=${param.store_uid}`,
    ),

  // GET :: Task status weekly
  getWeeklyTaskStatus: (param) =>
    axios.get(
      `/web/dashboard/task-status/weekly?store_uid=${param.store_uid}&year=${param.year}&month=${param.month}&week=${param.week}`,
    ),

  getBrandWeeklyTaskStatus: (param) =>
    axios.get(
      `/web/dashboard/brand/task-status/weekly?store_uid=${param.store_uid}&year=${param.year}&month=${param.month}&week=${param.week}`,
    ),
  // GET :: Task status monthly
  getMonthlyTaskStatus: (param) =>
    axios.get(
      `/web/dashboard/task-status/monthly?store_uid=${param.store_uid}&year=${param.year}`,
    ),
  getBrandMonthlyTaskStatus: (param) =>
    axios.get(
      `/web/dashboard/brand/task-status/monthly?store_uid=${param.store_uid}&year=${param.year}`,
    ),

  // GET :: Real-time picture task status
  getRealTimePictureTaskStatus: (param) =>
    axios.get(
      `/web/dashboard/real-time/image-task?store_uid=${param.store_uid}`,
    ),

  // GET :: Today work schedule
  getTodaysWorkSchedule: (param) =>
    axios.get(
      `/web/dashboard/schedule?store_uid=${param.store_uid}&date=${param.date}`,
    ),

  // GET :: Current worker
  getCurrentWorkers: (param) =>
    axios.get(`/web-owler/dashboard/now-worker?store_uid=${param.storeUID}`),

  // GET :: Review summary
  getTaskReviewSummary: (param) =>
    axios.get(`/web/dashboard/review?store_uid=${param.store_uid}`),

  getBrandTodaySchedule: (param) =>
    axios.get(
      `/web/dashboard/brand/today-schedule?store_uid=${param.store_uid}`,
    ),

  getBrandNewRequest: (param) =>
    axios.get(`/web/dashboard/brand/new-request?store_uid=${param.store_uid}`),

  getBrandUncheckNotice: (param) =>
    axios.get(
      `/web/dashboard/brand/unchecked-notice?store_uid=${param.store_uid}`,
    ),

  getStoreBrandList: (param) =>
    axios.get(`/web/store/brand/list?store_uid=${param.store_uid}`),

  getBrandTaskStatus: (param) =>
    axios.get(
      `/web/dashboard/brand/task-status/each?store_uid=${param.store_uid}&date=${param.date}&task_type=${param.task_type}`,
    ),
  /*
   ****************** FRANCHISE *************************
   */

  // get franchise store's infomation
  getFranchiseStores: (uid) =>
    axios.get(`/web/notice/my/franchise/list?store_uid=${uid}`),

  // post franchisee code
  postFranchiseeCode: (data) => axios.post('/store-franchisee-code', data),

  // get franchise store's infomation
  getFranchiseStoreInfo: (param) =>
    axios.get(
      `/store-franchisee-main?memberUID=${param.memberUID}&franchisestoreid=${param.franchiseStoreID}`,
    ),

  getSupplementationDashboard: () =>
    axios.get(`/web/franchise/dashboard/qsc-supplementation`),
  // get franchise store's sv-meeting schedules
  getSvMeetingSchedules: (param) =>
    axios.get(
      `/store-schedule?memberUID=${param.memberUID}&franchiseStoreID=${param.franchiseStoreID}`,
    ),

  // create sv-meeting schedule:
  createSvMeetingSchedule: (data) => axios.post('/store-schedule', data),

  // update Schedule Reponse:
  updateSvScheduleResponse: (data) => axios.patch('/store-schedule', data),

  // delete Schedule Reponse:
  deleteSvScheduleResponse: (data) =>
    axios.delete('/store-schedule', {data: data}),

  // get owler owner signature:
  getOwnerSignature: (storeUID) =>
    axios.get(`franchisee/owner-signature?storeuid=${storeUID}`),

  // get owler owner signature:
  postOwnerSignature: (data) => axios.post('/franchisee/qsc/sign/owner', data),

  // get owler owner signature:
  deleteOwnerSignature: (data) =>
    axios.delete('franchisee/owner-signature', {data: data}),

  /*
   ****************** COMMUNICATION POSTS *************************
   */

  // get communication posts
  getCommunicationPosts: (uid) => axios.get(`/communication?storeuid=${uid}`),

  // get received communication posts by page number
  getReceivedPostsByPage: (param) =>
    axios.get(`/communication?page=${param.page}&storeuid=${param.uid}`),

  // get sent communication posts by page number
  getSentPostsByPage: (param) =>
    axios.get(`/sentcommunication?page=${param.page}&storeuid=${param.uid}`),

  // make a new communication post
  makeNewCommunicationPost: (data) =>
    axios.post('/communication', data, {
      headers: {'Content-Type': 'multipart/form-data'},
    }),

  // edit communication post
  editCommunicationPost: (data) =>
    axios.patch('/communication', data, {
      headers: {'Content-Type': 'multipart/form-data'},
    }),

  // delete communication
  deleteCommunicationPost: (data) =>
    axios.delete('/communication', {data: data}),

  // add comment
  makeNewComment: (data) => axios.post('/comment', data),

  // delete comment
  deleteComment: (data) => axios.delete('/comment', {data: data}),

  /*
   ****************** REVIEW *************************
   */

  // get store review info
  getStoreReviewInfo: (uid) => axios.get(`/review/info?storeuid=${uid}`),

  /*
   ****************** SCHEDULE *************************
   */

  // get work suggestion list (swap and holiday break)
  getWorkSuggestionList: (uid) => axios.get(`/work-suggestion?storeuid=${uid}`),

  // apply for schedule change / holiday break
  postWorkSuggestion: (data) => axios.post('/work-suggestion', data),

  // edit schedule change / holiday break
  editWorkSuggestion: (data) => axios.patch('/work-suggestion', data),

  // delete application for schedule change / holiday break
  deleteWorkSuggestion: (data) =>
    axios.delete('/work-suggestion', {data: data}),

  // fetch user schedules list
  getUserSchedules: (param) =>
    axios.get(
      `/work-schedule?storeuid=${param.uid}&start=${param.start}&end=${param.end}`,
    ),

  // fetch user Replace schedules list
  getUserReplaceSchedules: (param) =>
    axios.get(
      `/work-replace?storeuid=${param.uid}&start=${param.start}&end=${param.end}`,
    ),

  // create a new schedule
  createNewSchedule: (data) => axios.post('/work-schedule', data),

  // edit a schedule
  editSchedule: (data) => axios.patch('/work-schedule', data),

  // delete a schedule
  deleteSchedule: (data) => axios.delete('/work-schedule', {data: data}),

  // get working days a user
  getUserWorkingDays: (param) =>
    axios.get(
      `/my-working-time?storeuid=${param.storeUID}&useruid=${param.userUID}&date=${param.date}`,
    ),

  getTodaySchedule: (uid) => axios.get(`/today-schedule?storeuid=${uid}`),

  /*******************    comute  ****************/

  getStoreMemberList: (data) =>
    axios.get(`/web/schedule/commute/members?store_uid=${data.store_uid}`),

  getMemberCommuteLog: (data) =>
    axios.get(
      `/web/schedule/commute/log?member_uid=${data.member_uid}&year=${data.year}&month=${data.month}`,
    ),

  /*
   ****************** COMMUNITY *************************
   */

  // get all community posts
  getCommunityPosts: (param) =>
    axios.get(
      `/community?page=${param.pageNum}&categoryUID=${param.catUID}&subCategoryUID=${param.subCatUID}&profileUID=${param.profileUID}&sort=${param.type}`,
    ),

  // get only one community post
  getACommunityPost: (param) =>
    axios.get(`/post?profileUID=${param.profileUID}&postUID=${param.postUID}`),

  createCommunityPost: (data) => axios.post('/post', data),

  editCommunityPost: (data) => axios.patch('/post', data),

  deleteCommunityPost: (data) => axios.delete('/post', {data}),

  // Community VOTE response
  voteRequest: (data) => axios.post('/vote', data),

  // Community post comment request
  createComComment: (data) => axios.post('/commu-comment', data),

  deleteComComment: (data) => axios.delete('/commu-comment', {data}),

  /*
   ****************** COMMUNITY-USER *************************
   */

  // create a community user profile
  createComUser: (data) => axios.post('/change-nickname', data),

  // block user:
  blockComUser: (data) => axios.post('/block-user', data),

  // create a community business certification
  createComCertificate: (data) => axios.post('/certifacation', data),

  // user's post responses: likes, bookmarks, views ...
  userPostResponse: (data) => axios.post('/post-option', data),

  userPostCommentResponse: (data) => axios.post('/comment-option', data),

  // get user's bookmarked posts
  getUserBookmarks: (param) =>
    axios.get(`/bookmark?profileUID=${param.profileUID}`),

  // get user's community posts
  getUserComPosts: (param) =>
    axios.get(`/myposts?profileUID=${param.profileUID}&page=${param.pageNum}`),

  // get user's post notifications
  getUserPostNotifications: (param) =>
    axios.get(
      `/notification?profileUID=${param.profileUID}&page=${param.pageNum}`,
    ),

  // get expert user's business card
  getExpertBusinessCard: (param) =>
    axios.get(`/namecard?profileUID=${param.profileUID}`),

  // update expert user's business card
  updateExpertBusinessCard: (data) => axios.post('/namecard', data),

  // report community post or comment
  reportPostComment: (data) => axios.post('/report', data),

  // get all reports list
  getReportList: (param) => axios.get(`/report?profileUID=${param.profileUID}`),

  // delete reported content, comments and posts
  deleteReportedContent: (data) => axios.delete('/report', {data: data}),

  // Post send or delete at Hot Category...
  postAtHotCategory: (data) => axios.patch('/select-post', data),

  /*
   ****************** POSITION *************************
   */

  getSvSign: () => axios.get(`/web/franchise/sv/signature`),

  postSvSign: (data) =>
    axios.post(`/web/franchise/sv/signature`, data, {
      headers: {'Content-Type': 'multipart/form-data'},
    }),

  // get PositionList
  getPositionLists: (uid) => axios.get(`/position?storeUID=${uid}`),

  // get PositionList
  getFranchisePositions: (sv_user_uid) =>
    axios.get(`/web/franchise/position?sv_user_uid=${sv_user_uid}`),

  postFranchisePosition: (data) => axios.post('/web/franchise/position', data),

  patchFranchisePosition: (data) =>
    axios.patch('/web/franchise/position', data),

  deleteFranchisePosition: (data) =>
    axios.delete('/web/franchise/position', {data}),

  patchSvPosition: (data) => axios.patch('/web/franchise/sv/position', data),

  // create a Position
  postPositionLists: (data) => axios.post('/position', data),

  // edit a Position
  editPositionLists: (data) => axios.patch('/position', data),

  // delete a Position
  deletePositionLists: (data) => axios.delete('/position', {data: data}),

  /*
   ******************CHATTING wiz Supervisor*************************
   */

  getSvChatRoom: (param) =>
    axios.get(`/chat/with-sv/?storeUID=${param.storeUID}`),

  getSvChatMessages: (param) =>
    axios.get(
      `/chat/with-sv/${param.roomID}/${param.isSV}/?page=${param.pageNum}`,
    ),

  deleteUserSvChat: (data) => axios.delete('/chat/', {data: data}),

  /*
   ****************** Memo *************************
   */

  // get memos
  getMemoes: (uid) => axios.get(`/transition?storeuid=${uid}`),

  // create a memo
  postMemo: (data) => axios.post('/transition', data),

  // delete a momo
  deleteMemo: (data) => axios.delete('/transition', {data: data}),

  // edit a memo
  editMemo: (data) => axios.patch('/transition', data),

  // check a memo
  postCheckMemo: (data) => axios.post('/transition/check', data),

  /*
   ****************** Q S C *************************

   */

  getFranchiseStoresQsc: () => axios.get(`/web/franchise/qsc/writing/list`),

  getQscProcessListByPage: (param) =>
    axios.get(
      `/web/franchise/qsc/list?&page=${param.page}&sort=${param.sort}&date=${param.date}`,
    ),

  // qsc owner summary:
  getQSCOwnerSummaryData: (param) =>
    axios.get(`franchisee/qsc/summary/owner?qscuid=${param.qscUID}`),

  // qsc summary:
  getQSCSummaryData: (param) =>
    axios.get(`/franchisee/qsc/summary?qscuid=${param.qscUID}`),

  // finish qsc process
  completeQSCProcess: (data) => axios.post('/franchisee/qsc/finish', data),

  // save qsc
  saveQSCAnalysis: (data) => axios.post('/franchisee/qsc/analysis', data),

  // get QSC Checklist detail:
  getQSCChecklistDetail: (param) =>
    axios.get(`/franchisee/qsc/checklist/detail?detailuid=${param.detailUID}`),

  // update qsc checklist
  udpateQSCchecklist: (data) => axios.post('/franchisee/qsc/checklist', data),

  // create a note for a qsc checklist
  commentQSCChecklist: (data) =>
    axios.post('/franchisee/qsc/checklist/detail', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),

  getQSCHistory: (param) =>
    axios.get(`/franchisee/qsc/before?qscuid=${param.qscUID}`),

  // show qsc process history
  getQSCProcessHistory: (param) =>
    axios.get(`/franchisee/qsc/history?storeuid=${param.storeUID}`),

  // load all the images and signatures and user's data:
  getQscApprovalLine: (param) =>
    axios.get(`/franchisee/qsc/approval-line?qscuid=${param.qscUID}`),

  // load signatures from server:
  getOwnerSignaturesFromServer: (param) =>
    axios.get(`franchisee/owner-signature/load?qscuid=${param.qscUID}`),

  /*
   ****************** SV NOTICES *************************
   */

  // get sv notices:
  getSvNotices: (param) =>
    axios.get(`/sv-notice?page=${param.page}&storeUID=${param.storeUID}`),

  // post sv notice:
  postSvNotice: (data) => axios.post('/franchisee/sv-notice', data),

  // edit sv notice:
  patchSvNotice: (data) => axios.patch('/franchisee/sv-notice', data),

  // delete sv notice:
  deleteSvNotice: (data) => axios.delete('/franchisee/sv-notice', {data: data}),

  responseToCheckSvNotice: (data) => axios.post('/sv-notice-response', data),

  responseToImageSvNotice: (data) => axios.post('/sv-notice-response', data),

  /*
   ****************** RECIPE *************************
   */

  // Get store recipes
  getRecipes: (uid) => axios.get(`/recipe?storeuid=${uid}`),

  // get manual basic setting:
  checkStoreManualClassific: (param) =>
    axios.get(`/check-recipe?storeUID=${param.storeUID}`),

  // post manual basic setting:
  postManualBasicSetting: (data) => axios.post('/recipe-basic-setting', data),

  // get store recipes
  getRecipesBySearch: (param) =>
    axios.get(`/recipe-search?storeuid=${param.storeUID}&word=${param.word}`),

  // post recipe category
  postRecipeClassification: (data) => axios.post('/classification', data),

  // update recipe category
  updateRecipeClassification: (data) => axios.patch('/classification', data),

  // delete recipe category
  deleteRecipeClassification: (data) =>
    axios.delete('/classification', {data: data}),

  // post recipe
  postNewRecipe: (data) => axios.post('/recipe', data),

  // update recipe
  updateRecipe: (data) => axios.patch('/recipe', data),

  // delete recipe
  deleteRecipe: (data) => axios.delete('/recipe', {data: data}),

  /*
   ****************** ATTENDANCE *************************
   */

  // Get user's attendance
  getAttendanceStatus: (param) =>
    axios.get(`/attendance?storeuid=${param.storeUID}`),

  // user arrived at
  postUserArrived: (data) => axios.post('/arrive', data),

  // user leaving
  postUserLeaving: (data) => axios.post('/leave', data),

  // check if user left the store
  getIfUserLeftStore: (param) =>
    axios.get(`/leave/check?storeuid=${param.storeUID}`),

  /*
   ****************** REVIEW *************************
   */

  // Get store reviews
  getRecentStoreReviews: (param) =>
    axios.get(
      `/review/recent?page=${param.page}&storeuid=${param.uid}&category=${param.category}`,
    ),

  // Get store reviews
  getStoreReviews: (param) =>
    axios.get(
      `/review?page=${param.page}&storeuid=${param.uid}&category=${param.category}`,
    ),

  // Delete a store review
  deleteStoreReview: (data) => axios.delete('/review', {data: data}),

  /*
   ****************** NOTIFICATIONS *************************
   */

  // get sv notifications:
  getNotifications: (param) =>
    axios.get(
      `/web/dashboard/notification?member_uid=${param.member_uid}&page=${param.page}`,
    ),
  getNotificationsDetail: (param) =>
    axios.get(
      `/web/dashboard/notification/detail?notification_uid=${param.notification_uid}`,
    ),

  // view sv notification:
  getViewNotificationDetail: (param) =>
    axios.get(
      `web/franchise/dashboard/notification/detail?sv_notification_uid=${param?.sv_notification_uid}`,
    ),

  /*
   ****************** USER PORTFOLIO *************************
   */

  // get user portfolio info:
  getUserPortfolio: (param) =>
    axios.get(
      `/ai-portfolio?storeuid=${param.storeUID}&useruid=${param.userUID}&year=${param.year}&month=${param.month}`,
    ),

  // get user's old portfolio list:
  getUserOldPortfolioList: (param) =>
    axios.get(
      `/ai-portfolio/list?storeuid=${param.storeUID}&useruid=${param.userUID}`,
    ),

  // get member profile for the portfolio:
  getMemberProfilePortfolio: (param) =>
    axios.get(
      `/portfolio/profile?storeuid=${param.storeUID}&useruid=${param.userUID}&year=${param.year}&month=${param.month}`,
    ),

  // get member's rating (they call it GPA, lol):
  getMemberGPA: (param) =>
    axios.get(
      `/gpa?storeuid=${param.storeUID}&useruid=${param.userUID}&year=${param.year}&month=${param.month}`,
    ),

  // get member's main working hours
  getMemberMainWorkHours: (param) =>
    axios.get(
      `/main-working-time?storeuid=${param.storeUID}&useruid=${param.userUID}&year=${param.year}&month=${param.month}`,
    ),

  /*
   ****************** AI TASKS & KEYWORDS *************************
   */

  // get store task status weekly :
  getAITaskStatusByWeeks: (param) =>
    axios.get(
      `/ai/task-status?storeuid=${param.storeUID}&year=${param.year}&month=${param.month}&type=1&weeks=${param.weekNum}`,
    ),

  // get store task status monthly:
  getAITaskStatusByMonths: (param) =>
    axios.get(
      `/ai/task-status?storeuid=${param.storeUID}&year=${param.year}&month=${param.month}&type=2`,
    ),

  // get store AI keyword:
  getAIKeyword: (param) => axios.get(`/ai/keyword?storeuid=${param.storeUID}`),

  // update store AI keyword
  updateAIKeyword: (data) => axios.patch('/ai/keyword', data),

  // get store AI keyword Analysis:
  getAIKeywordAnalysis: (param) =>
    axios.get(`/ai/keyword/analysis?keyword=${param.keyword}`),

  // get related summarized article:
  getAiSummarizedArticles: (param) =>
    axios.get(`/article/summarized?keyword=${param.keyword}`),

  // get related news:
  getAiRelatedNews: (param) =>
    axios.get(`/article/news?keyword=${param.keyword}`),

  // get related blogs:
  getAiRelatedBlogs: (param) =>
    axios.get(`/article/blog?keyword=${param.keyword}`),

  // get related cafes:
  getAiRelatedCafes: (param) =>
    axios.get(`/article/cafe?keyword=${param.keyword}`),

  /*
   ****************** MEMBER STORAGE *************************
   */

  // get member storage:
  getMemberStorage: (param) =>
    axios.get(
      `/member-storage?page=${param.page}&memberUID=${param.memberUID}`,
    ),

  // post to member storage:
  postToMemberStorage: (data) => axios.post('/member-storage', data),

  // delete from member storage:
  deleteFromMemberStorage: (data) =>
    axios.post('/member-storage', {data: data}),

  /*
   ************* suggest
   */
  getRequestList: (param) =>
    axios.get(
      `/web/request/list?page=${param.page}&store_uid=${param.store_uid}`,
    ),
  getRequestBrandList: (param) =>
    axios.get(
      `/web/request/list/brand?page=${param.page}&store_uid=${param.store_uid}`,
    ),

  getRequestDetail: (param) =>
    axios.get(`/web/request/?request_uid=${param.request_uid}`),

  getRequestStatus: (param) =>
    axios.get(
      `/web/request/status?request_uid=${param.request_uid}&level=${param.level}`,
    ),

  patchRequestStatus: (param) => axios.patch(`/web/request/status`, param),

  getRequestGroup: (param) =>
    axios.get(`/web/request/group?request_uid=${param.request_uid}`),

  patchRequestGroup: (param) => axios.patch(`/web/request/group`, param),

  postRequestComment: (param) => axios.post(`/web/request/comment`, param),

  patchRequestComment: (param) => axios.patch(`/web/request/comment`, param),

  deleteRequestComment: (param) =>
    axios.delete(`/web/request/comment`, {data: param}),

  postRequestPin: (param) => axios.post(`/web/request/group`, param),

  deleteRequestPin: (param) =>
    axios.delete(`/web/request/group`, {data: param}),

  // // // // // // // // // // // // // // // // // // // // // // // // ///

  /******************************** Calendar *************************************/

  getCalendarSchedule: (param) =>
    axios.get(`/web/schedule/?schedule_uid=${param.schedule_uid}`),

  postCalendarSchedule: (param) => axios.post(`/web/schedule/`, param),

  patchCalendarSchedule: (param) => axios.patch(`/web/schedule/`, param),

  deleteCalendarSchedule: (param) =>
    axios.delete(`/web/schedule/`, {data: param}),

  getCalendar: (param) =>
    axios.get(
      `/web/schedule/calendar?store_uid=${param.store_uid}&date=${param.date}`,
    ),

  getFranchiseList: (param) =>
    axios.get(`/web/schedule/franchise/list?store_uid=${param.store_uid}`),

  /*
   ****************** setting *************************
   */

  getPhoneJoinMember: (param) =>
    axios.get(
      `/web/store/member/invitation?phone_number=${encodeURIComponent(
        param.phone_number,
      )}`,
    ),

  postPhoneJoinMember: (data) =>
    axios.post(`/web/store/member/invitation`, data),

  getJoinMember: (param) =>
    axios.get(
      `/web/store/member/join/admin?store_uid=${param.store_uid}&memberUID=${param.memberUID}`,
    ),
  postJoinMember: (param) => axios.post(`/web/store/member/join/admin`, param),
  deleteJoinMember: (param) =>
    axios.delete(`/web/store/member/join/admin`, {data: param}),

  getAllMembers: (param) =>
    axios.get(`/web/store/member/manage?store_uid=${param.store_uid}`),
  patchAllMembers: (param) => axios.patch(`/web/store/member/manage`, param),

  deleteAllMembers: (param) =>
    axios.delete(`/web/store/member/manage`, {data: param}),

  getAllPositions: (param) =>
    axios.get(`/web/store/position?store_uid=${param.store_uid}`),

  postAllPositions: (param) => axios.post(`/web/store/position`, param),
  patchAllPositions: (param) => axios.patch(`/web/store/position`, param),

  deleteAllPositions: (param) =>
    axios.delete(`/web/store/position`, {data: param}),
  getBrandAssignMentList: (param) =>
    axios.get(
      `/web/store/brand/assignment/list?user_uid=${param.user_uid}&store_uid=${param.store_uid}`,
    ),
  postBrandAssignMent: (param) =>
    axios.post(`/web/store/brand/assignment`, param),
  /*
   ******************F*************************
   ******************R*************************
   ******************A*************************
   ******************N*************************
   ******************C*************************
   ******************H*************************
   ******************I*************************
   ******************S*************************
   ******************E*************************
   */

  /*
   ******************USER*************************
   */

  // Log in franchise user
  supervisorLogin: (data) => axios.post('/franchisee/login', data),

  // Refresh login
  userLoginRefresh: (data) =>
    axios.get(`/franchisee/main-page?parentUserUID=${data.uid}`),

  // Sign in franchise user
  supervisorSignUp: (data) => axios.post('/franchisee/signup', data),

  // Delete user
  deleteSvUser: (data) => axios.delete('/franchisee/user-delete', {data: data}),

  // get sv owner signature:
  getSvSignature: () => axios.get('/franchisee/sv-signature'),

  // get sv owner signature:
  postSvSignature: (data) => axios.post('/franchisee/sv-signature', data),

  // get sv owner signature:
  deleteSvSignature: () => axios.delete('/franchisee/sv-signature'),

  // get franchise brand color:
  getUserColor: () => axios.get('franchisee/color'),

  /*
   ****************** FRANCHISE - DASHBOARD *************************
   */
  getFranchiseName: () => axios.get(`web/franchise/dashboard/corp-name`),

  // GET :: Unread notification count
  getSvUnreadNotificationsCount: (param) =>
    axios.get(
      `/web/franchise/dashboard/notification/count?sv_user_uid=${param.sv_user_uid}`,
    ),

  // GET :: SV QSC Status
  getSvQSCStatus: (param) =>
    axios.get(
      `/web/franchise/dashboard/qsc/status?sv_user_uid=${param.sv_user_uid}`,
    ),

  // GET :: SV Today Schedule
  getSvTodaysSchedule: (param) =>
    axios.get(
      `/web/franchise/dashboard/schedule?sv_user_uid=${param.sv_user_uid}`,
    ),

  // GET :: Management store score status weekly
  getWeeklyManagementStoreScore: (param) =>
    axios.get(
      `/web/franchise/dashboard/score-status/weekly?sv_user_uid=${param.sv_user_uid}&year=${param.year}&month=${param.month}&week=${param.week}`,
    ),

  // GET :: Management store score status monthly
  getMonthlyManagementStoreScore: (param) =>
    axios.get(
      `/web/franchise/dashboard/score-status/monthly?sv_user_uid=${param.sv_user_uid}&year=${param.year}`,
    ),

  // GET :: Visit store status weekly
  getWeeklyVisitStoreStatus: (param) =>
    axios.get(
      `/web/franchise/dashboard/visit-store/weekly?sv_user_uid=${param.sv_user_uid}&year=${param.year}&month=${param.month}&week=${param.week}`,
    ),

  /*
   ******************STORE*************************
   */

  // Get franchise store list
  getFranchiseStoreList: (uid) =>
    axios.get(`/franchisee/search-store-list?userUID=${uid}`),

  // Add to supervisor store list
  addToSupervisorStoreList: (data) =>
    axios.post('/franchisee/sv-store-list', data),

  // Delete supervisor store from the list
  deleteSupervisorStore: (data) =>
    axios.delete('/franchisee/sv-store-list', {data: data}),

  /*
   ****************** QSC *****************************
   */

  // start qsc process
  getQSCUID: (param) =>
    axios.get(`/franchisee/qsc/start?store_uid=${param.storeUID}`),

  getQSCProcessList: (param) =>
    axios.get(`/franchisee/qsc/list?sort=${param.status}&page=${param.page}`),

  // qsc process comments:
  getQSCProcessComments: (param) =>
    axios.get(`/web/franchise/qsc/approval/comment?qsc_uid=${param.qsc_uid}`),

  // post comment for qsc process:
  postNewQSCProcessComment: (data) =>
    axios.post('/web/franchise/qsc/approval/comment', data),

  // delete comment for qsc process:
  deleteNewQSCProcessComment: (data) =>
    axios.delete('/web/franchise/qsc/approval/comment', {data: data}),

  // get user position uids instead of hardcoded code:
  getConfirmUserSVposition: (param) =>
    axios.get(`franchisee/qsc/confirm-position?svuser_uid=${param.svUserUID}`),

  // load all the signature images:
  loadQSCSignatures: (param) =>
    axios.get(`/franchisee/qsc/sign/image?qsc_uid=${param.qscUID}`),

  // approve supervisor, Team-leader, Director
  signInQSCUser: (formData) => axios.post('/franchisee/qsc/sign', formData),

  // approve owner
  signInQSCStoreOwner: (data) => axios.post('/franchisee/qsc/sign/owner', data),

  // load signatures from server:
  getSignaturesFromServer: (param) =>
    axios.post(`/web/franchise/qsc/approval/line`, param),

  // get supervisor's stores
  getSupervisorStores: (uid) =>
    axios.get(`/web/franchise/store/list?sv_user_uid=${uid}`),

  // get qsc notes:
  getQSCNotes: (param) =>
    axios.get(`/web/franchise/qsc/note?qsc_uid=${param.qsc_uid}`),

  // get qsc checklists:
  getQSCChecklist: (param) =>
    axios.get(`/web/franchise/qsc/pdf?qsc_uid=${param.qsc_uid}`),

  // get qsc info for exporting into pdf:
  getQSCCInfoForExport: (param) =>
    axios.get(`/franchisee/qsc/export/pdf?qsc_uid=${param.qscUID}`),

  createQSCNote: (data) => axios.post('/web/franchise/qsc/note', data),
  deleteQSCNote: (param) =>
    axios.delete('/web/franchise/qsc/note', {data: param}),

  // get supervisor's store detail
  getSupervisorStoreDetail: (id) =>
    axios.get(`/web/franchise/store/information?franchise_store_uid=${id}`),

  // get full qsc history
  getFullQSCHistory: (id) =>
    axios.get(`/web/franchise/qsc/history?franchise_store_uid=${id}`),

  // qsc stats info
  getQSCStatisticsInfo: (id) =>
    axios.get(
      `/web/franchise/qsc/statistics/information?franchise_store_uid=${id}`,
    ),

  getQSCStatisticsMatrix: (qsc_uid) =>
    axios.get(`/web/franchise/qsc/statistics?qsc_uid=${qsc_uid}`),

  getQSCApprovalLineSignImg: (param) =>
    axios.get(`/web/franchise/qsc/approval/line?qsc_uid=${param.qsc_uid}`),

  /*
      franchise_setting
 */

  getSVUserInfo: () => axios.get(`/web/franchise/profile`),
  getSupervisorList: () => axios.get(`/web/franchise/sv/list`),

  getSupervisorPositionList: () => axios.get(`/web/franchise/sv/position/list`),

  changeSvPositions: (data) =>
    axios.patch(`/web/franchise/sv/position/appointment`, data),

  patchSupervisorPermission: (data) =>
    axios.patch(`/web/franchise/sv/permission`, data),
  // get supervisor join request list
  getSvJoinRequestList: (sv_user_uid) =>
    axios.get(`/web/franchise/sv/admin/store/join?sv_user_uid=${sv_user_uid}`),

  // post accept sv join request
  postAcceptSvJoinRequest: (data) =>
    axios.post('/web/franchise/sv/admin/store/join', data),

  // delete reject sv join request
  rejectSvJoinRequest: (data) =>
    axios.delete('/web/franchise/sv/admin/store/join', {data: data}),

  getFranchiseSvJoin: (param) =>
    axios.get(
      `/web/franchise/sv/invitation?phone_number=${param.phone_number}`,
    ),
  postFranchiseSvJoin: (data) =>
    axios.post(`/web/franchise/sv/invitation`, data),

  getSvBrandList: (param) =>
    axios.get(
      `/web/franchise/sv/brand/list?target_sv_user_uid=${param.target_sv_user_uid}`,
    ),

  getSvfranchiseStoreList: (param) =>
    axios.get(
      `/web/franchise/sv/franchise-store/list?head_quarters_uid=${param.head_quarters_uid}&target_sv_user_uid=${param.target_sv_user_uid}`,
    ),

  patchSvfranchiseStore: (data) =>
    axios.patch(`/web/franchise/sv/franchise-store/appointment`, data),

  /*
   ******************NOTIFICATIONS*************************
   */

  // get sv notifications:
  getSvNotifications: (param) =>
    axios.get(
      `/web/franchise/dashboard/notification?page=${param.page}&sv_user_uid=${param.sv_user_uid}`,
    ),

  /*
   ******************supplementation*************************
   */
  getSvSupplementation: (param) =>
    axios.get(
      `/web/franchise/qsc/supplementation?page=${param.page}&start_date=${param.start_date}&end_date=${param.end_date}`,
    ),
  getSvSupplementationForPdf: (param) =>
    axios.get(
      `/web/franchise/qsc/supplementation/pdf?start_date=${param.start_date}&end_date=${param.end_date}`,
    ),
  /*
   ************************task_response****************************
   */

  postCompleteionTask: (data) => axios.post(`/web/task/completion`, data),

  deleteCompleteionTask: (data) =>
    axios.delete(`/web/task/completion`, {data: data}),
  getCompleteionTask: (data) =>
    axios.get(`/web/task/completion?task_uid=${data.task_uid}`),
  /*
   ************************task_response****************************
   */
  postReview: (data) => axios.post('/web/review', data),

  /*
   ************************naver_map****************************
   */
  getStoresInMap: (date) =>
    axios.get(
      `/web/marketing/map/store/list?max_latitude=${date.max_latitude}&max_longitude=${date.max_longitude}&min_latitude=${date.min_latitude}&min_longitude=${date.min_longitude}`,
    ),
  getStoresInSearch: (date) =>
    axios.get(
      `/web/marketing/search/store/list?store_name=${date.store_name}&latitude=${date.latitude}&longitude=${date.longitude}`,
    ),
  getEventList: (data) =>
    axios.get(`/web/marketing/event/list?store_uid=${data.store_uid}
      `),
  getEventDetail: (data) =>
    axios.get(`/web/marketing/event?event_uid=${data.event_uid}
      `),

  // notice/rich-image
  postRichImage: (data) => axios.post('/notice/rich-image', data),

  // notice/rich-image/multiple
  postRichImageMultiple: (data) =>
    axios.post('/notice/rich-image/multiple', data),

  //
  // Assuming 'data' contains necessary parameters for the GET request
  getWorkSchedule: (param) =>
    axios.get(
      `/web/work-schedule?work_schedule_uid=${param.work_schedule_uid}&date=${param.date}`,
    ),

  // Assuming 'data' contains necessary body for the POST request
  createWorkSchedule: (data) => axios.post(`/web/work-schedule/`, data),

  // 'data' is not used here as DELETE doesn't typically have a body
  deleteWorkSchedule: (data) => axios.delete(`/web/work-schedule/`, {data}),

  // GET request to fetch calendar data
  getWorkScheduleCalendar: (param) =>
    axios.get(`/web/work-schedule/calendar?store_uid=${param.store_uid}`),

  // GET request to fetch a list of schedules
  getScheduleList: (param) =>
    axios.get(
      `/web/work-schedule/list?store_uid=${param.store_uid}&date=${param.date}`,
    ),

  // GET request to fetch a timeline of schedules
  getScheduleTimeline: (param) =>
    axios.get(
      `/web/work-schedule/timeline?store_uid=${param.store_uid}&date=${param.date}`,
    ),

  // GET request to fetch time set data
  getTimeset: (param) =>
    axios.get(`/web/work-schedule/timeset?store_uid=${param.store_uid}`),

  // Assuming 'data' contains necessary body for the POST request to create a timeset
  createTimeset: (data) => axios.post(`/web/work-schedule/timeset`, data),

  // 'data' is not used here as DELETE doesn't typically have a body
  deleteTimeset: (data) => axios.delete(`/web/work-schedule/timeset`, {data}),

  /*
   ******************CHATTING*************************
   */

  getMembersToInviteChatRoom: (param) =>
    axios.get(
      `/chatting/member?store_uid=${param?.store_uid}&member_uid=${param?.member_uid}`,
    ),

  postMemberToChatRoom: (data) => axios.post('/chatting/', data),

  getChatRoom: (param) =>
    axios.get(`/chatting/list?member_uid=${param.member_uid}`),

  getChatMessages: (param) =>
    axios.get(
      `/chatting/message/list?chatting_uid=${param.chatting_uid}&member_uid=${param.member_uid}&page=${param.pageNum}`,
    ),

  deleteUserChat: (data) => axios.delete('/chatting/', {data: data}),

  // to pin the chat room on top of the list:
  pinChatRoom: (data) => axios.patch('/chatting/pin', data),

  // upload media in chat:
  postImageFileToChat: (data) =>
    axios.post('/chatting/message/file', data, {
      headers: {'Content-Type': 'multipart/form-data'},
    }),
};
