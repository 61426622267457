/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import theme from 'shared/styles/theme';

const StyledTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledText = styled.p`
  font-size: ${props => (props.fontSize ? props.fontSize : '12px')};
  font-weight: ${props => props.weight};
  font-family: 'Noto Sans KR', sans-serif;
  margin: ${props => props.margin};
  color: ${props => props.color};
`;

export default function AppText({
  text = '',
  fontSize = '12px',
  color = theme.colors.black,
  xColor = theme.colors.black,
  extra = '',
  xFontSize = '12px',
  margin = '2px',
  weight = 'regular',
  family = 'Sans serif',
}) {
  return (
    <StyledTextWrapper>
      <StyledText
        weight={weight}
        margin={margin}
        family={family}
        color={color}
        fontSize={fontSize}>
        {text}
      </StyledText>

      <StyledText
        weight={weight}
        margin={margin}
        family={family}
        color={xColor}
        fontSize={xFontSize}>
        {extra}
      </StyledText>
    </StyledTextWrapper>
  );
}
