import React from 'react';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import {styled} from '@mui/system';
import theme from 'shared/styles/theme';

const hexColor = theme.colors.oldYellow;
const red = parseInt(hexColor.slice(1, 3), 16);
const green = parseInt(hexColor.slice(3, 5), 16);
const blue = parseInt(hexColor.slice(5, 7), 16);

const CustomRating = styled(Rating)(({theme}) => ({
  fontSize: 28, // Adjust the font size to make it larger
  color: `rgba(${red}, ${green}, ${blue}, 0.55)`,
}));

const RatingStars = ({totalRate}) => {
  return (
    <Stack spacing={1}>
      <CustomRating
        name="custom-size"
        value={totalRate}
        precision={0.5}
        readOnly
      />
    </Stack>
  );
};

export default RatingStars;
