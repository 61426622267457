import React from 'react';
import styled from 'styled-components';

import {MuiButton, MuiModal, Text} from 'shared/components/index';
import {useDispatch, useSelector} from 'react-redux';
import {
  getSignaturesFromServerThunk,
  selectFranchiseQsc,
  setLoadSignatureModal,
} from 'franchise/utils/slices/frQscSlice';
import theme from 'shared/styles/theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 320px;
  height: 250px;
  padding: 40px;
  align-items: center;
  & > * {
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
  margin: 10px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
`;

const LoadSignaturesModal = () => {
  const dispatch = useDispatch();
  const {loadSignatureModal, loadSignatureQSC} =
    useSelector(selectFranchiseQsc);

  const handleModalClose = () => {
    dispatch(setLoadSignatureModal(false));
  };

  const handleDoneBtn = () => {
    dispatch(getSignaturesFromServerThunk({qsc_uid: loadSignatureQSC}))
      .unwrap()
      .then((payload) => {
        if (!payload) {
          //alert('슈퍼바이저의의 등록된 서명이 없습니다');
          dispatch(setLoadSignatureModal(false));
        }

        if (payload) {
          //alert('슈퍼바이저의의 등록된 서명이 없습니다');
          dispatch(setLoadSignatureModal(false));
        }
      })
      .catch((error) => {
        alert(error);
        dispatch(setLoadSignatureModal(false));
      });
  };

  return (
    <MuiModal open={loadSignatureModal} onClose={handleModalClose}>
      <Container>
        <TitleWrapper>
          <Text bold fontSize="xl">
            서명 불러오기
          </Text>
        </TitleWrapper>

        <Text>서명을 불러오시겠습니까?</Text>

        <ButtonContainer>
          <MuiButton
            onClick={handleDoneBtn}
            backgroundColor={theme.colors.primary}
            textColor={theme.colors.white}
            text="확인"
          />
        </ButtonContainer>
      </Container>
    </MuiModal>
  );
};
export default LoadSignaturesModal;
