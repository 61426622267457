import React from 'react';
import {Modal, Box, TextField, Button} from '@mui/material';
import * as Yup from 'yup';
import styled from 'styled-components';
import {Formik} from 'formik';
import theme from 'shared/styles/theme';

const StyleField = styled(TextField)`
  width: auto;
  flex-grow: 1;
`;

const FormContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  & > * {
    margin: 8px;
  }
`;

const Form = styled.form`
  border: 1px solid ${theme.colors.grey6};
  border-radius: 15px;
`;

const StyledButton = styled(Button)`
  color: ${theme.colors.kalsPurple};
  border-color: ${theme.colors.kalsPurple};
`;

const StyledContainedButton = styled(Button)`
  background-color: ${theme.colors.kalsPurple};
  &:hover {
    background-color: ${theme.colors.kalsPurple};
  }
  color: white;
`;

// Modal styling
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 520,
  bgcolor: 'background.paper',
  border: `1px solid ${theme.colors.grey4}`,
  borderRadius: '15px',
  boxShadow: 24,
  p: 4,
};

// Validation schema
const validationSchema = Yup.object({
  startHour: Yup.number()
    .typeError('0 ~ 23')
    .min(0, '0 ~ 23')
    .max(23, '0 ~ 23')
    .required('0 ~ 23'),
  startMinute: Yup.number()
    .typeError('0 ~ 59')
    .min(0, '0 ~ 59')
    .max(59, '0 ~ 59')
    .required('0 ~ 59'),
  endHour: Yup.number()
    .typeError('0 ~ 23')
    .min(0, '0 ~ 23')
    .max(23, '0 ~ 23')
    .required('0 ~ 23'),
  endMinute: Yup.number()
    .typeError('0 ~ 59')
    .min(0, '0 ~ 59')
    .max(59, '0 ~ 59')
    .required('0 ~ 59'),
});

const InlineTextField = ({formikProps, label, name, ...restProps}) => {
  return (
    <>
      <StyleField
        {...restProps}
        name={name}
        value={formikProps.values[name]}
        onChange={formikProps.handleChange}
        error={formikProps.touched[name] && Boolean(formikProps.errors[name])}
        helperText={
          (formikProps.touched[name] && formikProps.errors[name]) || ' '
        }
        variant="filled"
        fullWidth
        placeholder={label}
        inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
      />
    </>
  );
};

const ScheduleTimeModal = ({open, handleClose, handleSubmitTimeset}) => {
  const onSubmit = (values) => {
    handleSubmitTimeset(values);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div>
        <Formik
          initialValues={{
            startHour: '',
            startMinute: '',
            endHour: '',
            endMinute: '',
          }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {(formikProps) => {
            return (
              <Form
                onBlur={formikProps.handleBlur}
                onSubmit={formikProps.handleSubmit}
              >
                <Box sx={style}>
                  <FormContainer>
                    <InlineTextField
                      name="startHour"
                      label="시작 시"
                      formikProps={formikProps}
                    />
                    <span>:</span>
                    <InlineTextField
                      name="startMinute"
                      label="시작 분"
                      formikProps={formikProps}
                    />
                    <span style={{whiteSpace: 'nowrap'}}>부터</span>
                    <InlineTextField
                      name="endHour"
                      label="종료 시"
                      formikProps={formikProps}
                    />
                    <span>:</span>
                    <InlineTextField
                      name="endMinute"
                      label="종료 분"
                      formikProps={formikProps}
                    />
                    <span style={{whiteSpace: 'nowrap'}}>까지</span>
                  </FormContainer>

                  <Box
                    sx={{mt: 2, display: 'flex', justifyContent: 'flex-end'}}
                  >
                    <Button
                      sx={{
                        border: `1px solid ${theme.colors.kalsPurple}`,
                        color: `${theme.colors.kalsPurple}`,
                        '&:hover': {
                          backgroundColor: 'transparent',
                          borderColor: `${theme.colors.kalsPurple}`,
                        },
                      }}
                      onClick={handleClose}
                      variant="outlined"
                      id="cancel"
                    >
                      취소
                    </Button>

                    <Button
                      sx={{
                        backgroundColor: `${theme.colors.kalsPurple}`,
                        color: `${theme.colors.white}`,
                        marginLeft: '20px',
                        '&:hover': {
                          backgroundColor: `${theme.colors.kalsPurple}`,
                        },
                      }}
                      type="submit"
                      variant="contained"
                      id="confirm"
                    >
                      확인
                    </Button>
                  </Box>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default ScheduleTimeModal;
