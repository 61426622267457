import {Drawer, Wrapper} from 'shared/components/index';
import styled from 'styled-components';
import PdfTitleArea from './PdfTitleArea';
import PdfBodyArea from './PdfBodyArea';
import SearchForm from './SearchForm';
const Container = styled.div`
  margin-top: -40px;
  margin-left: 60px;
  width: 1050px;
`;

const WorkRegisterTemplate = ({
  storeMemberList,
  commuteLog,
  search,
  handlePdfButtonClick,
  year,
  month,
}) => {
  return (
    <Wrapper titleText="WorkRegister">
      <Drawer>
        <Container>
          <>
            <SearchForm
              storeMemberList={storeMemberList}
              search={search}
              handlePdfButtonClick={handlePdfButtonClick}
            />
            <PdfTitleArea year={year} month={month} commuteLog={commuteLog} />
            <PdfBodyArea commuteLog={commuteLog} />
          </>
        </Container>
      </Drawer>
    </Wrapper>
  );
};

export default WorkRegisterTemplate;
