import React from 'react';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';

import Text from '../atoms/Text';

const ItemWrapper = styled.li`
  display: flex;
  margin-right: 45px;
`;

const StyledLink = styled(NavLink)`
  display: flex;
  color: #252525;
  text-decoration: none;
`;

const MenuItem = props => {
  const {text, link, color} = props;
  return (
    <ItemWrapper>
      <StyledLink to={link}>
        <Text variant="link" bold color={color} fontSize="large">
          {text}
        </Text>
      </StyledLink>
    </ItemWrapper>
  );
};

export default MenuItem;
