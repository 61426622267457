import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectStore, setCurrentStore} from 'owler/utils/slices/storeSlice';
import {setSelectedDateT} from 'owler/utils/slices/taskSlice';
import NoticeListTemplate from './template';
import {
  getNoticesByPageAsync,
  selectNotice,
  setMasterNoticeList,
  setNoticePageCount,
  setCurrentNoticePage,
  deleteNoticePinThunk,
  pinNoticeThunk,
} from 'owler/utils/slices/noticeSlice';
import {useCallback} from 'react';
import {debounce} from 'lodash';

const NoticeList = () => {
  const dispatch = useDispatch();
  const {currentStore} = useSelector(selectStore);
  const {masterNoticeList, currentNoticePage} = useSelector(selectNotice);
  const [isLoading, setIsLoading] = useState(false);

  // on first load:
  useEffect(() => {
    dispatch(setSelectedDateT(new Date()));
    if (currentStore !== null) {
      setIsLoading(true);
      dispatch(
        getNoticesByPageAsync({
          store_uid: currentStore.store_uid,
          page: currentNoticePage,
        }),
      )
        .unwrap()
        .then((payload) => {
          dispatch(setNoticePageCount(payload?.count));
          dispatch(setMasterNoticeList(payload?.results));
        })
        .catch((err) => {
          alert(err.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStore, currentNoticePage]);

  const handlePinClick = (e, notice) => {
    e.stopPropagation();
    debouncedHandlePin(notice);
  };
  const debouncedHandlePin = useCallback(
    debounce((notice) => {
      if (notice.is_pinned === true) {
        dispatch(
          deleteNoticePinThunk({
            notice_uid: notice.notice_uid,
            store_uid: currentStore.store_uid,
          }),
        )
          .unwrap()
          .then(() => {
            dispatch(
              getNoticesByPageAsync({
                store_uid: currentStore.store_uid,
                page: currentNoticePage,
              }),
            )
              .unwrap()
              .then((payload) => {
                dispatch(setMasterNoticeList(payload.results));
              })
              .catch((error) => {
                console.error(error);
              });
          })
          .catch((error) => {
            alert(error);
          });
      } else {
        dispatch(
          pinNoticeThunk({
            notice_uid: notice.notice_uid,
            store_uid: currentStore.store_uid,
          }),
        )
          .unwrap()
          .then(() => {
            alert('상단 고정하였습니다.');
            dispatch(
              getNoticesByPageAsync({
                store_uid: currentStore.store_uid,
                page: currentNoticePage,
              }),
            )
              .unwrap()
              .then((payload) => {
                dispatch(setMasterNoticeList(payload.results));
              })
              .catch((err) => {});
          })
          .catch((error) => {
            alert(error);
          });
      }
    }, 250),
    [currentStore, currentNoticePage],
  );
  const handleStoreClick = useCallback(
    (_store) => {
      dispatch(setCurrentNoticePage(1));
      dispatch(setCurrentStore(_store));

      //navigate(`/owler/store/${_store.store_uid}`);
    },
    [dispatch],
  );
  return (
    <NoticeListTemplate
      currentStore={currentStore}
      masterNoticeList={masterNoticeList}
      currentNoticePage={currentNoticePage}
      handlePinClick={handlePinClick}
      handleStoreClick={handleStoreClick}
      isLoading={isLoading}
    />
  );
};
export default NoticeList;
