import React, {useCallback, useEffect, useState} from 'react';

import SignManagementTemplate from './template/index';
import {useDispatch} from 'react-redux';
import {
  getSvSignAsync,
  postSvSignAsync,
} from 'franchise/utils/slices/frUserSlice';

const SignManagement = () => {
  const dispatch = useDispatch();
  const [defaultSign, setDefaultSign] = useState(null);

  useEffect(() => {
    dispatch(getSvSignAsync())
      .unwrap()
      .then((payload) => {
        setDefaultSign(payload?.signature);
      })
      .catch(() => alert('기존 사인을 불러오지 못했습니다.'));
  }, [dispatch]);

  const submitSign = useCallback(
    (image) => {
      const form = new FormData();

      form.append('signature', image);
      dispatch(postSvSignAsync(form))
        .unwrap()
        .then(() => alert('변경되었습니다'));
    },
    [dispatch],
  );
  return (
    <SignManagementTemplate defaultSign={defaultSign} submitSign={submitSign} />
  );
};

export default SignManagement;
