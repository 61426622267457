/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import styled from 'styled-components';

const generateRandomId = () => {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
};

const SyledD = styled.div`
  width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 10px;
`;
// eslint-disable-next-line no-unused-vars
const ImageSelector = ({name, label, onChange, formikProps}) => {
  const [previewImages, setPreviewImages] = useState([]);

  const deleteImage = (img) => {
    const newPreviewImages = previewImages.filter(
      (image) => image.id !== img.id,
    );
    setPreviewImages(newPreviewImages);

    const newImages = formikProps.values[name].filter(
      (image) => image !== img.file,
    );
    formikProps.setFieldValue(name, newImages);
  };

  const handleImageChange = (event) => {
    const newImages = Array.from(event.target.files);
    const updatedImages = newImages.map((image) => {
      const id = generateRandomId();
      const preview = URL.createObjectURL(image);
      const imageName = image.name;
      return {id, preview, file: image, name: imageName};
    });

    const sendingImages = newImages.map((image) => {
      return image;
    });

    setPreviewImages([...previewImages, ...updatedImages]);
    formikProps.setFieldValue(name, [
      ...formikProps.values.description_images,
      ...sendingImages,
    ]);

    event.target.value = null;
  };

  return (
    <Wrapper>
      <label>{label}</label>

      <Label htmlFor={name} style={{whiteSpace: 'nowrap'}}>
        이미지 추가
      </Label>

      <Input
        type="file"
        name={name}
        id={name}
        style={{display: 'none'}}
        onChange={handleImageChange}
        accept="image/*"
        multiple
      />

      <SyledD>
        {previewImages?.map((previewImage, indx) => (
          <p key={indx.toString()}>{previewImage.name}, &nbsp;</p>
        ))}
      </SyledD>

      <div style={{display: 'flex'}}>
        {previewImages?.map((previewImage, indx) => (
          <div
            key={indx.toString()}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '150px', // set a fixed height
              marginRight: '10px',
            }}
          >
            <PreviewImageContainer
              key={indx.toString()}
              alt={name}
              image={previewImage.preview}
            />

            <Button type="button" onClick={() => deleteImage(previewImage)}>
              업로드 취소
            </Button>
          </div>
        ))}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 600;
`;
const Button = styled.button`
  margin-top: 8px;
  font-size: 14px;
`;

const Label = styled.label`
  width: 140px;
  text-align: center;
  cursor: pointer;
  background-color: #f0f0f0;
  padding: 8px 16px;
  border-radius: 4px;
  display: inline-block;
  margin-top: 10px;
  white-space: nowrap;
`;

const Input = styled.input`
  padding: 0.5rem;
`;

const PreviewImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 100px;
  background-color: white;
  background-image: url(${(props) => props.image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 10px;
`;

export default ImageSelector;
