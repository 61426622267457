import {Text, Drawer, Wrapper} from 'shared/components/index';
import styled, {createGlobalStyle} from 'styled-components';
import {Button} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import JoinManagement from './JoinManagement';
import PositionManagement from './PositionManagement';
import WorkerManagement from './WorkerManagement';
import {CSSTransition} from 'react-transition-group';
import BrandManagement from './BrandManagement';

const GlobalStyles = createGlobalStyle`
  .fade-enter {
    opacity: 0;
  }

  .fade-enter-active {
    opacity: 1;
    transition: opacity 100ms ease-in-out;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit-active {
    opacity: 0;
    transition: opacity 100ms ease-in-out;
  }
`;

const Container = styled.div`
  margin-top: 20px;
  width: 1040px;
  margin-left: 60px;
  display: flex;
  flex-direction: row;
`;
const SettingContainer = styled.div`
  height: ${({currentStore}) =>
    currentStore.is_corporation ? '320px' : '240px'};
  width: 30%;
  margin-left: 0%;
  border-radius: 20px;
  box-shadow: 0px 2px 2px 2px #bdbdbd;
`;
const DetailSettingContainer = styled.div`
  width: ${(props) => (props.setting === 3 ? '40%' : '65%')};
  max-height: 745px;
  margin-left: 0%;
  border-radius: 20px;
  box-shadow: 0px 2px 2px 2px #bdbdbd;
  margin-left: 30px;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    //background: #ffffff;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
const SettingButton = styled(Button)`
  width: 100%;
  height: 80px;
`;
const Enter = styled(ArrowRightIcon)`
  position: relative;
  top: 5px;
  left: ${({currentStore}) =>
    currentStore?.is_corporation ? '160px' : '176px'};
`;
const TextArea = styled.div`
  diplay: flex;
  flex-direction: row;
  margin-left: -150px;
  width: 120px;
  text-align: left;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${(props) => (props.setting === 3 ? '93%' : '95%')};
  margin-top: 15px;
  font-weight: 600;
  font-size: 20px;
  margin-left: 20px;
`;

const SettingsTemplate = ({
  currentStore,
  handleStoreClick,
  isAdmin,
  handlePositionManagement,
  handleWorkerManagement,
  handleJoinReqManagement,
  handleBrandManagement,
  setting,
  setSetting,
  accessJoin,
  rejectJoin,

  modifyMember,
  fireMember,

  handleAddBtn,
  handleChangeBtn,
  handleDeleteBtn,

  handleMemberClick,
  handleSubmit,

  searchMemberByPhone,
  inviteJoin,
}) => {
  const renderTitle = () => {
    if (setting === 1) return '직원 합류';
    if (setting === 2) return '직원 관리';
    if (setting === 3) return '직급 관리';
    if (setting === 4) return '브랜드 배정';
  };
  return (
    <Wrapper titleText="Settings" handleStoreClick={handleStoreClick}>
      <Drawer>
        <GlobalStyles />
        <Container>
          <SettingContainer currentStore={currentStore}>
            <SettingButton
              sx={{
                color: '#000000',
                fontWeight: '600',
                fontSize: '18px',
                backgroundColor: '#ffffff',
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px',
              }}
              onClick={handleJoinReqManagement}
            >
              <TextArea>
                직원 합류
                <Enter />
              </TextArea>
            </SettingButton>

            <SettingButton
              sx={{
                color: '#000000',
                fontWeight: '600',
                fontSize: '18px',
                backgroundColor: '#ffffff',
              }}
              onClick={handlePositionManagement}
            >
              <TextArea>
                직원 관리
                <Enter />
              </TextArea>
            </SettingButton>

            {currentStore.is_corporation && (
              <SettingButton
                sx={{
                  color: '#000000',
                  fontWeight: '600',
                  fontSize: '18px',
                  backgroundColor: '#ffffff',
                  margin: '0 0px',
                }}
                onClick={handleBrandManagement}
              >
                <TextArea>
                  브랜드 배정
                  <Enter currentStore={currentStore} />
                </TextArea>
              </SettingButton>
            )}

            <SettingButton
              sx={{
                color: '#000000',
                fontWeight: '600',
                fontSize: '18px',
                backgroundColor: '#ffffff',
                borderBottomLeftRadius: '20px',
                borderBottomRightRadius: '20px',
                margin: '0 0 0 0',
              }}
              onClick={handleWorkerManagement}
            >
              <TextArea>
                직급 관리
                <Enter />
              </TextArea>
            </SettingButton>
          </SettingContainer>
          <CSSTransition
            in={setting !== 0}
            timeout={100}
            classNames="fade"
            unmountOnExit
          >
            <DetailSettingContainer setting={setting}>
              <Header setting={setting}>
                <div>{renderTitle()}</div>
                <Button
                  onClick={() => setSetting(0)}
                  sx={{
                    color: '#000000',
                    marginTop: '0px',
                    height: '24px',
                    width: '24px',
                    minWidth: '0px',
                  }}
                >
                  <CloseIcon sx={{}} />
                </Button>
              </Header>
              {setting === 1 && (
                <>
                  <JoinManagement
                    accessJoin={accessJoin}
                    rejectJoin={rejectJoin}
                    searchMemberByPhone={searchMemberByPhone}
                    inviteJoin={inviteJoin}
                  />
                </>
              )}
              {setting === 2 && (
                <>
                  <PositionManagement
                    modifyMember={modifyMember}
                    fireMember={fireMember}
                  />
                </>
              )}
              {setting === 3 && (
                <>
                  <WorkerManagement
                    handleAddBtn={handleAddBtn}
                    handleDeleteBtn={handleDeleteBtn}
                    handleChangeBtn={handleChangeBtn}
                  />
                </>
              )}
              {setting === 4 && (
                <>
                  <BrandManagement
                    currentStore={currentStore}
                    handleMemberClick={handleMemberClick}
                    handleSubmit={handleSubmit}
                  />
                </>
              )}
            </DetailSettingContainer>
          </CSSTransition>
        </Container>
      </Drawer>
    </Wrapper>
  );
};

export default SettingsTemplate;
