/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
import React, {useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import PinIcon from 'shared/assets/icons/PinIcon';
import CheckCircleRegularIcon from 'shared/assets/icons/CheckCircleRegularIcon';
import ImageRegularIcon from 'shared/assets/icons/ImageRegularIcon';
import {TableData, TableDataGroup} from 'shared/components/index';
import theme from 'shared/styles/theme';
import NoticeFeedback from './NoticeFeedback';
import StoreNoticeFeedback from './StoreNoticeFeedback';
import styled from 'styled-components';
import {
  getNoticeResponseAsync,
  getNoticeDetailAsync,
  setCurrentNotice,
  setCurrentNotice_uid,
  setSelectedNotice,
} from 'owler/utils/slices/noticeSlice';
import {useDispatch, useSelector} from 'react-redux';
import {selectStore} from 'owler/utils/slices/storeSlice';
import {selectUser} from 'owler/utils/slices/userSlice';

const Wrapper = styled.div`
  margin-bottom: 10px;
`;

const TaskCategoryWrapper = styled.div`
  width: 100%;
  border: 1px solid black;
  padding: 5px;
  margin-bottom: 5px;
  margin-left: 45px;
  border: ${({theme}) => `1px solid ${theme.colors.grey9}`};
  border-radius: 10px;
  padding: 5px 20px;
  display: flex;
  &:hover {
    & > * {
      cursor: pointer;
    }
    & a {
      font-weight: bold;
      text-decoration: underline;
    }
    border: ${({theme}) => `1px solid ${theme.colors.medium}`};
    background-color: ${({theme}) => theme.colors.lightYellow1};
  }
  background-color: ${(props) =>
    props.notice.is_pinned === true ? '#F8ECE0;' : ''};
  border: 1px solid
    ${(props) =>
      props.notice.is_pinned === true ? '#F7F8E0;' : `${theme.colors.grey9}`};
`;
const FromFranchise = styled.div`
  padding: 0px 6px;
  border-radius: 5px;
  color: white;
  font-size: 15px;
  font-weight: 500;
  background-color: ${theme.colors.kalsPurple};
`;
const MasterNotice = ({notice, page, handlePinClick}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {userInfo} = useSelector(selectUser);
  const {currentStore, memberInfo} = useSelector(selectStore);
  const [openModal, toggleModal] = useState(false);
  const [openSecondModal, toggleSecondModal] = useState(false);
  const [storeFeedback, setStoreFeedback] = useState([]);

  const renderIcon = (_notice) => {
    if (_notice.notice_type === 'image')
      return <ImageRegularIcon length={20} fill={theme.colors.gray_dark} />;
    return (
      <CheckCircleRegularIcon length={20} fill={theme.colors.purple_dark} />
    );
  };

  const handleNoticeClick = useCallback(
    (_notice) => {
      dispatch(
        getNoticeDetailAsync({
          notice_uid: notice.notice_uid,
        }),
      )
        .unwrap()
        .then((payload) => {
          dispatch(setCurrentNotice(payload));
          dispatch(setSelectedNotice(_notice));
          dispatch(setCurrentNotice_uid(notice.notice_uid));
          navigate(`/owler/notices/noticeDetail/${_notice.notice_uid}`);
        });
    },
    [dispatch, navigate, notice.notice_uid],
  );

  const fromattedTime = (time) => {
    const noticeDate = new Date(time);
    const year = noticeDate.getFullYear();
    const month = noticeDate.getMonth() + 1;
    const day = noticeDate.getDate();

    if (month < 10 && day < 10) return `${year}.0${month}.0${day}`;
    if (month < 10) return `${year}.0${month}.${day}`;
    if (day < 10) return `${year}.${month}.0${day}`;
    return `${year}.${month}.${day}`;
  };

  const formattedTitle = (body) => {
    if (body !== null) {
      if (body?.length >= 30) {
        return `${body.substr(0, 30)} ···`;
      }
      return body;
    }
    return '';
  };

  const handleIconClick = (_notice) => {
    if (currentStore.is_hq === true) {
      toggleModal(true);
    } else {
      dispatch(
        getNoticeResponseAsync({
          notice_uid: notice.notice_uid,
        }),
      )
        .unwrap()
        .then((payload) => {
          setStoreFeedback(payload);
          toggleSecondModal(true);
        });
    }
  };

  const renderFrom = (notice) => {
    if (notice.from_franchise === true) {
      return <FromFranchise>본사</FromFranchise>;
    }
  };
  return (
    <Wrapper>
      <TaskCategoryWrapper
        notice={notice}
        onClick={() => handleNoticeClick(notice)}
        border
      >
        <TableDataGroup>
          &nbsp;&nbsp;
          <PinIcon
            length={25}
            stroke={notice.is_pinned === true ? '#FE2E2E' : '#D8D8D8'}
            onClick={(e) => handlePinClick(e, notice)}
          />
          <TableData>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <TableData width="62px">{renderFrom(notice)}</TableData>
            {formattedTitle(notice.title)}
          </TableData>
        </TableDataGroup>

        <TableDataGroup width={'250px'} justifyContent="space-between">
          <TableData>{fromattedTime(notice.created_on)}</TableData>
          <TableData>{notice.creator}</TableData>
          <TableData
            onClick={(e) => {
              e.stopPropagation();
              handleIconClick(notice);
            }}
          >
            {renderIcon(notice)}
          </TableData>
        </TableDataGroup>
      </TaskCategoryWrapper>

      {openModal && (
        <NoticeFeedback
          handleClose={() => toggleModal(false)}
          notice_type={notice.notice_type}
          notice_uid={notice.notice_uid}
          open={openModal}
        />
      )}

      {openSecondModal ? (
        <StoreNoticeFeedback
          notice_type={notice.notice_type}
          storeFeedback={storeFeedback}
          open={openSecondModal}
          handleClose={() => toggleSecondModal(false)}
        />
      ) : null}
    </Wrapper>
  );
};

export default MasterNotice;
