import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import i18n from 'i18next';

import api from 'shared/utils/api';

const initialState = {
  userStores: [],
  currentStore: null,
  corpCurrentStore: null,
  memberInfo: null,
  attendance: null,
  memberList: [],
  storeBrandList: [],
  storeInfo: null,
  taskProgress: null,
  compeleteByStoreModal: false,
  compeleteByStoreModalforImage: false,
  checkTasks: null,
  imageTasks: null,
};

// get user stores thunk
export const getUserStoresAsync = createAsyncThunk(
  'store/getUserStores',
  async (param, {rejectWithValue}) => {
    try {
      const response = await api.getUserStores(param);
      const {results} = response.data;
      return results;
    } catch (error) {
      const errorResponse = error.response || {};
      const data = errorResponse.data || {};
      const message =
        i18n.language === 'ko'
          ? data.KR || error.message || '잘못된 접근입니다.'
          : data.EN || error.message || 'Something went wrong :(';
      return rejectWithValue(message);
    }
  },
);

// get main store thunk
export const getStoreMainPageAsync = createAsyncThunk(
  'store/getStoreMainPage',
  async (param) => {
    try {
      const response = await api.getStoreMainPage(param);

      const {results} = response.data;

      return results;
    } catch (error) {
      console.error('Error getting main store: ', error);
    }
  },
);

export const getStoreBrandListAsync = createAsyncThunk(
  'store/getStoreBrandListAsync',
  async (param) => {
    try {
      const response = await api.getStoreBrandList(param);

      const {results} = response.data;

      return results;
    } catch (error) {}
  },
);

export const getBrandTaskStatusAsync = createAsyncThunk(
  'store/getBrandTaskStatusAsync',
  async (param) => {
    try {
      const response = await api.getBrandTaskStatus(param);

      const {results} = response.data;

      return results;
    } catch (error) {}
  },
);

export const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    setCurrentStore: (state, action) => {
      state.currentStore = action.payload;
    },
    setCompeleteByStoreModal: (state, action) => {
      state.compeleteByStoreModal = action.payload;
    },
    setCompeleteByStoreModalforImage: (state, action) => {
      state.compeleteByStoreModalforImage = action.payload;
    },
    setStoreBrandList: (state, action) => {
      state.storeBrandList = action.payload;
    },
    setCorpCurrentStore: (state, action) => {
      state.corpCurrentStore = action.payload;
    },
    setCheckTasks: (state, action) => {
      state.checkTasks = action.payload;
    },
    setImageTasks: (state, action) => {
      state.imageTasks = action.payload;
    },
    setMemberInfo: (state, action) => {
      state.memberInfo = action.payload;
    },
    resetStoreSlice: () => initialState,
  },

  extraReducers: (builder) => {
    builder
      .addCase(getUserStoresAsync.fulfilled, (state, action) => {
        state.userStores = action.payload;
      })
      .addCase(getStoreMainPageAsync.fulfilled, (state, action) => {
        const {attendance, memberList, storeInfo, memberInfo, taskProgress} =
          action.payload;
        state.attendance = attendance;
        state.memberList = memberList;
        state.storeInfo = storeInfo;
        state.memberInfo = memberInfo;
        state.taskProgress = taskProgress;
      });
  },
});

export const {
  setCurrentStore,
  resetStoreSlice,
  setCompeleteByStoreModal,
  setStoreBrandList,
  setCorpCurrentStore,
  setCompeleteByStoreModalforImage,
  setCheckTasks,
  setImageTasks,
  setMemberInfo,
} = storeSlice.actions;

export const selectStore = (state) => state.owler.store;

export default storeSlice.reducer;
