import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Header = styled.div`
  padding: 5px 30px;
  display: ${props => props.type === 1 ? 'block' : 'flex'};
  justify-content: ${props => props.type === 1 ? 'start' : 'center'};
`;

const TableHeader = props => {
  const {children, border,type} = props;
  return <Header border={border} type = {type}>{children}</Header>;
};

TableHeader.propTypes = {
  children: PropTypes.element.isRequired,
  border: PropTypes.bool,
  type: PropTypes.number,
};

TableHeader.defaultProps = {
  border: false,
};

export default TableHeader;
