import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Title = styled.div`
  width: 100%;
  font-size: 22px;
  font-weight: 600;
  color: #868686;
`;

const ModalTitle = ({ children }) => <Title>{children}</Title>;

ModalTitle.propTypes = {
  children: PropTypes.string.isRequired,
};

export default ModalTitle;

// TODO: 다른 곳에서 title이 쓰일 경우 type을 modal/default 이런 식으로 나눠서 사용할 것
