import React, {useState, useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {MuiModal, Text} from 'shared/components/index';
import CloseIcon from '@mui/icons-material/Close';
import {
  selectCalendar,
  setShowFranchiseListModal,
  setDirectGroup,
  setNoDirectGroup,
} from 'owler/utils/slices/calendarSlice';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import theme from 'shared/styles/theme';
import Checkbox from '@mui/material/Checkbox';

const Container = styled.div`
  background-color: white;
  //margin-top: 30px;
  width: 400px;
  height: 500px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0px 3px 3px 3px #6e6e6e;
`;
const BtnWrapper = styled.button`
  border: none;
  background-color: white;
  cursor: pointer;
  position: relative;
  top: 0px;
  left: 5px;
`;
const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;
const StoreList = styled.div`
  //border-bottom: 1px solid ${theme.colors.grey6};
  margin: 0px 0 10px 0;
  width: 280px;
  max-height: 200px;
  min-height: 200px;
  overflow: auto;
`;
const Store = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const LabelWrapper = styled.div`
  margin: 0px 0;
  width: 320px;
  border-bottom: 1px solid ${theme.colors.grey6};
  border-top: 1px solid ${theme.colors.grey6};
  display: flex;
  justify-content: space-between;
`;
const LabeltitleWrapper = styled.div`
  margin: 0px 0;
  width: 320px;
  border-bottom: 1px solid ${theme.colors.grey6};
  display: flex;
  justify-content: space-between;
`;
const SearchStore = styled.input`
  width: 240px;
  font-size: 16px;
  padding: 0 10px;
  border: none;
  border: 2px solid ${theme.colors.grey9};
  border-radius: 10px;
  margin: 5px 10px;
`;
const ShowFranchiseListModal = ({selectedEventDetail}) => {
  const {showFranchiseListModal, directGroup, noDirectGroup} =
    useSelector(selectCalendar);
  const dispatch = useDispatch();
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [directname, setdirectname] = useState('');
  const [nodirectname, setnodirectname] = useState('');

  useEffect(() => {
    const direct = [];
    const noDirect = [];
    selectedEventDetail?.target_stores.forEach((item) => {
      if (item.is_direct) {
        direct.push(item);
      } else {
        noDirect.push(item);
      }
    });
    dispatch(setDirectGroup(direct));
    dispatch(setNoDirectGroup(noDirect));
  }, [dispatch, selectedEventDetail]);

  const handleModalClose = () => {
    dispatch(setShowFranchiseListModal(false));
  };

  const handledNameChange = useCallback((e) => {
    setdirectname(e.target.value);
  }, []);
  const handledNameChange2 = useCallback((e) => {
    setnodirectname(e.target.value);
  }, []);

  return (
    <MuiModal open={showFranchiseListModal} onClose={handleModalClose}>
      <Container>
        <TitleWrapper>
          <Text bold margin="0 0 0 10px">
            공유 매장
          </Text>
          <BtnWrapper onClick={handleModalClose}>
            <CloseIcon />
          </BtnWrapper>
        </TitleWrapper>
        <LabeltitleWrapper>
          <Text margin="5px 0 5px 10px" bold>
            전체 매장
          </Text>
        </LabeltitleWrapper>
        <LabelWrapper>
          <Text margin="5px 0 0 10px" bold>
            직영 매장
          </Text>
          <Button
            onClick={() => {
              setShow1((prevShow) => !prevShow);
              if (show2 === true) setShow2(false);
            }}
          >
            {show1 === false ? (
              <ArrowDropDownIcon sx={{color: 'black'}} />
            ) : (
              <ArrowDropUpIcon sx={{color: 'black'}} />
            )}
          </Button>
        </LabelWrapper>
        {show1 && (
          <>
            <SearchStore
              value={directname}
              onChange={(e) => handledNameChange(e)}
              placeholder="매장 검색"
            />
            <StoreList>
              {directGroup?.length > 0 ? (
                directGroup
                  ?.filter((store) => store.branch_name.includes(directname))
                  .map((store) => {
                    return (
                      <Store key={store.franchise_store_uid}>
                        <Checkbox
                          id={store.branch_name}
                          value={store.franchise_store_uid}
                          checked={true}
                          onClick={() =>
                            alert('이미 전파한 공유 매장은 변경할 수 없습니다.')
                          }
                          sx={{
                            '&.Mui-checked': {color: theme.colors.kalsPurple},
                          }}
                        />
                        {store.branch_name}
                      </Store>
                    );
                  })
              ) : (
                <Store>전달한 매장이 없습니다.</Store>
              )}
            </StoreList>
          </>
        )}
        <LabelWrapper>
          <Text margin="5px 0 5px 10px" bold>
            가맹점
          </Text>
          <Button
            onClick={() => {
              setShow2((prevShow) => !prevShow);
              if (show1 === true) setShow1(false);
            }}
          >
            {show2 === false ? (
              <ArrowDropDownIcon sx={{color: 'black'}} />
            ) : (
              <ArrowDropUpIcon sx={{color: 'black'}} />
            )}
          </Button>
        </LabelWrapper>
        {show2 && (
          <>
            <SearchStore
              value={nodirectname}
              onChange={(e) => handledNameChange2(e)}
              placeholder="매장 검색"
            />
            <StoreList>
              {noDirectGroup?.length > 0 ? (
                noDirectGroup
                  ?.filter((store) => store.branch_name.includes(nodirectname))
                  .map((store) => {
                    return (
                      <Store key={store.franchise_store_uid}>
                        <Checkbox
                          id={store.branch_name}
                          value={store.franchise_store_uid}
                          checked={true}
                          onClick={() =>
                            alert('이미 전파한 공유 매장은 변경할 수 없습니다.')
                          }
                          sx={{
                            '&.Mui-checked': {color: theme.colors.kalsPurple},
                          }}
                        />
                        {store.branch_name}
                      </Store>
                    );
                  })
              ) : (
                <Store>전달한 매장이 없습니다.</Store>
              )}
            </StoreList>
          </>
        )}
      </Container>
    </MuiModal>
  );
};
export default ShowFranchiseListModal;
