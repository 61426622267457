import React from 'react';
import {MuiModal} from 'shared/components/index';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

const StyledModalBox = styled(Box)`
  position: absolute;
  width: 200px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ModalButton = styled(Button)`
  width: 100%;
  height: 50px;
  border-top: ${({borderTop}) => borderTop && `1px solid grey`};
  border-bottom: ${({borderBottom}) => borderBottom && `1px solid grey`};
  border-top-left-radius: ${({topLeftRadius}) => topLeftRadius && '10px'};
  border-top-right-radius: ${({topRightRadius}) => topRightRadius && '10px'};
  border-bottom-left-radius: ${({bottomLeftRadius}) =>
    bottomLeftRadius && '10px'};
  border-bottom-right-radius: ${({bottomRightRadius}) =>
    bottomRightRadius && '10px'};
  &:hover {
    background-color: #f5f5f5;
  }
`;

const ActionsModal = ({
  headerActionsModal,
  setHeaderActionsModal,
  chatData,
  handleChatRoomPin,
  handleReportMessage,
  handleDeleteChatRoom,
}) => {
  const {t} = useTranslation();

  return (
    <MuiModal
      open={headerActionsModal}
      onClose={() => setHeaderActionsModal(false)}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <StyledModalBox>
        <ModalButton
          onClick={() => {
            setHeaderActionsModal(false);
            handleChatRoomPin(chatData);
          }}
          topLeftRadius
          topRightRadius
          borderBottom
        >
          <Typography variant="h6" fontWeight="bold" color="grey">
            {chatData?.is_pinned ? t('buttons.unpin') : t('buttons.pin')}
          </Typography>
        </ModalButton>

        <ModalButton
          onClick={() => {
            handleDeleteChatRoom(chatData);
            setHeaderActionsModal(false);
          }}
        >
          <Typography variant="h6" fontWeight="bold" color="red">
            {t('buttons.exit')}
          </Typography>
        </ModalButton>

        <ModalButton
          onClick={() => setHeaderActionsModal(false)}
          borderTop
          bottomLeftRadius
          bottomRightRadius
        >
          <Typography variant="h6" fontWeight="bold" color="grey">
            {t('buttons.cancel')}
          </Typography>
        </ModalButton>
      </StyledModalBox>
    </MuiModal>
  );
};

export default ActionsModal;
