import React, {useState} from 'react';
import styled from 'styled-components';
import ClearIcon from '@mui/icons-material/Clear';

// Styled components
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 600;
`;
// Styled components
const Wrap = styled.div`
  border-radius: 5px;
  display: flex;
  flex-direction: column;
`;

const StyledClearIcon = styled(ClearIcon)`
  cursor: pointer;
  color: red;
  margin-left: 10px;
  &:hover {
    color: darkred;
  }
`;

const Label = styled.label`
  width: 140px;
  text-align: center;
  cursor: pointer;
  background-color: #f0f0f0;
  padding: 8px 16px;
  border-radius: 4px;
  display: inline-block;
  margin-top: 10px;
  white-space: nowrap;
`;

const Input = styled.input`
  display: none;
`;

const FileListContainer = styled.div`
  margin-top: 10px;
  width: 90%;
`;

const FileEntry = styled.div`
  padding: 5px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TruncatedText = styled('span')`
  display: inline-block;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SyledD = styled.div`
  width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 10px;
`;

// FileSelector component
const FileSelector = ({name, label, formikProps}) => {
  const [fileList, setFileList] = useState([]);

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    const filePreviews = newFiles.map((file) => ({
      name: file.name,
      file: file,
    }));

    setFileList([...fileList, ...filePreviews]);
    formikProps.setFieldValue(name, [...formikProps.values[name], ...newFiles]);

    event.target.value = null; // Clear the file input after selection
  };

  const deleteFile = (fileToDelete) => {
    const updatedFileList = fileList.filter(
      (file) => file.name !== fileToDelete.name,
    );
    setFileList(updatedFileList);

    const newFiles = formikProps.values[name].filter(
      (file) => file.name !== fileToDelete.name,
    );
    formikProps.setFieldValue(name, newFiles);
  };

  return (
    <Wrapper>
      <label>{label}</label>

      <Wrap>
        <Label htmlFor={name}>파일 첨부</Label>

        <Input
          type="file"
          name={name}
          id={name}
          onChange={handleFileChange}
          multiple // Allows multiple file selections
        />

        <FileListContainer>
          {fileList.map((file, index) => (
            <FileEntry key={index}>
              <TruncatedText>{file.name}</TruncatedText>

              <StyledClearIcon onClick={() => deleteFile(file)} />
            </FileEntry>
          ))}
        </FileListContainer>
      </Wrap>
    </Wrapper>
  );
};

export default FileSelector;
