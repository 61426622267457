import React from 'react';
import styled from 'styled-components';
import theme from 'shared/styles/theme';

// Styled components
const Header = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 5px;
`;

const LinearStyle = styled.div`
  width: 6px;
  height: 20px;
  background: linear-gradient(
    ${theme.colors.kalsPurple},
    ${theme.colors.kalsBlue}
  );
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0px')};
`;

const HeaderText = styled.span`
  margin-left: 10px;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 220px;
  font-weight: 500;

  /* Adjust the width as needed /
  display: inline-block; / Ensures the span respects the max-width */
`;

// The component
const OwlerRNHeader = ({title, marginLeft = '10px'}) => {
  return (
    <Header>
      <LinearStyle marginLeft={marginLeft} />
      <HeaderText>{title}</HeaderText>
    </Header>
  );
};

export default OwlerRNHeader;
