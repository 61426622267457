import styled from 'styled-components';
import theme from 'shared/styles/theme';

const TableWrapper = styled.div`
  width: 1050px;
  margin: 20px 0 0 0;
`;

const TheTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed; /* 고정 테이블 레이아웃 사용 */
  border: 2px solid ${theme.colors.grey3};
  font-size: 15px;
  text-align: center;

  th,
  td {
    height: 32px;
    border: 2px solid ${theme.colors.grey3};
  }

  th {
    background-color: #dfd7f3;
    font-weight: 600;
  }

  .col-header {
    width: 34%; /* 헤더 열의 너비를 25%로 설정 */
  }

  .col-date {
    width: 11%; /* 날짜 열의 너비를 50%로 설정 */
  }

  .col-numbers {
    width: 55%; /* 숫자 열의 너비를 12.5%로 설정 */
  }
`;

const PdfTitleArea = ({supplementionList, start, end}) => {
  const formatDate = (date) => {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    year = year.toString().substr(-2);

    return [year, month, day].join('-');
  };
  return (
    <TableWrapper>
      <TheTable border="1">
        <thead>
          <tr>
            <td
              className="col-header"
              rowSpan="2"
              colSpan="2"
              style={{fontSize: '20px'}}
            >
              <div style={{display: 'flex', alignItems: 'center'}}>
                <img
                  src={
                    supplementionList?.logo
                      ? supplementionList?.logo
                      : '/favicon.ico'
                  }
                  style={{
                    margin: '0 10px 0 10px',
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                  }}
                  alt=""
                />
                <p
                  style={{
                    width: '270px',

                    textAlign: 'left',
                  }}
                >
                  {supplementionList?.corp_name}
                </p>
              </div>
            </td>
            <th className="col-date">조회 기간</th>
            <td className="col-numbers" colSpan="5">
              {formatDate(start)} ~ {formatDate(end)}
            </td>
          </tr>
          <tr>
            <th>요청 건수</th>
            <td>{supplementionList?.count.request_count}</td>
            <th>보완 건수</th>
            <td>{supplementionList?.count.supplementation_count}</td>
            <th>집행률</th>
            <td>{supplementionList?.count.supplementation_rate}%</td>
          </tr>
        </thead>
      </TheTable>
    </TableWrapper>
  );
};
export default PdfTitleArea;
