import React, {useCallback, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import SettingsTemplate from './template/index';
import {useDispatch} from 'react-redux';

import {getSupervisorListAsync} from 'franchise/utils/slices/frUserSlice';

const Settings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getSupervisorListAsync())
      .unwrap()
      .catch(() => {
        alert('권한이 없습니다.');
        navigate(-1);
      });
  }, [navigate, dispatch]);

  const handlePositionManagement = useCallback(async () => {
    navigate('/franchise/settings/positionManagement');
  }, [navigate]);

  const handleWorkerManagement = useCallback(async () => {
    navigate('/franchise/settings/workerManagement');
  }, [navigate]);

  const handlePaymentManagement = useCallback(async () => {
    navigate('/franchise/settings/paymentManagement');
  }, [navigate]);

  const handleSignManagement = useCallback(async () => {
    navigate('/franchise/settings/signManagement');
  }, [navigate]);

  const handleStoreReqManagement = useCallback(async () => {
    navigate('/franchise/settings/storeJoinRequests');
  }, [navigate]);

  const handleStoreManagement = useCallback(async () => {
    navigate('/franchise/settings/storeManage');
  }, [navigate]);

  return (
    <SettingsTemplate
      handlePositionManagement={handlePositionManagement}
      handleWorkerManagement={handleWorkerManagement}
      handlePaymentManagement={handlePaymentManagement}
      handleStoreReqManagement={handleStoreReqManagement}
      handleStoreManagement={handleStoreManagement}
      handleSignManagement={handleSignManagement}
    />
  );
};

export default Settings;
