import React from 'react';
import {Text, View, StyleSheet, Font} from '@react-pdf/renderer';
import theme from 'shared/styles/theme';

Font.register({
  family: 'Nanum Gothic',
  src: 'https://fonts.gstatic.com/ea/nanumgothic/v5/NanumGothic-Regular.ttf',
});

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    padding: 15,
    paddingTop: 15,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    color: 'black',
    border: 1,
    height: '24px',
    textAlign: 'center',
    fontSize: 10,
    marginBottom: '20px',
  },
  logoWrapper: {
    width: '34%',
    color: 'black',
    borderRight: 1,
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    padding: '0 8px',
  },
  textWrapper: {
    width: '100%',
    display: 'flex',
    height: '100%',
  },
  textTop: {
    width: '100%',
    height: '50%',
    borderBottom: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  textBottom: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  textDate: {
    width: '83.3333333333%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textTh: {
    width: '11.11111111111%',
    borderRight: 1,
    height: '100%',
    backgroundColor: '#dfd7f3',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textTd: {
    width: '22.2222222222%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    height: 40,
    width: 40,
    objectFit: 'cover',
    border: 0.1,
    borderRadius: 40,
  },
  text: {
    textAlign: 'center',
    verticalAlign: 'middle',
    fontSize: 9,
    fontFamily: 'Nanum Gothic',
    color: theme.colors.notBlack,
    fontWeight: 'thin',
  },
});

const truncate = (str, n) =>
  str.length > n ? `${str.substr(0, n - 1)}...` : str;

const PdfHeader = ({commuteLog}) => {
  const storeName = truncate(commuteLog?.store_name || '', 20); // Adjust the number as needed
  const position = truncate(commuteLog?.position || '', 20); // Adjust the number as needed
  const name = truncate(commuteLog?.name || '', 20); // Adjust the number as needed

  return (
    <View style={styles.header}>
      <View style={styles.textWrapper}>
        <View style={styles.textBottom}>
          <View style={styles.textTh}>
            <Text style={styles.text}>소속</Text>
          </View>
          <View style={[styles.textTd, {borderRight: 1}]}>
            <Text style={styles.text}>{storeName}</Text>
          </View>
          <View style={styles.textTh}>
            <Text style={styles.text}>직급</Text>
          </View>
          <View style={[styles.textTd, {borderRight: 1}]}>
            <Text style={styles.text}>{position}</Text>
          </View>
          <View style={styles.textTh}>
            <Text style={styles.text}>성명</Text>
          </View>
          <View style={[styles.textTd]}>
            <Text style={styles.text}>{name}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default PdfHeader;
