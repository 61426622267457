import React, {useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import {ko} from 'date-fns/esm/locale';
import RightIcon from 'shared/assets/icons/RightIcon';
import LeftIcon from 'shared/assets/icons/LeftIcon';
import InputDateStyle from 'shared/styles/datePickerStyle';
import theme from 'shared/styles/theme';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const StyledInput = styled.input`
  border: none;
  box-sizing: content-box;
  font-size: 18px;
  text-align: center;
  width: fit-content;
  display: inline-block;
  width: 95px;
  cursor: pointer;
  //margin-left: 12px;
`;

const DatePickerWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const AppIconBtn = styled.button`
  width: 30px;
  height: 30px;
  background: none;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50%;
  padding: 0;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${theme.colors.secondaryLight};
  }
`;

const CustomDatePicker = ({selectedDate, handleDateChange, taskDatePicker}) => {
  const [startDate, setStartDate] = useState(selectedDate);

  const handlePreviousDay = () => {
    const previousDay = new Date(startDate);
    previousDay.setDate(startDate.getDate() - 1);
    setStartDate(previousDay);
    handleDateChange(previousDay);
  };

  const handleNextDay = () => {
    const nextDay = new Date(startDate);
    nextDay.setDate(startDate.getDate() + 1);
    setStartDate(nextDay);
    handleDateChange(nextDay);
  };

  return (
    <DatePickerWrapper>
      <AppIconBtn onClick={handlePreviousDay}>
        <ChevronLeftIcon />
      </AppIconBtn>
      <InputDateStyle>
        <DatePicker
          selected={startDate}
          onChange={(date) => {
            setStartDate(date);
            handleDateChange(date);
          }}
          dateFormat="yyyy-MM-dd"
          customInput={
            <StyledInput taskDatePicker={taskDatePicker ? 'true' : 'false'} />
          }
          locale={ko}
        />
      </InputDateStyle>
      <AppIconBtn onClick={handleNextDay}>
        <ChevronRightIcon />
      </AppIconBtn>
    </DatePickerWrapper>
  );
};

export default CustomDatePicker;
