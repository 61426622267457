import React, {useCallback, useMemo} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {
  TableData,
  TableDataGroup,
  Text,
  TextBox,
} from 'shared/components/index';
import ImageSolidIcon from 'shared/assets/icons/ImageSolidIcon';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ImageRegularIcon from 'shared/assets/icons/ImageRegularIcon';
import CheckCircleSolidIcon from 'shared/assets/icons/CheckCircleSolidIcon';
import CheckCircleRegularIcon from 'shared/assets/icons/CheckCircleRegularIcon';
import theme from 'shared/styles/theme';
import {
  setImagePostModal,
  setImageDetailModal,
} from 'owler/utils/slices/taskSlice';
import {useSelector} from 'react-redux';
import {selectTask} from 'owler/utils/slices/taskSlice';
import toast from 'react-hot-toast';
const NoTask = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 60px;
`;

const TaskWrapper = styled.div`
  width: 97%;
  margin-left: 3%;
  border: 1px solid black;

  margin-bottom: 5px;
  border: ${({theme}) => `1px solid ${theme.colors.grey9}`};
  border-radius: 10px;
  padding: 1px 20px 1px 5px;
  display: flex;
  &:hover {
    & > * {
      cursor: pointer;
    }
    & a {
      font-weight: bold;
      text-decoration: underline;
    }
    border: ${({theme}) => `1px solid ${theme.colors.medium}`};
    background-color: ${({theme}) => theme.colors.lightYellow1};
  }
`;

const getFormattedTime = (date) => {
  const startDate = new Date(date);
  let startHour = startDate.getHours();
  let startMinute = startDate.getMinutes();

  startHour = startHour > 9 ? startHour : `0${startHour}`;
  startMinute = startMinute > 9 ? startMinute : `0${startMinute}`;

  return `${startHour}:${startMinute}`;
};

const SlaveTask = ({
  id,
  category,
  task,
  handleTaskClickBtn,
  changeCompleted,
}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const rawDate = useSelector((state) => state.owler.task.selectedDateT);
  const selectedDateT = useMemo(() => new Date(rawDate), [rawDate]);

  const renderIcon = useCallback(() => {
    if (task.task_type === 'image') {
      return task.is_completed ? (
        <ImageSolidIcon fill={theme.colors.kalsPurple} />
      ) : (
        <ImageRegularIcon fill="#A4A4A4" />
      );
    } else if (task.task_type === 'memo') {
      return <TextSnippetIcon sx={{color: '#A4A4A4'}} />;
    }

    return task.is_completed ? (
      <CheckCircleSolidIcon fill={theme.colors.kalsPurple} />
    ) : (
      <CheckCircleRegularIcon fill="#A4A4A4" />
    );
  }, [task.is_completed, task.task_type]);

  const formatTime = useCallback(
    (time) => `${getFormattedTime(time.start)}~${getFormattedTime(time.end)}`,
    [],
  );

  const renderPeriodText = (_selectedTask) => {
    switch (_selectedTask?.repetition) {
      case 0:
        return t('screens.task.no_repeat');
      case 1:
        return t('screens.task.repeat_everyday');
      case 2: /////////// 매주 0요일 마다
        return t('screens.task.repeat_everyweek');
      case 3:
        return t('screens.task.once_a_month');
      case 4: /////////// 4일에 한번
        return t('screens.task._day_repeat');
      case 5: ////////// 4주 0요일 마다
        return t('screens.task._week_repeat');
      case 6:
        return t('screens.task.once_week');
      default:
        return '';
    }
  };

  const clicked = (task) => {
    if (task.task_type === 'image' && task.is_completed === false) {
      let today = new Date();
      if (today.setHours(0, 0, 0, 0) === selectedDateT.setHours(0, 0, 0, 0)) {
        let time = new Date();
        let hours = time.getHours();
        let minutes = time.getMinutes();
        let seconds = time.getSeconds();
        let parts = task.end.split(':');

        if (hours > parts[0]) {
          toast.error('기간이 지난 업무입니다!');
          return;
        } else if (hours > parts[0]) {
          if (minutes >= parts[1]) {
            toast.error('기간이 지난 업무입니다!');
            return;
          } else if ((minutes = parts[1])) {
            if (seconds >= parts[2]) {
              toast.error('기간이 지난 업무입니다!');
              return;
            }
          }
        }
      } else if (
        today.setHours(0, 0, 0, 0) > selectedDateT.setHours(0, 0, 0, 0)
      ) {
        toast.error('기간이 지난 업무입니다!');
        return;
      }
    }

    changeCompleted(task);

    if (task.task_type === 'image' && task.is_completed === false) {
      dispatch(setImagePostModal(true));
    } else if (task.task_type === 'image' && task.is_completed === true) {
      dispatch(setImageDetailModal(true));
    }
  };

  return (
    <>
      <TaskWrapper onClick={() => {}} border>
        <TableDataGroup onClick={() => handleTaskClickBtn(task, category)}>
          <TableData>
            <TextBox
              backgroundColor={theme.colors.kalsPurple}
              text={
                <Text
                  color={theme.colors.white}
                  style={{fontSize: '13px'}}
                  bold
                >
                  {renderPeriodText(task)}
                </Text>
              }
            />
          </TableData>
          <TableData>
            <Text margin="0 0 0 10px" style={{fontSize: '14px'}}>
              {task.name}
            </Text>
          </TableData>
        </TableDataGroup>
        <TableDataGroup width="30px" justifyContent="space-between">
          <TableData>
            <Text text={formatTime(task.start)} />
          </TableData>
          {/* 이미지인 경우에만 확인 가능 */}
          <TableData
            onClick={() => {
              clicked(task);
            }}
          >
            {renderIcon()}
          </TableData>
        </TableDataGroup>
      </TaskWrapper>
    </>
  );
};

const TaskView = ({tasks, category, handleTaskClickBtn, changeCompleted}) => {
  const renderTasks = useMemo(
    () =>
      tasks?.map((task) => {
        return (
          <SlaveTask
            key={task.task_uid}
            id={task.task_uid}
            task={task}
            category={category}
            handleTaskClickBtn={handleTaskClickBtn}
            changeCompleted={changeCompleted}
          />
        );
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tasks, handleTaskClickBtn],
  );
  if (renderTasks?.length === 0) {
    return (
      <NoTask>
        <Text style={{fontSize: '14px', color: '#bdbdbd'}}>
          업무가 존재하지 않습니다
        </Text>
      </NoTask>
    );
  }
  return renderTasks;
};

export default TaskView;
