import OwlerReviewTemplate from "./template/index";

const OwlerReview = () => {
    return(
    <>
    <OwlerReviewTemplate/>
    </>
    )
}

export default OwlerReview; 