/* eslint-disable react/prop-types */
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

// eslint-disable-next-line no-restricted-syntax
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AppSimpleModal({
  isOpen,
  btnOne,
  btnTwo,
  titleText,
  bodyText,
  handleClose,
  handleBtnOneClick,
}) {
  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description">
      <DialogTitle>{titleText}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description" style={{fontSize: '18px', fontWeight: '600'}}>{bodyText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {btnOne && (
          <Button color="secondary" onClick={handleBtnOneClick}>
            {btnOne}
          </Button>
        )}

        {btnTwo && (
          <Button color="secondary" onClick={handleClose}>
            {btnTwo}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
