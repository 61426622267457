/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/require-default-props */
import React, {useState} from 'react';
import {MuiModal, Text} from 'shared/components/index';
import styled from 'styled-components';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import MoreImageModal from './MoreImageModal';
const Container = styled.div`
  width: 600px;
  max-height: 650px;
  min-height: 500px;
  border-radius: 5px;
  background-color: #fff;
  padding: 5px 25px 5px 25px;
  overflow-y: scroll;
`;
const StyledCameraIcon = styled(PhotoCameraOutlinedIcon)`
  && {
    color: ${(props) => (props.coolor ? props.coolor : '#A8A8A8')};
  }
  margin-left: 15px;
`;

const StyledText = styled.p`
  font-size: ${(props) => (props.size ? props.size : '14px')};
  font-weight: ${(props) => (props.weight ? props.weight : '400')};
  color: ${(props) => (props.color ? props.color : 'black')};
  margin: 12px 0 5px 0;
`;

const StoreListContainer = styled.div`
  width: 98%;
  max-height: 230px;
  min-height: 160px;
  overflow-y: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    scrollbar-width: none;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  max-height: 530px;
  min-height: 379px;
`;

const UserContainer = styled.div`
  width: 100%;
  border-bottom: 0.3px solid #cecece;
  padding: 10px 15px 10px 0;
  user-select: none;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    background-color: #e5e5e5;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  height: auto;
  text-align: center;
`;

const StyledButton = styled.button`
  font-size: 12px;
  padding: 6px 30px;
  color: #fff;
  background-color: #8773d6;
  border: none;
  border-radius: 20px;
  margin: 5px auto;
  cursor: pointer;
`;

const UserImage = styled.img`
  width: 30px;
  height: 30px;
  display: inline;
  margin: 0 10px;
  vertical-align: middle;
  border: 1px solid black;
  border-radius: 15px;
`;

const StyledSpan2 = styled.div`
  display: flex;
  height: 32px;
  align-items: center;
`;
const MoreFeedbackModal = ({feedback, open, handleClose}) => {
  const [toggleModal, setTogleModal] = useState(false);

  const clickCameraIcon = () => {
    setTogleModal(true);
  };
  const handleCloseModal = () => {
    setTogleModal(false);
  };

  if (feedback.type === 'check') {
    return (
      <MuiModal open={open} onClose={handleClose}>
        <Container>
          <Wrapper>
            <StyledText color="#0ead69" weight="bold" size="16px">
              확인
            </StyledText>
            {feedback.feedback.length > 0 ? (
              <StoreListContainer>
                {feedback.feedback.map((user, i) => (
                  <UserContainer key={i}>
                    <StyledSpan2>
                      <UserImage
                        src={
                          user.image !== null ? user.image : '/empty_user.png'
                        }
                      />
                      <Text
                        style={{
                          width: '300px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {user.name}
                      </Text>
                    </StyledSpan2>
                    <StyledSpan2>
                      <Text
                        style={{
                          width: '120px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          textAlign: 'right',
                        }}
                      >
                        {user.position}
                      </Text>
                    </StyledSpan2>
                  </UserContainer>
                ))}
              </StoreListContainer>
            ) : (
              <MoreHorizIcon />
            )}

            <StyledText color="#e63946" weight="bold" size="16px">
              미확인
            </StyledText>
            {feedback.incompleteUsers.length > 0 ? (
              <StoreListContainer>
                {feedback.incompleteUsers.map((user, i) => (
                  <UserContainer key={i}>
                    <StyledSpan2>
                      <UserImage
                        src={
                          user.image !== null ? user.image : '/empty_user.png'
                        }
                      />
                      <Text
                        style={{
                          width: '300px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {user.name}
                      </Text>
                    </StyledSpan2>
                    <StyledSpan2>
                      <Text
                        style={{
                          width: '120px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          textAlign: 'right',
                        }}
                      >
                        {user.position}
                      </Text>
                    </StyledSpan2>
                  </UserContainer>
                ))}
              </StoreListContainer>
            ) : (
              <MoreHorizIcon />
            )}
          </Wrapper>
          <ButtonContainer>
            <StyledButton onClick={handleClose}>확인</StyledButton>
          </ButtonContainer>
        </Container>
      </MuiModal>
    );
  }

  if (feedback.type === 'image') {
    return (
      <MuiModal open={open} onClose={handleClose}>
        <Container>
          <Wrapper>
            <StyledText color="#0ead69" weight="bold" size="16px">
              확인
            </StyledText>
            {feedback.feedback.length > 0 ? (
              <StoreListContainer>
                {feedback.feedback.map((user, i) => (
                  <UserContainer onClick={() => clickCameraIcon()} key={i}>
                    <StyledSpan2>
                      <UserImage
                        src={
                          user.image !== null ? user.image : '/empty_user.png'
                        }
                      />
                      <Text
                        style={{
                          width: '300px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {user.name}
                      </Text>
                    </StyledSpan2>
                    <StyledSpan2>
                      <Text
                        style={{
                          width: '120px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          textAlign: 'right',
                        }}
                      >
                        {user.position}
                      </Text>
                    </StyledSpan2>
                    <StyledSpan2>
                      <StyledCameraIcon coolor="#8773D6" />
                    </StyledSpan2>
                  </UserContainer>
                ))}
              </StoreListContainer>
            ) : (
              <MoreHorizIcon />
            )}

            <StyledText color="#e63946" weight="bold" size="16px">
              미확인
            </StyledText>
            {feedback.incompleteUsers.length > 0 ? (
              <StoreListContainer>
                {feedback.incompleteUsers.map((user, i) => (
                  <UserContainer key={i}>
                    <StyledSpan2>
                      <UserImage
                        src={
                          user.image !== null ? user.image : '/empty_user.png'
                        }
                      />
                      <Text
                        style={{
                          width: '300px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {user.name}
                      </Text>
                    </StyledSpan2>
                    <StyledSpan2>
                      <Text
                        style={{
                          width: '120px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          textAlign: 'right',
                        }}
                      >
                        {user.position}
                      </Text>
                    </StyledSpan2>
                  </UserContainer>
                ))}
              </StoreListContainer>
            ) : (
              <MoreHorizIcon />
            )}
          </Wrapper>
          <ButtonContainer>
            <StyledButton onClick={handleClose}>확인</StyledButton>
          </ButtonContainer>
        </Container>
        {toggleModal && (
          <MoreImageModal
            feedback={feedback}
            open={toggleModal}
            handleClose={handleCloseModal}
          />
        )}
      </MuiModal>
    );
  }
  return null;
};

export default MoreFeedbackModal;
