import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {selectStore, setCurrentStore} from 'owler/utils/slices/storeSlice';
import {
  getStoreTasksByDateAsync,
  selectTask,
  setAddCategoryModal,
  setCategorySettingsModal,
  setSelectedCategory,
  getTaskAsync,
  postCompleteionTaskAsync,
  deleteCompleteionTaskAsync,
  getCompleteionTaskAsync,
  setSelectedTask,
  setSelectedDateT,
} from 'owler/utils/slices/taskSlice';
import toast from 'react-hot-toast';
import TaskListTemplate from './template';
import {useCallback, useEffect, useState, useMemo} from 'react';

const TaskList = () => {
  const {currentStore} = useSelector(selectStore);
  const {masterTaskList} = useSelector(selectTask);
  const rawDate = useSelector((state) => state.owler.task.selectedDateT);
  const selectedDateT = useMemo(() => new Date(rawDate), [rawDate]);
  // const [selectedDate, setSelectedDate] = useState(new Date());
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {selectedTask} = useSelector(selectTask);
  const {imageDetail} = useSelector(selectTask);
  const [taskUid, setTaskUid] = useState('');
  const {error} = useSelector(selectTask);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (currentStore) {
      setIsLoading(true);
      const year = selectedDateT.getFullYear();
      const month = (selectedDateT.getMonth() + 1).toString().padStart(2, '0'); // 월을 0으로 패딩
      const day = selectedDateT.getDate().toString().padStart(2, '0'); // 일을 0으로 패딩
      const date = `${year}-${month}-${day}`;
      dispatch(
        getStoreTasksByDateAsync({
          store_uid: currentStore.store_uid,
          date,
        }),
      )
        .unwrap()
        .finally(() => {
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStore, dispatch]);

  const handleDateChange = (date2) => {
    setIsLoading(true);
    dispatch(setSelectedDateT(date2));
    const year = date2.getFullYear();
    const month = (date2.getMonth() + 1).toString().padStart(2, '0'); // 월을 0으로 패딩
    const day = date2.getDate().toString().padStart(2, '0'); // 일을 0으로 패딩
    const date = `${year}-${month}-${day}`;
    dispatch(
      getStoreTasksByDateAsync({
        store_uid: currentStore.store_uid,
        date,
      }),
    )
      .unwrap()
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCreateNewTaskBtn = (event, _cat) => {
    event.stopPropagation(); // Stop event propagation
    dispatch(setSelectedCategory(_cat));
    navigate(`/owler/tasks/taskAdd/${_cat.task_category_uid}`, {
      state: {id: _cat.task_category_uid, name: _cat.name},
    });
  };

  const handleCategorySettingsBtn = (event, _cat) => {
    event.stopPropagation(); // Stop event propagation
    dispatch(setSelectedCategory(_cat));

    dispatch(setCategorySettingsModal(true));
  };

  const handleTaskClickBtn = useCallback(
    async (_task, _cat) => {
      await dispatch(getTaskAsync(_task));

      dispatch(setSelectedCategory(_cat));
      dispatch(setSelectedTask(_task));
      navigate(`/owler/tasks/taskDetail/${_task.task_uid}`, {
        state: {
          task: _task,
          cat: _cat.name,
        },
      });
    },
    [dispatch, navigate, selectedTask, taskUid],
  );

  const handleCategoryAddBtnClick = () => {
    dispatch(setAddCategoryModal(true));
  };

  const changeCompleted = useCallback(
    async (task) => {
      //Owen's
      setTaskUid(task.task_uid);
      const year = selectedDateT.getFullYear();
      const month = (selectedDateT.getMonth() + 1).toString().padStart(2, '0'); // 월을 0으로 패딩
      const day = selectedDateT.getDate().toString().padStart(2, '0'); // 일을 0으로 패딩
      const date = `${year}-${month}-${day}`;
      if (task.is_completed === false && task.task_type === 'check') {
        await dispatch(
          postCompleteionTaskAsync({
            task_uid: task.task_uid,
          }),
        )
          .unwrap()
          .then(({en, kr}) => {
            toast.success(kr);

            dispatch(
              getStoreTasksByDateAsync({
                store_uid: currentStore.store_uid,
                date,
              }),
            );
          })
          .catch((error) => {
            toast.error(error);
          });
      } else if (task.is_completed === true && task.task_type === 'check') {
        await dispatch(
          deleteCompleteionTaskAsync({
            task_uid: task.task_uid,
          }),
        )
          .unwrap()
          .then(({en, kr}) => {
            toast.success(kr);

            dispatch(
              getStoreTasksByDateAsync({
                store_uid: currentStore.store_uid,
                date,
              }),
            );
          })
          .catch((error) => {
            toast.error(error);
          });
      } else if (task.is_completed === true && task.task_type === 'image') {
        await dispatch(
          getCompleteionTaskAsync({
            task_uid: task.task_uid,
          }),
        );
      }

      // if(!(task.is_completed === false && task.task_type === 'image')){
      //   dispatch(getStoreTasksByDateAsync({
      //       store_uid: currentStore.store_uid,
      //       date}),);
      // }
    },
    [dispatch, selectedDateT],
  );

  const onSubmit = useCallback(
    async (images) => {
      // dispatch your async thunk action
      const formData = new FormData();
      formData.append('task_uid', taskUid);
      images.forEach((imageFile, index) => {
        const fileName = imageFile.name;
        formData.append(`images`, imageFile, fileName);
      });

      await dispatch(postCompleteionTaskAsync(formData))
        .unwrap()
        .then(({en, kr}) => {
          toast.success(kr);
        })
        .catch((error) => {
          toast.error(error);
        });

      const year = selectedDateT.getFullYear();
      const month = (selectedDateT.getMonth() + 1).toString().padStart(2, '0'); // 월을 0으로 패딩
      const day = selectedDateT.getDate().toString().padStart(2, '0'); // 일을 0으로 패딩
      const date = `${year}-${month}-${day}`;
      dispatch(
        getStoreTasksByDateAsync({
          store_uid: currentStore.store_uid,
          date,
        }),
      );
    },
    [currentStore, dispatch, selectedDateT, taskUid],
  );

  const handleStoreClick = useCallback(
    (_store) => {
      dispatch(setCurrentStore(_store));
    },
    [dispatch],
  );

  return (
    <TaskListTemplate
      currentStore={currentStore}
      masterTaskList={masterTaskList}
      selectedDate={selectedDateT}
      handleDateChange={handleDateChange}
      handleCreateNewTaskBtn={handleCreateNewTaskBtn}
      handleCategorySettingsBtn={handleCategorySettingsBtn}
      handleTaskClickBtn={handleTaskClickBtn}
      handleCategoryAddBtnClick={handleCategoryAddBtnClick}
      changeCompleted={changeCompleted}
      onSubmit={onSubmit}
      imageDetail={imageDetail}
      handleStoreClick={handleStoreClick}
      isLoading={isLoading}
    />
  );
};

export default TaskList;
