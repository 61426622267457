import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : null)};
`;
const LeftIcon = ({ length, fill, onClick }) => {
  return (
    <StyledSvg
      onClick={onClick}
      width={length}
      height={length}
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="caret-left"
      className="svg-inline--fa fa-caret-left fa-w-6"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 192 512">
      <path
        fill={fill}
        d="M192 127.338v257.324c0 17.818-21.543 26.741-34.142 14.142L29.196 270.142c-7.81-7.81-7.81-20.474 0-28.284l128.662-128.662c12.599-12.6 34.142-3.676 34.142 14.142z"
      />
    </StyledSvg>
  );
};

LeftIcon.propTypes = {
  length: PropTypes.number,
  fill: PropTypes.string,
  onClick: PropTypes.func,
};
LeftIcon.defaultProps = {
  length: 20,
  fill: 'black',
  onClick: null,
};
export default LeftIcon;
