import styled from 'styled-components';
import React from 'react';

import theme from 'shared/styles/theme';
import {ScheduleViewGanttChart} from 'shared/components/index';

const ModalWrapper = styled.div`
  background-color: ${theme.colors.notBlack};
  height: 250px;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

function ViewScheduleDetailModal({scheduleTimeline}) {
  return (
    <ModalWrapper>
      <ScheduleViewGanttChart scheduleTimeline={scheduleTimeline} />
    </ModalWrapper>
  );
}

export default ViewScheduleDetailModal;
