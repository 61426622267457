import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from 'shared/utils/api';
import i18n from 'i18next';

const initialState = {
  joinMembers: [],
  offerList: null,
  allMembers: [],
  allPositions: [],
  brandList: [],
  brandAssinment: {
    all: false,
    stores: [],
    names: [],
  },
  positionDetailModalOpen: false,
  questionModalOpen: false,
  positionChangeModalOpen: false,
  positionAddModalOpen: false,
  memberPositionChangeModalOpen: false,
};

export const getPhoneJoinMemberAsync = createAsyncThunk(
  `position/getPhoneJoinMember`,
  async (data, {rejectWithValue}) => {
    try {
      const response = await api.getPhoneJoinMember(data);
      return response.data;
    } catch (error) {
      const errorResponse = error.response || {};
      const data = errorResponse.data || {};
      const message =
        i18n.language === 'ko'
          ? data.kr || error.message || '잘못된 접근입니다.'
          : data.en || error.message || 'Something went wrong :(';
      return rejectWithValue(message);
    }
  },
);

export const postPhoneJoinMemberAsync = createAsyncThunk(
  `position/postPhoneJoinMemberAsync`,
  async (data, {rejectWithValue}) => {
    try {
      const response = await api.postPhoneJoinMember(data);
      return response.data;
    } catch (error) {
      const errorResponse = error.response || {};
      const data = errorResponse.data || {};
      const message =
        i18n.language === 'ko'
          ? data.kr || error.message || '잘못된 접근입니다.'
          : data.en || error.message || 'Something went wrong :(';
      return rejectWithValue(message);
    }
  },
);

export const getJoinMemberAsync = createAsyncThunk(
  `position/getJoinMemberAsync`,
  async (data) => {
    const response = await api.getJoinMember(data);
    const {results} = response.data;

    return results;
  },
);
export const postJoinMemberAsync = createAsyncThunk(
  `position/postJoinMemberAsync`,
  async (data) => {
    const response = await api.postJoinMember(data);
    const {results} = response.data;

    return results;
  },
);

export const deleteJoinMemberAsync = createAsyncThunk(
  `position/deleteJoinMemberAsync`,
  async (data) => {
    const response = await api.deleteJoinMember(data);
    const {results} = response.data;

    return results;
  },
);

export const getAllMembersAsync = createAsyncThunk(
  `position/getAllMembersAsync`,
  async (data) => {
    const response = await api.getAllMembers(data);
    const {results} = response.data;

    return results;
  },
);
export const patchAllMembersAsync = createAsyncThunk(
  `position/patchAllMembersAsync`,
  async (data, {rejectWithValue}) => {
    try {
      const response = await api.patchAllMembers(data);

      const {results} = response.data;

      return results;
    } catch (error) {
      const errorResponse = error.response || {};
      const data = errorResponse.data || {};

      return rejectWithValue(data.kr);
    }
  },
);

export const deleteAllMembersAsync = createAsyncThunk(
  `position/deleteAllMembersAsync`,
  async (data, {rejectWithValue}) => {
    try {
      const response = await api.deleteAllMembers(data);

      const {results} = response.data;

      return results;
    } catch (error) {
      const errorResponse = error.response || {};
      const data = errorResponse.data || {};

      return rejectWithValue(data.kr);
    }
  },
);

export const getAllPositionsAsync = createAsyncThunk(
  `position/deleteAllMembersAsync`,
  async (data) => {
    const response = await api.getAllPositions(data);
    const {results} = response.data;

    return results;
  },
);
export const postAllPositionsAsync = createAsyncThunk(
  `position/deleteAllMembersAsync`,
  async (data, {rejectWithValue}) => {
    try {
      const response = await api.postAllPositions(data);

      const {results} = response.data;

      return results;
    } catch (error) {
      const errorResponse = error.response || {};
      const data = errorResponse.data || {};

      return rejectWithValue(data.kr);
    }
  },
);
export const patchAllPositionsAsync = createAsyncThunk(
  `position/deleteAllMembersAsync`,
  async (data, {rejectWithValue}) => {
    try {
      const response = await api.patchAllPositions(data);

      const {results} = response.data;

      return results;
    } catch (error) {
      const errorResponse = error.response || {};
      const data = errorResponse.data || {};

      return rejectWithValue(data.kr);
    }
  },
);
export const deleteAllPositionsAsync = createAsyncThunk(
  `position/deleteAllMembersAsync`,
  async (data, {rejectWithValue}) => {
    try {
      const response = await api.deleteAllPositions(data);

      const {results} = response.data;

      return results;
    } catch (error) {
      const errorResponse = error.response || {};
      const data = errorResponse.data || {};

      return rejectWithValue(data.kr);
    }
  },
);

export const getBrandAssignMentListAsync = createAsyncThunk(
  `position/getBrandAssignMentListAsync`,
  async (data) => {
    const response = await api.getBrandAssignMentList(data);
    const {results} = response.data;

    return results;
  },
);

export const postBrandAssignMentAsync = createAsyncThunk(
  `position/postBrandAssignMentAsync`,
  async (data) => {
    const response = await api.postBrandAssignMent(data);
    const {results} = response.data;

    return results;
  },
);

export const positionSlice = createSlice({
  name: 'position',
  initialState,
  reducers: {
    setJoinMembers: (state, action) => {
      state.joinMembers = action.payload;
    },
    setOfferList: (state, action) => {
      state.offerList = action.payload;
    },
    setAllMembers: (state, action) => {
      state.allMembers = action.payload;
    },
    setPositionDetailModalOpen: (state, action) => {
      state.positionDetailModalOpen = action.payload;
    },
    setAllPositions: (state, action) => {
      state.allPositions = action.payload;
    },
    setBrandList: (state, action) => {
      state.brandList = action.payload;
    },
    setBrandAssinment: (state, action) => {
      state.brandAssinment = action.payload;
    },
    setQuestionModalOpen: (state, action) => {
      state.questionModalOpen = action.payload;
    },
    setPositionChangeModalOpen: (state, action) => {
      state.positionChangeModalOpen = action.payload;
    },
    setPositionAddModalOpen: (state, action) => {
      state.positionAddModalOpen = action.payload;
    },
    setMemberPositionChangeModalOpen: (state, action) => {
      state.memberPositionChangeModalOpen = action.payload;
    },
    resetStoreSlice: () => initialState,
  },
});

export const {
  setJoinMembers,
  setOfferList,
  setAllMembers,
  setPositionDetailModalOpen,
  setAllPositions,
  setQuestionModalOpen,
  setPositionChangeModalOpen,
  setPositionAddModalOpen,
  setMemberPositionChangeModalOpen,
  setBrandList,
  setBrandAssinment,
} = positionSlice.actions;

export const selectPosition = (state) => state.owler.position;

export default positionSlice.reducer;
