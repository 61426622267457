import {useEffect, useState} from 'react';
import styled from 'styled-components';
import theme from 'shared/styles/theme';
import {Text} from 'shared/components/index';
import Button from '@mui/material/Button';
import {useSelector, useDispatch} from 'react-redux';
import {
  selectPosition,
  setPositionDetailModalOpen,
  setBrandAssinment,
} from 'owler/utils/slices/positionSlice';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {MuiButton} from 'shared/components/index';

const Container = styled.div`
  width: 95%;
  margin-left: 2.5%;
  margin-top: 10px;
`;
const PositionName = styled.p`
  padding-left: 5px;
  border-bottom: 1px solid ${theme.colors.grey9};
`;
const PositionBox = styled.div`
  background-color: #f2f2f2;
  padding: 10px 20px;
  border-radius: 10px;
  margin: 10px 0;
`;
const MemberBox = styled.div`
  display: flex;
  overflow: auto;
  margin-left: -9px;
`;
const Members = styled.div`
  width: 80px;
  min-width: 80px;
  display: flex;
  flex-direction: column;
  margin: 5px 10px;
  padding: 10px 10px;
  background-color: #d8d8d8;
  border-radius: 25px;
  &:hover {
    cursor: pointer;
  }
`;
const Img = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 40px;
  object-fit: cover;
  background-color: white;
  margin-bottom: 5px;
`;
const BrandWrapper = styled.div`
  width: 145px;
  height: 145px;
  border-radius: 15px;
  box-shadow: 0px 2px 2px 2px #bdbdbd;
  margin: 5px;
  background-color: white;
`;
const BrandContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 336px;
  width: 100%;
  justify-content: start;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow-x: auto;
  border-radius: 20px;
  background-color: #fafafa;
  border: 1px solid #f2f2f2;
`;
const BrandImg = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 40px;
  object-fit: cover;
  background-color: white;
`;
const ImgWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  top: -7px;
`;
const ButtonWrapper = styled.div`
  width: 100%;
  text-align: right;
  margin: 20px 0;
`;
const BrandManagement = ({currentStore, handleMemberClick, handleSubmit}) => {
  const {allMembers, brandList, brandAssinment} = useSelector(selectPosition);
  const [selected, setSelected] = useState(null);
  const dispatch = useDispatch();
  const [changeBrand, setChangeBrand] = useState(false);

  useEffect(() => {
    dispatch(
      setBrandAssinment({
        alls: false,
        stores: [],
        names: [],
      }),
    );
    const brand_name = [];
    const brand_uid = [];
    brandList.map((brand) => {
      if (brand.is_joined) {
        brand_name.push(brand.store_name);
        brand_uid.push(brand.brand_store_uid);
      }
    });
    dispatch(
      setBrandAssinment({
        ...brandAssinment,
        stores: brand_uid,
        names: brand_name,
      }),
    );
  }, [brandList]);

  const handleCheckedStores = (e) => {
    const checked = e.target.value;
    const id = e.target.id;

    if (e.target.checked) {
      if (
        !brandAssinment.stores.includes(checked) &&
        !brandAssinment.names.includes(id)
      ) {
        dispatch(
          setBrandAssinment({
            ...brandAssinment,
            stores: [...brandAssinment.stores, checked],
            names: [...brandAssinment.names, id],
          }),
        );
      }
    } else {
      dispatch(
        setBrandAssinment({
          ...brandAssinment,
          stores: brandAssinment.stores.filter(
            (store) => store.indexOf(checked) === -1,
          ),
          names: brandAssinment.names.filter((name) => name.indexOf(id) === -1),
        }),
      );
    }
  };

  return (
    <Container>
      {changeBrand === false ? (
        allMembers.map((position, index) => {
          return (
            <PositionBox key={index}>
              <PositionName>{position.position_name}</PositionName>
              <MemberBox>
                {position?.members_list?.map((member, index) => {
                  return (
                    <Members
                      key={member.image}
                      onClick={() => {
                        setSelected({
                          position: position.position_name,
                          member: member,
                        });
                        setChangeBrand(true);
                        handleMemberClick(member);
                      }}
                    >
                      <Img
                        src={
                          member.image !== null
                            ? member.image
                            : '/empty_user.png'
                        }
                      />
                      <Text bold style={{fontSize: '12px'}}>
                        {member.name}
                      </Text>
                    </Members>
                  );
                })}
              </MemberBox>
            </PositionBox>
          );
        })
      ) : (
        <div>
          <Text bold>{selected.member.name}</Text>
          <BrandContainer>
            {brandList.map((brand) => {
              return (
                <BrandWrapper>
                  <Checkbox
                    sx={{
                      margin: '-5px 0 0 108px',
                      '&.Mui-checked': {
                        color: `${theme.colors.kalsPurple}`,
                      },
                    }}
                    id={brand.store_name}
                    value={brand.brand_store_uid}
                    checked={brandAssinment.stores.includes(
                      brand.brand_store_uid,
                    )}
                    onChange={handleCheckedStores}
                  />
                  <ImgWrapper>
                    <BrandImg src={brand.logo} />
                  </ImgWrapper>
                  <div style={{textAlign: 'center'}}>
                    <Text style={{fontSize: '13px'}} bold>
                      {brand.store_name}
                    </Text>
                  </div>
                </BrandWrapper>
              );
            })}
          </BrandContainer>
          <ButtonWrapper>
            <MuiButton
              onClick={() => {
                setChangeBrand(false);
                handleSubmit(selected);
              }}
              text="완료"
              backgroundColor={theme.colors.kalsPurple}
              textColor="white"
              fontSize="18px"
            ></MuiButton>
          </ButtonWrapper>
        </div>
      )}
    </Container>
  );
};

export default BrandManagement;
