import {Wrapper, Drawer} from 'shared/components/index';
import {Toaster} from 'react-hot-toast';
import styled from 'styled-components';
import ReactCalendar from './ReactCalendar';
import DayScheduleModal from './DayScheduleModal';
import EventAddModal from './EventAddModal';
import EventDetailModal from './EventDetailModal';
import {useSelector} from 'react-redux';
import {selectCalendar} from 'owler/utils/slices/calendarSlice';

const Container = styled.div`
  margin-top: 68px;
  width: 1060px;
  display: flex;
  // align-items: center;
  // justify-content: center;
  margin-left: 45px;
`;
const CalendarTemplate = ({
  handleStoreClick,
  currentStore,
  masterNoticeList,
  handleEventSubmit,
  deleteEvent,
  setSelectedDate,
  load,
  member_uid,
  reload,
}) => {
  const why = 'true';
  const {eventAddModal, eventDetailModal, extraNoticeList} =
    useSelector(selectCalendar);
  return (
    <Wrapper handleStoreClick={handleStoreClick}>
      <Drawer why={why}>
        <div style={{display: 'flex'}}>
          <Container>
            <Toaster toastOptions={{duration: 4000}} />
            <ReactCalendar setSelectedDate={setSelectedDate} load={load} />
            <DayScheduleModal />
            {eventAddModal && (
              <EventAddModal
                currentStore={currentStore}
                masterNoticeList={masterNoticeList}
                extraNoticeList={extraNoticeList}
                handleEventSubmit={handleEventSubmit}
              />
            )}
            {eventDetailModal && (
              <EventDetailModal
                deleteEvent={deleteEvent}
                member_uid={member_uid}
                reload={reload}
                currentStore={currentStore}
              />
            )}
            {/* {categoryModifyModal && <EventCategoryModifyModal/>}  */}
          </Container>
        </div>
      </Drawer>
    </Wrapper>
  );
};

export default CalendarTemplate;
