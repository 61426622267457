import React from 'react';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import {MuiModal, Text} from 'shared/components/index';
import {useDispatch, useSelector} from 'react-redux';
import {
  selectNotice,
  setNotificationModal,
  setNotificationDetail,
} from 'owler/utils/slices/noticeSlice';

const Container = styled.div`
  background-color: white;
  width: 700px;
  height: 560px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 5px;
`;

const BtnWrapper = styled.button`
  border: none;
  background-color: white;
  margin-left: 20px;
  cursor: pointer;
`;

const ContentWrapper = styled.div`
  width: 95%;
  margin-left: 2.5%;
  text-align: left;
  margin-top: 30px;
`;
const NotificationModal = () => {
  const dispatch = useDispatch();
  const {notificationModal, notificationDetail} = useSelector(selectNotice);
  const handleModalClose = () => {
    dispatch(setNotificationModal(false));
    dispatch(setNotificationDetail(null));
  };
  return (
    <MuiModal open={notificationModal} onClose={handleModalClose}>
      <Container>
        <TitleWrapper>
          <BtnWrapper onClick={handleModalClose}>
            <Text bold margin="0 232px 0 0">
              {notificationDetail?.title}
            </Text>
            <CloseIcon />
          </BtnWrapper>
        </TitleWrapper>
        <ContentWrapper>{notificationDetail?.contents}</ContentWrapper>
      </Container>
    </MuiModal>
  );
};
export default NotificationModal;
