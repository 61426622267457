/* eslint-disable prefer-template */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {ko} from 'date-fns/esm/locale';
import {
  Button,
  FranchiseDrawer,
  FranchiseWrapper,
  TableContent,
  TableData,
  TableDataGroup,
  TableHeader,
  TableRow,
  TableWrapper,
  Text,
} from 'shared/components/index';
import theme from 'shared/styles/theme';
import {setCurrentQSCPage} from 'franchise/utils/slices/frQscSlice';
import RightIcon from 'shared/assets/icons/RightIcon';
import LeftIcon from 'shared/assets/icons/LeftIcon';
import InputDateStyle from 'shared/styles/datePickerStyle';
import {useDispatch} from 'react-redux';

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  background-color: #e8e8e8;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #d9d9d9;
  }

  &:active {
    background-color: #bdbdbd;
  }
  svg {
    width: 16px;
    height: 16px;
    fill: #333;
  }
`;

const QscBtnContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  margin-right: 5px;
  padding: 2px 5px;
`;
const NoQscDiv = styled.div`
  font-size: 13px;
  width: 100%;
  color: #bdbdbd;
  text-align: center;
`;
const StyledTableContent2 = styled(TableContent)`
  margin-left: 50px;
`;
const StyledInput = styled.input`
  border: none;
  box-sizing: content-box;
  font-size: 15px;
  text-align: center;
  width: fit-content;
  display: inline-block;
  cursor: pointer;

  width: 100px;

  text-decoration-thickness: 2px;
`;
const CustomPagination = styled(Pagination)`
  & .MuiPaginationItem-page.Mui-selected {
    background-color: ${theme.colors.kalsPurple};
  }
  & .MuiPaginationItem-page.Mui-active {
    background-color: ${theme.colors.kalsPurple};
  }
  & .MuiPaginationItem-page:hover {
    background-color: ${theme.colors.kalsPurple}; // 원하는 색상으로 수정하세요.
  }
  & .MuiPaginationItem-page.Mui-selected:hover {
    background-color: #8f77d9; // 원하는 색상으로 수정하세요.
  }
`;

const QSCSelctor = ({selectedValue, setSelectedValue, handleDateChange}) => {
  const [startDate, setStartDate] = useState(new Date());
  const dispatch = useDispatch();
  const handlePreviousDay = () => {
    const previousDay = new Date(startDate);
    previousDay.setDate(startDate.getDate() - 1);
    setStartDate(previousDay);
    handleDateChange(previousDay);
  };

  const handleNextDay = () => {
    const nextDay = new Date(startDate);
    nextDay.setDate(startDate.getDate() + 1);
    setStartDate(nextDay);
    handleDateChange(nextDay);
  };
  return (
    <TableHeader>
      <TableRow margin="60px 0 -10px 635px" width="370px" head>
        <TableDataGroup>
          {selectedValue === '3' ? (
            <TableData>
              <ButtonWrapper onClick={handlePreviousDay}>
                <LeftIcon />
              </ButtonWrapper>
              <InputDateStyle>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    handleDateChange(date);
                  }}
                  dateFormat="yyyy-MM-dd"
                  customInput={<StyledInput />}
                  locale={ko}
                />
              </InputDateStyle>
              <ButtonWrapper onClick={handleNextDay}>
                <RightIcon />
              </ButtonWrapper>
            </TableData>
          ) : (
            <></>
          )}
        </TableDataGroup>
        <TableDataGroup justifyContent="flex-end">
          <select
            name="choice"
            value={selectedValue}
            onChange={(e) => {
              setSelectedValue(e.target.value);
              dispatch(setCurrentQSCPage(1));
            }}
          >
            <option value="1">전체</option>
            <option value="2">결재대기</option>
            <option value="3">날짜별</option>
          </select>
        </TableDataGroup>
      </TableRow>
    </TableHeader>
  );
};

const QSCHeader = () => {
  return (
    <TableHeader>
      <TableRow margin="0px 0 0px -90px" width="1040px" head>
        <TableDataGroup margin="0 0 0 20px" width="320px">
          <TableData width="100%">
            <Text
              style={{textAlign: 'left', width: '100%', padding: '0 0 0 20px'}}
              bold
            >
              매장명
            </Text>
          </TableData>
        </TableDataGroup>
        <TableDataGroup width="120px">
          <TableData width="100%">
            <Text bold>작성자</Text>
          </TableData>
        </TableDataGroup>
        <TableDataGroup width="240px">
          <TableData width="100%">
            <Text bold>작성일시</Text>
          </TableData>
        </TableDataGroup>
        <TableDataGroup width="240px">
          <TableData width="100%">
            <Text bold>결재현황</Text>
          </TableData>
        </TableDataGroup>
        <TableDataGroup width="100px"></TableDataGroup>
      </TableRow>
    </TableHeader>
  );
};

const QSCProcessList = ({
  qscProcessList,
  handleQSCProcessClick,
  handleViewQscDetail,
}) =>
  qscProcessList?.map((_qscProcess) => {
    return (
      <TableRow
        width="1050px"
        margin="10px 0 0 10px"
        onClick={() => handleQSCProcessClick(_qscProcess)}
        key={_qscProcess.qsc_uid}
        height="40px"
        border
      >
        <TableDataGroup width="320px">
          <TableData margin="0 0 0 20px">
            <Text
              style={{
                fontSize: '13px',
              }}
              anchor
            >
              {_qscProcess.store_name.length > 45
                ? `${_qscProcess.store_name.slice(0, 45)}...`
                : _qscProcess.store_name}{' '}
            </Text>
          </TableData>
        </TableDataGroup>

        <TableDataGroup width="120px">
          <TableData width="100%">
            <Text style={{fontSize: '13px'}} anchor>
              {_qscProcess.supervisor}
            </Text>
          </TableData>
        </TableDataGroup>

        <TableDataGroup width="240px">
          <TableData width="100%">
            <Text style={{fontSize: '13px'}} anchor>
              {new Date(_qscProcess.created_on)
                .toLocaleString('ko-KR', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: false,
                })
                .replace(',', '')}
            </Text>
          </TableData>
        </TableDataGroup>

        <TableDataGroup width="240px">
          <TableData width="100%">
            <Text
              style={{fontSize: '13px'}}
              anchor
              color={
                _qscProcess?.owner === null && _qscProcess?.status === null
                  ? theme.colors.greenDark
                  : theme.colors.black
              }
            >
              {/* SUN 3 */}
              {_qscProcess?.owner !== null &&
                _qscProcess?.status !== null &&
                `${_qscProcess?.owner} 및`}
              {_qscProcess?.owner !== null &&
                _qscProcess?.status === null &&
                `${_qscProcess?.owner} 승인 대기`}
              {_qscProcess?.status && ` ${_qscProcess?.status} 승인 대기`}
              {_qscProcess?.owner === null &&
                _qscProcess?.status === null &&
                '승인 완료'}
            </Text>
          </TableData>
        </TableDataGroup>
        <TableDataGroup width="100px">
          <TableData width="100%">
            <Button
              style={{height: '28px', lineHeight: '0px', margin: '0 0 0 30px'}}
              color="#6650c0"
              onClick={(event) => {
                event.stopPropagation();
                handleViewQscDetail(_qscProcess);
              }}
            >
              <QscBtnContent>QSC</QscBtnContent>
            </Button>
          </TableData>
        </TableDataGroup>
      </TableRow>
    );
  });

const QSCTemplate = ({
  handleQSCProcessClick,
  qscProcessList,
  handleNoteCreateBtn,
  handlePaginationChange,
  count,
  handleViewQscDetail,
  selectedValue,
  setSelectedValue,
  handleDateChange,
}) => {
  const why = true;
  return (
    <FranchiseWrapper>
      <FranchiseDrawer why={why}>
        <TableWrapper>
          <QSCSelctor
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            handleDateChange={handleDateChange}
          />
          <QSCHeader />
          <StyledTableContent2>
            {qscProcessList.length === 0 ? (
              <TableRow width="1100px">
                <NoQscDiv>작성된 QSC가 없습니다.</NoQscDiv>
              </TableRow>
            ) : (
              <QSCProcessList
                handleQSCProcessClick={handleQSCProcessClick}
                qscProcessList={qscProcessList}
                handleNoteCreateBtn={handleNoteCreateBtn}
                handleViewQscDetail={handleViewQscDetail}
              />
            )}
          </StyledTableContent2>

          {qscProcessList.length > 0 ? (
            <PaginationContainer>
              <Stack spacing={2}>
                <CustomPagination
                  onChange={handlePaginationChange}
                  count={count()}
                  siblingCount={4}
                  // color="primary"
                />
              </Stack>
            </PaginationContainer>
          ) : (
            <></>
          )}
        </TableWrapper>
      </FranchiseDrawer>
    </FranchiseWrapper>
  );
};

const PaginationContainer = styled.div`
  margin-top: -120px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88%;
`;

export default QSCTemplate;
