import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import api from 'shared/utils/api';

const initialState = {
  positionLists: [],
  selectedPosition: null,
  editPositionModal: false,
  editPositionModalInput: '',
  onlyPositionLists: [],
};

// get franchisePosition list thunk
export const getFranchisePositionsAsync = createAsyncThunk(
  'franchisePosition/getFranchisePositions',
  async (uid) => {
    const response = await api.getFranchisePositions(uid);
    const {results} = response.data;
    return results;
  },
);

export const postFranchisePositionAsync = createAsyncThunk(
  'franchisePosition/postFranchisePositionAsync',
  async (data) => {
    const response = await api.postFranchisePosition(data);
    const {results} = response.data;
    return results;
  },
);
// patch franchisePosition thunk
export const patchFranchisePositionAsync = createAsyncThunk(
  'franchisePosition/patchFranchisePosition',
  async (data) => {
    const response = await api.patchFranchisePosition(data);
    const {results} = response.data;
    return results;
  },
);

export const deleteFranchisePositionAsync = createAsyncThunk(
  'franchisePosition/deleteFranchisePositionAsync',
  async (data) => {
    const response = await api.deleteFranchisePosition(data);
    const {results} = response.data;
    return results;
  },
);

export const getSupervisorPositionListAsync = createAsyncThunk(
  'franchise/user/getSupervisorPositionListAsync',
  async (sv_user_uid) => {
    const response = await api.getSupervisorPositionList(sv_user_uid);

    const {results} = response.data;

    return results;
  },
);

// patch franchisePosition thunk
export const patchSvPositionAsync = createAsyncThunk(
  'franchisePosition/patchFranchisePosition',
  async (data) => {
    const response = await api.patchSvPosition(data);
    const {results} = response.data;
    return results;
  },
);

export const franchisePositionSlice = createSlice({
  name: 'franchisePosition',
  initialState,
  reducers: {
    setSelectedPosition: (state, action) => {
      state.selectedPosition = action.payload;
    },

    setEditPositionModal: (state, action) => {
      state.editPositionModal = action.payload;
    },

    setEditPositionModalInput: (state, action) => {
      state.editPositionModalInput = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getFranchisePositionsAsync.fulfilled, (state, action) => {
      state.positionLists = action.payload;
    });
    builder.addCase(
      getSupervisorPositionListAsync.fulfilled,
      (state, action) => {
        state.onlyPositionLists = action.payload;
      },
    );
  },
});

export const {
  setSelectedPosition,
  setEditPositionModal,
  setEditPositionModalInput,
} = franchisePositionSlice.actions;

export const selectFranchisePosition = (state) => state.franchise.position;

export default franchisePositionSlice.reducer;
