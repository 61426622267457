import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import common_en from '../translations/en/common.json';
import common_ko from '../translations/ko/common.json';

const language = navigator.language.split('-')[0];

i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  fallbackLng: 'en',
  debug: true,
  lng: language,
  resources: {
    en: {
      translation: common_en,
    },
    ko: {
      translation: common_ko,
    },
  },
});
