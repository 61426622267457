/* eslint-disable no-plusplus */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable no-console */
import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {catchError} from 'shared/utils/catchError';

import QSCProcessTemplate from './template';
import {
  deleteNewQSCProcessCommentAsync,
  selectFranchiseQsc,
  setQscSignatures,
} from 'franchise/utils/slices/frQscSlice';
import api from 'shared/utils/api';

const QSCProcess = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {qsc_uid, qscSignatures, loadSignatureModal} =
    useSelector(selectFranchiseQsc);

  const [qscProcessComments, setQscProcessComments] = useState([]);

  const getQSCProcessComments = useMemo(
    () => async () => {
      try {
        const response = await api.getQSCProcessComments({qsc_uid});

        const {results} = response.data;
        setQscProcessComments(results);
      } catch (e) {}
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const getQSCApprovalLineSignImgAsync = useMemo(
    () => async () => {
      try {
        const response = await api.getQSCApprovalLineSignImg({qsc_uid});

        const {results} = response.data;
        dispatch(setQscSignatures(results));
      } catch (e) {}
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    getQSCApprovalLineSignImgAsync();
    getQSCProcessComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadSignatureModal]);

  const onSubmit = async (value, {resetForm}) => {
    const {message} = value;

    if (message.trim() !== '') {
      try {
        const data = {
          qsc_uid: qsc_uid,
          contents: message,
        };

        await catchError(api.postNewQSCProcessComment, data).then(() => {
          getQSCProcessComments();
          resetForm();
        });
      } catch (e) {}
    }
  };

  // Go back to chat room list
  const handleListBtnClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleProcessCommentClick = async (msg) => {
    if (window.confirm('해당 기록을 삭제하시겠습니까?')) {
      dispatch(
        deleteNewQSCProcessCommentAsync({
          approval_comment_uid: msg.approval_comment_uid,
        }),
      )
        .unwrap()
        .then(() => {
          getQSCProcessComments();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <>
      <QSCProcessTemplate
        handleListBtnClick={handleListBtnClick}
        qscProcessComments={qscProcessComments}
        onSubmit={onSubmit}
        qscSignatures={qscSignatures}
        handleProcessCommentClick={handleProcessCommentClick}
      />
    </>
  );
};

export default QSCProcess;
