import React, {useState, useEffect, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate, useLocation} from 'react-router-dom';
import {selectUser} from 'owler/utils/slices/userSlice';
import {selectApp} from 'shared/utils/redux/appSlice';

export default function withAuth(WrappedComponent) {
  function AuthComponent() {
    const {appType} = useSelector(selectApp);
    const userState = useSelector(selectUser);
    const navigate = useNavigate();
    const location = useLocation();
    const [authenticated, setAuthenticated] = useState(false);

    const isLoginPage = useCallback(() => {
      const root = location.pathname;
      return root === '/' || root === '/phoneLogin';
    }, [location]);

    useEffect(() => {
      if (appType === 'owler') {
        const isLogin = isLoginPage();
        const isAuthenticated = userState.currentAuthUser;

        switch (true) {
          case !isLogin && !isAuthenticated:
            alert('admin만 접근이 가능합니다.');
            navigate('/phoneLogin');
            break;
          case !isLogin && isAuthenticated:
            setAuthenticated(true);
            break;
          case isLogin && !isAuthenticated:
            setAuthenticated(true);
            break;
          case isLogin && isAuthenticated:
            setAuthenticated(true);

            navigate('/owler/storeList');

            break;
          default:
            break;
        }
      } else {
        alert('Owler user cannot access franchise');
        navigate('/');
      }
    }, [appType, isLoginPage, navigate, userState]);

    return authenticated && <WrappedComponent />;
  }

  return AuthComponent;
}
