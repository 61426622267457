import React from 'react';
import styled from 'styled-components';

import RightIcon from 'shared/assets/icons/RightIcon';
import LeftIcon from 'shared/assets/icons/LeftIcon';

import DatePicker from '../atoms/DatePicker';

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-right: 0px;
  }
  & > *:last-child {
    margin-right: 0px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const DateArea = ({date, handleDates}) => {
  const {handleBackClick, handleForwardClick, handleDateChange} = handleDates;
  return (
    <Wrapper>
      <ItemWrapper>
        <LeftIcon length={20} onClick={handleBackClick} />
        <DatePicker date={date} handleDateChange={handleDateChange} />
        <RightIcon length={20} onClick={handleForwardClick} />
      </ItemWrapper>
    </Wrapper>
  );
};

export default DateArea;
