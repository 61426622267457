import React from 'react';
import styled from 'styled-components';
import NoticeEditForm from './NoticeEditForm';
import {Text, Wrapper} from 'shared/components/index';

const TitleWrapper = styled.div`
  padding: 100px 0px 0px 0px;
  width: 550px;
  margin: 0 auto;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 60px;
  font-size: 25px;
  font-weight: 600;
`;

const NoticeEditTemplate = ({
  onSubmit,
  onValidate,
  handleBackBtnClick,
  handleContentChange,
}) => {
  return (
    <Wrapper>
      <TitleWrapper>
        <Text fontSize="xxxl">공지사항 수정</Text>
      </TitleWrapper>
      <NoticeEditForm
        onSubmit={onSubmit}
        onValidate={onValidate}
        handleBackBtnClick={handleBackBtnClick}
        handleContentChange={handleContentChange}
      />
    </Wrapper>
  );
};

export default NoticeEditTemplate;
