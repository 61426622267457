import {
  Wrapper,
  Text,
  Drawer,
  WeekNumberSelector,
  YearNumberSelector,
  CustomDatePicker,
} from 'shared/components/index';
import theme from 'shared/styles/theme';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import JobPerformanceTemplate from './JobPerformanceTemplate';
import RealTimePhotoReport from './RealTimePhotoReport';
import EmployeeStatus from './EmployeeStatus';
import StoreReportStatus from './StoreReportStatus';
import RealTimePhotoDetailModal from './RealTimePhotoDetailModal';
import RealTimePhotoImageModal from './RealTimePhotoImageModal';
import ExtraEmployeeModal from './ExtraEmployeeModal';
import PerformanceByStore from './PerformanceByStore';
import HeadDiscription from './HeadDiscription';
import CorporationDashboard from './CorporationDashboard';
import Chatting from 'owler/pages/Chat/index';

const DashContainer = styled.div`
  width: 1180px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -62px;
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
`;

const LeftDashWrapper = styled.div`
  width: 555px;
  min-width: 555px;
  border-right: 1px solid ${theme.colors.grey9};
  border-radius: 15px;
  margin: 0 0px 0 0px;
  padding: 15px;
`;

const LeftDashWrapperBrand = styled.div`
  width: 555px;
  min-width: 555px;
  border-right: 1px solid ${theme.colors.grey9};
  border-radius: 15px;
  margin: 0 0px 0 0px;
  padding: 15px;
`;

const RightDashWrapper = styled.div`
  width: 555px;
  height: 690px;
  min-width: 555px;
`;

const TopWrapper = styled.div`
  width: 100%;
  height: 350px;
`;

const BottomWrapper = styled.div`
  width: 100%;
  height: 420px;
`;

const DashboardTemplate = ({
  currentStore,
  dailyTaskStatus,
  activeTaskStat,
  handleTaskStatClick,
  lastWeekGraphData,
  thisWeekGraphData,
  thisMonthLineGraphData,
  realTimeTaskPictures,
  realTimeBrandTaskPictures,
  dateChangeActions,
  workSchedules,
  currentEmployees,
  storeReviews,
  handleStoreClick,
  handleBrandStoreClick,
  isLoading,
  isDateLoading,
  isStoreLoading,
  isImageLoading,
  handleDateChange,
  selectedDate,
  brandtaskStatusList,
  todaySchedule,
  newRequestList,
  unCheckNotice,
  handleEachStoreBranchClick,
}) => {
  const {
    handleChangeWeekBack,
    handleChangeWeekForward,
    displayDateWeekly,
    clickCount,
    selectedYear,
    handleYearChange,
  } = dateChangeActions;

  return (
    <Wrapper
      titleText={currentStore.store_name}
      overflow="visible"
      handleStoreClick={handleStoreClick}
    >
      <Drawer>
        <DashContainer>
          {currentStore.is_corporation ? (
            <CorporationDashboard
              handleBrandStoreClick={handleBrandStoreClick}
            />
          ) : isLoading ? (
            <Box
              sx={{
                display: 'flex',
                width: '95%',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '200px',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {currentStore?.is_hq ? (
                <LeftDashWrapperBrand>
                  <StyledHeader>
                    <Text fontSize="large" margin="15px 0 20px 20px" bold>
                      매장별 업무 수행 현황
                    </Text>
                  </StyledHeader>

                  <TopWrapper>
                    <PerformanceByStore
                      brandtaskStatusList={brandtaskStatusList}
                      isStoreLoading={isStoreLoading}
                      handleEachStoreBranchClick={handleEachStoreBranchClick}
                    />
                  </TopWrapper>

                  <BottomWrapper>
                    <Text fontSize="large" margin="12px 0 10px 20px" bold>
                      실시간 업무 수행 현황
                    </Text>

                    <RealTimePhotoReport
                      realTimeTaskPictures={realTimeBrandTaskPictures}
                      isImageLoading={isImageLoading}
                    />

                    <RealTimePhotoDetailModal />
                    <RealTimePhotoImageModal />

                    <ExtraEmployeeModal workSchedules={workSchedules} />
                  </BottomWrapper>
                </LeftDashWrapperBrand>
              ) : (
                <LeftDashWrapper>
                  <StyledHeader>
                    <Text fontSize="large" margin="15px 0 20px 20px" bold>
                      업무 수행 현황
                    </Text>

                    {activeTaskStat === 1 && (
                      <div style={{margin: '0px 18px 0 0'}}>
                        <CustomDatePicker
                          handleDateChange={handleDateChange}
                          selectedDate={selectedDate}
                          style={{margin: '10px'}}
                        />
                      </div>
                    )}

                    {activeTaskStat === 2 && (
                      <div style={{margin: '10px 0px 0 0'}}>
                        <WeekNumberSelector
                          handleChangeWeekBack={handleChangeWeekBack}
                          handleChangeWeekForward={handleChangeWeekForward}
                          displayDateWeekly={displayDateWeekly}
                          clickCount={clickCount}
                        />
                      </div>
                    )}

                    {activeTaskStat === 3 && (
                      <div style={{margin: '12px 0px 0 0'}}>
                        <YearNumberSelector
                          selectedYear={selectedYear}
                          handleYearChange={handleYearChange}
                        />
                      </div>
                    )}
                  </StyledHeader>

                  <TopWrapper>
                    <JobPerformanceTemplate
                      isDateLoading={isDateLoading}
                      dailyTaskStatus={dailyTaskStatus}
                      activeTaskStat={activeTaskStat}
                      handleTaskStatClick={handleTaskStatClick}
                      lastWeekGraphData={lastWeekGraphData}
                      thisWeekGraphData={thisWeekGraphData}
                      thisMonthLineGraphData={thisMonthLineGraphData}
                    />
                  </TopWrapper>

                  <BottomWrapper>
                    <Text fontSize="large" margin="12px 0 10px 20px" bold>
                      실시간 업무 수행 현황
                    </Text>

                    <RealTimePhotoReport
                      realTimeTaskPictures={realTimeTaskPictures}
                      isImageLoading={isImageLoading}
                    />

                    <RealTimePhotoDetailModal />
                    <RealTimePhotoImageModal />
                    <ExtraEmployeeModal workSchedules={workSchedules} />
                  </BottomWrapper>
                </LeftDashWrapper>
              )}

              <RightDashWrapper>
                {currentStore?.is_hq ? (
                  <HeadDiscription
                    currentStore={currentStore}
                    todaySchedule={todaySchedule}
                    newRequestList={newRequestList}
                    unCheckNotice={unCheckNotice}
                  />
                ) : (
                  <>
                    <EmployeeStatus workSchedules={workSchedules} />
                    <StoreReportStatus storeReviews={storeReviews} />
                  </>
                )}
              </RightDashWrapper>
            </>
          )}
        </DashContainer>
      </Drawer>
    </Wrapper>
  );
};

export default DashboardTemplate;
