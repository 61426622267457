import styled from "styled-components"
import theme from "shared/styles/theme";
const Container = styled.div`
    border: 3px solid ${theme.colors.kalsPurple};
    width: 85vw;
    min-height: 20vh;
    max-height: 20vh;
    border-radius: 30px;
    background-color:${props => props.list.is_close === false ? '':'#E6E6E6'};
    opacity:${props => props.list.is_close === false ? '1':'0.68'};
    overflow: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    &:hover{
        cursor: ${props => props.list.is_close === false ? 'pointer':'default'};
    }
`

export default Container;