import styled from 'styled-components';
import theme from 'shared/styles/theme';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  postRequestCommentAsync,
  getRequestDetailAsync,
  setCurrentRequest,
  deleteRequestCommentAsync,
  patchRequestCommentAsync,
} from 'owler/utils/slices/requestSlice';
import {selectNotice} from 'owler/utils/slices/noticeSlice';
import {Text} from 'shared/components/index';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';

const Container = styled.div`
  width: 100%;
  height: 650px;
  border-top: 1px solid ${theme.colors.grey9};
`;

const ChattingArea = styled.div`
  width: 100%;
  height: 92%;
  overflow: auto;
  padding: 5px 0;
`;
const InsertArea = styled.div`
  display: flex;
  width: 100%;
  height: 8%;
  background-image: linear-gradient(
    to right,
    ${theme.colors.kalsBlue} 0%,
    ${theme.colors.kalsPurple} 70%
  );
`;
const StyledInput = styled.input`
  width: 80%;
  height: 65%;
  margin-top: 9px;
  margin-left: 5%;
`;
const ForBubble = styled.div``;

const TextZone = styled.div`
  border-bottom: 1px solid ${theme.colors.grey9};
  padding: 0 0 0 5px;
  position: relative;
`;
const formatDate = dateString => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // because getMonth() is 0-indexed
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}`;
};
const Chatting = ({currentRequest, currentRequestUid}) => {
  const [text, setText] = useState('');
  const {member_uid} = useSelector(selectNotice);
  const dispatch = useDispatch();
  const [menuState, setMenuState] = useState({
    anchorEl: null,
    messageId: null,
  });
  const [editingIndex, setEditingIndex] = useState(null);
  const [value, setValue] = useState('');

  const handleOpen = (event, id) => {
    setMenuState({anchorEl: event.currentTarget, messageId: id});
  };

  const handleEdit = message => {
    if (value === '') {
      setEditingIndex(null);
      return;
    }
    if (window.confirm('수정하시겠습니까?')) {
      dispatch(
        patchRequestCommentAsync({
          request_comment_uid: message?.request_comment_uid,
          member_uid: member_uid.member_uid,
          contents: value,
        }),
      )
        .unwrap()
        .then(() => {
          dispatch(
            getRequestDetailAsync({
              request_uid: currentRequestUid,
            }),
          )
            .unwrap()
            .then(payload => {
              dispatch(setCurrentRequest(payload));
            });
        });

      setValue('');
      setEditingIndex(null);
    }
  };

  const handleClose = (action, message, index) => {
    if (action === 'edit') {
      setEditingIndex(index);
    }
    if (action === 'delete') {
      if (window.confirm('정말 삭제하시겠습니까?')) {
        dispatch(
          deleteRequestCommentAsync({
            request_comment_uid: message?.request_comment_uid,
            member_uid: member_uid.member_uid,
          }),
        )
          .unwrap()
          .then(() => {
            dispatch(
              getRequestDetailAsync({
                request_uid: currentRequestUid,
              }),
            )
              .unwrap()
              .then(payload => {
                dispatch(setCurrentRequest(payload));
              });
          });
      }
    }
    setMenuState({anchorEl: null, messageId: null});
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleClick();
    }
  };

  const handleClick = () => {
    dispatch(
      postRequestCommentAsync({
        request_uid: currentRequestUid,
        member_uid: member_uid.member_uid,
        contents: text,
      }),
    )
      .unwrap()
      .then(() => {
        dispatch(
          getRequestDetailAsync({
            request_uid: currentRequestUid,
          }),
        )
          .unwrap()
          .then(payload => {
            dispatch(setCurrentRequest(payload));
          });
      });
    setText('');
  };

  return (
    <Container>
      <ChattingArea>
        <ForBubble>
          {currentRequest?.comments?.map((message, index) => {
            if (message.creator.member_uid === member_uid.member_uid) {
            }
            return (
              <>
                <TextZone key={index}>
                  {message.creator.member_uid === member_uid.member_uid ? (
                    <>
                      {editingIndex !== index ? (
                        <>
                          <Button
                            id="demo-customized-button"
                            onClick={e => handleOpen(e, index)}
                            sx={{
                              color: '#000000',
                              marginTop: '4px',
                              height: '24px',
                              width: '18px',
                              minWidth: '0px',
                              position: 'absolute',
                              left: '295px',
                              top: '-2px',
                            }}
                          >
                            <MoreVertIcon fontSize="medium" />
                          </Button>
                          <Menu
                            id="demo-customized-menu"
                            MenuListProps={{
                              'aria-labelledby': 'demo-customized-button',
                            }}
                            anchorEl={menuState.anchorEl}
                            open={
                              Boolean(menuState.anchorEl) &&
                              menuState.messageId === index
                            }
                            onClose={handleClose}
                          >
                            <MenuItem
                              sx={{width: '85px', fontSize: '15px'}}
                              onClick={() =>
                                handleClose('edit', message, index)
                              }
                              disableRipple
                            >
                              {' '}
                              <EditIcon sx={{fontSize: '18px'}} /> 수정{' '}
                            </MenuItem>
                            <MenuItem
                              sx={{width: '40px', fontSize: '15px'}}
                              onClick={() => handleClose('delete', message)}
                              disableRipple
                            >
                              {' '}
                              <DeleteIcon sx={{fontSize: '18px'}} /> 삭제{' '}
                            </MenuItem>
                          </Menu>
                        </>
                      ) : (
                        <Button
                          id="demo-customized-button"
                          onClick={() => handleEdit(message)}
                          sx={{
                            color: '#000000',
                            marginTop: '4px',
                            height: '24px',
                            width: '18px',
                            minWidth: '0px',
                            position: 'absolute',
                            left: '295px',
                            top: '-2px',
                          }}
                        >
                          <EditIcon fontSize="small" />
                        </Button>
                      )}
                    </>
                  ) : null}
                  <Text style={{fontSize: '15px', margin: '0 0 0 2px'}} bold>
                    {message.creator.name}
                  </Text>
                  <Text
                    style={{
                      fontSize: '11px',
                      margin: '0 0 0 5px',
                      lineHeight: '-20px',
                    }}
                  >
                    {message.creator.branch_name}
                  </Text>

                  <br />
                  {editingIndex !== index ? (
                    <Text
                      style={{
                        fontSize: '14px',
                        margin: '0 0 0px 1px',
                        width: '92%',
                      }}
                    >
                      {message.contents}
                    </Text>
                  ) : (
                    <TextField
                      multiline
                      rows={2}
                      size="small"
                      placeholder={message.contents}
                      style={{width: '270px'}}
                      onChange={e => setValue(e.target.value)}
                    />
                  )}
                  <br />
                  <Text style={{fontSize: '11px', margin: '0 0 0 1px'}}>
                    {formatDate(message.created_on)}
                  </Text>
                </TextZone>
              </>
            );
          })}
        </ForBubble>
      </ChattingArea>
      <InsertArea>
        <StyledInput
          placeholder="댓글을 입력해주세요."
          onChange={e => setText(e.target.value)}
          onKeyPress={handleKeyPress}
          value={text}
        ></StyledInput>
        <Button onClick={handleClick}>
          <SendIcon sx={{color: 'white', fontSize: '30px'}} />
        </Button>
      </InsertArea>
    </Container>
  );
};

export default Chatting;
