import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import i18n from 'i18next';
import api from 'shared/utils/api';

const initialState = {
  scheduleModal: false,
  selectedDate: new Date(),
  events: [],
  eventAddModal: false,
  eventDetailModal: false,
  selectedEvent: null,
  selectedEventDetail: null,
  showFranchiseListModal: false,
  categorys: [],
  categoryAddModal: false,
  categoryModifyModal: false,
  selectedCategory: null,
  selectedSchedule: null,
  currentFrachiseList: [],
  directGroup: [],
  noDirectGroup: [],
  franchiseListModal: false,
  directstoreData: {
    all: false,
    stores: [],
    names: [],
  },
  nodirectstoreData: {
    all: false,
    stores: [],
    names: [],
  },
  noticeLinkAddmodal: false,
  extraNoticeList: [],
  scheduleTimelineArray: [],
};

export const getCalendarScheduleAsync = createAsyncThunk(
  `position/getCalendarScheduleAsync`,
  async (data) => {
    const response = await api.getCalendarSchedule(data);
    const {results} = response.data;

    return results;
  },
);

export const postCalendarScheduleAsync = createAsyncThunk(
  `position/postCalendarScheduleAsync`,
  async (data, {rejectWithValue}) => {
    try {
      const response = await api.postCalendarSchedule(data);
      return response.data;
    } catch (error) {
      const errorResponse = error.response || {};
      const errorData = errorResponse.data || {};
      const message =
        i18n.language === 'ko'
          ? errorData.kr || error.message || '잘못된 접근입니다.'
          : errorData.en || error.message || 'Something went wrong :(';
      const status = errorResponse.status || 500;
      return rejectWithValue({message, status});
    }
  },
);

export const patchCalendarScheduleAsync = createAsyncThunk(
  `position/patchCalendarScheduleAsync`,
  async (data, {rejectWithValue}) => {
    try {
      const response = await api.patchCalendarSchedule(data);
      return response.data;
    } catch (error) {
      const errorResponse = error.response || {};
      const errorData = errorResponse.data || {};
      const message =
        i18n.language === 'ko'
          ? errorData.kr || error.message || '잘못된 접근입니다.'
          : errorData.en || error.message || 'Something went wrong :(';
      const status = errorResponse.status || 500;
      return rejectWithValue({message, status});
    }
  },
);
export const deleteCalendarScheduleAsync = createAsyncThunk(
  `position/deleteCalendarScheduleAsync`,
  async (data, {rejectWithValue}) => {
    try {
      const response = await api.deleteCalendarSchedule(data);
      return response.data;
    } catch (error) {
      const errorResponse = error.response || {};
      const errorData = errorResponse.data || {};
      const message =
        i18n.language === 'ko'
          ? errorData.kr || error.message || '잘못된 접근입니다.'
          : errorData.en || error.message || 'Something went wrong :(';
      const status = errorResponse.status || 500;
      return rejectWithValue({message, status});
    }
  },
);
export const getCalendarAsync = createAsyncThunk(
  `position/getCalendarAsync`,
  async (data) => {
    const response = await api.getCalendar(data);
    const {results} = response.data;

    return results;
  },
);
export const getFranchiseListAsync = createAsyncThunk(
  `position/getFranchiseListAsync`,
  async (data) => {
    const response = await api.getFranchiseList(data);
    const {results} = response.data;

    return results;
  },
);

export const fetchWorkScheduleAsync = createAsyncThunk(
  'workSchedule/fetchWorkSchedule',
  async (param, {rejectWithValue}) => {
    try {
      const response = await api.getWorkSchedule(param);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  },
);

export const createWorkScheduleAsync = createAsyncThunk(
  'workSchedule/createWorkSchedule',
  async (data, {rejectWithValue}) => {
    try {
      const response = await api.createWorkSchedule(data);
      return response.data;
    } catch (error) {
      const errorResponse = error.response || {};
      const errorData = errorResponse.data || {};
      const message =
        i18n.language === 'ko'
          ? errorData.kr || error.message || '잘못된 접근입니다.'
          : errorData.en || error.message || 'Something went wrong :(';
      const status = errorResponse.status || 500;
      return rejectWithValue({message, status});
    }
  },
);

export const deleteWorkScheduleAsync = createAsyncThunk(
  'workSchedule/deleteWorkSchedule',
  async (data, {rejectWithValue}) => {
    try {
      const response = await api.deleteWorkSchedule(data);
      return response.data;
    } catch (error) {
      const errorResponse = error.response || {};
      const errorData = errorResponse.data || {};
      const message =
        i18n.language === 'ko'
          ? errorData.kr || error.message || '잘못된 접근입니다.'
          : errorData.en || error.message || 'Something went wrong :(';
      const status = errorResponse.status || 500;
      return rejectWithValue({message, status});
    }
  },
);

export const fetchWorkScheduleCalendar = createAsyncThunk(
  'workSchedule/fetchWorkScheduleCalendar',
  async (param, {rejectWithValue}) => {
    try {
      const response = await api.getWorkScheduleCalendar(param);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  },
);

export const fetchScheduleList = createAsyncThunk(
  'workSchedule/fetchScheduleList',
  async (param, {rejectWithValue}) => {
    try {
      const response = await api.getScheduleList(param);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  },
);

export const fetchScheduleTimeline = createAsyncThunk(
  'workSchedule/fetchScheduleTimeline',
  async (param, {rejectWithValue}) => {
    try {
      const response = await api.getScheduleTimeline(param);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  },
);

export const fetchTimeset = createAsyncThunk(
  'workSchedule/fetchTimeset',
  async (param, {rejectWithValue}) => {
    try {
      const response = await api.getTimeset(param);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  },
);

export const createTimesetAsync = createAsyncThunk(
  'workSchedule/createTimeset',
  async (data, {rejectWithValue}) => {
    try {
      const response = await api.createTimeset(data);
      return response.data;
    } catch (error) {
      const errorResponse = error.response || {};
      const errorData = errorResponse.data || {};
      const message =
        i18n.language === 'ko'
          ? errorData.kr || error.message || '잘못된 접근입니다.'
          : errorData.en || error.message || 'Something went wrong :(';
      const status = errorResponse.status || 500;
      return rejectWithValue({message, status});
    }
  },
);

export const deleteTimesetAsync = createAsyncThunk(
  'workSchedule/deleteTimeset',
  async (data, {rejectWithValue}) => {
    try {
      const response = await api.deleteTimeset(data);
      return response.data;
    } catch (error) {
      const errorResponse = error.response || {};
      const errorData = errorResponse.data || {};
      const message =
        i18n.language === 'ko'
          ? errorData.kr || error.message || '잘못된 접근입니다.'
          : errorData.en || error.message || 'Something went wrong :(';
      const status = errorResponse.status || 500;
      return rejectWithValue({message, status});
    }
  },
);

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setScheduleModal: (state, action) => {
      state.scheduleModal = action.payload;
    },
    setSelectedDate2: (state, action) => {
      state.selectedDate = action.payload;
    },
    setEvents: (state, action) => {
      state.events = action.payload;
    },
    setEventAddModal: (state, action) => {
      state.eventAddModal = action.payload;
    },
    setEventDetailModal: (state, action) => {
      state.eventDetailModal = action.payload;
    },
    setSelectedEvent: (state, action) => {
      state.selectedEvent = action.payload;
    },
    setSelectedEventDetail: (state, action) => {
      state.selectedEventDetail = action.payload;
    },
    setShowFranchiseListModal: (state, action) => {
      state.showFranchiseListModal = action.payload;
    },

    addEvents: (state, action) => {
      state.events.push(action.payload);
    },
    setCategorys: (state, action) => {
      state.categorys = action.payload;
    },
    addCategorys: (state, action) => {
      state.categorys.push(action.payload);
    },
    setCategoryAddModal: (state, action) => {
      state.categoryAddModal = action.payload;
    },
    setCategoryModifyModal: (state, action) => {
      state.categoryModifyModal = action.payload;
    },
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    modifyCategorys: (state, action) => {
      const {category_uid, category_name, color} = action.payload;
      const eventIndex = state.categorys.findIndex(
        (event) => event.category_uid === category_uid,
      );

      if (eventIndex > -1) {
        state.categorys[eventIndex].category_name = category_name;
        state.categorys[eventIndex].color = color;
      }
    },
    moveEvent: (state, action) => {
      const {title, start, end} = action.payload;
      const eventIndex = state.events.findIndex(
        (event) => event.title === title,
      );
      if (eventIndex > -1) {
        state.events[eventIndex].start = start;
        state.events[eventIndex].end = end;
      }
    },

    setCurrentFrachiseList: (state, action) => {
      state.currentFrachiseList = action.payload;
    },
    setFranchiseListModal: (state, action) => {
      state.franchiseListModal = action.payload;
    },
    setdirectStoreData: (state, action) => {
      state.directstoreData = action.payload;
      if (
        state.directstoreData.stores.length === state.directGroup.length &&
        state.directGroup.length !== 0
      ) {
        state.directstoreData.all = true;
      } else {
        state.directstoreData.all = false;
      }
    },
    setnoDirectStoreData: (state, action) => {
      state.nodirectstoreData = action.payload;
      if (
        state.nodirectstoreData.stores.length === state.noDirectGroup.length &&
        state.noDirectGroup.length !== 0
      ) {
        state.nodirectstoreData.all = true;
      } else {
        state.nodirectstoreData.all = false;
      }
    },
    setDirectGroup: (state, action) => {
      state.directGroup = action.payload;
    },
    setNoDirectGroup: (state, action) => {
      state.noDirectGroup = action.payload;
    },
    setNoticeLinkAddmodal: (state, action) => {
      state.noticeLinkAddmodal = action.payload;
    },
    setExtraNoticeList: (state, action) => {
      if (action.payload === null) {
        state.extraNoticeList = [];
      } else {
        if (
          !state?.extraNoticeList?.some(
            (item) => item.notice_uid === action?.payload?.notice_uid,
          )
        ) {
          state.extraNoticeList.push(action.payload);
        } else {
          alert('이미 등록된 공지입니다.');
        }
      }
    },
    deleteExtraNoticeList: (state, action) => {
      if (action.payload.notice_uid) {
        state.extraNoticeList = state.extraNoticeList.filter(
          (item) => item.notice_uid !== action.payload.notice_uid,
        );
      }
    },

    setSelectedSchedule: (state, action) => {
      state.selectedSchedule = action.payload;
    },
    resetCalendarSlice: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(fetchScheduleTimeline.fulfilled, (state, action) => {
      state.scheduleTimelineArray = action.payload?.results;
    });
  },
});

export const {
  setScheduleModal,
  setSelectedDate2,
  setEvents,
  resetCalendarSlice,
  setEventAddModal,
  setEventDetailModal,
  addEvents,
  setSelectedEvent,
  setCategorys,
  addCategorys,
  setCategoryAddModal,
  moveEvent,
  setCategoryModifyModal,
  setSelectedCategory,
  modifyCategorys,
  setCurrentFrachiseList,
  setFranchiseListModal,
  setdirectStoreData,
  setnoDirectStoreData,
  setDirectGroup,
  setNoDirectGroup,
  setNoticeLinkAddmodal,
  setExtraNoticeList,
  deleteExtraNoticeList,
  setSelectedEventDetail,
  setShowFranchiseListModal,
  setSelectedSchedule,
} = calendarSlice.actions;
export const selectCalendar = (state) => state.owler.calendar;

export default calendarSlice.reducer;
