import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  appType: null,
};

// Setting up app slice (redux-toolkit)
export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppType: (state, action) => {
      state.appType = action.payload;
    },
  },
});

export const {setAppType} = appSlice.actions;

export const selectApp = state => state.app;

export default appSlice.reducer;
