/* eslint-disable no-restricted-globals */
import React, {useCallback, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {
  selectRequest,
  getRequestGroupAsync,
  getRequestStatusAsync,
  setGroupList,
  setStatusList,
} from 'owler/utils/slices/requestSlice';
import RequestDetailTemplate from './template/index';

const RequestDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {currentRequest, currentRequestUid, statusList, groupList} =
    useSelector(selectRequest);

  const {t} = useTranslation();

  useEffect(() => {
    if (currentRequest) {
      dispatch(
        getRequestGroupAsync({
          request_uid: currentRequestUid,
        }),
      )
        .unwrap()
        .then(payload => {
          dispatch(setGroupList(payload));
        })
        .catch(err => alert(err));

      dispatch(
        getRequestStatusAsync({
          request_uid: currentRequestUid,
        }),
      )
        .unwrap()
        .then(payload => {
          dispatch(setStatusList(payload));
        })
        .catch(err => alert(err));
    }
  }, [currentRequest]);

  const handleListBtnClick = useCallback(async () => {
    navigate(-1);
  }, [navigate]);

  return (
    <>
      <RequestDetailTemplate
        currentRequest={currentRequest}
        handleListBtnClick={handleListBtnClick}
        statusList={statusList}
        groupList={groupList}
        currentRequestUid={currentRequestUid}
      />
    </>
  );
};

export default RequestDetail;
