const getCookies = () => {
  const decodedCookie = decodeURIComponent(document.cookie);
  const token = decodedCookie.split(';')[0].slice(6);
  return token;
};

const setCookies = (val, exp = 1000) => {
  const d = new Date();
  // Create a cookie
  document.cookie = 'token=; Max-Age=0';
  d.setTime(d.getTime() + exp * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()};`;
  // Set a cookie
  document.cookie = `token=${val.toString()};${expires}`;
};

function deleteAllCookies() {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i += 1) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos).trim() : cookie.trim();
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
  }
}

export {setCookies, getCookies, deleteAllCookies};
