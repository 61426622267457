import React from 'react';
import styled from 'styled-components';
import {
  TableData,
  TableDataGroup,
  TableHeader,
  TableRow,
  TableWrapper,
  Text,
} from 'shared/components/index';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import theme from 'shared/styles/theme';
import StoreListHeader from './StoreListHeader';
import StoreIcon from '@mui/icons-material/Store';
import BusinessIcon from '@mui/icons-material/Business';

const Wrapper = styled.div`
  overflow: ${(props) => props.overflow};
  max-width: 1320px;
  margin: 0 auto;
  padding: 0 20px;
  min-height: 100%;
  & > *:last-child {
    margin-bottom: 0;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  min-width: 1280px;
  margin-top: 60px;
  height: auto;
`;
const TableContentStore = styled.div`
  border-radius: 5px;
  /* border-color: ${({theme}) => theme.colors.gray_3}; */
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // align-items: center;
  // padding: 0 45px;
  min-height: 60px;
  margin-bottom: 20px;
`;
const StoreText = styled(Text)`
  width: 240px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  &:hover {
    transform: scale(1.008);
  }
`;
const SearchStore = styled.input`
  width: 250px;
  font-size: 18px;
  padding: 0 8px;
  border: none;
  border: 2px solid ${theme.colors.grey9};
  border-radius: 5px;
`;

const IsCorp = styled.div`
  width: 70px;
  height: 25px;
  color: white;
  background-color: ${theme.colors.kalsPurple};
  position: relative;
  top: -37px;
  left: -290px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;
const StoreHeader = ({name, handledNameChange, title, isCorp}) => {
  return (
    <TableHeader type={1}>
      <TableRow head>
        <TableDataGroup width="950px">
          <TableData>
            <Text margin="0 0 10px -30px" style={{fontSize: '20px'}} bold>
              {title}
            </Text>
          </TableData>
        </TableDataGroup>

        <TableDataGroup justifyContent="space-between">
          {!(isCorp === false) && (
            <SearchStore
              value={name}
              onChange={(e) => handledNameChange(e)}
              placeholder={`${title} 검색`}
            />
          )}
        </TableDataGroup>
      </TableRow>
    </TableHeader>
  );
};
const StoreList = ({storeList, handleStoreClick, debouncedName}) => {
  const filteredStores = storeList.filter((store) => {
    if (debouncedName.length > 0) {
      return store.store_name
        .toLowerCase()
        .includes(debouncedName.toLowerCase());
    }
    return true;
  });

  const noBrandList = filteredStores.filter(
    (each) => each.is_hq === false && each.is_corporation === false,
  );
  if (noBrandList.length === 0) {
    return (
      <Text margin="0 0 0 30px" style={{width: '100%'}}>
        해당 이름의 매장이 없습니다.
      </Text>
    );
  }

  return noBrandList.map((store, index) => (
    <TableRow
      onClick={() => handleStoreClick(store)}
      key={index.toString()}
      width="295px"
      border
      margin="20px 12px 0"
      height="55px"
    >
      <TableDataGroup>
        <TableData>
          <StoreIcon />
          <StoreText margin="0 0 0 5px">{store.store_name}</StoreText>
        </TableData>
      </TableDataGroup>
    </TableRow>
  ));
};
const CorpStoreList = ({storeList, handleStoreClick, debouncedName}) => {
  const filteredStores = storeList.filter((store) => {
    if (debouncedName.length > 0) {
      return store.store_name
        .toLowerCase()
        .includes(debouncedName.toLowerCase());
    }
    return true;
  });

  const sortedStores = filteredStores
    .filter((each) => each.is_hq === true || each.is_corporation === true)
    .sort((a, b) => {
      if (a.is_corporation === b.is_corporation) {
        return 0;
      }
      return a.is_corporation ? -1 : 1;
    });

  const CorpList = storeList.filter(
    (each) => each.is_hq === true || each.is_corporation === true,
  );

  if (sortedStores.length === 0 && CorpList.length > 0) {
    return (
      <Text margin="0 0 0 30px" style={{width: '100%'}}>
        해당 이름의 브랜드가 없습니다.
      </Text>
    );
  } else if (CorpList.length === 0) {
    return (
      <>
        <Text
          margin="0 0 0 30px"
          fontSize="large"
          color="#6E6E6E"
          style={{width: '100%'}}
        >
          프랜차이즈 등록이 되어있지 않습니다.
        </Text>
      </>
    );
  }

  return sortedStores.map((store, index) => {
    if (true === store.is_corporation)
      return (
        <TableRow
          onClick={() => handleStoreClick(store)}
          key={index.toString()}
          width="295px"
          border
          margin="20px 12px 0"
          height="55px"
          style={{border: `4px solid ${theme.colors.kalsPurple}`}}
        >
          <TableDataGroup>
            <TableData>
              <BusinessIcon />
              <StoreText margin="0 0 0 5px" style={{textAlign: 'left'}}>
                {store.store_name}
              </StoreText>
              <IsCorp>본사</IsCorp>
            </TableData>
          </TableDataGroup>
        </TableRow>
      );
    else {
      return (
        <TableRow
          onClick={() => handleStoreClick(store)}
          key={index.toString()}
          width="295px"
          border
          margin="20px 12px 0"
          height="55px"
        >
          <TableDataGroup>
            <TableData>
              <BusinessIcon />
              <StoreText margin="0 0 0 5px" style={{textAlign: 'left'}}>
                {store.store_name}
              </StoreText>
            </TableData>
          </TableDataGroup>
        </TableRow>
      );
    }
  });
};
const StoreTemplate = ({
  isLoading,
  storeList,
  handleStoreClick,
  name,
  handledNameChange,
  debouncedName,
  corpName,
  debouncedCorpName,
  handledCorpNameChange,
}) => {
  const CorpList = storeList?.filter(
    (each) => each.is_hq === true || each.is_corporation === true,
  );

  return (
    <Wrapper>
      <StoreListHeader />
      <ContentWrapper>
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '200px',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <TableWrapper>
            <TableContentStore>
              <StoreHeader
                title="브랜드"
                name={corpName}
                handledNameChange={handledCorpNameChange}
                storeList={storeList}
                isCorp={CorpList.length > 0 ? true : false}
              />
              <CorpStoreList
                handleStoreClick={handleStoreClick}
                storeList={storeList}
                debouncedName={debouncedCorpName}
              />
            </TableContentStore>
            <TableContentStore>
              <StoreHeader
                title="매장명"
                name={name}
                handledNameChange={handledNameChange}
              />
              <StoreList
                handleStoreClick={handleStoreClick}
                storeList={storeList}
                debouncedName={debouncedName}
              />
            </TableContentStore>
          </TableWrapper>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default StoreTemplate;
