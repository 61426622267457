import React from 'react';
import {styled} from '@mui/system';
import Button from '@mui/material/Button';

// Custom styles for the button
const StyledButton = styled(Button)`
  && {
    font-size: ${({fontSize}) => fontSize};
    background-color: ${({backgroundcolor}) => backgroundcolor};
    color: ${({textcolor}) => textcolor};
  }
`;

const MuiButton = ({
  fontSize,
  onClick,
  backgroundColor,
  textColor,
  variant,
  text,
  endIcon,
  ...restProps
}) => {
  return (
    <StyledButton
      onClick={onClick}
      variant={variant}
      fontSize={fontSize}
      backgroundcolor={backgroundColor}
      textcolor={textColor}
      endIcon={endIcon}
      {...restProps}
    >
      {text}
    </StyledButton>
  );
};

export default MuiButton;
