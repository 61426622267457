import DatePicker from 'react-datepicker';
import {ko} from 'date-fns/esm/locale';
import InputDateStyle from 'shared/styles/datePickerStyle';
import styled from 'styled-components';
import {Text, Button} from '../index';
import theme from 'shared/styles/theme';

const DateStyle = styled.div`
  .react-datepicker__input-container {
    input {
      width: 120px;
      text-align: center;
      border-radius: 5px;
      border: 1px solid ${theme.colors.grey3};
      line-height: 23px;
    }
  }
`;

const CustomDatePicker2 = ({
  methods,
  setLastChangedDate,
  search,
  start,
  end,
  formatDate,
}) => {
  const onSubmit = (data) => {
    !(data.startDate && data.endDate)
      ? alert('날짜를 입력해주세요!')
      : search(data);
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <InputDateStyle>
        <DateStyle>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <Text fontSize="medium" margin="0 15px 0 0" bold>
              조회 기간
            </Text>
            <DatePicker
              locale={ko}
              dateFormat="yyyy-MM-dd"
              placeholderText={formatDate(start)}
              selected={methods?.watch('startDate')}
              onChange={(date) => {
                methods?.setValue('startDate', date);
                setLastChangedDate('startDate');
              }}
            />
            &nbsp;~&nbsp;
            <DatePicker
              locale={ko}
              dateFormat="yyyy-MM-dd"
              placeholderText={formatDate(end)}
              selected={methods?.watch('endDate')}
              onChange={(date) => {
                methods?.setValue('endDate', date);
                setLastChangedDate('endDate');
              }}
            />
            <Button type="submit" size="xs" margin="20px">
              조회
            </Button>
          </div>
        </DateStyle>
      </InputDateStyle>
    </form>
  );
};

export default CustomDatePicker2;
