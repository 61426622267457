import styled from 'styled-components';

const State = styled.div`
  padding: 0 1.2vw;
  background-color: ${(props) =>
    props.list.state !== '마감' ? '#9d86fd' : '#FE2E2E'};
  color: #ffffff;
  font-size: 15px;
  border-radius: 10px;
  line-height: 25px;
`;
export default State;
