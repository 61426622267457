/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, {useEffect, useState} from 'react';
import {MuiButton, PortalModal} from 'shared/components/index';
import styled, {css} from 'styled-components';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import {useSelector} from 'react-redux';
import {selectNotice} from 'owler/utils/slices/noticeSlice';
import AddStoresModal from './AddStoreModal';

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: ${({container}) => (container ? 'row' : 'column')};
  &:not(:last-child) {
    margin-right: 30px;
  }
  width: ${({width}) => width || '100%'};
  ${({center}) =>
    center &&
    css`
      margin: 0 auto;
    `}
`;

const TitleText = styled.div`
  font-size: 18px;
  color: #252525;
  margin-bottom: 10px;
`;

const FlexBox = styled.div`
  display: flex;
`;

const Field = ({
  formikProps,
  label,
  name,
  placeholder,
  classes,
  ...restProps
}) => {
  return (
    <>
      <Typography gutterBottom>{label}</Typography>
      <TextField
        {...restProps}
        disabled
        name={name}
        value={formikProps.values[name]}
        onChange={() => {}}
        error={formikProps.touched[name] && Boolean(formikProps.errors[name])}
        helperText={
          (formikProps.touched[name] && formikProps.errors[name]) || ' '
        }
        size="small"
        variant="filled"
        fullWidth
        placeholder={placeholder}
        className={classes && classes.text}
        InputProps={
          classes && {
            classes: {
              root: classes.text,
              input: classes.textInput,
              multiline: classes.multi,
            },
          }
        }
      />
    </>
  );
};

const AddStores = ({formprops}) => {
  const {directStoreData, noDirectStoreData} = useSelector(selectNotice);
  const [placeholder, setPlaceholder] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const text1 = directStoreData?.names?.join('  /  ');
    const text2 = noDirectStoreData?.names?.join('  /  ');
    setPlaceholder(text1 + '  /  ' + text2);
  }, [directStoreData?.names, noDirectStoreData?.names]);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div>
      <FlexBox>
        <FieldWrapper width="80%">
          <TitleText>적용 매장</TitleText>
          <Field
            formikProps={formprops}
            id="stores"
            name="stores-here"
            placeholder={
              directStoreData?.names?.length === 0 &&
              noDirectStoreData?.names?.length === 0
                ? '선택된 매장이 없습니다.'
                : placeholder
            }
          />
        </FieldWrapper>
        <FieldWrapper width="20%" center style={{marginTop: '47px'}}>
          <MuiButton
            id="noticeAdd-goBack"
            text="매장찾기"
            onClick={toggleModal}
            textColor="black"
            backgroundColor="#f2f2f2"
            style={{
              border: '1px solid black',
              fontSize: '15px',
              borderRadius: '10px',
            }}
          />
        </FieldWrapper>
      </FlexBox>

      {showModal && (
        <PortalModal>
          <AddStoresModal toggleModal={toggleModal} />
        </PortalModal>
      )}
    </div>
  );
};

export default AddStores;
