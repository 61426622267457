import {Text, FranchiseDrawer, FranchiseWrapper} from 'shared/components/index';

const CalendarTemplate = () => {
  return (
    <FranchiseWrapper titleText="Calendar">
      <FranchiseDrawer>
        <Text>Hello Calendar!</Text>
      </FranchiseDrawer>
    </FranchiseWrapper>
  );
};

export default CalendarTemplate;
