import React,{ useState,useEffect }  from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import { 
    selectPosition,
    setPositionAddModalOpen,
} from 'owler/utils/slices/positionSlice';
import {MuiButton, MuiModal, Text} from 'shared/components/index';
import theme from 'shared/styles/theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 40px;
  align-items: center;
  overflow: hidden;
  & > * {
    margin-bottom: 30px;
  }
  & > :last-child {
    margin-bottom: 0px;
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  justify-content: flex-start;
`;

const ButtonSeparator = styled.div`
  margin: 0 10px 0 10px;
`;

const ButtonContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const SelectWrapper = styled.div`
  width: 100%;
  display: flex;
`
const StyledInput = styled.input`
  width: 180px;
  //margin-right: 40px;
`
const StyledSelect = styled.select`
  width: 100px;
  margin-left: 15px;
`
const PositionAddModal = ({handleAddBtn}) => {
  const {t} = useTranslation();
  const {positionAddModalOpen} = useSelector(selectPosition);
  const dispatch = useDispatch();
  const [name,setNewName] = useState('')
  const [level,setNewLevel]= useState(0)

  const handleModalClose = () => {
    dispatch(setPositionAddModalOpen(false));
  };

  const handleModifyBtn = () => {
    handleAddBtn(name,level);
  };

  return (
    <MuiModal open={positionAddModalOpen} onClose={handleModalClose}>
      <Container>
        <TitleWrapper>
          <Text bold fontSize="xl" margin='0 100px 0 5px'>
            직급
          </Text>
          <Text bold fontSize="xl">
            권한
          </Text>
        </TitleWrapper>
        <SelectWrapper>
          <StyledInput 
            placeholder="직급명을 입력하세요."
            onChange={(e) => setNewName(e.target.value)}
          />
          <StyledSelect name="choice" value={level} onChange={(e) => setNewLevel(e.target.value)}>
            <option value="0">사장</option>
            <option value="1">매니저</option>
            <option value="2">정직원</option>
            <option value="3">아르바이트</option>
          </StyledSelect>
        </SelectWrapper>

        <ButtonContainer>
          <ButtonSeparator>
            <MuiButton
              onClick={handleModifyBtn}
              variant="contained"
              backgroundColor={theme.colors.kalsBlue}
              text={t('buttons.attach')}
            />
          </ButtonSeparator>

          <MuiButton
            onClick={handleModalClose}
            variant="contained"
            backgroundColor={theme.colors.grey}
            text={t('buttons.cancel')}
          />
        </ButtonContainer>
      </Container>
    </MuiModal>
  );
};
export default PositionAddModal;
