/* eslint-disable react/prop-types */
import * as React from 'react';
import TextField from '@mui/material/TextField';
import {styled} from '@mui/material/styles';

import InputAdornment from '@mui/material/InputAdornment';
import theme from 'shared/styles/theme';

const StyledTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: theme.colors.primary,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.colors.primary,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.colors.mediumLight,
      borderRadius: 10,
      borderWidth: 0.5,
    },
    '&:hover fieldset': {
      borderColor: theme.colors.primary,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.colors.primary,
    },
  },
});

export default function AppTextInput({
  color = 'secondary',
  backIcon = '',
  label = '',
  labelColor = theme.colors.lavender,
  value,
  onFocus,
  onChange,
  formWidth = '100%',
  ...otherProps
}) {
  return (
    <StyledTextField
      color={color}
      label={label}
      size="small"
      InputLabelProps={{
        style: {color: labelColor},
      }}
      sx={{
        width: formWidth,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">{backIcon}</InputAdornment>
        ),
      }}
      variant="outlined"
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      {...otherProps}
    />
  );
}
