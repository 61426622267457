import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import api from '../../../shared/utils/api';

const initialState = {
  selectedMarker: null,
  alls: null,
  eventList: [],
  eventDetail: [],
  mapBounds: null,
};

export const getStoresInMapAsync = createAsyncThunk(
  'map/getStoresInMapAsync',
  async (data) => {
    const response = await api.getStoresInMap(data);

    return response.data;
  },
);
export const getStoresInSearchAsync = createAsyncThunk(
  'map/getStoresInSearchAsync',
  async (data) => {
    const response = await api.getStoresInSearch(data);

    return response.data;
  },
);

export const getEventListAsync = createAsyncThunk(
  'map/getEventListAsync',
  async (data) => {
    const response = await api.getEventList(data);

    return response.data;
  },
);
export const getEventDetailAsync = createAsyncThunk(
  'map/getEventDetailAsync',
  async (data) => {
    const response = await api.getEventDetail(data);

    return response.data;
  },
);

export const postReviewAsync = createAsyncThunk(
  'map/postReviewAsync',
  async (data) => {
    const response = await api.postReview(data);
    const {result} = response.data;

    return result;
  },
);

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setSelectedMarker: (state, action) => {
      state.selectedMarker = action.payload;
    },
    setAlls: (state, action) => {
      state.alls = action.payload;
    },
    setEventList: (state, action) => {
      state.eventList = action.payload;
    },
    setEventDetail: (state, action) => {
      state.eventDetail = action.payload;
    },
    setMapBounds: (state, action) => {
      state.mapBounds = action.payload;
    },
    resetTaskSlice: () => initialState,
  },
});

export const {
  setSelectedMarker,
  setAlls,
  setEventList,
  setEventDetail,
  setMapBounds,
} = mapSlice.actions;

export const selectMap = (state) => state.map;

export default mapSlice.reducer;
