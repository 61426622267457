import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledBox = styled.div`
  border: 1px solid;
  border-radius: 5px;
  border-color: ${({ theme }) => theme.colors.gray_light};
`;
const Box = ({ text }) => <StyledBox>{text}</StyledBox>;
Box.propTypes = {
  text: PropTypes.string.isRequired,
};
export default Box;
