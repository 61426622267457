import React from 'react';
import styled, {css} from 'styled-components';
import theme from '../../../styles/theme';

const Row = styled.div`
  border: ${({border}) => (border ? '1px solid #d5d5d5' : 'none')};
  border-radius: 10px;

  margin: ${({margin}) => margin || '20px 0 0 0'};
  padding: ${({head}) => (head ? '5px 20px' : '5px 20px')};
  width: ${({width}) => width || '1280px'};
  display: flex;
  color: ${({head}) => (head ? '#252525' : '#252525')};
  height: ${({height}) => (height ? height : '')};
  background-color: ${({bgColor}) => bgColor};
  &:hover {
    ${({onClick}) =>
      onClick &&
      css`
        & > * {
          cursor: pointer;
        }
        & a {
          font-weight: bold;
          text-decoration: underline;
        }
        border: ${({border}) => (border ? '1px solid #d5d5d5' : 'none')};
        background-color: ${theme.colors.purple_light};
      `}
  }
`;

const TableRow = ({
  children,
  border,
  head,
  margin,
  width,
  bgColor,
  onClick,
  height,
  ...restProps
}) => (
  <Row
    head={head}
    border={border}
    margin={margin}
    width={width}
    bgColor={bgColor}
    onClick={onClick}
    height={height}
    {...restProps}
  >
    {children}
  </Row>
);

export default TableRow;
