import React from 'react';
import styled from 'styled-components';

import theme from 'shared/styles/theme';
import {Text} from 'shared/components/index';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {useNavigate} from 'react-router-dom';
import StoreQscNoteModal from './StoreQscNoteModal';
import {useDispatch, useSelector} from 'react-redux';
import {selectFranchiseUser} from 'franchise/utils/slices/frUserSlice';
import {setOpenModal} from 'franchise/utils/slices/frQscSlice';
const LeftDashWrapper = styled.div`
  width: 100%;
  border-bottom: 2px solid ${theme.colors.grey9};
  padding: 0 10px 0 20px;
  height: 420px;
`;

const ButtonWrap = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 20px 0px;
  border-bottom: 1px solid ${theme.colors.grey9};
  // border-radius: 10px;

  padding-left: 10px;
  &:hover {
    cursor: pointer;
  }
`;

const IconButtonWrap = styled.button`
  width: 50px;
  height: 50px;
  background: none;
  margin: 0 0px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50%;
  padding: 0;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${theme.colors.secondaryLight};
  }
`;

const TopFranchiseActions = ({svQSCStatus, handleNoteCreateBtn}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {svUserInfo} = useSelector(selectFranchiseUser);

  const handleSignWaitQscCount = () => {
    navigate(`/franchise/qsc?selected=2`);
  };
  const handleCompleteWaitQscCount = () => {
    navigate(`/franchise/qsc`);
  };
  const handleWritingQscCount = () => {
    dispatch(setOpenModal(true));
  };
  const handleInChargeStoreCount = () => {
    navigate(`/franchise/qscStoreList`);
  };
  return (
    <LeftDashWrapper>
      <Text fontSize="large" margin="20px 0 20px 6px" bold>
        관리 현황
      </Text>
      <div style={{display: 'flex'}}>
        <div
          style={{
            border: '1px solid #bdbdbd',
            width: '40%',
            margin: '25px 30px 0 10px',
            height: '275px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '40px 0',
            borderRadius: '20px',
          }}
        >
          <img
            style={{
              width: '80px',
              border: `2px solid #bdbdbd`,
              height: '80px',
              borderRadius: '50%',
            }}
            src="/empty_user.png"
          />
          <Text style={{fontSize: '18px', margin: '40px 0 20px 0'}} bold>
            {svUserInfo?.user_name}
          </Text>
          <Text>{svUserInfo?.position_name}</Text>
        </div>
        <div style={{display: 'flex', flexDirection: 'column', width: '50%'}}>
          <ButtonWrap onClick={handleSignWaitQscCount}>
            <Text style={{fontSize: '15px'}} margin="0 0 0 0">
              결재대기
            </Text>
            <Text />
            <Text margin="0 10px 0 0">
              {svQSCStatus?.sign_wait_qsc_count}/{svQSCStatus?.whole_qsc_count}
            </Text>
            <IconButtonWrap onClick={handleSignWaitQscCount}>
              <ChevronRightIcon />
            </IconButtonWrap>
          </ButtonWrap>

          {/* Button 2 */}
          <ButtonWrap onClick={handleCompleteWaitQscCount}>
            <Text style={{fontSize: '15px'}} margin="0 0 0 0">
              완료대기
            </Text>
            <Text />
            <Text margin="0 10px 0 0">
              {svQSCStatus?.complete_wait_qsc_count}/
              {svQSCStatus?.whole_qsc_count}
            </Text>
            <IconButtonWrap onClick={handleCompleteWaitQscCount}>
              <ChevronRightIcon />
            </IconButtonWrap>
          </ButtonWrap>

          {/* Button 3 */}
          <ButtonWrap onClick={handleWritingQscCount}>
            <Text style={{fontSize: '15px'}} margin="0 0 0 0">
              작성 중
            </Text>
            <Text />
            <Text margin="0 10px 0 0">{svQSCStatus?.writing_qsc_count}</Text>
            <IconButtonWrap onClick={handleWritingQscCount}>
              <ChevronRightIcon />
            </IconButtonWrap>
          </ButtonWrap>

          {/* Button 4 */}
          <ButtonWrap onClick={handleInChargeStoreCount}>
            <Text style={{fontSize: '15px'}} margin="0 0 0 0">
              담당 매장 수
            </Text>
            <Text />
            <Text margin="0 10px 0 0">
              {svQSCStatus?.in_charge_store_count}
            </Text>
            <IconButtonWrap onClick={handleInChargeStoreCount}>
              <ChevronRightIcon />
            </IconButtonWrap>
          </ButtonWrap>
        </div>
      </div>

      <StoreQscNoteModal handleNoteCreateBtn={handleNoteCreateBtn} />
    </LeftDashWrapper>
  );
};

export default TopFranchiseActions;
