/* eslint-disable dot-notation */
/* eslint-disable no-constant-condition */
/* eslint-disable react/prop-types */
/* eslint-disable no-lone-blocks */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
import React from 'react';
import {Formik} from 'formik';
import styled from 'styled-components';

import {makeStyles} from '@mui/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Fab from '@mui/material/Fab';
import SendIcon from '@mui/icons-material/Send';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {Divider} from '@mui/material';

import LoadSignaturesModal from './LoadSignaturesModal';
import {
  Button,
  FranchiseWrapper,
  TableData,
  TableDataGroup,
  Text,
} from 'shared/components/index';
import {
  selectFranchiseQsc,
  setLoadSignatureQSC,
} from 'franchise/utils/slices/frQscSlice';
import {useDispatch, useSelector} from 'react-redux';
import PlusCircleSolidIcon from 'shared/assets/icons/PlusCircleSolidIcon';
import theme from 'shared/styles/theme';

const Container = styled.div`
  margin-top: 90px;
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin-right: 5px;
  white-space: nowrap;
`;

const ButtonEmptyContent = styled.div`
  display: done;
  width: 100px;
  margin-right: 5px;
`;

const Title = styled.div`
  width: 1280px;
  background-color: #fff;
  position: fixed;
  margin: 0 auto;
  margin-top: 100px;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px;
  padding-top: 50px;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const useStyles = makeStyles({
  creator: {
    display: 'flex',
    flexDirection: 'column',
  },

  creatorField: {
    color: '#000000',
    fontWeight: 'bold',
    fontSize: '13px',
    margin: '0 0 3px 0',
  },

  table: {
    minWidth: 650,
  },

  chatSection: {
    width: '100%',
    height: '90vh',
    marginTop: '10px',
    boxShadow: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  headBG: {
    backgroundColor: '#e0e0e0',
  },

  signatureSection: {
    height: '100vh',

    borderRadius: '10px',
  },

  messageArea: {
    height: '62vh',
    overflowY: 'auto',
  },

  commentsSection: {
    borderRadius: '10px',
    height: '100vh',
  },

  chatMessage: {
    border: '1px solid #e0e0e0',
    borderRadius: '10px',
    padding: '10px',
  },
});

const QSCProcessTemplate = (props) => {
  const dispatch = useDispatch();
  const {qsc_uid, qscProcess} = useSelector(selectFranchiseQsc);
  const classes = useStyles();
  const {
    handleListBtnClick,
    qscProcessComments,
    onSubmit,
    qscSignatures,
    handleProcessCommentClick,
  } = props;

  return (
    <>
      <FranchiseWrapper
        overflow="hidden"
        infoText={`${qscProcess.store_name} ${qscProcess.position}`}
      >
        <Container>
          <div
            style={{
              marginTop: '150px',
              width: '1200px',
              margin: '15px ',
            }}
          >
            <Grid container>
              <TableDataGroup justifyContent="space-between">
                <TableData>
                  <Button
                    size="sm"
                    color="#6650c0"
                    onClick={handleListBtnClick}
                  >
                    <ButtonContent>
                      <ChevronLeftIcon />
                      이전 화면
                    </ButtonContent>
                  </Button>
                </TableData>

                <TableData>
                  <ButtonEmptyContent />
                </TableData>
              </TableDataGroup>
            </Grid>

            <Grid
              container
              spacing={0}
              component={Paper}
              className={classes.chatSection}
            >
              {/* SUN 4 */}
              <Grid item xs={5} className={classes.signatureSection}>
                <div
                  style={{
                    width: '100%',
                    border: '1px solid #d5d5d5',
                    borderRadius: '10px',
                    padding: '10px 10px 15px 20px',
                    margin: '0 0 30px 0',
                  }}
                >
                  <TableDataGroup
                    style={{marginTop: '10px'}}
                    justifyContent="flex-start"
                  >
                    <Text
                      style={{
                        width: '100%',
                        textAlign: 'left',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                      fontSize="large"
                      bold
                    >
                      매장명
                    </Text>
                  </TableDataGroup>

                  <TableDataGroup
                    style={{marginTop: '10px'}}
                    justifyContent="flex-start"
                  >
                    <Text
                      style={{
                        width: '100%',
                        textAlign: 'left',
                        // color: '#bdbdbd',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                      fontSize="base"
                    >
                      {`작성자: ${qscProcess.supervisor}`}
                    </Text>
                  </TableDataGroup>

                  <TableDataGroup
                    style={{marginTop: '10px'}}
                    justifyContent="flex-start"
                  >
                    <Text
                      style={{
                        width: '100%',
                        textAlign: 'left',
                        fontSize: '15px',
                      }}
                    >
                      작성일시
                    </Text>
                  </TableDataGroup>

                  <TableDataGroup
                    style={{marginTop: '10px'}}
                    justifyContent="flex-start"
                  >
                    <Text
                      style={{
                        width: '100%',
                        textAlign: 'left',
                      }}
                      fontSize="base"
                    >
                      보관기관
                    </Text>
                  </TableDataGroup>

                  <TableDataGroup
                    style={{marginTop: '10px'}}
                    justifyContent="flex-start"
                  >
                    <Text
                      style={{width: '100%', textAlign: 'left'}}
                      fontSize="base"
                    >
                      파기 예정일
                    </Text>
                  </TableDataGroup>

                  <TableDataGroup
                    style={{marginTop: '10px'}}
                    justifyContent="flex-start"
                  >
                    <Text
                      style={{width: '100%', textAlign: 'left'}}
                      fontSize="base"
                    ></Text>
                  </TableDataGroup>

                  <TableDataGroup
                    style={{marginTop: '10px'}}
                    justifyContent="flex-start"
                  >
                    <Text
                      style={{width: '100%', textAlign: 'left'}}
                      fontSize="base"
                    >
                      현재 상태
                    </Text>
                  </TableDataGroup>
                </div>

                <List>
                  <TableDataGroup justifyContent="center">
                    <p
                      style={{
                        fontSize: '18px',
                        fontWeight: 'bold',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        textAlign: 'left',
                        margin: '20px 0 10px 20px',
                      }}
                    >
                      결재 현황
                    </p>
                  </TableDataGroup>
                </List>

                <Divider />

                <Grid
                  sx={{flexGrow: 1}}
                  style={{
                    marginTop: '15px',
                  }}
                  container
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <Grid container justifyContent="center" spacing={3}>
                      {qscSignatures?.map((line, indx) => (
                        <Grid
                          key={indx.toString()}
                          item
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <div
                            style={{
                              height: '70px',
                              width: '70px',
                              border: '1px solid #e0e0e0',
                              borderRadius: 10,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            {line?.signature === null ? (
                              <PlusCircleSolidIcon length={30} fill="#000" />
                            ) : (
                              <img
                                style={{width: 60, height: 60}}
                                src={line?.signature}
                                alt="signature"
                              />
                            )}
                          </div>

                          <p
                            style={{
                              marginTop: 4,
                              fontSize: '13px',
                              fontWeight: '500',
                            }}
                          >
                            {line?.position}
                          </p>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>

                <TableDataGroup
                  style={{bottom: 0, marginTop: '70px'}}
                  justifyContent="center"
                >
                  <Button
                    size="xl"
                    color="#6650c0"
                    onClick={() => dispatch(setLoadSignatureQSC(qsc_uid))}
                  >
                    서명하기
                  </Button>
                </TableDataGroup>
              </Grid>

              <Grid item xs={6} className={classes.commentsSection}>
                <TableDataGroup justifyContent="center">
                  {qscProcessComments?.length === 0 ? (
                    <p style={{marginTop: '20px'}}>
                      아직 메시지가 존재하지 않습니다.
                    </p>
                  ) : (
                    <p
                      style={{
                        marginTop: '20px',
                        fontSize: '18px',
                        fontWeight: 'bold',
                        width: '100%',
                        textAlign: 'left',
                        padding: '0 0 10px 20px',
                        borderBottom: '1px solid #bdbdbd',
                      }}
                    >
                      기록
                    </p>
                  )}
                </TableDataGroup>

                <List className={classes.messageArea}>
                  {qscProcessComments?.map((msg, indx) => {
                    let dateTime = new Date(msg?.created_on);
                    let formattedTime =
                      `${dateTime.getFullYear()}-${String(
                        dateTime.getMonth() + 1,
                      ).padStart(2, '0')}-${String(dateTime.getDate()).padStart(
                        2,
                        '0',
                      )} ` +
                      `${String(dateTime.getHours()).padStart(2, '0')}:${String(
                        dateTime.getMinutes(),
                      ).padStart(2, '0')}:${String(
                        dateTime.getSeconds(),
                      ).padStart(2, '0')}`;

                    return (
                      <ListItemButton
                        key={indx.toString()}
                        onClick={() => handleProcessCommentClick(msg)}
                      >
                        <Grid container>
                          <Grid item xs={12}>
                            <ListItemText
                              className={`${classes.chatMessage} ${
                                true ? 'sender' : 'receiver'
                              }`}
                              primary={
                                <div className={classes.creator}>
                                  <span
                                    className={classes.creatorField}
                                  >{`${msg?.creator} ${msg?.position}`}</span>
                                  <span>{msg?.contents}</span>
                                </div>
                              }
                              secondary={formattedTime}
                              style={{margin: '0 0 0 0'}}
                            />
                          </Grid>
                        </Grid>
                      </ListItemButton>
                    );
                  })}
                </List>

                <Divider />

                <Formik onSubmit={onSubmit} initialValues={{message: ''}}>
                  {(formikProps) => (
                    <Form
                      encType="multipart/form-data"
                      onBlur={formikProps.handleBlur}
                      onSubmit={formikProps.handleSubmit}
                    >
                      <Grid
                        container
                        style={{padding: '20px', borderRadius: '10px'}}
                      >
                        <Grid item xs={11}>
                          <TextField
                            id="outlined-basic-email"
                            label=""
                            fullWidth
                            name="message"
                            value={formikProps.values['message']}
                            onChange={formikProps.handleChange}
                            error={
                              formikProps.touched['message'] &&
                              Boolean(formikProps.errors['message'])
                            }
                            helperText={
                              (formikProps.touched['message'] &&
                                formikProps.errors['message']) ||
                              ' '
                            }
                            InputProps={
                              classes && {
                                classes: {
                                  root: classes.text,
                                  input: classes.textInput,
                                  multiline: classes.multi,
                                },
                              }
                            }
                          />
                        </Grid>

                        <Grid item xs={1} align="right">
                          <Fab
                            id="message-submit"
                            type="submit"
                            style={{
                              backgroundColor: theme.colors.kalsPurple,
                              color: 'white',
                              height: '40px',
                              width: '40px',
                              margin: '5px 0 0 25px',
                            }}
                            aria-label="add"
                            className={classes.sendButton}
                          >
                            <SendIcon />
                          </Fab>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </div>
        </Container>

        <LoadSignaturesModal />
      </FranchiseWrapper>
    </>
  );
};

const Form = styled.form``;

export default QSCProcessTemplate;
