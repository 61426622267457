import React, {useState, useEffect, useMemo} from 'react';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import theme from 'shared/styles/theme';
import {Text} from 'shared/components/index';
import Slider from 'react-slick';
import {selectStore} from 'owler/utils/slices/storeSlice';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Container = styled.div`
  width: 1070px;
  border: 2px solid ${theme.colors.grey9};
  border-radius: 20px;
  .slick-dots li {
    background-color: white;
  }
`;

const StoreListWrapper = styled.div`
  width: 100%;
  height: 515px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  align-content: flex-start;
`;

const StoreWrapper = styled.div`
  width: 315px;
  height: 150px;
  max-height: 150px;
  margin: 10px 20px;
  box-shadow: 0px 2px 2px 2px #bdbdbd;
  border-radius: 20px;
  &:hover {
    cursor: pointer;
    transform: scale(1.01);
  }
`;
const BrandImg = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 0 0 25px;
  //border: 1px solid black;
  background-color: white;
  object-fit: cover;
`;
const Upper = styled.div`
  display: flex;
  border-bottom: 2px solid ${theme.colors.grey9};
  height: 50%;
  align-items: center;
  width: 100%;
  white-space: normal;
  word-wrap: break-word;
  word-break: keep-all;
`;
const Under = styled.div`
  display: flex;
  height: 50%;
  width: 100%;
  align-items: center;
`;
const TextArea = styled.div`
  width: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const CorporationDashboard = ({handleBrandStoreClick}) => {
  const {storeBrandList} = useSelector(selectStore);
  const [list, setList] = useState([]);

  const settings = {
    dots: true,
    infinite: true,
    //speed:500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    // 슬라이드 변경 시간
    speed: 1500,
    lazyLoad: 'ondemand',
    autoplaySpeed: 5000,
  };

  useEffect(() => {
    const forList = [];
    for (let i = 0; i <= 6; i++) {
      storeBrandList.map((store) => {
        forList.push(store);
      });
    }
    setList(forList);
  }, [storeBrandList]);

  const groupedList = useMemo(() => {
    const groups = [];
    for (let i = 0; i < storeBrandList.length; i += 9) {
      groups.push(storeBrandList.slice(i, i + 9));
    }
    return groups;
  }, [storeBrandList]);

  return (
    <Container>
      <Slider {...settings}>
        {groupedList.map((group) => {
          return (
            <div>
              <StoreListWrapper>
                {group.map((store) => {
                  return (
                    <StoreWrapper
                      key={store.brand_store_uid}
                      onClick={() => {
                        handleBrandStoreClick(store);
                      }}
                    >
                      <Upper>
                        <BrandImg src={store.logo} />
                        <Text bold margin="0 0 0 20px">
                          {store.store_name}
                        </Text>
                      </Upper>
                      <Under>
                        <TextArea>
                          <Text bold>신규 건의 요청</Text>
                          <Text
                            color={
                              store.new_request > 0
                                ? theme.colors.kalsPurple
                                : '#BDBDBD'
                            }
                          >
                            {store.new_request} 건
                          </Text>
                        </TextArea>
                        <TextArea>
                          <Text bold>오늘 일정</Text>
                          <Text
                            color={
                              store.schedule > 0
                                ? theme.colors.kalsPurple
                                : '#BDBDBD'
                            }
                          >
                            {store.schedule} 건
                          </Text>
                        </TextArea>
                      </Under>
                    </StoreWrapper>
                  );
                })}
              </StoreListWrapper>
            </div>
          );
        })}
      </Slider>
    </Container>
  );
};

export default CorporationDashboard;
