import React, {useCallback} from 'react';
import theme from 'shared/styles/theme';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import LogoutIcon from '@mui/icons-material/Logout';
import {useTranslation} from 'react-i18next';

import {Logo, MuiButton} from 'shared/components/index';
import NewLogoCircle from 'shared/assets/icons/NewLogoCircle';
import useLogout from 'shared/utils/hooks/useLogout';

const StyledDrawer = styled.div`
  height: 75px;
  width: 100%;
  min-width: 1280px;

  margin: 0 auto;
  box-shadow: 0px 1px 2px #00000016;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  z-index: 9999;
`;

const ContentWrapper = styled.div`
  width: 1200px;
  display: flex;
  align-items: center;
`;

const LogoWrapper = styled.div`
  margin-right: 20px;
`;

const List = styled.ul`
  display: flex;
  margin-left: 40px;
  flex: 1;
`;

const StoreListHeader = () => {
  const navigate = useNavigate();
  const {logUserOut} = useLogout();
  const {t} = useTranslation();

  const handleLogoClick = useCallback(() => {
    navigate('/owler/storeList');
  }, [navigate]);

  const handleUserClickLogout = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('정말 로그아웃하시겠습니까?')) {
      logUserOut();
    }
  };

  return (
    <StyledDrawer>
      <ContentWrapper>
        <LogoWrapper onClick={handleLogoClick}>
          <Logo size="lg" src={<NewLogoCircle length={58} />} />
        </LogoWrapper>

        <List />

        <MuiButton
          fontSize={theme.fontSizes.body}
          onClick={handleUserClickLogout}
          backgroundColor="#A4A4A4"
          textColor="#f8f8f8"
          variant="contained"
          text={t('buttons.logout')}
          endIcon={<LogoutIcon />}
          style={{height: '30px'}}
        />
      </ContentWrapper>
    </StyledDrawer>
  );
};

export default StoreListHeader;
