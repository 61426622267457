import PropTypes from 'prop-types';
import styled from 'styled-components';
import StyledDatePicker from 'react-datepicker';

import '../../styles/datePicker.css';

const StyledInput = styled.input`
  border: none;
  box-sizing: content-box;
  font-size: 30px;
  text-align: center;
  width: fit-content;
  display: inline-block;
  width: 180px;
  cursor: pointer;
`;

const DatePicker = ({date, handleDateChange}) => (
  <div style={{width: '180px', textAlign: 'center'}}>
    <StyledDatePicker
      selected={date}
      onChange={handleDateChange}
      dateFormat="yyyy-MM-dd"
      customInput={<StyledInput />}
    />
  </div>
);
DatePicker.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  handleDateChange: PropTypes.func.isRequired,
};
export default DatePicker;
