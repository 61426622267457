/* eslint-disable no-restricted-globals */
import React, {useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {deleteNoticeAsync, selectNotice} from 'owler/utils/slices/noticeSlice';
import NoticeDetailTemplate from './template/index';

const NoticeDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {currentNotice, member_uid, selectedNotice} = useSelector(selectNotice);
  const {t} = useTranslation();
  const [noticeImageModal, setNoticeImageModal] = useState(false);

  const handleEditBtnClick = useCallback(
    (_notice) => {
      if (selectedNotice.from_web !== false) {
        navigate(`/owler/notices/noticeEdit/${_notice.notice_uid}`, {
          state: {
            title: _notice?.title,
            created_on: _notice?.created_on,
            creator: _notice?.creator,
            notice_type: _notice?.notice_type,
            files: _notice?.files,
            urls: _notice?.urls,
            notice_uid: _notice?.notice_uid,
          },
        });
      } else {
        alert('모바일에서 작성된 공지는 모바일에서만 수정이 가능합니다.');
      }
    },
    [navigate, selectedNotice],
  );

  const handleDeleteBtnClick = useCallback(
    async (_notice) => {
      if (!confirm('정말로 삭제하시겠습니까?')) return;
      const form = new FormData();
      form.append('notice_uid', _notice.notice_uid);
      form.append('member_uid', member_uid?.member_uid);
      dispatch(
        deleteNoticeAsync(form),
        // deleteNoticeAsync({
        //   notice_uid: _notice.notice_uid,
        // }),
      )
        .unwrap()
        .then(() => {
          alert(t('alerts.delete_notice_success'));
          navigate(-1);
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.kr) {
            alert(err.response.data.kr);
          } else {
            alert('본사에서 보낸 공지는 삭제할 수 없습니다'); // 기본 에러 메시지
          }
        });
    },
    [dispatch, member_uid?.member_uid, navigate, t],
  );

  const handleListBtnClick = useCallback(async () => {
    navigate(-1);
  }, [navigate]);

  return (
    <>
      <NoticeDetailTemplate
        currentNotice={currentNotice}
        handleEditBtnClick={handleEditBtnClick}
        handleDeleteBtnClick={handleDeleteBtnClick}
        handleListBtnClick={handleListBtnClick}
        noticeImageModal={noticeImageModal}
        setNoticeImageModal={setNoticeImageModal}
      />
    </>
  );
};

export default NoticeDetail;
