import React from 'react';
import {useSelector} from 'react-redux';
import {PDFViewer} from '@react-pdf/renderer';

import QscToPdfDocument from './template/QscToPdfDocument';
import {selectFranchiseQsc} from 'franchise/utils/slices/frQscSlice';

const DetailViewPdf = () => {
  const {qscExportInfo} = useSelector(selectFranchiseQsc);

  const pdfContent = (
    <PDFViewer style={{width: '100%', height: '100vh'}}>
      <QscToPdfDocument qscExportInfo={qscExportInfo} />
    </PDFViewer>
  );

  return pdfContent;
};

export default DetailViewPdf;
