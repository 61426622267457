import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import QSCStoreListTemplate from './template/index';

import {selectFranchiseUser} from 'franchise/utils/slices/frUserSlice';
import {
  getSupervisorStoresAsync,
  selectFranchiseStore,
} from 'franchise/utils/slices/frStoreSlice';
import {useCallback} from 'react';

const QscStoreList = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {supervisorData} = useSelector(selectFranchiseUser);
  const {supervisorStores, franchiseName} = useSelector(selectFranchiseStore);
  const [loading, setLoading] = useState(false);

  // Handle fetching user stores
  useEffect(() => {
    setLoading(true);

    if (supervisorData !== null) {
      dispatch(getSupervisorStoresAsync(supervisorData))
        .unwrap()
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          alert(t('alerts.failed_to_load'), error.message);
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supervisorData, dispatch]);

  // Handle currently clicked store
  const handleQSCStoreClick = useCallback(
    async (_store) => {
      const {franchise_store_uid} = _store;
      navigate(`/franchise/qscStore/${franchise_store_uid}`, {
        state: {
          franchise_store_uid,
          currentStore: _store,
        },
      });
    },
    [navigate],
  );

  return (
    <QSCStoreListTemplate
      loading={loading}
      handleQSCStoreClick={handleQSCStoreClick}
      qscStoreList={supervisorStores}
      franchiseName={franchiseName}
    />
  );
};

export default QscStoreList;
