/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  FranchiseWrapper,
  TableContent,
  TableData,
  TableDataGroup,
  TableRow,
  TableWrapper,
  Text,
  Button,
} from 'shared/components/index';
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const Container = styled.div`
  margin-top: 90px;
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin-right: 5px;
  white-space: nowrap;
`;

const ButtonEmptyContent = styled.div`
  display: done;
  width: 100px;
  margin-right: 5px;
`;

const QSCHistoryList = ({qscHistoryList, handleViewQscDetail}) =>
  qscHistoryList.map((_qscProcess, indx) => {
    return (
      <TableRow
        key={indx.toString()}
        onClick={() => handleViewQscDetail(_qscProcess)}
        border
      >
        <TableDataGroup>
          <TableData>
            <Text anchor>{`날짜: ${new Date(_qscProcess.created_on)
              .toLocaleString('ko-KR', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
              })
              .replace(',', '')}`}</Text>
          </TableData>
        </TableDataGroup>

        <TableDataGroup>
          <TableData>
            <Text anchor>{`평점: ${_qscProcess.score}`}</Text>
          </TableData>
        </TableDataGroup>
      </TableRow>
    );
  });

const QSCHistoryTemplate = ({
  qscHistoryList,
  handleListBtnClick,
  handleViewQscDetail,
}) => (
  <FranchiseWrapper buttonText="button">
    <Container>
      <div
        style={{
          marginTop: '150px',
          width: '1200px',
          margin: '15px ',
        }}
      >
        <TableWrapper margin="90px">
          <TableContent>
            <Grid container>
              <TableDataGroup justifyContent="space-between">
                <TableData>
                  <Button
                    size="sm"
                    color="#6650c0"
                    onClick={handleListBtnClick}
                  >
                    <ButtonContent>
                      <ChevronLeftIcon />
                      이전 화면
                    </ButtonContent>
                  </Button>
                </TableData>

                <TableData>
                  <ButtonEmptyContent />
                </TableData>
              </TableDataGroup>
            </Grid>

            <QSCHistoryList
              qscHistoryList={qscHistoryList}
              handleViewQscDetail={handleViewQscDetail}
            />
          </TableContent>
        </TableWrapper>
      </div>
    </Container>
  </FranchiseWrapper>
);

export default QSCHistoryTemplate;
