import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from 'shared/utils/api';

const initialState = {
  requestList: [],
  currentRequest: null,
  currentRequestUid: null,
  statusList: [],
  groupList: [],
  currentRequestPage: 1,
  requestPageCount: null,

  imageModal: false,
};

export const getRequestListAsync = createAsyncThunk(
  `position/getRequestListAsync`,
  async (data) => {
    const response = await api.getRequestList(data);
    const {results} = response.data;

    return results;
  },
);
export const getRequestBrandListAsync = createAsyncThunk(
  `position/getRequestBrandListAsync`,
  async (data) => {
    const response = await api.getRequestBrandList(data);
    const {results} = response.data;

    return results;
  },
);

export const getRequestDetailAsync = createAsyncThunk(
  `position/getRequestDetailAsync`,
  async (data) => {
    const response = await api.getRequestDetail(data);
    const {results} = response.data;

    return results;
  },
);
export const getRequestStatusAsync = createAsyncThunk(
  `position/getRequestStatusAsync`,
  async (data) => {
    const response = await api.getRequestStatus(data);
    const {results} = response.data;

    return results;
  },
);
export const patchRequestStatusAsync = createAsyncThunk(
  `position/patchRequestStatusAsync`,
  async (data) => {
    const response = await api.patchRequestStatus(data);
    const {results} = response.data;

    return results;
  },
);
export const getRequestGroupAsync = createAsyncThunk(
  `position/getRequestGroupAsync`,
  async (data) => {
    const response = await api.getRequestGroup(data);
    const {results} = response.data;

    return results;
  },
);
export const patchRequestGroupAsync = createAsyncThunk(
  `position/patchRequestGroupAsync`,
  async (data) => {
    const response = await api.patchRequestGroup(data);
    const {results} = response.data;

    return results;
  },
);
export const postRequestCommentAsync = createAsyncThunk(
  `position/postRequestCommentAsync`,
  async (data) => {
    const response = await api.postRequestComment(data);
    const {results} = response.data;

    return results;
  },
);
export const patchRequestCommentAsync = createAsyncThunk(
  `position/patchRequestCommentAsync`,
  async (data) => {
    const response = await api.patchRequestComment(data);
    const {results} = response.data;

    return results;
  },
);
export const deleteRequestCommentAsync = createAsyncThunk(
  `position/deleteRequestCommentAsync`,
  async (data) => {
    const response = await api.deleteRequestComment(data);
    const {results} = response.data;

    return results;
  },
);
export const postRequestPinAsync = createAsyncThunk(
  `position/postRequestPinAsync`,
  async (data) => {
    const response = await api.postRequestPin(data);
    const {results} = response.data;

    return results;
  },
);
export const deleteRequestPinAsync = createAsyncThunk(
  `position/deleteRequestPinAsync`,
  async (data) => {
    const response = await api.deleteRequestPin(data);
    const {results} = response.data;

    return results;
  },
);

export const requestSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    setRequestList: (state, action) => {
      state.requestList = action.payload;
    },
    setCurrentRequestUid: (state, action) => {
      state.currentRequestUid = action.payload;
    },
    setCurrentRequest: (state, action) => {
      state.currentRequest = action.payload;
    },
    setStatusList: (state, action) => {
      state.statusList = action.payload;
    },
    setGroupList: (state, action) => {
      state.groupList = action.payload;
    },
    setRequestPageCount: (state, action) => {
      state.requestPageCount = action.payload;
    },
    setCurrentRequestPage: (state, action) => {
      state.currentRequestPage = action.payload;
    },
    setImageModal: (state, action) => {
      state.imageModal = action.payload;
    },
    resetRequestSlice: () => initialState,
  },
});
export const {
  setRequestList,
  setImageModal,
  setStatusList,
  setGroupList,
  setCurrentRequest,
  setRequestPageCount,
  setCurrentRequestPage,
  setCurrentRequestUid,
} = requestSlice.actions;
export const selectRequest = (state) => state.owler.request;

export default requestSlice.reducer;
