import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import i18n from 'i18next';

import api from '../../../shared/utils/api';
import {setCookies} from '../../../shared/utils/cookie';

const initialState = {
  currentAuthUser: false,
  userPhoneNumber: null,
  userFirebaseUID: null,
  userInfo: null,
  unreadMessagesCount: 0,
  unreadNotificationsCount: 0,
};

// web login user thunk
export const webPhoneLoginAsync = createAsyncThunk(
  'user/webPhoneLogin',
  async (data, {rejectWithValue}) => {
    try {
      const response = await api.webPhoneLogin(data);

      const {results} = response.data;

      //const {token,userInfo,commuProfile} = result;
      const {
        access: token,
        sv_user_uid: commuProfile,
        user_uid: userInfo,
      } = results;

      // Set the access and refresh tokens to your cookie
      setCookies(token, 60);

      return {userInfo, commuProfile};
    } catch (error) {
      const errorResponse = error.response || {};
      const data = errorResponse.data || {};
      const message =
        i18n.language === 'ko'
          ? data.KR || error.message || '잘못된 접근입니다.'
          : data.EN || error.message || 'Something went wrong :(';
      return rejectWithValue(message);
    }
  },
);

// post email and password
export const postEmailPasswordAsync = createAsyncThunk(
  'user/postEmailLogin',
  async (data, {rejectWithValue}) => {
    try {
      const response = await api.webEmailLogin(data);

      const {results} = response.data;

      const {
        access: token,
        sv_user_uid: commuProfile,
        user_uid: userInfo,
      } = results;
      // Set the access and refresh tokens to your cookie
      setCookies(token, 60);

      return {userInfo, commuProfile};
    } catch (error) {
      const errorResponse = error.response || {};
      const data = errorResponse.data || {};
      const message =
        i18n.language === 'ko'
          ? data.kr || error.message || '잘못된 접근입니다.'
          : data.en || error.message || 'Something went wrong :(';
      return rejectWithValue(message);
    }
  },
);

// get user applied stores thunk
export const getAppliedWorkplacesAsync = createAsyncThunk(
  'store/getAppliedWorkplaces',
  async (uid) => {
    try {
      const response = await api.getAppliedWorkplaces(uid);

      const {results} = response.data;

      return results;
    } catch (error) {
      console.error('Error getting user applied stores: ', error);
    }
  },
);

// Get user's email address thunk
export const getUserEmailAddressAsync = createAsyncThunk(
  'user/getUserEmailAddress',
  async (param, {rejectWithValue}) => {
    try {
      const response = await api.getUserEmailAddress(param);

      const {results} = response.data;

      return results;
    } catch (error) {
      const errorResponse = error.response || {};
      const data = errorResponse.data || {};
      const message =
        i18n.language === 'ko'
          ? data.KR || error.message || '잘못된 접근입니다.'
          : data.EN || error.message || 'Something went wrong :(';
      return rejectWithValue(message);
    }
  },
);

// GET :: Unread chat total count
export const getUnreadMessagesCountAsync = createAsyncThunk(
  'task/getUnreadMessagesCount',
  async (param) => {
    const response = await api.getUnreadMessagesCount(param);
    const {results} = response.data;

    return results;
  },
);

// GET :: Unread notification count
export const getUnreadNotificationsCountAsync = createAsyncThunk(
  'task/getUnreadNotificationsCount',
  async (param) => {
    const response = await api.getUnreadNotificationsCount(param);
    const {results} = response.data;

    return results;
  },
);

// Send phone validation code thunk
export const sendPhoneValidationCodeAsync = createAsyncThunk(
  'user/sendPhoneValidationCode',
  async (data, {rejectWithValue}) => {
    try {
      const response = await api.sendPhoneValidationCode(data);
      return response.data;
    } catch (error) {
      const errorResponse = error.response || {};
      const errorData = errorResponse.data || {};
      const message =
        i18n.language === 'ko'
          ? errorData.kr || error.message || '잘못된 접근입니다.'
          : errorData.en || error.message || 'Something went wrong :(';
      return rejectWithValue(message);
    }
  },
);

// Validation code for password check
export const validatePhoneSMSCodeAsync = createAsyncThunk(
  'user/validatePhoneSMSCode',
  async (data, {rejectWithValue}) => {
    try {
      const response = await api.validatePhoneSMSCode(data);
      return response.data;
    } catch (error) {
      const errorResponse = error.response || {};
      const errorData = errorResponse.data || {};
      const message =
        i18n.language === 'ko'
          ? errorData.kr || error.message || '잘못된 접근입니다.'
          : errorData.en || error.message || 'Something went wrong :(';
      return rejectWithValue(message);
    }
  },
);

// Setting up user slice (redux-toolkit)
// All the magic happens here, lol.
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserPhoneNumber: (state, action) => {
      state.userPhoneNumber = action.payload;
    },

    setUserFirebaseUID: (state, action) => {
      state.userFirebaseUID = action.payload;
    },

    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },

    setCurrentAuthUser: (state, action) => {
      state.currentAuthUser = action.payload;
    },

    resetUserSlice: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(
      getUnreadNotificationsCountAsync.fulfilled,
      (state, action) => {
        state.unreadNotificationsCount = action.payload;
      },
    );
  },
});

export const {
  setUserPhoneNumber,
  setUserFirebaseUID,
  setUserInfo,
  resetUserSlice,
  setCurrentAuthUser,
} = userSlice.actions;

export const selectUser = (state) => state.owler.user;

export default userSlice.reducer;
