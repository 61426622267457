/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/require-default-props */
import React from 'react';
import {MuiModal} from 'shared/components/index';
import styled from 'styled-components';

const Container = styled.div`
  width: 600px;
  max-height: 650px;
  min-height: 500px;
  border-radius: 5px;
  background-color: #fff;
  padding: 5px 25px 5px 25px;
  overflow-y: scroll;
`;

const StyledText = styled.p`
  font-size: ${(props) => (props.size ? props.size : '14px')};
  font-weight: ${(props) => (props.weight ? props.weight : '400')};
  color: ${(props) => (props.color ? props.color : 'black')};
  margin: 12px 0 5px 0;
`;

const ImgWrapper = styled.div`
  width: 100%;
  height: 260px;
  margin: 15.5px 0;
  overflow-x: scroll;
`;

const StyledImg = styled.img`
  margin-right: 10px;
  height: auto;
  max-height: 300px; // Adjust as needed
`;

const ButtonContainer = styled.div`
  width: 100%;
  height: auto;
  text-align: center;
`;

const StyledButton = styled.button`
  font-size: 12px;
  padding: 6px 30px;
  color: #fff;
  background-color: #8773d6;
  border: none;
  border-radius: 20px;
  margin: 5px auto;
  cursor: pointer;
`;

const StyledSpan = styled.span`
  font-size: 16px;
  font-weight: bold;
`;
const ImageContainer = styled.div`
  overflow: scroll;
`;

const MoreImageModal = ({feedback, open, handleClose, storeFeedback}) => {
  return (
    <MuiModal open={open} onClose={handleClose}>
      <Container>
        <StyledText weight="bold" size="20px">
          사진공지 확인
        </StyledText>
        {feedback &&
          feedback.feedback.map((data, i) => (
            <ImageContainer key={i}>
              <ImgWrapper>
                {data.notice_response_image?.map((imgUrl, index) => (
                  <StyledImg key={index} alt={data.name} src={imgUrl} />
                ))}
              </ImgWrapper>

              <StyledText>
                <StyledSpan>게시자:</StyledSpan> {data.name}
              </StyledText>
              <StyledText>
                <StyledSpan>등록일:</StyledSpan> {data.created_on?.substr(0, 4)}
                . {data.created_on?.substr(5, 2)}.{' '}
                {data.created_on?.substr(8, 2)}
              </StyledText>
            </ImageContainer>
          ))}

        {storeFeedback &&
          storeFeedback.responses.map((data, i) => {
            return (
              <ImageContainer key={i}>
                <ImgWrapper>
                  {data.notice_response_image.map((imgUrl, index) => (
                    <StyledImg key={index} alt={data.name} src={imgUrl} />
                  ))}
                </ImgWrapper>

                <StyledText>
                  <StyledSpan>게시자:</StyledSpan> {data.name}
                </StyledText>
                <StyledText>
                  <StyledSpan>등록일:</StyledSpan>{' '}
                  {data.created_on?.substr(0, 4)}.{' '}
                  {data.created_on?.substr(5, 2)}.{' '}
                  {data.created_on?.substr(8, 2)}
                </StyledText>
              </ImageContainer>
            );
          })}

        <ButtonContainer>
          <StyledButton onClick={handleClose}>확인</StyledButton>
        </ButtonContainer>
      </Container>
    </MuiModal>
  );
};
export default MoreImageModal;
