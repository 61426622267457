import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';

import api from 'shared/utils/api';
import StoreDetailTemplate from './template';
import {useDispatch} from 'react-redux';
import {setQSCUID} from 'franchise/utils/slices/frQscSlice';

const QSCStore = () => {
  const {
    state: {franchise_store_uid, currentStore},
  } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [svStoreDetails, setSvStoreDetails] = useState(null);
  const [svFirstStoreDetail, setSvFirstStoreDetail] = useState(null);
  const [qscStatsInfo, setQscStatsInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [matrixLoading, setMatrixLoading] = useState(false);
  const [matrixTableData, setMatrixTableData] = useState(null);
  const [matrixGraphData, setMatrixGraphData] = useState(null);

  const getSvStoreDetails = useMemo(
    () => async () => {
      setIsLoading(true);
      try {
        const response = await api.getSupervisorStoreDetail(
          franchise_store_uid,
        );

        const {results} = response.data;
        setSvStoreDetails(results);
        if (results?.qsc_history.length > 0) {
          setSvFirstStoreDetail(results?.qsc_history[0]);
        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const getQSCStatisticsMatrixAsync = useMemo(
    () => async (qsc_uid) => {
      setMatrixLoading(true);
      try {
        const response = await api.getQSCStatisticsMatrix(qsc_uid);
        const {results} = response.data;
        setMatrixLoading(false);
        setMatrixTableData(results);
        setMatrixGraphData(results);
      } catch (e) {
        setMatrixLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const getQSCStatisticsInfo = useMemo(
    () => async () => {
      setIsLoading(true);
      try {
        const response = await api.getQSCStatisticsInfo(franchise_store_uid);

        const {results} = response.data;

        if (results) {
          setQscStatsInfo(results);
        } else {
          setQscStatsInfo(null);
        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    getSvStoreDetails();
    getQSCStatisticsInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFullHistoryBtn = useCallback(
    (_id) => {
      navigate(`/franchise/qscHistory/${_id}`, {
        state: {
          id: _id,
        },
      });
    },
    [navigate],
  );

  const handleQSCMatrixGraphTable = (_history) => {
    getQSCStatisticsMatrixAsync(_history.qsc_uid);
  };

  const handleViewQscDetail = useCallback(
    (_qscDetail) => {
      const {qsc_uid} = _qscDetail;
      dispatch(setQSCUID(qsc_uid));

      navigate(`/franchise/qscDetail/${qsc_uid}`);
    },
    [dispatch, navigate],
  );

  // Go back to chat room list
  const handleListBtnClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <>
      {!isLoading && (
        <StoreDetailTemplate
          handleListBtnClick={handleListBtnClick}
          currentStore={currentStore}
          svStoreDetails={svStoreDetails}
          svFirstStoreDetail={svFirstStoreDetail}
          qscStatsInfo={qscStatsInfo}
          handleFullHistoryBtn={handleFullHistoryBtn}
          handleQSCMatrixGraphTable={handleQSCMatrixGraphTable}
          matrixGraphData={matrixGraphData}
          matrixTableData={matrixTableData}
          matrixLoading={matrixLoading}
          handleViewQscDetail={handleViewQscDetail}
        />
      )}
    </>
  );
};

export default QSCStore;
