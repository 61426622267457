/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, {useState, useCallback} from 'react';
import {useNavigate, useLocation, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';

import NoticeEditTemplate from './template';
import {selectStore} from 'owler/utils/slices/storeSlice';
import {selectNotice} from 'owler/utils/slices/noticeSlice';
import {editNoticeAsync} from 'owler/utils/slices/noticeSlice';
import {getNoticesByPageAsync} from 'owler/utils/slices/noticeSlice';
import {setMasterNoticeList} from 'owler/utils/slices/noticeSlice';
import {array, object} from 'prop-types';

const NoticeEdit = () => {
  const {t} = useTranslation();
  const {id} = useParams();
  const navigate = useNavigate();
  const {currentStore} = useSelector(selectStore);
  const {currentNotice} = useSelector(selectNotice);
  const dispatch = useDispatch();

  const [content, setContent] = useState(currentNotice.rich_contents);

  const handleContentChange = (htmlContent) => {
    setContent(htmlContent);
  };

  const convertUrlToFile = async (url, name, type) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], name, {type});
  };

  const onSubmit = useCallback(
    async (value) => {
      let {title, files, urls, description_images} = value;
      try {
        const form = new FormData();
        form.append('notice_uid', id);
        form.append('store_uid', currentStore.store_uid);
        form.append('title', title);
        form.append('notice_type', currentNotice.notice_type);
        form.append('rich_contents', content);

        for (const file of files) {
          let fileToAppend;

          if (file.type === undefined) {
            // This is a newly selected file, so we can append it directly.
            fileToAppend = file;

            form.append('files', fileToAppend?.file, fileToAppend?.file?.name);
          } else {
            // This is an existing file URL; we convert it to a File object.
            fileToAppend = await convertUrlToFile(
              file.uri,
              file.name,
              file.type,
            );

            form.append('files', fileToAppend, fileToAppend.name);
          }
        }

        if (description_images.length) {
          await Promise.all(
            description_images.map(async (image, index) => {
              let fileName;
              let fileExtension;
              let imageUrl;
              let data;
              if (image instanceof File) {
                imageUrl = URL.createObjectURL(image);
                fileName = image.name;
                fileExtension = image.type;
                data = image;
              } else {
                imageUrl = image;
                const originalFileExtension = imageUrl.substring(
                  imageUrl.lastIndexOf('.') + 1,
                );
                fileExtension =
                  originalFileExtension === 'jpg'
                    ? 'image/jpeg'
                    : `image/${originalFileExtension}`;
                fileName =
                  imageUrl.substring(
                    imageUrl.lastIndexOf('/') + 1,
                    imageUrl.lastIndexOf('.'),
                  ) +
                  '.' +
                  originalFileExtension;
                const response = await fetch(imageUrl, {
                  method: 'GET',
                  headers: {
                    'Cache-Control': 'no-cache',
                    // 여기에 필요한 다른 헤더들도 추가할 수 있습니다.
                  },
                });
                data = await response.blob();
              }
              form.append(
                'images',
                new File([data], fileName, {type: fileExtension}),
              );

              if (image instanceof File) {
                URL.revokeObjectURL(imageUrl);
              }
            }),
          );
        }

        if (typeof urls === object) {
          urls.forEach((url) => {
            url = url.includes('https://') ? url : `https://${url}`;
            form.append('urls', url);
          });
        } else if (urls.length === 0) {
        } else {
          form.append('urls', urls);
        }

        dispatch(editNoticeAsync(form))
          .unwrap()
          .then(() => {
            dispatch(
              getNoticesByPageAsync({
                store_uid: currentStore.store_uid,
                page: 1,
              }),
            )
              .unwrap()
              .then((payload) => {
                dispatch(setMasterNoticeList(payload?.results));
              })
              .catch((err) => alert(err.message));

            navigate(-2);
          })
          .catch(() => {
            alert(t('alerts.cant_make_notice'), '', [
              {
                text: t('buttons.confirm'),
                onPress: () => navigate(-2),
                style: 'cancel',
              },
            ]);
          });
      } catch (e) {
        console.error(e);
      }
    },
    [
      content,
      currentNotice.notice_type,
      currentStore.store_uid,
      dispatch,
      id,
      navigate,
      t,
    ],
  );

  const handleBackBtnClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onValidate = (value) => {
    const errors = {};
    if (!value.title) errors.title = '내용을 입력해주세요';
    return errors;
  };

  return (
    <>
      <NoticeEditTemplate
        store={currentStore}
        onSubmit={onSubmit}
        onValidate={onValidate}
        handleBackBtnClick={handleBackBtnClick}
        handleContentChange={handleContentChange}
      />
      ;
    </>
  );
};

export default NoticeEdit;
