import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const StyledSvg = styled.svg`
  cursor: ${({onClick}) => (onClick ? 'pointer' : null)};
`;
const ImageRegularIcon = ({length, fill, onClick}) => {
  return (
    <StyledSvg
      onclick={onClick}
      width={length}
      height={length}
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="image"
      className="svg-inline--fa fa-image fa-w-16"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512">
      <path
        fill={fill}
        d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm-6 336H54a6 6 0 0 1-6-6V118a6 6 0 0 1 6-6h404a6 6 0 0 1 6 6v276a6 6 0 0 1-6 6zM128 152c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zM96 352h320v-80l-87.515-87.515c-4.686-4.686-12.284-4.686-16.971 0L192 304l-39.515-39.515c-4.686-4.686-12.284-4.686-16.971 0L96 304v48z"
      />
    </StyledSvg>
  );
};

ImageRegularIcon.propTypes = {
  length: PropTypes.number,
  fill: PropTypes.string,
  onClick: PropTypes.func,
};
ImageRegularIcon.defaultProps = {
  length: 20,
  fill: 'black',
  onClick: null,
};
export default ImageRegularIcon;
