/* eslint-disable dot-notation */
/* eslint-disable no-constant-condition */
/* eslint-disable react/prop-types */
/* eslint-disable no-lone-blocks */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/require-default-props */
import React from 'react';
import {Formik} from 'formik';
import styled from 'styled-components';

import {makeStyles} from '@mui/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Fab from '@mui/material/Fab';
import SendIcon from '@mui/icons-material/Send';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {Divider} from '@mui/material';

import {
  FranchiseWrapper,
  Text,
  TableData,
  TableDataGroup,
  Button,
} from 'shared/components/index';

const Container = styled.div`
  margin-top: 125px;
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin-right: 5px;
  white-space: nowrap;
`;

const ButtonEmptyContent = styled.div`
  display: done;
  width: 100px;
  margin-right: 5px;
`;

const ButtonWrapper = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const useStyles = makeStyles({
  creator: {
    display: 'flex',
    flexDirection: 'column',
  },

  creatorField: {
    color: '#000000',
  },

  table: {
    minWidth: 650,
  },

  chatSection: {
    width: '100%',
    height: '80vh',
    marginTop: '10px',
    boxShadow: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  headBG: {
    backgroundColor: '#e0e0e0',
  },

  signatureSection: {
    height: '50vh',
    border: '1px solid #e0e0e0',
    borderRadius: '10px',
  },

  messageArea: {
    height: '70vh',
    overflowY: 'auto',
  },

  commentsSection: {
    border: '1px solid #e0e0e0',
    borderRadius: '10px',
  },

  chatMessage: {
    border: '1px solid #e0e0e0',
    borderRadius: '10px',
    padding: '10px',
  },
});

const QSCNoteTemplate = (props) => {
  const classes = useStyles();
  const {handleListBtnClick, qscNotes, onSubmit, handleDeleteQscNote} = props;

  return (
    <>
      <FranchiseWrapper overflow="hidden">
        <Container>
          <div
            style={{
              marginTop: '100px',
              width: '1200px',
              margin: '15px auto',
            }}
          >
            <Grid container>
              <TableDataGroup justifyContent="space-between">
                <TableData>
                  <Button
                    size="sm"
                    color="#6650c0"
                    onClick={handleListBtnClick}
                  >
                    <ButtonContent>
                      <ChevronLeftIcon />
                      이전 화면
                    </ButtonContent>
                  </Button>
                </TableData>

                <Text text="비고" fontSize="xxxl" />

                <TableData>
                  <ButtonEmptyContent />
                </TableData>
              </TableDataGroup>
            </Grid>

            <Grid
              container
              spacing={0}
              component={Paper}
              className={classes.chatSection}
            >
              <Grid item xs={12} className={classes.commentsSection}>
                <TableDataGroup justifyContent="center">
                  <>
                    {qscNotes?.length === 0 && (
                      <p style={{marginTop: '20px'}}>작성된 비고가 없습니다.</p>
                    )}
                  </>
                </TableDataGroup>

                <List className={classes.messageArea}>
                  {qscNotes?.map((msg, indx) => (
                    <ListItem key={indx.toString()}>
                      <Grid container spacing={2}>
                        <Grid item xs={11}>
                          <ListItemText
                            className={`${classes.chatMessage} ${
                              true ? 'sender' : 'receiver'
                            }`}
                            primary={
                              <div className={classes.creator}>
                                <span>{msg?.contents}</span>
                              </div>
                            }
                          />
                        </Grid>

                        <Grid item xs={1}>
                          <ButtonWrapper>
                            <Button
                              onClick={() => handleDeleteQscNote(msg)}
                              color="red"
                            >
                              삭제
                            </Button>
                          </ButtonWrapper>
                        </Grid>
                      </Grid>
                    </ListItem>
                  ))}
                </List>

                <Divider />

                <Formik onSubmit={onSubmit} initialValues={{message: ''}}>
                  {(formikProps) => (
                    <Form
                      encType="multipart/form-data"
                      onBlur={formikProps.handleBlur}
                      onSubmit={formikProps.handleSubmit}
                    >
                      <Grid
                        container
                        style={{padding: '20px', borderRadius: '10px'}}
                      >
                        <Grid item xs={11}>
                          <TextField
                            id="outlined-basic-email"
                            label="비고 작성"
                            fullWidth
                            name="message"
                            value={formikProps.values['message']}
                            onChange={formikProps.handleChange}
                            error={
                              formikProps.touched['message'] &&
                              Boolean(formikProps.errors['message'])
                            }
                            helperText={
                              (formikProps.touched['message'] &&
                                formikProps.errors['message']) ||
                              ' '
                            }
                            InputProps={
                              classes && {
                                classes: {
                                  root: classes.text,
                                  input: classes.textInput,
                                  multiline: classes.multi,
                                },
                              }
                            }
                          />
                        </Grid>

                        <Grid item xs={1} align="right">
                          <Fab
                            id="message-submit"
                            type="submit"
                            color="primary"
                            aria-label="add"
                            className={classes.sendButton}
                          >
                            <SendIcon />
                          </Fab>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </div>
        </Container>
      </FranchiseWrapper>
    </>
  );
};

const Form = styled.form``;

export default QSCNoteTemplate;
