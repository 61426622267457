import React, {useState} from 'react';
import {Formik} from 'formik';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
// import {useTranslation} from 'react-i18next';
import styled, {css} from 'styled-components';
import {
  CheckBox,
  ImageSelector,
  Text,
  MuiButton,
  MuiRadio,
  WeekSelector,
} from 'shared/components/index';
import theme from 'shared/styles/theme';

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: ${({container}) => (container ? 'row' : 'column')};
  &:not(:last-child) {
    margin-right: 30px;
  }
  width: ${({width}) => width || '100%'};
  ${({center}) =>
    center &&
    css`
      margin: 0 auto;
    `}
`;

const Form = styled.form`
  width: 920px;
  min-height: 700px;
  padding: 20px 40px;
  margin: 0 auto 50px auto;
  border: 1px solid ${theme.colors.grey6};
  border-radius: 15px;
  & > * {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
    &:first-child {
      margin: 0px 0 70px;
    }
    &:last-child {
      margin-top: 30px;
    }
  }
`;

const Field = ({
  formikProps,
  label,
  name,
  placeholder,
  classes,
  ...restProps
}) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        {label}
      </Typography>
      <TextField
        {...restProps}
        name={name}
        value={formikProps.values[name]}
        onChange={formikProps.handleChange}
        error={formikProps.touched[name] && Boolean(formikProps.errors[name])}
        helperText={
          (formikProps.touched[name] && formikProps.errors[name]) || ' '
        }
        variant="filled"
        fullWidth
        placeholder={placeholder}
        className={classes && classes.text}
        InputProps={
          classes && {
            classes: {
              root: classes.text,
              input: classes.textInput,
              multiline: classes.multi,
            },
          }
        }
      />
    </>
  );
};

const SlaveTaskAddForm = ({
  categoryName,
  onSubmit,
  onValidate,
  handleGoback,
}) => {
  const [showDaysInput, setShowDaysInput] = useState(false);
  const [showWeeksInput, setShowWeeksInput] = useState(false);
  // const {t} = useTranslation();
  const weeks = [
    {label: '월', value: 'MON'},
    {label: '화', value: 'TUE'},
    {label: '수', value: 'WED'},
    {label: '목', value: 'THU'},
    {label: '금', value: 'FRI'},
    {label: '토', value: 'SAT'},
    {label: '일', value: 'SUN'},
  ];

  const handleRadioClick = (value) => {
    if (value === 'n일 마다') {
      setShowDaysInput(true);
      setShowWeeksInput(false);
    } else if (value === 'n주 마다') {
      setShowDaysInput(false);
      setShowWeeksInput(true);
    } else {
      setShowDaysInput(false);
      setShowWeeksInput(false);
    }
  };

  return (
    <Formik
      onSubmit={onSubmit}
      validate={onValidate}
      initialValues={{
        name: '',
        // content: '',
        start: '',
        end: '',
        repetition: '없음',
        task_type: '체크',
        description: '',
        notify: false,
        // numberOfDays: 1,
        // numberOfWeeks: 1,
        // daysOfTheWeek: [],
        description_images: [],
        weeks: [],
        repeat_amount: 1,
      }}
    >
      {(formikProps) => {
        return (
          <Form
            onBlur={formikProps.handleBlur}
            onSubmit={formikProps.handleSubmit}
          >
            <Text
              style={{
                width: '100%',
                textAlign: 'center',
                fontSize: '25px',
                fontWeight: '600',
              }}
            >
              새로운 업무 생성
            </Text>
            <Typography
              align="left"
              style={{
                fontSize: '20px',
                fontWeight: 'bold',
                margin: '-30px 0 20px 0',
              }}
            >{`카테고리: ${categoryName}`}</Typography>
            <FieldWrapper>
              <Field
                name="name"
                label="업무명"
                placeholder="ex)유통기한 검사하기"
                formikProps={formikProps}
              />
            </FieldWrapper>
            <FieldWrapper container>
              <FieldWrapper width="33%">
                <Field
                  name="start"
                  label="업무 수행 시간"
                  placeholder="ex)13:00"
                  formikProps={formikProps}
                />
                <Text
                  style={{
                    fontSize: '16px',
                    margin: '-10px 0 0 5px',
                  }}
                >
                  부터
                </Text>
                <Field
                  name="end"
                  placeholder="ex)21:00"
                  formikProps={formikProps}
                />
                <Text
                  style={{
                    fontSize: '16px',
                    margin: '-10px 0 0 5px',
                  }}
                >
                  까지
                </Text>
              </FieldWrapper>
              <FieldWrapper container width="33%" style={{marginLeft: '50px'}}>
                <MuiRadio
                  formLabel="주기"
                  name="repetition"
                  value={formikProps.values.repetition}
                  labels={[
                    '없음',
                    '매일',
                    '주 1회',
                    'n일 마다',
                    'n주 마다',
                    '월 1회',
                  ]}
                  handleChange={formikProps.handleChange}
                  handleRadioClick={handleRadioClick}
                />

                <FieldWrapper width="33%">
                  {showDaysInput && (
                    <FieldWrapper width="80%">
                      <div style={{height: '150px'}} />
                      <TextField
                        label="반복 주기"
                        name="repeat_amount"
                        value={formikProps.values.repeat_amount}
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        type="number"
                        inputProps={{min: 1}}
                        placeholder="0"
                      />
                    </FieldWrapper>
                  )}

                  {showWeeksInput && (
                    <FieldWrapper width="80%">
                      <div style={{height: '190px'}} />
                      <TextField
                        label="반복 주기"
                        name="repeat_amount"
                        value={formikProps.values.repeat_amount}
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        type="number"
                        inputProps={{min: 1}}
                        placeholder="0"
                      />
                    </FieldWrapper>
                  )}
                </FieldWrapper>
              </FieldWrapper>

              <FieldWrapper width="20%">
                <MuiRadio
                  formLabel="업무 보고 방식"
                  name="task_type"
                  value={formikProps.values.task_type}
                  labels={['체크', '사진', '메모']}
                  handleChange={formikProps.handleChange}
                />
              </FieldWrapper>
            </FieldWrapper>

            {showWeeksInput && (
              <FieldWrapper container width="100%">
                <FieldWrapper width="40%" />

                <FieldWrapper width="50%">
                  <WeekSelector
                    label="요일 선택"
                    name="weeks"
                    options={weeks}
                    value={formikProps.values.weeks}
                    onChange={formikProps.setFieldValue}
                  />
                </FieldWrapper>
              </FieldWrapper>
            )}

            <FieldWrapper>
              <Field
                name="description"
                label="업무 설명"
                multiline
                rows={2}
                placeholder="ex) 유통기한 확인 후, 기한이 짧은 순대로 앞부터 진열"
                formikProps={formikProps}
              />
            </FieldWrapper>

            <FieldWrapper>
              <ImageSelector
                name="description_images"
                value={formikProps.values.description_images}
                formikProps={formikProps}
                label="업무 설명 이미지"
                onChange={formikProps.handleChange}
              />
            </FieldWrapper>

            <FieldWrapper>
              <CheckBox
                label="업무 완료시 알림 받기"
                name="notify"
                value={formikProps.values.notify}
                style={{transform: 'scale(1.5)'}}
                onChange={formikProps.handleChange}
              />
            </FieldWrapper>

            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div></div>
              <div>
                <MuiButton
                  id="taskAdd-submit"
                  text="취소"
                  backgroundColor="#bdbdbd"
                  textcolor={theme.colors.white}
                  style={{margin: '0 10px 0 0'}}
                  onClick={() => handleGoback()}
                />
                <MuiButton
                  id="taskAdd-submit"
                  disabled={
                    formikProps.values.name === '' || !formikProps.isValid
                  }
                  text="생성"
                  type="submit"
                  backgroundColor="#6550c0"
                  textcolor={theme.colors.white}
                />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SlaveTaskAddForm;
