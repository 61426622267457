import React, {useEffect, useRef} from 'react';
import FullCalendar from '@fullcalendar/react';
import styled from 'styled-components';
import {useState} from 'react';
import {differenceInDays, getDay} from 'date-fns';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import {useDispatch, useSelector} from 'react-redux';
import {
  selectCalendar,
  setScheduleModal,
  setSelectedDate2,
  setEventAddModal,
  moveEvent,
} from 'owler/utils/slices/calendarSlice';
import {Text} from 'shared/components/index';

const CalendarContainer = styled.div`
  width: 75%;
`;
const GlobalStyles = styled.div`
  .fc-daygrid-block-event {
    & .fc-event-title {
      padding: 0 0 0 3px;
    }
    margin-bottom: 1px;
  }
  .fc-daygrid-day-events {
    height: 75px;
  }

  .fc-daygrid-day:hover {
    cursor: pointer;
    opacity: 0.6;
  }
  .fc-daygrid-day.fc-day-disabled {
    cursor: default;
    opacity: 1;
  }
  .fc-popover {
    z-index: 2000;
  }
  .fc-toolbar-chunk {
    font-size: 13px;

    &:first-child {
      margin: 0 0 0 20px;
    }
    &:nth-child(2) {
      margin-left: -400px;
    }
  }
  .fc-day-sun {
    color: red;
  }
`;
const WrapperForEvent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  background-color: ${({color}) => color};
  width: 100%;
  border-radius: 4px;
  height: 21px !important;
`;

const ReactCalendar = ({setSelectedDate, load}) => {
  const [calendarHeight, setCalendarHeight] = useState(770);
  const dispatch = useDispatch();
  const {events} = useSelector(selectCalendar);
  const [rerender, setRerender] = useState(false);
  const calendarRef = useRef(null);

  const days = (startDate, endDate) => {
    // 한 달이 몇주 인지 높이 조절 함수
    const totalDays =
      differenceInDays(new Date(endDate), new Date(startDate)) + 1;
    const startDay = getDay(new Date(startDate));
    // 첫 주의 일 수를 계산
    const firstWeekDays = 7 - startDay;
    const remainingDays = totalDays - firstWeekDays;

    const fullWeeks = Math.floor(remainingDays / 7);
    const remainingWeekDays = remainingDays % 7;
    let weeks = remainingWeekDays > 0 ? 1 + fullWeeks + 1 : 1 + fullWeeks;

    if (weeks === 4) setCalendarHeight(540);
    if (weeks === 5) setCalendarHeight(645);
    if (weeks === 6) setCalendarHeight(760);
    return;
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setRerender((prev) => !prev);
    }, 0); // 10 milliseconds or 0.01 second

    return () => {
      clearTimeout(timer); // 타이머 정리
    };
  }, [events]);

  const handleDateClick = (info) => {
    //팝업 띄우는 함수
    dispatch(setScheduleModal(true));
    dispatch(setSelectedDate2(info.date));
  };
  const handleEventClick = (info) => {
    // 이벤트를 클릭했을 때 해당 이벤트의 시작 날짜를 사용합니다.
    const date = info.event.start;
    handleDateClick({date}); // handleDateClick에 시작 날짜를 전달합니다.
  };
  const handleModalOpen = () => {
    dispatch(setEventAddModal(true));
  };

  const handleDateSet = (info) => {
    const startOfMonth = new Date(info.start);
    setSelectedDate(startOfMonth); // 현재 달의 첫 날짜를 설정합니다.
    dispatch(setSelectedDate2(startOfMonth));
    const endWeekOfMonth = new Date(info.end - 1);
    days(startOfMonth, endWeekOfMonth);
  };

  const handleEventDrop = (info) => {
    const {title, start, end} = info.event;
    dispatch(moveEvent({title, start, end}));
  };
  const renderEventContent = (eventInfo) => {
    return (
      <WrapperForEvent color={eventInfo.event.borderColor}>
        <div>
          <div className="custom-event-title">
            <Text
              color="white"
              style={{
                padding: '0 0px 0 3px',
                width: '100px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {eventInfo.event.title}
            </Text>
          </div>
        </div>
      </WrapperForEvent>
    );
  };
  return (
    <CalendarContainer>
      <GlobalStyles>
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          locale="ko"
          weekends={true}
          headerToolbar={{left: 'title', center: 'prev next today', right: ''}}
          buttonText={{today: '이번 달', month: '월', week: '주', day: '일'}}
          fixedWeekCount={false}
          events={events}
          eventColor="#D0A9F5"
          showNonCurrentDates={false}
          height={calendarHeight}
          datesSet={handleDateSet}
          dateClick={handleDateClick}
          eventClick={handleEventClick}
          dayMaxEvents={true}
          editable={true} // 수정 가능?
          nowIndicator={true}
          customButtons={{
            addEvent: {
              text: `이벤트 추가`,
              click: handleModalOpen,
            },
          }}
          eventDrop={handleEventDrop}
          eventDurationEditable={false}
          dayCellClassNames={(arg) => {
            const classNames = [];
            if (arg.date.getDay() === 0) {
              classNames.push('fc-day-sun');
            }
            return classNames;
          }}
          eventContent={renderEventContent}
        />
      </GlobalStyles>
    </CalendarContainer>
  );
};

export default ReactCalendar;
