/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import Grid from '@mui/material/Grid';
import styled from 'styled-components';
import QscBarChart from './QscBarChart';
import QSCMatrix from './QSCMatrix';
import {
  Button,
  FranchiseWrapper,
  TableData,
  TableDataGroup,
  TableWrapper,
  Text,
} from 'shared/components/index';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const Container = styled.div`
  margin-top: 90px;
`;

const QscBtnContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin-right: 5px;
  white-space: nowrap;
`;

const ButtonEmptyContent = styled.div`
  display: done;
  width: 100px;
  margin-right: 5px;
`;

const StoreDetailColumnOne = ({
  currentStore,
  svStoreDetails,
  handleFullHistoryBtn,
  handleViewQscDetail,
}) => {
  return (
    <div
      style={{
        width: '40%',
        marginRight: '50px',
        marginTop: '0px',
      }}
    >
      {/* Store Info Detail  */}
      <Text
        fontSize="large"
        bold
        margin="0 0 55px 0"
        style={{
          width: '215px',
          textAlign: 'left',
        }}
      >
        매장 정보
      </Text>

      <div
        style={{
          width: '100%',
          border: '1px solid #d5d5d5',
          borderRadius: '10px',
          padding: '10px 10px 15px 20px',
        }}
      >
        <TableDataGroup style={{marginTop: '10px'}} justifyContent="flex-start">
          <Text
            style={{
              width: '100%',
              textAlign: 'left',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            fontSize="large"
            bold
          >
            {currentStore.store_name}
          </Text>
        </TableDataGroup>

        <TableDataGroup style={{marginTop: '10px'}} justifyContent="flex-start">
          <Text
            style={{
              width: '100%',
              textAlign: 'left',
              color: '#bdbdbd',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            fontSize="base"
          >{`${currentStore.store_address}`}</Text>
        </TableDataGroup>

        <TableDataGroup style={{marginTop: '10px'}} justifyContent="flex-start">
          <Text
            style={{width: '100%', textAlign: 'left', fontSize: '15px'}}
          >{`점주: ${currentStore.owner_name}`}</Text>
        </TableDataGroup>

        <TableDataGroup style={{marginTop: '10px'}} justifyContent="flex-start">
          <Text
            style={{
              width: '100%',
              textAlign: 'left',
              color: svStoreDetails?.contact_number === '' && '#bdbdbd',
            }}
            fontSize="base"
          >{`연락처: ${
            svStoreDetails?.contact_number || '등록된 연락처 정보가 없습니다.'
          }`}</Text>
        </TableDataGroup>

        <TableDataGroup style={{marginTop: '10px'}} justifyContent="flex-start">
          <Text
            style={{width: '100%', textAlign: 'left'}}
            fontSize="base"
          >{`마지막 방문일: ${
            currentStore.last_visit !== null
              ? new Date(currentStore.last_visit)
                  .toLocaleString('ko-KR', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false,
                  })
                  .replace(',', '')
              : '--'
          }`}</Text>
        </TableDataGroup>
      </div>

      {/* Store QSC History  */}
      <div
        style={{
          width: '100%',
          height: '700px',
          borderRadius: '10px',
          marginTop: '42px',
          padding: '10px',
          overflowY: 'auto',
        }}
      >
        <Grid container>
          <TableDataGroup
            style={{marginBottom: '15px'}}
            justifyContent="center"
          >
            <Text
              fontSize="large"
              bold
              margin="0 0 15px 0"
              style={{
                width: '215px',
                textAlign: 'left',
              }}
            >
              QSC 히스토리
            </Text>
          </TableDataGroup>

          <TableDataGroup
            style={{marginBottom: '15px'}}
            justifyContent="center"
          ></TableDataGroup>
        </Grid>

        <div style={{width: '100%', height: '30px'}}>
          <Text
            style={{
              fontSize: '15px',
              width: '180px',
              textAlign: 'center',
            }}
          >
            작성일
          </Text>
          <Text
            style={{
              fontSize: '15px',
              width: '180px',
              textAlign: 'center',
              margin: '0  0 0 15px',
            }}
          >
            작성자
          </Text>
        </div>

        {svStoreDetails?.qsc_history?.length === 0 && (
          <TableDataGroup style={{marginTop: '10px'}} justifyContent="center">
            <Text fontSize="lg">과거 QSC가 존재하지 않습니다.</Text>
          </TableDataGroup>
        )}

        {svStoreDetails?.qsc_history?.map((_history, indx) => (
          <div
            style={{
              width: '100%',
              height: '50px',
              borderBottom: '1px solid #d5d5d5',
              //borderRadius: '5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '10px 0 0 0',
            }}
            key={indx.toString()}
          >
            <TableDataGroup>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0 10px',
                }}
              >
                <Text
                  style={{
                    fontSize: '13px',
                    width: '180px',
                    textAlign: 'left',
                    padding: '0 0 0 20px',
                  }}
                >{`${new Date(_history?.created_on)
                  .toLocaleString('ko-KR', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false,
                  })
                  .replace(',', '')}`}</Text>
                {/* SUN 1. */}
                <Text
                  style={{
                    fontSize: '13px',
                    width: '180px',
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {_history?.sv_name + ' ' + _history?.sv_position}
                </Text>
                <Button
                  style={{
                    fontSize: '13px',
                    height: '28px',
                    borderRadius: '6px',
                  }}
                  onClick={() => handleViewQscDetail(_history)}
                  color="#6650c0"
                >
                  <QscBtnContent>QSC</QscBtnContent>
                </Button>
              </div>
            </TableDataGroup>
          </div>
        ))}
        <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
          <Button
            style={{fontSize: '15px', width: '160px', marginTop: '20px'}}
            color="#6650c0"
            onClick={() =>
              handleFullHistoryBtn(currentStore.franchise_store_uid)
            }
          >
            전체 히스토리 보기
          </Button>
        </div>
      </div>
    </div>
  );
};

const StoreDetailColumnTwo = ({
  qscStatsInfo,
  svStoreDetails,
  svFirstStoreDetail,
  handleQSCMatrixGraphTable,
  matrixGraphData,
  matrixTableData,
  matrixLoading,
}) => {
  return (
    <div
      style={{
        width: '45%',
        marginTop: '0px',
      }}
    >
      <Grid container>
        <Text
          fontSize="large"
          bold
          margin="0 0 10px 0"
          style={{
            width: '215px',
            textAlign: 'left',
          }}
        >
          매장 평가 개요
        </Text>
        <div>
          <TableDataGroup
            width="600px"
            justifyContent="flex-end"
            // style={{border: '1px solid black'}}
          >
            <TableDataGroup width="250px" justifyContent="space-between">
              <Text
                text={`평균 평점: ${qscStatsInfo?.avg_qsc_score || '0'}`}
                fontSize="xxl"
              >
                평균 평점: {qscStatsInfo?.avg_qsc_score || '0'}
              </Text>

              <Text
                text={`최근 평점: ${qscStatsInfo?.last_qsc_score || '0'}`}
                fontSize="xxl"
              >
                최근 평점: {qscStatsInfo?.last_qsc_score || '0'}
              </Text>
            </TableDataGroup>
          </TableDataGroup>

          <div
            style={{
              marginTop: '10px',
              // border: '1px solid #d5d5d5',
              borderRadius: '10px',
              padding: '10px',
            }}
          >
            <QscBarChart
              matrixGraphData={matrixGraphData}
              matrixLoading={matrixLoading}
            />
          </div>

          <div
            style={{
              marginTop: '20px',
              // border: '1px solid #d5d5d5',
              borderRadius: '10px',
              padding: '10px',
            }}
          >
            <QSCMatrix
              svStoreDetails={svStoreDetails}
              svFirstStoreDetail={svFirstStoreDetail}
              handleQSCMatrixGraphTable={handleQSCMatrixGraphTable}
              matrixTableData={matrixTableData}
              matrixLoading={matrixLoading}
            />
          </div>
        </div>
      </Grid>
    </div>
  );
};

const StoreDetailTemplate = ({
  handleListBtnClick,
  currentStore,
  svStoreDetails,
  svFirstStoreDetail,
  qscStatsInfo,
  handleFullHistoryBtn,
  handleQSCMatrixGraphTable,
  matrixGraphData,
  matrixTableData,
  matrixLoading,
  handleViewQscDetail,
}) => {
  return (
    <FranchiseWrapper>
      <Container>
        <div
          style={{
            marginTop: '150px',
            width: '1200px',
            margin: '15px ',
          }}
        >
          <Grid container>
            <TableDataGroup justifyContent="space-between">
              <TableData>
                <Button size="sm" color="#6650c0" onClick={handleListBtnClick}>
                  <ButtonContent>
                    <ChevronLeftIcon />
                    이전 화면
                  </ButtonContent>
                </Button>
              </TableData>

              <TableData>
                <ButtonEmptyContent />
              </TableData>
            </TableDataGroup>
          </Grid>

          <TableWrapper>
            <div
              style={{
                width: '1250px',
                marginTop: '100px',
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
              }}
            >
              <StoreDetailColumnOne
                currentStore={currentStore}
                svStoreDetails={svStoreDetails}
                handleFullHistoryBtn={handleFullHistoryBtn}
                handleViewQscDetail={handleViewQscDetail}
              />

              <StoreDetailColumnTwo
                qscStatsInfo={qscStatsInfo}
                svStoreDetails={svStoreDetails}
                svFirstStoreDetail={svFirstStoreDetail}
                handleQSCMatrixGraphTable={handleQSCMatrixGraphTable}
                matrixGraphData={matrixGraphData}
                matrixTableData={matrixTableData}
                matrixLoading={matrixLoading}
              />
            </div>
          </TableWrapper>
        </div>
      </Container>
    </FranchiseWrapper>
  );
};

export default StoreDetailTemplate;
