import React, {useState, useEffect} from 'react';
import EventDetailTemplate from './template/index';
import {useNavigate, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {
  selectMap,
  getEventDetailAsync,
  setEventDetail,
} from 'shared/utils/redux/mapSlice';

const EventDetail = () => {
  const navigate = useNavigate();
  const {
    state: {item},
  } = useLocation();
  const [buttonName, setButtonName] = useState('');
  const dispatch = useDispatch();
  const {eventDetail} = useSelector(selectMap);

  const goback = () => {
    navigate(-1);
  };

  const gotoLink = (url) => {
    if (url !== null) {
      window.open(url);
    } else {
      const ua = navigator.userAgent;
      const isAndroid = /android/i.test(ua);
      const isIOS = /iPad|iPhone|iPod/.test(ua) && !window.MSStream;
      if (item.category === '[네이버 영수증 리뷰] 이벤트') {
        window.open('https://m.place.naver.com/my/checkin');
      } else if (item.category === '[인스타그램 리뷰] 이벤트') {
        if (isAndroid) {
          if (window.confirm('Instagram 앱을 여시겠습니까?')) {
            setTimeout(() => {
              window.open('https://www.instagram.com/');
            }, 500);
          }
        } else if (isIOS) {
          if (window.confirm('Instagram 앱을 여시겠습니까?')) {
            window.open('instagram://app');
          }
        } else {
          // 웹 브라우저에서의 경우
          window.open('https://www.instagram.com/');
        }
      } else if (item.category === '[건의/불만/제안 작성] 이벤트') {
        if (window.confirm('아울러로 이동하시겠습니까?')) {
          navigate('/owlerReview');
        }
      } else {
        if (isAndroid) {
          if (window.confirm('추가로 연결된 링크(YouTube)를 여시겠습니까?')) {
            setTimeout(() => {
              window.open('https://www.youtube.com/');
            }, 500);
          }
        } else if (isIOS) {
          if (window.confirm('추가로 연결된 링크(YouTube)를 여시겠습니까?')) {
            window.open('youtube://www.youtube.com');
          }
        } else {
          // 웹 브라우저에서의 경우
          window.open('https://www.youtube.com/');
        }
      }
    }
  };

  useEffect(() => {
    if (item.category === '[네이버 영수증 리뷰] 이벤트') {
      setButtonName('네이버 영수증 리뷰 작성하기');
    } else if (item.category === '[인스타그램 리뷰] 이벤트') {
      setButtonName('인스타 게시글 작성하기');
    } else if (item.category === '[건의/불만/제안 작성] 이벤트') {
      setButtonName('아울러 리뷰 작성하기');
      dispatch(setEventDetail(item));
      return;
    } else {
      setButtonName('추후 변동');
    }
    dispatch(getEventDetailAsync({event_uid: item.event_uid})).then(
      (payload) => {
        dispatch(setEventDetail(payload.payload.results));
      },
    );
    return () => {
      dispatch(setEventDetail([]));
    };
  }, [dispatch, item]);

  return (
    <EventDetailTemplate
      goback={goback}
      item={eventDetail}
      item2={item}
      gotoLink={gotoLink}
      buttonName={buttonName}
    />
  );
};
export default EventDetail;
