import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';

const logoSize = {
  SMALL: 'sm',
  LARGE: 'lg',
};

const LogoWrapper = styled.div`
  border-radius: 50%;
  margin: 10px 0;
  cursor: pointer;
  ${({size}) => {
    switch (size) {
      case logoSize.SMALL:
        return css`
          width: 74px;
          height: 74px;
        `;
      case logoSize.LARGE:
        return css`
          width: 74px;
          height: 60px;
        `;
      default:
        return css`
          width: 80px;
          height: 74px;
        `;
    }
  }}
  &:hover {
    transition: all;
    transform: scale(1.05);
  }
  & .logo {
    width: 100%;
    height: 100%;
  }
`;

const Logo = (props) => {
  const {size, src, onClick} = props;
  return (
    <LogoWrapper size={size} onClick={onClick}>
      {src}
      {/* <img className="logo" src={src} alt="logo" /> */}
    </LogoWrapper>
  );
};

Logo.propTypes = {
  size: PropTypes.string,
  src: PropTypes.element,
};

Logo.defaultProps = {
  size: 'base',
  src: null,
};

export default Logo;
