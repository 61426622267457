/* eslint-disable no-restricted-globals */
import React, {useCallback, useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useNavigate, useLocation} from 'react-router-dom';

import {selectStore} from 'owler/utils/slices/storeSlice';
import TaskDetailTemplate from './template/index';
import {
  deleteTaskAsync,
  selectTask,
  setSelectedTask,
  getCompleteionTaskAsync,
  setDeleteTaskModal,
} from 'owler/utils/slices/taskSlice';

const TaskDetail = () => {
  const {currentStore} = useSelector(selectStore);
  const {
    state: {task, cat},
  } = useLocation();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {selectedCategory} = useSelector(selectTask);
  const {forTaskDetail} = useSelector(selectTask);
  const [imgOpened, setImgOpened] = useState(false);
  const dispatch = useDispatch();
  const [images, setImages] = useState([]);
  const [taskImageModal, setTaskImageModal] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(setDeleteTaskModal(false));
    };
  }, [dispatch]);

  const handleEditBtnClick = (_task) => {
    dispatch(setSelectedTask(_task));
    navigate(`/owler/tasks/taskEdit/${selectedCategory.task_category_uid}`, {
      state: {
        id: selectedCategory.task_category_uid,
        name: selectedCategory.name,
        task_uid: _task.task_uid,
      },
    });
  };

  const handleDeleteBtnClick = useCallback(
    async (_selectedTask, _option) => {
      if (!confirm('정말로 삭제하시겠습니까?')) return;
      dispatch(
        deleteTaskAsync({
          task_uid: _selectedTask?.task_uid,
          option: _option,
        }),
      )
        .unwrap()
        .then(() => {
          alert(t('alerts.task_deleted_success'));
          navigate(-1);
          dispatch(setDeleteTaskModal(false));
        })
        .catch((err) => alert(err.message));
    },
    [dispatch, navigate, t],
  );

  const handleListBtnClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleImgIconClick = useCallback(
    async (task_type, is_completed) => {
      if (task_type !== 'image') {
        return;
      }
      if (is_completed) {
        await dispatch(
          getCompleteionTaskAsync({
            task_uid: task.task_uid,
          }),
        ).then((payload) => {
          setImages(payload?.payload?.images);
          setImgOpened((prev) => !prev);
        });
      } else alert('업무가 완료되지 않았습니다.');
    },
    [setImgOpened, dispatch, task.task_uid],
  );

  const handleImgIconClose = useCallback(() => {
    setImgOpened(false);
  }, [setImgOpened]);

  return (
    <TaskDetailTemplate
      store={currentStore}
      task={task}
      forTaskDetail={forTaskDetail}
      imgOpened={imgOpened}
      handleDeleteBtnClick={handleDeleteBtnClick}
      handleListBtnClick={handleListBtnClick}
      handleImgIconClick={handleImgIconClick}
      handleImgIconClose={handleImgIconClose}
      handleEditBtnClick={handleEditBtnClick}
      images={images}
      cat={cat}
      taskImageModal={taskImageModal}
      setTaskImageModal={setTaskImageModal}
      setDeleteTaskModal={setDeleteTaskModal}
      dispatch={dispatch}
    />
  );
};

export default TaskDetail;
