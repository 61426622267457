/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, {useEffect, useState, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useLocation, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import NoticeAddTemplate from './template';
import {selectStore} from 'owler/utils/slices/storeSlice';
import {
  createNewNoticeAsync,
  getNoticesByPageAsync,
  selectNotice,
  setMasterNoticeList,
  setDirectStoreData,
  setNoDirectStoreData,
  setDirectGroup,
  setNoDirectGroup,
} from 'owler/utils/slices/noticeSlice';
import {
  getFranchiseStoresAsync,
  selectFranchise,
} from 'owler/utils/slices/franchiseSlice';

const NoticeAdd = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {id} = useParams();
  const {currentStore} = useSelector(selectStore);
  const {noDirectGroup, directStoreData, noDirectStoreData, directGroup} =
    useSelector(selectNotice);
  const {franchiseStores} = useSelector(selectFranchise);
  const {state} = useLocation();

  const [loading, setLoading] = useState(false);

  const [content, setContent] = useState('');

  const handleContentChange = (htmlContent) => {
    setContent(htmlContent);
  };

  // get franchise stores:
  useEffect(() => {
    dispatch(
      setDirectStoreData({
        all: false,
        stores: [],
        names: [],
      }),
    );
    dispatch(
      setNoDirectStoreData({
        all: false,
        stores: [],
        names: [],
      }),
    );
    if (currentStore?.is_hq) {
      dispatch(getFranchiseStoresAsync(currentStore.store_uid));
    }
  }, [dispatch, currentStore]);

  useEffect(() => {
    const direct = [];
    const noDirect = [];
    franchiseStores.forEach((item) => {
      if (item.is_direct) {
        direct.push(item);
      } else {
        noDirect.push(item);
      }
    });
    dispatch(setDirectGroup(direct));
    dispatch(setNoDirectGroup(noDirect));
  }, [dispatch, franchiseStores]);

  useEffect(() => {
    const direct_store_list = directGroup?.map(
      (eachstore) => eachstore.target_store_uid,
    );
    const direct_branch_name = directGroup?.map(
      (eachStore) => eachStore.branch_name,
    );
    const nodirect_store_list = noDirectGroup?.map(
      (eachstore) => eachstore.target_store_uid,
    );
    const nodirect_branch_name = noDirectGroup?.map(
      (eachStore) => eachStore.branch_name,
    );

    dispatch(
      setDirectStoreData({
        all: true,
        stores: direct_store_list,
        names: direct_branch_name,
      }),
    );
    dispatch(
      setNoDirectStoreData({
        all: true,
        stores: nodirect_store_list,
        names: nodirect_branch_name,
      }),
    );
  }, [directGroup, dispatch, noDirectGroup]);

  const onSubmit = async (value) => {
    let {notice_type, urls} = value;
    const {title, files, description_images} = value;

    if (notice_type === '') {
      alert('공지체크 방식을 선택해주세요.');
      return;
    }

    if (notice_type === '사진 취합: 피드백을 사진으로 취합 받아야 하는 경우')
      notice_type = 'image';
    else notice_type = 'check';

    try {
      const form = new FormData();
      form.append('store_uid', id);
      form.append('title', title);
      form.append('rich_contents', content);
      // Check if files is an array and has at least one file
      if (Array.isArray(files) && files.length > 0) {
        // Loop over the files array and append each file to the form
        files.forEach((file, index) => {
          form.append('files', file, file.name);
        });
      }

      form.append('notice_type', notice_type);

      if (
        directStoreData.stores === null &&
        noDirectStoreData.stores === null
      ) {
        form.append('store_list', JSON.stringify([]));
      } else {
        directStoreData.stores.forEach((store) => {
          form.append('store_list', store);
        });
        noDirectStoreData.stores.forEach((store) => {
          form.append('store_list', store);
        });
        //form.append('store_list', JSON.stringify(storeData.stores));
      }

      if (urls.length >= 1) {
        urls = urls.includes('https://') ? urls : `https://${urls}`;
        form.append('urls', urls);
      } else {
      }

      if (description_images.length) {
        description_images.forEach((image, index) => {
          form.append('images', image);
        });
      }

      // Create new notice
      setLoading(true);
      dispatch(createNewNoticeAsync(form))
        .unwrap()
        .then(() => {
          setLoading(false);
          dispatch(
            getNoticesByPageAsync({
              store_uid: currentStore.store_uid,
              page: 1,
            }),
          )
            .unwrap()
            .then((payload) => {
              dispatch(setMasterNoticeList(payload?.results));
            })
            .catch((err) => alert(err.message));

          navigate(-1);
        })
        .catch((e) => {
          setLoading(false);

          alert(t(e), '', [
            {
              text: t('buttons.confirm'),
              onPress: () => navigate(-1),
              style: 'cancel',
            },
          ]);
        });
    } catch (e) {
      console.error(e);
    }
  };

  const handleBackBtnClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onValidate = (value) => {
    const errors = {};
    if (!value.title) errors.title = '제목을 입력해주세요';
    if (!value.notice_type) errors.notice_type = '공지체크 방식을 선택해주세요';
    return errors;
  };

  return (
    <>
      <NoticeAddTemplate
        loading={loading}
        prevValue={state}
        store={currentStore}
        onSubmit={onSubmit}
        onValidate={onValidate}
        handleBackBtnClick={handleBackBtnClick}
        handleContentChange={handleContentChange}
      />
    </>
  );
};

export default NoticeAdd;
