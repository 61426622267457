import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';
import Search from './modal_components/Search';
import {MuiModal, MuiButton} from 'shared/components/index';
import theme from 'shared/styles/theme';
import {useDispatch, useSelector} from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  selectFranchiseUser,
  setSvStoreSelectModal,
} from 'franchise/utils/slices/frUserSlice';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const Wrapper = styled.div`
  display: block;
  width: 100vw;
  height: 100vh;
  border-radius: 5px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999 !important;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(18px);
`;

const ModalContainer = styled.div`
  width: 620px;
  min-width: 620px;
  height: 620px;
  min-width: 620px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 25px;
  border-radius: 5px;
`;

const StyledText = styled.p`
  font-size: ${(props) => (props.font ? props.font : '14px')};
  font-weight: ${(props) => (props.weight ? props.weight : 400)};
  white-space: ${(props) => (props.white_space ? props.white_space : 'normal')};
  color: ${(props) => (props.color ? props.color : '#252525')};
  line-height: 20px;
  margin: 12px 0 10px 0;
`;

const FlexBox = styled.div`
  width: 100%;
  height: ${(props) => (props.height ? props.height : '50px')};
  display: flex;
  justify-content: ${(props) =>
    props.justify ? props.justify : 'space-between'};
  align-items: center;
  margin: 12px 0;
`;

const ShopListWrapper = styled.div`
  width: 100%;
  height: ${(props) => (props.height ? props.height : '410px')};
  overflow-y: scroll;
  background-color: #f7f7f7;
  display: flex;
  align-content: flex-start;
  padding: 5px;
  margin-bottom: 10px;
  flex-wrap: wrap;
`;

const BtnWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 32px;
  justify-content: flex-end;
`;

const StoreName = styled.p`
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 3px;
  padding: 3px 8px;
  font-size: 13px;
  text-align: center;
  margin: 3px;
  width: 260px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: left;
  user-select: none;
  cursor: pointer;
`;
const BranchName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StoreSelectModal = ({handleSaveStoreList, storeLoading}) => {
  const dispatch = useDispatch();
  const {svStoreSelectModal, franchiseStoreList} =
    useSelector(selectFranchiseUser);
  const [directStoreData, setDirectStoreData] = useState({
    all: false,
    stores: franchiseStoreList
      ?.filter((each) => each?.is_in_charged && each?.is_direct === true)
      ?.map((each) => each.franchise_store_uid),
  });
  const [noDirectStoreData, setNoDirectStoreData] = useState({
    all: false,
    stores: franchiseStoreList
      ?.filter((each) => each?.is_in_charged && each?.is_direct === false)
      ?.map((each) => each.franchise_store_uid),
  });
  const [directGroup, setDirectGroup] = useState([]);
  const [noDirectGroup, setNoDirectGroup] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const handleModalClose = () => {
    dispatch(setSvStoreSelectModal(false));
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const direct = [];
    const noDirect = [];

    franchiseStoreList?.forEach((item) => {
      if (item.is_direct) {
        direct.push(item);
      } else {
        noDirect.push(item);
      }
    });

    const directStores = franchiseStoreList
      ?.filter((each) => each?.is_in_charged && each?.is_direct === true)
      ?.map((each) => each.franchise_store_uid);

    const noDirectStores = franchiseStoreList
      ?.filter((each) => each?.is_in_charged && each?.is_direct === false)
      ?.map((each) => each.franchise_store_uid);

    if (direct.length === directStores.length) {
      setDirectStoreData({
        all: true,
        stores: directStores,
      });
    } else {
      setDirectStoreData({
        all: false,
        stores: directStores,
      });
    }

    if (noDirect.length === noDirectStores.length) {
      setNoDirectStoreData({
        all: true,
        stores: noDirectStores,
      });
    } else {
      setNoDirectStoreData({
        all: false,
        stores: noDirectStores,
      });
    }

    setDirectGroup(direct);
    setNoDirectGroup(noDirect);
  }, [franchiseStoreList]);

  const onCheckBoxChange = (e) => {
    if (e.target.checked) {
      const direct_store_list = directGroup?.map(
        (eachstore) => eachstore.franchise_store_uid,
      );

      const nodirect_store_list = noDirectGroup?.map(
        (eachstore) => eachstore.franchise_store_uid,
      );

      setDirectStoreData({
        all: e.target.checked,
        stores: direct_store_list,
      });
      setNoDirectStoreData({
        all: e.target.checked,
        stores: nodirect_store_list,
      });
    } else {
      setDirectStoreData({
        all: e.target.checked,
        stores: [],
      });

      setNoDirectStoreData({
        all: e.target.checked,
        stores: [],
      });
    }
  };

  const onCheckBoxChange2 = (e) => {
    if (e.target.checked) {
      const direct_store_list = directGroup?.map(
        (eachstore) => eachstore.franchise_store_uid,
      );
      setDirectStoreData({
        all: e.target.checked,
        stores: direct_store_list,
      });
    } else {
      setDirectStoreData({
        all: e.target.checked,
        stores: [],
      });
    }
  };

  const onCheckBoxChange3 = (e) => {
    if (e.target.checked) {
      const nodirect_store_list = noDirectGroup?.map(
        (eachstore) => eachstore?.franchise_store_uid,
      );
      setNoDirectStoreData({
        all: e.target.checked,
        stores: nodirect_store_list,
      });
    } else {
      setNoDirectStoreData({
        all: e.target.checked,
        stores: [],
      });
    }
  };

  const handleCheckedStores = (e) => {
    const checked = e.target.value;

    if (e.target.checked) {
      if (!directStoreData?.stores?.includes(checked)) {
        setDirectStoreData({
          ...directStoreData,
          stores: [...directStoreData.stores, checked],
        });
      }
      if ([...directStoreData?.stores, checked].length === directGroup.length) {
        setDirectStoreData({
          all: true,
          stores: [...directStoreData?.stores, checked],
        });
      }
    } else {
      setDirectStoreData({
        ...directStoreData,
        stores: directStoreData?.stores?.filter(
          (store) => store?.indexOf(checked) === -1,
        ),
      });
      if ([...directStoreData?.stores, checked].length !== directGroup.length) {
        setDirectStoreData({
          all: false,
          stores: directStoreData?.stores?.filter(
            (store) => store?.indexOf(checked) === -1,
          ),
        });
      }
    }
  };

  const handleCheckedStores2 = (e) => {
    const checked = e.target.value;

    if (e.target.checked) {
      if (!noDirectStoreData?.stores?.includes(checked)) {
        setNoDirectStoreData({
          ...noDirectStoreData,
          stores: [...noDirectStoreData?.stores, checked],
        });
        if (
          [...noDirectStoreData?.stores, checked].length ===
          noDirectGroup.length
        ) {
          setNoDirectStoreData({
            all: true,
            stores: [...noDirectStoreData?.stores, checked],
          });
        }
      }
    } else {
      setNoDirectStoreData({
        ...noDirectStoreData,
        stores: noDirectStoreData?.stores?.filter(
          (store) => store?.indexOf(checked) === -1,
        ),
      });
      if (
        [...noDirectStoreData?.stores, checked].length !== noDirectGroup.length
      ) {
        setNoDirectStoreData({
          all: false,
          stores: noDirectStoreData?.stores?.filter(
            (store) => store?.indexOf(checked) === -1,
          ),
        });
      }
    }
  };

  return (
    <MuiModal open={svStoreSelectModal} onClose={handleModalClose}>
      <Wrapper>
        <ModalContainer>
          <StyledText font="22px" weight="bold">
            매장 검색하기
          </StyledText>
          {noDirectGroup.length !== 0 ? (
            <>
              <FlexBox>
                <Search value={searchTerm} onChange={handleChange} />
                <FormControlLabel
                  value="전체 매장 선택"
                  control={
                    <Checkbox
                      checked={directStoreData.all && noDirectStoreData.all}
                      onChange={onCheckBoxChange}
                      sx={{'&.Mui-checked': {color: theme.colors.kalsPurple}}}
                    />
                  }
                  label="전체 매장 선택"
                  labelPlacement="start"
                />
              </FlexBox>
              <FlexBox height="12px">
                <FormControlLabel
                  value="직영점 선택"
                  control={
                    <Checkbox
                      checked={directStoreData.all}
                      onChange={onCheckBoxChange2}
                      sx={{'&.Mui-checked': {color: theme.colors.kalsPurple}}}
                    />
                  }
                  label="직영점 선택"
                  labelPlacement="start"
                />
              </FlexBox>
              <ShopListWrapper height="175px">
                {storeLoading === true ? (
                  <Box
                    sx={{
                      display: 'flex',
                      height: '100%',
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  directGroup
                    ?.filter((eachStore) =>
                      eachStore.branch_name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()),
                    )
                    .map((eachStore) => {
                      return (
                        <StoreName key={eachStore.franchise_store_uid}>
                          <Checkbox
                            id={eachStore.branch_name}
                            value={eachStore.franchise_store_uid}
                            onChange={handleCheckedStores}
                            sx={{
                              '&.Mui-checked': {
                                color: `${theme.colors.kalsPurple}`,
                              },
                            }}
                            checked={directStoreData?.stores?.includes(
                              eachStore.franchise_store_uid,
                            )}
                          />
                          <BranchName>{eachStore.branch_name} </BranchName>
                        </StoreName>
                      );
                    })
                )}
              </ShopListWrapper>
              <FlexBox height="12px">
                <FormControlLabel
                  value="가맹점 선택"
                  control={
                    <Checkbox
                      checked={noDirectStoreData.all}
                      onChange={onCheckBoxChange3}
                      sx={{'&.Mui-checked': {color: theme.colors.kalsPurple}}}
                    />
                  }
                  label="가맹점 선택"
                  labelPlacement="start"
                />
              </FlexBox>
              <ShopListWrapper height="175px">
                {storeLoading === true ? (
                  <Box
                    sx={{
                      display: 'flex',
                      height: '100%',
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  noDirectGroup
                    ?.filter((eachStore) =>
                      eachStore.branch_name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()),
                    )
                    .map((eachStore) => {
                      return (
                        <StoreName key={eachStore.franchise_store_uid}>
                          <Checkbox
                            id={eachStore.branch_name}
                            value={eachStore.franchise_store_uid}
                            onChange={handleCheckedStores2}
                            sx={{
                              '&.Mui-checked': {
                                color: `${theme.colors.kalsPurple}`,
                              },
                            }}
                            checked={noDirectStoreData?.stores?.includes(
                              eachStore.franchise_store_uid,
                            )}
                          />
                          <BranchName>{eachStore.branch_name} </BranchName>
                        </StoreName>
                      );
                    })
                )}
              </ShopListWrapper>
            </>
          ) : (
            <>
              <FlexBox>
                <Search value={searchTerm} onChange={handleChange} />
              </FlexBox>
              <FlexBox height="12px">
                <FormControlLabel
                  value="직영점 선택"
                  control={
                    <Checkbox
                      checked={directStoreData.all}
                      onChange={onCheckBoxChange2}
                      sx={{'&.Mui-checked': {color: theme.colors.kalsPurple}}}
                    />
                  }
                  label="직영점 선택"
                  labelPlacement="start"
                />
              </FlexBox>
              <ShopListWrapper height="400px">
                {storeLoading === true ? (
                  <Box
                    sx={{
                      display: 'flex',
                      height: '100%',
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  directGroup
                    ?.filter((eachStore) =>
                      eachStore.branch_name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()),
                    )
                    .map((eachStore) => {
                      return (
                        <StoreName key={eachStore.franchise_store_uid}>
                          <Checkbox
                            id={eachStore.branch_name}
                            value={eachStore.franchise_store_uid}
                            onChange={handleCheckedStores}
                            sx={{
                              '&.Mui-checked': {
                                color: `${theme.colors.kalsPurple}`,
                              },
                            }}
                            checked={directStoreData?.stores?.includes(
                              eachStore.franchise_store_uid,
                            )}
                          />
                          <BranchName>{eachStore.branch_name} </BranchName>
                        </StoreName>
                      );
                    })
                )}
              </ShopListWrapper>
            </>
          )}

          <FlexBox justify="center">
            <BtnWrapper>
              <MuiButton
                textColor="white"
                backgroundColor={theme.colors.grey3}
                id="noticeAdd-goBack"
                text="취소"
                onClick={handleModalClose}
                type="submit"
                style={{marginRight: '20px'}}
              />
              <MuiButton
                textColor="white"
                backgroundColor="#8773d6"
                id="noticeAdd-goBack"
                text="저장"
                onClick={() =>
                  handleSaveStoreList(
                    directStoreData.stores,
                    noDirectStoreData.stores,
                  )
                }
                type="submit"
              />
            </BtnWrapper>
          </FlexBox>
        </ModalContainer>
      </Wrapper>
    </MuiModal>
  );
};

export default StoreSelectModal;
