import React, {useMemo} from 'react';
import styled from 'styled-components';
import theme from 'shared/styles/theme';
import {
  Drawer,
  MuiButton,
  Wrapper,
  Text,
  RichContentViewer,
} from 'shared/components/index';
import {getFormattedTime} from 'shared/utils/time';
import NoticeImageModal from './TaskImageModal';
import ImageRegularIcon from 'shared/assets/icons/ImageRegularIcon';
import {useSelector} from 'react-redux';
import {selectNotice} from 'owler/utils/slices/noticeSlice';

import DesktopIcon from 'shared/assets/icons/DesktopIcon';
import PhoneIcon from 'shared/assets/icons/PhoneIcon';

const Container = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  margin: -25px 0 0 30px;
  width: 1110px;
  padding: 0 7px;
  border-left: 1px solid ${theme.colors.grey9};
`;

const TopContainer = styled.div`
  flex-basis: 30%;
  display: flex;
  flex-direction: column;
  & > * {
    flex-basis: 33%;
  }
  margin-top: 75px;
`;

// const BottomContainer = styled.div`
//   flex-basis: 70%;
//   padding: 30px;
//   border-bottom: 1px solid #d5d5d5;
//   min-height: 400px;
//   width: 100%;
// `;

const Row = styled.div`
  height: 100%;
  display: flex;
  border-top: 1px solid #d5d5d5;
  :last-child {
    height: auto;
    border-bottom: 1px solid #d5d5d5;
  }
`;

const Box = styled.div`
  height: 61px;
  min-height: 61px;
  min-width: ${({width}) => width};
  flex: ${({width}) => !width && 1};
  align-items: center;
  display: flex;
  background-color: ${({backgroundColor}) => backgroundColor};
  color: ${({color}) => color};
  padding: 20px;
  cursor: ${({onClick}) => onClick && 'pointer'};
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
`;
const BoxThree = styled.div`
  width: 920px;
  height: 400px;
  background-color: ${({backgroundColor}) => backgroundColor};
  color: ${({color}) => color};
  padding: 20px;
  cursor: ${({onClick}) => onClick && 'pointer'};
  overflow: auto;
`;

const BoxTwo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  overflow: auto;
  background-color: ${({backgroundColor}) => backgroundColor};
  color: ${({color}) => color};
  cursor: ${({onClick}) => onClick && 'pointer'};
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Divider = styled.div`
  width: 50%;
  display: flex;
`;

const BtnContainer = styled.div`
  margin: 20px 0 50px 22px;
  display: flex;
  justify-content: space-between;
  width: 1110px;
`;

const Link = styled.a`
  :link,
  :visited,
  :-webkit-any-link {
    min-width: 300px;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: blue;
    text-decoration: blue;
  }
`;

const StyledImage = styled.img`
  width: 145px;
  height: 110px;

  object-fit: contain;
  background-color: #bdbdbd;
`;

const ImageWrapper = styled.div`
  opacity: 0.4;
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
  width: 145px;
  height: 110px;
`;

const NoticeDetailTemplate = ({
  currentNotice,
  handleEditBtnClick,
  handleDeleteBtnClick,
  handleListBtnClick,
  noticeImageModal,
  setNoticeImageModal,
}) => {
  const {selectedNotice} = useSelector(selectNotice);
  const formattedTime = useMemo(
    () =>
      `${`${currentNotice?.created_on?.split('T')[0]}   ${getFormattedTime(
        currentNotice?.created_on,
      )}`}`,
    [currentNotice?.created_on],
  );

  // const downloadFile = async (url, filename) => {

  //   const response = await fetch(url);

  //   if (!response.ok) {
  //     throw new Error('HTTP error ' + response.status);
  //   }

  //   const blob = await response.blob();
  //   const link = document.createElement('a');
  //   link.href = URL.createObjectURL(blob);
  //   link.download = filename;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const downloadFile = (url, filename) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const why = 'true';

  return (
    <>
      <Wrapper>
        <Drawer why={why}>
          <Container>
            {/* <Title>
              <Text fontSize="massive">{currentNotice?.title}</Text>
            </Title> */}
            <TopContainer>
              <Row>
                <Box width="180px" backgroundColor="#eff0fc" color="#252525">
                  <Text
                    style={{
                      width: '100%',
                      textAlign: 'center',
                    }}
                  >
                    제목
                  </Text>
                </Box>
                <Box style={{marginLeft: '20px', textAlign: 'left'}}>
                  {currentNotice?.title}
                </Box>
              </Row>
              <Row>
                <Divider>
                  <Box width="180px" backgroundColor="#eff0fc" color="#252525">
                    <Text
                      style={{
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      작성자
                    </Text>
                  </Box>

                  <Box style={{marginLeft: '20px', textAlign: 'left'}}>
                    {currentNotice?.creator}
                  </Box>

                  <Box>
                    <div>
                      {selectedNotice.hasOwnProperty('from_web') ? (
                        selectedNotice.from_web ? (
                          <DesktopIcon />
                        ) : (
                          <PhoneIcon />
                        )
                      ) : null}
                    </div>
                  </Box>
                </Divider>
                <Divider>
                  <Box width="180px" backgroundColor="#eff0fc" color="#252525">
                    <Text
                      style={{
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      작성일
                    </Text>
                  </Box>
                  <Box style={{marginLeft: '20px', textAlign: 'left'}}>
                    {formattedTime}
                  </Box>
                </Divider>
              </Row>
              <Row>
                <Divider>
                  <Box width="180px" backgroundColor="#eff0fc" color="#252525">
                    <Text
                      style={{
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      첨부 링크
                    </Text>
                  </Box>

                  <Box style={{marginLeft: '20px', textAlign: 'left'}}>
                    {currentNotice?.urls.length > 0 ? (
                      <Link href={currentNotice?.urls[0]}>
                        {currentNotice?.urls[0]}
                      </Link>
                    ) : (
                      <Text
                        style={{
                          fontSize: '13px',
                          color: '#bdbdbd',
                          width: '100%',
                          textAlign: 'left',
                        }}
                      >
                        등록된 첨부 링크가 없습니다
                      </Text>
                    )}
                  </Box>
                </Divider>
                <Divider>
                  <Box width="180px" backgroundColor="#eff0fc" color="#252525">
                    <Text
                      style={{
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      첨부 파일
                    </Text>
                  </Box>
                  <BoxTwo>
                    {currentNotice?.files.length > 0 ? (
                      currentNotice?.files.map((file, index) => (
                        <Link
                          key={index}
                          href={file?.file}
                          onClick={(event) => {
                            event.preventDefault();
                            downloadFile(file?.file, file?.file_name);
                          }}
                        >
                          {file?.file_name}
                        </Link>
                      ))
                    ) : (
                      <Text
                        style={{
                          fontSize: '13px',
                          color: '#bdbdbd',
                          width: '100%',
                          textAlign: 'left',
                        }}
                      >
                        등록된 첨부 파일이 없습니다
                      </Text>
                    )}
                  </BoxTwo>
                </Divider>
              </Row>
              <Row>
                <Box
                  width="180px"
                  style={{height: '122px'}}
                  backgroundColor="#eff0fc"
                  color="#252525"
                >
                  <Text
                    style={{
                      width: '100%',
                      textAlign: 'center',
                    }}
                  >
                    이미지
                  </Text>
                </Box>
                <Box
                  style={{
                    marginLeft: '20px',
                    textAlign: 'left',
                    height: '122px',
                  }}
                >
                  {currentNotice?.images.length > 0 ? (
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <ImageWrapper
                        style={{position: 'relative'}}
                        onClick={() => setNoticeImageModal(true)}
                      >
                        <StyledImage src={currentNotice?.images[0]} />
                        <div
                          style={{
                            position: 'relative',
                            top: '-75px',
                            left: '47px',
                            width: '50px',
                            height: '50px',
                          }}
                        >
                          <ImageRegularIcon fill="black" length={40} />
                        </div>
                      </ImageWrapper>
                    </div>
                  ) : (
                    <Text
                      style={{
                        fontSize: '13px',
                        color: '#bdbdbd',
                        width: '100%',
                        textAlign: 'left',
                      }}
                    >
                      등록된 이미지가 없습니다
                    </Text>
                  )}
                </Box>
              </Row>
              <Row>
                <Box
                  width="180px"
                  backgroundColor="#eff0fc"
                  color="#252525"
                  style={{height: '425px'}}
                >
                  <Text
                    style={{
                      width: '100%',
                      textAlign: 'center',
                    }}
                  >
                    내용
                  </Text>
                </Box>

                <BoxThree style={{}}>
                  {currentNotice?.rich_contents ? (
                    <div style={{}}>
                      <RichContentViewer
                        richContents={currentNotice?.rich_contents}
                      />
                    </div>
                  ) : (
                    <Text
                      style={{
                        width: '100%',
                        textAlign: 'left',
                        margin: '0 0 0 20px',
                        overflowY: 'auto',
                        height: '95%',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      {currentNotice?.contents ? (
                        currentNotice?.contents
                      ) : (
                        <div
                          style={{
                            alignItems: 'center',
                            display: 'flex',
                            height: '100%',
                          }}
                        >
                          <p
                            style={{
                              fontSize: '13px',
                              color: '#bdbdbd',
                            }}
                          >
                            작성된 내용이 없습니다
                          </p>
                        </div>
                      )}
                    </Text>
                  )}
                </BoxThree>
              </Row>
            </TopContainer>
            {/* <BottomContainer
              style={{whiteSpace: 'pre-line', overflowWrap: 'break-word'}}
            >
              {currentNotice?.contents}
              {currentNotice?.images.length > 0 && (
                <ImageWrapper
                  style={{position: 'relative'}}
                  onClick={() => setNoticeImageModal(true)}
                >
                  <StyledImage src={currentNotice?.images[0]} />
                  <div
                    style={{
                      position: 'relative',
                      top: '-178px',
                      left: '215px',
                      width: '100px',
                      height: '100px',
                    }}
                  >
                    <ImageRegularIcon fill="black" length={70} />
                  </div>
                </ImageWrapper>
              )}
            </BottomContainer> */}
          </Container>
          <NoticeImageModal
            noticeImageModal={noticeImageModal}
            setNoticeImageModal={setNoticeImageModal}
            images={currentNotice?.images}
          />
          <BtnContainer>
            <div></div>
            <div>
              <MuiButton
                backgroundColor="#bdbdbd"
                textColor="white"
                onClick={handleListBtnClick}
                text="취소"
                style={{margin: '0 10px 0 0'}}
              />

              <MuiButton
                backgroundColor="#FA5858"
                textColor="white"
                onClick={() => handleDeleteBtnClick(currentNotice)}
                text="삭제"
                style={{margin: '0 10px 0 0'}}
              />
              <MuiButton
                backgroundColor={theme.colors.kalsPurple}
                textColor="white"
                onClick={() => handleEditBtnClick(currentNotice)}
                text="편집"
              />
            </div>
          </BtnContainer>
        </Drawer>
      </Wrapper>
    </>
  );
};

export default NoticeDetailTemplate;
