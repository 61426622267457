import React from 'react';
import {Text, View, StyleSheet, Image, Font} from '@react-pdf/renderer';
import theme from 'shared/styles/theme';

Font.register({
  family: 'Nanum Gothic',
  src: 'https://fonts.gstatic.com/ea/nanumgothic/v5/NanumGothic-Regular.ttf',
});

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    padding: 15,
    paddingTop: 15,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    color: 'black',
    border: 1,
    height: '48px',
    textAlign: 'center',
    fontSize: 10,
    marginBottom: '20px',
  },
  logoWrapper: {
    width: '34%',
    color: 'black',
    borderRight: 1,
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    padding: '0 8px',
  },
  textWrapper: {
    width: '66%',
    display: 'flex',
    height: '100%',
  },
  textTop: {
    width: '100%',
    height: '50%',
    borderBottom: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  textBottom: {
    width: '100%',
    height: '50%',
    display: 'flex',
    flexDirection: 'row',
  },
  textDate: {
    width: '83.3333333333%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textTh: {
    width: '16.6666666667%',
    borderRight: 1,
    height: '100%',
    backgroundColor: '#dfd7f3',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textTd: {
    width: '16.6666666667%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    height: 40,
    width: 40,
    objectFit: 'cover',
    border: 0.1,
    borderRadius: 40,
  },
  text: {
    textAlign: 'center',
    verticalAlign: 'middle',
    fontSize: 9,
    fontFamily: 'Nanum Gothic',
    color: theme.colors.notBlack,
    fontWeight: 'thin',
  },
});

const PdfHeader = ({supplementionDetailPdf, start, end}) => {
  function getUniqueUrl(url) {
    const timestamp = new Date().getTime(); // 현재 시간의 타임스탬프
    // 이미 URL에 쿼리가 있는지 확인하고, 적절히 타임스탬프를 추가합니다.
    const separator = url?.includes('?') ? '&' : '?';
    return `${url}${separator}timestamp=${timestamp}`;
  }
  return (
    <View style={styles.header}>
      <View style={styles.logoWrapper}>
        <Image
          style={styles.image}
          source={{
            uri: getUniqueUrl(supplementionDetailPdf?.logo),
          }}
        />
        <Text
          style={[
            styles.text,
            {width: '125px', fontSize: 11, textAlign: 'left'},
          ]}
        >
          {supplementionDetailPdf?.corp_name}
        </Text>
      </View>
      <View style={styles.textWrapper}>
        <View style={styles.textTop}>
          <View style={styles.textTh}>
            <Text style={styles.text}>조회 기간</Text>
          </View>
          <View style={styles.textDate}>
            <Text style={styles.text}>
              {start} ~ {end}
            </Text>
          </View>
        </View>
        <View style={styles.textBottom}>
          <View style={styles.textTh}>
            <Text style={styles.text}>요청 건수</Text>
          </View>
          <View style={[styles.textTd, {borderRight: 1}]}>
            <Text style={styles.text}>
              {supplementionDetailPdf?.count?.request_count}
            </Text>
          </View>
          <View style={styles.textTh}>
            <Text style={styles.text}> 보완 건수 </Text>
          </View>
          <View style={[styles.textTd, {borderRight: 1}]}>
            <Text style={styles.text}>
              {supplementionDetailPdf?.count?.supplementation_count}
            </Text>
          </View>
          <View style={styles.textTh}>
            <Text style={styles.text}>집행률</Text>
          </View>
          <View style={[styles.textTd]}>
            <Text style={styles.text}>
              {supplementionDetailPdf?.count?.supplementation_rate}%
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default PdfHeader;
