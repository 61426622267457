import React from 'react';
import styled from 'styled-components';
import {State, OwlerRNHeader} from 'shared/components/index';
import theme from 'shared/styles/theme';
import giftImage from 'shared/assets/images/gift_box_icon.png';

const TitleArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DetailReview = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
  margin-top: 2px;
`;

const Participate = styled.div`
  background-color: #f2f2f2;
  padding: 10px;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
`;
const Memo = styled.div`
  background-color: #f2f2f2;
  padding: 1.2vh 3vw;
  border-radius: 2vh;
`;
const TextBody = styled.div``;
const Image = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 10px;
  object-fit: cover;
`;

const ProductDetail = ({goback, item}) => {
  // const renderRepetition = (rep) => {
  //   if (rep === 0) return '1회성';
  //   if (rep === 1) return '일';
  //   if (rep === 2) return '주';
  //   if (rep === 3) return '월';
  //   if (rep === 4) return '추첨';
  // };

  const renderDescription = (item) => {
    if (item.description !== null) {
      return item?.description?.split('\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ));
    } else {
      if (item.category === '[네이버 영수증 리뷰] 이벤트') {
        return `직원에게 영수증 수령 -> 네이버 영수증 리뷰 등록 -> 직원에게 확인`;
      } else if (item.category === '[인스타그램 리뷰] 이벤트') {
        return '인스타에 게시물 업로드(태그 필수!) -> 직원에게 확인';
      } else if (item.category === '[건의/불만/제안 작성] 이벤트') {
        return '아래 링크를 통해 아울러 [건의/불만/제안] 페이지로 이동\n -> 리뷰 작성 -> 직원에게 확인';
      } else {
        return '알아서 작성';
      }
    }
  };

  return (
    <DetailReview>
      <OwlerRNHeader title={item.category} marginLeft="2px" />

      <TitleArea>
        <OwlerRNHeader title="리워드" marginLeft="2px" />

        {item.status === '한정수량' ? (
          <State>{item.status}</State>
        ) : (
          <div style={{width: '60px', height: '20px'}} />
        )}
      </TitleArea>

      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '10px',
          marginTop: '8px',
          marginBottom: '20px',
        }}
      >
        <div
          style={{
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {item.image ? <Image src={item.image} /> : <Image src={giftImage} />}
        </div>

        <div
          style={{
            marginLeft: '12px',
          }}
        >
          <p
            style={{
              fontSize: 15,
              color: theme.colors.black,
              marginBottom: '5px',
            }}
          >
            {item.title}
          </p>
        </div>
      </div>

      <OwlerRNHeader title="참여 방법" marginLeft="2px" />

      <Participate>
        <TextBody>{renderDescription(item)}</TextBody>
      </Participate>

      {item.memo ? (
        <Memo>
          &#9679; 비고 <TextBody>{item.memo}</TextBody>
        </Memo>
      ) : null}
    </DetailReview>
  );
};

export default ProductDetail;
