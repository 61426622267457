import React, {useCallback, useEffect, useState} from 'react';
import StoreTemplate from './template';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {
  getUserStoresAsync,
  selectStore,
  setCurrentStore,
  setCorpCurrentStore,
} from 'owler/utils/slices/storeSlice';
import {setSelectedDateT} from 'owler/utils/slices/taskSlice';
import {selectUser} from 'owler/utils/slices/userSlice';
import {setCurrentNoticePage} from 'owler/utils/slices/noticeSlice';
import {setMember_uid} from 'owler/utils/slices/noticeSlice';

const StoreList = () => {
  const [name, setName] = useState('');
  const [debouncedName, setDebouncedName] = useState('');
  const [corpName, setCorpName] = useState('');
  const [debouncedCorpName, setDebouncedCorpName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const {userStores} = useSelector(selectStore);
  const {userInfo} = useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setMember_uid(null));
    dispatch(setSelectedDateT(new Date()));
    dispatch(setCurrentNoticePage(1));
    if (userInfo) {
      setIsLoading(true);
      dispatch(getUserStoresAsync(userInfo))
        .unwrap()
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [dispatch, userInfo]);

  const handleStoreClick = useCallback(
    (_store) => {
      dispatch(setCurrentStore(_store));
      if (_store.is_corporation) {
        dispatch(setCorpCurrentStore(_store));
        navigate(`/owler/store/${_store.store_uid}`);
      } else {
        navigate(`/owler/store/${_store.store_uid}`);
      }
    },
    [dispatch, navigate],
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedName(name);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [name]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedCorpName(corpName);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [corpName]);

  const handledNameChange = useCallback((e) => {
    setName(e.target.value);
  }, []);

  const handledCorpNameChange = useCallback((e) => {
    setCorpName(e.target.value);
  }, []);

  return (
    <StoreTemplate
      isLoading={isLoading}
      handleStoreClick={handleStoreClick}
      storeList={userStores}
      handledNameChange={handledNameChange}
      handledCorpNameChange={handledCorpNameChange}
      name={name}
      debouncedName={debouncedName}
      corpName={corpName}
      debouncedCorpName={debouncedCorpName}
    />
  );
};

export default StoreList;
