import React from 'react';
import styled from 'styled-components';
import {Text} from '../index';

const TouchableOpacity = styled.button`
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.grey5};
  padding: 8px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  cursor: pointer;
  border-radius: 8px;
  background-color: ${(props) =>
    props.notification.is_view === false ? '#E6E6E6' : '#FFFFFF'};
`;

const Title = styled(Text)`
  font-weight: ${(props) =>
    props.notification.is_view === false ? '800' : '300'};
  font-size: 16px;
  width: 500px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SubTitle = styled(Text)`
  font-weight: ${(props) =>
    props.notification.is_view === false ? '700' : '250'};
  font-size: 14px;
  margin: 5px 0;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Time = styled(Text)`
  font-size: 12px;
  color: ${(props) => props.theme.colors.mediumLight};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Notification = ({notification, onPress}) => {
  return (
    <TouchableOpacity onClick={onPress} notification={notification}>
      <Title notification={notification}>{notification?.contents}</Title>
      <SubTitle notification={notification}>
        {notification?.store_name}
      </SubTitle>
      <Time>
        {new Date(notification?.created_on).toLocaleDateString('en-US', {
          day: '2-digit',
          month: '2-digit',
          year: '2-digit',
        }) +
          ' ' +
          new Date(notification?.created_on).toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          })}
      </Time>
    </TouchableOpacity>
  );
};

export default Notification;
