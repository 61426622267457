import React, {useState, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {Text} from 'shared/components/index';
import theme from 'shared/styles/theme';
import styled, {createGlobalStyle} from 'styled-components';
import Slider from 'react-slick';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CarouselContainer = styled.div`
  width: 62%;
  height: 300px;
`;

const OneMoreWrap = styled.div`
  width: 155%;
  height: 450px;
  overflow: hidden;
  margin-left: 15px;
  margin-top: 0px;
`;

const CarouselItem = styled.div`
  width: 490px;
  height: 40px;
  margin: 10px 5px;
  cursor: pointer;
  align-items: center;
  display: flex;
  transition: transform 0.3s;
  justify-content: space-between;
  &:hover {
    transform: scale(1.01);
  }
  border: 1px solid ${theme.colors.grey9};
  border-radius: 8px;
`;
const TextCenter = styled.div`
  text-align: left;
  margin-left: 20px;
  display: flex;
  align-items: center;
`;
const StyledP = styled.p`
  font-size: 20px;
  margin-left: 50px;
`;
const GlobalStyle = createGlobalStyle`
  .slick-dots {
    display: flex;
    justify-content: center;
  }
  .slick-dots li {
    background: lightgray;
    border-radius: 50%;
    background: transparent;  /* 배경 투명하게 */
  }
  .slick-dots li.slick-active {  // 활성화된 dot에 대한 스타일
    background: transparent;  // 배경 투명하게
  }
`;
const ProgressBar = styled.div`
  height: 100%;
  width: ${({progress}) => progress}%;
  background-color: ${theme.colors.kalsPurple};
  border-radius: ${({progress}) => (progress < 5 ? '5px' : '3px')};
  position: relative;

  z-index: -1;
`;
const ProgressBarContainer = styled.div`
  width: 150px;
  height: 25px;
  border: 1px solid ${theme.colors.kalsPurple};
  position: relative;
  margin-right: 10px;
  border-radius: 5px;
`;

const PerformanceByStore = ({
  brandtaskStatusList,
  isStoreLoading,
  handleEachStoreBranchClick,
}) => {
  const dispatch = useDispatch();
  const sliderRef = React.useRef(null);

  const groupedPictures = useMemo(() => {
    const groups = [];
    for (let i = 0; i < brandtaskStatusList.length; i += 5) {
      groups.push(brandtaskStatusList.slice(i, i + 5));
    }
    return groups;
  }, [brandtaskStatusList]);

  if (!brandtaskStatusList.length && isStoreLoading === false) {
    return (
      <CarouselContainer>
        <GlobalStyle brandtaskStatusList={brandtaskStatusList} />
        <StyledP>하위 매장이 등록되지 않았습니다</StyledP>
      </CarouselContainer>
    );
  }
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    speed: 1500,
    lazyLoad: 'ondemand',
    autoplaySpeed: 5000,
  };

  return (
    <CarouselContainer>
      <GlobalStyle brandtaskStatusList={brandtaskStatusList?.length} />
      <OneMoreWrap>
        {isStoreLoading === true ? (
          <Box
            sx={{
              display: 'flex',
              width: '95%',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '100px',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Slider
            {...settings}
            key={brandtaskStatusList.length}
            ref={sliderRef}
            className="unique-slider" // Add this
          >
            {groupedPictures.map((group, index) => {
              return (
                <>
                  <div key={index}>
                    {group.map((item, index) => (
                      <CarouselItem
                        key={index}
                        onClick={() => handleEachStoreBranchClick(item)}
                      >
                        <TextCenter>
                          <Text margin="0px 0 0 0" style={{fontSize: '13px'}}>
                            {item?.branch_name}
                          </Text>
                        </TextCenter>

                        <ProgressBarContainer>
                          <ProgressBar progress={item?.status} />
                          <div
                            style={{
                              width: '100%',
                              justifyContent: 'center',
                              height: '100%',
                              alignItems: 'center',
                              display: 'flex',
                              position: 'relative',
                              top: '-23px',
                            }}
                          >
                            <Text
                              style={{fontSize: '13px'}}
                              margin="0 0 0 10px"
                            >
                              {Math.round(item?.status)}%
                            </Text>
                          </div>
                        </ProgressBarContainer>
                      </CarouselItem>
                    ))}
                  </div>
                </>
              );
            })}
          </Slider>
        )}
      </OneMoreWrap>
    </CarouselContainer>
  );
};

export default PerformanceByStore;
