import styled from 'styled-components';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import ProductDetail from './ProductDetail';
import theme from 'shared/styles/theme';
import AppText from 'shared/components/AppText';

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  >*{
    margin 0; 0;
  }
`;

const ReviewWrapper = styled.div`
  height: 80vh;
  width: 90vw;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  > * {
    margin-top: 3vh;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.75vh 3.5vw;
  height: ${(props) => props.height || ''};
  background: rgb(242, 242, 242);
  background: linear-gradient(
    90deg,
    rgba(242, 242, 242, 1) 0%,
    rgba(106, 120, 240, 0) 0%,
    rgba(157, 134, 253, 1) 100%
  );
`;

const Title = styled.div`
  font-size: 17px;
  font-weight: 500;
`;

const StyledButton = styled.button`
  margin-bottom: 5.5vh;
  margin-top: 1.5vh;
  width: 88vw;
  border-radius: 40px;
  min-height: 35px;
  background-color: #9d86fd;
  background-image: linear-gradient(
    to right,
    ${theme.colors.kalsPurple},
    ${theme.colors.kalsBlue}
  );
  color: #ffffff;
  font-weight: 500;
  border: none;
  line-height: 15px;
  &:hover {
    cursor: pointer;
    transform: scale(1.01);
  }
`;

const StyledArrowButton = styled.button`
  background: none;
  border: none;
  &:hover {
    cursor: pointer;
  }
`;

const StyledArrowIcon = styled((props) => (
  <ArrowBackIosIcon {...props} fontSize="small" />
))`
  margin-top: 5px;
`;

const StyledReplacer = styled.div`
  width: 24px;
  height: 24px;
`;

const EventDetailTemplate = ({goback, item, gotoLink, buttonName, item2}) => {
  return (
    <Container>
      <LogoWrapper>
        <StyledArrowButton onClick={() => goback()}>
          <StyledArrowIcon />
        </StyledArrowButton>

        <AppText text="이벤트 상세" fontSize="18px" />

        <StyledReplacer />
      </LogoWrapper>

      <ReviewWrapper>
        <ProductDetail item={item} />
      </ReviewWrapper>

      <div
        style={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          backgroundColor: '#fff',
          display: 'flex', // Add this
          justifyContent: 'center', // Add this
          alignItems: 'center', // Add this
        }}
      >
        {item2.is_close === true ? (
          <Title style={{color: '#ff0000', marginBottom: '3vh'}}>
            오늘의 이벤트가 종료되었습니다.
          </Title>
        ) : (
          <StyledButton onClick={() => gotoLink(item.url)}>참여</StyledButton>
        )}
      </div>
    </Container>
  );
};

export default EventDetailTemplate;
