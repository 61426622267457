import React, {useState, useEffect, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {
  setEvents,
  selectCalendar,
  postCalendarScheduleAsync,
  getCalendarAsync,
  deleteCalendarScheduleAsync,
  setEventDetailModal,
  setEventAddModal,
} from 'owler/utils/slices/calendarSlice';
import CalendarTemplate from './template/index';
import {setCurrentStore} from 'owler/utils/slices/storeSlice';
import {selectStore} from 'owler/utils/slices/storeSlice';
import {useSelector} from 'react-redux';
import {
  getNoticesByPageAsync,
  selectNotice,
  setMasterNoticeList,
  setNoticePageCount,
} from 'owler/utils/slices/noticeSlice';

const Calendar = () => {
  const dispatch = useDispatch();
  const {currentStore} = useSelector(selectStore);
  const {masterNoticeList, currentNoticePage, member_uid} =
    useSelector(selectNotice);
  const {directstoreData, nodirectstoreData, extraNoticeList} =
    useSelector(selectCalendar);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [load, setLoad] = useState(false);

  useEffect(() => {
    setLoad(true);
    if (currentStore !== null) {
      const year = selectedDate.getFullYear();
      const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0'); // 월을 0으로 패딩
      const day = selectedDate.getDate().toString().padStart(2, '0'); // 일을 0으로 패딩
      const date = `${year}-${month}-${day}`;

      dispatch(
        getCalendarAsync({
          store_uid: currentStore.store_uid,
          date: date,
        }),
      )
        .unwrap()
        .then((payload) => {
          dispatch(setEvents(payload));
          setLoad(false);
        })
        .catch((err) => {
          alert(err.message);
        });
      dispatch(
        getNoticesByPageAsync({
          store_uid: currentStore.store_uid,
          page: currentNoticePage,
        }),
      )
        .unwrap()
        .then((payload) => {
          dispatch(setNoticePageCount(payload?.count));
          dispatch(setMasterNoticeList(payload?.results));
        })
        .catch((err) => {
          alert(err.message);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStore, selectedDate, currentNoticePage]);

  const toKST = (date) => {
    // KST는 UTC +9입니다. 따라서 offset은 540분입니다.
    const offset = 540 - date.getTimezoneOffset();
    return new Date(date.getTime() + offset * 30000);
  };

  const reload = useCallback(() => {
    const year = selectedDate.getFullYear();
    const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0'); // 월을 0으로 패딩
    const day = selectedDate.getDate().toString().padStart(2, '0'); // 일을 0으로 패딩
    const date = `${year}-${month}-${day}`;

    dispatch(
      getCalendarAsync({
        store_uid: currentStore.store_uid,
        date: date,
      }),
    )
      .unwrap()
      .then((payload) => {
        dispatch(setEvents(payload));
      })
      .catch((err) => {
        alert(err.message);
      });
  }, [selectedDate, dispatch, currentStore]);

  const handleEventSubmit = useCallback(
    (value) => {
      let {title, start, end, contents, color} = value;

      dispatch(
        postCalendarScheduleAsync({
          store_uid: currentStore.store_uid,
          title: title,
          start: toKST(start),
          end: toKST(end),
          contents: contents,
          color: color,
          franchise_store_list:
            currentStore.is_hq === true
              ? [...directstoreData.stores, ...nodirectstoreData.stores]
              : [],
          notice_list: extraNoticeList.map((item) => item.notice_uid),
        }),
      )
        .unwrap()
        .then(() => {
          alert('생성되었습니다.');
          dispatch(setEventAddModal(false));
          reload();
        })
        .catch((err) => {
          alert(err.message);
        });
    },
    [
      directstoreData,
      nodirectstoreData,
      extraNoticeList,
      currentStore,
      dispatch,
      reload,
    ],
  );

  const deleteEvent = useCallback(
    (event) => {
      dispatch(
        deleteCalendarScheduleAsync({
          schedule_uid: event.schedule_uid,
          member_uid: member_uid.member_uid,
        }),
      )
        .unwrap()
        .then(() => {
          alert('삭제되었습니다.');
          dispatch(setEventDetailModal(false));
          reload();
        })
        .catch((err) => {
          alert(err.message);
        });
    },
    [dispatch, member_uid, reload],
  );

  const handleStoreClick = useCallback(
    (_store) => {
      dispatch(setCurrentStore(_store));
    },
    [dispatch],
  );

  return (
    <CalendarTemplate
      handleStoreClick={handleStoreClick}
      currentStore={currentStore}
      masterNoticeList={masterNoticeList}
      handleEventSubmit={handleEventSubmit}
      deleteEvent={deleteEvent}
      setSelectedDate={setSelectedDate}
      load={load}
      member_uid={member_uid.member_uid}
      reload={reload}
    />
  );
};

export default Calendar;
