/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';
import {Text, View, StyleSheet, Font} from '@react-pdf/renderer';
import theme from 'shared/styles/theme';

Font.register({
  family: 'Nanum Gothic',
  src: 'https://fonts.gstatic.com/ea/nanumgothic/v5/NanumGothic-ExtraBold.ttf',
});

const styles = StyleSheet.create({
  wrap: {
    marginTop: 5,
  },
  table: {
    display: 'table',
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableRow: {
    flexDirection: 'row',
    border: 0.5,
    borderColor: theme.colors.notBlack,
  },
  headerTableRow: {
    flexDirection: 'row',
  },
  tableCell: {
    width: '10%',
    padding: 5,
    border: 0.5,
    borderColor: theme.colors.notBlack,
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  tableCell2: {
    width: '10%',
    padding: 5,
    textAlign: 'center',
    verticalAlign: 'middle',
    borderTop: 0.5,
    borderBottom: 0.5,
    borderColor: theme.colors.notBlack,
  },
  halfTableCell: {
    width: '50%',
    padding: 5,
    border: 0.5,
    borderColor: theme.colors.notBlack,
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  halfTableCell1: {
    width: '50%',
    padding: 5,
    borderColor: theme.colors.notBlack,
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  headerLastTableCell: {
    width: '40%',
    textAlign: 'center',
    verticalAlign: 'middle',
    flexDirection: 'row',
  },
  lastTableCell: {
    width: '20%',
    padding: 5,
    textAlign: 'center',
    verticalAlign: 'middle',
    backgroundColor: theme.colors.secondaryLight,
  },
  text: {
    verticalAlign: 'middle',
    fontSize: 9,
    fontFamily: 'Nanum Gothic',
    color: theme.colors.notBlack,
    fontWeight: 'thin',
  },
  descriptionCell: {
    width: '15%',
    padding: 5,
    borderRightWidth: 1,
    borderColor: theme.colors.notBlack,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  totalScoreCell: {
    width: '20%',
    padding: 5,
    borderRightWidth: 0.5,
    borderColor: theme.colors.notBlack,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerDynamicCell: {
    width: '85%',
  },
  dynamicCell: {
    width: '85%',
    minHeight: 30,
    border: 0.5,
    borderColor: theme.colors.notBlack,
  },
  dynamicCell2: {
    width: '80%',
    minHeight: 30,
    border: 0.5,
    borderColor: theme.colors.notBlack,
  },
  itemCell: {
    border: 0.5,
    borderColor: theme.colors.notBlack,
    padding: 5,
    marginBottom: 2,
  },
});

const QscScoreTable = ({score, scoreTitleList}) => (
  <View style={styles.wrap}>
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View
          style={[
            styles.descriptionCell,
            {borderRight: 0.1},
            {backgroundColor: theme.colors.secondaryLight},
          ]}
        >
          <Text style={styles.text}>점수</Text>
        </View>

        <View style={styles.headerDynamicCell}>
          <View style={styles.headerTableRow}>
            <View style={[styles.tableCell, {width: '30%'}]}>
              <Text style={styles.text}>분류</Text>
            </View>

            {scoreTitleList?.map((scoreTitle, _indx) => (
              <View key={_indx.toString()} style={styles.tableCell2}>
                {_indx === Math.floor(scoreTitleList.length / 2) && (
                  <Text style={styles.text}>비율</Text>
                )}
              </View>
            ))}

            <View style={[styles.headerLastTableCell]}>
              <View style={[styles.halfTableCell]}>
                <Text style={styles.text}>분류 점수</Text>
              </View>

              <View style={[styles.halfTableCell]}>
                <Text style={styles.text}>총점</Text>
              </View>
            </View>
          </View>

          {score?.category_list?.map((item, indx) => (
            <View
              key={indx.toString()}
              style={[styles.headerTableRow, {borderLeft: 0}]}
            >
              <View style={[styles.tableCell, {width: '30%'}]}>
                <Text style={styles.text}>{item?.name}</Text>
              </View>

              {scoreTitleList?.map((scoreTitle, indxx) => (
                <View key={indxx.toString()} style={styles.tableCell}>
                  <Text style={styles.text}>
                    {item?.value_percent_list[indxx]}%
                  </Text>
                </View>
              ))}

              <View style={[styles.headerLastTableCell]}>
                <View style={[styles.halfTableCell]}>
                  <Text style={styles.text}>{item?.score}</Text>
                </View>

                <View style={[styles.halfTableCell1]}>
                  {indx === Math.floor(score?.category_list.length / 2) && (
                    <Text style={styles.text}>{score?.total_score}</Text>
                  )}
                </View>
              </View>
            </View>
          ))}

          <View style={styles.headerTableRow}>
            <View style={[styles.tableCell, {width: '30%'}]}>
              <Text style={styles.text}>{score?.overall_value?.name}</Text>
            </View>

            {scoreTitleList?.map((scoreTitle, indxx) => (
              <View key={indxx.toString()} style={[styles.tableCell]}>
                <Text style={styles.text}>
                  {score?.overall_value?.value_percent_list[indxx]}%
                </Text>
              </View>
            ))}

            <View style={[styles.headerLastTableCell]}>
              <View style={[styles.halfTableCell]}>
                <Text style={styles.text}>-</Text>
              </View>

              <View style={[styles.halfTableCell1]}>
                <Text style={styles.text} />
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  </View>
);

export default QscScoreTable;
