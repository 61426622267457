/* eslint-disable react/require-default-props */
import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import theme from 'shared/styles/theme';

const MuiRadio = ({
  formLabel,
  labels,
  name,
  value,
  handleChange,
  row,
  handleRadioClick = () => {},
}) => {
  const onClick = (event) => {
    handleRadioClick(event.target.value);
  };
  return (
    <div>
      <FormLabel style={{fontSize: '18px', color: 'black', fontWeight: '600'}}>
        {formLabel}
      </FormLabel>

      <RadioGroup name={name} value={value} onChange={handleChange} row={row}>
        {labels.map((label, i) => (
          <FormControlLabel
            key={label + i.toString()}
            label={label}
            value={label}
            control={<Radio onClick={onClick} />}
          />
        ))}
      </RadioGroup>
    </div>
  );
};

export default MuiRadio;
