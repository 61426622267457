import React from 'react';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {MuiButton, MuiModal, Text} from 'shared/components/index';
import {toast} from 'react-hot-toast';
import {
  deleteCategoryAsync,
  getStoreTasksByDateAsync,
  selectTask,
  setCategoryDeleteModal,
} from 'owler/utils/slices/taskSlice';
import theme from 'shared/styles/theme';
import {selectStore} from 'owler/utils/slices/storeSlice';
import {formatDateRangeForApi} from 'shared/utils/time';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 320px;
  height: 200px;
  padding: 40px;
  font-size: 13px;

  & > * {
  }
`;

const TitleWrapper = styled.div`
  margin: 0 0 10px 0;
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CategoryDeleteModal = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {currentStore} = useSelector(selectStore);
  const {categoryDeleteModal, selectedCategory} = useSelector(selectTask);

  const handleDeleteBtn = () => {
    dispatch(
      deleteCategoryAsync({
        task_category_uid: selectedCategory?.task_category_uid,
      }),
    )
      .unwrap()
      .then(() => {
        toast.success(t('alerts.category_deleted_success'));
        const selectedDate = new Date();
        const year = selectedDate.getFullYear();
        const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0'); // 월을 0으로 패딩
        const day = selectedDate.getDate().toString().padStart(2, '0'); // 일을 0으로 패딩
        const date = `${year}-${month}-${day}`;
        dispatch(
          getStoreTasksByDateAsync({
            store_uid: currentStore?.store_uid,
            date,
          }),
        );
        dispatch(setCategoryDeleteModal(false));
      })
      .catch((error) => alert(error.message));
  };

  const handleModalClose = () => {
    dispatch(setCategoryDeleteModal(false));
  };

  return (
    <MuiModal open={categoryDeleteModal} onClose={handleModalClose}>
      <Container>
        <TitleWrapper>
          <Text bold fontSize="xl">
            해당 카테고리를 정말 삭제하시겠습니까?
            <br />
            <br /> 카테고리에 포함된 업무도 함께 삭제됩니다.
          </Text>
        </TitleWrapper>

        <ButtonContainer>
          <div />
          <div>
            <MuiButton
              onClick={handleModalClose}
              variant="contained"
              backgroundColor={theme.colors.grey}
              text={t('buttons.cancel')}
              style={{margin: '0 10px 0 0'}}
            />

            <MuiButton
              onClick={handleDeleteBtn}
              variant="contained"
              backgroundColor={theme.colors.danger}
              text={t('buttons.confirm')}
            />
          </div>
        </ButtonContainer>
      </Container>
    </MuiModal>
  );
};
export default CategoryDeleteModal;
