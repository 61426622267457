/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import HomeTemplates from './template/index';
import {setAppType} from 'shared/utils/redux/appSlice';

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleStartBtn = useCallback(userType => {
    dispatch(setAppType(userType));
    navigate('/phoneLogin');
  }, []);

  return <HomeTemplates handleStartBtn={handleStartBtn} />;
};

export default Home;
