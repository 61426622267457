import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import {Drawer} from 'shared/components/index';
import {Wrapper} from 'shared/components/index';
import {selectStore} from 'owler/utils/slices/storeSlice';
import {
  deleteUserChatAsync,
  getChatMessagesAsync,
  getChatRoomAsync,
  pinChatRoomAsync,
  postImageFileToChatAsync,
  setAddChatRoomModal,
} from 'owler/utils/slices/chattingSlice';
import Chat from './template/index';
import AddChatRoomModal from './template/AddChatRoomModal';
import ActionsModal from './template/ChatActionsModal';
import MediaUploadModal from './template/MediaUploadModal';

const Container = styled.div`
  width: 1080px;
  height: 45vh;
  margin-left: 60px;
`;

const PAGE_SIZE = 10; // Number of items to fetch per page

const Chatting = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const webSocket = useRef(null);
  const topMessageRef = useRef(null);
  const bottomListRef = useRef(null);
  const {currentStore, memberInfo} = useSelector(selectStore);

  const [loading, setLoading] = useState(true);
  const [chatRoomList, setChatRoomList] = useState([]);
  const [filteredChatRoomList, setFilteredChatRoomList] = useState([]);
  const [serverMessages, setServerMessages] = useState([]);
  const [socketMessages, setSocketMessages] = useState([]);

  const [tabValue, setTabValue] = useState(0);
  const [chatData, setChatData] = useState(null);
  const [taskImageModal, setTaskImageModal] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [uploadMediaModal, setUploadMediaModal] = useState(false);
  const [headerActionsModal, setHeaderActionsModal] = useState(false);
  const [isEndReached, setIsEndReached] = useState(false);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const loadChatRooms = () => {
      if (memberInfo) {
        setLoading(true);
        dispatch(getChatRoomAsync({member_uid: memberInfo?.member_uid}))
          .unwrap()
          .then((payload) => {
            setChatRoomList(payload);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      }
    };

    loadChatRooms();
  }, [dispatch, memberInfo]);

  useEffect(() => {
    if (chatRoomList?.length) {
      setFilteredChatRoomList(chatRoomList);
    } else {
      setFilteredChatRoomList([]);
    }
  }, [chatRoomList]);

  const loadChatRooms = useCallback(() => {
    if (memberInfo) {
      setLoading(true);
      dispatch(getChatRoomAsync({member_uid: memberInfo?.member_uid}))
        .unwrap()
        .then((payload) => {
          setChatRoomList(payload);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [dispatch, memberInfo]);

  const fetchChatMessages = async (chatData, _pageNum, member_uid) => {
    if (chatData === null) return;

    try {
      setLoading(true);
      dispatch(
        getChatMessagesAsync({
          chatting_uid: chatData?.chatting_uid,
          member_uid: member_uid,
          pageNum: _pageNum,
        }),
      )
        .unwrap()
        .then((payload) => {
          // Check if there is data for the next page
          setIsEndReached(payload?.results?.length < PAGE_SIZE);

          setServerMessages((prevMessages) =>
            _pageNum === 1
              ? [...payload?.results?.reverse()]
              : [...prevMessages, ...payload?.results?.reverse()],
          );

          setLoading(false);
        })
        .catch((error) => {
          if (error === '404') {
            setLoading(false);
          } else {
            alert(error);
          }
        });
    } catch (error) {
      setLoading(false);
    }
  };

  // websocket function
  const initiateSocketConnection = (_chatData, _memberInfo) => {
    // Live server 1
    // webSocket.current = new WebSocket(
    //   `wss://owler-dev1.kr/ws/chatting/${chatData.chatting_uid}/${memberInfo.member_uid}/`,
    // );

    // Live server 2 / version 6.6.1
    webSocket.current = new WebSocket(
      `wss://corp-via.com/ws/chatting/${_chatData?.chatting_uid}/${_memberInfo.member_uid}`,
    );

    webSocket.current.onopen = (e) => {
      console.error('Socket opened:', e);
    };

    webSocket.current.onclose = (e) => {
      console.error('Socket closed:', e);
    };

    webSocket.current.onerror = (e) => {
      console.error('Error:', e);
    };

    webSocket.current.onmessage = (event) => {
      const chatMessageDto = JSON.parse(event?.data);
      setSocketMessages((prevMessages) => [...prevMessages, chatMessageDto]);
    };
  };

  // Close websocket and disconnect
  const closeWebSocket = async () => {
    if (webSocket?.current) {
      await webSocket?.current?.close();
      webSocket.current = null;
    }
    return;
  };

  const handleChatRoomPress = async (chatData) => {
    try {
      await closeWebSocket();
      setSocketMessages([]);
      setServerMessages([]);

      // Introduce a delay before initializing the socket and fetching messages
      setTimeout(() => {
        setChatData(chatData);

        initiateSocketConnection(chatData, memberInfo);
        fetchChatMessages(chatData, 1, memberInfo?.member_uid);
      }, 500);
    } catch (error) {
      alert(error);
    }
  };

  const handleAddChatRoom = () => {
    dispatch(setAddChatRoomModal(true));
  };

  // Send message
  const submitMessage = (values, actions) => {
    if ((chatData, values.message)) {
      webSocket.current.send(
        JSON.stringify({
          member_uid: memberInfo.member_uid,
          message_type: 'message',
          message: values.message,
        }),
      );

      actions.resetForm();
    }
  };

  const handleChatRoomPin = (data) => {
    dispatch(
      pinChatRoomAsync({
        chatting_uid: data?.chatting_uid,
        member_uid: memberInfo?.member_uid,
      }),
    )
      .unwrap()
      .then((payload) => {
        alert(payload.kr);
      })
      .catch((error) => {
        alert(error);
      });

    setHeaderActionsModal(false);
  };

  const handleMediaSubmit = (values, {resetForm}) => {
    if (values.description_images.length || values.files.length) {
      const form = new FormData();

      form.append('member_uid', memberInfo.member_uid);
      form.append('chatting_uid', chatData?.chatting_uid);

      if (values.description_images.length) {
        form.append('message_type', 'image');
      } else {
        form.append('message_type', 'file');
      }

      form.append('message', null);

      if (tabValue === 0) {
        if (values.description_images.length) {
          values.description_images.forEach((image, index) => {
            form.append('images', image);
          });
        }
      } else {
        if (Array.isArray(values.files) && values.files.length > 0) {
          values.files.forEach((file, index) => {
            form.append('files', file, file.name);
          });
        }
      }

      dispatch(postImageFileToChatAsync(form))
        .unwrap()
        .then(({en, kr}) => {
          alert(kr);

          setUploadMediaModal(false);
        })
        .catch((error) => {
          alert(error);
        });
    }

    resetForm();
  };

  const handleReportMessage = () => {
    alert(t('alerts.report_this_message'), '', [
      {
        text: t('buttons.cancel'),
        style: 'cancel',
      },
      {
        text: t('buttons.report'),
        style: 'destructive',
        onPress: () =>
          setTimeout(() => alert(t('alerts.report_received')), 1000),
      },
    ]);
  };

  const handleDeleteChatRoom = async (_chatData) => {
    try {
      await closeWebSocket();
    } catch (error) {
      // Handle any errors that occurred while closing the WebSocket
      console.error('Error closing WebSocket:', error);
    } finally {
      dispatch(
        deleteUserChatAsync({
          member_uid: memberInfo?.member_uid,
          chatting_uid: _chatData?.chatting_uid,
        }),
      )
        .unwrap()
        .then(({en, kr}) => {
          alert(kr);

          loadChatRooms();
          setChatData(null);
        })
        .catch((error) => {
          alert(error);
        });
    }
  };

  const handleChatActions = () => {
    setHeaderActionsModal(true);
  };

  const combinedMessages = useMemo(() => {
    return [...serverMessages.reverse(), ...socketMessages];
  }, [serverMessages, socketMessages]);

  // Scroll to the bottom every time chatDataList changes
  useEffect(() => {
    if (bottomListRef.current) {
      bottomListRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }, [combinedMessages]);

  const handleScroll = async () => {
    if (topMessageRef.current.scrollTop === 0) {
      if (!loading && !isEndReached) {
        const pageNum = Math.ceil(serverMessages.length / PAGE_SIZE) + 1;
        await fetchChatMessages(chatData, pageNum, memberInfo?.member_uid);
      }
    }
  };

  const searchFilter = (text) => {
    if (text) {
      const newData = chatRoomList?.filter((_member) => {
        const filterData = _member.title
          ? _member.title.toUpperCase()
          : ''.toUpperCase();

        const textData = text.toUpperCase();
        return filterData.indexOf(textData) > -1;
      });
      setFilteredChatRoomList(newData);
      setSearch(text);
    } else {
      setFilteredChatRoomList(chatRoomList);
      setSearch(text);
    }
  };

  const handleMedialValidate = (value) => {};

  return (
    <Wrapper titleText={currentStore?.store_name}>
      <Drawer>
        <Container>
          <Chat
            chatData={chatData}
            loading={loading}
            chatRoomList={chatRoomList}
            filteredChatRoomList={filteredChatRoomList}
            chatDataList={combinedMessages}
            handleChatRoomPress={handleChatRoomPress}
            handleAddChat={handleAddChatRoom}
            onSubmit={submitMessage}
            handleChatActions={handleChatActions}
            topMessageRef={topMessageRef}
            messagesEndRef={bottomListRef}
            handleScroll={handleScroll}
            setUploadMediaModal={setUploadMediaModal}
            setTaskImageModal={setTaskImageModal}
            taskImageModal={taskImageModal}
            selectedMessage={selectedMessage}
            setSelectedMessage={setSelectedMessage}
            search={search}
            searchFilter={searchFilter}
          />
        </Container>
      </Drawer>

      <AddChatRoomModal loadChatRooms={loadChatRooms} />

      <ActionsModal
        headerActionsModal={headerActionsModal}
        setHeaderActionsModal={setHeaderActionsModal}
        chatData={chatData}
        handleChatRoomPin={handleChatRoomPin}
        handleReportMessage={handleReportMessage}
        handleDeleteChatRoom={handleDeleteChatRoom}
      />

      <MediaUploadModal
        onSubmit={handleMediaSubmit}
        onValidate={handleMedialValidate}
        uploadMediaModal={uploadMediaModal}
        setUploadMediaModal={setUploadMediaModal}
        tabValue={tabValue}
        setTabValue={setTabValue}
      />
    </Wrapper>
  );
};

export default Chatting;
