import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledLabel = styled.label`
  font-size: ${({ fontSize, theme }) => theme.fontSizes[fontSize]};
`;

const Label = (props) => {
  const { htmlFor, fontSize, children } = props;
  return (
    <StyledLabel fontSize={fontSize} htmlFor={htmlFor}>
      {children}
    </StyledLabel>
  );
};

Label.propTypes = {
  htmlFor: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  fontSize: PropTypes.string,
};

Label.defaultProps = {
  fontSize: 'base',
};

export default Label;
