/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';
import {Text, View, StyleSheet, Font} from '@react-pdf/renderer';
import theme from 'shared/styles/theme';

Font.register({
  family: 'Nanum Gothic',
  src: 'https://fonts.gstatic.com/ea/nanumgothic/v5/NanumGothic-ExtraBold.ttf',
});

const styles = StyleSheet.create({
  table: {
    display: 'table',
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: 5,
  },
  tableRow: {
    flexDirection: 'row',

    borderColor: theme.colors.notBlack,
  },
  descriptionCell: {
    width: '15%',
    padding: 5,
    border: 0.7,
    borderColor: theme.colors.notBlack,
    backgroundColor: theme.colors.secondaryLight,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dynamicCell: {
    width: '85%',
    padding: 5,
    minHeight: 30,
    border: 0.7,
    borderColor: theme.colors.notBlack,
  },
  itemCell: {
    borderColor: theme.colors.notBlack,
    padding: 5,
    marginBottom: 2,
  },
  text: {
    verticalAlign: 'middle',
    fontSize: 9,
    fontFamily: 'Nanum Gothic',
    color: theme.colors.notBlack,
  },
});

const QscMemoTable = ({memoList}) => {
  return (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View
          style={[
            styles.descriptionCell,
            {
              borderRight: 0,
            },
          ]}
        >
          <Text style={styles.text}>메모</Text>
        </View>
        <View style={styles.dynamicCell}>
          {memoList?.map((item, index) => {
            return (
              <View key={index} style={styles.itemCell}>
                <Text style={styles.text}>{item}</Text>
              </View>
            );
          })}
        </View>
      </View>
    </View>
  );
};

export default QscMemoTable;
