import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Select2} from 'shared/components/index';
import {Button} from 'shared/components/index';
import {useForm} from 'react-hook-form';

const HeaderArea = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  align-items: center;
`;
const PdfButton = styled.button`
  background-color: #8773d6;
  color: #ffffff;
  border: none;
  padding: 0 10px;
  font-size: 15px;
  height: 30px;
  border-radius: 6px;
  &:hover {
    background-color: #6650c0;
  }
`;
const Area = styled.div`
  display: flex;
  justidy-content: center;
  align-items: center;
`;

const SearchArea = styled.div`
  display: flex;
  flex-direction: flex-start;
  align-items: center;

  > * {
    margin: 0 10px 0 0;
  }
`;

const SearchForm = ({search, handlePdfButtonClick, storeMemberList}) => {
  const {register, setValue, handleSubmit} = useForm();
  const currentYear = new Date().getFullYear();
  const years = Array.from({length: currentYear - 2019}, (v, k) => 2020 + k);
  const thisMonth = new Date().getMonth();

  useEffect(() => {
    if (storeMemberList && storeMemberList.length > 0) {
      setValue('member_uid', storeMemberList[0].member_uid);
    }
  }, [storeMemberList, setValue]);

  const onSubmit = (data) => {
    search(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <HeaderArea>
        <SearchArea>
          <p style={{fontSize: '20px'}}>조회하기</p>
          <Area>
            <Select2
              width="70px"
              style={{margin: '0 5px 0 0'}}
              {...register('year')}
            >
              {years.reverse().map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </Select2>
            <p>년</p>
          </Area>
          <Area>
            <Select2
              width="55px"
              style={{margin: '0 5px 0 0'}}
              {...register('month')}
            >
              {[...Array(12).keys()].map((num) => {
                if (thisMonth === num) {
                  return (
                    <option key={num} value={num + 1} selected>
                      {num + 1}
                    </option>
                  );
                }

                return (
                  <option key={num} value={num + 1}>
                    {num + 1}
                  </option>
                );
              })}
            </Select2>
            <p>월&nbsp;&nbsp;&nbsp;&nbsp;</p>
          </Area>
          <Area>
            <p>직원:&nbsp;</p>
            <Select2
              width="120px"
              style={{margin: '0 5px 0 0'}}
              {...register('member_uid')}
            >
              {storeMemberList?.map((member) => {
                return (
                  <option key={member.member_uid} value={member.member_uid}>
                    {member.name}
                  </option>
                );
              })}
            </Select2>
          </Area>
          <Button type="submit" size="xs" margin="20px">
            조회
          </Button>
        </SearchArea>
        <PdfButton onClick={handlePdfButtonClick}>PDF 다운로드</PdfButton>
      </HeaderArea>
    </form>
  );
};

export default SearchForm;
