import React from 'react';
import {FileSelector, MuiModal} from 'shared/components/index';
import Box from '@mui/material/Box';
import {useTranslation} from 'react-i18next';
import styled, {css} from 'styled-components';
import {Formik} from 'formik';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import {MuiButton} from 'shared/components/index';
import theme from 'shared/styles/theme';
import {ImageSelector} from 'shared/components/index';

const StyledModalBox = styled(Box)`
  position: absolute;
  width: 70%;
  max-height: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: scroll;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: ${({container}) => (container ? 'row' : 'column')};
  &:not(:last-child) {
    margin-right: 30px;
  }
  width: ${({width}) => width || '100%'};
  ${({center}) =>
    center &&
    css`
      margin: 0 auto;
    `}
`;

const Form = styled.form`
  width: 920px;
  padding: 30px;
  margin: 0 auto 50px auto;
  border-radius: 15px;
  & > * {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    &:last-child {
      margin-top: 20px;
    }
  }
  border: none;
`;

const FlexBox = styled.div`
  display: flex;
  margin: 0 0 15px 0;
`;

const MediaUploadModal = ({
  onSubmit,
  onValidate,
  uploadMediaModal,
  setUploadMediaModal,
  tabValue,
  setTabValue,
}) => {
  const {t} = useTranslation();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <MuiModal
      open={uploadMediaModal}
      onClose={() => setUploadMediaModal(false)}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <StyledModalBox>
        <Formik
          onSubmit={onSubmit}
          validate={onValidate}
          initialValues={{
            files: [],
            description_images: [],
          }}
        >
          {(formikProps) => {
            return (
              <Form
                encType="multipart/form-data"
                onBlur={formikProps.handleBlur}
                onSubmit={formikProps.handleSubmit}
              >
                <Tabs value={tabValue} onChange={handleTabChange}>
                  <Tab label="사진 업로드" />
                  <Tab label="파일 업로드" />
                </Tabs>

                {tabValue === 0 && (
                  <FieldWrapper>
                    <ImageSelector
                      name="description_images"
                      value={formikProps.values.description_images}
                      formikProps={formikProps}
                      label=""
                      onChange={formikProps.handleChange}
                    />
                  </FieldWrapper>
                )}

                {tabValue === 1 && (
                  <FlexBox>
                    <FieldWrapper>
                      <FileSelector
                        name="files"
                        value={formikProps}
                        formikProps={formikProps}
                        label=""
                        onChange={formikProps.handleChange}
                      />
                    </FieldWrapper>
                  </FlexBox>
                )}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '20px',
                    marginBottom: '-40px',
                  }}
                >
                  <MuiButton
                    name="cancel"
                    id="noticeAdd-cancel"
                    onClick={() => setUploadMediaModal(false)}
                    style={{backgroundColor: 'grey', color: 'white'}}
                    text={t('buttons.cancel')}
                  />

                  <MuiButton
                    name="submit"
                    id="noticeAdd-submit"
                    backgroundColor={theme.colors.kalsPurple}
                    textColor="white"
                    text="완료"
                    type="submit"
                    style={{marginLeft: '30px'}}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </StyledModalBox>
    </MuiModal>
  );
};

export default MediaUploadModal;
