import React, {useState} from 'react';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import {MuiModal} from 'shared/components/index';
import {useDispatch, useSelector} from 'react-redux';
import {selectTask, setImagePostModal} from 'owler/utils/slices/taskSlice';
import {MuiButton} from 'shared/components/index';
import toast from 'react-hot-toast';
const Container = styled.div`
  background-color: white;
  width: 500px;
  min-height: 380px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 5px;
`;

const BtnWrapper = styled.button`
  border: none;
  background-color: white;
  margin-left: 20px;
  cursor: pointer;
`;

const ImagePreview = styled.img`
  height: 100px;
  margin: 10px;
`;
const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 50px;
`;
const Label = styled.label`
  font-weight: bold;
  margin-bottom: 0.5rem;
  border: 1px solid black;
  text-align: center;
  padding: 4px 8px;
  width: 120px;
  font-size: 20px;
  border-radius: 12px;
  background-color: #f2f2f2;
  font-weight: 500;
  margin-top: 10px;
`;

const Input = styled.input`
  padding: 0.5rem;
`;
const Button = styled.button`
  margin-top: 8px;
  font-size: 15px;
  margin-left: -10px;
`;
const ImagePostModal = ({onSubmit}) => {
  const dispatch = useDispatch();
  const {imagePostModal} = useSelector(selectTask);
  const [selectedImages, setSelectedImages] = useState([]); // 추가된 상태
  const [previewImages, setPreviewImages] = useState([]);
  const handleModalClose = () => {
    setSelectedImages([]);
    setPreviewImages([]);
    dispatch(setImagePostModal(false)); // 모달을 닫습니다.
  };
  const handleImageChange = (event) => {
    setSelectedImages([...event.target.files]);

    const files = Array.from(event.target.files);
    const fileReaders = files.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve({src: reader.result, name: file.name});
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    });
    Promise.all(fileReaders).then((values) => {
      setPreviewImages(values);
    });

    event.target.value = null;
  };
  const handleSubmit = () => {
    if (selectedImages.length === 0) {
      toast.error('이미지를 선택해주세요.');
      return;
    }
    if (typeof onSubmit === 'function') {
      onSubmit(selectedImages);
      setSelectedImages([]);
      dispatch(setImagePostModal(false));
    } else {
      console.error('onSubmit prop is not a function');
    }
  };
  const deleteImage = (img) => {
    // 이미지의 이름으로 이미지를 찾아 제거합니다.
    const newPreviewImages = previewImages.filter(
      (image) => image.name !== img.name,
    );
    setPreviewImages(newPreviewImages);

    const newSelectedImages = selectedImages.filter(
      (image) => image.name !== img.name,
    );
    setSelectedImages(newSelectedImages);
  };
  return (
    <MuiModal open={imagePostModal} onClose={handleModalClose}>
      <Container>
        <TitleWrapper>
          <BtnWrapper onClick={handleModalClose}>
            <CloseIcon />
          </BtnWrapper>
        </TitleWrapper>

        <label htmlFor="images">업무 보고 사진</label>
        <Label htmlFor="images">사진 등록</Label>
        <Input
          type="file"
          name="images"
          id="images"
          style={{display: 'none'}}
          onChange={handleImageChange}
          accept="image/*"
          multiple
        />
        <div>
          {previewImages.map((file, index) => (
            <div key={file.src} style={{textAlign: 'center'}}>
              <ImagePreview key={index} src={file.src} />
              <p>{file.name}</p>
              <Button
                type="button"
                onClick={() => {
                  deleteImage(file);
                }}
              >
                업로드 취소
              </Button>
            </div>
          ))}
        </div>
        <ButtonContainer>
          <MuiButton
            onClick={() => {
              handleSubmit();
              setSelectedImages([]);
              setPreviewImages([]);
            }}
            variant="contained"
            backgroundColor="#8773D6"
            textcolor="#f8f8f8"
            text="제출하기"
          />
        </ButtonContainer>
      </Container>
    </MuiModal>
  );
};
export default ImagePostModal;
