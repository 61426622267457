import React from 'react';
import {Text} from 'shared/components/index';
import theme from 'shared/styles/theme';

function ErrorMessage({error, visible}) {
  // eslint-disable-next-line curly
  if (!visible || !error) return null;
  return <Text color={theme.colors.danger}>{error}</Text>;
}

export default ErrorMessage;
