import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import api from 'shared/utils/api';

const initialState = {
  supplementionList: [],
  supplementionDetailPdf: null,
  selectedImages: [],
  selectedImagesForDashBoard: null,

  startDate: null,
  endDate: null,
};

export const getSvSupplementationAsync = createAsyncThunk(
  'franchise/getSvSupplementationAsync',
  async (param) => {
    const response = await api.getSvSupplementation(param);
    const {results} = response.data;

    return results;
  },
);

export const getSvSupplementationForPdfAsync = createAsyncThunk(
  'franchise/getSvSupplementationForPdfAsync',
  async (param) => {
    const response = await api.getSvSupplementationForPdf(param);
    const {results} = response.data;

    return results;
  },
);

export const getSupplementationDashboardAsync = createAsyncThunk(
  'franchise/getSupplementationDashboardAsync',
  async (param) => {
    const response = await api.getSupplementationDashboard(param);
    const {results} = response.data;

    return results;
  },
);

export const franchiseSupplementionSlice = createSlice({
  name: 'franchiseSupplemention',
  initialState,
  reducers: {
    setSupplementionList: (state, action) => {
      state.supplementionList = action.payload;
    },
    setSupplementionDetailPdf: (state, action) => {
      state.supplementionDetailPdf = action.payload;
    },
    setSelectedImages: (state, action) => {
      state.selectedImages = action.payload;
    },
    setSelectedImagesForDashBoard: (state, action) => {
      state.selectedImagesForDashBoard = action.payload;
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getSvSupplementationAsync.fulfilled, (state, action) => {
      state.supplementionList = action.payload;
    });
    builder.addCase(
      getSvSupplementationForPdfAsync.fulfilled,
      (state, action) => {
        state.supplementionDetailPdf = action.payload;
      },
    );
  },
});

export const {
  setSupplementionList,
  setSelectedImages,
  setSupplementionDetailPdf,
  setStartDate,
  setEndDate,
  setSelectedImagesForDashBoard,
} = franchiseSupplementionSlice.actions;

export const selectFranchiseSupplemention = (state) =>
  state.franchise.supplemention;

export default franchiseSupplementionSlice.reducer;
