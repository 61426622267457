/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/prop-types */
import React from 'react';
import {Text, View, StyleSheet, Font, Image} from '@react-pdf/renderer';
import theme from 'shared/styles/theme';

Font.register({
  family: 'Nanum Gothic',
  src: 'https://fonts.gstatic.com/ea/nanumgothic/v5/NanumGothic-Regular.ttf',
});

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    padding: 15,
    paddingTop: 15,
  },
  body: {
    display: 'flex',
    width: '100%',
    color: 'black',
    border: 1,
    borderBottom: 0,
    minHeight: '24px',
    textAlign: 'center',
    fontSize: 10,
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#dfd7f3',
    borderBottom: 1,
    height: '24px',
  },
  text: {
    textAlign: 'center',
    verticalAlign: 'middle',
    fontSize: 9,
    fontFamily: 'Nanum Gothic',
    color: theme.colors.notBlack,
    fontWeight: 'thin',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: 1,
    minHeight: '23px',
    paddingTop: 1,
    paddingBottom: 1,
  },
  image: {
    height: 15,
    width: 15,
    objectFit: 'cover',
  },
});

const PdfBody = ({commuteLog}) => {
  const timeformmater = (time) => {
    const date = new Date(time);
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // 시간과 분을 항상 두 자리 숫자로 표시 (예: '07:05')
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}`;
  };

  return (
    <View style={styles.body}>
      <View style={styles.headerWrapper}>
        <View style={[styles.header, {width: '12.5%', borderRight: 1}]}>
          <Text style={styles.text}>일자</Text>
        </View>
        <View style={[styles.header, {width: '25%', borderRight: 1}]}>
          <Text style={styles.text}>근무 예정</Text>
        </View>
        <View style={[styles.header, {width: '12.5%', borderRight: 1}]}>
          <Text style={styles.text}>출근</Text>
        </View>
        <View style={[styles.header, {width: '12.5%', borderRight: 1}]}>
          <Text style={styles.text}>퇴근</Text>
        </View>
        <View style={[styles.header, {width: '12.5%', borderRight: 1}]}>
          <Text style={styles.text}>지각</Text>
        </View>
        <View style={[styles.header, {width: '12.5%', borderRight: 1}]}>
          <Text style={styles.text}>조퇴</Text>
        </View>
        <View style={[styles.header, {width: '12.5%'}]}>
          <Text style={styles.text}>비고</Text>
        </View>
      </View>
      {commuteLog.length !== 0 &&
        Object.values(commuteLog?.commute_log_list).map((dayCommute, index) => (
          <View style={styles.contentWrapper}>
            <View style={[styles.content, {width: '12.5%', borderRight: 1}]}>
              <Text style={styles.text}>{index + 1}</Text>
            </View>
            <View style={[styles.content, {width: '25%', borderRight: 1}]}>
              {dayCommute.schedule.length !== 0 ? (
                dayCommute.schedule.map((eachSchedule) => {
                  return (
                    <Text style={styles.text}>
                      {timeformmater(eachSchedule.start)}~
                      {timeformmater(eachSchedule.end)}
                    </Text>
                  );
                })
              ) : (
                <Text style={[styles.text, {color: '#BDBDBD'}]}>
                  등록된 근무표가 없습니다.
                </Text>
              )}
            </View>
            <View style={[styles.content, {width: '12.5%', borderRight: 1}]}>
              {dayCommute.arrive.map((eachArrive) => {
                return (
                  <Text style={styles.text}>{timeformmater(eachArrive)}</Text>
                );
              })}
            </View>
            <View style={[styles.content, {width: '12.5%', borderRight: 1}]}>
              {dayCommute.leave.map((eachLeave) => {
                return (
                  <Text style={styles.text}>{timeformmater(eachLeave)}</Text>
                );
              })}
            </View>
            <View style={[styles.content, {width: '12.5%', borderRight: 1}]}>
              {dayCommute.late === true && (
                <Image style={styles.image} source="/checkIcon.png"></Image>
              )}
            </View>
            <View style={[styles.content, {width: '12.5%', borderRight: 1}]}>
              {dayCommute.early === true && (
                <Image style={styles.image} source="/checkIcon.png"></Image>
              )}
            </View>
            <View style={[styles.content, {width: '12.5%'}]}>
              <Text style={styles.text}></Text>
            </View>
          </View>
        ))}
    </View>
  );
};

export default PdfBody;
