import React, {useState} from 'react';

import PositionManagementTemplate from './template/index';
import {useDispatch} from 'react-redux';
import {
  getFranchiseSvJoinAsync,
  postFranchiseSvJoinAsync,
} from 'franchise/utils/slices/frUserSlice';

const PositionManagement = () => {
  const dispatch = useDispatch();
  const [input, setIput] = useState('');
  const [offerList, setOfferList] = useState(null);

  const searchNumber = () => {
    const sanitizedInput = input.replace(/\s+/g, '');

    dispatch(
      getFranchiseSvJoinAsync({
        phone_number: sanitizedInput,
      }),
    )
      .unwrap()
      .then((payload) => {
        setOfferList(payload);
      })
      .catch((err) => {
        setOfferList(null);
      });
  };

  const joinSvMemeber = (_user_uid) => {
    dispatch(
      postFranchiseSvJoinAsync({
        user_uid: _user_uid,
      }),
    )
      .unwrap()
      .then(() => {
        alert('합류 되었습니다.');
        searchNumber();
      });
  };

  return (
    <PositionManagementTemplate
      offerList={offerList}
      input={input}
      setIput={setIput}
      searchNumber={searchNumber}
      joinSvMemeber={joinSvMemeber}
    />
  );
};

export default PositionManagement;
