import {useMemo} from 'react';
import styled from 'styled-components';
import {Toaster} from 'react-hot-toast';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {
  Wrapper,
  TableWrapper,
  TableContent,
  CustomDatePicker,
  Drawer,
  MuiButton,
} from 'shared/components/index';
import MasterTaskView from './MasterTaskView';
import theme from 'shared/styles/theme';
import CategoryAddModal from './CategoryAddModal';
import CategorySettingsModal from './CategorySettingsModal';
import CategoryDeleteModal from './CategoryDeleteModal';
import CategoryModifyModal from './CategoryModifyModal';
import ImagePostModal from './ImagePostModal';
import ImageDetailModal from './ImageDetailModal';

const CustomHeaderWrap = styled.div`
  width: 1085px;

  height: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -20px;
  margin-top: -15px;
  margin-left: 52px;
`;

const MasterTasksWrapper = styled.div`
  width: 1085px;
  margin-bottom: 30px;
  margin-top: -50px;
  margin-left: 52px;
`;

const StoreDetailTemplate = ({
  currentStore,
  masterTaskList,
  selectedDate,
  handleDateChange,
  handleCreateNewTaskBtn,
  handleCategorySettingsBtn,
  handleTaskClickBtn,
  handleCategoryAddBtnClick,
  changeCompleted,
  onSubmit,
  imageDetail,
  handleStoreClick,
  isLoading,
}) => {
  const masterTaskViews = useMemo(() => {
    if (!masterTaskList || masterTaskList.length === 0) {
      return (
        <div
          style={{
            height: '350px',
            display: 'flex',
            alignItems: 'center',
            width: '952px',
            textAlign: 'center',
          }}
        >
          <p
            style={{
              width: '970px',
              fontSize: '18px',
              color: '#bdbdbd',
            }}
          >
            업무가 존재하지 않습니다.
          </p>
        </div>
      );
    }

    return masterTaskList.map((masterTasks, index) => (
      <MasterTaskView
        key={index}
        masterTasks={masterTasks}
        handleCreateNewTaskBtn={handleCreateNewTaskBtn}
        handleCategorySettingsBtn={handleCategorySettingsBtn}
        handleTaskClickBtn={handleTaskClickBtn}
        changeCompleted={changeCompleted}
      />
    ));
  }, [
    masterTaskList,
    handleCreateNewTaskBtn,
    handleCategorySettingsBtn,
    handleTaskClickBtn,
    changeCompleted,
  ]);

  const why = 'true';

  return (
    <Wrapper
      titleText={currentStore.storeName}
      handleStoreClick={handleStoreClick}
    >
      <Drawer why={why}>
        <Toaster toastOptions={{duration: 4000}} />
        <TableWrapper>
          <TableContent>
            <CustomHeaderWrap>
              <div></div>
              <CustomDatePicker
                selectedDate={selectedDate}
                handleDateChange={handleDateChange}
                taskDatePicker
              />

              <MuiButton
                fontSize={theme.fontSizes.body}
                onClick={handleCategoryAddBtnClick}
                backgroundColor={theme.colors.kalsPurple}
                textColor="#f8f8f8"
                variant="contained"
                text="카테고리 추가"
                style={{height: '28px'}}
                endIcon={<AddCircleIcon />}
              />
            </CustomHeaderWrap>
            {isLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  width: '95%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '100px',
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <MasterTasksWrapper>{masterTaskViews}</MasterTasksWrapper>
            )}
          </TableContent>
        </TableWrapper>

        <CategoryAddModal />
        <CategorySettingsModal />
        <CategoryDeleteModal />
        <CategoryModifyModal />
        <ImagePostModal onSubmit={onSubmit} />
        <ImageDetailModal imageDetail={imageDetail} />
      </Drawer>
    </Wrapper>
  );
};

export default StoreDetailTemplate;
