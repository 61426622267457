import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import MarketingTemplate from './template/index';
import {selectMap} from 'shared/utils/redux/mapSlice';
import {useSelector} from 'react-redux';

const Marketing = () => {
  const {alls} = useSelector(selectMap);
  const navigate = useNavigate();

  const [listOpen, setListOpen] = useState(false);
  const [lat, setLat] = useState(37.5399918);
  const [lng, setLng] = useState(127.0693646);
  const [zoom, setZoom] = useState(16);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (window.naver) {
        clearInterval(intervalId);
      }
    }, 100);

    const options = {
      enableHighAccuracy: true,
      maximumAge: 30000,
      timeout: 27000,
    };

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLoading(true);
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
        setZoom(16);
        setLoading(false);
      },
      (error) => {
        console.error(error);
        setLoading(false);
      },
      options,
    );

    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        // setMyLat(position.coords.latitude);
        // setMyLng(position.coords.longitude);
      },
      (error) => {
        console.error(error);
      },
      options,
    );

    return () => {
      clearInterval(intervalId);
      navigator.geolocation.clearWatch(watchId);
    };
  }, []);

  const buttonClick = (store_uid) => {
    navigate(`/eventList?store_uid=${store_uid}`);
  };

  return (
    <MarketingTemplate
      zoom={zoom}
      Latitude={lat}
      Longtitude={lng}
      roadAddress=""
      alls={alls}
      setLat={setLat}
      setLng={setLng}
      setZoom={setZoom}
      buttonClick={buttonClick}
      mylat={37.5399918}
      mylng={127.0693646}
      listOpen={listOpen}
      setListOpen={setListOpen}
      loading={loading}
    />
  );
};
export default Marketing;
