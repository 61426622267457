/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';

const TextWrapper = styled.span`
  font-size: ${({theme, fontSize}) => theme.fontSizes[fontSize]};
  font-weight: ${({bold}) => (bold ? 'Bold' : null)};
  margin: ${({margin}) => (margin ? margin : '0 auto')};
  display: inline-block;
  color: ${({theme, color}) => (color ? color : theme.colors.notBlack)};
`;

const TextPWrapper = styled.p`
  font-size: ${({theme, fontSize}) => theme.fontSizes[fontSize]};
  font-weight: ${({bold}) => (bold ? 'Bold' : null)};
  margin: ${({margin}) => (margin ? margin : '0 auto')};
  color: ${({theme, color}) => (color ? color : theme.colors.notBlack)};
`;

const AnchorWrapper = styled.span`
  font-size: ${({theme, fontSize}) => theme.fontSizes[fontSize]};
  font-weight: ${({bold}) => (bold ? 'bold' : null)};
  margin: auto 0;
  width: 100%;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  & > strong {
    text-decoration: underline;
  }
  color: ${({color}) => '#686868' && color};
  display: inline-block;
`;

const Text = ({
  variant = 'span',
  children,
  fontSize,
  color,
  bold,
  ...props
}) => {
  if (variant === 'span') {
    return (
      <TextWrapper {...props} color={color} bold={bold} fontSize={fontSize}>
        {children}
      </TextWrapper>
    );
  }

  if (variant === 'text') {
    return (
      <TextPWrapper {...props} color={color} bold={bold} fontSize={fontSize}>
        {children}
      </TextPWrapper>
    );
  }

  if (variant === 'link') {
    return (
      <AnchorWrapper {...props} color={color} bold={bold} fontSize={fontSize}>
        {children}
      </AnchorWrapper>
    );
  }
};

export default Text;
