import React from 'react';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import {MuiModal} from 'shared/components/index';
import {useDispatch, useSelector} from 'react-redux';
import {
  selectFranchiseQsc,
  setQscImageModal,
} from 'franchise/utils/slices/frQscSlice';

const Container = styled.div`
  background-color: white;
  width: 650px;
  height: 500px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 5px;
`;

const BtnWrapper = styled.button`
  border: none;
  background-color: white;
  margin-left: 20px;
  cursor: pointer;
`;

const Image = styled.img`
  height: 400px;
  width: auto;
`;

const ViewQscImageModal = () => {
  const dispatch = useDispatch();
  const {qscImage, qscImageModal} = useSelector(selectFranchiseQsc);

  const handleModalClose = () => {
    dispatch(setQscImageModal(false));
  };

  return (
    <MuiModal open={qscImageModal} onClose={handleModalClose}>
      <Container>
        <TitleWrapper>
          <BtnWrapper onClick={handleModalClose}>
            <CloseIcon />
          </BtnWrapper>
        </TitleWrapper>

        <Image src={qscImage?.image} alt="Image" />
      </Container>
    </MuiModal>
  );
};
export default ViewQscImageModal;
