/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import styled from 'styled-components';

const StyledTextField = styled(TextField)`
  && {
    width: 426px;
    height: 32px;
    margin: 5px 0 20px 0;
  }

  &&::focus {
    border: 2px solid red;
  }
  fieldset {
    border-radius: 15px;
  }
`;

export default function SearchInputWithIcon({onChange, value}) {
  return (
    <div>
      <StyledTextField
        id="input-with-icon-textfield"
        variant="outlined"
        size="small"
        onChange={onChange}
        value={value}
        placeholder=""
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}
