/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {
  selectStore,
  setCurrentStore,
  setStoreBrandList,
  getStoreBrandListAsync,
  getBrandTaskStatusAsync,
  setImageTasks,
  setCheckTasks,
  setMemberInfo,
} from 'owler/utils/slices/storeSlice';
import DashboardTemplate from './template';
import {
  getDailyTaskStatusAsync,
  getMonthlyTaskStatusAsync,
  getRealTimePictureTaskStatusAsync,
  getTaskReviewSummaryAsync,
  getTodaysWorkScheduleAsync,
  getWeeklyTaskStatusAsync,
  setSelectedDateT,
  getBrandTaskStatusListAsync,
  getBrandMonthlyTaskStatusAsync,
  getBrandDailyTaskStatusAsync,
  getBrandWeeklyTaskStatusAsync,
  getBrandNewRequestAsync,
  getBrandTodayScheduleAsync,
  getBrandUncheckNoticeAsync,
  getBrandRealTimeImageTaskAsync,
} from 'owler/utils/slices/taskSlice';
import {getWeeksInMonth, getWeek} from 'shared/utils/time';
import {getMemberUidAsync, setMember_uid} from 'owler/utils/slices/noticeSlice';

const koreanWeekNames = {
  SUN: '일',
  MON: '월',
  TUE: '화',
  WED: '수',
  THU: '목',
  FRI: '금',
  SAT: '토',
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const {currentStore, userStores} = useSelector(selectStore);
  //const {start, end} = formatDateRangeForApi(new Date());
  const now = new Date();
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, '0'); // 월을 0으로 패딩
  const day = now.getDate().toString().padStart(2, '0'); // 일을 0으로 패딩
  const date = `${year}-${month}-${day}`;

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isDateLoading, setIsDateLoading] = useState(false);
  const [isStoreLoading, setIsStoreLoading] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [activeTaskStat, setActiveTaskStat] = useState(1);
  const [dailyTaskStatus, setDailyTaskStatus] = useState(null);
  const [lastWeekGraphData, setLastWeekGraphData] = useState([]);
  const [thisWeekGraphData, setThisWeekGraphData] = useState([]);
  const [thisMonthLineGraphData, setThisMonthLineGraphData] = useState(null);
  const [realTimeTaskPictures, setRealTimeTaskPictures] = useState([]);
  const [realTimeBrandTaskPictures, setRealTimeBrandTaskPictures] = useState(
    [],
  );
  const [workSchedules, setWorkSchedules] = useState([]);
  const [storeReviews, setStoreReviews] = useState(null);
  const [displayDateWeekly, setDisplayDateWeekly] = useState(
    `${new Date().getFullYear()}-${new Date().getMonth() + 1}`,
  );
  const [weekStateDate, setWeekStateDate] = useState(new Date());
  const [clickCount, setClickCount] = useState(getWeek());

  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [brandtaskStatusList, setBrandTaskStatusList] = useState([]);
  const [todaySchedule, setTodaySchedule] = useState([]);
  const [newRequestList, setNewRequestList] = useState([]);
  const [unCheckNotice, setUnCheckNotice] = useState([]);

  useEffect(() => {
    dispatch(getMemberUidAsync(currentStore.store_uid))
      .unwrap()
      .then((payload) => {
        dispatch(setMember_uid(payload));
        dispatch(setMemberInfo(payload));
      });
    if (currentStore.is_corporation) {
      dispatch(
        getStoreBrandListAsync({
          store_uid: currentStore.store_uid,
        }),
      )
        .unwrap()
        .then((payload) => {
          dispatch(setStoreBrandList(payload));
        });
    } else {
      if (currentStore) {
        setIsLoading(true);
      }
      const fetchApiData = () => {
        if (!currentStore.is_hq) {
          setIsDateLoading(true);
          setIsImageLoading(true);
          dispatch(setSelectedDateT(new Date()));
          const apiCallCommonData = {
            store_uid: currentStore.store_uid,
            date,
          };
          const storeUIDData = {store_uid: currentStore?.store_uid};

          dispatch(getDailyTaskStatusAsync(apiCallCommonData))
            .unwrap()
            .then((payload) => {
              setDailyTaskStatus(payload);
            })
            .catch((error) => {
              alert(error.message);
            })
            .finally(() => {
              setIsDateLoading(false);
            });

          dispatch(getRealTimePictureTaskStatusAsync(storeUIDData))
            .unwrap()
            .then((payload) => setRealTimeTaskPictures(payload))
            .catch((error) => {
              alert(error.message);
            })
            .finally(() => {
              setIsImageLoading(false);
            });

          dispatch(getTodaysWorkScheduleAsync(apiCallCommonData))
            .unwrap()
            .then((payload) => setWorkSchedules(payload))
            .catch((error) => {
              alert(error.message);
            })
            .finally(() => {
              setIsLoading(false);
            });

          dispatch(getTaskReviewSummaryAsync(storeUIDData))
            .unwrap()
            .then((payload) => setStoreReviews(payload))
            .catch((error) => {
              alert(error.message);
            });
        }

        if (currentStore.is_hq) {
          setIsDateLoading(true);
          setIsStoreLoading(true);
          dispatch(setSelectedDateT(new Date()));
          const apiCallCommonData = {
            store_uid: currentStore.store_uid,
            date,
          };
          const storeUIDData = {store_uid: currentStore?.store_uid};
          dispatch(getBrandTaskStatusListAsync(storeUIDData))
            .unwrap()
            .then((payload) => setBrandTaskStatusList(payload))
            .catch((error) => {
              alert(error.message);
            })
            .finally(() => {
              setIsLoading(false);
              setIsStoreLoading(false);
            });

          // new api to fetch latest image task status live:
          dispatch(getBrandRealTimeImageTaskAsync(storeUIDData))
            .unwrap()
            .then((payload) => {
              setRealTimeBrandTaskPictures(payload);
            })
            .catch((error) => {
              alert(error.message);
            })
            .finally(() => {
              setIsImageLoading(false);
              setIsLoading(false);
              setIsStoreLoading(false);
            });
          dispatch(getBrandDailyTaskStatusAsync(apiCallCommonData))
            .unwrap()
            .then((payload) => {
              setDailyTaskStatus(payload);
            })
            .catch((error) => {
              alert(error.message);
            })
            .finally(() => {
              setIsLoading(false);
              setIsDateLoading(false);
            });
          dispatch(
            getBrandTaskStatusAsync({
              task_type: 'check',
              store_uid: currentStore.store_uid,
              date,
            }),
          )
            .unwrap()
            .then((payload) => {
              dispatch(setCheckTasks(payload));
            })
            .catch((err) => alert(err.message));

          dispatch(
            getBrandTaskStatusAsync({
              task_type: 'image',
              store_uid: currentStore.store_uid,
              date,
            }),
          )
            .unwrap()
            .then((payload) => {
              dispatch(setImageTasks(payload));
            })
            .catch((err) => alert(err.message));
          dispatch(getBrandNewRequestAsync(apiCallCommonData))
            .unwrap()
            .then((payload) => setNewRequestList(payload))
            .catch((error) => {
              alert(error.message);
            });
          dispatch(getBrandUncheckNoticeAsync(apiCallCommonData))
            .unwrap()
            .then((payload) => setUnCheckNotice(payload))
            .catch((error) => {
              alert(error.message);
            })
            .finally(() => {
              setIsLoading(false);
            });
          dispatch(getBrandTodayScheduleAsync(apiCallCommonData))
            .unwrap()
            .then((payload) => setTodaySchedule(payload))
            .catch((error) => {
              alert(error.message);
            });
        }
      };
      fetchApiData(); // Call immediately when component mounts

      const intervalId = setInterval(fetchApiData, 300000);

      return () => clearInterval(intervalId);
    }
  }, [currentStore]);

  useEffect(() => {
    if (activeTaskStat === 2) {
      setIsDateLoading(true);

      if (currentStore.is_hq) {
        dispatch(
          getBrandWeeklyTaskStatusAsync({
            store_uid: currentStore?.store_uid,
            year: weekStateDate.getFullYear(),
            month: weekStateDate.getMonth() + 1,
            week: clickCount,
          }),
        )
          .unwrap()
          .then((payload) => {
            const convertData = (weekData) => {
              return Object.entries(weekData).map(([day, value]) => ({
                x: koreanWeekNames[day],
                y: value,
              }));
            };

            if (payload !== null) {
              setLastWeekGraphData(convertData(payload?.last));
              setThisWeekGraphData(convertData(payload?.current));
            }
            setIsDateLoading(false);
          })
          .catch((error) => alert(error.message));
      }

      if (!currentStore.is_hq) {
        dispatch(
          getWeeklyTaskStatusAsync({
            store_uid: currentStore?.store_uid,
            year: weekStateDate.getFullYear(),
            month: weekStateDate.getMonth() + 1,
            week: clickCount,
          }),
        )
          .unwrap()
          .then((payload) => {
            const convertData = (weekData) => {
              return Object.entries(weekData).map(([day, value]) => ({
                x: koreanWeekNames[day],
                y: value,
              }));
            };

            if (payload !== null) {
              setLastWeekGraphData(convertData(payload?.last));
              setThisWeekGraphData(convertData(payload?.current));
            }
            setIsDateLoading(false);
          })
          .catch((error) => alert(error.message));
      }
    }
  }, [weekStateDate, clickCount, currentStore]);

  useEffect(() => {
    if (activeTaskStat === 3) {
      setIsDateLoading(true);

      if (currentStore.is_hq) {
        dispatch(
          getBrandMonthlyTaskStatusAsync({
            store_uid: currentStore?.store_uid,
            year: selectedYear,
            month: new Date().getMonth() + 1,
          }),
        )
          .unwrap()
          .then((payload) => {
            setThisMonthLineGraphData(payload);
            setIsDateLoading(false);
          })
          .catch((error) => alert(error));
      }

      if (!currentStore.is_hq) {
        dispatch(
          getMonthlyTaskStatusAsync({
            store_uid: currentStore?.store_uid,
            year: selectedYear,
            month: new Date().getMonth() + 1,
          }),
        )
          .unwrap()
          .then((payload) => {
            setThisMonthLineGraphData(payload);
            setIsDateLoading(false);
          })
          .catch((error) => alert(error));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear, currentStore]);

  const handleYearChange = (increment) => {
    const newYear = selectedYear + increment;
    setSelectedYear(newYear);
  };

  const handleChangeWeekBack = () => {
    setClickCount((old) => old - 1);

    if (clickCount === 1) {
      setWeekStateDate((oldDate) => {
        const newDate = new Date(oldDate); // Create a new Date object based on oldDate
        newDate.setMonth(newDate.getMonth() - 1);

        setDisplayDateWeekly(
          `${newDate.getFullYear()}-${newDate.getMonth() + 1}`,
        );

        setClickCount(
          getWeeksInMonth(newDate.getFullYear(), newDate.getMonth()),
        );

        return newDate;
      });
    }
  };

  const handleChangeWeekForward = () => {
    setClickCount((old) => old + 1);

    if (
      clickCount ===
      getWeeksInMonth(weekStateDate.getFullYear(), weekStateDate.getMonth())
    ) {
      setWeekStateDate((oldDate) => {
        const newDate = new Date(oldDate); // Create a new Date object based on oldDate
        newDate.setMonth(newDate.getMonth() + 1);

        setDisplayDateWeekly(
          `${newDate.getFullYear()}-${newDate.getMonth() + 1}`,
        );

        setClickCount(1);

        return newDate;
      });
    }
  };

  const handleTaskStatClick = (statNumber) => {
    setIsDateLoading(true);
    if (currentStore.is_hq) {
      if (statNumber === 1) {
        const year = selectedDate.getFullYear();
        const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0'); // 월을 0으로 패딩
        const day = selectedDate.getDate().toString().padStart(2, '0'); // 일을 0으로 패딩
        const date = `${year}-${month}-${day}`;
        dispatch(
          getBrandDailyTaskStatusAsync({
            store_uid: currentStore.store_uid,
            date,
          }),
        )
          .unwrap()
          .then((payload) => {
            setDailyTaskStatus(payload);
            setIsDateLoading(false);
          })
          .catch((error) => {
            alert(error.message);
          });
        dispatch(
          getBrandTaskStatusAsync({
            task_type: 'check',
            store_uid: currentStore.store_uid,
            date,
          }),
        )
          .unwrap()
          .then((payload) => {
            setCheckTasks(payload);
          })
          .catch((err) => alert(err.message));
        dispatch(
          getBrandTaskStatusAsync({
            task_type: 'image',
            store_uid: currentStore.store_uid,
            date,
          }),
        )
          .unwrap()
          .then((payload) => {
            setImageTasks(payload);
          })
          .catch((err) => alert(err.message));
      } else if (statNumber === 2) {
        var currentDate = new Date();

        var currentDay = currentDate.getDay();

        if (currentDay !== 0) {
          var totalDays = currentDate.getDate();
          var weekNumber = Math.ceil((totalDays - currentDay) / 7);
        } else {
          // if the current day is Sunday, the week number is correct
          weekNumber = Math.ceil(totalDays / 7);
        }

        dispatch(
          getBrandWeeklyTaskStatusAsync({
            store_uid: currentStore?.store_uid,
            year: new Date(displayDateWeekly).getFullYear(),
            month: new Date(displayDateWeekly).getMonth() + 1,
            week: clickCount,
          }),
        )
          .unwrap()
          .then((payload) => {
            const convertData = (weekData) => {
              return Object.entries(weekData).map(([day, value]) => ({
                x: koreanWeekNames[day],
                y: value,
              }));
            };

            if (payload !== null) {
              setLastWeekGraphData(convertData(payload?.last));
              setThisWeekGraphData(convertData(payload?.current));
            }
            setIsDateLoading(false);
          })
          .catch((error) => alert(error.message));
      } else if (statNumber === 3) {
        dispatch(
          getBrandMonthlyTaskStatusAsync({
            store_uid: currentStore?.store_uid,
            year: selectedYear,
            month: new Date().getMonth() + 1,
          }),
        )
          .unwrap()
          .then((payload) => {
            setThisMonthLineGraphData(payload);
            setIsDateLoading(false);
          })
          .catch((error) => alert(error));
      }

      setActiveTaskStat(statNumber);
    }
    if (!currentStore.is_hq) {
      if (statNumber === 1) {
        const year = selectedDate.getFullYear();
        const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0'); // 월을 0으로 패딩
        const day = selectedDate.getDate().toString().padStart(2, '0'); // 일을 0으로 패딩
        const date = `${year}-${month}-${day}`;
        dispatch(
          getDailyTaskStatusAsync({store_uid: currentStore.store_uid, date}),
        )
          .unwrap()
          .then((payload) => {
            setDailyTaskStatus(payload);
            setIsDateLoading(false);
          })
          .catch((error) => {
            alert(error.message);
          });
      } else if (statNumber === 2) {
        var currentDate = new Date();

        var currentDay = currentDate.getDay();

        if (currentDay !== 0) {
          var totalDays = currentDate.getDate();
          var weekNumber = Math.ceil((totalDays - currentDay) / 7);
        } else {
          // if the current day is Sunday, the week number is correct
          weekNumber = Math.ceil(totalDays / 7);
        }

        dispatch(
          getWeeklyTaskStatusAsync({
            store_uid: currentStore?.store_uid,
            year: new Date(displayDateWeekly).getFullYear(),
            month: new Date(displayDateWeekly).getMonth() + 1,
            week: clickCount,
          }),
        )
          .unwrap()
          .then((payload) => {
            const convertData = (weekData) => {
              return Object.entries(weekData).map(([day, value]) => ({
                x: koreanWeekNames[day],
                y: value,
              }));
            };

            if (payload !== null) {
              setLastWeekGraphData(convertData(payload?.last));
              setThisWeekGraphData(convertData(payload?.current));
            }
            setIsDateLoading(false);
          })
          .catch((error) => alert(error.message));
      } else if (statNumber === 3) {
        dispatch(
          getMonthlyTaskStatusAsync({
            store_uid: currentStore?.store_uid,
            year: selectedYear,
            month: new Date().getMonth() + 1,
          }),
        )
          .unwrap()
          .then((payload) => {
            setThisMonthLineGraphData(payload);
            setIsDateLoading(false);
          })
          .catch((error) => alert(error));
      }

      setActiveTaskStat(statNumber);
    }
  };

  const handleStoreClick = useCallback(
    (_store) => {
      dispatch(setCurrentStore(_store));
    },
    [dispatch],
  );

  const handleBrandStoreClick = useCallback(
    (_store) => {
      userStores.map((store) => {
        if (store.store_uid === _store.brand_store_uid) {
          dispatch(setCurrentStore(store));
        }
      });
    },
    [dispatch],
  );

  const handleDateChange = (date2) => {
    setIsDateLoading(true);
    setSelectedDate(date2);
    const year = date2.getFullYear();
    const month = (date2.getMonth() + 1).toString().padStart(2, '0'); // 월을 0으로 패딩
    const day = date2.getDate().toString().padStart(2, '0'); // 일을 0으로 패딩
    const date = `${year}-${month}-${day}`;
    const apiCallCommonData = {
      store_uid: currentStore.store_uid,
      date,
    };
    if (!currentStore.is_hq) {
      dispatch(getDailyTaskStatusAsync(apiCallCommonData))
        .unwrap()
        .then((payload) => {
          setDailyTaskStatus(payload);
          setIsDateLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
    }
    if (currentStore.is_hq) {
      dispatch(getBrandDailyTaskStatusAsync(apiCallCommonData))
        .unwrap()
        .then((payload) => {
          setDailyTaskStatus(payload);
          setIsDateLoading(false);
        })
        .catch((error) => {
          alert(error.message);
        });
      dispatch(
        getBrandTaskStatusAsync({
          task_type: 'check',
          store_uid: currentStore.store_uid,
          date,
        }),
      )
        .unwrap()
        .then((payload) => {
          setCheckTasks(payload);
        })
        .catch((err) => alert(err.message));
      dispatch(
        getBrandTaskStatusAsync({
          task_type: 'image',
          store_uid: currentStore.store_uid,
          date,
        }),
      )
        .unwrap()
        .then((payload) => {
          setImageTasks(payload);
        })
        .catch((err) => alert(err.message));
    }
  };

  const handleEachStoreBranchClick = useCallback(
    (_store) => {
      const userStore = userStores.find(
        (store) => store.store_uid === _store.store_uid,
      );
      if (userStore) {
        dispatch(setCurrentStore(userStore));
        navigate(`/owler/store/${_store?.store_uid}`);
      }
    },
    [dispatch, navigate, userStores],
  );

  return (
    <DashboardTemplate
      currentStore={currentStore}
      dailyTaskStatus={dailyTaskStatus}
      activeTaskStat={activeTaskStat}
      handleTaskStatClick={handleTaskStatClick}
      lastWeekGraphData={lastWeekGraphData}
      thisWeekGraphData={thisWeekGraphData}
      dateChangeActions={{
        handleChangeWeekBack,
        handleChangeWeekForward,
        displayDateWeekly,
        clickCount,
        handleYearChange,
        selectedYear,
      }}
      thisMonthLineGraphData={thisMonthLineGraphData}
      realTimeTaskPictures={realTimeTaskPictures}
      realTimeBrandTaskPictures={realTimeBrandTaskPictures}
      workSchedules={workSchedules}
      storeReviews={storeReviews}
      handleStoreClick={handleStoreClick}
      handleBrandStoreClick={handleBrandStoreClick}
      isLoading={isLoading}
      isDateLoading={isDateLoading}
      handleDateChange={handleDateChange}
      selectedDate={selectedDate}
      brandtaskStatusList={brandtaskStatusList}
      todaySchedule={todaySchedule}
      newRequestList={newRequestList}
      unCheckNotice={unCheckNotice}
      isStoreLoading={isStoreLoading}
      isImageLoading={isImageLoading}
      handleEachStoreBranchClick={handleEachStoreBranchClick}
    />
  );
};

export default Dashboard;
