import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {selectStore} from 'owler/utils/slices/storeSlice';
import PlusCircleSolidIcon from 'shared/assets/icons/PlusCircleSolidIcon';
import {
  Button,
  Drawer,
  TableContent,
  TableData,
  TableDataGroup,
  TableHeader,
  TableRow,
  TableWrapper,
  Wrapper,
} from 'shared/components/index';
import NoticeLists from './NoticeLists';
import theme from 'shared/styles/theme';

const ButtonContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 1px;
  font-size: 13px;
  & > * {
    margin-left: 8px;
  }
`;
const Container = styled.div`
  margin-left: 10px;
`;
// eslint-disable-next-line no-unused-vars
const NoticeHeader = () => {
  const navigate = useNavigate();
  const {currentStore} = useSelector(selectStore);
  const handleNoticeAddBtn = useCallback(
    (id) => {
      navigate(`/owler/notices/noticeAdd/${id}`);
    },
    [navigate],
  );

  return (
    <TableHeader>
      <TableRow width="1000px" head>
        <TableDataGroup>
          <TableData width="100px"></TableData>
        </TableDataGroup>
        <TableDataGroup justifyContent="space-between" margin="0 0px -10px 0">
          <TableData></TableData>
          <TableData></TableData>
          <TableData>
            <Button
              margin="850px"
              size="sm"
              color={theme.colors.kalsPurple}
              onClick={() => handleNoticeAddBtn(currentStore.store_uid)}
            >
              <ButtonContent>
                공지등록
                <PlusCircleSolidIcon length={20} fill="#ffffff" />
              </ButtonContent>
            </Button>
          </TableData>
        </TableDataGroup>
      </TableRow>
    </TableHeader>
  );
};

const NoticeHeader2 = () => {
  return (
    <TableHeader>
      <TableRow width="990px" head margin="0px">
        <TableDataGroup>
          <TableData width="120px">고정</TableData>
          <TableData width="100px">제목</TableData>
        </TableDataGroup>
        <TableDataGroup
          width="248px"
          justifyContent="space-between"
          style={{marginRight: '-100px'}}
        >
          <TableData width="70px">작성 날짜</TableData>
          <TableData>작성자</TableData>
          <TableData>응답</TableData>
        </TableDataGroup>
      </TableRow>
    </TableHeader>
  );
};
const NoticeListTemplate = ({
  currentStore,
  masterNoticeList,
  handlePinClick,
  currentNoticePage,
  handleStoreClick,
  isLoading,
}) => {
  return (
    <Wrapper
      titleText={currentStore.store_name}
      handleStoreClick={handleStoreClick}
    >
      <Drawer>
        <Container>
          <TableWrapper width="1090px" margin="-85px">
            {isLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '200px',
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <NoticeHeader />
                <NoticeHeader2 />

                <TableContent>
                  <NoticeLists
                    masterNoticeList={masterNoticeList}
                    currentNoticePage={currentNoticePage}
                    handlePinClick={handlePinClick}
                  />
                </TableContent>
              </>
            )}
          </TableWrapper>
        </Container>
      </Drawer>
    </Wrapper>
  );
};

export default NoticeListTemplate;
