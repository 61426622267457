import React from 'react';
import styled, {css} from 'styled-components';

const Data = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: ${({margin}) => (margin ? margin : '')};
  width: ${({width}) => width || 'auto'};
  ${({onClick}) => {
    if (onClick)
      return css`
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
      `;
    return null;
  }}
  text-align: ${({textAlign}) => textAlign};
`;
const TableData = ({
  padding = '0',
  width = 'auto',
  onClick = null,
  fontSize = 'auto',
  textAlign,
  children,
  margin,
}) => {
  return (
    <Data
      padding={padding}
      width={width}
      fontSize={fontSize}
      onClick={onClick}
      margin={margin}
      textAlign={textAlign}
    >
      {children}
    </Data>
  );
};

export default TableData;
