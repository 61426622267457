import React, {useState, useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {MuiModal, Text} from 'shared/components/index';
import CloseIcon from '@mui/icons-material/Close';
import {
  selectCalendar,
  setFranchiseListModal,
  setdirectStoreData,
  setnoDirectStoreData,
  setDirectGroup,
  setNoDirectGroup,
} from 'owler/utils/slices/calendarSlice';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import theme from 'shared/styles/theme';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const Container = styled.div`
  background-color: white;
  //margin-top: 30px;
  width: 550px;
  height: 600px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0px 3px 3px 3px #6e6e6e;
`;
const BtnWrapper = styled.button`
  border: none;
  background-color: white;
  cursor: pointer;
  position: relative;
  top: 0px;
  left: 5px;
`;
const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;
const StoreList = styled.div`
  //border-bottom: 1px solid ${theme.colors.grey6};
  margin: 0px 0 10px 0;
  width: 400px;
  max-height: 300px;
  min-height: 300px;
  overflow: auto;
`;
const Store = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const LabelWrapper = styled.div`
  margin: 0px 0;
  width: 450px;
  border-bottom: 1px solid ${theme.colors.grey6};
  border-top: 1px solid ${theme.colors.grey6};
  display: flex;
  justify-content: space-between;
`;
const LabeltitleWrapper = styled.div`
  margin: 0px 0;
  width: 450px;
  border-bottom: 1px solid ${theme.colors.grey6};
  display: flex;
  justify-content: space-between;
`;
const SearchStore = styled.input`
  width: 400px;
  font-size: 16px;
  padding: 0 10px;
  border: none;
  border: 2px solid ${theme.colors.grey9};
  border-radius: 4px;
  margin: 5px 10px;
`;
const FranchiseListModal = () => {
  const {
    franchiseListModal,
    currentFrachiseList,
    directstoreData,
    nodirectstoreData,
    directGroup,
    noDirectGroup,
  } = useSelector(selectCalendar);
  const dispatch = useDispatch();
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [directname, setdirectname] = useState('');
  const [nodirectname, setnodirectname] = useState('');

  useEffect(() => {
    const direct = [];
    const noDirect = [];
    currentFrachiseList.forEach((item) => {
      if (item.is_direct) {
        direct.push(item);
      } else {
        noDirect.push(item);
      }
    });
    dispatch(setDirectGroup(direct));
    dispatch(setNoDirectGroup(noDirect));
  }, [currentFrachiseList, dispatch]);

  useEffect(() => {}, [noDirectGroup]);

  const handleModalClose = () => {
    dispatch(setFranchiseListModal(false));
  };

  const onCheckBoxChange = (e) => {
    if (e.target.checked) {
      const direct_store_list = directGroup?.map(
        (eachstore) => eachstore.franchise_store_uid,
      );
      const direct_branch_name = directGroup?.map(
        (eachStore) => eachStore.branch_name,
      );
      const nodirect_store_list = noDirectGroup?.map(
        (eachstore) => eachstore.franchise_store_uid,
      );
      const nodirect_branch_name = noDirectGroup?.map(
        (eachStore) => eachStore.branch_name,
      );

      dispatch(
        setdirectStoreData({
          all: e.target.checked,
          stores: direct_store_list,
          names: direct_branch_name,
        }),
      );
      dispatch(
        setnoDirectStoreData({
          all: e.target.checked,
          stores: nodirect_store_list,
          names: nodirect_branch_name,
        }),
      );
    } else {
      dispatch(
        setdirectStoreData({
          all: e.target.checked,
          stores: [],
          names: [],
        }),
      );
      dispatch(
        setnoDirectStoreData({
          all: e.target.checked,
          stores: [],
          names: [],
        }),
      );
    }
  };
  const onCheckBoxChange2 = (e) => {
    if (e.target.checked) {
      const store_list = directGroup?.map(
        (eachstore) => eachstore.franchise_store_uid,
      );
      const branch_name = directGroup?.map(
        (eachStore) => eachStore.branch_name,
      );

      dispatch(
        setdirectStoreData({
          all: e.target.checked,
          stores: store_list,
          names: branch_name,
        }),
      );
    } else {
      dispatch(
        setdirectStoreData({
          all: e.target.checked,
          stores: [],
          names: [],
        }),
      );
    }
  };
  const onCheckBoxChange3 = (e) => {
    if (e.target.checked) {
      const store_list = noDirectGroup?.map(
        (eachstore) => eachstore.franchise_store_uid,
      );
      const branch_name = noDirectGroup?.map(
        (eachStore) => eachStore.branch_name,
      );

      dispatch(
        setnoDirectStoreData({
          all: e.target.checked,
          stores: store_list,
          names: branch_name,
        }),
      );
    } else {
      dispatch(
        setnoDirectStoreData({
          all: e.target.checked,
          stores: [],
          names: [],
        }),
      );
    }
  };

  const handleCheckedDirectStores = (e) => {
    const checked = e.target.value;
    const id = e.target.id;

    if (e.target.checked) {
      if (
        !directstoreData.stores.includes(checked) &&
        !directstoreData.names.includes(id)
      ) {
        dispatch(
          setdirectStoreData({
            ...directstoreData,
            stores: [...directstoreData.stores, checked],
            names: [...directstoreData.names, id],
          }),
        );
      }
    } else {
      dispatch(
        setdirectStoreData({
          ...directstoreData,
          stores: directstoreData.stores.filter(
            (store) => store.indexOf(checked) === -1,
          ),
          names: directstoreData.names.filter(
            (name) => name.indexOf(id) === -1,
          ),
        }),
      );
    }
  };
  const handleCheckedNoDirectStores = (e) => {
    const checked = e.target.value;
    const id = e.target.id;

    if (e.target.checked) {
      if (
        !nodirectstoreData.stores.includes(checked) &&
        !nodirectstoreData.names.includes(id)
      ) {
        dispatch(
          setnoDirectStoreData({
            ...nodirectstoreData,
            stores: [...nodirectstoreData.stores, checked],
            names: [...nodirectstoreData.names, id],
          }),
        );
      }
    } else {
      dispatch(
        setnoDirectStoreData({
          ...nodirectstoreData,
          stores: nodirectstoreData.stores.filter(
            (store) => store.indexOf(checked) === -1,
          ),
          names: nodirectstoreData.names.filter(
            (name) => name.indexOf(id) === -1,
          ),
        }),
      );
    }
  };

  const handledNameChange = useCallback((e) => {
    setdirectname(e.target.value);
  }, []);
  const handledNameChange2 = useCallback((e) => {
    setnodirectname(e.target.value);
  }, []);

  return (
    <MuiModal open={franchiseListModal} onClose={handleModalClose}>
      <Container>
        <TitleWrapper>
          <Text bold margin="0 0 0 10px">
            매장 선택
          </Text>
          <BtnWrapper onClick={handleModalClose}>
            <CloseIcon />
          </BtnWrapper>
        </TitleWrapper>
        {noDirectGroup.length !== 0 && (
          <>
            <LabeltitleWrapper>
              <FormControlLabel
                value="전체매장 선택"
                control={
                  <Checkbox
                    checked={directstoreData.all && nodirectstoreData.all}
                    onChange={onCheckBoxChange}
                    sx={{'&.Mui-checked': {color: theme.colors.kalsPurple}}}
                  />
                }
                label="전체매장 선택"
                labelPlacement="end"
                sx={{marginLeft: '0px'}}
              />
            </LabeltitleWrapper>
          </>
        )}
        <LabelWrapper>
          <FormControlLabel
            value="직영매장 선택"
            control={
              <Checkbox
                checked={directstoreData.all}
                onChange={onCheckBoxChange2}
                sx={{'&.Mui-checked': {color: theme.colors.kalsPurple}}}
              />
            }
            label="직영매장 선택"
            labelPlacement="end"
            sx={{marginLeft: '0px'}}
          />
          <Button
            onClick={() => {
              setShow1((prevShow) => !prevShow);
              if (show2 === true) setShow2(false);
            }}
          >
            {show1 === false ? (
              <ArrowDropDownIcon sx={{color: 'black'}} />
            ) : (
              <ArrowDropUpIcon sx={{color: 'black'}} />
            )}
          </Button>
        </LabelWrapper>

        {show1 && (
          <>
            <SearchStore
              value={directname}
              onChange={(e) => handledNameChange(e)}
              placeholder={`지점명을 입력해주세요 ex) 강남점`}
            />
            <StoreList>
              {directGroup
                ?.filter((store) => store.branch_name.includes(directname))
                .map((store) => {
                  return (
                    <Store key={store.franchise_store_uid}>
                      <Checkbox
                        id={store.branch_name}
                        value={store.franchise_store_uid}
                        onChange={handleCheckedDirectStores}
                        checked={directstoreData?.stores?.includes(
                          store.franchise_store_uid,
                        )}
                        sx={{'&.Mui-checked': {color: theme.colors.kalsPurple}}}
                      />
                      {store.branch_name}
                    </Store>
                  );
                })}
            </StoreList>
          </>
        )}
        {noDirectGroup.length !== 0 && (
          <LabelWrapper>
            <FormControlLabel
              value="가맹점 선택"
              control={
                <Checkbox
                  checked={nodirectstoreData.all}
                  onChange={onCheckBoxChange3}
                  sx={{'&.Mui-checked': {color: theme.colors.kalsPurple}}}
                />
              }
              label="가맹점 선택"
              labelPlacement="end"
              sx={{marginLeft: '0px'}}
            />
            <Button
              onClick={() => {
                setShow2((prevShow) => !prevShow);
                if (show1 === true) setShow1(false);
              }}
            >
              {show2 === false ? (
                <ArrowDropDownIcon sx={{color: 'black'}} />
              ) : (
                <ArrowDropUpIcon sx={{color: 'black'}} />
              )}
            </Button>
          </LabelWrapper>
        )}
        {show2 && (
          <>
            <SearchStore
              value={nodirectname}
              onChange={(e) => handledNameChange2(e)}
              placeholder="매장 검색"
            />
            <StoreList>
              {noDirectGroup
                ?.filter((store) => store.branch_name.includes(nodirectname))
                .map((store) => {
                  return (
                    <Store key={store.franchise_store_uid}>
                      <Checkbox
                        id={store.branch_name}
                        value={store.franchise_store_uid}
                        onChange={handleCheckedNoDirectStores}
                        checked={nodirectstoreData?.stores?.includes(
                          store.franchise_store_uid,
                        )}
                        sx={{'&.Mui-checked': {color: theme.colors.kalsPurple}}}
                      />
                      {store.branch_name}
                    </Store>
                  );
                })}
            </StoreList>
          </>
        )}
      </Container>
    </MuiModal>
  );
};
export default FranchiseListModal;
