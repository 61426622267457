import React from 'react';
import styled from 'styled-components';

import theme from 'shared/styles/theme';
import {
  Text,
  WeekNumberSelector,
  YearNumberSelector,
} from 'shared/components/index';
import WeeklyQSCPerformance from './WeeklyQSCPerformance';
import MonthlyQSCPerformance from './MonthlyQSCPerformance';

const StyledWrapper = styled.div`
  width: 100%;
  height: 320px;
  margin: -5px 15px -10px 15px;
`;

const StyledHeader = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  height: 40px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0px;
  margin-top: 20px;
  margin-left: 35px;
  z-index: 3000;
`;

const Button = styled.button`
  margin-right: 10px;
  border: none;
  border-bottom: ${({isActive}) =>
    isActive ? `2px solid ${theme.colors.primary}` : 'none'};
  background-color: transparent;
  font-size: 16px;
  cursor: pointer;
  z-index: 3000;
`;

const Box = styled.div`
  height: 235px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
`;

const QSCAverageScore = ({
  activeQSCStat,
  handleQSCStatClick,
  lastWeekScoreGraphData,
  thisWeekScoreGraphData,
  thisMonthScoreGraphData,

  handleChangeWeekBack,
  handleChangeWeekForward,
  displayDateWeekly,
  clickCount,

  selectedYear,
  handleYearChange,
}) => {
  return (
    <StyledWrapper>
      <StyledHeader>
        <Text fontSize="large" margin="20px 0 0 10px" bold>
          담당 매장 평균 점수
        </Text>
        <div
          style={{
            position: 'relative',
            top: '53px',
            left: '-25px',
            zIndex: '3000',
          }}
        >
          {activeQSCStat === 1 && (
            <WeekNumberSelector
              handleChangeWeekBack={handleChangeWeekBack}
              handleChangeWeekForward={handleChangeWeekForward}
              displayDateWeekly={displayDateWeekly}
              clickCount={clickCount}
            />
          )}

          {activeQSCStat === 2 && (
            <YearNumberSelector
              selectedYear={selectedYear}
              handleYearChange={handleYearChange}
            />
          )}
        </div>
      </StyledHeader>

      <ButtonContainer>
        <Button
          isActive={activeQSCStat === 1}
          onClick={() => handleQSCStatClick(1)}
          style={{fontSize: '13px'}}
        >
          주간
        </Button>
        <Button
          isActive={activeQSCStat === 2}
          onClick={() => handleQSCStatClick(2)}
          style={{fontSize: '13px'}}
        >
          월간
        </Button>
      </ButtonContainer>

      <Box>
        {activeQSCStat === 1 && (
          <WeeklyQSCPerformance
            lastWeekScoreGraphData={lastWeekScoreGraphData}
            thisWeekScoreGraphData={thisWeekScoreGraphData}
          />
        )}

        {activeQSCStat === 2 && (
          <MonthlyQSCPerformance
            thisMonthScoreGraphData={thisMonthScoreGraphData}
          />
        )}
      </Box>
    </StyledWrapper>
  );
};

export default QSCAverageScore;
