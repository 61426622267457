import React, {useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import theme from 'shared/styles/theme';
import {Drawer, MuiButton, Text, Wrapper} from 'shared/components/index';
import {getFormattedTime} from 'shared/utils/time';
import ImageDetailModal from './ImageDetailModal';
import {setImageModal} from 'owler/utils/slices/requestSlice';
import {useDispatch} from 'react-redux';
import Chatting from './Chatting';
import {
  patchRequestGroupAsync,
  patchRequestStatusAsync,
  getRequestDetailAsync,
  setCurrentRequest,
} from 'owler/utils/slices/requestSlice';

const Container = styled.div`
  margin: 30px;
  display: flex;
  flex-direction: row;
  margin-left: 45px;
  width: 1110px;
  max-height: 800px;
`;

const LeftContainer = styled.div`
  width: 65%;
  padding: 0 0 0 0px;
`;
const RightContainer = styled.div`
  width: 30%;
  border-left: 1px solid #d5d5d5;
  border-right: 1px solid #d5d5d5;
  margin-top: 85px;
`;

const TopContainer = styled.div`
  flex-basis: 30%;
  display: flex;
  flex-direction: column;
  & > * {
    flex-basis: 33%;
  }
  margin-top: 85px;
  margin-right: 20px;
  border-right: 1px solid #d5d5d5;
`;

const BottomContainer = styled.div`
  flex-basis: 70%;
  padding: 30px;
  border-bottom: 1px solid #d5d5d5;
  min-height: 200px;
  width: 100%;
  margin-right: 15px;
  overflow-y: auto;
  max-height: 300px;
`;

const Row = styled.div`
  height: 100%;
  display: flex;
  border-top: 1px solid #d5d5d5;
  :last-child {
    height: auto;
    border-bottom: 1px solid #d5d5d5;
  }
`;

const Box = styled.div`
  height: 50px;
  min-width: ${({width}) => width};
  flex: ${({width}) => !width && 1};

  background-color: ${({backgroundColor}) => backgroundColor};
  color: ${({color}) => color};
  padding: 20px;
  cursor: ${({onClick}) => onClick && 'pointer'};
  text-align: center;
  text-overflow: ellipsis;
  overflow: show;
  line-height: 10px;
`;

const Divider = styled.div`
  width: 50%;
  display: flex;
`;

const BtnContainer = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  height: 20px;
`;

const StyledImage = styled.img`
  height: 110px;
  width: 110px;
  object-fit: cover;
  margin: 15px 5px;
  border-radius: 20px;
  border: 1px solid ${theme.colors.grey};
`;
const ImageContainer = styled.div`
  border-bottom: 1px solid #d5d5d5;
  display: flex;
`;
const State = styled.div`
  background-color: ${({color}) =>
    color ? color : `${theme.colors.kalsPurple}`};
  width: 120px;
  height: 30px;
  border-radius: 15px;
  position: relative;
  top: -10px;
  left: 20px;
  text-align: center;
  color: white;
  line-height: 30px;
  &:hover {
    cursor: pointer;
  }
`;
const SelcetList = styled.div`
  margin-top: 0;
  width: 120px;
  margin-left: 20px;
  //border-radius: 20px;
  height: 30px;
  text-align: center;
  line-height: 25px;

  position: relative;
  top: -20px;
  background-color: #fafafa;

  &:hover {
    cursor: pointer;
    font-size: 17px;
  }

  &:nth-child(2) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 2px solid ${theme.colors.grey9};
  }
  &:nth-child(3) {
    border-right: 2px solid ${theme.colors.grey9};
    border-left: 2px solid ${theme.colors.grey9};
  }

  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 2px solid ${theme.colors.grey9};
  }
`;

const RequestDetailTemplate = ({
  currentRequest,
  handleListBtnClick,
  groupList,
  statusList,
  currentRequestUid,
}) => {
  const [selectImage, setSelectImage] = useState(null);
  const dispatch = useDispatch();
  const [modeG, setModeG] = useState(1);
  const [modeS, setModeS] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);

  const renderCategory = (category) => {
    if (category == 1) return <State>A</State>;
    if (category == 2) return <State>B</State>;
    if (category == 3) return <State>C</State>;
    if (category == 4) return <State>D</State>;
    else
      return (
        <p
          style={{
            fontSize: '15px',
            textAlign: 'center',
            width: '120px',
            position: 'relative',
            left: '20px',
          }}
        >
          {category}
        </p>
      );
  };

  const rendeGroup = (group, setMode) => {
    if (group === '가맹')
      return (
        <State
          color="#A4A4A4"
          onClick={() => {
            setMode(2);
            setModeS(1);
          }}
        >
          {group}
        </State>
      );
    else
      return (
        <State
          onClick={() => {
            setMode(2);
            setModeS(1);
          }}
        >
          {group}
        </State>
      );
  };

  const renderState = (status, setMode) => {
    if (status === '처리중')
      return (
        <State
          color="#0070C0"
          onClick={() => {
            setMode(2);
            setModeG(1);
          }}
        >
          {status}
        </State>
      );
    if (status === '보류')
      return (
        <State
          color="#FF4343"
          onClick={() => {
            setMode(2);
            setModeG(1);
          }}
        >
          {status}
        </State>
      );
    if (status === '처리완료')
      return (
        <State
          color="#00B050"
          onClick={() => {
            setMode(2);
            setModeG(1);
          }}
        >
          {status}
        </State>
      );
    if (status === '미확인')
      return (
        <State
          color="#A4A4A4"
          onClick={() => {
            setMode(2);
            setModeG(1);
          }}
        >
          {status}
        </State>
      );
    else
      return (
        <State
          onClick={() => {
            {
              setMode(2);
              setModeG(1);
            }
          }}
        >
          {status}
        </State>
      );
  };

  const handleOpenMenu = (event) => {
    if (anchorEl === null) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const formattedTime = useMemo(
    () =>
      `${`${currentRequest?.created_on.split('T')[0]}   ${getFormattedTime(
        currentRequest?.created_on,
      )}`}`,
    [currentRequest?.created_on],
  );
  useEffect(() => {}, [modeG, modeS, currentRequest]);

  const why = 'true';
  return (
    <>
      <Wrapper>
        <Drawer why={why}>
          <Container>
            <LeftContainer>
              <TopContainer>
                <Row>
                  <Box width="120px" backgroundColor="#eff0fc" color="#252525">
                    제목
                  </Box>
                  <Box
                    style={{
                      margin: '-8px 0 0 0px',
                      textAlign: 'center',
                      lineHeight: '15px',
                      fontSize: '13px',
                    }}
                  >
                    {currentRequest?.title}
                  </Box>
                </Row>
                <Row>
                  <Divider>
                    <Box
                      width="120px"
                      backgroundColor="#eff0fc"
                      color="#252525"
                    >
                      매장(점주)
                    </Box>
                    <Box
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {currentRequest?.store_and_owner}
                    </Box>
                  </Divider>
                  <Divider>
                    <Box
                      width="120px"
                      backgroundColor="#eff0fc"
                      color="#252525"
                    >
                      작성일
                    </Box>
                    <Box>{formattedTime}</Box>
                  </Divider>
                </Row>
                <Row>
                  <Divider>
                    <Box
                      width="120px"
                      backgroundColor="#eff0fc"
                      color="#252525"
                    >
                      카테고리
                    </Box>

                    <Box style={{marginLeft: '12px', textAlign: 'left'}}>
                      {renderCategory(currentRequest?.request_category)}
                    </Box>
                  </Divider>
                  <Divider>
                    <Box
                      width="120px"
                      backgroundColor="#eff0fc"
                      color="#252525"
                    >
                      담담 sv
                    </Box>
                    <Box>{currentRequest.supervisor}</Box>
                  </Divider>
                </Row>
                <Row>
                  <Divider>
                    <Box
                      width="120px"
                      backgroundColor="#eff0fc"
                      color="#252525"
                    >
                      분류
                    </Box>
                    <Box style={{marginLeft: '12px', textAlign: 'left'}}>
                      {rendeGroup(currentRequest.group, setModeG)}

                      {modeG === 2 &&
                        groupList.map((group) => {
                          return (
                            <SelcetList
                              onClick={() => {
                                dispatch(
                                  patchRequestGroupAsync({
                                    request_uid: currentRequestUid,
                                    request_group_uid: group.request_group_uid,
                                  }),
                                )
                                  .unwrap()
                                  .then(() => {
                                    dispatch(
                                      getRequestDetailAsync({
                                        request_uid: currentRequestUid,
                                      }),
                                    )
                                      .unwrap()
                                      .then((payload) => {
                                        dispatch(setCurrentRequest(payload));
                                        setModeG(1);
                                      });
                                  });
                              }}
                            >
                              {group.name}
                            </SelcetList>
                          );
                        })}
                    </Box>
                  </Divider>
                  <Divider>
                    <Box
                      width="120px"
                      backgroundColor="#eff0fc"
                      color="#252525"
                    >
                      현황
                    </Box>
                    <Box style={{marginLeft: '12px', textAlign: 'left'}}>
                      {renderState(currentRequest.status, setModeS)}
                      {modeS == 2 &&
                        statusList.map((status) => {
                          return (
                            <SelcetList
                              onClick={() => {
                                dispatch(
                                  patchRequestStatusAsync({
                                    request_uid: currentRequestUid,
                                    request_status_uid:
                                      status.request_status_uid,
                                  }),
                                )
                                  .unwrap()
                                  .then(() => {
                                    dispatch(
                                      getRequestDetailAsync({
                                        request_uid: currentRequestUid,
                                      }),
                                    )
                                      .unwrap()
                                      .then((payload) => {
                                        dispatch(setCurrentRequest(payload));
                                        setModeS(1);
                                      });
                                  });
                              }}
                            >
                              {status.name}
                            </SelcetList>
                          );
                        })}
                    </Box>
                  </Divider>
                </Row>
              </TopContainer>

              <BottomContainer
                style={{whiteSpace: 'pre-line', overflowWrap: 'break-word'}}
              >
                {currentRequest?.contents}
              </BottomContainer>
              {currentRequest?.images && (
                <ImageContainer>
                  {currentRequest?.images?.map((image) => {
                    return (
                      <StyledImage
                        src={image}
                        onClick={() => {
                          dispatch(setImageModal(true));
                          setSelectImage(image);
                        }}
                      />
                    );
                  })}
                </ImageContainer>
              )}
              <BtnContainer>
                <div style={{width: '220px'}} />
                <div style={{width: '80px'}}>
                  <MuiButton onClick={handleListBtnClick} text="목록보기" />
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '220px',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>&nbsp;</div>
                  {/* edit button */}
                  <div style={{width: '100px', marginRight: '20px'}}></div>
                </div>
              </BtnContainer>
            </LeftContainer>
            <RightContainer>
              <Chatting
                currentRequest={currentRequest}
                currentRequestUid={currentRequestUid}
              />
            </RightContainer>
          </Container>
          {selectImage && <ImageDetailModal selectImage={selectImage} />}
        </Drawer>
      </Wrapper>
    </>
  );
};

export default RequestDetailTemplate;
