import React from 'react';
import {useSelector} from 'react-redux';
import {PDFViewer} from '@react-pdf/renderer';
import {selectFranchiseSupplemention} from 'franchise/utils/slices/frSupplementionSlice';
import SupplementationViewPdfTemplate from './template/index';
//import {useLocation} from 'react-router-dom';
//import {saveAs} from 'file-saver';

const SupplementationViewPdf = () => {
  const {supplementionDetailPdf, startDate, endDate} = useSelector(
    selectFranchiseSupplemention,
  );
  //const location = useLocation();
  //const {start, end} = location.state;

  // const saveFile = () => {
  //   pdf(
  //     <SupplementationViewPdfTemplate
  //       supplementionDetailPdf={supplementionDetailPdf}
  //       start={start}
  //       end={end}
  //     />,
  //   )
  //     .toBlob()
  //     .then((blob) => saveAs(blob, 'YourFileName.pdf'));
  // };

  const pdfContent = (
    <PDFViewer style={{width: '100%', height: '100vh'}}>
      <SupplementationViewPdfTemplate
        supplementionDetailPdf={supplementionDetailPdf}
        start={startDate}
        end={endDate}
      />
    </PDFViewer>
  );

  return pdfContent;
};

export default SupplementationViewPdf;
