import React from 'react';
import {Route, Routes} from 'react-router-dom';

import OwlerRoutes from 'owler/routes/owlerRoutes';
import FranchiseRoutes from 'franchise/routes/franchiseRoutes';

import Home from 'shared/pages/Home/index';
import Page404 from 'shared/pages/404page/index';
import PhoneLogin from 'shared/pages/PhoneLogin/index';
import EmailLogin from 'shared/pages/EmailLogin/index';
import Marketing from 'shared/pages/Marketing/index';
import EventList from 'shared/pages/EventList/index';
import EventDetail from 'shared/pages/EventDetail/index';
import OwlerReview from 'shared/pages/OwlerReview/index';

// import TestComponent from 'shared/pages/Test/index';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<Page404 />} />
      <Route path="/" element={<Home />} />
      <Route path="/phoneLogin" element={<PhoneLogin />} />
      <Route path="/emailLogin" element={<EmailLogin />} />
      <Route path="/marketing" element={<Marketing />} />
      <Route path="/eventList" element={<EventList />} />
      <Route path="/eventDetail" element={<EventDetail />} />
      <Route path="/owlerReview" element={<OwlerReview />} />

      {/* <Route path="/test" element={<TestComponent />} /> */}

      {/* owler routing */}
      <Route path="/owler/*" element={<OwlerRoutes />} />

      {/* franchise routing  */}
      <Route path="/franchise/*" element={<FranchiseRoutes />} />
    </Routes>
  );
};

export default AppRoutes;
