const theme = {
  colors: {
    black: '#000',
    danger: '#FF5F5F',
    dynamic: '#571F1C',
    dynamicLight: '#BFB3BF',
    electicYellow: '#F8F800',
    goldYellow: '#FFE600',
    oldYellow: '#f1c868',
    olderYellow: '#e0bf74',
    yellowish: '#F1C868',
    gold: '#FFBD14',
    green: '#B1D9B0',
    green1: '#3DFF64',
    greenDark: '#0ead69',
    grey: '#A7A7A7',
    grey1: '#707070',
    grey2: '#646464',
    grey3: '#787878',
    grey4: '#D2D2D2',
    grey5: '#D8D8D8',
    grey6: '#CACACA',
    grey7: '#C0C0C0',
    grey8: '#8D8D8D',
    grey9: '#d5d5d5',
    kalsPurple: '#9D86FD',
    kalsBlue: '#6A78F0',
    kakaoYellow: '#FEE500',
    lavender: '#E1E1E1',
    light: '#FAFAFA',
    lighterLight: '#F5F5F5',
    lightYellow: '#FFFFA5',
    lightYellow1: '#FFFCED',
    lighterLight2: '#F8F8F8',
    medium: '#BFBFBF',
    mediumLight: '#AAAAAA',
    mediumDark: '#4B4B4B',
    mediumGrey: '#E1E1E1',
    mediumLightGray: '#A8A8A8',
    moodyBlue: '#8773D6',
    naverGreen: '#03C75A',
    newColor1: '#9680F8',
    newLight: '#F0F0F0',
    newPrimary: '#7C61F6',
    notBlack: '#282828',
    paleYellow: '#F2F290',
    pink: '#FF6C72',
    primary: '#8160FF',
    primaryDark: '#5E3BB8',
    purple: '#e0ccec',
    red: '#FF0000',
    red1: '#FF3D3D',
    secondary: '#6F4AFF',
    secondaryLight: '#DFD7F3',
    secondaryLighter: '#EFF0FC',
    secondaryMedium: '#EEEEEE',
    silver: '#C8C8C8',
    sprout: '#B1D9B0',
    skyBlue1: '#9FF6F2',
    skyBlue2: '#5cf7f0',
    violet_nia: '#8F72FF',
    white: '#fff',
    whiteMedium: '#DCDCDC',
    bgSkelet: '#352268',
    skyBlue: '#9FF6F2',
    wheat: '#F9CE8B',
  },
  fontSizes: {
    xsmall: '12px',
    small: '14px',
    medium: '16px',
    large: '18px',
    xlarge: '20px',
    xxlarge: '22px',
    huge: '26px',
    massive: '32px',
    gigantic: '34px',
    gigantic2: '100px',
  },
  spacing: {
    small: '8px',
    medium: '16px',
    large: '32px',
  },
  borderRadius: {
    small: '4px',
    medium: '8px',
    large: '16px',
  },
};

export default theme;
