import React from 'react';
import styled from 'styled-components';
import {useDispatch} from 'react-redux';
import theme from 'shared/styles/theme';
import {Text} from 'shared/components/index';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const StyledWrapper = styled.div`
  width: 100%;
  height: 530px;
  border-bottom: 1px solid ${theme.colors.grey9};

  //border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -32.5px;
`;

const WorkSchedulesWrap = styled.div`
  width: 100%;
  //overflow-y: hidden;
  height: 350px;
  border-bottom: 1px solid ${theme.colors.grey9};
`;

const CurrentEmployeesWrapper = styled.div`
  width: 95%;
  //overflow-y: scroll;
  // display: flex;
  //text-align: center;
  margin: 0 0 0 18px;
  // align-items: center;
  height: 190px;

  .slick-dots {
    display: flex;
    justify-content: center;
    bottom: ${(props) => {
      if (props.length > 30 && props.length <= 60) return '-50px';
      if (props.length > 60) return '-110px';
      return '-50px';
    }};
  }
`;

const EmployeeItem = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  margin-top: 6px;
  margin-bottom: 5px;
  margin-left: 5px;
  width: 160px;
  padding: 5px 0px;
  //background-color: #8b77d9;
  box-shadow: 0px 2px 2px 2px #bdbdbd;
  //border: 1px solid ${theme.colors.primary};
  padding: 5px;
  text-align: center;
  border-radius: 45px;
`;

const ProfilePicture = styled.img`
  width: 47px;
  height: 47px;
  border-radius: 50%;
  margin: 5px 5px 5px 5px;
  //border: 1px solid black;
  background-color: white;
  border: 1px solid ${theme.colors.primary};
`;

const Name = styled.span`
  font-weight: 500;
  color: black;
  font-size: 18px;
`;
const Position = styled.span`
  color: black;
  font-size: 15px;
`;
const TableContainer = styled.div`
  height: 300px; // 원하는 높이로 조절하세요.
  overflow-y: auto;
  margin-left: 5px;
  border-top: 3px solid ${theme.colors.grey9};
  //border-bottom: 3px solid ${theme.colors.grey9};
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-track {
    background: #ffffff;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
const StyledTable = styled.table`
  width: 99%;
  border-collapse: collapse;
  height: 100px;
  font-size: 15px;
`;

const StyledTr = styled.tr`
  border-bottom: 2px solid ${theme.colors.grey9};
  //border: 2px solid ${theme.colors.grey9};
`;

const StyledTd = styled.td`
  padding: 7px 12px;
  text-align: left;
  //border: 1px solid #444444;
  height: 40px;
  min-height: 40px;
`;

const StyledHeaderTd = styled(StyledTd)`
  font-weight: bold;
`;

const CurrentEmployees = ({currentEmployees}) => {
  const settings = {
    dots: true,
    infinite: currentEmployees.current_worker?.length > 3 ? true : false,
    // speed:500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1500,
    lazyLoad: 'progressive',
    arrows: false,
  };
  return (
    <CurrentEmployeesWrapper length={currentEmployees.current_worker?.length}>
      <Text fontSize="large" margin="10px 0 15px -5px" bold>
        현재 근무자: {currentEmployees.current_worker?.length} 명
      </Text>
      <Slider {...settings}>
        {currentEmployees.current_worker?.map((employee, index) => (
          <div key={index}>
            <EmployeeItem>
              <ProfilePicture
                src={employee.image ? employee.image : '/favicon.ico'}
                alt={employee.name}
              />
              <div
                style={{textAlign: 'center', width: '80px', marginTop: '-4px'}}
              >
                <Name>{employee.name}</Name>
                <br></br>
                <Position> {employee.position}</Position>
              </div>
            </EmployeeItem>
          </div>
        ))}
      </Slider>
    </CurrentEmployeesWrapper>
  );
};
const WorkSchedule = ({workSchedules}) => {
  const dispatch = useDispatch();
  return (
    <WorkSchedulesWrap>
      <Text fontSize="large" margin="2px 10px 8px 15px" bold>
        근무표
      </Text>
      <TableContainer>
        <StyledTable>
          <thead>
            <StyledTr>
              <StyledHeaderTd style={{textAlign: 'left'}}>
                근무자명
              </StyledHeaderTd>
              <StyledHeaderTd style={{textAlign: 'center', width: '185px'}}>
                근무 일정
              </StyledHeaderTd>
              <StyledHeaderTd style={{textAlign: 'center', width: '185px'}}>
                출/퇴근
              </StyledHeaderTd>
            </StyledTr>
          </thead>
          <tbody>
            {workSchedules.work_schedule?.map((schedules, i) => (
              <StyledTr key={i}>
                <StyledTd>
                  {schedules.name}/{schedules.position}
                </StyledTd>
                <StyledTd style={{textAlign: 'center'}}>
                  {schedules?.schedule?.map((time, idx) => (
                    <div key={idx}>
                      {new Date(time?.start).getHours()}:
                      {new Date(time?.start)
                        .getMinutes()
                        .toString()
                        .padStart(2, '0')}
                      ~{new Date(time?.end).getHours()}:
                      {new Date(time?.end)
                        .getMinutes()
                        .toString()
                        .padStart(2, '0')}
                    </div>
                  ))}
                </StyledTd>
                <StyledTd style={{textAlign: 'center'}}>
                  {schedules?.attendance?.map((time, idx) => (
                    <div key={idx}>
                      {new Date(time?.arrive).getHours()}:
                      {new Date(time?.arrive)
                        .getMinutes()
                        .toString()
                        .padStart(2, '0')}
                      ~
                      {time.leave !== null ? (
                        `${new Date(time?.leave).getHours()}:${new Date(
                          time?.leave,
                        )
                          .getMinutes()
                          .toString()
                          .padStart(2, '0')}`
                      ) : (
                        <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                      )}
                    </div>
                  ))}
                </StyledTd>
              </StyledTr>
            ))}
          </tbody>
        </StyledTable>
      </TableContainer>
    </WorkSchedulesWrap>
  );
};

const EmployeeStatus = ({workSchedules, currentEmployees}) => {
  return (
    <StyledWrapper>
      <WorkSchedule workSchedules={workSchedules} />

      <CurrentEmployees currentEmployees={workSchedules} />
    </StyledWrapper>
  );
};

export default EmployeeStatus;
