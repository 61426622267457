import React from 'react';
import styled from 'styled-components';
import {
  Drawer,
  TableContent,
  TableData,
  TableDataGroup,
  TableHeader,
  TableRow,
  TableWrapper,
  Wrapper,
} from 'shared/components/index';
import RequestList from './RequestList';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const Container = styled.div`
  margin-left: 10px;
  margin-top: -50px;
`;

const RequestHeader2 = () => {
  return (
    <TableHeader>
      <TableRow width="990px" head margin="0px">
        <TableDataGroup>
          <TableData width="100px">카테고리</TableData>
          <TableData width="70px">분류</TableData>
          <div style={{width: '350px', textAlign: 'center'}}>
            &nbsp;&nbsp;&nbsp;제목
          </div>
        </TableDataGroup>
        <TableDataGroup
          width="475px"
          justifyContent="space-between"
          style={{marginRight: '-83px'}}
        >
          <TableData width="162px">작성 날짜</TableData>
          <TableData width="130px">매장</TableData>
          <TableData width="100px">담당 sv</TableData>
          <TableData>현황</TableData>
        </TableDataGroup>
      </TableRow>
    </TableHeader>
  );
};

const RequestListTemplate = ({
  currentStore,
  requestList,
  currentRequestPage,
  isLoading,
  handleStoreClick,
}) => {
  return (
    <Wrapper
      titleText={currentStore?.store_name}
      handleStoreClick={handleStoreClick}
    >
      <Drawer>
        <Container>
          {isLoading ? (
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '200px',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <TableWrapper width="1080px" margin="0">
              {/* <SRequestHeader /> */}
              <RequestHeader2 />

              <TableContent>
                <RequestList
                  requestList={requestList}
                  currentRequestPage={currentRequestPage}
                />
              </TableContent>
            </TableWrapper>
          )}
        </Container>
      </Drawer>
    </Wrapper>
  );
};

export default RequestListTemplate;
