import {FranchiseDrawer, FranchiseWrapper} from 'shared/components/index';
import styled from 'styled-components';
import {CustomDatePicker2} from 'shared/components/index';
import {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import PdfTitleArea from './PdfTitleArea';
import PdfBodyArea from './PdfBodyArea';
import Pagination from '@mui/material/Pagination';
import theme from 'shared/styles/theme';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import ExtraImageShowModal from './ExtraImageShowModal';

const PaginationContainer = styled.div`
  width: 1050px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;
const CustomPagination = styled(Pagination)`
  & .MuiPaginationItem-page.Mui-selected {
    background-color: ${theme.colors.kalsPurple};
  }
  & .MuiPaginationItem-page.Mui-active {
    background-color: ${theme.colors.kalsPurple};
  }
  & .MuiPaginationItem-page:hover {
    background-color: ${theme.colors.kalsPurple}; // 원하는 색상으로 수정하세요.
  }
  & .MuiPaginationItem-page.Mui-selected:hover {
    background-color: #8f77d9; // 원하는 색상으로 수정하세요.
  }
`;

const Container = styled.div`
  margin-top: -55px;
  margin-left: 60px;
  width: 1050px;
`;
const HeaderArea = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  align-items: center;
`;
const PdfButton = styled.button`
  background-color: #8773d6;
  color: #ffffff;
  border: none;
  padding: 0 10px;
  font-size: 15px;
  height: 30px;
  border-radius: 6px;
  &:hover {
    background-color: #6650c0;
  }
`;
const SupplementationTemplate = ({
  start,
  end,
  formatDate,
  search,
  supplementionList,
  page,
  handlePaginationChange,
  handlePdfButtonClick,
  count,
  isLoading,
  extraImageShowModalOpen,
  setExtraImageShowModalOpen,
}) => {
  const methods = useForm();
  const watchStart = methods.watch('startDate');
  const watchEnd = methods.watch('endDate');
  const [lastChangedDate, setLastChangedDate] = useState(null);

  useEffect(() => {
    if (
      watchStart !== undefined &&
      watchEnd !== undefined &&
      watchStart > watchEnd
    ) {
      alert('기간이 잘못 입력되었습니다!');
      if (lastChangedDate === 'startDate')
        methods.setValue('startDate', undefined);
      if (lastChangedDate === 'endDate') methods.setValue('endDate', undefined);
    }
  }, [watchStart, watchEnd, lastChangedDate, methods]);

  return (
    <FranchiseWrapper titleText="Supplementation">
      <FranchiseDrawer>
        <Container>
          {isLoading ? (
            <Box
              sx={{
                display: 'flex',
                width: '85%',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '150px',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <HeaderArea>
                <CustomDatePicker2
                  methods={methods}
                  setLastChangedDate={setLastChangedDate}
                  search={search}
                  start={start}
                  end={end}
                  formatDate={formatDate}
                />
                <PdfButton onClick={handlePdfButtonClick}>
                  PDF 다운로드
                </PdfButton>
              </HeaderArea>
              <PdfTitleArea
                supplementionList={supplementionList}
                start={start}
                end={end}
              />
              <PdfBodyArea
                supplementionList={supplementionList}
                start={start}
                end={end}
                setExtraImageShowModalOpen={setExtraImageShowModalOpen}
              />
              {supplementionList?.count?.request_count > 10 && (
                <PaginationContainer>
                  <Stack spacing={2}>
                    <CustomPagination
                      onChange={handlePaginationChange}
                      count={count()}
                      siblingCount={4}
                      page={page}
                    />
                  </Stack>
                </PaginationContainer>
              )}
            </>
          )}
        </Container>
        <ExtraImageShowModal
          extraImageShowModalOpen={extraImageShowModalOpen}
          setExtraImageShowModalOpen={setExtraImageShowModalOpen}
        />
      </FranchiseDrawer>
    </FranchiseWrapper>
  );
};

export default SupplementationTemplate;
