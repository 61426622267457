import React from 'react';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import {ImageCarousel, MuiModal, Text} from 'shared/components/index';
import {useDispatch, useSelector} from 'react-redux';
import {
  selectFranchiseQsc,
  setQscCheckDetailModal,
  setQscImage,
  setQscImageModal,
} from 'franchise/utils/slices/frQscSlice';
import theme from 'shared/styles/theme';

const Container = styled.div`
  background-color: white;
  width: 500px;
  height: 400px;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  & > * {
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const ButtonContainer = styled.div`
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const BtnWrapper = styled.button`
  border: none;
  background-color: white;
  margin-left: 20px;
  cursor: pointer;
`;

const ItemName = styled.div`
  width: 80px;
  height: 30px;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  line-height: 30px;
  color: white;
  background-color: ${theme.colors.kalsPurple};
`;

const DetailArea = styled.div`
  width: 420px;
  > div {
    border-top: 1px solid ${theme.colors.grey9};

    width: 100%;
    min-height: 40px;
    display: flex;
    align-items: center;
  }
  // &:last-child {
  //   border-bottom: 1px solid ${theme.colors.grey9};
  // }
`;
const NoteArea = styled.div`
  font-size: 13px;
  min-height: 100px;
  height: 100px;
  padding: 5px 0;
  align-items: stretch !important;
  overflow: auto;
  white-space: pre-wrap;
`;
const ViewQscDetailModal = ({scoreTitleList}) => {
  const dispatch = useDispatch();
  const {qscCheckDetailModal, qscCheckDetail, qscCheckCatName} =
    useSelector(selectFranchiseQsc);

  const handleModalClose = () => {
    dispatch(setQscCheckDetailModal(false));
  };
  const setScore = (num) => {
    const score = scoreTitleList.find((score, index) => index === num);
    return score;
  };
  return (
    <MuiModal open={qscCheckDetailModal} onClose={handleModalClose}>
      <Container>
        <TitleWrapper>
          <Text style={{width: '280px'}} bold fontSize="lg">
            평가 상세
          </Text>

          <ButtonContainer>
            <BtnWrapper onClick={handleModalClose}>
              <CloseIcon />
            </BtnWrapper>
          </ButtonContainer>
        </TitleWrapper>
        <DetailArea>
          <div>
            <ItemName>카테고리명</ItemName>
            <Text style={{width: '300px'}}>{qscCheckCatName}</Text>
          </div>
          <div>
            <ItemName>항목명</ItemName>
            <Text style={{width: '300px'}}>{qscCheckDetail?.name}</Text>
          </div>
          <div>
            <ItemName>점수</ItemName>
            <Text style={{width: '300px'}}>
              {setScore(qscCheckDetail?.index)}
            </Text>
          </div>
          <NoteArea>
            {qscCheckDetail?.note ? (
              qscCheckDetail?.note
            ) : (
              <p style={{color: '#bdbdbd'}}>작성된 내용이 없습니다.</p>
            )}
          </NoteArea>
          <div>
            <ItemName>첨부 이미지</ItemName>
          </div>
        </DetailArea>
      </Container>
    </MuiModal>
  );
};
export default ViewQscDetailModal;
