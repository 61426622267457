/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import styled from 'styled-components';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

const StyleTextField = styled(TextField)`
  width: 380px;
`;

export default function InputWithIcon({onChange, value}) {
  return (
    <div>
      <StyleTextField
        size="small"
        id="input-with-icon-textfield"
        onChange={onChange}
        value={value}
        placeholder="지점명을 입력해주세요 ex) 강남점"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}
