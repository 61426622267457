/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/prop-types */
import React from 'react';
import {Page, Document, StyleSheet, Font} from '@react-pdf/renderer';
import theme from 'shared/styles/theme';
import PdfHeader from './PdfHeader';
import PdfBody from './PdfBody';

Font.register({
  family: 'Nanum Gothic',
  src: 'https://fonts.gstatic.com/ea/nanumgothic/v5/NanumGothic-Regular.ttf',
});

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    padding: 15,
    paddingTop: 15,
  },
  body: {
    display: 'flex',
    width: '100%',
    color: 'black',
    border: 1,
    borderBottom: 0,
    minHeight: '24px',
    textAlign: 'center',
    fontSize: 10,
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#dfd7f3',
    borderBottom: 1,
    height: '24px',
  },
  image: {
    marginTop: 3,
    marginBottom: 3,
    height: 52.5,
    width: 70,
    objectFit: 'contain',
  },
  text: {
    textAlign: 'center',
    verticalAlign: 'middle',
    fontSize: 9,
    fontFamily: 'Nanum Gothic',
    color: theme.colors.notBlack,
    fontWeight: 'thin',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: 1,
    minHeight: '24px',
  },
});

// Create Document Component
const SupplementationViewPdfTemplate = ({
  supplementionDetailPdf,
  start,
  end,
}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <PdfHeader
          supplementionDetailPdf={supplementionDetailPdf}
          start={start}
          end={end}
        />
        <PdfBody supplementionDetailPdf={supplementionDetailPdf} />
      </Page>
    </Document>
  );
};

export default SupplementationViewPdfTemplate;
