import {
  Wrapper,
  Text,
  Drawer,
  WeekNumberSelector,
  YearNumberSelector,
  CustomDatePicker,
} from 'shared/components/index';
import theme from 'shared/styles/theme';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import CorporationDashboard from './CorporationDashboard';

const DashContainer = styled.div`
  width: 1180px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -16px;
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
`;

const LeftDashWrapper = styled.div`
  width: 555px;
  min-width: 555px;
  border-right: 2px solid ${theme.colors.grey9};
  //border-radius: 15px;
  margin: 0 0px 0 0px;
  //padding: 15px;
`;

const RightDashWrapper = styled.div`
  width: 555px;
  height: 690px;
  min-width: 555px;
`;

const TopWrapper = styled.div`
  width: 100%;
  height: 302px;
  border-bottom: 2px solid ${theme.colors.grey9};
`;

const BottomWrapper = styled.div`
  width: 100%;
  height: 350px;
`;

const CorporationTemplate = ({
  corpCurrentStore,
  handleStoreClick,
  handleBrandStoreClick,
}) => {
  return (
    <Wrapper
      titleText={corpCurrentStore.store_name}
      overflow="visible"
      handleStoreClick={handleStoreClick}
    >
      <Drawer>
        <DashContainer>
          <CorporationDashboard handleBrandStoreClick={handleBrandStoreClick} />
        </DashContainer>
      </Drawer>
    </Wrapper>
  );
};

export default CorporationTemplate;
