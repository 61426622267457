import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import {
  selectTask,
  setAddCategoryModalInput,
  setCategoryDeleteModal,
  setCategorySettingsModal,
  setEditCategoryModel,
} from 'owler/utils/slices/taskSlice';
import {MuiButton, MuiModal, Text} from 'shared/components/index';
import theme from 'shared/styles/theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 40px;
  align-items: center;
  overflow: hidden;
  & > * {
    margin-bottom: 30px;
  }
  & > :last-child {
    margin-bottom: 0px;
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
`;

const ButtonSeparator = styled.div`
  margin: 0 10px 0 10px;
`;

const ButtonContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CategorySettingsModal = () => {
  const {t} = useTranslation();
  const {categorySettingsModal, selectedCategory} = useSelector(selectTask);
  const dispatch = useDispatch();

  const handleModalClose = () => {
    dispatch(setCategorySettingsModal(false));
  };

  const handleDeleteBtn = () => {
    dispatch(setCategorySettingsModal(false));
    dispatch(setCategoryDeleteModal(true));
  };
  const handleModifyBtn = () => {
    dispatch(setAddCategoryModalInput(selectedCategory?.title));
    dispatch(setEditCategoryModel(true));
    dispatch(setCategorySettingsModal(false));
  };

  return (
    <MuiModal open={categorySettingsModal} onClose={handleModalClose}>
      <Container>
        <TitleWrapper>
          <Text bold fontSize="xl">
            카테고리: {selectedCategory?.name}
          </Text>
        </TitleWrapper>

        <ButtonContainer>
          <MuiButton
            onClick={handleModalClose}
            variant="contained"
            backgroundColor={theme.colors.grey}
            text={t('buttons.cancel')}
          />
          <ButtonSeparator>
            <MuiButton
              onClick={handleDeleteBtn}
              variant="contained"
              backgroundColor={theme.colors.danger}
              text={t('buttons.delete')}
            />
          </ButtonSeparator>

          <MuiButton
            onClick={handleModifyBtn}
            variant="contained"
            backgroundColor={theme.colors.kalsPurple}
            text={t('buttons.edit')}
          />
        </ButtonContainer>
      </Container>
    </MuiModal>
  );
};
export default CategorySettingsModal;
