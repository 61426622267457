import React, {useCallback} from 'react';
import styled from 'styled-components';
import {useDispatch} from 'react-redux';
import theme from 'shared/styles/theme';
import {Text} from 'shared/components/index';
import Button from '@mui/material/Button';
import {useNavigate} from 'react-router-dom';
import CircleIcon from '@mui/icons-material/Circle';

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  & > div:not(:last-child) {
    border-bottom: 1px solid ${theme.colors.grey9};
  }
  margin-top: -34px;
`;

const Container = styled.div`
  height: 368px;
  width: 100%;
`;
const TitleArea = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ListArea = styled.div`
  margin: 15px 0 0 10px;
`;
const List = styled.div`
    display: flex;
    align-items::center;
    width: 100%;
    margin: 10px 0;
    font-size: 13px;
`;
const TodaySchedule = ({handleExtra, todaySchedule}) => {
  return (
    <Container>
      <TitleArea>
        <Text fontSize="large" margin="2px 0 0 20px" bold>
          금일 일정
        </Text>
        <Button
          onClick={() => handleExtra('calendar')}
          style={{color: 'black', margin: '-3px 0 0 0'}}
        >
          더보기 &gt;
        </Button>
      </TitleArea>
      <ListArea>
        {todaySchedule?.length > 0 ? (
          todaySchedule?.map((schedule, index) => {
            return (
              <List>
                <CircleIcon
                  style={{fontSize: '12px', margin: '3px 0 0 10px'}}
                />
                <div>
                  <Text
                    style={{
                      width: '500px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    margin="-15px 0 0 10px"
                    bold
                  >
                    {schedule.title}
                  </Text>
                </div>
              </List>
            );
          })
        ) : (
          <Text
            margin="0 0 0 10px"
            style={{fontSize: '13px', color: '#bdbdbd'}}
          >
            등록된 금일 일정이 없습니다.
          </Text>
        )}
      </ListArea>
    </Container>
  );
};

const NewRequest = ({handleExtra, newRequestList}) => {
  return (
    <Container>
      <TitleArea>
        <Text fontSize="large" margin="10px 0 0 20px" bold>
          신규 건의/요청
        </Text>
        <Button onClick={() => handleExtra('request')} style={{color: 'black'}}>
          더보기 &gt;
        </Button>
      </TitleArea>
      <ListArea>
        {newRequestList?.length > 0 ? (
          newRequestList?.map((request, index) => {
            return (
              <List>
                <CircleIcon
                  style={{fontSize: '12px', margin: '3px 0 0 10px'}}
                />
                <div>
                  <Text
                    style={{
                      color: 'black',
                      fontSize: '20px',
                      margin: '-5px 0 0 7px',
                    }}
                  >
                    {request.title}
                  </Text>
                  <Text
                    style={{
                      color: 'grey',
                      fontSize: '18px',
                      margin: '-5px 0 0 7px',
                    }}
                  >
                    {request.branch_name}
                  </Text>
                  <Text
                    style={{
                      color: 'grey',
                      fontSize: '16px',
                      margin: '-5px 0 0 7px',
                    }}
                  >
                    {request.category}
                  </Text>
                </div>
              </List>
            );
          })
        ) : (
          <Text
            margin="0 0 0 10px"
            style={{fontSize: '13px', color: '#bdbdbd'}}
          >
            신규 건의/요청 사항이 없습니다.
          </Text>
        )}
      </ListArea>
    </Container>
  );
};
const UnResponseNotice = ({handleExtra, unCheckNotice}) => {
  return (
    <Container>
      <TitleArea>
        <Text fontSize="large" margin="10px 0 0 20px" bold>
          미확인 매장이 있는 공지
        </Text>
        <Button onClick={() => handleExtra('notices')} style={{color: 'black'}}>
          더보기 &gt;
        </Button>
      </TitleArea>
    </Container>
  );
};

const HeadDiscription = ({
  currentStore,
  todaySchedule,
  newRequestList,
  unCheckNotice,
}) => {
  const navigate = useNavigate();

  const handleExtra = useCallback((type) => {
    if (type === 'calendar') {
      navigate(`/owler/calendar/${currentStore?.store_uid}`);
    }
    if (type === 'request') {
      navigate(`/owler/request/${currentStore?.store_uid}`);
    }
    if (type === 'notices') {
      navigate(`/owler/notices/${currentStore?.store_uid}`);
    }
  }, []);

  return (
    <StyledWrapper>
      <TodaySchedule handleExtra={handleExtra} todaySchedule={todaySchedule} />
      <NewRequest handleExtra={handleExtra} newRequestList={newRequestList} />
      {/* <UnResponseNotice
        handleExtra={handleExtra}
        unCheckNotice={unCheckNotice}
      /> */}
    </StyledWrapper>
  );
};

export default HeadDiscription;
