/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer';
import theme from 'shared/styles/theme';

import QscMemoTable from './QscMemoTable';
import QscCategoryListTable from './QscCategoryListTable';
import QscScoreTable from './QscScoreTable';

Font.register({
  family: 'Nanum Gothic',
  src: 'https://fonts.gstatic.com/ea/nanumgothic/v5/NanumGothic-Regular.ttf',
});

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    padding: 15,
    paddingTop: 20,
  },
  header: {
    marginTop: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  image: {
    height: 80,
    width: 80,
    objectFit: 'cover',
    marginRight: 10,
    border: 1,
  },
  storeName: {
    fontSize: 10,
    fontFamily: 'Nanum Gothic',
    color: theme.colors.notBlack,
    maxWidth: '77%',
  },
  storeInfoWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    border: 0.5,
    borderColor: theme.colors.notBlack,
    flexDirection: 'row',
    padding: 10,
    width: '55%',
  },
  signaturesWrap: {
    width: '40%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    padding: 10,
  },
  signLineWrap: {
    width: 50,
    height: 60,
    border: 0.5,
    borderColor: theme.colors.notBlack,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 5,
    marginLeft: 4,
  },
  signatureImage: {
    height: 40,
    width: 40,
    marginBottom: 5,
    borderBottom: 0.3,
    borderColor: theme.colors.notBlack,
  },
  positionName: {
    fontSize: 5,
    fontFamily: 'Nanum Gothic',
    color: theme.colors.notBlack,
  },

  table: {
    display: 'table',
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: 5,
    borderBottomWidth: 0.5,
  },
  tableRow: {
    flexDirection: 'row',
    borderTopWidth: 0.6,
    borderColor: theme.colors.notBlack,
  },
  tableCell: {
    width: '25%',
    padding: 5,
    borderRightWidth: 0.5,
    borderLeftWidth: 0.7,
    borderColor: theme.colors.notBlack,
  },
  descriptionCell: {
    backgroundColor: theme.colors.secondaryLight,
    textAlign: 'right',
    borderRight: 'none',
  },
  text: {
    textAlign: 'center',
    verticalAlign: 'middle',
    fontSize: 9,
    fontFamily: 'Nanum Gothic',
    color: theme.colors.notBlack,
    fontWeight: 'thin',
  },
});

// Create Document Component
const QscToPdfDocument = ({qscExportInfo}) => {
  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    const hours = `0${date.getHours()}`.slice(-2);
    const minutes = `0${date.getMinutes()}`.slice(-2);
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.storeInfoWrap}>
            {qscExportInfo?.store_info.logo !== null ? (
              <Image
                style={styles.image}
                source={qscExportInfo?.store_info.logo}
              />
            ) : (
              <View style={styles.image} />
            )}
            <Text style={styles.storeName}>
              {qscExportInfo?.store_info?.store_name.length > 40
                ? `${qscExportInfo?.store_info?.store_name.slice(0, 40)}...`
                : qscExportInfo?.store_info?.store_name}
            </Text>
          </View>

          {/* Signatures: */}
          <View style={styles.signaturesWrap}>
            {qscExportInfo?.approval_line?.map((signature, indx) => (
              <View key={indx.toString()} style={styles.signLineWrap}>
                {signature.signature !== null ? (
                  <Image
                    style={styles.signatureImage}
                    source={signature.signature}
                  />
                ) : (
                  <View style={styles.signatureImage} />
                )}
                <Text style={styles.positionName}>{signature?.position}</Text>
              </View>
            ))}
          </View>
        </View>

        {/* QSC INFO TABLE  */}
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={[styles.tableCell, styles.descriptionCell]}>
              <Text style={styles.text}>방문일자</Text>
            </View>
            <View
              style={[
                styles.tableCell,
                {
                  borderRight: 0,
                },
              ]}
            >
              <Text style={[styles.text]}>
                {formatDate(qscExportInfo?.qsc_info?.visit_date)}
              </Text>
            </View>
            <View style={[styles.tableCell, styles.descriptionCell]}>
              <Text style={styles.text}>담당자</Text>
            </View>
            <View style={styles.tableCell}>
              <Text style={styles.text}>
                {qscExportInfo?.qsc_info?.supervisor}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={[styles.tableCell, styles.descriptionCell]}>
              <Text style={styles.text}>직전 방문일자</Text>
            </View>
            <View
              style={[
                styles.tableCell,
                {
                  borderRight: 0,
                },
              ]}
            >
              <Text style={styles.text}>
                {qscExportInfo?.qsc_info?.last_visit
                  ? formatDate(qscExportInfo?.qsc_info?.last_visit)
                  : ''}
              </Text>
            </View>
            <View style={[styles.tableCell, styles.descriptionCell]}>
              <Text style={styles.text}>직전 총점</Text>
            </View>
            <View style={styles.tableCell}>
              <Text style={styles.text}>
                {qscExportInfo?.qsc_info?.last_score}
              </Text>
            </View>
          </View>
        </View>

        {/* QSC MEMO TABLE  */}
        <QscMemoTable memoList={qscExportInfo?.memo_list} />

        {/* QSC CATEGORY LIST TABLE  */}
        <QscCategoryListTable
          categoryList={qscExportInfo?.category_list}
          scoreTitleList={qscExportInfo?.score_title_list}
        />

        {/* QSC SCORE TABLE  */}
        <QscScoreTable
          score={qscExportInfo?.score}
          scoreTitleList={qscExportInfo?.score_title_list}
        />
      </Page>
    </Document>
  );
};

export default QscToPdfDocument;
