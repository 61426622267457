import styled from "styled-components";

const GlobalStyles = styled.div`
  .fc-daygrid-block-event {
    & .fc-event-title {
      padding: 0 0 0 3px;
    }
    margin-bottom: 3px;
  }
  .fc-timegrid-slot{
    font-size: 12px;
  }
  .fc-event-title{
    font-size: 12px;
  }
  .fc-timegrid-axis-cushion{
    max-width: 71px!important;
  }
  .fc-toolbar-chunk{
    font-size: 14px;

    &:first-child {
        margin: 5px 0 0 20px;
        font-size: 14px;

      }

    &:last-child {
      position: relative;
      top:45px;
      left: -200px;
      font-size: 15px;
    }
  }
  .fc-daygrid-event-dot{
    width: 20px;
    height: 20px;
    border: 3px solid black;
    border-radius: 4px;
  }
  .fc-event-time{
    font-size: 10px;
  }
  .fc-event-title{
    font-size: 20px;
  }
  .fc-theme-standard td{
    border: none !important
  }
  .fc-myCustomButton-button{
    background-color: white;
    border: none;
    color: #585858;
    &:hover{
      background-color: #FAFAFA;
      border: none;
      color:  #585858;
    }
    &:active{
      background-color: #F2F2F2 !important;
      border: none !important;
      color:  #585858!important;
    }
  }
  .fc-scrollgrid-section-header{
    display: none;
  }
  .fc-view-harness{
    margin-top: 20px;
  }
  .fc-button-primary{
    &:hover{
        background-color: #FAFAFA;
        border: none;
        color:  #585858;
      }
      &:active{
        background-color: #F2F2F2 !important;
        border: none !important;
        color:  #585858!important;
      }
  }
  .fc-theme-standard .fc-scrollgrid {
    border: 1px solid #fff;
    }
    .fc-daygrid-event{
        border: none;
        color: black !important;
    }
    .custom-event-time{
        color: black !important;
    }
    .fc-daygrid-dot-event:hover {
        background: rgba(0, 0, 0, 0);
    }
    .fc-event:hover{
        cursor: pointer;
        transform: scale(1.01)
    }
    .fc-day-today{
        background-color: white !important;
    }
`

export default GlobalStyles