import {Text, FranchiseDrawer, FranchiseWrapper} from 'shared/components/index';
import styled from 'styled-components';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {Button} from '@mui/material';

const Container = styled.div`
  margin-top: 20px;
  display: flex;
  margin: 0 0 0 60px;
  justify-content: space-between;
  flex-direction: column;
  & > Button {
    margin: 10px 0;
  }
  width: 1050px;
`;
const Container2 = styled.div`
  margin-left: 60px;
`;

const SettingButton = styled(Button)`
  width: 100%;
  height: 80px;
`;

const Enter2 = styled(ArrowRightIcon)`
  position: relative;
  top: 5px;
  left: 173px;
`;
const Enter = styled(ArrowRightIcon)`
  position: relative;
  top: 5px;
  left: 210px;
`;
const TextArea = styled.div`
  diplay: flex;
  flex-direction: row;
  margin-left: -150px;
  width: 150px;
  text-align: left;
`;

const SettingContainer = styled.div`
  height: 480px;
  width: 35%;
  margin-left: 0%;
  border-radius: 20px;
  box-shadow: 0px 2px 2px 2px #bdbdbd;
`;

const SettingsTemplate = ({
  handlePositionManagement,
  handleWorkerManagement,
  handlePaymentManagement,
  handleStoreReqManagement,
  handleStoreManagement,
  handleSignManagement,
}) => {
  return (
    <FranchiseWrapper titleText="Settings">
      <FranchiseDrawer>
        <Container2>
          <Text bold fontSize="large"></Text>
        </Container2>
        <Container>
          <SettingContainer>
            <SettingButton
              sx={{
                color: '#000000',
                fontWeight: '600',
                fontSize: '18px',
                backgroundColor: '#ffffff',
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px',
              }}
              onClick={handlePositionManagement}
            >
              <TextArea>
                직원 합류
                <Enter />
              </TextArea>
            </SettingButton>

            <SettingButton
              sx={{
                color: '#000000',
                fontWeight: '600',
                fontSize: '18px',
                backgroundColor: '#ffffff',
              }}
              onClick={handleWorkerManagement}
            >
              <TextArea>
                직원 관리
                <Enter />
              </TextArea>
            </SettingButton>

            <SettingButton
              sx={{
                color: '#000000',
                fontWeight: '600',
                fontSize: '18px',
                backgroundColor: '#ffffff',
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px',
              }}
              onClick={handlePaymentManagement}
            >
              <TextArea>
                결재 관리
                <Enter />
              </TextArea>
            </SettingButton>

            <SettingButton
              sx={{
                color: '#000000',
                fontWeight: '600',
                fontSize: '18px',
                backgroundColor: '#ffffff',
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px',
              }}
              onClick={handleSignManagement}
            >
              <TextArea>
                서명 관리
                <Enter />
              </TextArea>
            </SettingButton>

            <SettingButton
              sx={{
                color: '#000000',
                fontWeight: '600',
                fontSize: '18px',
                backgroundColor: '#ffffff',
                margin: '0 0 0 0',
              }}
              onClick={handleStoreManagement}
            >
              <TextArea>
                담당 매장 관리
                <Enter2 />
              </TextArea>
            </SettingButton>

            <SettingButton
              sx={{
                color: '#000000',
                fontWeight: '600',
                fontSize: '18px',
                backgroundColor: '#ffffff',
                margin: '0 0px',
                borderBottomLeftRadius: '20px',
                borderBottomRightRadius: '20px',
              }}
              onClick={handleStoreReqManagement}
            >
              <TextArea>
                매장 합류 신청
                <Enter2 />
              </TextArea>
            </SettingButton>
          </SettingContainer>
        </Container>
      </FranchiseDrawer>
    </FranchiseWrapper>
  );
};

export default SettingsTemplate;
