/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useLocation} from 'react-router-dom';
import theme from 'shared/styles/theme';
import {selectNotice} from 'owler/utils/slices/noticeSlice';
//import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import {useSelector} from 'react-redux';
import {selectUser} from 'owler/utils/slices/userSlice';
import {selectStore} from 'owler/utils/slices/storeSlice';
import styled, {keyframes} from 'styled-components';
import {Text} from '../index';
import {MuiButton} from 'shared/components/index';
import useLogout from 'shared/utils/hooks/useLogout';
import LogoutIcon from '@mui/icons-material/Logout';

const drawerWidth = 170;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const StyledDrawer = styled(Drawer)`
  &.MuiDrawer-root .MuiDrawer-paper {
    position: absolute;
    height: 900px;
    top: ${(props) => (props.why ? '-50px' : '-162.9px')};
    left: -28px; // 부모 요소를 기준으로 왼쪽에 배치하려면 이 속성을 사용합니다.
  }
`;

const StyledBox = styled(Box)`
  width: 1280px;
`;
const Content = styled.div`
  flex: 1;
  margin: 0 auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const StyledBox2 = styled(Box)`
  position: relative;
  min-width: 1300px;
  @media (max-width: 1300px) {
    margin-left: 20px;
  }
  @media (min-width: 1300px) and (max-width: 1304px) {
    margin-left: 18px;
  }
  @media (min-width: 1305px) and (max-width: 1308px) {
    margin-left: 16px;
  }
  @media (min-width: 1309px) and (max-width: 1312px) {
    margin-left: 14px;
  }
  @media (min-width: 1313px) and (max-width: 1316px) {
    margin-left: 12px;
  }
  @media (min-width: 1317px) and (max-width: 1320px) {
    margin-left: 10px;
  }
  @media (min-width: 1321px) and (max-width: 1324px) {
    margin-left: 8px;
  }
  @media (min-width: 1325px) and (max-width: 1328px) {
    margin-left: 6px;
  }
  @media (min-width: 1329px) and (max-width: 1332px) {
    margin-left: 4px;
  }
  @media (min-width: 1333px) and (max-width: 1336px) {
    margin-left: 2px;
  }
  @media (min-width: 1337px) and (max-width: 1340px) {
    margin-left: 1px;
  }

  diplay: flex;
  justify-content: center;
`;
const MainBox = styled(Box)`
  position: relative;
  left: 80px;
`;
const AnimatedListItem = styled(ListItem)`
  animation: ${fadeIn} 0.2s forwards;
  animation-delay: ${(props) =>
    props.index * 0.3}s; // 인덱스에 따라 딜레이 증가
  opacity: 0; // 처음에는 투명하게 시작
`;

const UserInfo = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  padding: 10px 0;
  //border: 1px solid black;
`;
const UserImage = styled.img`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 1px solid ${theme.colors.grey9};
  margin-top: 0px;
  margin-left: 10px;
`;
const InfoArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 90px;
  margin-left: 5px;
  //border: 1px solid black;
`;
function ResponsiveDrawer({children, why}) {
  const {t} = useTranslation();
  const {userInfo} = useSelector(selectUser);
  const {currentStore} = useSelector(selectStore);
  const {member_uid} = useSelector(selectNotice);
  const location = useLocation();
  const navigate = useNavigate();
  const {logUserOut} = useLogout();

  const checkLoc = useCallback(
    (cur) => {
      if (location.pathname === cur) {
        return theme.colors.kalsBlue;
      } else if (location.pathname.startsWith(cur)) {
        return theme.colors.black;
      }
      return theme.colors.black;
    },
    [location.pathname],
  );

  const mainRoutes = [
    {
      name: t('buttons.dashboard'),
      link: `/owler/store/${currentStore?.store_uid}`,
    },
    {
      name: t('buttons.task_title_btn'),
      link: `/owler/tasks/${currentStore?.store_uid}`,
    },
    {
      name: t('buttons.work_register'),
      link: `/owler/workRegister/${currentStore?.store_uid}`,
    },
    {
      name: t('buttons.notice_title_btn'),
      link: `/owler/notices/${currentStore?.store_uid}`,
    },
    {
      name: t('buttons.calender'),
      link: `/owler/calendar/${currentStore?.store_uid}`,
    },
    // {
    //   name: t('buttons.schedule_title_btn'),
    //   link: `/owler/workSchedule/${currentStore?.store_uid}`,
    // },
    {
      name: t('buttons.suggestion'),
      link: `/owler/request/${currentStore?.store_uid}`,
      hideIf: currentStore.is_hq === false,
    },
    {
      name: t('buttons.owler_settings'),
      link: `/owler/settings/${currentStore?.store_uid}`,
      hideIf: member_uid?.authority_level > 1,
    },
    {
      name: '매장 리스트',
      link: `/owler/storeList`,
    },
  ].filter((route) => {
    if (route.hideIf) return false;
    if (currentStore.is_corporation) {
      return ![
        `/owler/tasks/${currentStore?.store_uid}`,
        `/owler/notices/${currentStore?.store_uid}`,
        `/owler/calendar/${currentStore?.store_uid}`,
      ].includes(route.link);
    }
    return true;
  });

  const handleUserClickLogout = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('정말 로그아웃하시겠습니까?')) {
      logUserOut();
    }
  };

  const handleNavigation = useCallback(
    (_route) => {
      navigate(_route.link);
    },
    [navigate],
  );
  const drawer = (
    <div>
      <Toolbar />
      <Toolbar />
      {/* <ListItem>
        <ListItemButton>
          <ListItemText primary={userInfo?.name} />

          <ListItemText
            disableTypography
            primary={
              <Typography
                variant="body2"
                style={{color: theme.colors.medium, marginLeft: '-80px'}}
              >
                {currentStore?.position}
              </Typography>
            }
          />
        </ListItemButton>
      </ListItem> */}
      <UserInfo>
        <UserImage
          src={
            member_uid?.image !== null ? member_uid?.image : '/empty_user.png'
          }
        />
        <InfoArea>
          <Text
            style={{
              width: '100%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'center',
              fontSize: '18px',
            }}
            bold
          >
            {member_uid?.name}
          </Text>
          <Text
            style={{
              width: '100%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'center',
              fontSize: '14px',
            }}
          >
            {member_uid?.position_name}
          </Text>
          <MuiButton
            fontSize="9.5px"
            onClick={handleUserClickLogout}
            backgroundColor="#A4A4A4"
            textColor="#f8f8f8"
            variant="contained"
            text={t('buttons.logout')}
            style={{
              padding: '0px!important',
              height: '25px',
              margin: '7px 0 0 0',
              minWidth: '0px!important',
              width: '100px',
            }}
            endIcon={<LogoutIcon style={{fontSize: '13px'}} />}
          />
        </InfoArea>
      </UserInfo>

      <Divider />
      <List>
        {mainRoutes.map((route, index) => {
          if (index === mainRoutes.length - 1) {
            return (
              <AnimatedListItem key={route.name}>
                <ListItemButton onClick={() => handleNavigation(route)}>
                  <ListItemText
                    primary={route.name}
                    style={{color: checkLoc(route.link)}}
                  />
                </ListItemButton>
              </AnimatedListItem>
            );
          }
        })}
      </List>
      <Divider />

      <List>
        {mainRoutes.map((route, index) => {
          if (index !== mainRoutes.length - 1) {
            return (
              <AnimatedListItem key={route.name}>
                <ListItemButton onClick={() => handleNavigation(route)}>
                  <ListItemText
                    primary={route.name}
                    style={{color: checkLoc(route.link)}}
                  />
                </ListItemButton>
              </AnimatedListItem>
            );
          }
        })}
      </List>

      {/* <Divider />

      <List>
        {['Franchise', 'QSC'].map((text, index) => (
          <ListItem key={text}>
            <ListItemButton>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
    </div>
  );

  return (
    <StyledBox2 sx={{display: 'flex'}}>
      <StyledBox
        component="nav"
        sx={{width: {sm: 0}, flexShrink: {sm: 0}}}
        aria-label="mailbox folders"
      >
        <StyledDrawer
          variant="permanent"
          sx={{
            display: {xs: 'block', sm: 'block'},
            '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
          }}
          open
          why={why}
        >
          {drawer}
        </StyledDrawer>
      </StyledBox>

      <MainBox component="main" sx={{flexGrow: 1, p: 3}}>
        <Content>{children}</Content>
      </MainBox>
    </StyledBox2>
  );
}

export default ResponsiveDrawer;
