import React, {useCallback, useEffect, useState} from 'react';

import WorkerManagementTemplate from './template/index';
import {useDispatch, useSelector} from 'react-redux';

import {
  getSupervisorListAsync,
  selectFranchiseUser,
  setChangeSvPositionModal,
  setSelectedSvUserPosition,
  patchSupervisorPermissionAsync,
} from 'franchise/utils/slices/frUserSlice';

const WorkerManagement = () => {
  const dispatch = useDispatch();
  const {supervisorList, supervisorData} = useSelector(selectFranchiseUser);
  const [loading, setLoading] = useState(false);
  const [selectedSvMemeber, setSelectedSvMember] = useState(null);

  useEffect(() => {
    setLoading(true);
    dispatch(getSupervisorListAsync(supervisorData));
    setLoading(false);
    return () => {
      dispatch(setChangeSvPositionModal(false));
    };
  }, [dispatch, supervisorData]);

  const handleSvSelect = useCallback(
    (_user) => {
      setSelectedSvMember(_user);
      dispatch(setSelectedSvUserPosition(_user));
      dispatch(setChangeSvPositionModal(true));
    },
    [dispatch],
  );

  const haddleChangeSvPermission = useCallback(
    (_isAdmin) => {
      dispatch(
        patchSupervisorPermissionAsync({
          sv_user_uid: selectedSvMemeber?.sv_user_uid,
          is_admin: _isAdmin,
        }),
      )
        .unwrap()
        .then(() => {
          dispatch(getSupervisorListAsync(supervisorData));
        });
    },
    [dispatch, selectedSvMemeber, supervisorData],
  );

  const handleFireSv = useCallback((_user) => {
    if (
      window.confirm(
        '해당 인원을 퇴사처리 하시겠습니까?\n퇴사 이후에도 작성한 QSC는 보관됩니다.',
      )
    ) {
      alert('퇴사하였습니다.');
    }
  }, []);

  return (
    <WorkerManagementTemplate
      loading={loading}
      supervisorList={supervisorList}
      handleSvSelect={handleSvSelect}
      haddleChangeSvPermission={haddleChangeSvPermission}
      handleFireSv={handleFireSv}
      setSelectedSvMember={setSelectedSvMember}
    />
  );
};

export default WorkerManagement;
