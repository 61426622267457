import React, {useState} from 'react';
import styled from 'styled-components';
import {
  ArrowIndicator,
  TableData,
  TableDataGroup,
} from 'shared/components/index';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import theme from 'shared/styles/theme';
import TaskView from './TaskView';

const Wrapper = styled.div`
  margin-bottom: 10px;
`;

const TaskCategoryWrapper = styled.div`
  width: 100%;
  border: 1px solid black;
  padding: 5px;
  margin-bottom: 5px;
  border: ${({theme}) => `1px solid ${theme.colors.grey9}`};
  border-radius: 10px;
  padding: 5px 20px;
  display: flex;
  &:hover {
    & > * {
      cursor: pointer;
    }
    & a {
      font-weight: bold;
      text-decoration: underline;
    }
    border: ${({theme}) => `1px solid ${theme.colors.medium}`};
    background-color: ${({theme}) => theme.colors.lightYellow1};
  }
`;

const MasterTasksTitle = styled.div`
  cursor: pointer;
  font-size: 15px;
`;

const TasksListWrapper = styled.div`
  margin-left: 10px;
`;

const MasterTaskView = ({
  masterTasks,
  handleCreateNewTaskBtn,
  handleCategorySettingsBtn,
  handleTaskClickBtn,
  changeCompleted,
}) => {
  const [show, setShow] = useState(true);

  const handleTitleClick = () => {
    setShow(!show);
  };

  return (
    <Wrapper>
      <TaskCategoryWrapper onClick={handleTitleClick} border>
        <TableDataGroup>
          <TableData>
            <ArrowIndicator margin="5px 30px 0 -5px" open={show} />
          </TableData>

          <TableData>
            <MasterTasksTitle>{masterTasks.name}</MasterTasksTitle>
          </TableData>
        </TableDataGroup>

        <TableDataGroup width="fit-content" justifyContent="space-around">
          <TableData
            onClick={(event) => handleCreateNewTaskBtn(event, masterTasks)}
          >
            <AddCircleIcon
              style={{color: theme.colors.primary, fontSize: 30}}
            />
          </TableData>

          <TableData
            onClick={(event) => handleCategorySettingsBtn(event, masterTasks)}
          >
            <SettingsIcon
              style={{color: theme.colors.mediumDark, marginLeft: 15}}
            />
          </TableData>
          <TableData />
        </TableDataGroup>
      </TaskCategoryWrapper>

      {show && (
        <TasksListWrapper>
          <TaskView
            category={masterTasks}
            tasks={masterTasks?.task_list}
            handleTaskClickBtn={handleTaskClickBtn}
            changeCompleted={changeCompleted}
          />
        </TasksListWrapper>
      )}
    </Wrapper>
  );
};

export default MasterTaskView;
